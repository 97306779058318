import React from "react";
import { I18n } from "react-redux-i18n";

const Button = ({ parentClassName, iconClassName, text, Href, onClick }) => (
  <div className={parentClassName}>
    <a onClick={onClick} href={Href}>
      {iconClassName && (
        <span>
          <i className={iconClassName} aria-hidden />
        </span>
      )}
      {I18n.t(text)}
    </a>
  </div>
);

export default Button;
