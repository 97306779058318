import React from 'react';
import {I18n} from 'react-redux-i18n';

function Partners() {
  return (
    <section className="Partners">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{I18n.t('partners')}</h2>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Partners__List">
              <img src="images/amazon.png" alt="amazon" loading="lazy" />
              <img src="images/google.png" alt="Google" loading="lazy" />
              <img src="images/stripe.png" alt="Stripe" loading="lazy" />
              <img src="images/hotjar.png" alt="Hotjar" loading="lazy" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Partners;
