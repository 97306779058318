import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import Carousel from "react-slick";
import { Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { Num } from "../Numbers";
import { 
  CLEAR_ADD_SERVICE_SUCCESS, 
  CLEAR_EDIT_SERVICE_SUCCESS,
  CLEAR_FOLLOW_USER_SUCCESS,
  CLEAR_UNFOLLOW_USER_SUCCESS
} from "../../actions/types";
import { getfreelancerServiceItem } from "../../actions/freelancerserviceitemActions";
import { deleteserviceitem } from "../../actions/deleteserviceActions";
import { followUser } from "../../actions/followActions";
import { unfollowUser } from "../../actions/followActions";
//import { getrelatedServices } from "../../actions/getservicesActions";
import { getreportsTypes } from "../../actions/reportsActions";
import { purchesservice } from "../../actions/purchesserviceActions";
import Spin from "../Spin";
//import settings from "../Sliders/slider-configurations";
import { TitleComponent } from "../../components/TitleComponent";
import { MetaComponent } from "../../components/MetaComponent";
import AddingServiceModal from "../AddingServiceModal";
import LockCard from "../LockCard";
import RatingStarsPreview from "../RatingStarsPreview";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import ConfToast from "../ConfToast";
import StatusLabel from "../../StatusLabel";
import DeleteModal from "../DeleteModal";
import ReportContentModal from "../ReportContentModal";
import NoPermisions from "../NoPermissions";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import ErrorBox from "../ErrorBox";
import { getServiceFeedback } from "../../redux/services/actions/getActions";
import Pagination from "react-js-pagination";
import { addFavourite } from "../../redux/favourite/actions/addActions";
import { deleteFavourite } from "../../redux/favourite/actions/deleteActions";
import { CLEAR_ADD_FAVOURITE_SUCCESS, CLEAR_DELETE_FAVOURITE_SUCCESS } from "../../redux/favourite/actions/types";
import SendMessageModal from "../SendMessageModal";
import { ServerURL } from "../Server";
import { getFollowings } from "../../redux/followers/actions/getActions";

export default function ServiceDetails(props) {
  const dispatch = useDispatch();

  const { id } = props.match.params;

  const {
    lang,
    isAuthenticated,
    user,
    userInfo,
    serviceitem,
    relatedServices,
    loadingserviceitem,
    loadingRelatedServices,
    followUserLoading,
    followDone,
    unFollowUserLoading,
    unfollowDone,
    followings,
    serviceItemDeleted,
    postReportLoading,
    loadingPurches,
    servicePurchesSuccess,
    invoiceData,
    isLoading,
    addServiceSuccess,
    editServiceSuccess,
    editedServiceItem,
    deleteServiceLoading,
    errorId,
    errorMessage,
    locale,
    getServiceFeedbackLoading,
    serviceFeedbacks,
    feedbackPerPage,
    totalFeedbacks,

    //Add To favourite
    addingFavouriteLoading,
    addingFavouriteSuccess,

    //Delete from favourite
    deletingFavouriteLoading,
    deletingFavouriteSuccess,

    lastNotification,

    //Send Message
    sendMessageLoading,
  } = useSelector((state) => ({
    lang: state.lang.lang,

    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    userInfo: state.userData.user,

    serviceitem: state.freelancerserviceitem.serviceitem,
    relatedServices: state.services.relatedservices.relatedServices,
    loadingserviceitem: state.freelancerserviceitem.loadingserviceitem,
    loadingRelatedServices: state.services.relatedservices.loadingRelatedServices,

    followUserLoading: state.followUser.followUserLoading,
    followDone: state.followUser.followDone,
    unFollowUserLoading: state.followUser.unFollowUserLoading,
    unfollowDone: state.followUser.unfollowDone,
    followings: state.followers.followings,

    serviceItemDeleted: state.deleteserviceItem.serviceItemDeleted,

    postReportLoading: state.reportsTypes.isLoading,

    loadingPurches: state.servicePurches.loadingPurches,
    servicePurchesSuccess: state.servicePurches.servicePurchesSuccess,
    invoiceData: state.servicePurches.invoiceData,

    isLoading: state.addserviceitem.isLoading,
    addServiceSuccess: state.addserviceitem.addServiceSuccess,

    editServiceSuccess: state.addserviceitem.editServiceSuccess,
    editedServiceItem: state.addserviceitem.serviceItem,

    deleteServiceLoading: state.deleteserviceItem.isLoading,

    errorId: state.error.id,
    errorMessage: state.error.msg,

    locale: state.i18n.locale,

    getServiceFeedbackLoading: state.service.getServiceFeedbackLoading,
    serviceFeedbacks: state.service.serviceFeedbacks,
    feedbackPerPage: state.service.feedbackPerPage,
    totalFeedbacks: state.service.totalFeedbacks,

    addingFavouriteLoading: state.favourites.addingFavouriteLoading,
    addingFavouriteSuccess: state.favourites.addingFavouriteSuccess,

    
    deletingFavouriteLoading: state.favourites.deletingFavouriteLoading,
    deletingFavouriteSuccess: state.favourites.deletingFavouriteSuccess,

    lastNotification: state.notifications.lastNotification,

    sendMessageLoading: state.messages.sendMessageLoading,
  }));

  const [showReport, setShowReport] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [qty, setQty] = useState(1);
  const [servicePrice, setServicePrice] = useState(0);
  const [service_id, setServiceId] = useState("");
  const [lgShow, setLgShow] = useState(false);
  //const [randServices, setRandServices] = useState([]);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = () => setShowDeleteConfirm(true);
  const [activePage, setActivePage] = useState(1);
  const [redirect, setRedirect] = useState(false);
  const [loadingService, setLoadingService] = useState(true);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if(lastNotification && lastNotification.notification_content.type === "ServiceStatusChangedByAdmin" && window.location.href.indexOf("/services") > -1 && (lastNotification.notification_content.content.id).toString() === id){
      const ItemData = {
        id,
        locale
      };
      dispatch(getfreelancerServiceItem(ItemData));
    }
  }, [lastNotification, dispatch, id, locale]);

  useEffect(() => {
    if(userInfo && userInfo.memberships && userInfo.memberships.length > 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    } else if(userInfo && userInfo.memberships && userInfo.memberships.length <= 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    }
  }, [userInfo]);

  useEffect(() => {
    setLoadingService(loadingserviceitem);
  }, [loadingserviceitem]);

  function handlePageChange (pageNumber) {
      setActivePage(pageNumber);
  }

  useEffect(() => {
    if(id){
      const ItemData = {
        id,
        locale
      };
      dispatch(getfreelancerServiceItem(ItemData));
      dispatch(getreportsTypes());
    }
  }, [id, dispatch, locale]);

  useEffect(() => {
    if(isAuthenticated && serviceitem.length > 0){
      const page = 1;
      const limit = 20;
      const user_id = serviceitem[0].user.id;
      dispatch(getFollowings(page, limit, user_id));
    }
  }, [dispatch, isAuthenticated, serviceitem]);

  useEffect(() => {
    if(id && isAuthenticated){
      const page = activePage;
      const limit = 10;
      dispatch(getServiceFeedback(id, page, limit));
    }
  }, [id, dispatch, activePage, isAuthenticated]);

  const handleToast = useCallback((showToast, title, message, status, delay) =>{
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  useEffect(() => {
    if (serviceitem.length > 0 && loadingserviceitem === false) {
      // const sub_cat_id = serviceitem[0].sub_category.id;
      // const publish = 1;
      // const limit = 200;
      // const status_id = 1;
      // dispatch(getrelatedServices(
      //   sub_cat_id, 
      //   publish, 
      //   status_id,
      //   limit
      // ));
      setServicePrice(serviceitem[0].price);
      setServiceId(serviceitem[0].id);
    }
  }, [serviceitem, loadingserviceitem, dispatch]);

  useEffect(() => {
    if (errorId === null && loadingPurches === false && Object.keys(invoiceData).length !== 0 && servicePurchesSuccess){
      setRedirect(true);
    };
  }, [errorId, loadingPurches, invoiceData, servicePurchesSuccess]);

  useEffect(() => {
      if(redirect){
          const { id } = invoiceData;
          window.location.replace(`${ServerURL}/payment?payable_type=service&payable_id=${id}&lang=${locale}`)
      }
  }, [redirect, invoiceData, locale]);

  function addToFavourite(){
    const morphType = "services";
    const morphID = id;
    dispatch(addFavourite(
      morphType,
      morphID,
      locale
    ));
  }

  function deleteFromFavourite(){
    const morphType = "services";
    dispatch(deleteFavourite(
      morphType,
      id,
      locale
    ));
  }

  useEffect(() => {
    if (addingFavouriteSuccess){
      const ItemData = {
        id,
        locale
      };
      dispatch(getfreelancerServiceItem(ItemData));
      setTimeout(() => {
        handleToast(true, I18n.t('adding_service_to_fav'), I18n.t('adding_service_to_fav_success'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_ADD_FAVOURITE_SUCCESS
      });
    };
  },[addingFavouriteSuccess, handleToast, dispatch, id, locale]);

  useEffect(() => {
    if (deletingFavouriteSuccess){
      const ItemData = {
        id,
        locale
      };
      dispatch(getfreelancerServiceItem(ItemData));
      setTimeout(() => {
        handleToast(true, I18n.t('deleting_service_from_fav'), I18n.t('deleting_service_from_fav_success'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_DELETE_FAVOURITE_SUCCESS
      });
    };
  },[deletingFavouriteSuccess, handleToast, dispatch, id, locale]);


  function handelFollow(id) {
    const action = "follow";
    const followed_id = id;
    //create follow object
    const followData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(followUser(followData));
  };
  function handelUnfollow(id) {
    const action = "unfollow";
    const followed_id = id;
    //create follow object
    const unfollowData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(unfollowUser(unfollowData));
  };
  function handleShow() {
    setLgShow(true);
  };
  function handleHide() {
    setLgShow(false);
  };
  function handleShowReport() {
    setShowReport(true);
  };
  function handleHideReport() {
    setShowReport(false);
  };

  function handleShowMessage() {
    setShowMessageModal(true);
  };
  function handleHideMessage() {
    setShowMessageModal(false);
  };

  function handleDeleteOnClick() {
    dispatch(deleteserviceitem({ id }));
  }

  function featureSelect(event) {
      if(event.checked === true){
          const newPrice = totalPrice + event.price;
          setTotalPrice(newPrice);
          const updatedFeatures = selectedFeatures;
          updatedFeatures.push(event.id);
          setSelectedFeatures(updatedFeatures);
      }else{
          const newPrice = totalPrice - event.price;
          setTotalPrice(newPrice);
          const updatedFeatures = selectedFeatures;
          for( var i = 0; i < updatedFeatures.length; i++){ 
              if ( updatedFeatures[i] === event.id) { 
                updatedFeatures.splice(i, 1); 
                setSelectedFeatures(updatedFeatures);
              }
          }
      }
  }
  function handleRequestPlus() {
    if (qty <= 9) {
      const newValue = qty + 1;
      setQty(newValue);
    }
  };
  function handleRequestMinus() {
    if (qty >= 2) {
      const newValue = qty - 1;
      setQty(newValue);
    }
  };
  function handelBuyService() {
    const features = selectedFeatures;
    const formData = {
      service_id,
      features,
      qty,
    };
    dispatch(purchesservice(formData));
  };

  const currentService = serviceitem[0];

  const GetService = useCallback(() => {
    const chosenService = relatedServices[Math.round((Math.random()*relatedServices.length))];
  
    if (chosenService === currentService.id)
    {
      return GetService();
    } 
    return chosenService;
  }, [relatedServices, currentService]);

  useEffect(() => {
    if(relatedServices.length > 0 && loadingRelatedServices === false && currentService){
      // if (relatedServices.length > 6) {
      //   const filteredServices = [];
      //   for (var i = 0; i < 10; i++) {
      //     const pickedService = GetService();
      //     filteredServices.push(pickedService);
      //   }
      //   setRandServices(filteredServices);
      // } else {
      //   const randomServices = [];
      //   relatedServices.map((item) =>
      //     item.id === currentService.id ? null : randomServices.push(item)
      //   );
      //   setRandServices(randomServices);
      // }
    }
  }, [relatedServices, loadingRelatedServices, currentService, GetService]);

  useEffect(() => {
    if (followDone && !followUserLoading && id) {
      const ItemData = {
        id,
        locale
      };
      const page = 1;
      const limit = 20;
      const user_id = currentService.user.id;
      dispatch(getFollowings(page, limit, user_id));
      dispatch(getfreelancerServiceItem(ItemData));
      setTimeout(() => {
        handleToast(true, I18n.t('following') + " " + currentService.user.fname, I18n.t('following_success') + " " + currentService.user.fname + " " + I18n.t('successfull'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_FOLLOW_USER_SUCCESS
      });
    }
  }, [followDone, followUserLoading, dispatch, handleToast, currentService, id, locale]);

  useEffect(() => {
    if (unfollowDone && !unFollowUserLoading && id) {
      const ItemData = {
        id,
        locale
      };
      const page = 1;
      const limit = 20;
      const user_id = currentService.user.id;
      dispatch(getFollowings(page, limit, user_id));
      dispatch(getfreelancerServiceItem(ItemData));
      setTimeout(() => {
        handleToast(true, I18n.t('unfollowing') + " " + currentService.user.fname, I18n.t('unfollowing_success') + " " + currentService.user.fname + " " + I18n.t('successfull'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_UNFOLLOW_USER_SUCCESS
      });
    }
  }, [unfollowDone, unFollowUserLoading, dispatch, handleToast, currentService, id, locale]);

  useEffect(() => {
    if (addServiceSuccess && isLoading === false) {
      dispatch({
          type: CLEAR_ADD_SERVICE_SUCCESS
      });
      setTimeout(() => {
        handleToast(true, I18n.t('add_service'), I18n.t('add_service_success'), 'Toast__Container__Success', 7000);
      }, 1500);
    }
  }, [addServiceSuccess, isLoading, dispatch, handleToast]);

  useEffect(() => {
    if (editServiceSuccess && isLoading === false) {
      dispatch({
          type: CLEAR_EDIT_SERVICE_SUCCESS
      });
      if(editedServiceItem.status.id === 2){
        setTimeout(() => {
          handleToast(true, I18n.t('edit_service'), I18n.t('edit_service_review_success'), 'Toast__Container__Success', 7000);
        }, 1500);
      }else{
        setTimeout(() => {
          handleToast(true, I18n.t('edit_service'), I18n.t('edit_service_success'), 'Toast__Container__Success', 6000);
        }, 1500);
      }
    }
  }, [editServiceSuccess, isLoading, dispatch, handleToast, editedServiceItem]);

  const totalItemsCount = totalFeedbacks;
  const itemsCountPerPage = Number(feedbackPerPage);

  function stripHtml(html){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html.replace(/&nbsp;/g, '');
    return tmp.textContent || tmp.innerText || "";
  }

  if (serviceItemDeleted && !deleteServiceLoading) {
    return <Redirect to="/dashboard/services" />;
  }

  const createMarkup = (text) => {
    return { __html: text };
  };

  
  if (loadingService) {
    return <Spin />;
  } else if(errorId === "GET_SERVICE_ITEM_FAIL" && errorMessage === "Network Error"){
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 Dashboard__tasks__List">
              <ConnectionLost
                errorType= "connection"
                title = "network_error_title"
                message = "network_error_message"
              />
            </div>
          </div>
        </div>
      )
  }else if(errorId === "GET_SERVICE_ITEM_FAIL"){
      return (
        <div className="container">
          <div className="row">
            <div className="col-12 Dashboard__tasks__List">
              <ConnectionLost
                errorType= "connection"
                title = "network_error_title"
                message = "network_error_message"
              />
            </div>
          </div>
        </div>
      )
  }else if (serviceitem.length > 0) {
    const serviceCarouselSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      rtl: lang === "English" ? false : true
    };
    return (
      <Fragment>
        <TitleComponent title={I18n.t('lancersin_services') + " - " + currentService.title.substring(0, 35)} />
        <MetaComponent keywords={currentService.keywords && currentService.keywords.length >= 3 ? currentService.keywords : currentService.title} description={stripHtml(currentService.description).substring(0, 150)} />
        <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
        />
        <DeleteModal
          onHide={handleDeleteConfirmClose}
          onDelete={handleDeleteOnClick}
          show={showDeleteConfirm}
          modalTitle={"delete_service"}
          className="CustomPopup"
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          loading={deleteServiceLoading}
          messageHeading="delete_conf"
          messageBody={currentService.title}
        />
        <ReportContentModal
          onHide={handleHideReport}
          show={showReport}
          modalTitle={"edit_article"}
          className="CustomPopup"
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          loading={postReportLoading}
          model="services"
          model_id={currentService.id}
          setConfShow={setConfShow}
          setConfTitle={setConfTitle}
          setConfMessage={setConfMessage}
          setConfStatus={setConfStatus}
          setDelay={setDelay}
        />
        <SendMessageModal
          onHide={handleHideMessage}
          show={showMessageModal}
          modalTitle={I18n.t('inquiry_about') + " " + currentService.title}
          className="CustomPopup"
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          loading={sendMessageLoading}
          morphType="services"
          morphID={currentService.id}
          userID={currentService.user.id}
          setConfShow={setConfShow}
          setConfTitle={setConfTitle}
          setConfMessage={setConfMessage}
          setConfStatus={setConfStatus}
          setDelay={setDelay}
        />
        <AddingServiceModal
          show={lgShow}
          serviceItem={currentService}
          className="CustomPopup"
          size="lg"
          onHide={handleHide}
          backdrop="static"
          keyboard={false}
          modalTitle={I18n.t("edit_service")}
          aria-labelledby="example-modal-sizes-title-lg"
          props={props}
          isLoading={isLoading}
          setConfShow={setConfShow}
          setConfTitle={setConfTitle}
          setConfMessage={setConfMessage}
          setConfStatus={setConfStatus}
          setDelay={setDelay}
        />
        {
        currentService.user.id === user.id || (currentService.status.id === 1 && currentService.publish === 1) ?
          <Fragment>
            <div className="breadcrumb__container">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                            <li className="breadcrumb-item"><Link to="/services">{I18n.t('services')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{currentService.title} {props.prevPage}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="PortfolioDetails">
              <div className="container InnerPageContainer mt-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="Profile-Card box-shadow">
                      <div className="row">
                        <div className="col-12">
                          <div className="ProfileData">
                            <div className="ProfileImage">
                              <figure>
                                <img
                                  src={currentService.user.image.url ? currentService.user.image.url : currentService.user.image}
                                  alt={currentService.user.fname + " " + currentService.user.lname}
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                            <div className="ProfileContent">
                              <h3 className="ProfileTitle">
                                <Translate value="by" />:{" "}
                                <Link to={{ pathname: `/in/${currentService.user.username}` }}>
                                  {currentService.user.fname} {currentService.user.lname}
                                </Link>
                              </h3>
                              <RatingStarsPreview rate={currentService.user.rate} />
                              <div className="LocationTime">
                                {
                                  currentService.user.country ?
                                  <address>
                                    <span className="LocationFlag">
                                      <img
                                        src={currentService.user.country.flag}
                                        alt={currentService.user.country.name}
                                      />
                                    </span>
                                    <span className="Location">
                                      {currentService.user.city.name ? currentService.user.city.name + ',' : null} {currentService.user.country.name}
                                    </span>
                                  </address>
                                  : null
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          { 
                            isAuthenticated ?
                              currentService.user.id !== user.id ? (
                              <Fragment>
                                {/*
                                  user.memberships.some(
                                    (item) => item.id === 2
                                  ) ? (
                                  <Dropdown>
                                    <Dropdown.Toggle
                                      id="dropdown-basic"
                                      className="HireMe"
                                    >
                                      {I18n.t("hire_me")}{" "}
                                      <span>
                                        <i className="icon icon-angle-down"></i>
                                      </span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">
                                        <Translate value="invite_project" />
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">
                                        <Translate value="invite_contest" />
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                  ) : null
                                */}
                                {
                                  isClient ?
                                  <button 
                                    className="btn btn-primary w-100 mb-2"
                                    onClick={() => {
                                      handleShowMessage();
                                    }}
                                  >
                                    <i className="icon icon-envelope"></i>{" "}
                                    <Translate value="message_seller" />
                                  </button>
                                  : null
                                }
                                {
                                  followings.length > 0 && followings[0].id === currentService.user.id ?
                                    <ButtonWithLoading
                                      variant="primary"
                                      type="button"
                                      onClick={() =>
                                        handelUnfollow(currentService.user.id)
                                      }
                                      data-backdrop="static"
                                      loading={unFollowUserLoading}
                                      className="w-100 mb-2"
                                    >
                                      <i className="fas fa-heart"></i>{" "}
                                      <Translate value="un_follow" />
                                    </ButtonWithLoading>
                                  : 
                                    <ButtonWithLoading
                                        variant="primary"
                                        type="button"
                                        className="w-100 mb-2"
                                        onClick={() =>
                                          handelFollow(currentService.user.id)
                                        }
                                        data-backdrop="static"
                                        loading={followUserLoading}
                                    >
                                      <i className="far fa-heart"></i>{" "}
                                      {I18n.t("follow")}
                                    </ButtonWithLoading>
                                }

                                {
                                  currentService.is_favourite ?
                                  <ButtonWithLoading
                                    variant="danger"
                                    type="submit"
                                    data-backdrop="static"
                                    loading={deletingFavouriteLoading}
                                    onClick={() => deleteFromFavourite()}
                                    className="w-100 mb-2"
                                  >
                                    <span className="fas fa-heart"></span>
                                    {I18n.t('delete_from_fav')}
                                  </ButtonWithLoading>
                                  :
                                  <ButtonWithLoading
                                    variant="primary"
                                    type="submit"
                                    onClick={() => addToFavourite()}
                                    data-backdrop="static"
                                    loading={addingFavouriteLoading}
                                    className="w-100 mb-2"
                                  >
                                    <span className="icon icon-heart"></span>
                                    {I18n.t('add_to_favourite')}
                                  </ButtonWithLoading>
                                }
                              </Fragment>
                              ) : null
                            : null
                          }
                          <div className="FollowsCount">
                            <Translate value="followers" />:{" "}
                            {Num(currentService.user.followersCount)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Card>
                      <Card.Header>
                        <h2><Translate value="service_statistics" /></h2>
                      </Card.Header>
                      <Card.Body>
                        <div className="Service__Info">
                          <span className="icon icon-deal"></span>
                          <span className="number">
                            {Num(currentService.purchasesCount)}
                          </span>{" "}
                          {I18n.t("bought_service")}
                        </div>
                        <div className="Service__Info">
                          <span className="icon icon-in-progress"></span>
                          <span className="number">
                            {Num(currentService.servicesInProgress)}
                          </span>{" "}
                          {I18n.t("inprogress_requests")}
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className="col-md-9">
                    <Card>
                      <Card.Header>
                        <div>
                          <h1>{currentService.title}</h1>
                          {
                            currentService.user.id === user.id ?
                              currentService.publish === 1 &&  currentService.status.id === 1?
                                  <OverlayTrigger
                                      key="published"
                                      placement="top"
                                      overlay={
                                      <Tooltip id="tooltip-published">
                                        {I18n.t('any_one_service')}
                                      </Tooltip>
                                      }
                                  >
                                      <i className="far fa-eye mx-1"></i>
                                  </OverlayTrigger>
                                  : 
                                  <OverlayTrigger
                                      key="hidden"
                                      placement="top"
                                      overlay={
                                      <Tooltip id="tooltip-hidden">
                                        {I18n.t('no_one_service')}
                                      </Tooltip>
                                      }
                                  >
                                      <i className="far fa-eye-slash mx-1"></i>
                                  </OverlayTrigger>
                              : null
                          }
                        </div>
                        {
                          isAuthenticated ?
                            currentService.user.id === user.id ? (
                              <div>
                                <button
                                  className="btn"
                                  onClick={() => {
                                    handleShow();
                                  }}
                                >
                                  <span className="icon icon-edit"></span>
                                  <Translate value="edit" />
                                </button>
                                <button
                                  className="btn"
                                  onClick={handleDeleteConfirmShow}
                                >
                                  <span className="icon icon-delete"></span>
                                  <Translate value="delete" />
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn"
                                onClick={() => {
                                  handleShowReport();
                                }}
                              >
                                <span className="icon icon-report"></span>
                                <Translate value="report_content" />
                              </button>
                            )
                          : null
                        }
                      </Card.Header>
                      <Card.Body>
                        {currentService.images.length > 0 ||
                          currentService.videos.length > 0 ? (
                          <div className="row mb-5">
                            <div className="col-12">
                              <Carousel
                                {...serviceCarouselSettings}
                                className="Service__Carousel"
                              >
                                {currentService.images.length > 0 ? 
                                  currentService.images.map((image, index) => (
                                      <img
                                        src={image.url}
                                        alt={currentService.title}
                                        key={index}
                                      />
                                    ))
                                  : null
                                }
                                {currentService.videos.length > 0
                                  ? currentService.videos.map((video, index) => (
                                    <div className="player-wrapper">
                                      <ReactPlayer
                                        width="100%"
                                        height="100%"
                                        className="react-player"
                                        key={index}
                                        url={video}
                                      />
                                    </div>
                                    ))
                                  : null}
                              </Carousel>
                            </div>
                          </div>
                        ) : null}
                        {
                          currentService.images.length <= 0 &&
                          currentService.videos.length <= 0 ?
                          currentService.image.url ?
                          <div className="d-flex justify-content-center mb-3">
                            <img
                              src={currentService.image.url}
                              alt={currentService.title}
                              className="img-fluid"
                            />
                          </div>
                          :
                          <div className="d-flex justify-content-center mb-3">
                            <img
                              src={currentService.image}
                              alt={currentService.title}
                              className="img-fluid"
                            />
                          </div>
                          : null
                        }
                        {currentService.description ? (
                          <div className="row mb-3">
                            <div className="col-12">
                              <h3 className="listing__title">
                                <Translate value="description" />
                              </h3>
                            </div>
                            <div className="col-12">
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  currentService.description
                                )}
                                className="listing__description"
                              ></div>
                            </div>
                          </div>
                        ) : null}
                        {currentService.category ? (
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <span className="listing__title">
                                <Translate value="category" />:
                              </span>
                              <span className="listing__description">
                                {currentService.category.title}
                              </span>
                            </div>
                            <div className="col-md-6">
                              <span className="listing__title">
                                <Translate value="sub_category" />:
                              </span>
                              <span className="listing__description">
                                {currentService.sub_category.title}
                              </span>
                            </div>
                          </div>
                        ) : null}
                        <div className="row mb-3">
                          {currentService.deadline ? (
                            <div className="col-md-6">
                              <span className="listing__title">
                                <h4><Translate value="number_to_deliver" /></h4>:
                              </span>
                              <span className="listing__description">
                                {currentService.deadline} <Translate value="day_s" />
                              </span>
                            </div>
                          ) : null}
                          {currentService.price ? (
                            <div className="col-md-6">
                              <span className="listing__title">
                                <h3><Translate value="price_start" /></h3>:
                              </span>
                              <span className="listing__description">
                                {currentService.price}$
                              </span>
                            </div>
                          ) : null}
                        </div>
                        {
                          currentService.user.id === user.id ? (
                            currentService.status ? (
                              <div className="row mb-3">
                                <div className="col-md-6">
                                  <div className="Service__Status">
                                    <span className="listing__title">
                                      <Translate value="status" />:
                                    </span>
                                    <StatusLabel status_id={currentService.status ? currentService.status.id : null} type="project" />
                                  </div>
                                </div>
                              </div>
                            ) : null
                          ) : null
                        }
                      </Card.Body>
                    </Card>
                    {currentService.features.length > 0 ? (
                      <Card>
                        <Card.Header>
                          <div>
                            <Translate value="features_to_service" />
                          </div>
                        </Card.Header>
                        <Card.Body>
                          {currentService.features.map((item, index) => (
                            <div className="card box-shadow mb-2" key={index}>
                              <div className="Service__Features__Title">
                                <Form.Group controlId={item.id}>
                                  {/*
                                  Choose feature to buy will display after enabling payment
                                  */}
                                  {
                                    currentService.user.id !== user.id && isClient ? (
                                      <Form.Check
                                        label={item.title}
                                        onChange={(e) =>
                                          featureSelect({
                                            id: item.id,
                                            price: item.price,
                                            checked: e.target.checked,
                                          })
                                        }
                                      />
                                    ) : (
                                      <Form.Label>{item.title}</Form.Label>
                                    )
                                  }
                                </Form.Group>
                              </div>
                              <div className="Service__Features__Content">
                                {item.description ? (
                                  <div className="row mb-1">
                                    <div className="col-12">
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.description
                                        )}
                                        className="listing__description"
                                      ></div>
                                    </div>
                                  </div>
                                ) : null}
                                <div className="row">
                                  <div className="col-md-6">
                                    <span className="listing__title">
                                      <Translate value="number_to_deliver" />:
                                    </span>
                                    <span className="listing__description">
                                      +{item.deadline} <Translate value="day_s" />
                                    </span>
                                  </div>
                                  <div className="col-md-6">
                                    <span className="listing__title">
                                      <Translate value="price" />:
                                    </span>
                                    <span className="listing__description">
                                      +{item.price}$
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </Card.Body>
                      </Card>
                    ) : null}
                    {/*
                      Buy service component will show it after lauch payment
                    */}
                    {
                      !isAuthenticated ?
                      <Card>
                          <Card.Header>
                            <div>
                              <Translate value="buy_service" />
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <LockCard
                              user={currentService.user}
                              title="sign_to_buy"
                              signFor="sign_service_buy"
                              fullPage={true}
                            />
                          </Card.Body>
                      </Card>
                      : currentService.user.id !== user.id && isClient ? (
                        <Card>
                          <Card.Header>
                            <div>
                              <Translate value="buy_service" />
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <h3 className="Service__Price text-center d-block mb-3">
                              <Translate value="total_request" />:
                              <button
                                className="btn btn-primary mx-1"
                                onClick={handleRequestMinus}
                                type="button"
                              >
                                -
                              </button>
                              <input
                                className="form-control d-inline w-auto text-center mx-1 input-curved"
                                value={qty}
                                disabled
                              />
                              <button
                                className="btn btn-primary mx-1"
                                onClick={handleRequestPlus}
                                type="button"
                              >
                                +
                              </button>
                            </h3>
                            <h3 className="Service__Price text-center d-block">
                              <Translate value="total_price" />:{" "}
                              <span className="listing__description">
                                {(totalPrice + servicePrice) * qty}$
                              </span>
                            </h3>
                            {
                              errorId === "PURCHES_SERVICE_FAIL" && errorMessage === "Network Error" ?
                                <ErrorBox message={I18n.t('network_error_message')} className="mt-3" />
                              : errorId === "PURCHES_SERVICE_FAIL" && errorMessage === "Other Errors" ?
                                <ErrorBox message={I18n.t('something_error_message')} className="mt-3" />
                              : null
                            }
                            <div className="text-center mt-3">
                              <ButtonWithLoading
                              className="btn btn-primary m-auto"
                              type="submit"
                              onClick={handelBuyService}
                              loading={loadingPurches ? loadingPurches : null}
                              >
                                  <Translate value="buy_now" />
                              </ButtonWithLoading>
                            </div>
                          </Card.Body>
                        </Card>
                      ) : null
                    }
                    {
                      serviceFeedbacks ?
                        serviceFeedbacks.length > 0 ?
                        <Card>
                          <Card.Header>
                            <div>
                              <Translate value="buyers_feedback" />
                            </div>
                          </Card.Header>
                          <Card.Body>
                            {
                              getServiceFeedbackLoading ?
                              <Spin />
                              :
                              <ul className="serviceFeedback__List">
                                {
                                  serviceFeedbacks.map( item => {
                                      return (
                                        <li key={item.id}>
                                          {
                                            item.feedbacks.map(feedback => {
                                              if(feedback.user_type === "Buyer"){
                                                return(
                                                  <div className="feedback__Buyer" key={feedback.id}>
                                                    <div className="Comment__userInfo">
                                                        <div className="UserPic">
                                                          <img
                                                              src={ feedback.user.image }
                                                              alt={ feedback.user.fname + " " + feedback.user.lname }
                                                          />
                                                        </div>
                                                        <div className="CommentContent__Details">
                                                          <h3>
                                                              <Link to={{ pathname: `/in/${feedback.user.username}`}} >
                                                              {feedback.user.fname + " " + feedback.user.lname}
                                                              </Link>
                                                          </h3>
                                                          <div className="CommentContent__Details__PostTime">
                                                              { feedback.created_at.Date + " - " + feedback.created_at.Time }
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <RatingStarsPreview rate={feedback.rate} />
                                                    <div className="CommentContent">
                                                      <div dangerouslySetInnerHTML={createMarkup( feedback.comment )}></div>
                                                    </div>
                                                  </div>
                                                );
                                              }else{
                                                return(
                                                  <div className="feedback__Seller" key={feedback.id}>
                                                    <div className="Comment__userInfo">
                                                        <div className="UserPic">
                                                          <img
                                                              src={ feedback.user.image }
                                                              alt={ feedback.user.fname + " " + feedback.user.lname }
                                                          />
                                                        </div>
                                                        <div className="CommentContent__Details">
                                                          <h3>
                                                              <Link to={{ pathname: `/in/${feedback.user.username}`}} >
                                                              {feedback.user.fname + " " + feedback.user.lname}
                                                              </Link>
                                                          </h3>
                                                          <div className="CommentContent__Details__PostTime">
                                                              { feedback.created_at.Date + " - " + feedback.created_at.Time }
                                                          </div>
                                                        </div>
                                                    </div>
                                                    <RatingStarsPreview rate={feedback.rate} />
                                                    <div className="CommentContent">
                                                      <div dangerouslySetInnerHTML={createMarkup( feedback.comment )}></div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            })
                                          }
                                        </li>
                                      )
                                  })
                                }
                              </ul>
                            }
                            {
                              serviceFeedbacks.length > 0 ?
                              <div className="row">
                                <div className="col-12 mt-3">
                                  <Pagination
                                      activePage={activePage}
                                      itemsCountPerPage={itemsCountPerPage}
                                      totalItemsCount={totalItemsCount}
                                      pageRangeDisplayed={10}
                                      onChange={handlePageChange}
                                      itemClass="page-item"
                                      linkClass="page-link"
                                  />
                                </div>
                              </div>
                              : null
                            }
                          </Card.Body>
                        </Card>
                        : null
                      : null
                    }
                  </div>
                </div>

                {/* 
                <Card>
                  <Card.Header>
                    <div>
                      <Translate value="relatedـservices" />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="row">
                      <div className="col-12">
                        {loadingRelatedServices ? (
                          <Spin />
                        ) : randServices.length > 0 ? (
                          <Carousel {...settings} dots arrows={false}>
                            {randServices.map((serviceItem) =>
                              <div
                                className="services__listitem box-shadow"
                                key={serviceItem.id}
                              >
                                  <figure>
                                    <Link
                                      className="services__imgcontainer"
                                      to={{
                                        pathname: `/services/${
                                          serviceItem.id
                                        }/${serviceItem.title
                                          .replace(/\s+/g, "-")
                                          .replace(/\//g, "-")
                                          .toLowerCase()}`,
                                      }}
                                    >
                                      <img
                                        src={serviceItem.image.url ? serviceItem.image.url : serviceItem.image}
                                        alt={serviceItem.title}
                                      />
                                    </Link>
                                    <figcaption>
                                      <h2>
                                        <Link
                                          to={{
                                            pathname: `/services/${
                                              serviceItem.id
                                            }/${serviceItem.title
                                              .replace(/\s+/g, "-")
                                              .replace(/\//g, "-")
                                              .toLowerCase()}`,
                                          }}
                                        >
                                          {serviceItem.title}
                                        </Link>
                                      </h2>
                                    </figcaption>
                                  </figure>
                                  <div className="services__info">
                                    <div className="bought">
                                      <span className="icon icon-deal"></span>
                                      {Num(serviceItem.purchasesCount)}
                                    </div>
                                    <div className="price">
                                      <span className="icon icon-price-label"></span>
                                      {serviceItem.price}$
                                    </div>
                                  </div>
                              </div>
                            )}
                          </Carousel>
                        ) : (
                          <div className="text-center">No Items</div>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                */}

              </div>
            </div>
          </Fragment>
          : <NoPermisions />
        }
        </Fragment>
    );
  }else {
    return <NoItems />;
  }
}
