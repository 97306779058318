import TYPES  from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
    UPDATE_USER_PLANS_LOADING,
    UPDATE_USER_PLANS_SUCCESS,
    UPDATE_USER_PLANS_FAIL,
    SET_USER_VIEW_LOADING,
    SET_USER_VIEW_SUCCESS,
    SET_USER_VIEW_FAIL,
  } = TYPES;
  
  export function updateUserPlans(data) {
    return async function updateUserPlansWithDispatch(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
        type: UPDATE_USER_PLANS_LOADING
      });
      try {
          let response = await axiosInstance
          .post(
            API.UPDATE_USER_PLANS, 
            data, 
            tokenConfig(getState)
            );
          if(response.status !== 200){
              dispatch({
                type: UPDATE_USER_PLANS_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
            dispatch({
              type: UPDATE_USER_PLANS_SUCCESS,
              payload: response.data
            })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch({
              type: UPDATE_USER_PLANS_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'UPDATE_USER_PLANS_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            dispatch({
              type: UPDATE_USER_PLANS_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'UPDATE_USER_PLANS_FAIL'));
          }
      }
    }
  }

  export function updateUserView(data) {
    return async function updateUserViewWithDispatch(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
        type: SET_USER_VIEW_LOADING
      });
      try {
          let response = await axiosInstance
          .post(
            API.SET_USER_VIEW, 
            data, 
            tokenConfig(getState)
            );
          if(response.status !== 200){
              dispatch({
                type: SET_USER_VIEW_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
            dispatch({
              type: SET_USER_VIEW_SUCCESS,
              payload: response.data
            })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch({
              type: SET_USER_VIEW_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'SET_USER_VIEW_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            dispatch({
              type: SET_USER_VIEW_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'SET_USER_VIEW_FAIL'));
          }
      }
    }
  }
  
  const exportedObject = {
    updateUserPlans,
    updateUserView
  };
  
  export default exportedObject;