import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getFreelancer} from '../../actions/freelancerprofileActions';
import Spin from '../Spin';
import NoItems from '../NoItems';
import ProfileCard from './ProfileCard';
import ProfileContent from './ProfileContent';
import {I18n} from 'react-redux-i18n';
import ConnectionLost from '../ConnectionLost';
import ConfToast from '../ConfToast';
import {
  CLEAR_UNFOLLOW_USER_SUCCESS,
  CLEAR_FOLLOW_USER_SUCCESS,
} from '../../actions/types';
import {TitleComponent} from '../TitleComponent';
import {MetaComponent} from '../MetaComponent';

const ProfilePage = props => {
  const dispatch = useDispatch();

  const {username} = props.match.params;
  const {
    loggedUser,
    LoadingUser,
    freelancer,
    isLoading,
    errorId,
    errorMessage,
    locale,
    followDone,
    unfollowDone,
  } = useSelector(state => ({
    loggedUser: state.auth.user,
    LoadingUser: state.auth.isLoading,
    freelancer: state.freelancerdata.freelancer,
    isLoading: state.freelancerdata.isLoading,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    locale: state.i18n.locale,
    followDone: state.followUser.followDone,
    unfollowDone: state.followUser.unfollowDone,
  }));

  const [loadingFreelancer, setLoadingFreelancer] = useState(true);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    setLoadingFreelancer(isLoading);
  }, [isLoading]);

  useEffect(() => {
    const ItemData = {
      username,
      locale,
    };
    dispatch(getFreelancer(ItemData));
  }, [username, dispatch, locale]);

  useEffect(() => {
    if (followDone) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('following') + ' ' + freelancer[0].fname,
          I18n.t('following_success') +
            ' ' +
            freelancer[0].fname +
            ' ' +
            I18n.t('successfull'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      dispatch({
        type: CLEAR_FOLLOW_USER_SUCCESS,
      });
      const ItemData = {
        username,
        locale,
      };
      dispatch(getFreelancer(ItemData));
      //window.location.reload(setTimeout(3000));
    } else if (unfollowDone) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('unfollowing') + ' ' + freelancer[0].fname,
          I18n.t('unfollowing_success') +
            ' ' +
            freelancer[0].fname +
            ' ' +
            I18n.t('successfull'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      dispatch({
        type: CLEAR_UNFOLLOW_USER_SUCCESS,
      });
      const ItemData = {
        username,
        locale,
      };
      dispatch(getFreelancer(ItemData));
      //window.location.reload(setTimeout(3000));
    }
  }, [
    followDone,
    unfollowDone,
    freelancer,
    handleToast,
    dispatch,
    username,
    locale,
  ]);

  function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html.replace(/&nbsp;/g, '');
    return tmp.textContent || tmp.innerText || '';
  }

  const freelancerProfile = freelancer[0];
  if (loadingFreelancer) {
    return <Spin />;
  } else if (
    errorId === 'FREELANCER_PROFILE_FAIL' &&
    errorMessage === 'Network Error'
  ) {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 Dashboard__tasks__List">
            <ConnectionLost
              errorType="connection"
              title="network_error_title"
              message="network_error_message"
            />
          </div>
        </div>
      </div>
    );
  } else if (errorId === 'FREELANCER_PROFILE_FAIL') {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12 Dashboard__tasks__List">
            <ConnectionLost
              errorType="connection"
              title="network_error_title"
              message="network_error_message"
            />
          </div>
        </div>
      </div>
    );
  } else if (freelancer.length > 0 && !loadingFreelancer) {
    return (
      <Fragment>
        <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
        />
        <TitleComponent
          title={
            I18n.t('lancersin_freelancers') +
            ' - ' +
            (freelancerProfile.fname + ' ' + freelancerProfile.lname).substring(
              0,
              25,
            )
          }
        />
        <MetaComponent
          description={
            freelancerProfile.about
              ? stripHtml(freelancerProfile.about).substring(0, 150)
              : null
          }
        />
        <div className="breadcrumb__container">
          <div className="container">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">{I18n.t('home')}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/freelancers">{I18n.t('freelancers')}</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {freelancerProfile.fname + ' ' + freelancerProfile.lname}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <ProfileCard
          freelancerdata={freelancerProfile}
          loggedUser={loggedUser}
          LoadingUser={LoadingUser}
        />
        <ProfileContent freelancerdata={freelancerProfile} />
      </Fragment>
    );
  } else {
    return <NoItems />;
  }
};

export default ProfilePage;
