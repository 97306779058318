import { 
    GET_RUNNING_INVOICES_SUCCESS, 
    GET_RUNNING_INVOICES_LOADING,
    GET_RUNNING_INVOICES_FAIL, 
    GET_CLOSED_INVOICES_LOADING, 
    GET_CLOSED_INVOICES_SUCCESS,
    GET_CLOSED_INVOICES_FAIL,
    GET_CANCELLED_INVOICES_LOADING, 
    GET_CANCELLED_INVOICES_SUCCESS, 
    GET_CANCELLED_INVOICES_FAIL,
} from '../actions/types';

const initialState = {
    runninginvoices: [],
    runningtotalRecords: 0,
    runningitemsPerPage: 0,
    loadingrunninginvoices: false,
    closedinvoices: [],
    closedtotalRecords: 0,
    closeditemsPerPage: 0,
    loadingclosedinvoices: false,
    cancelledinvoices: [],
    cancelledtotalRecords: 0,
    cancelleditemsPerPage: 0,
    loadingcancelledinvoices: false
}

export default function serviceinvoicesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_RUNNING_INVOICES_SUCCESS:
            return{
                ...state,
                runninginvoices: action.payload.data,
                runningtotalRecords: action.payload.totalRecords,
                runningitemsPerPage: action.payload.itemsPerPage,
                loadingrunninginvoices: false
            }    
        ;
        case GET_RUNNING_INVOICES_LOADING: 
            return{
                ...state,
                runninginvoices: [],
                loadingrunninginvoices: true
            }
        ;
        case GET_RUNNING_INVOICES_FAIL:
            return{
                ...state,
                loadingrunninginvoices: false
            }
        ;
        case GET_CLOSED_INVOICES_SUCCESS:
            return{
                ...state,
                closedinvoices: action.payload.data,
                closedtotalRecords: action.payload.totalRecords,
                closeditemsPerPage: action.payload.itemsPerPage,
                loadingclosedinvoices: false
            }    
        ;
        case GET_CLOSED_INVOICES_LOADING: 
            return{
                ...state,
                closedinvoices: [],
                loadingclosedinvoices: true
            }
        ;
        case GET_CLOSED_INVOICES_FAIL:
            return{
                ...state,
                loadingclosedinvoices: false
            }
        ;
        case GET_CANCELLED_INVOICES_SUCCESS:
            return{
                ...state,
                cancelledinvoices: action.payload.data,
                cancelledtotalRecords: action.payload.totalRecords,
                cancelleditemsPerPage: action.payload.itemsPerPage,
                loadingcancelledinvoices: false
            }    
        ;
        case GET_CANCELLED_INVOICES_LOADING: 
            return{
                ...state,
                cancelledinvoices: [],
                loadingcancelledinvoices: true
            }
        ;
        case GET_CANCELLED_INVOICES_FAIL:
            return{
                ...state,
                loadingcancelledinvoices: false
            }
        ;
        default:
            return state;
    }
}