import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';
import ButtonWithLoading from './Buttons/ButtonWithLoading';

const NoItems = (props) => {
    const {
        title,
        message,
        buttonText,
        iconClassName,
        onClick,
        className
    } = props
    return(
        <div className="No__Data">
            <div className={ className ? className + " No__Data__Container" : "No__Data__Container"}>
                <Fragment>
                    <figure>
                        <span className='NoDataImg'></span>
                    </figure>
                    <h1> { title ? I18n.t(title) : I18n.t('noitems_title')}</h1>
                    <p>
                        { message ? I18n.t(message) : I18n.t('noitems_message')}
                    </p>
                    {
                        buttonText ?
                        <ButtonWithLoading
                        onClick={onClick}
                        loading={false}
                        >
                            <span>
                                <i className={iconClassName} aria-hidden />
                            </span>
                            {I18n.t(buttonText)}
                        </ButtonWithLoading>
                        : null
                    }
                </Fragment>
            </div>
        </div>
    )
}

export default NoItems;