import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setLangEnglish, setLangArabic, setLocaleWithFallback } from '../../actions/langActions';
import { Translate, I18n } from "react-redux-i18n";
import { getSocial } from '../../actions/getsocialActions';
//import meeza from '../../imgs/Meeza.png';

export default function Footer() {
    const dispatch = useDispatch();

    const {
        lang,
        socialLinks,
    } = useSelector((state) => ({
        lang: state.lang.lang,
        socialLinks: state.websiteSocial.socialLinks,
    }));

    useEffect(() => {
        if(socialLinks.length <= 0){
            dispatch(getSocial());
        }
    }, [dispatch, socialLinks.length])

    function handlelangArabic(code) {
        const langSet = localStorage.getItem('lang');
        if (langSet){
            if (langSet === 'English'){
                dispatch(setLangArabic());
                dispatch(setLocaleWithFallback(code));
                //require('../../styles/styles_ar.scss');
                //window.location.reload(false);
            } else {
                return null;
            }
        }
    }
    function handlelangEnglish(code) {
        const langSet = localStorage.getItem('lang');
        if (langSet){
            if (langSet === 'English'){
                return null;
            } else {
                dispatch(setLangEnglish());
                dispatch(setLocaleWithFallback(code));
                //require('../../styles/styles_en.scss');
                //window.location.reload(false);
            }
        }
    }
    return (
        <footer className="footer sectionTopBottomSpaces">
            <div className="container">
                <nav>
                    <div className="footer__leftSide">
                        <figure>
                            <Link to="/" aria-label={I18n.t('lancersin_tech')}>
                                {
                                    lang === 'English' ?
                                    <span className='logoEn'></span>
                                    : 
                                    <span className='logoAr'></span>
                                }
                            </Link>
                        </figure>
                        {
                            lang === "English" ?
                            <button className="btn btn-primary post-project" onClick={() => handlelangArabic('ar')}>
                                <i aria-hidden className="icon-globe" />
                                العربية
                            </button>
                            :
                            <button className="btn btn-primary post-project" onClick={() => handlelangEnglish('en')}>
                                <i aria-hidden className="icon-globe" />
                                English
                            </button>
                        }
                        {
                            socialLinks.length > 0 ?
                            <ul className="socialmedia">
                                {
                                    socialLinks.map( (socialLink, index) => 
                                        <li key={index}> 
                                            <a className={socialLink.icon} href={socialLink.link} target="_blank" rel="noopener noreferrer" aria-label={socialLink.name ? socialLink.name : "Lancersin"}><span>{socialLink.name ? socialLink.name : "Lancersin"}</span></a>
                                        </li>
                                    )
                                }
                            </ul>
                            : null
                        }
                    </div>
                    
                    <div className="footer__rightSide">
                        <div className="list">
                            <p><Translate value='categories' /></p>
                            <ul>
                                <li><Link to="/projects"><Translate value='projects' /></Link></li>
                                {/*<li><Link to="/contests"><Translate value='contests' /></Link></li>*/}
                                <li><Link to="/services"><Translate value='services' /></Link></li>
                                <li><Link to="/portfolio"><Translate value='portfolio' /></Link></li>
                                <li><Link to="/freelancers"><Translate value='freelancers' /></Link></li>
                                {/*<li><Link to="/"><Translate value='lancersin_pro' /></Link></li>
                                <li><Link to="/">Project Management</Link></li>
                                <li><Link to="/">API for Developers</Link></li>*/}
                            </ul>
                        </div>

                        <div className="list">
                            <p><Translate value='about' /></p>
                            <ul>
                                <li> <a href="/about-us"><Translate value='about_us' /></a></li>
                                <li> <a href="/how-it-works"><Translate value='how_it_works' /></a></li>
                                {/*<li> <a href="/test"><Translate value='sitemap' /></a></li>*/}
                                <li> <Link to="/articles"><Translate value='articles' /></Link></li>
                                <li> <Link to="/contact-us"><Translate value='contact' /></Link></li>
                                {/*<li> <a href="/test"><Translate value='referral_program' /></a></li>*/}
                            </ul>
                        </div>

                        <div className="list">
                            <p><Translate value='terms' /></p>
                            <ul>
                                <li> <a href="/privacy-policy"><Translate value='privacy_policy' /></a></li>
                                <li> <a href="/terms-and-conditions"><Translate value='terms_conditions' /></a></li>
                                {/*<li> <a href="/test"><Translate value='copyright_policy' /></a></li>
                                <li> <a href="/test"><Translate value='code_of_conduct' /></a></li>
                                <li> <a href="/test"><Translate value='fees_charges' /></a> </li>*/}
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="copyright__bg">
                <div className="container">
                    <div className="copyright__container">
                        <p className="copyright">{I18n.t('copyright')} {(new Date().getFullYear())} {I18n.t('lancersin_tech')}</p>
                        <ul>
                            <li className='lazy-background visa visible'><span></span></li>
                            <li className='lazy-background master visible'><span></span></li>
                            <li className='lazy-background paybal visible'><span></span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}