import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';
import {
    GET_PAGES_LOADING,
    GET_PAGES_SUCCESS,
    GET_PAGES_FAIL
} from "./types";

export const getPages = (locale) => {
    return async function(dispatch){
        //Headers

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Accept-Language": locale ? locale : "en"
            }
        }
        dispatch(clearErrors());
        dispatch({
            type: GET_PAGES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/pages',
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_PAGES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_PAGES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if(error.response && error.response.data){
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_PAGES_FAIL'));
                dispatch ({ 
                    type: GET_PAGES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PAGES_FAIL')),
                    dispatch ({ 
                        type: GET_PAGES_FAIL 
                    })
                )
            }
        }
    }
};