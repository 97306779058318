import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Pagination from 'react-js-pagination';
import ConnectionLost from '../ConnectionLost';
import NoItems from '../NoItems';
import Spin from '../Spin';
import {I18n} from 'react-redux-i18n';
import Filteration from '../Filteration';
import {TitleComponent} from '../TitleComponent';
import {MetaComponent} from '../MetaComponent';

export default function FreelancersList() {
  const history = useHistory();
  const location = useLocation();
  const path =
    typeof window !== 'undefined' ? window.location.pathname : '/freelancers';
  const {
    loading,
    freelancers,
    totalRecords,
    itemsPerPage,
    errorId,
    errorMessage,
  } = useSelector(state => ({
    loading: state.freelancer.loadingFreelancers,
    freelancers: state.freelancer.freelancers,
    totalRecords: state.freelancer.totalRecords,
    itemsPerPage: state.freelancer.itemsPerPage,
    errorId: state.error.id,
    errorMessage: state.error.msg,
  }));

  const [activePage, setActivePage] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingFreelancersList, setLoadingFreelancersList] = useState(true);
  const [loadPageNumber, setLoadPageNumber] = useState(true);

  useEffect(() => {
    setLoadingFreelancersList(loading);
  }, [loading]);

  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);

  useEffect(() => {
    let url_string = '';
    let url = '';
    let page = '';
    if (loadPageNumber) {
      if (typeof window != 'undefined') {
        if (window.location.href.indexOf('page=') > -1) {
          url_string = window.location.href;
          url = new URL(url_string);
          if (url.searchParams) {
            page = url.searchParams.get('page');
            setActivePage(parseInt(page));
            setLoadPageNumber(false);
          }
        } else {
          setActivePage(1);
          history.push(`${path}?page=${1}`);
          setLoadPageNumber(false);
        }
      }
    } else {
      if (typeof window != 'undefined') {
        if (window.location.href.indexOf('page=') > -1) {
          url_string = window.location.href;
          url = new URL(url_string);
          if (url.searchParams) {
            page = url.searchParams.get('page');
            setActivePage(parseInt(page));
            setLoadPageNumber(false);
          }
        }
      }
    }
  }, [loadPageNumber, location, history, path]);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    if (typeof window != 'undefined') {
      var url_string = window.location.href;
      var url = new URL(url_string);
      if (url_string.indexOf('page=') > -1) {
        var search_params = url.searchParams;
        if (search_params) {
          search_params.set('page', pageNumber);
          url.search = search_params.toString();
          var new_url = url.pathname + url.search;
          history.push(new_url);
        }
      }
    }
  }

  return (
    <Fragment>
      <TitleComponent title={I18n.t('lancersin_freelancers')} />
      <MetaComponent />
      <div className="breadcrumb__container">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">{I18n.t('home')}</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {I18n.t('freelancers')}
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="container Projects__List__Container">
        <div className="row">
          <div className="col-12 mb-4">
            <div className="section__title my-0">
              <div className="section__title__text">
                <span className="icon icon-user-group"></span>
                <h1>{I18n.t('freelancers')}</h1>
              </div>
              <div>
                {open === false ? (
                  <button className="btn" onClick={() => setOpen(!open)}>
                    <span className="icon icon-search"></span>
                    <h3>{I18n.t('advanced_search')}</h3>
                  </button>
                ) : (
                  <button className="btn" onClick={() => setOpen(!open)}>
                    <span className="fas fa-times"></span>
                    <h3>{I18n.t('close_search')}</h3>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {loadPageNumber ? (
          <Spin />
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-12">
                <Filteration
                  page={activePage}
                  limit={30}
                  type="freelancers"
                  open={open}
                  setOpen={setOpen}
                />
              </div>
            </div>
            <div className="row Freelancers__List">
              {loadingFreelancersList ? (
                <Spin />
              ) : errorId === 'GET_FREELANCERS_FAIL' &&
                errorMessage === 'Network Error' ? (
                <div className="col-12">
                  <ConnectionLost
                    errorType="connection"
                    title="network_error_title"
                    message="network_error_message"
                  />
                </div>
              ) : errorId === 'GET_FREELANCERS_FAIL' ? (
                <div className="col-12">
                  <ConnectionLost
                    errorType="somethingWrong"
                    title="something_error_title"
                    message="something_error_message"
                  />
                </div>
              ) : freelancers.length > 0 && !loadingFreelancersList ? (
                <Fragment>
                  {freelancers.map(freelancer => (
                    <div className="col-md-6 col-lg-4 mb-4" key={freelancer.id}>
                      <div className="Profile-Card box-shadow">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="ProfileData">
                              <div className="ProfileImage">
                                <figure>
                                  <img
                                    src={
                                      freelancer.image.url
                                        ? freelancer.image.url
                                        : freelancer.image
                                    }
                                    alt={
                                      freelancer.fname + ' ' + freelancer.lname
                                    }
                                    className="img-fluid"
                                  />
                                </figure>
                              </div>
                              <div className="ProfileContent">
                                <h2 className="ProfileName">
                                  <Link
                                    to={{
                                      pathname: `/in/${freelancer.username}`,
                                    }}>
                                    {freelancer.fname + ' ' + freelancer.lname}
                                  </Link>
                                  {freelancer.price_per_hour ? (
                                    <Fragment>
                                      <span className="HourRate">
                                        {' ' + freelancer.price_per_hour}
                                      </span>
                                      <span>{I18n.t('dollar_per_hour')}</span>
                                    </Fragment>
                                  ) : null}
                                </h2>
                                <h3 className="ProfileTitle">
                                  {freelancer.position
                                    ? freelancer.position
                                    : null}
                                </h3>
                                {freelancer.country ? (
                                  <div className="LocationTime">
                                    <address>
                                      <span className="LocationFlag">
                                        <img
                                          src={freelancer.country.flag}
                                          alt={freelancer.country.name}
                                        />
                                      </span>
                                      <span className="Location">
                                        {freelancer.city.name}-
                                        {freelancer.country.name}
                                      </span>
                                    </address>
                                  </div>
                                ) : null}
                                {/*<div className="LastSeen">Last seen: 23 minutes ago</div>*/}
                              </div>
                              <Link
                                to={{
                                  pathname: `/in/${freelancer.username}`,
                                }}
                                className="btn btn-primary">
                                {I18n.t('view_profile')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 my-4">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={10}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </Fragment>
              ) : (
                <div className="col-12">
                  <NoItems />
                </div>
              )}
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
