import { 
    PURCHES_SERVICE_LOADING, 
    PURCHES_SERVICE_SUCCESS, 
    PURCHES_SERVICE_FAIL,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Purches Service

export const purchesservice = ({ service_id, features, qty }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: PURCHES_SERVICE_LOADING
        });
        const body = { service_id, features, qty };
        try {
            let response = await axiosInstance.post(
                '/api/services/purchase', 
                body, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: PURCHES_SERVICE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: PURCHES_SERVICE_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'PURCHES_SERVICE_FAIL'));
                dispatch ({ 
                    type: PURCHES_SERVICE_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'PURCHES_SERVICE_FAIL')),
                    dispatch ({ 
                        type: PURCHES_SERVICE_FAIL 
                    })
                )
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}