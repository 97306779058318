import TYPES, { CLEAR_UPDATE_USER_PLANS_SUCCESS, CLEAR_SET_USER_VIEW_SUCCESS } from "../actions/types";
import {
  USER_LOADED,
} from '../../../actions/types';

const INITIAL_STATE = {
  getUserDataLoading: false,
  getUserDataError: "",
  getUserDataSuccess: false,
  user: null,
  updateUserPlansLoading: false,
  updateUserPlansSuccess: false,
  updateUserPlansError: "",
  setUserViewLoading: false,
  setUserViewSuccess: false,
  setUserViewError: "",
};

const {
  GET_USER_DATA_LOADING,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  UPDATE_USER_PLANS_LOADING,
  UPDATE_USER_PLANS_SUCCESS,
  UPDATE_USER_PLANS_FAIL,
  SET_USER_VIEW_LOADING,
  SET_USER_VIEW_SUCCESS,
  SET_USER_VIEW_FAIL,
} = TYPES;

export default function userDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USER_DATA_LOADING:
      return {
        ...state,
        getUserDataLoading: true,
        getUserDataError: "",
      };
    case GET_USER_DATA_SUCCESS:
      return {
        ...state,
        getUserDataLoading: false,
        getUserDataError: "",
        user: action.payload.data,
      };
    case USER_LOADED:
        return {
          ...state,
          getUserDataLoading: false,
          getUserDataError: "",
          user: action.payload.data,
        }
    case GET_USER_DATA_FAIL:
      return {
        ...state,
        getUserDataLoading: false,
        getUserDataError: action.error,
      };
    case UPDATE_USER_PLANS_LOADING:
      return {
        ...state,
        updateUserPlansLoading: true,
        updateUserPlansError: "",
      };
    case UPDATE_USER_PLANS_SUCCESS:
      return {
        ...state,
        updateUserPlansLoading: false,
        updateUserPlansError: "",
        updateUserPlansSuccess: action.payload.data === "success",
      };
    case UPDATE_USER_PLANS_FAIL:
      return {
        ...state,
        updateUserPlansLoading: false,
        updateUserPlansError: action.error,
      };
    case CLEAR_UPDATE_USER_PLANS_SUCCESS:
      return{
        ...state,
        updateUserPlansLoading: false,
        updateUserPlansError: "",
        updateUserPlansSuccess: false,
      }
      case SET_USER_VIEW_LOADING:
      return {
        ...state,
        setUserViewLoading: true,
        setUserViewError: "",
      };
    case SET_USER_VIEW_SUCCESS:
      return {
        ...state,
        setUserViewLoading: false,
        setUserViewError: "",
        setUserViewSuccess: action.payload.data === "success",
      };
    case SET_USER_VIEW_FAIL:
      return {
        ...state,
        setUserViewLoading: false,
        setUserViewError: action.error,
      };
    case CLEAR_SET_USER_VIEW_SUCCESS:
      return{
        ...state,
        setUserViewLoading: false,
        setUserViewError: "",
        setUserViewSuccess: false,
      }
    default:
      return state;
  }
}
