import {
    PURCHES_SERVICE_LOADING,
    PURCHES_SERVICE_SUCCESS, 
    PURCHES_SERVICE_FAIL,
    CLEAR_PURCHES_SERVICE_SUCCESS
} from '../actions/types';

const initialState = {
    loadingPurches: false,
    servicePurchesSuccess: false,
    invoiceData: {}
}

export default function purchesserviceReducer(state = initialState, action) {
    switch (action.type) {
        case PURCHES_SERVICE_LOADING:
            return {
                ...state,
                loadingPurches: true
            };
        case PURCHES_SERVICE_SUCCESS:
            return {
                ...state,
                loadingPurches: false,
                servicePurchesSuccess: action.payload.data === "success",
                invoiceData: action.payload.response
            };
        case PURCHES_SERVICE_FAIL:
            return {
                ...state,
                loadingPurches: false,
            };
        case CLEAR_PURCHES_SERVICE_SUCCESS:
            return{
                ...state,
                servicePurchesSuccess: false,
            };
        default:
            return state;
    }
}