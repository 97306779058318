import { 
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAIL,
    FOLLOW_USER_LOADING,
    FOLLOWERS_SUCCESS,
    FOLLOWERS_FAIL,
    FOLLOWERS_LOADING,
    FOLLOWINGS_SUCCESS,
    FOLLOWINGS_FAIL,
    FOLLOWINGS_LOADING,
    UNFOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_FAIL,
    UNFOLLOW_USER_LOADING,
    CLEAR_FOLLOW_USER_SUCCESS,
    CLEAR_UNFOLLOW_USER_SUCCESS
} from '../actions/types';

const initialState = {
    followersLoading: false,
    followingsLoading: false,
    followUserLoading: false,
    unFollowUserLoading: false,
    followDone: false,
    unfollowDone: false,
    followers: [],
    followings: []
}

export default function followReducer(state = initialState, action) {
    switch (action.type) {
        case FOLLOW_USER_LOADING:
            return {
                ...state,
                followUserLoading: true
            };
        case FOLLOW_USER_SUCCESS:
            return {
                ...state,
                followUserLoading: false,
                followDone: action.payload.data === "success"
            };
        case FOLLOW_USER_FAIL:
            return {
                ...state,
                followUserLoading: false,
            };
        case CLEAR_FOLLOW_USER_SUCCESS:
            return{
                ...state,
                followDone: false
            };
        case UNFOLLOW_USER_LOADING:
            return {
                ...state,
                unFollowUserLoading: true
            };
        case UNFOLLOW_USER_SUCCESS:
            return {
                ...state,
                unFollowUserLoading: false,
                unfollowDone: action.payload.data === "success"
            };
        case UNFOLLOW_USER_FAIL:
            return {
                ...state,
                unFollowUserLoading: false,
            };
        case CLEAR_UNFOLLOW_USER_SUCCESS:
            return{
                ...state,
                unfollowDone: false
            };    
        case FOLLOWERS_LOADING:
            return {
                ...state,
                followersLoading: true,
            };
        case FOLLOWERS_SUCCESS:
            return {
                ...state,
                followersLoading: false,
                followers: action.payload.data
            };
        case FOLLOWERS_FAIL:
            return {
                ...state,
                followersLoading: false,
        };
        case FOLLOWINGS_LOADING:
            return {
                ...state,
                followingsLoading: true,
            };
        case FOLLOWINGS_SUCCESS:
            return {
                ...state,
                followingsLoading: false,
                followings: action.payload.data
            };
        case FOLLOWINGS_FAIL:
            return {
                ...state,
                followingsLoading: false,
        };
        default:
            return state;
    }
}