import TYPES, { CLEAR_FEEDBACKS } from "../actions/types";

const INITIAL_STATE = {
    //get service feedback
    getFeedbacksLoading: false,
    getFeedbacksError: "",
    feedbacks: null,
    feedbacksPerPage: 0,
    totalFeedbacks: 0,
};

const {
    // GET Feedbacks
    GET_FEEDBACK_LOADING,
    GET_FEEDBACK_SUCCESS,
    GET_FEEDBACK_FAIL,
} = TYPES;

export default function feedbacksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET Feedbacks
    case GET_FEEDBACK_LOADING:
      return{
        ...state,
        getFeedbacksLoading: true,
      };
    case GET_FEEDBACK_SUCCESS:
      return{
        ...state,
        getFeedbacksLoading: false,
        feedbacks: action.payload.data,
        feedbacksPerPage: action.payload.itemsPerPage,
        totalFeedbacks: action.payload.totalRecords,
      };
    case GET_FEEDBACK_FAIL:
      return{
        ...state,
        getFeedbacksLoading: false,
        getFeedbacksError: action.error,
      };
    case CLEAR_FEEDBACKS:
      return{
        ...state,
        feedbacks: null,
        feedbacksPerPage: 0,
        totalFeedbacks: 0,
      }
    default:
      return state;
  }
}
