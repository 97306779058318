import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { I18n } from "react-redux-i18n";
import RatingStarsPreview from "../RatingStarsPreview";
import StatusLabel from "../../StatusLabel";
import { deleteFavourite } from "../../redux/favourite/actions/deleteActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";

export default function Card({ hide, item, deleteButton }) {
  const dispatch = useDispatch();

  var {

    //Delete from favourite
    deletingFavouriteLoading,

    locale
  } = useSelector((state) => ({

    deletingFavouriteLoading: state.favourites.deletingFavouriteLoading,

    locale: state.i18n.locale,
  }));

  const createMarkup = (text) => {
    return { __html: text };
  };
  function deleteFromFavourite(){
    const morphType = "projects";
    const id = item.id;
    dispatch(deleteFavourite(
      morphType,
      id,
      locale
    ));
  }

  return (
    <div className="col-12">
      <div className="Dashboard__Project">
        <h2>
          <div>
            <Link
              to={{ pathname: `/projects/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }}
            >
              {item.title}
            </Link>
            <StatusLabel status_id={item.status.id} type="project" />
          </div>
          <div className="Dashboard__Project__Budget">
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{I18n.t('project_budget')}</Tooltip>}>
              <div className="Owner__Name">
                <span className="icon icon-budget"></span>{item.price} {I18n.t('dollar')}
              </div>
            </OverlayTrigger>
          </div>
        </h2>
        <div className="Dashboard__Project__Category">
          {
            item.category ? 
            <div className="Owner__Name">
              <span className={`icon  ${item.category.icon}`}></span>{item.category.title}
            </div>
            : null
          }
          {
            item.sub_category ?
            <div className="Owner__Name">
              <span className={`icon ${item.sub_category.icon}`}></span>{item.sub_category.title}
            </div>
            : null
          }
        </div>
        {
          item.description ?
          <div className="Dashboard__Project__Description" dangerouslySetInnerHTML={createMarkup(
            item.description.replace(/(<([^>]+)>)/gi, "")
          )}>
          </div>
          : null
        }
        {
          item.skills ?
          <ul className="SkillList">
          { 
            item.skills.map( (item, index) => 
              <li key={index}>
                {item}
              </li>
            )
          }
          </ul>
          : null
        }
        <div className="Dashboard__Project__Owner">
          <div className="Owner__Name__Rate">
            <Link
              to={{ pathname: `/in/${item.user.username}` }}
            >
            <span className="Owner__Image"><img src={item.user.image} alt={item.user.fname + " " + item.user.lname} /></span>
            <div className="Owner__Content">
              {item.user.fname} {item.user.lname}
              <RatingStarsPreview rate={item.user.rate} />
            </div>
            </Link>
          </div>
          {
            item.user.country && item.user.city ?
            <div className="Owner__Name">
              <span className="Country__Flag"><img src={item.user.country.flag} alt={item.user.country.name} /> {item.user.city.name}, {item.user.country.name}</span>
            </div>
            : null
          }
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{I18n.t('created_at')}</Tooltip>}>
            <div className="Owner__Name">
              <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
            </div>
          </OverlayTrigger>
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{I18n.t('bids_sent')}</Tooltip>}>
            <div className="Owner__Name">
              <span className="icon icon-proposals-sent"></span>
              {
                item.bids_count ?
                item.bids_count
                : I18n.t('no_bids')
              }
            </div>
          </OverlayTrigger>
          {
            item.bids_average ?
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{I18n.t('bids_average')}</Tooltip>}>
              <div className="Owner__Name">
                <span className="icon icon-budget"></span>{Math.round(item.bids_average) + " " + I18n.t('dollar')}
              </div>
            </OverlayTrigger>
            : null
          }
        </div>
        {
          deleteButton ?
          <ButtonWithLoading
            variant="danger"
            type="submit"
            data-backdrop="static"
            loading={deletingFavouriteLoading}
            onClick={() => deleteFromFavourite()}
            className="mb-2"
          >
            <span className="fas fa-heart"></span>
            {I18n.t('delete_from_fav')}
          </ButtonWithLoading>
          : null
        }
      </div>
    </div>
  );
}
