// Contests
const CONTESTS = "/api/contests";
const CLOSE_CONTEST = CONTESTS + "/close";

// Contests Bids
const BIDS = CONTESTS + "/bids";
const BIDS_LIKE = BIDS + "/like";
const BIDS_RATE = BIDS + "/rate";
const BIDS_COMMENT = BIDS + "/comments";
const BIDS_USERS = BIDS + "/users";

export default {
  CONTESTS,
  CLOSE_CONTEST,
  BIDS,
  BIDS_LIKE,
  BIDS_RATE,
  BIDS_COMMENT,
  BIDS_USERS,
};
