const MESSAGES = "/api/conversations";
const REPLIES = MESSAGES + "/replies";
const REPLYADMIN = MESSAGES + "/reply-to-admin";
const READ = MESSAGES + "/mark-as-read";

const exportedObject = {
  MESSAGES,
  REPLIES,
  REPLYADMIN,
  READ
};

export default exportedObject;
