import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Num } from "../Numbers";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { deleteFavourite } from "../../redux/favourite/actions/deleteActions";
import { I18n } from "react-redux-i18n";

export default function Card({ item, deleteButton }){
    const dispatch = useDispatch();

    const {
        locale,

        //Delete from favourite
        deletingFavouriteLoading,

      } = useSelector((state) => ({
        locale: state.i18n.locale,
        
        deletingFavouriteLoading: state.favourites.deletingFavouriteLoading,
      }));

    function deleteFromFavourite(){
        const morphType = "services";
        const id = item.id;
        dispatch(deleteFavourite(
          morphType,
          id,
          locale
        ));
    }
    return(
        <div className="col-md-6 col-lg-4" key={item.id}>
            <div className={deleteButton ? "services__listitem box-shadow containsRemove" : "services__listitem box-shadow"}>
                <figure>
                    <Link className="services__imgcontainer" to={{pathname:`/services/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                        <img src={item.image.url ? item.image.url : item.image} alt={item.title} />
                    </Link>
                    <figcaption>
                        <div className="Service__Provider">
                            <div className="img-container">
                                <img src={item.user.image.url ? item.user.image.url : item.user.image} alt={item.user.fname + ' ' + item.user.lname} className="img-fluid" />
                            </div>
                            <p>
                                <Link to={{pathname:`/in/${item.user.username}`}}>{item.user.fname} {item.user.lname}</Link>
                            </p>
                        </div>
                        <h2>
                            <Link to={{pathname:`/services/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>{item.title}</Link>
                            {
                                deleteButton ?
                                <ButtonWithLoading
                                    variant="danger"
                                    type="submit"
                                    data-backdrop="static"
                                    loading={deletingFavouriteLoading}
                                    onClick={() => deleteFromFavourite()}
                                    className="mb-2"
                                    >
                                    <span className="fas fa-heart"></span>
                                    {I18n.t('delete_from_fav')}
                                </ButtonWithLoading>
                                : null
                            }
                        </h2>
                    </figcaption>
                </figure>
                <div className="services__info">
                    <div className="bought">
                        <span className="icon icon-deal"></span>{Num(item.purchasesCount)}
                    </div>
                    <div className="price">
                    <span className="icon icon-price-label"></span>{item.price}$
                    </div>
                </div>
            </div>
        </div>
    )
}