import { GET_SERVICES_SUCCESS, SERVICES_ITEMS_LOADING, GET_SERVICES_FAIL } from '../actions/types';

const initialState = {
    services: [],
    loadingservices: false
}

export default function freelancerservicesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SERVICES_SUCCESS:
            return{
                ...state,
                services: action.payload,
                loadingservices: false
            }    
        ;
        case SERVICES_ITEMS_LOADING: 
            return{
                ...state,
                loadingservices: true
            }
        ;
        case GET_SERVICES_FAIL:
            return{
                ...state,
                loadingservices: false
            }
        ;
        default:
            return state;
    }
}