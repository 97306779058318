import React, {Fragment} from 'react';
import Pagination from "react-js-pagination";
import { useSelector } from 'react-redux';
import NoItems from '../NoItems';
import Spin from '../Spin';
import ProjectCard from "../ProjectCard";

export default function Projects(props){

    const {
        totalProjects,
        projectsPerPage,
        getProjectsLoading,
        projects = [],
    } = useSelector((state) => ({
        totalProjects: state.project.totalProjects,
        projectsPerPage: state.project.projectsPerPage,
        getProjectsLoading: state.project.getProjectsLoading,
        projects: state.project.projects,
    }));

    const {
        activePage,
        setActivePage
    } = props;
    
    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
    }

    const totalItemsCount = totalProjects;
    const itemsCountPerPage = Number(projectsPerPage);
    
    return(
        <Fragment>
            <div className="ShowCase__List">
                <div className="row">
                    {
                        getProjectsLoading ?
                            <div className="fullPageLoading">
                                <Spin />
                            </div>
                        : projects && projects.length > 0 ?
                            <Fragment>
                                {
                                    projects.map( project => (
                                        <ProjectCard key={`project-#${project.id}`} item={project} />
                                    ))
                                }
                                <div className="col-12 my-4">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={10}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </Fragment>
                        :
                        <NoItems
                            title="no_projects_found"
                            message="no_projects_message_with_no_action"
                        />
                    }
                </div>
            </div>
        </Fragment>
    )
}