import React, {Fragment, useEffect, useState} from 'react';
import {useLocation, useHistory} from 'react-router-dom';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {register} from '../../actions/authActions';
import {clearErrors} from '../../actions/errorActions';
import {Translate, I18n} from 'react-redux-i18n';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {CLEAR_REGISTER} from '../../actions/types';
import GRecaptch from '../GRecaptch';
import {WebsiteLink} from '../Server';
import ConfToast from '../ConfToast';
const {Control} = Form;
const {Feedback} = Control;

function Signup() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [plans, setPlans] = useState([]);
  const [localErrors, setLocalErrors] = useState({});
  const [isHuman, setIsHuman] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [prevPath, setPrevPath] = useState(null);

  const {
    isAuthenticated,
    registerSuccess,
    errorMsg: {errors = {}},
    registerLoading,
    savedProject,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    registerSuccess: state.auth.registerSuccess,
    errorMsg: state.error.msg,
    registerLoading: state.auth.registerLoading,
    savedProject: state.project.savedProject,
  }));

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     history.push('/');
  //   }
  // }, [isAuthenticated, history]);

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.href.indexOf('path=') > -1) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        if (url.searchParams) {
          var path = url.searchParams.get('path');
          setPrevPath(path);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (registerSuccess) {
      if (savedProject && Object.keys(savedProject).length > 0) {
        history.push('/projects/create');
        setTimeout(() => {
          history.push('/verify-account');
        }, 10000);
        dispatch({
          type: CLEAR_REGISTER,
        });
      } else {
        history.push('verify-account');
      }
    }
  }, [registerSuccess, history, dispatch, location]);

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    array = false,
  }) {
    if (currentValue && !array) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else if (currentValue && array) {
      if (currentValue.length > 0) {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: message,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function ValidateEmail(mail) {
    const regex =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!mail || regex.test(mail) === false) {
      if (!mail) {
        setLocalErrors(errors => ({
          ...errors,
          email: I18n.t('email_required'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          email: I18n.t('enter_valid_email'),
        }));
      }
      return false;
    }
    setLocalErrors(errors => ({
      ...errors,
      email: undefined,
    }));
    return true;
  }

  function onFnameChange(e) {
    setFname(e.target.value);
  }

  function onFnameBlur() {
    handleItemLocalError({
      propName: 'fname',
      currentValue: fname,
      message: I18n.t('fname_required'),
    });
  }

  function onLnameChange(e) {
    setLname(e.target.value);
  }

  function onLnameBlur() {
    handleItemLocalError({
      propName: 'lname',
      currentValue: lname,
      message: I18n.t('lname_required'),
    });
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onEmailBlur() {
    ValidateEmail(email);
  }

  function onUsernameChange(e) {
    setUsername(e.target.value);
  }

  function onlyLettersAndSpaces(str) {
    return /^[a-zA-Z0-9_-]*$/.test(str);
  }

  function onUsernameBlur() {
    if (username) {
      if (
        !onlyLettersAndSpaces(username) ||
        username.endsWith('-') ||
        username.endsWith('_')
      ) {
        setLocalErrors(errors => ({
          ...errors,
          username: I18n.t('username_regex_error'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          username: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        username: I18n.t('username_required'),
      }));
    }
  }

  function onPasswordChange(e) {
    setPassword(e.target.value);
  }

  function onPasswordBlur() {
    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
  }

  function onRePasswordChange(e) {
    setRepassword(e.target.value);
  }

  function onRePasswordBlur() {
    if (repassword) {
      if (repassword !== password) {
        setLocalErrors(errors => ({
          ...errors,
          repassword: I18n.t('repassword_password_required'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          repassword: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        repassword: I18n.t('repassword_required'),
      }));
    }
  }

  function onChangePlans(e) {
    const accountType = e.target.value;
    if (plans.includes(accountType)) {
      const index = plans.indexOf(accountType);
      if (index > -1) {
        plans.splice(index, 1);
      }
    } else {
      setPlans(plans.concat(accountType));
    }
  }

  function handleToast(showToast, title, message, status, delay) {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function onSubmit(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'fname',
      currentValue: fname,
      message: I18n.t('fname_required'),
    });
    handleItemLocalError({
      propName: 'lname',
      currentValue: lname,
      message: I18n.t('lname_required'),
    });
    ValidateEmail(email);
    onUsernameBlur();
    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
    if (repassword) {
      if (repassword !== password) {
        setLocalErrors(errors => ({
          ...errors,
          repassword: I18n.t('repassword_password_required'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          repassword: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        repassword: I18n.t('repassword_required'),
      }));
    }
    handleItemLocalError({
      propName: 'plans',
      currentValue: plans,
      message: I18n.t('account_type_required'),
      array: true,
    });

    const referrer = localStorage.getItem('referrer');

    if (
      fname &&
      lname &&
      password &&
      repassword &&
      email &&
      username &&
      plans.length > 0 &&
      isHuman &&
      !localErrors.fname &&
      !localErrors.lname &&
      !localErrors.password &&
      !localErrors.repassword &&
      !localErrors.email &&
      !localErrors.username &&
      !localErrors.plans
    ) {
      //create user object
      const newUser = {
        fname,
        lname,
        password,
        email,
        username,
        plans,
        referrer,
      };
      //attempt to register
      dispatch(clearErrors());
      dispatch(register(newUser));
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function handleSignin() {
    if (prevPath !== null) {
      history.push(`/signin?path=${prevPath}`);
    } else {
      history.push('/signin');
    }
  }
  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="container">
        <div className="SignContainer">
          <header>
            <h1 className="mb-1">{I18n.t('signup')}</h1>
            <p className="text-center my-3">{I18n.t('watch_register_video')}</p>
            <a
              href="https://youtu.be/FEmwhTHJAew"
              target="_blank"
              rel="noopener noreferrer"
              className="videoBTn btn-success mb-4">
              <i className="fas fa-play"></i> {I18n.t('watch_video')}
            </a>
          </header>

          <form>
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('first_name')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="text"
                    name="fname"
                    id="fname"
                    className="form-control"
                    placeholder={I18n.t('first_name')}
                    onChange={onFnameChange}
                    onBlur={onFnameBlur}
                  />
                  {errors.fname ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.fname[0]}
                    </Feedback>
                  ) : localErrors.fname ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.fname}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('last_name')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    id="lname"
                    className="form-control"
                    placeholder={I18n.t('last_name')}
                    onChange={onLnameChange}
                    onBlur={onLnameBlur}
                  />
                  {errors.lname ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.lname[0]}
                    </Feedback>
                  ) : localErrors.lname ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.lname}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('email_address')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="form-control"
                    placeholder={I18n.t('email_address')}
                    onChange={onEmailChange}
                    onBlur={onEmailBlur}
                  />
                  {errors.email ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.email[0]}
                    </Feedback>
                  ) : localErrors.email ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.email}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('username')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="form-control"
                    placeholder={I18n.t('username')}
                    onChange={onUsernameChange}
                    onBlur={onUsernameBlur}
                  />
                  <small>
                    {I18n.t('profile_link')} {WebsiteLink + '/in/' + username}
                  </small>
                  {localErrors.username ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.username}
                    </Feedback>
                  ) : errors.username ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.username[0]}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('password')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder={I18n.t('password')}
                    onChange={onPasswordChange}
                    onBlur={onPasswordBlur}
                  />
                  {errors.password ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.password[0]}
                    </Feedback>
                  ) : localErrors.password ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.password}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div class="form-group">
                  <label>
                    {I18n.t('repassword')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="password"
                    name="repassword"
                    id="repassword"
                    className="form-control"
                    placeholder={I18n.t('repassword')}
                    onChange={onRePasswordChange}
                    onBlur={onRePasswordBlur}
                  />
                  {localErrors.repassword ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.repassword}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <label>
                  {I18n.t('account_type')}{' '}
                  <small className="required">({I18n.t('required')})</small>
                </label>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="Freelancer"
                      name="freelancer"
                      value="1"
                      className="form-check-input"
                      onChange={onChangePlans}
                    />
                    <label
                      title=""
                      htmlFor="Freelancer"
                      className="form-check-label">
                      {I18n.t('freelancer')}{' '}
                      <small>{I18n.t('freelancer_description')}</small>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="form-group">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id="Client"
                      name="client"
                      value="3"
                      className="form-check-input"
                      onChange={onChangePlans}
                    />
                    <label
                      title=""
                      htmlFor="Client"
                      className="form-check-label">
                      {I18n.t('client')}{' '}
                      <small>{I18n.t('client_description')}</small>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                {errors.plans ? (
                  <Feedback type="invalid" className="d-block mt-0 mb-2">
                    {errors.plans[0]}
                  </Feedback>
                ) : localErrors.plans ? (
                  <Feedback type="invalid" className="d-block mt-0 mb-2">
                    {localErrors.plans}
                  </Feedback>
                ) : null}
              </div>
              <div className="col-12 mb-1">
                <GRecaptch setIsHuman={setIsHuman} className="mb-2" />
              </div>
              <div className="col-12 mb-3">
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  onClick={onSubmit}
                  data-backdrop="static"
                  loading={registerLoading}>
                  <Translate value="join_now" />
                </ButtonWithLoading>
              </div>
            </div>
          </form>

          <aside>
            <p>
              {I18n.t('by_registering')}{' '}
              <a href="/terms-and-conditions">{I18n.t('terms_conditions')}</a>{' '}
              {I18n.t('and')}{' '}
              <a href="/privacy-policy">{I18n.t('privacyـpolicy')}</a>,{' '}
              {I18n.t('signup_consent')}
            </p>
          </aside>

          <footer>
            <p>
              {I18n.t('have_account')}
              <button onClick={() => handleSignin()}>{I18n.t('signin')}</button>
            </p>
          </footer>
        </div>
      </div>
    </Fragment>
  );
}

export default Signup;
