import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { I18n } from 'react-redux-i18n';
import { cancelServiceInvoice } from "../../redux/services/actions/addActions";
import QuillBox from "../QuillBox";
import { CLEAR_CANCEL_SERVICE_INVOICE_SUCCESS } from "../../redux/services/actions/types";
import { clearErrors } from "../../actions/errorActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import GRecaptch from "../GRecaptch";

export default function CancelInvoiceModal(props){
    const dispatch = useDispatch();

    const {
        errorsId,
        errorsMsg,
        cancelServiceInvoiceSuccess,
      } = useSelector((state) => ({
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
        cancelServiceInvoiceSuccess: state.service.cancelServiceInvoiceSuccess,
    }));

    const [report, setReport] = useState("");
    const [errors, setErrors] = useState([]);
    const [localErrors, setLocalErrors] = useState({});
    const [isHuman, setIsHuman] = useState(false);

    const {
        invoice_id,
        loading,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay,
        onHide,
        invoiceStatusId
    } = props;

    const handleHide = useCallback(() => {
        setLocalErrors({});
        onHide();
        dispatch(clearErrors());
    }, [dispatch, onHide]);

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

    useEffect(() => {
        if (Object.keys(errorsMsg).length > 0){
          setErrors(errorsMsg.errors);
        };
    }, [errorsMsg]);

    useEffect(() => {
        if (cancelServiceInvoiceSuccess){
            handleHide();
            handleToast(true, I18n.t('cancel_order'), I18n.t('cancel_order_succes'), 'Toast__Container__Success', 6000);
            dispatch({
                type: CLEAR_CANCEL_SERVICE_INVOICE_SUCCESS
            });
            setReport("");
            setErrors([]);
            setLocalErrors({});
            setIsHuman(false);
        };
    }, [cancelServiceInvoiceSuccess, dispatch, handleHide, handleToast]);

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: undefined,
            }));
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: message,
            }));
        }
    }

    function handleReportChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setReport("");
        } else {
            setReport(value);
        }
    }

    function onReportBlur(){
        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('explanation_field_required'),
        });
    }

    function onSubmit(e) {
        e.preventDefault();

        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('explanation_field_required'),
        });
        if(
            report !== "" &&
            isHuman
            ){
            const reportData = {
            report,
            invoice_id,
            };
            dispatch(cancelServiceInvoice(reportData));
        } else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
    };

    return(
        <Modal
            className={props.className}
            size={props.size}
            show={props.show}
            onHide={props.onHide}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            aria-labelledby={props["aria-labelledby"]}
            >
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18n.t('cancel_order')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        invoiceStatusId === 5 ?
                        <div className="alert alert-warning" role="alert">
                            {I18n.t('cancellation_request_to_seller')}
                        </div>
                        : invoiceStatusId === 4 ?
                        <div className="alert alert-warning" role="alert">
                            {I18n.t('cancellation_request_to_admin')}
                        </div>
                        : null
                    }
                    <QuillBox
                        required
                        controlId="Description"
                        label="explain_cancel_order"
                        value={report}
                        onChange={handleReportChange}
                        onBlur={onReportBlur}
                        feedbackType="invalid"
                        feedback={localErrors.report ? localErrors.report : null}
                        isInvalid={(errorsId === "CANCEL_SERVICE_INVOICE_FAIL" && errors.report) || localErrors.report}
                    />

                    <GRecaptch
                        setIsHuman={setIsHuman}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonWithLoading
                        variant="primary"
                        type="submit"
                        onClick={onSubmit}
                        data-backdrop="static"
                        loading={loading ? loading : null}
                    >
                        {I18n.t('submit')}
                    </ButtonWithLoading>
                    <Button
                        variant="secondary"
                        className="btn btn-secondary"
                        onClick={handleHide}
                    >
                        {I18n.t('close')}
                    </Button>
                </Modal.Footer>
            </Form>
            </Modal>
    )
}