import React from 'react';
import {Form, InputGroup, Alert} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';

const {Group, Label, Control} = Form;
const {Feedback} = Control;
const {Prepend, Text, Append} = InputGroup;

export default function TextInput({
  name,
  onChange,
  onBlur,
  controlId = '',
  label = '',
  desc = '',
  type = 'text',
  value = '',
  feedback = '',
  feedbackType = 'invalid',
  placeholder = '',
  prepandText = '',
  appendText = '',
  required = true,
  isInvalid = false,
  disabled = false,
  inputExtraProps = {},
  warning = '',
  warningType = '',
  className = '',
  minlength = '',
  characterCount = '',
}) {
  return (
    <Group controlId={controlId}>
      <Label>
        <Translate value={label} />
        {required ? (
          <small className="required"> ({I18n.t('required')})</small>
        ) : (
          <small className="optional"> ({I18n.t('optional')})</small>
        )}
      </Label>
      {warning ? <Alert variant={warningType}>{I18n.t(warning)}</Alert> : null}

      {desc ? (
        <div className="alert alert-primary" role="alert">
          <Translate value={desc} />
        </div>
      ) : null}

      <InputGroup
        className={
          className
            ? className.includes('mobile')
              ? `mb-2 ${className}`
              : 'mb-2'
            : 'mb-2'
        }>
        {prepandText ? (
          <Prepend>
            <Text>
              <Translate value={prepandText} />
            </Text>
          </Prepend>
        ) : null}

        <Control
          disabled={disabled}
          type={type}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          isInvalid={isInvalid}
          onWheel={type === 'number' ? e => e.target.blur() : null}
          placeholder={placeholder}
          className={appendText ? 'hasAppend' : null}
          {...inputExtraProps}
        />

        {appendText ? (
          <Append>
            <Text>
              {typeof appendText === 'string' ? (
                <Translate value={appendText} />
              ) : (
                appendText
              )}
            </Text>
          </Append>
        ) : null}

        {minlength ? (
          <small>
            {I18n.t('min_character')} {characterCount} / {minlength}
          </small>
        ) : null}

        {isInvalid && feedback ? (
          <Feedback type={feedbackType}>{feedback}</Feedback>
        ) : null}
      </InputGroup>
    </Group>
  );
}
