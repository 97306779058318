import React, { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import {Num} from '../Numbers';
import { I18n } from "react-redux-i18n";
import Spin from '../Spin';
import NoItems from "../NoItems";
import Filteration from '../Filteration';
import AddingPortfolioItem from "../AddingPortfolioModal";
import ConfToast from "../ConfToast";
import { clearErrors } from '../../actions/errorActions';
import ConnectionLost from "../ConnectionLost";
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";

export default function ShowCaseList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const path = typeof window !== 'undefined' ? window.location.pathname : '/portfolio';
    const {
        user,
        lang,
        isAuthenticated,
        allportfolios,
        totalRecords,
        itemsPerPage,
        loadingallportfolios,
        isLoading,
        errorId,
        errorMessage,
    } = useSelector( state => ({
        user: state.userData.user,
        lang: state.lang.lang,
        isAuthenticated: state.auth.isAuthenticated,
        allportfolios: state.portfolios.allportfolios,
        totalRecords: state.portfolios.totalRecords,
        itemsPerPage: state.portfolios.itemsPerPage,
        loadingallportfolios: state.portfolios.loadingallportfolios,
        isLoading: state.addportfolioitem.isLoading,
        errorId: state.error.id,
        errorMessage: state.error.msg,
    }));

    const [activePage, setActivePage] = useState(1);
    const [open, setOpen] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [loadingPortfolios, setLoadingPortfolios] = useState(true);
    const [isClient, setIsClient] = useState(false);
    const [loadPageNumber, setLoadPageNumber] = useState(true);

    useEffect(() => {
        setLoadingPortfolios(loadingallportfolios);
    }, [loadingallportfolios]);

    useEffect(() => {
        let url_string = "";
        let url = "";
        let page = "";
        if(loadPageNumber){
            if(typeof window != 'undefined'){
                if(window.location.href.indexOf("page=") > -1){
                    url_string = window.location.href; 
                    url = new URL(url_string);
                    if(url.searchParams){
                        page = url.searchParams.get("page");
                        setActivePage(parseInt(page));
                        setLoadPageNumber(false);
                    }
                }else{
                    setActivePage(1);
                    history.push(`${path}?page=${1}`);
                    setLoadPageNumber(false);
                }
            }
        }else{
            if(typeof window != 'undefined'){
                if(window.location.href.indexOf("page=") > -1){
                    url_string = window.location.href; 
                    url = new URL(url_string);
                    if(url.searchParams){
                        page = url.searchParams.get("page");
                        setActivePage(parseInt(page));
                        setLoadPageNumber(false);
                    }
                }
            }
        }
    }, [loadPageNumber, location, history, path]);

    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
        if(typeof window != 'undefined'){
            var url_string = window.location.href; 
            var url = new URL(url_string);
            if(url_string.indexOf("page=") > -1){
                var search_params = url.searchParams;
                if(search_params){
                  search_params.set('page', pageNumber);
                  url.search = search_params.toString();
                  var new_url = url.pathname + url.search;
                  history.push(new_url);
                }
            }
        }
    }
    //Get Portfolios
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };

    useEffect(() => {
        if(user && user.memberships && user.memberships.length >= 1){
            user.memberships.map(item => {
                if((item.id === 3 || item.id === 4) && item.default){
                    return setIsClient(true);
                }else{
                    return setIsClient(false);
                }
            })
        }
    }, [user]);

    return(
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <TitleComponent title={I18n.t('lancersin_portfolio')} />
            <MetaComponent />
            <AddingPortfolioItem 
                modalTitle={"add_portfolio_item_title"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                isLoading={isLoading}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            <div className="breadcrumb__container">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{I18n.t('portfolio')}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container Projects__List__Container">
                <div className="row">
                    <div className="col-12 mb-4">
                        <div className="section__title my-0">
                            <div className="section__title__text">
                                <span className="icon icon-business-case"></span>
                                <h1>{I18n.t('portfolio')}</h1>
                            </div>
                            <div>
                                {
                                isAuthenticated && !isClient?
                                <button className="btn mx-3" onClick={() => {handleShow()}}>
                                    <span className="icon icon-plus"></span>
                                    <h2>{I18n.t('add_portfolio_item')}</h2>
                                </button>
                                : null
                                }
                                {
                                    open === false ?
                                    <button className="btn" onClick={() => setOpen(!open)}>
                                        <span className="icon icon-search"></span>
                                        <h3>{I18n.t('advanced_search')}</h3>
                                    </button>
                                    :
                                    <button className="btn" onClick={() => setOpen(!open)}>
                                        <span className="fas fa-times"></span>
                                        <h3>{I18n.t('close_search')}</h3>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div> 
                {
                    loadPageNumber ?
                    <Spin />
                    :
                    <Fragment>
                        <div className="row">
                            <div className="col-12">
                                <Filteration 
                                    page={activePage} 
                                    limit={30}
                                    type="portfolio" 
                                    open={open} 
                                    setOpen={setOpen} 
                                    setConfShow={setConfShow}
                                    setConfTitle={setConfTitle}
                                    setConfMessage={setConfMessage}
                                    setConfStatus={setConfStatus}
                                    setDelay={setDelay}
                                />
                            </div>
                        </div>
                        <div className="ShowCase__List">
                            <div className="row">
                                {
                                    loadingPortfolios ?
                                        <Spin />
                                    : errorId === "GET_ALL_PORTFOLIOS_FAIL" && errorMessage === "Network Error" ?
                                    <div className="col-12">
                                        <ConnectionLost
                                        errorType= "connection"
                                        title = "network_error_title"
                                        message = "network_error_message"
                                        />
                                    </div>
                                    : errorId === "GET_ALL_PORTFOLIOS_FAIL" ?
                                    <div className="col-12">
                                        <ConnectionLost
                                        errorType= "somethingWrong"
                                        title = "something_error_title"
                                        message = "something_error_message"
                                        />
                                    </div> 
                                    : allportfolios.length > 0 && !loadingPortfolios ?
                                    <Fragment>
                                        {
                                            allportfolios.map( item => (
                                            <div className="col-md-6 col-lg-4" key={item.id}>
                                                <div className="freelancers__listItem box-shadow">
                                                    <figure>
                                                        <Link to={{pathname:`/portfolio/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                            <p className="portfolio__title">{item.title}</p>
                                                            <img src={item.image} alt={item.title} className="img-fluid" />                                        
                                                        </Link>
                                                    </figure>
                                                    <figure>
                                                        <figcaption>
                                                            <div className="owner__profile">
                                                                <Link to={{pathname:`/in/${item.user.username}`}}>
                                                                    <span className="img-container">
                                                                        <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} className="img-fluid" />
                                                                    </span>
                                                                    {item.user.fname} {item.user.lname}
                                                                </Link>
                                                            </div>
                                                            <p className="icon-like">
                                                                {Num(item.likes_count)}
                                                            </p>
                                                        </figcaption>
                                                    </figure>
                                                    <Link to={{pathname:`/portfolio/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}><i className={lang === "English" ? "icon-arrow-right goToTarget" : "icon-arrow-left goToTarget"} aria-hidden /><span className="More__Link">More</span></Link>
                                                </div>
                                            </div>
                                            ))
                                        }
                                        <div className="col-12 mt-4">
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                pageRangeDisplayed={10}
                                                onChange={handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </Fragment>
                                    : 
                                    <div className="col-12">
                                        {
                                            isAuthenticated ?
                                            <NoItems
                                                title="no_portfolio_found"
                                                message="no_portfolio_message_with_action"
                                                buttonText="add_portfolio_item"
                                                iconClassName="icon icon-plus"
                                                onClick={() => {
                                                    handleShow();
                                                }}
                                            />
                                            :
                                            <NoItems
                                                title="no_portfolio_found"
                                                message="no_portfolio_message"
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </Fragment>
                }
            </div>
        </Fragment>
    )
}