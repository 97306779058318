import { 
    GET_SERVICE_INVOICE_COMMENTS_SUCCESS, 
    GET_SERVICE_INVOICE_COMMENTS_FAIL, 
    GET_SERVICE_INVOICE_COMMENTS_LOADING,
    ADD_SERVICE_INVOICE_COMMENT_SUCCESS,
    ADD_SERVICE_INVOICE_COMMENT_FAIL,
    ADD_SERVICE_INVOICE_COMMENT_LOADING,
    EDIT_SERVICE_INVOICE_COMMENT_SUCCESS,
    EDIT_SERVICE_INVOICE_COMMENT_FAIL,
    EDIT_SERVICE_INVOICE_COMMENT_LOADING,
    DELETE_SERVICE_INVOICE_COMMENT_SUCCESS,
    DELETE_SERVICE_INVOICE_COMMENT_FAIL,
    DELETE_SERVICE_INVOICE_COMMENT_LOADING,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// GET SERVICE INVOICE COMMENTS

export const getserviceinvoiceComments = (id = null, page = null, limit = null) => (dispatch, getState) => {
    // Data Loading
    dispatch(clearErrors());
    dispatch({
      type: GET_SERVICE_INVOICE_COMMENTS_LOADING,
    });
    //Request body
    axiosInstance.get(`/api/services/invoices/comments?invoice_id=${id}&page=${page}&limit=${limit}`, tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: GET_SERVICE_INVOICE_COMMENTS_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_SERVICE_INVOICE_COMMENTS_FAIL'))
            dispatch ({ 
                type: GET_SERVICE_INVOICE_COMMENTS_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_SERVICE_INVOICE_COMMENTS_FAIL')),
                dispatch ({ 
                    type: GET_SERVICE_INVOICE_COMMENTS_FAIL 
                })
            )
        }
    });
};

// ADD SERVICE INVOICE COMMENT
export const addserviceinvoiceComment = ({comment, invoice_id, files}) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: ADD_SERVICE_INVOICE_COMMENT_LOADING
    });
    //Request body
    const body = { comment, invoice_id, files };
    axiosInstance.post('/api/services/invoices/comments', body, tokenConfig(getState))
    .then( res => {
            dispatch ({
                type: ADD_SERVICE_INVOICE_COMMENT_SUCCESS,
                payload: res.data
            })
        }
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'ADD_SERVICE_INVOICE_COMMENT_FAIL'))
            dispatch ({ 
                type: ADD_SERVICE_INVOICE_COMMENT_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_SERVICE_INVOICE_COMMENT_FAIL')),
                dispatch ({ 
                    type: ADD_SERVICE_INVOICE_COMMENT_FAIL 
                })
            )
        }
    });
};

// DELETE SERVICE INVOICE COMMENT
export const deleteserviceinvoiceComment = ({ id }) => async (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: DELETE_SERVICE_INVOICE_COMMENT_LOADING
    });
    await axiosInstance.delete(`/api/services/invoices/comments?id=${id}`, tokenConfig(getState))
    .then( res => {
            dispatch ({
                type: DELETE_SERVICE_INVOICE_COMMENT_SUCCESS,
                payload: res.data
            })
        }
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'DELETE_SERVICE_INVOICE_COMMENT_FAIL'))
            dispatch ({ 
                type: DELETE_SERVICE_INVOICE_COMMENT_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'DELETE_SERVICE_INVOICE_COMMENT_FAIL')),
                dispatch ({ 
                    type: DELETE_SERVICE_INVOICE_COMMENT_FAIL 
                })
            )
        }
    });
};

// EDIT SERVICE INVOICE COMMENT
export const editserviceinvoiceComment = ({ comment, invoice_id, id, files }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: EDIT_SERVICE_INVOICE_COMMENT_LOADING
    });
    //Request body
    const body = { comment, invoice_id, id, files };
    axiosInstance.post('/api/services/invoices/comments', body, tokenConfig(getState))
    .then( res => {
            dispatch ({
                type: EDIT_SERVICE_INVOICE_COMMENT_SUCCESS,
                payload: res.data
            })
        }
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'EDIT_SERVICE_INVOICE_COMMENT_FAIL'))
            dispatch ({ 
                type: EDIT_SERVICE_INVOICE_COMMENT_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'EDIT_SERVICE_INVOICE_COMMENT_FAIL')),
                dispatch ({ 
                    type: EDIT_SERVICE_INVOICE_COMMENT_FAIL 
                })
            )
        }
    });
};

// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}