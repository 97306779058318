import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import Spin from '../Spin';
import { I18n } from "react-redux-i18n";
import Pagination from "react-js-pagination";
import { clearErrors } from '../../actions/errorActions';
import { getfreelancerPortfolio } from '../../actions/freelancerportfolioActions';
import AddingPortfolioItem from '../AddingPortfolioModal';
import ConfToast from "../ConfToast";
import { RESET_PORTFOLIO_ITEM } from "../../actions/types";
import ConnectionLost from "../ConnectionLost";
import NoItems from '../NoItems';
import AddingRow from "../../components/AddingRow";
import { MetaComponent } from '../MetaComponent';
import { TitleComponent } from "../../components/TitleComponent";


export default function MyPortfolio() {
    const dispatch = useDispatch();
    var { 
        userLoading,
        isLoading,
        portfolioItemAdded,
        user,
        freelancerportfolio,
        loadingportfolio,
        totalRecords,
        itemsPerPage,
        portfolioItemDeleted,
        errorId,
        errorMessage,
    } = useSelector((state) => ({
        userLoading: state.auth.isLoading,
        isLoading: state.addportfolioitem.isLoading,
        portfolioItemAdded: state.addportfolioitem.portfolioItem,
        user: state.auth.user,
        freelancerportfolio: state.freelancerportfolio.portfolios,
        loadingportfolio: state.freelancerportfolio.loadingportfolio,
        totalRecords: state.freelancerportfolio.totalRecords,
        itemsPerPage: state.freelancerportfolio.itemsPerPage,
        portfolioItemDeleted: state.deleteportfolioItem.portfolioItemDeleted,
        errorId: state.error.id,
        errorMessage: state.error.msg,
    }));
    const [lgShow, setLgShow] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [loadingUserPortfolio, setLoadingUserPortfolio] = useState(true);

    useEffect(() => {
        setLoadingUserPortfolio(loadingportfolio);
    }, [loadingportfolio]);

    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
    }
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };
    useEffect(() => {
        if(Object.keys(user).length > 0){
            const page = activePage;
            const limit = 12;
            dispatch(getfreelancerPortfolio(null, null, null, page, limit));
        }
    }, [user, dispatch, activePage]);

    useEffect(() => {
        if (portfolioItemDeleted === "success") {
            setConfShow(true);
            setConfTitle(I18n.t('delete_portfolio'));
            setConfMessage(I18n.t('delete_portfolio_success'));
            setConfStatus('Toast__Container__Success');
            setDelay(6000);
            dispatch({ 
                type: RESET_PORTFOLIO_ITEM
            });
        }
    }, [portfolioItemDeleted, dispatch]);

    useEffect(() => {
        if(portfolioItemAdded === "success"){
            const page = activePage;
            const limit = 12;
            dispatch(getfreelancerPortfolio(null, null, null, page, limit));
            dispatch({ 
                type: RESET_PORTFOLIO_ITEM
            });
            setConfShow(true);
            setConfTitle(I18n.t('add_portfolio_item_title'));
            setConfMessage(I18n.t('add_portfolio_success'));
            setConfStatus('Toast__Container__Success');
            setDelay(6000);
        }
    }, [portfolioItemAdded, dispatch, user, activePage]);

    return(
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <TitleComponent title={I18n.t('lancersin_myportfolio')} />
            <MetaComponent />
            <AddingPortfolioItem 
                modalTitle={"add_portfolio_item_title"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                isLoading={isLoading}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            {
                userLoading ?
                <Spin />
                :
                <div className="container">
                    <AddingRow
                      onAdding={() => {
                        handleShow();
                      }}
                      title={"portfolio"}
                      titleIconClassName={"icon-business-case"}
                      btnText={"add_portfolio_item"}
                      btnIconClassName={"icon-plus"}
                      forceShowButton={true}
                    />
                    <div className="row">
                        {
                            loadingUserPortfolio ? 
                            <Spin /> 
                            : errorId === "GET_PORTFOLIO_FAIL" && errorMessage === "Network Error" ?
                            <div className="col-12">
                                <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                                />
                            </div>
                            : errorId === "GET_PORTFOLIO_FAIL" ?
                            <div className="col-12">
                                <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                                />
                            </div>
                            : 
                            freelancerportfolio.length > 0 && !loadingUserPortfolio ? 
                            <Fragment>
                                {
                                    freelancerportfolio.map( portfolioItem => (
                                        portfolioItem.publish === 1 && portfolioItem.status_id === 1 ?
                                        <div className="col-sm-6 col-md-4" key={portfolioItem.id}>
                                            <div className="services__listitem box-shadow">
                                                <figure>
                                                <Link className="services__imgcontainer" to={{pathname:`/portfolio/${portfolioItem.id}/${portfolioItem.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                        <img src={portfolioItem.image} alt={portfolioItem.title} />
                                                    </Link>
                                                    <figcaption>
                                                        <h2><Link to={{pathname:`/portfolio/${portfolioItem.id}/${portfolioItem.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>{portfolioItem.title}</Link></h2>
                                                    </figcaption>
                                                </figure>
                                                <div className="portfolio__info">
                                                    <div className="likes">
                                                        <span className="icon icon-like"></span>{portfolioItem.likes_count}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : 
                                        <div className="col-sm-6 col-md-4" key={portfolioItem.id}>
                                            <div className="services__listitem services__hiddenitem box-shadow">
                                                <figure>
                                                    <Link className="services__imgcontainer" to={{pathname:`/portfolio/${portfolioItem.id}/${portfolioItem.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                        <img src={portfolioItem.image} alt={portfolioItem.title} />
                                                    </Link>
                                                    <figcaption>
                                                        <h2><Link to={{pathname:`/portfolio/${portfolioItem.id}/${portfolioItem.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>{portfolioItem.title}</Link></h2>
                                                    </figcaption>
                                                </figure>
                                                <div className="portfolio__info">
                                                    <div className="likes">
                                                        <span className="icon icon-like"></span>{portfolioItem.likes_count}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="col-12 my-4">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={itemsCountPerPage}
                                        totalItemsCount={totalItemsCount}
                                        pageRangeDisplayed={10}
                                        onChange={handlePageChange}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                    />
                                </div>
                            </Fragment>
                            : 
                            <div className="col-12">
                                <NoItems
                                    title="no_portfolio_found"
                                    message="no_portfolio_message_with_action"
                                    buttonText="add_portfolio_item"
                                    iconClassName="icon icon-plus"
                                    onClick={() => {
                                        handleShow();
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
            }
        </Fragment>
    )
}