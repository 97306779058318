import {
    ADD_CLIENT_REPLY_LOADING,
    ADD_CLIENT_REPLY_SUCCESS, 
    ADD_CLIENT_REPLY_FAIL,
    CLEAR_CLIENT_REPLY_SUCCESS
} from '../actions/types';

const initialState = {
    isLoading: false,
    clientReplySuccess: false, 
}

export default function addclientreplyReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_CLIENT_REPLY_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ADD_CLIENT_REPLY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                clientReplySuccess: action.payload.data === "success"
            };
        case ADD_CLIENT_REPLY_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case CLEAR_CLIENT_REPLY_SUCCESS:
            return{
                ...state,
                isLoading: false,
                clientReplySuccess: false,
            };
        default:
            return state;
    }
}