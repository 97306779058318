import React from 'react';
import HomeSlider from '../Sliders/HomeSlider/Slider';

export default function MainSlider() {
  return (
    <section className="MainSlider">
      <div className="container">
        <HomeSlider />
      </div>
    </section>
  );
}
