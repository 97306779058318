import axios from "axios";
import { ServerURL } from "./components/Server";

const langSet = typeof window !== 'undefined' && localStorage.getItem("lang");

export const axiosInstance = axios.create({
  baseURL: `${ServerURL}`,
  // timeout: 5000,
  headers: {
    "Accept-Language": langSet === "English" ? "en" : "ar",
  },
});

export const tokenConfig = (getState,locale) => {
  // Get token from localStorage
  const token = getState().auth.token;
  //Headers
  const config = {
    headers: {
      "Content-type": "application/json",
      "Accept-Language": locale ? locale : langSet === "English" ? "en" : "ar",
    },
  };

  // If token, add to headers

  if (token) {
    config.headers["Authorization"] = "Bearer " + token;
  }

  return config;
};

export const handleError = ({ error, rejectWithValue }) => {
  if (error instanceof Error) {
    let errorJson = JSON.stringify(error);
    let errorParsed = JSON.parse(errorJson);
    return rejectWithValue(errorParsed.message);
  }
  return rejectWithValue(error.response.data);
};
