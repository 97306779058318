import React, {useState, useEffect, useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Button, Form} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';
import TextInput from '../TextInput';
import QuillBox from '../QuillBox';
import {Uploader} from '../Upload';
import {ADD_PORTFOLIO_FAIL} from '../../actions/types';
import {CreatableSkills} from '../CreatableSkills';
import {clearErrors} from '../../actions/errorActions';
import {SelectBox} from '../SelectBox';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {addportfolioitem} from '../../actions/addportfolioActions';
import ErrorBox from '../ErrorBox';
import GRecaptch from '../GRecaptch';
import {CreatableKeywords} from '../CreatableKeywords';
import {deleteFile} from '../../actions/deletefileActions';
import {ServerURL} from '../Server';

const {Control} = Form;
const {Feedback} = Control;

export default function AddingPortfolioItem(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    modalTitle,
    portfolioItem,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  var {
    cats,
    errorsId,
    errorsMsg: errors,
    isLoading,
    portfolioaddedItem,
  } = useSelector(state => ({
    cats: state.category.cats || [],
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    isLoading: state.addportfolioitem.isLoading,
    portfolioaddedItem: state.addportfolioitem.portfolioItem,
  }));

  const returnedErrors = errors['errors'] || {};
  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [video, setVideo] = useState('');
  const [portfolioKeywords, setPortfolioKeywords] = useState([]);
  const [publish, setPublish] = useState(true);
  const [spacing, setSpacing] = useState(true);
  const [skills, setSkills] = useState([]);
  const [isHuman, setIsHuman] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [titleCharacterCount, setTitleCharacterCount] = useState(0);

  const [title, setTitle] = useState('');
  const [projectURL, setProjectURL] = useState('');
  const [description, setDescription] = useState('');
  const [id, setId] = useState('');
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_PORTFOLIO_FAIL);

  const [image, setImage] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [localErrors, setLocalErrors] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [imageSortLength, setImageSortLength] = useState(false);
  const [filteredErrorsLength, setFilteredErrorsLength] = useState(false);
  const [deletedFile, setDeletedFile] = useState([]);

  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState(false);
  const [imagesChosen, setImagesChosen] = useState(false);
  const [imagesUploadErrors, setImagesUploadErrors] = useState([]);
  const [imagesSortLength, setImagesSortLength] = useState(false);
  const [filteredImagesErrorsLength, setFilteredImagesErrorsLength] =
    useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const filteredErrors = uploadErrors
    ? uploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  const filteredImagesErrors = imagesUploadErrors
    ? imagesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  useEffect(() => {
    if (image.length > 0) {
      setImageSortLength(true);
    }
  }, [image]);

  useEffect(() => {
    if (images.length > 0) {
      setImagesSortLength(true);
    }
  }, [images]);

  useEffect(() => {
    if (filteredErrors.length > 0) {
      setFilteredErrorsLength(true);
    }
  }, [filteredErrors]);

  useEffect(() => {
    if (filteredImagesErrors.length > 0) {
      setFilteredImagesErrorsLength(true);
    }
  }, [filteredImagesErrors]);

  useEffect(() => {
    if (errorsId && errors) {
      setErrorActionType(errorsId);
    }
  }, [errorsId, errors]);

  const getCategories = useCallback(() => {
    if (cats) {
      return cats.map(item => {
        return {
          ...item,
          value: item.title,
          label: item.title,
        };
      });
    }
    return [];
  }, [cats]);

  const categories = getCategories();

  const catId = category.id;

  const getSubCategories = useCallback(
    catId => {
      if (catId && cats) {
        let targetCat = cats.find(item => item.id === catId);
        let {sub_categories = []} = targetCat || {};
        let subsCat = sub_categories.map(item => ({
          ...item,
          value: item.title,
          label: item.title,
        }));
        return subsCat;
      }
      return [];
    },
    [cats],
  );

  const subCategories = getSubCategories(catId);

  const handleHide = useCallback(() => {
    setLocalErrors({});
    props.onHide && props.onHide();
    dispatch(clearErrors());
  }, [dispatch, props]);

  useEffect(() => {
    if (
      errorsId === null &&
      isLoading === false &&
      portfolioaddedItem === 'success'
    ) {
      handleHide();
      setCategory({});
      setSubCategory({});
      setVideo('');
      setPortfolioKeywords([]);
      setPublish(true);
      setSpacing(true);
      setSkills([]);
      setTitle('');
      setProjectURL('');
      setDescription('');
      setImages([]);
      setImage([]);
      setDeletedFiles([]);
      setDeletedFile([]);
      return history.push('/dashboard/portfolio');
    }
  }, [portfolioaddedItem, isLoading, errorsId, handleHide, history]);

  function isObject(val) {
    return typeof val === 'object';
  }

  useEffect(() => {
    if (portfolioItem) {
      setTitle(portfolioItem.title);
      setId(portfolioItem.id);
      setDescription(portfolioItem.description);
      setPublish(portfolioItem.publish === 1 ? true : false);
      setSpacing(portfolioItem.spacing === 1 ? true : false);
      setProjectURL(portfolioItem.preview_url);
      let categories = getCategories();
      if (portfolioItem.category) {
        let category =
          categories.find(({id}) => id === portfolioItem.category.id) || [];
        setCategory(category);
        if (portfolioItem.sub_category) {
          let subCat = getSubCategories(portfolioItem.category.id).filter(
            ({id}) => id === portfolioItem.sub_category.id,
          );
          setSubCategory(subCat[0]);
        }
      }
      setPortfolioKeywords(portfolioItem.keywords);
      setSkills(portfolioItem.skills);
      setVideo(portfolioItem.video_url);
      if (
        isObject(portfolioItem.image) &&
        portfolioItem.image.url.includes(`uploads/`)
      ) {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: portfolioItem.image.size,
          name: portfolioItem.image.filename,
          origin: portfolioItem.image.filename,
          path: portfolioItem.image.filename,
          percent: 100,
          preview: portfolioItem.image.url,
          size: portfolioItem.image.size,
          source: portfolioItem.image.url,
          total: portfolioItem.image.size,
          type: `image/${portfolioItem.image.extension}`,
        };
        setImage(imageCopy);
      } else if (portfolioItem.image.includes(`lancersin/public/`)) {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 2139,
          name: 'no-image.png',
          origin: 'no-image.png',
          path: 'no-image.png',
          percent: 100,
          preview: portfolioItem.image,
          size: 2139,
          source: portfolioItem.image,
          total: 2139,
          type: 'image/png',
        };
        setImage(imageCopy);
      } else {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 100257,
          name: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          origin: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          path: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          percent: 100,
          preview: `${ServerURL}/public/images/no-image.png`,
          size: 100257,
          source: `${ServerURL}/public/images/no-image.png`,
          total: 100257,
          type: 'image/jpg',
        };
        setImage(imageCopy);
      }
      setImages(
        portfolioItem.images.map(item => ({
          loaded: item.size,
          name: item.filename,
          origin: item.filename,
          path: item.filename,
          percent: 100,
          preview: item.url,
          size: item.size,
          source: item.url,
          total: item.size,
          type: `image/${item.extension}`,
        })),
      );
    }
  }, [portfolioItem, getCategories, getSubCategories]);

  function handleKeywordsChange(keywords) {
    setPortfolioKeywords(keywords);
  }

  function onKeywordsBlur() {
    handleItemLocalError({
      propName: 'keywords',
      currentValue: portfolioKeywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });
  }

  function togglePublish() {
    setPublish(!publish);
  }
  function toggleSpacing() {
    setSpacing(!spacing);
  }

  function handleSkillsChange(skills) {
    setSkills(skills);
  }

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    uploadArray,
  }) {
    if (currentValue) {
      if (isObject(currentValue) && !Array.isArray(currentValue)) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else if (Array.isArray(currentValue) && uploadArray === true) {
        if (currentValue.length <= 0) {
          const noImageError = {
            code: 'NoImage',
            message: message,
          };
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setUploadErrors(filtered);
        } else {
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          setUploadErrors(filtered);
        }
      } else if (Array.isArray(currentValue) && uploadArray === false) {
        if (currentValue.length <= 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onTitleBlur() {
    if (title) {
      if (title.replace(/<(.|\n)*?>/g, '').trim().length < 25) {
        setLocalErrors(errors => ({
          ...errors,
          title: I18n.t('title_limit'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          title: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        title: I18n.t('title_required'),
      }));
    }
  }

  function handleCatChange(e) {
    setCategory(e);
    setSubCategory(null);
  }

  function onCatBlur() {
    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });
  }

  function handleSubcatChange(e) {
    setSubCategory(e);
  }

  function onSubCatBlur() {
    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });
  }

  function isValidURL(URL) {
    const regex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
    return regex.test(URL);
  }

  function onProjectURLChange(e) {
    const {value} = e.target;
    const thisTrueVal = !value || isValidURL(value);
    setProjectURL(value);
    if (thisTrueVal) {
      setLocalErrors(errors => ({
        ...errors,
        projectURL: undefined,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        projectURL: I18n.t('valid_url'),
      }));
    }
  }

  useEffect(() => {
    if (description) {
      setCharacterCount(description.replace(/<(.|\n)*?>/g, '').trim().length);
    }
  }, [description]);

  useEffect(() => {
    if (title) {
      setTitleCharacterCount(title.replace(/<(.|\n)*?>/g, '').trim().length);
    }
  }, [title]);

  function handleDescriptionChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setDescription('');
    } else {
      setDescription(value);
    }
  }

  function onDescriptionBlur() {
    if (description) {
      if (description.replace(/<(.|\n)*?>/g, '').trim().length < 60) {
        setLocalErrors(errors => ({
          ...errors,
          description: I18n.t('description_limit_small'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          description: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        description: I18n.t('description_required'),
      }));
    }
  }

  function isYoutubeVideo(link) {
    return link.match(
      /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_-]+)/i,
    );
  }

  function isVimeoVideo(link) {
    return link.match(/\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i);
  }

  function isVideoLink(link) {
    return isYoutubeVideo(link) || isVimeoVideo(link);
  }

  function handleVideo(e) {
    const {value} = e.target;
    var url = isVideoLink(value);
    setVideo(value);
    if (url || value === '') {
      //setVideo(value);
      setLocalErrors(errors => ({
        ...errors,
        video: undefined,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        video: I18n.t('valid_video'),
      }));
    }
  }

  function handleToast(showToast, title, message, status, delay) {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function onSubmit(e) {
    e.preventDefault();

    onTitleBlur();

    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });

    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });

    onDescriptionBlur();

    handleItemLocalError({
      propName: 'keywords',
      currentValue: portfolioKeywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: 'image',
      currentValue: image,
      message: I18n.t('image_required'),
      uploadArray: true,
    });

    if (
      fileUpload === false &&
      fileChosen === false &&
      imagesUpload === false &&
      imagesChosen === false &&
      title &&
      description &&
      category &&
      subCategory &&
      image.length > 0 &&
      isHuman &&
      portfolioKeywords.length > 0 &&
      !localErrors.title &&
      !localErrors.description &&
      !localErrors.category &&
      !localErrors.subCategory &&
      !localErrors.image &&
      !localErrors.keywords
    ) {
      if (deletedFiles.length > 0) {
        deletedFiles.map(item => {
          const model = 'portfolios';
          const id = portfolioItem ? portfolioItem.id : null;
          const fileURL = item;
          const fileData = {
            model,
            id,
            fileURL,
          };
          return dispatch(deleteFile(fileData));
        });
      }
      if (deletedFile.length > 0) {
        deletedFile.map(item => {
          const model = 'portfolios';
          const id = portfolioItem ? portfolioItem.id : null;
          const fileURL = item;
          const fileData = {
            model,
            id,
            fileURL,
          };
          return dispatch(deleteFile(fileData));
        });
      }
      let images_sort = images.map(item => item.source);
      let keywords = portfolioKeywords;
      let itemData = {
        title,
        description,
        cat_id: category.id,
        sub_cat_id: subCategory.id,
        images_sort,
        keywords,
        skills,
        image: image[0].source,
        images: images.map(item => item.source),
        video_url: video,
        preview_url: projectURL,
        spacing: spacing === true ? 1 : 0,
        publish: publish === true ? 1 : 0,
        id,
      };
      dispatch(addportfolioitem(itemData));
    } else if (
      fileUpload === true ||
      fileChosen === true ||
      imagesUpload === true ||
      imagesChosen === true
    ) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        6000,
      );
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Error',
        10000,
      );
    }
  }

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props['aria-labelledby']}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger" role="alert">
                {I18n.t('adding_warning')}
              </div>
            </div>
          </div>
          <TextInput
            required
            controlId="Title"
            label="portfolio_title"
            type="text"
            name="title"
            desc="portfolio_title_placeholder"
            onChange={onTitleChange}
            onBlur={onTitleBlur}
            value={title}
            minlength="25"
            characterCount={titleCharacterCount}
            feedbackType="invalid"
            feedback={
              localErrors.title
                ? localErrors.title
                : returnedErrors.title
                ? returnedErrors.title[0]
                : null
            }
            isInvalid={
              (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_FAIL' &&
                returnedErrors.title) ||
              localErrors.title
            }
          />
          <div className="row">
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Category"
                name="category"
                label={I18n.t('category')}
                options={categories}
                value={category}
                onChange={handleCatChange}
                onBlur={onCatBlur}
                feedbackType="invalid"
                feedback={
                  localErrors.category
                    ? localErrors.category
                    : returnedErrors.cat_id
                    ? returnedErrors.cat_id[0]
                    : null
                }
                isInvalid={
                  (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_FAIL' &&
                    returnedErrors.cat_id) ||
                  localErrors.category
                }
                placeholder={I18n.t('category')}
              />
            </div>
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Sub-Category"
                label={I18n.t('sub_category')}
                options={subCategories}
                value={subCategory}
                onChange={handleSubcatChange}
                onBlur={onSubCatBlur}
                feedbackType="invalid"
                feedback={
                  localErrors.subCategory
                    ? localErrors.subCategory
                    : returnedErrors.sub_cat_id
                    ? returnedErrors.sub_cat_id[0]
                    : null
                }
                isInvalid={
                  (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_FAIL' &&
                    returnedErrors.cat_id) ||
                  (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_FAIL' &&
                    returnedErrors.sub_cat_id) ||
                  localErrors.subCategory
                }
                placeholder={I18n.t('sub_category')}
                noOptionsMessage={
                  Object.keys(category).length > 0
                    ? I18n.t('select_no_options')
                    : I18n.t('choose_cat_first')
                }
              />
            </div>
          </div>
          <QuillBox
            required
            controlId="Description"
            label={I18n.t('description')}
            value={description}
            onChange={handleDescriptionChange}
            onBlur={onDescriptionBlur}
            feedbackType="invalid"
            minlength="60"
            characterCount={characterCount}
            feedback={
              localErrors.description
                ? localErrors.description
                : returnedErrors.description
                ? returnedErrors.description[0]
                : null
            }
            isInvalid={
              (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_FAIL' &&
                returnedErrors.description) ||
              localErrors.description
            }
          />
          <Form.Group controlId="SmallImage">
            <Form.Label>
              <Translate value="thumbnail_image" />
              <small className="required"> ({I18n.t('required')})</small>
            </Form.Label>
            <Uploader
              accept={[
                {
                  mime: 'image/jpeg',
                  ext: 'jpeg',
                },
                {
                  mime: 'image/jpg',
                  ext: 'jpg',
                },
                {
                  mime: 'image/png',
                  ext: 'png',
                },
              ]}
              maxFiles={1}
              multiple={false}
              maxSize={5000000}
              sortable={false}
              setFilesChosen={setFileChosen}
              setFilesUpload={setFileUpload}
              setUploadErrors={setUploadErrors}
              uploadErrors={uploadErrors}
              setFilesSort={setImage}
              filesSort={image}
              filesSortLength={imageSortLength}
              filteredErrorsLength={filteredErrorsLength}
              filteredErrors={filteredErrors}
              uploadPath="portfolios"
              projectId={portfolioItem ? portfolioItem.id : null}
              deletedFiles={deletedFile}
              setDeletedFiles={setDeletedFile}
              optimize={1}
            />
            {errorsId === 'DELETE_FILE_FAIL' && deletedFile.length > 0 ? (
              <ErrorBox message={I18n.t('delete_file_error')} />
            ) : null}
            {localErrors.image || (returnedErrors && returnedErrors.image) ? (
              <Feedback type="d-block invalid">
                {localErrors.image
                  ? localErrors.image
                  : returnedErrors.image
                  ? returnedErrors.image[0]
                  : null}
              </Feedback>
            ) : null}
          </Form.Group>
          <Form.Group controlId="PortfolioImages">
            <Form.Label>
              <Translate value="portfolio_images" />
              <small className="optional"> ({I18n.t('optional')})</small>
            </Form.Label>
            <small>
              <Translate value="sort_message" />
            </small>
            <Uploader
              accept={[
                {
                  mime: 'image/jpeg',
                  ext: 'jpeg',
                },
                {
                  mime: 'image/jpg',
                  ext: 'jpg',
                },
                {
                  mime: 'image/png',
                  ext: 'png',
                },
              ]}
              maxFiles={5}
              multiple={true}
              maxSize={5000000}
              sortable={true}
              setFilesChosen={setImagesChosen}
              setFilesUpload={setImagesUpload}
              setUploadErrors={setImagesUploadErrors}
              uploadErrors={imagesUploadErrors}
              setFilesSort={setImages}
              filesSort={images}
              filesSortLength={imagesSortLength}
              filteredErrorsLength={filteredImagesErrorsLength}
              filteredErrors={filteredImagesErrors}
              uploadPath="portfolios"
              projectId={portfolioItem ? portfolioItem.id : null}
              deletedFiles={deletedFiles}
              setDeletedFiles={setDeletedFiles}
              optimize={1}
            />
          </Form.Group>
          {errorsId === 'DELETE_FILE_FAIL' && deletedFiles.length > 0 ? (
            <ErrorBox message={I18n.t('delete_file_error')} />
          ) : null}
          <TextInput
            type="text"
            controlId="PortfolioVideo"
            name="PortfolioVideo"
            label={I18n.t('video_link')}
            placeholder={I18n.t('video_placeholder')}
            value={video}
            required={false}
            onChange={handleVideo}
            feedbackType="invalid"
            feedback={localErrors.video ? localErrors.video : null}
            isInvalid={localErrors.video}
          />
          <TextInput
            type="text"
            controlId="ProjectURL"
            name="ProjectURL"
            label={I18n.t('project_link')}
            placeholder={I18n.t('sample_url')}
            value={projectURL}
            required={false}
            onChange={onProjectURLChange}
            feedbackType="invalid"
            feedback={localErrors.projectURL ? localErrors.projectURL : null}
            isInvalid={localErrors.projectURL}
          />
          <CreatableSkills
            onChange={handleSkillsChange}
            feedback={errors.skills}
            feedbackType="invalid"
            isInvalid={localErrors.skills}
            required={false}
            value={skills}
          />

          <CreatableKeywords
            onChange={handleKeywordsChange}
            feedback={
              localErrors.keywords
                ? localErrors.keywords
                : returnedErrors.keywords
                ? returnedErrors.keywords[0]
                : null
            }
            feedbackType="invalid"
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.keywords) ||
              localErrors.keywords
            }
            value={portfolioKeywords}
            onBlur={onKeywordsBlur}
          />

          <Form.Check
            type="switch"
            id="publishItem"
            label={I18n.t('hide_from_portfolio')}
            name="publish"
            onChange={togglePublish}
            checked={!publish}
          />
          <Form.Check
            type="switch"
            id="itemsSpacing"
            label={I18n.t('remove_items_margin')}
            name="spacing"
            onChange={toggleSpacing}
            checked={!spacing}
          />

          <GRecaptch setIsHuman={setIsHuman} className="mt-3" />

          {errorsId === 'ADD_PORTFOLIO_FAIL' && errors === 'Network Error' ? (
            <ErrorBox
              message={I18n.t('network_error_message')}
              className="mt-3"
            />
          ) : errorsId === 'ADD_PORTFOLIO_FAIL' && errors === 'Other Errors' ? (
            <ErrorBox
              message={I18n.t('something_error_message')}
              className="mt-3"
            />
          ) : null}
        </Modal.Body>
        <Modal.Footer>
          <ButtonWithLoading
            variant="primary"
            type="submit"
            onClick={onSubmit}
            data-backdrop="static"
            loading={isLoading ? isLoading : null}>
            <Translate value="submit" />
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}>
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
