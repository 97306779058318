import { combineReducers } from "redux";
import { i18nReducer } from "react-redux-i18n";
import freelancersReducer from "./freelancersReducer.js";
import projectsReducer from "../redux/project/reducers";
import notificationsReducer from "../redux/notification/reducers";
import favouritesReducer from "../redux/favourite/reducers";
import articlesReducer from "../redux/article/reducers";
import contactReducer from "../redux/contact/reducers";
import catsReducer from "./catsReducer";
import statusReducer from "./statusReducer";
import freelancerprofileReducer from "./freelancerprofileReducer.js";
import updateuserprofileReducer from "./updateprofileReducer";
import errorReducer from "./errorReducer.js";
import authReducer from "./authReducer.js";
import langReducer from "./langReducer";
import addportfolioitemReducer from "./addportfolioReducer";
import editportfolioitemReducer from "./editportfolioReducer";
import freelancerportfolioReducer from "./freelancerportfolioReducer";
import freelancerportfolioitemReducer from "./freelancerportfolioitemReducer";
import portfolioitemcommentsReducer from "./portfoliocommentsReducer";
import portfoliolikesReducer from "./portfoliolikesReducer";
import followReducer from "./followReducer";
import deletefileReducer from "./deletefileReducer";
import reportsReducer from "./reportsReducer";
import deleteportfolioReducer from "./deleteportfolioReducer";
import deleteserviceReducer from "./deleteserviceReducer";
import addserviceitemReducer from "./addserviceReducer";
import freelancerservicesReducer from "./freelancerservicesReducer";
import freelancerserviceitemReducer from "./freelancerserviceitemReducer";
import servicesReducer from "./getservicesReducer";
import purchesserviceReducer from "./purchesserviceReducer";
import serviceinvoicesReducer from "./getserviceinvoicesReducer";
import serviceinvoiceReducer from "./getserviceinvoiceReducer";
import portfoliosReducer from "./getportfoliosReducer";
import addclientreplyReducer from "./addclientreplyReducer";
import serviceinvoicecommentsReducer from "./serviceinvoicecommentsReducer";
import contestReducer from "../redux/contest/reducers";
import verifyaccountReducer from "./verifyaccountReducer";
import resetpasswordReducer from "./resetpasswordReducer";
import getpagesReducer from "./getpagesReducer.js";
import getsocialReducer from "./getsocialReducer.js";
import serviceReducer from "../redux/services/reducers";
import messagesReducer from "../redux/messages/reducers";
import followersReducer from "../redux/followers/reducers/index.js";
import paymentReducer from "../redux/payments/reducers/index.js";
import feedbacksReducer from "../redux/feedbacks/reducers/index";
import userDataReducer from "../redux/userData/reducers/index.js";

export default combineReducers({
  i18n: i18nReducer,
  freelancer: freelancersReducer,
  freelancerdata: freelancerprofileReducer,
  followers: followersReducer,
  updateprofile: updateuserprofileReducer,
  project: projectsReducer,
  service: serviceReducer,
  notifications: notificationsReducer,
  messages: messagesReducer,
  favourites: favouritesReducer,
  article: articlesReducer,
  category: catsReducer,
  status: statusReducer,
  contest: contestReducer,
  contact: contactReducer,
  error: errorReducer,
  auth: authReducer,
  userData: userDataReducer,
  lang: langReducer,
  addportfolioitem: addportfolioitemReducer,
  editportfolioitem: editportfolioitemReducer,
  freelancerportfolio: freelancerportfolioReducer,
  freelancerportfolioitem: freelancerportfolioitemReducer,
  portfolioitemcomments: portfolioitemcommentsReducer,
  portfolioitemLikes: portfoliolikesReducer,
  followUser: followReducer,
  deleteFiles: deletefileReducer,
  deleteportfolioItem: deleteportfolioReducer,
  reportsTypes: reportsReducer,
  addserviceitem: addserviceitemReducer,
  freelancerservices: freelancerservicesReducer,
  freelancerserviceitem: freelancerserviceitemReducer,
  deleteserviceItem: deleteserviceReducer,
  services: servicesReducer,
  servicePurches: purchesserviceReducer,
  serviceInvoices: serviceinvoicesReducer,
  serviceInvoice: serviceinvoiceReducer,
  portfolios: portfoliosReducer,
  addclientreply: addclientreplyReducer,
  serviceinvoicecomments: serviceinvoicecommentsReducer,
  verifyAccount: verifyaccountReducer,
  resetPassword: resetpasswordReducer,
  websitePages: getpagesReducer,
  websiteSocial: getsocialReducer,
  payments: paymentReducer,
  feedbacks: feedbacksReducer,
});
