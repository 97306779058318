import React from "react";
import { Link } from "react-router-dom";
import { Num } from "../Numbers";

export default function Card({ hide, item }) {
  return (
    <div className="col-sm-6 col-md-4">
      <div
        className={`services__listitem ${
          hide ? "services__hiddenitem" : ""
        } box-shadow`}
      >
        <figure>
          <Link
            className="services__imgcontainer"
            to={{
              pathname: `/services/${item.id}/${item.title
                .replace(/\s+/g, "-")
                .replace(/\//g, "-")
                .toLowerCase()}`,
            }}
          >
            <img src={item.image.url ? item.image.url : item.image} alt={item.title} />
          </Link>
          <figcaption>
            <h2>
              <Link
                to={{
                  pathname: `/services/${item.id}/${item.title
                    .replace(/\s+/g, "-")
                    .replace(/\//g, "-")
                    .toLowerCase()}`,
                }}
              >
                {item.title}
              </Link>
            </h2>
          </figcaption>
        </figure>
        <div className="services__info">
          <div className="bought">
            <span className="icon icon-deal"></span>
            {Num(item.purchasesCount)}
          </div>
          <div className="price">
            <span className="icon icon-price-label"></span>
            {item.price}$
          </div>
        </div>
      </div>
    </div>
  );
}
