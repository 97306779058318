import React, {useState} from 'react';
import {Accordion, Card, Button} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';

const Overview = (props) => {
    const freelancer = props.freelancerdata;
    const createMarkup = (text) => {
        return {__html: text};
    }

    const [activeId, setActiveId] = useState('0');

    function toggleActive(id) {
        if (activeId === id) {
        setActiveId(null);
        } else {
        setActiveId(id);
        }
    }

    return(
        <Accordion defaultActiveKey="0">
            {
                freelancer.about ?
                <Card className={activeId === '0' ? 'active-panel' : ''}>
                    <Card.Header>
                        <Accordion.Toggle onClick={() => toggleActive('0')} as={Button} variant="link" eventKey="0">
                            <span className="icon icon-user"></span>{I18n.t('about_user')}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div dangerouslySetInnerHTML={createMarkup(freelancer.about)}></div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                : null
            }
            {
                freelancer.skills ?
                    freelancer.skills.length > 0 ?
                        <Card className={activeId === '1' ? 'active-panel' : ''}>
                            <Card.Header>
                                <Accordion.Toggle onClick={() => toggleActive('1')} as={Button} variant="link" eventKey="1">
                                    <span className="icon icon-skills"></span> {I18n.t('skills')}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ul className="ProfileSkills">
                                        {
                                            freelancer.skills.map((item, index) => <li key={index}>{item}</li>)
                                        }
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    : null
                : null
            }
        </Accordion>
    )
}   

export default Overview;