import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Pagination from 'react-js-pagination';
import {Card, Button, Form} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';
import QuillBox from '../QuillBox';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import ReportContentModal from '../ReportContentModal';
import DeleteModal from '../DeleteModal';
import {RESET_PORTFOLIO_COMMENTS} from '../../actions/types';
import {addportfolioitemComment} from '../../actions/portfoliocommentsActions';
import {editportfolioitemComment} from '../../actions/portfoliocommentsActions';
import {deleteportfolioitemComment} from '../../actions/portfoliocommentsActions';
import {getPortfolioComments} from '../../actions/portfoliocommentsActions';
import Spin from '../Spin';
import GRecaptch from '../GRecaptch';

export default function PortfolioItemComments(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    params,
    portfolioItem,
    portfolioItemId,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  const {id} = params;

  const {
    isAuthenticated,
    user,
    errorsId,
    errorsMsg,
    portfolioCommentsLoading,
    portfolioitemComments,
    totalRecords,
    itemsPerPage,
    addCommentLoading,
    portfolioitemCommentAdded,
    editCommentLoading,
    portfolioitemCommentEdited,
    deleteCommentLoading,
    portfolioitemCommentDeleted,
    postReportLoading,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    portfolioCommentsLoading: state.portfolioitemcomments.isLoading,
    portfolioitemComments: state.portfolioitemcomments.portfolioitemComments,
    totalRecords: state.portfolioitemcomments.totalRecords,
    itemsPerPage: state.portfolioitemcomments.itemsPerPage,
    addCommentLoading: state.portfolioitemcomments.addPortfolioCommentLoading,
    portfolioitemCommentAdded:
      state.portfolioitemcomments.portfolioitemCommentAdded,
    editCommentLoading: state.portfolioitemcomments.editPortfolioCommentLoading,
    portfolioitemCommentEdited:
      state.portfolioitemcomments.portfolioitemCommentEdited,
    deleteCommentLoading:
      state.portfolioitemcomments.deletePortfolioCommentLoading,
    portfolioitemCommentDeleted:
      state.portfolioitemcomments.portfolioitemCommentDeleted,
    postReportLoading: state.reportsTypes.isLoading,
  }));

  const {errors} = errorsMsg;

  const [activePage, setActivePage] = useState(1);
  const [comment, setComment] = useState('');
  const [editComment, setEditComment] = useState(false);
  const [commentId, setCommentId] = useState('');
  const [commentEdited, setCommentEdited] = useState('');
  const [localErrors, setLocalErrors] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState('');
  const [deleteCommentId, setDeleteCommentId] = useState('');
  const [deleteComment, setDeleteComment] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = (id, comment) => {
    setDeleteCommentId(id);
    setDeleteComment(comment);
    setShowDeleteConfirm(true);
  };

  function handlePageChange(pageNumber) {
    const page = pageNumber;
    const limit = 30;
    const portfolio_id = id;
    dispatch(getPortfolioComments(portfolio_id, page, limit));
    setActivePage(pageNumber);
  }

  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);

  useEffect(() => {
    if (errorsId) {
      setErrorActionType(errorsId);
    }
  }, [errorsId]);

  function isObject(val) {
    return typeof val === 'object';
  }

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    uploadArray,
  }) {
    if (currentValue) {
      if (isObject(currentValue) && !Array.isArray(currentValue)) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else if (Array.isArray(currentValue) && uploadArray === true) {
        if (currentValue.length <= 0) {
          const noImageError = {
            code: 'NoImage',
            message: message,
          };
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setUploadErrors(filtered);
        } else {
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          setUploadErrors(filtered);
        }
      } else if (Array.isArray(currentValue) && uploadArray === false) {
        if (currentValue.length <= 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function handleCommentChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setComment('');
    } else {
      setComment(value);
    }
  }

  function onCommentBlur() {
    handleItemLocalError({
      propName: 'comment',
      currentValue: comment,
      message: I18n.t('comment_required'),
    });
  }

  function handleCommentEdit(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setCommentEdited('');
    } else {
      setCommentEdited(value);
    }
  }

  function onCommentEditBlur() {
    handleItemLocalError({
      propName: 'commentEdited',
      currentValue: commentEdited,
      message: I18n.t('comment_required'),
    });
  }

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  function addComment(e) {
    e.preventDefault();
    handleItemLocalError({
      propName: 'comment',
      currentValue: comment,
      message: I18n.t('comment_required'),
    });

    if (comment && isHuman) {
      const commentData = {
        comment,
        portfolio_id: portfolioItemId,
      };
      dispatch(addportfolioitemComment(commentData));
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function onSubmitEditComment(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'commentEdited',
      currentValue: commentEdited,
      message: I18n.t('comment_required'),
    });
    if (commentEdited && isHuman) {
      const commentData = {
        comment: commentEdited,
        portfolio_id: portfolioItemId,
        id: commentId,
      };
      dispatch(editportfolioitemComment(commentData));
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function editCommentFun(comment) {
    setEditComment(true);
    setCommentId(comment.id);
    setCommentEdited(comment.comment);
  }

  function cancelEditComment() {
    setEditComment(false);
    setCommentId('');
    setCommentEdited('');
  }

  useEffect(() => {
    if (portfolioitemCommentAdded === 'success') {
      const page = 1;
      const limit = 30;
      const portfolio_id = id;
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      setComment('');
      handleToast(
        true,
        I18n.t('adding_bid_comment_title'),
        I18n.t('adding_bid_comment_success'),
        'Toast__Container__Success',
        6000,
      );
      dispatch({
        type: RESET_PORTFOLIO_COMMENTS,
      });
    }
  }, [portfolioitemCommentAdded, dispatch, handleToast, id]);

  useEffect(() => {
    if (portfolioitemCommentDeleted === 'success') {
      const page = 1;
      const limit = 30;
      const portfolio_id = id;
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      setShowDeleteConfirm(false);
      handleToast(
        true,
        I18n.t('delete_comment'),
        I18n.t('delete_comment_success'),
        'Toast__Container__Success',
        6000,
      );
      dispatch({
        type: RESET_PORTFOLIO_COMMENTS,
      });
    }
  }, [portfolioitemCommentDeleted, dispatch, handleToast, id]);

  useEffect(() => {
    if (portfolioitemCommentEdited === 'success') {
      const page = 1;
      const limit = 30;
      const portfolio_id = id;
      setCommentEdited('');
      setCommentId('');
      setEditComment(false);
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      handleToast(
        true,
        I18n.t('editing_bid_comment_title'),
        I18n.t('editing_bid_comment_success'),
        'Toast__Container__Success',
        6000,
      );
      dispatch({
        type: RESET_PORTFOLIO_COMMENTS,
      });
    }
  }, [portfolioitemCommentEdited, dispatch, handleToast, id]);

  function handleShowReport() {
    setShowReport(true);
  }
  function handleHideReport() {
    setShowReport(false);
  }

  function handleDeleteOnClick() {
    dispatch(deleteportfolioitemComment({id: deleteCommentId}));
  }

  function onSignInClick() {
    history.push(`/signin?path=${location.pathname}`);
  }

  function onSignUpClick() {
    history.push(`/signup?path=${location.pathname}`);
  }

  const createMarkup = text => {
    return {__html: text};
  };

  return (
    <Fragment>
      <ReportContentModal
        onHide={handleHideReport}
        show={showReport}
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={postReportLoading}
        model="portfolio_comments"
        model_id={commentId}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <DeleteModal
        onHide={handleDeleteConfirmClose}
        onDelete={handleDeleteOnClick}
        show={showDeleteConfirm}
        modalTitle={'delete_comment'}
        className="CustomPopup"
        size="md"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={deleteCommentLoading}
        messageHeading="delete_conf"
        messageBody={deleteComment}
      />
      <Card>
        <Card.Header>
          <div>
            <span className="icon icon-comment"></span>{' '}
            <Translate value="comments" />
          </div>
        </Card.Header>
        <Card.Body>
          {isAuthenticated && editComment === false ? (
            <Form>
              <QuillBox
                required
                controlId="Comment"
                label={I18n.t('add_comment')}
                value={comment}
                onChange={handleCommentChange}
                onBlur={onCommentBlur}
                feedbackType="invalid"
                feedback={localErrors.comment ? localErrors.comment : null}
                isInvalid={
                  (ERROR_ACTION_TYPE === 'ADD_PORTFOLIO_COMMENT_FAIL' &&
                    errors.comment) ||
                  localErrors.comment
                }
              />
              <GRecaptch setIsHuman={setIsHuman} className="my-3" />
              <ButtonWithLoading
                variant="primary"
                type="submit"
                onClick={addComment}
                data-backdrop="static"
                loading={addCommentLoading ? addCommentLoading : null}>
                <Translate value="add" />
              </ButtonWithLoading>
            </Form>
          ) : editComment === true ? null : (
            <div className="SignTo">
              <span className="icon icon-lock"></span>
              <h4>
                <Translate value="join_conversation" />
              </h4>
              <p>
                {I18n.t('add_feedback', {
                  fname: portfolioItem.user.fname,
                  lname: portfolioItem.user.lname,
                })}
              </p>
              <div className="SignTo__Actions">
                <button className="btn btn-primary" onClick={onSignInClick}>
                  <span className="icon icon-user"></span>{' '}
                  <Translate value="signin" />
                </button>
                <button className="btn btn-primary" onClick={onSignUpClick}>
                  <span className="icon icon-add-user"></span>{' '}
                  <Translate value="signup" />
                </button>
              </div>
            </div>
          )}

          {portfolioCommentsLoading ? (
            <Spin />
          ) : portfolioitemComments.length > 0 ? (
            <Fragment>
              <ul className="CommentsList mt-4">
                {portfolioitemComments.map(comment => {
                  return (
                    <li key={comment.id}>
                      <div className="Comment__userInfo">
                        <div className="UserPic">
                          <img
                            src={
                              comment.user.image.url
                                ? comment.user.image.url
                                : comment.user.image
                            }
                            alt={comment.user.fname + ' ' + comment.user.lname}
                          />
                        </div>
                        <div className="CommentContent__Details">
                          <h3>
                            <Link
                              to={{pathname: `/in/${comment.user.username}`}}>
                              {comment.user.fname + ' ' + comment.user.lname}
                            </Link>
                          </h3>
                          <div className="CommentContent__Details__PostTime">
                            {comment.created_at.Date +
                              ' - ' +
                              comment.created_at.Time}
                          </div>
                        </div>
                      </div>
                      <div className="CommentContent">
                        {editComment === true && commentId === comment.id ? (
                          <Form>
                            <QuillBox
                              required
                              controlId="Comment"
                              label={I18n.t('edit_comment')}
                              value={commentEdited}
                              onChange={handleCommentEdit}
                              onBlur={onCommentEditBlur}
                              feedbackType="invalid"
                              feedback={
                                localErrors.commentEdited
                                  ? localErrors.commentEdited
                                  : null
                              }
                              isInvalid={
                                (ERROR_ACTION_TYPE ===
                                  'EDIT_PORTFOLIO_COMMENT_FAIL' &&
                                  errors.comment) ||
                                localErrors.commentEdited
                              }
                            />
                            <GRecaptch
                              setIsHuman={setIsHuman}
                              className="my-3"
                            />
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={onSubmitEditComment}
                              data-backdrop="static"
                              loading={
                                editCommentLoading ? editCommentLoading : null
                              }>
                              <Translate value="save" />
                            </ButtonWithLoading>
                            <Button
                              variant="secondary"
                              type="button"
                              onClick={cancelEditComment}
                              data-backdrop="static">
                              <Translate value="cancel" />
                            </Button>
                          </Form>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              comment.comment,
                            )}></div>
                        )}
                      </div>
                      {isAuthenticated ? (
                        user.id === comment.user.id ? (
                          editComment === false ? (
                            <div className="Comments__actions">
                              <button
                                className="btn btn-link"
                                onClick={() => editCommentFun(comment)}>
                                <span className="icon icon-edit"></span>{' '}
                                <Translate value="edit" />
                              </button>
                              <button
                                className="btn btn-link-delete"
                                onClick={() =>
                                  handleDeleteConfirmShow(
                                    comment.id,
                                    comment.comment,
                                  )
                                }>
                                <span className="icon icon-delete"></span>{' '}
                                <Translate value="delete" />
                              </button>
                            </div>
                          ) : null
                        ) : (
                          <div className="Comments__actions">
                            <button
                              className="btn btn-link"
                              onClick={() => {
                                handleShowReport();
                                setCommentId(comment.id);
                              }}>
                              <span className="icon icon-report"></span>{' '}
                              <Translate value="report_content" />
                            </button>
                          </div>
                        )
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              <div className="row">
                <div className="col-12 my-4">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            </Fragment>
          ) : null}
        </Card.Body>
      </Card>
    </Fragment>
  );
}
