import React, {useState, useEffect, useCallback} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {Translate, I18n} from 'react-redux-i18n';
import {useSelector, useDispatch} from 'react-redux';
import TextInput from '../TextInput';
import {SelectBox} from '../SelectBox';
import QuillBox from '../QuillBox';
import {CreatableSkills} from '../CreatableSkills';
import {CreatableKeywords} from '../CreatableKeywords';
import {clearErrors} from '../../actions/errorActions';
import {addProject} from '../../redux/project/actions/addActions';
import {deleteFile} from '../../actions/deletefileActions';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import TYPES from '../../redux/project/actions/types';
import {Uploader} from '../Upload';
//import { ServerURL } from "../Server";
import GRecaptch from '../GRecaptch';
import ErrorBox from '../ErrorBox';

const {ADD_PROJECT_FAIL} = TYPES;

var mime = require('mime-types');

export default function AddingProjectModal(props) {
  const dispatch = useDispatch();

  const {
    cats,
    errorsId,
    errorsMsg: {returnedErrors = {}},
    projectsPrices,
    getProjectsPricesLoading,
    addedProject,
    editingProjectSuccess,
    addingProjectSuccess,
    locale,
  } = useSelector(state => ({
    cats: state.category.cats,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    projectsPrices: state.project.projectsPrices || [],
    projects: state.project.projects || [],
    user: state.auth.user,
    getProjectsPricesLoading: state.project.getProjectsPricesLoading,
    addedProject: state.project.addedProject,
    editingProjectSuccess: state.project.editingProjectSuccess,
    addingProjectSuccess: state.project.addingProjectSuccess,
    locale: state.i18n.locale,
  }));

  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [localErrors, setLocalErrors] = useState({});
  const [skills, setSkills] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const [title, setTitle] = useState('');
  const [promo, setPromo] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');

  const [chosenProjectPrice, setChosenProjectPrice] = useState({});
  const [id, setId] = useState('');
  const [isHuman, setIsHuman] = useState(false);

  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_PROJECT_FAIL);

  //const [image, setImage] = useState([]);
  //const [fileUpload, setFileUpload] = useState(false);
  //const [fileChosen, setFileChosen] = useState(false);
  //const [uploadErrors, setUploadErrors] = useState([]);
  //const [imageSortLength, setImageSortLength] = useState(false);
  //const [filteredErrorsLength, setFilteredErrorsLength] = useState(false);

  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState(false);
  const [imagesChosen, setImagesChosen] = useState(false);
  const [imagesUploadErrors, setImagesUploadErrors] = useState([]);
  const [imagesSortLength, setImagesSortLength] = useState(false);
  const [filteredImagesErrorsLength, setFilteredImagesErrorsLength] =
    useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  // const filteredErrors = uploadErrors ? uploadErrors.reduce(
  //     (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
  //     [],
  // ) : null;

  const filteredImagesErrors = imagesUploadErrors
    ? imagesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  // useEffect(()=> {
  //     if(filteredErrors.length > 0){
  //       setFilteredErrorsLength(true)
  //     }
  // }, [filteredErrors]);

  useEffect(() => {
    if (filteredImagesErrors.length > 0) {
      setFilteredImagesErrorsLength(true);
    }
  }, [filteredImagesErrors]);

  // useEffect(() => {
  //     if(image.length > 0){
  //         setImageSortLength(true)
  //     }
  // }, [image]);

  useEffect(() => {
    if (images.length > 0) {
      setImagesSortLength(true);
    }
  }, [images]);

  useEffect(() => {
    if (errorsId && returnedErrors) {
      setErrorActionType(errorsId);
    }
  }, [errorsId, returnedErrors]);

  const {
    modalTitle,
    projectDetails,
    addSimilarProject,
    loading,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  function isObject(val) {
    return typeof val === 'object';
  }

  const getCategories = useCallback(() => {
    if (cats) {
      return cats.map(item => {
        return {
          ...item,
          value: item.title,
          label: item.title,
        };
      });
    }
    return [];
  }, [cats]);

  const categories = getCategories();

  const getSubCategories = useCallback(
    catId => {
      if (catId) {
        let targetCat = cats.find(item => item.id === catId);
        let {sub_categories = []} = targetCat || {};
        let subsCat = sub_categories.map(item => ({
          ...item,
          value: item.title,
          label: item.title,
        }));
        return subsCat;
      }
      return [];
    },
    [cats],
  );

  const subCategories = getSubCategories(category.id);

  function handleItemLocalError({propName, currentValue, message}) {
    if (currentValue) {
      if (isObject(currentValue) && !Array.isArray(currentValue)) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onPromoChange(e) {
    setPromo(e.target.value);
  }

  function onTitleBlur() {
    handleItemLocalError({
      propName: 'title',
      currentValue: title,
      message: I18n.t('title_required'),
    });
  }

  function handleCatChange(e) {
    setCategory(prevCat => Object.assign(prevCat, e));
    setSubCategory({});
  }

  function onCatBlur() {
    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });
  }

  function handleSubcatChange(e) {
    setSubCategory(prevSubCat => Object.assign(prevSubCat, e));
  }

  function onSubCatBlur() {
    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });
  }

  function handleDescriptionChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setDescription('');
    } else {
      setDescription(value);
    }
  }

  function onDescriptionBlur() {
    handleItemLocalError({
      propName: 'description',
      currentValue: description,
      message: I18n.t('description_required'),
    });
  }

  function handleChosenProjectPrice(chosenPrice) {
    setChosenProjectPrice(prevPrice => Object.assign(prevPrice, chosenPrice));
  }

  function onPriceBlur() {
    handleItemLocalError({
      propName: 'price',
      currentValue: chosenProjectPrice,
      message: I18n.t('project_budget_required'),
    });
  }

  function handleDeadline(e) {
    e.preventDefault();
    setDeadline(e.target.value);
  }

  function onDeadlineBlur() {
    handleItemLocalError({
      propName: 'deadline',
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });
  }

  function handleSkillsChange(skills) {
    setSkills(skills);
  }

  function handleKeywordsChange(keywords) {
    setKeywords(keywords);
  }

  const handleHide = useCallback(() => {
    setLocalErrors({});
    setCategory({});
    setSubCategory({});
    setLocalErrors({});
    setSkills([]);
    setKeywords([]);
    setTitle('');
    setDescription('');
    setDeadline('');
    setChosenProjectPrice({});
    setId('');
    setIsHuman(false);
    setErrorActionType(ADD_PROJECT_FAIL);
    setImages([]);
    setImagesUpload(false);
    setImagesChosen(false);
    setImagesUploadErrors([]);
    setImagesSortLength(false);
    setFilteredImagesErrorsLength(false);
    setDeletedFiles([]);
    props.onHide && props.onHide();
    dispatch(clearErrors());
  }, [dispatch, props]);

  function onSubmit(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'title',
      currentValue: title,
      message: I18n.t('title_required'),
    });

    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });

    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });

    handleItemLocalError({
      propName: 'description',
      currentValue: description,
      message: I18n.t('description_required'),
    });

    handleItemLocalError({
      propName: 'price',
      currentValue: chosenProjectPrice,
      message: I18n.t('project_budget_required'),
    });

    handleItemLocalError({
      propName: 'deadline',
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });

    if (
      //fileUpload === false &&
      //fileChosen === false &&
      imagesUpload === false &&
      imagesChosen === false &&
      title &&
      isObject(category) &&
      Object.keys(category).length > 0 &&
      isObject(subCategory) &&
      Object.keys(subCategory).length > 0 &&
      Object.keys(chosenProjectPrice).length > 0 &&
      description &&
      deadline &&
      isHuman &&
      !localErrors.title &&
      !localErrors.category &&
      !localErrors.subCategory &&
      !localErrors.description &&
      !localErrors.price &&
      !localErrors.deadline
    ) {
      if (deletedFiles.length > 0) {
        deletedFiles.map(item => {
          const model = 'projects';
          const id = projectDetails ? projectDetails.id : null;
          const fileURL = item;
          const fileData = {
            model,
            id,
            fileURL,
          };
          return dispatch(deleteFile(fileData));
        });
      }
      let images_sort = [];
      let projectFiles = [];
      if (images.length > 0) {
        images_sort = images.map(item => item.source);
        projectFiles = images.map(item => item.source);
      }
      const promo_code = promo;
      dispatch(
        addProject(
          title,
          category.id,
          subCategory.id,
          skills,
          keywords,
          description,
          chosenProjectPrice.id,
          deadline,
          null,
          projectFiles,
          images_sort,
          promo_code,
          id && addSimilarProject && addSimilarProject === false ? id : null,
          locale,
        ),
      );
    } else if (
      //fileUpload === true ||
      //fileChosen === true ||
      imagesUpload === true ||
      imagesChosen === true
    ) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        6000,
      );
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Error',
        10000,
      );
    }
  }

  const history = useHistory();
  useEffect(() => {
    setDeletedFiles([]);
    if (
      errorsId === null &&
      loading === false &&
      addingProjectSuccess === true &&
      addedProject.length > 0
    ) {
      const newProject = addedProject[0];
      return (
        history.push(
          `/projects/${newProject.id}/${newProject.title
            .replace(/\s+/g, '-')
            .replace(/\//g, '-')
            .toLowerCase()}`,
        ),
        handleHide()
      );
    }
  }, [
    addedProject,
    errorsId,
    handleHide,
    history,
    loading,
    addingProjectSuccess,
  ]);

  useEffect(() => {
    setDeletedFiles([]);
    if (
      errorsId === null &&
      loading === false &&
      editingProjectSuccess === true &&
      addedProject.length > 0
    ) {
      return handleHide();
    }
  }, [addedProject, errorsId, handleHide, loading, editingProjectSuccess]);

  useEffect(() => {
    if (errorsId === 'DELETE_FILE_FAIL') {
      handleToast(
        true,
        I18n.t('delete_file'),
        I18n.t('delete_file_error'),
        'Toast__Container__Error',
        6000,
      );
    }
  }, [errorsId, handleToast]);

  useEffect(() => {
    if (projectDetails && addSimilarProject === true && props.show === true) {
      setTitle(projectDetails.title);
      setDescription(projectDetails.description);
      setDeadline(projectDetails.deadline);
      let categories = getCategories();
      if (projectDetails.category) {
        let category =
          categories.find(({id}) => id === projectDetails.category.id) || [];
        setCategory(category);
        if (projectDetails.sub_category) {
          let subCat = getSubCategories(projectDetails.category.id).filter(
            ({id}) => id === projectDetails.sub_category.id,
          );
          setSubCategory(subCat[0]);
        }
      }
      setSkills(projectDetails.skills);
      setKeywords(projectDetails.keywords);
      setChosenProjectPrice({
        ...projectDetails.price,
        label: projectDetails.price.title,
        value: projectDetails.price.title,
      });
      setId(projectDetails.id);
    } else if (
      projectDetails &&
      addSimilarProject === false &&
      props.show === true
    ) {
      setTitle(projectDetails.title);
      setDescription(projectDetails.description);
      setDeadline(projectDetails.deadline);
      let categories = getCategories();
      if (projectDetails.category) {
        let category =
          categories.find(({id}) => id === projectDetails.category.id) || [];
        setCategory(category);
        if (projectDetails.sub_category) {
          let subCat = getSubCategories(projectDetails.category.id).filter(
            ({id}) => id === projectDetails.sub_category.id,
          );
          setSubCategory(subCat[0]);
        }
      }
      setSkills(projectDetails.skills);
      setKeywords(projectDetails.keywords);
      // if(isObject(projectDetails.image) && projectDetails.image.url.includes(`uploads/`)){
      //   let imageCopy = [];
      //   imageCopy[0] = {
      //     loaded: projectDetails.image.size,
      //     name: projectDetails.image.filename,
      //     origin: projectDetails.image.filename,
      //     path: projectDetails.image.filename,
      //     percent: 100,
      //     preview: projectDetails.image.url,
      //     size: projectDetails.image.size,
      //     source: projectDetails.image.url,
      //     total: projectDetails.image.size,
      //     type: `image/${projectDetails.image.extension}`
      //   };
      //   setImage(imageCopy);
      // }else{
      //   let imageCopy = [];
      //   imageCopy[0] = {
      //     loaded: 100257,
      //     name: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
      //     origin: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
      //     path: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
      //     percent: 100,
      //     preview: `${ServerURL}/public/images/no-image.png`,
      //     size: 100257,
      //     source: `${ServerURL}/public/images/no-image.png`,
      //     total: 100257,
      //     type: "image/jpg"
      //   };
      //   setImage(imageCopy);
      // }
      setImages(
        projectDetails.files.map(item => ({
          loaded: item.size,
          name: item.filename,
          origin: item.filename,
          path: item.filename,
          percent: 100,
          preview: item.url,
          size: item.size,
          source: item.url,
          total: item.size,
          type: mime.lookup(item.extension),
          //`image/${item.extension}`
        })),
      );
      let images_sort = [];
      if (projectDetails.files.length > 0) {
        projectDetails.files.map((item, index) =>
          images_sort.push([
            {uploadedImage: item.url},
            {
              localImage: (
                <img src={item.url} key={index} alt={projectDetails.title} />
              ),
            },
          ]),
        );
      }
      setChosenProjectPrice({
        ...projectDetails.price,
        label: projectDetails.price.title,
        value: projectDetails.price.title,
      });
      setId(projectDetails.id);
    }
  }, [
    projectDetails,
    getCategories,
    getSubCategories,
    addSimilarProject,
    props.show,
  ]);

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props['aria-labelledby']}>
      <Form>
        <Modal.Header>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            required
            controlId="Title"
            label="project_title"
            type="text"
            name="title"
            value={title}
            onChange={onTitleChange}
            onBlur={onTitleBlur}
            feedbackType="invalid"
            desc="project_title_placeholder"
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.title) ||
              localErrors.title
            }
            feedback={
              localErrors.title
                ? localErrors.title
                : returnedErrors.title
                ? returnedErrors.title[0]
                : null
            }
          />

          <div className="row">
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Category"
                name="category"
                label="category"
                options={categories}
                value={category}
                onChange={handleCatChange}
                onBlur={onCatBlur}
                feedbackType="invalid"
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && returnedErrors.cat_id) ||
                  localErrors.category
                }
                feedback={
                  localErrors.category
                    ? localErrors.category
                    : returnedErrors.cat_id
                    ? returnedErrors.cat_id[0]
                    : null
                }
                placeholder={I18n.t('category')}
              />
            </div>
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Sub-Category"
                name="sub_category"
                label="sub_category"
                options={subCategories}
                value={subCategory}
                onChange={handleSubcatChange}
                onBlur={onSubCatBlur}
                feedbackType="invalid"
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && returnedErrors.cat_id) ||
                  (errorsId === ERROR_ACTION_TYPE &&
                    returnedErrors.sub_cat_id) ||
                  localErrors.subCategory
                }
                feedback={
                  localErrors.subCategory
                    ? localErrors.subCategory
                    : returnedErrors.sub_cat_id
                    ? returnedErrors.sub_cat_id[0]
                    : null
                }
                placeholder={I18n.t('sub_category')}
                noOptionsMessage={
                  Object.keys(category).length > 0
                    ? I18n.t('select_no_options')
                    : I18n.t('choose_cat_first')
                }
              />
            </div>
          </div>

          <CreatableSkills
            onChange={handleSkillsChange}
            feedbackType="invalid"
            required={false}
            value={skills}
          />

          <QuillBox
            required
            controlId="Description"
            label="description"
            value={description}
            onChange={handleDescriptionChange}
            onBlur={onDescriptionBlur}
            feedbackType="invalid"
            desc="project_desc_placeholder"
            feedback={
              localErrors.description
                ? localErrors.description
                : returnedErrors.description
                ? returnedErrors.description[0]
                : null
            }
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.description) ||
              localErrors.description
            }
          />

          <SelectBox
            loading={getProjectsPricesLoading}
            required
            controlId="Budget"
            name="budget"
            label="project_budget"
            options={projectsPrices.map(price => ({
              ...price,
              label: price.title + I18n.t('dollar'),
              value: price.title,
            }))}
            value={chosenProjectPrice}
            onChange={handleChosenProjectPrice}
            onBlur={onPriceBlur}
            feedbackType="invalid"
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.price_id) ||
              localErrors.price
            }
            feedback={
              localErrors.price
                ? localErrors.price
                : returnedErrors.price_id
                ? returnedErrors.price_id[0]
                : null
            }
            placeholder={I18n.t('budget')}
          />

          <TextInput
            name="deadline"
            onChange={handleDeadline}
            onBlur={onDeadlineBlur}
            controlId="ProjectDelivery"
            label="number_to_deliver_project"
            type="number"
            value={deadline}
            required
            feedbackType="invalid"
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.deadline) ||
              localErrors.deadline
            }
            feedback={
              localErrors.deadline
                ? localErrors.deadline
                : returnedErrors.deadline
                ? returnedErrors.deadline[0]
                : null
            }
            prepandText="day_s"
          />

          <CreatableKeywords
            onChange={handleKeywordsChange}
            feedbackType="invalid"
            value={keywords}
            required={false}
          />

          {/*<Form.Group controlId="SmallImage">
            <Form.Label>
              <Translate value="thumbnail_image" />
            </Form.Label>
            <Uploader 
              accept={[
                {
                  mime: "image/jpeg",
                  ext: "jpeg"
                }, 
                {
                  mime: "image/jpg",
                  ext: "jpg"
                }, 
                {
                  mime: "image/png",
                  ext: "png"
                }
              ]}
              maxFiles={1} 
              multiple={false}
              maxSize={1000000}
              sortable={false}
              setFilesChosen={setFileChosen}
              setFilesUpload={setFileUpload}
              setUploadErrors={setUploadErrors}
              uploadErrors={uploadErrors}
              setFilesSort={setImage}
              filesSort={image}
              filesSortLength={imageSortLength}
              filteredErrorsLength={filteredErrorsLength}
              filteredErrors={filteredErrors}
              uploadPath="services"
              projectId= {projectDetails ? projectDetails.id : null}
            />
            {localErrors.image || (returnedErrors && returnedErrors.image) ? (
              <Feedback type="d-block invalid">{localErrors.image}</Feedback>
            ) : null}
            </Form.Group>*/}

          <Form.Group controlId="Attachments">
            <Form.Label>
              <Translate value="attachments" />
            </Form.Label>
            <small>{I18n.t('project_files_hint')}</small>
            <Uploader
              accept={[
                {
                  mime: 'application/msword',
                  ext: 'doc',
                },
                {
                  mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  ext: 'docx',
                },
                {
                  mime: 'application/vnd.ms-powerpoint',
                  ext: 'ppt',
                },
                {
                  mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                  ext: 'pptx',
                },
                {
                  mime: 'application/vnd.ms-excel',
                  ext: 'xls',
                },
                {
                  mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  ext: 'xlsx',
                },
                {
                  mime: 'application/pdf',
                  ext: 'pdf',
                },
                {
                  mime: 'image/jpeg',
                  ext: 'jpeg',
                },
                {
                  mime: 'image/gif',
                  ext: 'gif',
                },
                {
                  mime: 'image/png',
                  ext: 'png',
                },
                {
                  mime: 'application/rtf',
                  ext: 'rtf',
                },
                {
                  mime: 'text/plain',
                  ext: 'plain-text',
                },
                {
                  mime: 'application/x-rar',
                  ext: 'rar',
                },
                {
                  mime: 'application/zip',
                  ext: 'zip',
                },
              ]}
              maxFiles={10}
              multiple={true}
              maxSize={20000000}
              sortable={true}
              setFilesChosen={setImagesChosen}
              setFilesUpload={setImagesUpload}
              setUploadErrors={setImagesUploadErrors}
              uploadErrors={imagesUploadErrors}
              setFilesSort={setImages}
              filesSort={images}
              filesSortLength={imagesSortLength}
              filteredErrorsLength={filteredImagesErrorsLength}
              filteredErrors={filteredImagesErrors}
              uploadPath="projects"
              projectId={projectDetails ? projectDetails.id : null}
              deletedFiles={deletedFiles}
              setDeletedFiles={setDeletedFiles}
              optimize={1}
            />
          </Form.Group>
          {errorsId === 'DELETE_FILE_FAIL' && deletedFiles.length > 0 ? (
            <ErrorBox message={I18n.t('delete_file_error')} />
          ) : null}

          <TextInput
            required={false}
            controlId="DiscountCoupon"
            label="discount_coupon"
            type="text"
            name="discount"
            value={promo}
            onChange={onPromoChange}
            feedbackType="invalid"
            desc="discount_desc"
          />

          <GRecaptch setIsHuman={setIsHuman} />

          {errorsId === 'ADD_PROJECT_FAIL' &&
          returnedErrors === 'Network Error' ? (
            <ErrorBox message={I18n.t('network_error_message')} />
          ) : errorsId === 'ADD_PROJECT_FAIL' &&
            returnedErrors === 'Other Errors' ? (
            <ErrorBox message={I18n.t('something_error_message')} />
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
            variant="primary"
            type="submit"
            onClick={onSubmit}
            data-backdrop="static"
            loading={loading}>
            <Translate value="submit" />
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}
            disabled={loading}>
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
