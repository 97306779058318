// Dependency
import React, {Fragment, useState, useEffect} from 'react';
import LazyLoad from 'react-lazyload';
import {Switch, Route} from 'react-router-dom';
import {loadUser} from './actions/authActions';
import {useDispatch, useSelector} from 'react-redux';
import {getDir} from './redux/selectors/i18n';
import {Translate} from 'react-redux-i18n';

// StyleSheets
import 'normalize.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './imgs/icons/icons.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import './styles/styles.scss';

import ScrollToTop from './ScrollToTop';

// App Main Component
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import LockCard from './components/LockCard';

// App Home Page Component
// import Home from './components/Home/Home';

// App Sign Components
import Signup from './components/Sign/Signup';
import Signin from './components/Sign/Signin';
import VerifyAccount from './components/Sign/VerifyAccount';
import ForgotPassword from './components/Sign/ForgotPassword';

//Dashboard Pages Components
import Navbar from './components/ProfilePage/Navbar';
import Dashboard from './components/UserDashboard/Dashboard';
import MyProfile from './components/UserDashboard/MyProfile';
import MyProjects from './components/UserDashboard/MyProjects';
//import MyContests from "./components/UserDashboard/MyContests";
import MyFavourites from './components/UserDashboard/MyFavourites';
import MyPortfolio from './components/UserDashboard/MyPortfolio';
import MyFollowers from './components/UserDashboard/MyFollowers';
import MyArticles from './components/UserDashboard/MyArticles';
import MyServices from './components/UserDashboard/MyServices';
import Withdrawals from './components/UserDashboard/Withdrawals';

// App Pages Components
import ProjectList from './components/Projects/ProjectList';
import ProjectDetails from './components/Projects/ProjectDetails';
import AddingProject from './components/AddingProjectPage';
// import ContestList from "./components/Contests/ContestList";
// import ContestDetails from "./components/Contests/ContestDetails";
// import ContestEntryDetails from "./components/Contests/ContestEntryDetails";
import PortfolioList from './components/Portfolio/PortfolioList';
import PortfolioDetails from './components/Portfolio/PortfolioDetails';
import ServicesList from './components/Services/ServicesList';
import ServiceDetails from './components/Services/ServiceDetails';
import ServiceInvoiceDetails from './components/Services/ServiceInvoiceDetails';
import FreelancersList from './components/FreelancersPage/FreeancersList';
import ProfilePage from './components/ProfilePage/Profile';
import ArticlesList from './components/Articles/ArticlesList';
import ArticleDetails from './components/Articles/ArticleDetails';

// Confirmation Toast Component
// import ConfToast from './components/ConfToast';
import Spin from './components/Spin';
import How from './components/WebsitePages/How';
import Terms from './components/WebsitePages/Terms';
import Privacy from './components/WebsitePages/Privacy';
import Addons from './components/WebsitePages/Addons';
import Contact from './components/WebsitePages/Contact';
import About from './components/WebsitePages/About';

import MyNotifications from './components/UserDashboard/MyNotifications';
import MyMessages from './components/UserDashboard/MyMessages';
import MessageDetails from './components/Messages/MessageDetails';

//Pusher Channels
import Channels from './components/Pusher/Channels';

//Landing Page
import LandingPage from './components/LandingPage';

//RSS Page
import RssFeed from './components/RssFeed';

//HomeTwo
import HomeTwo from './components/Home/HomeTwo';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default function AppWrapper(props) {
  const dispatch = useDispatch();

  const {dir, isAuthenticated, isLoading, user} = useSelector(state => ({
    dir: getDir(state),
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
    user: state.userData.user,
  }));

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(loadUser());
    }
  }, [dispatch, isAuthenticated]);

  const [isClient, setIsClient] = useState(false);

  const [Links, setLinks] = useState([]);

  useEffect(() => {
    if (!isClient) {
      setLinks([
        {
          id: 1,
          to: '/',
          icon: 'icon icon-dashboard',
          title: <Translate value="dashboard" />,
        },
        {
          id: 2,
          to: '/dashboard/profile',
          icon: 'icon icon-user',
          title: <Translate value="my_profile" />,
        },
        {
          id: 3,
          to: '/dashboard/withdrawal-accounts',
          icon: 'icon icon-budget',
          title: <Translate value="withdrawal_accounts" />,
        },
        {
          id: 4,
          to: '/dashboard/notifications',
          icon: 'icon icon-notification',
          title: <Translate value="notifications" />,
        },
        {
          id: 5,
          to: '/dashboard/messages',
          icon: 'icon icon-envelope',
          title: <Translate value="messages" />,
        },
        {
          id: 6,
          to: '/dashboard/projects',
          icon: 'icon icon-projects',
          title: <Translate value="projects" />,
        },
        // {
        //   id: 7,
        //   to: "/dashboard/contests",
        //   icon: "icon icon-contests",
        //   title: <Translate value="contests" />,
        // },
        {
          id: 8,
          to: '/dashboard/services',
          icon: 'icon icon-services',
          title: <Translate value="services" />,
        },
        {
          id: 9,
          to: '/dashboard/favourites',
          icon: 'icon icon-favourite',
          title: <Translate value="favourite" />,
        },
        {
          id: 10,
          to: '/dashboard/portfolio',
          icon: 'icon icon-business-case',
          title: <Translate value="portfolio" />,
        },
        {
          id: 11,
          to: '/dashboard/followers',
          icon: 'icon-add-friend',
          title: <Translate value="followers" />,
        },
        {
          id: 12,
          to: '/dashboard/articles',
          icon: 'icon icon-articles',
          title: <Translate value="articles" />,
        },
      ]);
    } else {
      setLinks([
        {
          id: 1,
          to: '/',
          icon: 'icon icon-dashboard',
          title: <Translate value="dashboard" />,
        },
        {
          id: 2,
          to: '/dashboard/profile',
          icon: 'icon icon-user',
          title: <Translate value="my_profile" />,
        },
        {
          id: 3,
          to: '/dashboard/withdrawal-accounts',
          icon: 'icon icon-budget',
          title: <Translate value="withdrawal_accounts" />,
        },
        {
          id: 4,
          to: '/dashboard/notifications',
          icon: 'icon icon-notification',
          title: <Translate value="notifications" />,
        },
        {
          id: 5,
          to: '/dashboard/messages',
          icon: 'icon icon-envelope',
          title: <Translate value="messages" />,
        },
        {
          id: 6,
          to: '/dashboard/projects',
          icon: 'icon icon-projects',
          title: <Translate value="projects" />,
        },
        // {
        //   id: 7,
        //   to: "/dashboard/contests",
        //   icon: "icon icon-contests",
        //   title: <Translate value="contests" />,
        // },
        {
          id: 8,
          to: '/dashboard/services',
          icon: 'icon icon-services',
          title: <Translate value="services" />,
        },
        {
          id: 9,
          to: '/dashboard/favourites',
          icon: 'icon icon-favourite',
          title: <Translate value="favourite" />,
        },
        {
          id: 11,
          to: '/dashboard/followers',
          icon: 'icon-add-friend',
          title: <Translate value="followers" />,
        },
      ]);
    }
  }, [isClient]);

  useEffect(() => {
    document.dir = dir;
  }, [dir]);

  const [landing, setLanding] = useState(false);
  const [rss, setRss] = useState(false);

  const currentLocation =
    typeof window !== 'undefined' ? window.location.pathname : '/';

  useEffect(() => {
    currentLocation === '/landing-page' ? setLanding(true) : setLanding(false);
    currentLocation === '/rss' ? setRss(true) : setRss(false);
  }, [currentLocation]);

  useEffect(() => {
    if (user && user.memberships && user.memberships.length >= 1) {
      user.memberships.map(item => {
        if ((item.id === 3 || item.id === 4) && item.default) {
          return setIsClient(true);
        } else {
          return setIsClient(false);
        }
      });
    }
  }, [user]);

  return (
    <div id="wrapper">
      <ScrollToTop />
      <Channels />
      {rss || landing ? null : <Header />}
      <div className="main__wrapper">
        <div className="main__wrapper__content">
          <Switch>
            {/* Full Page Without Header and Footer */}
            <Route exact path="/rss">
              <LazyLoad height={400}>
                <RssFeed />
              </LazyLoad>
            </Route>
            <Route exact path="/landing-page">
              <LazyLoad height={400}>
                <LandingPage />
              </LazyLoad>
            </Route>
            {/* Sign Components */}
            <Route exact path="/signup">
              <LazyLoad height={400}>
                <Signup />
              </LazyLoad>
            </Route>
            <Route exact path="/signin">
              <LazyLoad height={400}>
                <Signin />
              </LazyLoad>
            </Route>
            <Route exact path="/reset-password">
              <LazyLoad height={400}>
                <ForgotPassword />
              </LazyLoad>
            </Route>
            <Route exact path="/verify-account">
              <LazyLoad height={400}>
                <VerifyAccount />
              </LazyLoad>
            </Route>
            {/* Projects */}
            <Route exact path="/projects">
              <LazyLoad height={400}>
                <ProjectList />
              </LazyLoad>
            </Route>
            <Route
              path="/projects/:id/:title"
              render={props => (
                <LazyLoad height={400}>
                  <ProjectDetails {...props} />
                </LazyLoad>
              )}
            />
            <Route
              path="/projects/create"
              render={props => (
                <LazyLoad height={400}>
                  <AddingProject {...props} />
                </LazyLoad>
              )}
            />
            {/* Contests 
              <Route exact path="/contests" component={ContestList} />
              <Route exact path="/contests/:id/:title" component={ContestDetails} />
              <Route path="/contests/:id/:title/entry/:entryId" component={ContestEntryDetails} />
              */}
            {/* ShowCase */}
            <Route exact path="/portfolio">
              <LazyLoad height={400}>
                <PortfolioList />
              </LazyLoad>
            </Route>
            <Route
              path="/portfolio/:id/:title"
              render={props => (
                <LazyLoad height={400}>
                  <PortfolioDetails {...props} />
                </LazyLoad>
              )}
            />
            {/* Services */}
            <Route exact path="/services">
              <LazyLoad height={400}>
                <ServicesList />
              </LazyLoad>
            </Route>
            <Route
              exact
              path="/services/:id/:title"
              render={props => (
                <LazyLoad height={400}>
                  <ServiceDetails {...props} />
                </LazyLoad>
              )}
            />
            <Route
              path="/services/invoices/:id/:title"
              render={props => (
                <LazyLoad height={400}>
                  <ServiceInvoiceDetails {...props} />
                </LazyLoad>
              )}
            />
            {/* Articles */}
            <Route exact path="/articles">
              <LazyLoad height={400}>
                <ArticlesList />
              </LazyLoad>
            </Route>
            <Route
              exact
              path="/articles/:id/:title"
              render={props => (
                <LazyLoad height={400}>
                  <ArticleDetails {...props} />
                </LazyLoad>
              )}
            />
            {/* Freelancers */}
            <Route exact path="/freelancers">
              <LazyLoad height={400}>
                <FreelancersList />
              </LazyLoad>
            </Route>
            <Route
              path="/in/:username"
              render={props => (
                <LazyLoad height={400}>
                  <ProfilePage {...props} />
                </LazyLoad>
              )}
            />
            <Route exact path="/about-us">
              <LazyLoad height={400}>
                <About />
              </LazyLoad>
            </Route>
            <Route exact path="/how-it-works">
              <LazyLoad height={400}>
                <How />
              </LazyLoad>
            </Route>
            <Route exact path="/terms-and-conditions">
              <LazyLoad height={400}>
                <Terms />
              </LazyLoad>
            </Route>
            <Route exact path="/privacy-policy">
              <LazyLoad height={400}>
                <Privacy />
              </LazyLoad>
            </Route>
            <Route exact path="/service-improvements">
              <LazyLoad height={400}>
                <Addons />
              </LazyLoad>
            </Route>
            <Route exact path="/contact-us">
              <LazyLoad height={400}>
                <Contact />
              </LazyLoad>
            </Route>
            {/*<Route exact path="/" component={isAuthenticated ? Dashboard : Home} />*/}
            {/* Dashboard */}
            {isLoading ? (
              <Spin />
            ) : isAuthenticated ? (
              <Fragment>
                <div className="loggedin">
                  <div className="LoggedNav">
                    <Navbar Links={Links} />
                  </div>
                  <Route exact path="/">
                    <LazyLoad height={400}>
                      <Dashboard />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/profile">
                    <LazyLoad height={400}>
                      <MyProfile />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/withdrawal-accounts">
                    <LazyLoad height={400}>
                      <Withdrawals />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/projects">
                    <LazyLoad height={400}>
                      <MyProjects />
                    </LazyLoad>
                  </Route>
                  {/* 
                    <Route path="/dashboard/contests">
                      <LazyLoad height={400}>
                        <MyContests />
                      </LazyLoad>
                    </Route>
                    */}
                  <Route path="/dashboard/services">
                    <LazyLoad height={400}>
                      <MyServices />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/favourites">
                    <LazyLoad height={400}>
                      <MyFavourites />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/portfolio">
                    <LazyLoad height={400}>
                      <MyPortfolio />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/followers">
                    <LazyLoad height={400}>
                      <MyFollowers />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/articles">
                    <LazyLoad height={400}>
                      <MyArticles />
                    </LazyLoad>
                  </Route>
                  <Route path="/dashboard/notifications">
                    <LazyLoad height={400}>
                      <MyNotifications />
                    </LazyLoad>
                  </Route>
                  <Route exact path="/dashboard/messages">
                    <LazyLoad height={400}>
                      <MyMessages />
                    </LazyLoad>
                  </Route>
                  <Route
                    exact
                    path="/dashboard/messages/:id/:title"
                    render={props => (
                      <LazyLoad height={400}>
                        <MessageDetails {...props} />
                      </LazyLoad>
                    )}
                  />
                </div>
              </Fragment>
            ) : !isAuthenticated &&
              !isLoading &&
              currentLocation.includes('dashboard') ? (
              <LazyLoad height={400}>
                <LockCard
                  title="sign_to_view"
                  signFor="sign_dashboard_preview"
                  fullPage={true}
                />
              </LazyLoad>
            ) : (
              <Route exact path="/">
                <LazyLoad height={400}>
                  <HomeTwo />
                </LazyLoad>
              </Route>
            )}
          </Switch>
        </div>
      </div>
      {rss || landing ? null : (
        <LazyLoad height={400}>
          <Footer />
        </LazyLoad>
      )}
    </div>
  );
}
