import React, { useState, useEffect, useCallback, Fragment, useRef } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../../TextInput";
import { clearErrors } from "../../../actions/errorActions";
import { verifyAccount, sendVerification } from "../../../actions/verifyaccountActions";
import { RESET_VERIFICATION } from "../../../actions/types";
import ButtonWithLoading from "../../Buttons/ButtonWithLoading";
import ErrorBox from "../../ErrorBox";
import Spin from "../../Spin";
import { Link } from "react-router-dom";

export default function VerifyAccountModal(props) {
  const dispatch = useDispatch();
  const Ref = useRef(null);

  const {
    locale,
    verifySuccess,
    registerLoading,
    sendVerificationLoading,
    sendVerificationSuccess,
    errorId,
    errorMessage,
    user,
  } = useSelector((state) => ({
    locale: state.i18n.locale,
    verifySuccess: state.verifyAccount.verifySuccess,
    registerLoading: state.auth.registerLoading,
    sendVerificationLoading: state.verifyAccount.sendVerificationLoading,
    sendVerificationSuccess: state.verifyAccount.sendVerificationSuccess,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    user: state.auth.user,
  }));

  const [localErrors, setLocalErrors] = useState({});

  const [verificationCode, setVerificationCode] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState('00:00');

  const {
    modalTitle,
    loading,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay
  } = props;

  function handleItemLocalError({ propName, currentValue, message }) {
    if(currentValue){
        setLocalErrors((errors) => ({
          ...errors,
          [propName]: undefined,
        }));
    }else{
      setLocalErrors((errors) => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  const getTimeRemaining = useCallback(
    (e) => {
      const total = Date.parse(e) - Date.parse(new Date());
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 / 60 / 60) % 24);
      return {
          total, hours, minutes, seconds
      };
    },
    []
  );

  const startTimer = useCallback(
    (e) => {
      let { total, minutes, seconds } = getTimeRemaining(e);
      if (total >= 0) {
          setTimer(
              (minutes > 9 ? minutes : '0' + minutes) + ':'
              + (seconds > 9 ? seconds : '0' + seconds)
          )
      }
    },
    [setTimer, getTimeRemaining]
  );

  const clearTimer = useCallback(
    (e) => {
      setTimer('02:00');
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
          startTimer(e);
      }, 1000)
      Ref.current = id;
    },
    [Ref, setTimer, startTimer]
  );

  const getDeadTime = () => {
      let deadline = new Date();
      deadline.setMinutes(deadline.getMinutes() + 2);
      return deadline;
  }

  useEffect(() => {
    if(isDisabled){
      clearTimer(getDeadTime());
    }
  }, [isDisabled, clearTimer]);

  function setBtnToDefault(){
    setTimeout(() => {
      setIsDisabled(false);
    }, 120000);
  }

  useEffect(() => {
    if(sendVerificationSuccess){
      setIsDisabled(true);
      setBtnToDefault();
      dispatch({
        type: RESET_VERIFICATION
      })
    }
  }, [sendVerificationSuccess, dispatch]);

  function onVerifyChange(e) {
    setVerificationCode(e.target.value);
  }

  function onVerifyBlur(){
    handleItemLocalError({
      propName: "verify",
      currentValue: verificationCode,
      message: I18n.t('verification_required'),
    });
  }

  const handleHide = useCallback(
    () => {
      setLocalErrors({});
      props.onHide();
      dispatch(clearErrors());
    },
    [props, dispatch]
  );

  function handleToast(showToast, title, message, status, delay){
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function onSubmit(e) {
    e.preventDefault();
    handleItemLocalError({
      propName: "verify",
      currentValue: verificationCode,
      message: I18n.t('verification_required'),
    });
    if (
      verificationCode
    ) {
        let itemData = {
            verification_code: verificationCode,
            locale: locale
        };
        dispatch(verifyAccount(itemData));
    } else{
      handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 10000)
    }
  }

  function resendCode(){
    if(user && user.email){
        const type = "verification-change-password";
        let ItemData = {
            email: user.email,
            type
        };
        dispatch(sendVerification(ItemData));
    }
  };

  useEffect(() => {
    if (errorId === null && loading === false && verifySuccess === "success"){
        handleHide();
    };
  }, [errorId, loading, verifySuccess, handleHide]);

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props["aria-labelledby"]}
    >
      
      <Form>
        <Modal.Header>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>

        {
            sendVerificationLoading || registerLoading ?
            <Spin />
            : 
            <Fragment>
                <Modal.Body>
                    <div className="Verify_Account">
                        <span className="Verify_Account_Img"></span>
                        <h1>{I18n.t('check_email_inbox')}: {user.email}</h1>
                        <p className="mb-1">{I18n.t('verification_sent')}</p>
                        <p className="text-center mb-3">
                            {I18n.t('watch_register_video_issue')}
                        </p>
                        <a href='https://youtu.be/FEmwhTHJAew' target="_blank" rel="noopener noreferrer" className="videoBTn btn-success mb-2"><i className="fas fa-play"></i> {I18n.t('watch_video')}</a>
                        {
                          isDisabled ?
                          <h1 className="mt-2">{timer}</h1>
                          : null
                        }
                        <div className="mt-2 mb-4">
                          {I18n.t('not_receive_verify_code')}
                          <button 
                            className="btn btn-primary btn-sm mx-2" 
                            onClick={() => {resendCode();}}
                            disabled={isDisabled}
                          >
                            <span className="far fa-envelope"></span>{I18n.t('resend_verify_code')}
                          </button>
                        </div>
                        <div className="alert alert-warning" role="alert">
                          {I18n.t('still_have_problem')} <Link to="/contact-us"><Translate value='contact' /></Link>
                        </div>
                    </div>

                    <TextInput
                        required
                        controlId="VerificationCode"
                        label="verfication_code"
                        type="text"
                        name="VerificationCode"
                        feedbackType="invalid"
                        onChange={onVerifyChange}
                        onBlur={onVerifyBlur}
                        isInvalid={(errorId === "VERIFY_ACCOUNT_FAIL" && errorMessage.errors) || localErrors.verify}
                        value={verificationCode}
                        feedback={ localErrors.verify ? localErrors.verify : errorMessage.errors ? errorMessage.errors[0] : null }
                    />

                    {
                    errorId === "VERIFY_ACCOUNT_FAIL" && errorMessage === "Network Error" ?
                        <ErrorBox message={I18n.t('network_error_message')} />
                    : errorId === "VERIFY_ACCOUNT_FAIL" && errorMessage === "Other Errors" ?
                        <ErrorBox message={I18n.t('something_error_message')} />
                    : null
                    }

                </Modal.Body>

                <Modal.Footer>
                    <ButtonWithLoading
                    variant="primary"
                    type="submit"
                    onClick={onSubmit}
                    data-backdrop="static"
                    loading={loading ? loading : null}
                    >
                        <Translate value="verify" />
                    </ButtonWithLoading>
                    <Button
                    variant="secondary"
                    onClick={props.onHide}
                    >
                      <Translate value="later" />
                    </Button>
                </Modal.Footer>
            </Fragment>
        }
      </Form>
    </Modal>
  );
}
