import { axiosInstance } from '../axiosInstance';
import { GET_STATUS, STATUS_LOADING, STATUS_FAIL } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getStatus = () => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: STATUS_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/statuses', 
            );
            if(response.status !== 200){
                dispatch({
                    type: STATUS_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_STATUS,
                    payload: response.data.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'STATUS_FAIL'));
                dispatch ({ 
                    type: STATUS_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'STATUS_FAIL')),
                    dispatch ({ 
                        type: STATUS_FAIL 
                    })
                )
            }
        }
    }
};