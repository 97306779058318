import React, {useState, useEffect, useCallback, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Card, Accordion, Button, Form, Alert} from 'react-bootstrap';
import ReactPlayer from 'react-player';
import Carousel from 'react-slick';
import {useDispatch, useSelector} from 'react-redux';
import {Translate, I18n} from 'react-redux-i18n';
import {TitleComponent} from '../../components/TitleComponent';
import {MetaComponent} from '../../components/MetaComponent';
import {
  changeInvoiceStatus,
  getserviceinvoice,
} from '../../actions/getserviceinvoiceActions';
import {getFollowings} from '../../actions/followActions';
import {addclientreply} from '../../actions/addclientreplyActions';
import {getserviceinvoiceComments} from '../../actions/serviceinvoicecommentsActions';
import {getreportsTypes} from '../../actions/reportsActions';
import Spin from '../Spin';
import FeedbackModal from '../FeedbackModal';
import {truncate} from '../ReduceFileName';
import {Size} from '../CalculateSize';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import NoPermisions from '../NoPermissions';
import ServiceInvoiceComments from './ServiceInvoiceComments';
import ConfToast from '../ConfToast';
import QuillBox from '../QuillBox';
import {Uploader} from '../Upload';
import {
  ADD_CLIENT_REPLY_FAIL,
  CLEAR_PURCHES_SERVICE_SUCCESS,
  CLEAR_CLIENT_REPLY_SUCCESS,
} from '../../actions/types';
import LockCard from '../LockCard';
import ConnectionLost from '../ConnectionLost';
import NoItems from '../NoItems';
import ErrorBox from '../ErrorBox';
import StatusLabel from '../../StatusLabel';
import CancelInvoiceModal from '../CancelInvoiceModal';
import ReportInvoiceModal from '../ReportInvoiceModal';
import {
  handoverServiceInvoice,
  indorseServiceInvoice,
} from '../../redux/services/actions/addActions';
import {
  CLEAR_HANDOVER_SERVICE_INVOICE_SUCCESS,
  CLEAR_INDORSE_SERVICE_INVOICE_SUCCESS,
} from '../../redux/services/actions/types';
import logoIcon from '../../imgs/logo-icon.png';
import GRecaptch from '../GRecaptch';
import {getFeedbacks} from '../../redux/feedbacks/actions/getActions';
import RatingStarsPreview from '../RatingStarsPreview';
import CloseServiceModal from '../CloseServiceModal';

export default function ServiceInvoiceDetails(props) {
  const dispatch = useDispatch();

  const {id} = props.match.params;

  const {
    isAuthenticated,
    userLoading,
    user,
    loadinginvoice,
    invoice,
    clientReplySuccess,
    loadingClientReply,
    errorsId,
    errorsMsg,
    invoiceRatingSuccess,
    loadingInvoiceRating,
    loadingPurches,
    servicePurchesSuccess,
    cancelServiceInvoiceLoading,
    cancelServiceInvoiceSuccess,
    reportServiceInvoiceLoading,
    handoverServiceInvoiceLoading,
    handoverServiceInvoiceSuccess,
    indorseServiceInvoiceSuccess,
    lastNotification,
    feedbacks,
    loadingInvoiceStatus,
    invoiceStatusSuccess,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userLoading: state.auth.isLoading,
    user: state.auth.user,
    loadinginvoice: state.serviceInvoice.loadinginvoice,
    invoice: state.serviceInvoice.invoice,
    clientReplySuccess: state.addclientreply.clientReplySuccess,
    loadingClientReply: state.addclientreply.isLoading,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    invoiceRatingSuccess: state.serviceInvoice.invoiceRatingSuccess,
    loadingInvoiceRating: state.serviceInvoice.loadingInvoiceRating,
    loadingPurches: state.servicePurches.loadingPurches,
    servicePurchesSuccess: state.servicePurches.servicePurchesSuccess,
    cancelServiceInvoiceLoading: state.service.cancelServiceInvoiceLoading,
    cancelServiceInvoiceSuccess: state.service.cancelServiceInvoiceSuccess,
    reportServiceInvoiceLoading: state.service.reportServiceInvoiceLoading,
    handoverServiceInvoiceLoading: state.service.handoverServiceInvoiceLoading,
    handoverServiceInvoiceSuccess: state.service.handoverServiceInvoiceSuccess,
    indorseServiceInvoiceSuccess: state.service.indorseServiceInvoiceSuccess,
    lastNotification: state.notifications.lastNotification,
    feedbacks: state.feedbacks.feedbacks,
    loadingInvoiceStatus: state.serviceInvoice.loadingInvoiceStatus,
    invoiceStatusSuccess: state.serviceInvoice.invoiceStatusSuccess,
  }));

  const [activeId, setActiveId] = useState('0');
  const [reply, setReply] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [feedbackModel, setFeedbackModel] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [localErrors, setLocalErrors] = useState({});
  const [feedbackModalTitle, setFeedbackModalTitle] = useState('');
  const [showReport, setShowReport] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [invoiceToCloseId, setInvoiceToCloseId] = useState('');
  const [invoiceToCloseTitle, setInvoiceToCloseTitle] = useState('');
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState(false);
  const [filesChosen, setFilesChosen] = useState(false);
  const [filesUploadErrors, setFilesUploadErrors] = useState([]);
  const [filesSortLength, setFilesSortLength] = useState(false);
  const [filteredFilesErrorsLength, setFilteredFilesErrorsLength] =
    useState(false);
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState('');
  const [loadingServiceInvoice, setLoadingServiceInvoice] = useState(true);
  const [isHuman, setIsHuman] = useState(false);

  useEffect(() => {
    setLoadingServiceInvoice(loadinginvoice);
  }, [loadinginvoice]);

  useEffect(() => {
    const morphType = 'service_invoice';
    const morphID = id;
    dispatch(getFeedbacks(null, morphType, morphID, null, null));
  }, [id, dispatch]);

  const filteredFilesErrors = filesUploadErrors
    ? filesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  function handleShowReport() {
    setShowReport(true);
  }

  function handleHideReport() {
    setShowReport(false);
  }

  function handleShowCancel() {
    setShowCancel(true);
  }

  function handleHideCancel() {
    setShowCancel(false);
  }

  useEffect(() => {
    if (id && isAuthenticated) {
      dispatch(getserviceinvoice(id));
      dispatch(getserviceinvoiceComments(id, 1, 30));
      dispatch(getFollowings());
      dispatch(getreportsTypes());
      setInvoiceId(id);
    }
  }, [id, dispatch, isAuthenticated]);

  useEffect(() => {
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'CloseInvoice' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'InvoiceCancelationRequest' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.invoice.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'InvoiceReportAdminDeclined' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.invoice.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'InvoiceReportAdminApproved' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.invoice.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'InvoiceHandover' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.invoice.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'ServiceInvoiceFeedback' &&
      window.location.href.indexOf('/services/invoices') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'InvoiceRequestDeclined' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'handover'
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'InvoiceRequestApproved' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'handover'
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'InvoiceRequestDeclined' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'cancel'
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'InvoiceRequestApproved' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'cancel'
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'CreateInvoiceReply'
    ) {
      dispatch(getserviceinvoice(id));
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'ServiceInvoiceFeedback' &&
      window.location.href.indexOf('/invoices') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(getserviceinvoice(id));
    }
  }, [lastNotification, dispatch, id]);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    if (window.location.href.indexOf('/payment&payment=success') > -1) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('service_purchase'),
          I18n.t('service_purchase_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      const pathName = window.location.pathname;
      const redirectTo = pathName.replace('payment&payment=success', '');
      window.history.replaceState(null, '', redirectTo);
    }
  }, [handleToast]);

  useEffect(() => {
    if (files.length > 0) {
      setFilesSortLength(true);
    }
  }, [files]);

  useEffect(() => {
    if (errorsId === null && !loadingPurches && servicePurchesSuccess) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('service_purchase'),
          I18n.t('service_purchase_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 1500);
      dispatch({
        type: CLEAR_PURCHES_SERVICE_SUCCESS,
      });
    }
  }, [errorsId, loadingPurches, servicePurchesSuccess, dispatch, handleToast]);

  useEffect(() => {
    if (errorsId === null && !loadingClientReply && clientReplySuccess && id) {
      dispatch(getserviceinvoice(id));
      handleToast(
        true,
        I18n.t('reply_to_seller'),
        I18n.t('reply_to_seller_success'),
        'Toast__Container__Success',
        8000,
      );
      dispatch({
        type: CLEAR_CLIENT_REPLY_SUCCESS,
      });
    }
  }, [
    errorsId,
    loadingClientReply,
    clientReplySuccess,
    dispatch,
    handleToast,
    id,
  ]);

  useEffect(() => {
    if (
      errorsId === null &&
      !handoverServiceInvoiceLoading &&
      handoverServiceInvoiceSuccess &&
      id
    ) {
      dispatch(getserviceinvoice(id));
      handleToast(
        true,
        I18n.t('service_handover'),
        I18n.t('service_handover_success'),
        'Toast__Container__Success',
        8000,
      );
      dispatch({
        type: CLEAR_HANDOVER_SERVICE_INVOICE_SUCCESS,
      });
    }
  }, [
    errorsId,
    handoverServiceInvoiceLoading,
    handoverServiceInvoiceSuccess,
    dispatch,
    handleToast,
    id,
  ]);

  useEffect(() => {
    if (errorsId === null && cancelServiceInvoiceSuccess && id) {
      dispatch(getserviceinvoice(id));
    }
  }, [errorsId, cancelServiceInvoiceSuccess, dispatch, id]);

  useEffect(() => {
    if (filteredFilesErrorsLength.length > 0) {
      setFilteredFilesErrorsLength(true);
    }
  }, [filteredFilesErrorsLength]);

  useEffect(() => {
    if (errorsId === 'ADD_CLIENT_REPLY_FAIL') {
      setErrorActionType(ADD_CLIENT_REPLY_FAIL);
    }
  }, [errorsId]);

  function showFeedbackModal() {
    setFeedbackModalTitle('service_feedback');
    setFeedbackModel(true);
  }

  const hideFeedbackModal = useCallback(() => {
    setFeedbackModalTitle('');
    setFeedbackModel(false);
  }, []);

  useEffect(() => {
    if (
      invoiceRatingSuccess === 'success' &&
      loadingInvoiceRating === false &&
      user.id === invoice[0].user.id
    ) {
      hideFeedbackModal();
      if (id) {
        dispatch(getserviceinvoice(id));
      }
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('service_rating_title'),
          I18n.t('service_rating_message'),
          'Toast__Container__Success',
          6000,
        );
      }, 3000);
    } else if (
      invoiceRatingSuccess === 'success' &&
      loadingInvoiceRating === false &&
      user.id === invoice[0].service.user.id
    ) {
      hideFeedbackModal();
      if (id) {
        dispatch(getserviceinvoice(id));
      }
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('service_rating_customer_title'),
          I18n.t('service_rating_customer_message'),
          'Toast__Container__Success',
          6000,
        );
      }, 3000);
    }
  }, [
    invoiceRatingSuccess,
    loadingInvoiceRating,
    id,
    dispatch,
    hideFeedbackModal,
    handleToast,
    invoice,
    user,
  ]);

  useEffect(() => {
    if (invoice.length > 0) {
      if (
        invoice[0].rated_as_buyer === false &&
        user.id === invoice[0].user.id &&
        invoice[0].status.id === 6 &&
        invoice[0].id.toString() === id
      ) {
        showFeedbackModal();
      }
    }
  }, [invoice, user, id]);

  useEffect(() => {
    if (invoice.length > 0) {
      if (
        invoice[0].rated_as_seller === false &&
        user.id === invoice[0].service.user.id &&
        invoice[0].status.id === 6 &&
        invoice[0].id.toString() === id &&
        invoice[0].rated_as_buyer === true
      ) {
        setFeedbackModalTitle('rate_customer');
        setFeedbackModel(true);
      }
    }
  }, [invoice, user, id]);

  function isObject(val) {
    return typeof val === 'object';
  }

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    uploadArray,
  }) {
    if (currentValue) {
      if (isObject(currentValue) && !Array.isArray(currentValue)) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else if (Array.isArray(currentValue) && uploadArray === true) {
        if (currentValue.length <= 0) {
          const noImageError = {
            code: 'NoImage',
            message: message,
          };
          const filtered = filesUploadErrors.filter(function (
            value,
            index,
            arr,
          ) {
            return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setFilesUploadErrors(filtered);
        } else {
          const filtered = filesUploadErrors.filter(function (
            value,
            index,
            arr,
          ) {
            return value.code !== 'NoImage';
          });
          setFilesUploadErrors(filtered);
        }
      } else if (Array.isArray(currentValue) && uploadArray === false) {
        if (currentValue.length <= 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  function onClientReplyChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setReply('');
    } else {
      setReply(value);
    }
  }

  function onClientReplyBlur() {
    handleItemLocalError({
      propName: 'reply',
      currentValue: reply,
      message: I18n.t('clarification_required'),
    });
  }

  function onHandover(e) {
    e.preventDefault();
    const data = {
      invoice_id: invoiceId,
    };
    dispatch(handoverServiceInvoice(data));
  }

  function onSubmitClientReply(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'reply',
      currentValue: reply,
      message: I18n.t('clarification_required'),
    });

    if (filesUpload === false && filesChosen === false && reply && isHuman) {
      const replyData = {
        reply,
        invoice_id: invoiceId,
        files: files.map(item => item.source),
      };
      dispatch(addclientreply(replyData));
    } else if (filesUpload === true || filesChosen === true) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        6000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function checkReports(reports) {
    const cancelOrders = reports
      .filter(function (item) {
        if (item.type === 'cancel') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (cancelOrders.length > 0) {
      const maxId = Math.max(...cancelOrders.map(item => item.id));
      const currentCancelOrder = cancelOrders
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentCancelOrder[0].status === 'approved') {
        return 'approved';
      } else if (currentCancelOrder[0].status === 'declined') {
        return 'declined';
      } else if (currentCancelOrder[0].status === 'pending') {
        return 'pending';
      }
      return 'noRequest';
    }
  }

  function currentCancel(reports) {
    const cancelOrders = reports
      .filter(function (item) {
        if (item.type === 'cancel') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (cancelOrders.length > 0) {
      const maxId = Math.max(...cancelOrders.map(item => item.id));
      const currentCancelOrder = cancelOrders
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentCancelOrder[0].status === 'pending') {
        return currentCancelOrder[0];
      }
      return 'noRequest';
    }
  }

  function handover(reports) {
    const handoverRequests = reports
      .filter(function (item) {
        if (item.type === 'handover') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (handoverRequests.length > 0) {
      const maxId = Math.max(...handoverRequests.map(item => item.id));
      const currentHandover = handoverRequests
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentHandover[0].status === 'pending') {
        return 'pending';
      } else if (currentHandover[0].status === 'declined') {
        return 'declined';
      }
      return 'noHandover';
    }
  }

  function indorseInvoice(status) {
    if (status === 'declined' && invoice.length > 0) {
      setDeclineLoading(true);
      const reports = invoice[0].reports;
      const cancelOrders = reports
        .filter(function (item) {
          if (item.type === 'cancel') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (cancelOrders.length > 0) {
        const maxId = Math.max(...cancelOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseServiceInvoice(data));
      }
    } else if (status === 'approved' && invoice.length > 0) {
      setApproveLoading(true);
      const reports = invoice[0].reports;
      const cancelOrders = reports
        .filter(function (item) {
          if (item.type === 'cancel') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (cancelOrders.length > 0) {
        const maxId = Math.max(...cancelOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseServiceInvoice(data));
      }
    }
  }

  function indorseInvoiceHandover(status) {
    if (status === 'declined' && invoice.length > 0) {
      setDeclineLoading(true);
      const reports = invoice[0].reports;
      const handoverOrders = reports
        .filter(function (item) {
          if (item.type === 'handover') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (handoverOrders.length > 0) {
        const maxId = Math.max(...handoverOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseServiceInvoice(data));
      }
    } else if (status === 'approved' && invoice.length > 0) {
      setApproveLoading(true);
      const reports = invoice[0].reports;
      const handoverOrders = reports
        .filter(function (item) {
          if (item.type === 'handover') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (handoverOrders.length > 0) {
        const maxId = Math.max(...handoverOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseServiceInvoice(data));
      }
    }
  }

  useEffect(() => {
    if (indorseServiceInvoiceSuccess) {
      setDeclineLoading(false);
      setApproveLoading(false);
      dispatch({
        type: CLEAR_INDORSE_SERVICE_INVOICE_SUCCESS,
      });
      dispatch(getserviceinvoice(id));
    }
  }, [indorseServiceInvoiceSuccess, dispatch, id]);

  const handleCloseServiceConfirmClose = () => setShowCloseConfirm(false);
  const handleCloseServiceConfirmShow = (id, projectTitle) => {
    setInvoiceToCloseId(id);
    setInvoiceToCloseTitle(projectTitle);
    setShowCloseConfirm(true);
  };
  function handleCloseOnClick() {
    const invoiceStatusInfo = {
      invoice_id: invoiceId,
      status_id: 6,
    };
    dispatch(changeInvoiceStatus(invoiceStatusInfo));
  }

  useEffect(() => {
    if (invoiceStatusSuccess === 'success') {
      window.location.reload();
    }
  }, [invoiceStatusSuccess]);

  if (loadingServiceInvoice || userLoading) {
    return <Spin />;
  } else if (isAuthenticated) {
    if (
      errorsId === 'GET_SERVICE_INVOICE_FAIL' &&
      errorsMsg === 'Network Error'
    ) {
      return (
        <ConnectionLost
          errorType="connection"
          title="network_error_title"
          message="network_error_message"
        />
      );
    } else if (errorsId === 'GET_SERVICE_INVOICE_FAIL') {
      return (
        <ConnectionLost
          errorType="connection"
          title="network_error_title"
          message="network_error_message"
        />
      );
    } else if (invoice.length > 0 && !loadingServiceInvoice) {
      const serviceinvoice = invoice[0];
      const serviceitem = serviceinvoice.service;
      const loggedUserId = user.id;
      const serviceUserId = serviceitem.user.id;
      const serviceBuyerId = serviceinvoice.user.id;
      const {errors} = errorsMsg;
      const createMarkup = text => {
        return {__html: text};
      };
      const serviceCarouselSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };
      const buyerReply = serviceinvoice.client_replies[0];

      return loggedUserId === serviceUserId ||
        loggedUserId === serviceBuyerId ? (
        <div className="PortfolioDetails">
          <TitleComponent
            title={I18n.t('lancersin_services') + ' - ' + serviceitem.title}
          />
          <MetaComponent
            keywords={
              serviceitem.keywords && serviceitem.keywords.length >= 3
                ? serviceitem.keywords
                : serviceitem.title
            }
          />
          <ConfToast
            showConf={confShow}
            setShowConf={setConfShow}
            title={confTitle}
            message={confMessage}
            status={confStatus}
            delay={delay}
          />
          <CancelInvoiceModal
            onHide={handleHideCancel}
            show={showCancel}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={cancelServiceInvoiceLoading}
            invoiceStatusId={serviceinvoice.status.id}
            invoice_id={serviceinvoice.id}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <ReportInvoiceModal
            onHide={handleHideReport}
            show={showReport}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={reportServiceInvoiceLoading}
            invoice_id={serviceinvoice.id}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <CloseServiceModal
            onHide={handleCloseServiceConfirmClose}
            onClose={handleCloseOnClick}
            show={showCloseConfirm}
            modalTitle={'service_received'}
            className="CustomPopup"
            size="md"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={loadingInvoiceStatus}
            messageHeading="service_delivery_body"
            messageBody={invoiceToCloseTitle}
          />
          <div className="breadcrumb__container">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{I18n.t('home')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {serviceitem.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container InnerPageContainer mt-4">
            <div className="row">
              {checkReports(serviceinvoice.reports) === 'pending' &&
              serviceinvoice.status.id === 5 &&
              loggedUserId === serviceUserId ? (
                <div className="col-12">
                  <Card>
                    <Card.Header>{I18n.t('order_actions')}</Card.Header>
                    <Card.Body>
                      <div className="serviceDelivery">
                        <div className="serviceDelivery__Img">
                          <img src={logoIcon} alt="Lancersin" />
                        </div>
                        <div className="serviceDelivery__Content">
                          <div className="serviceDelivery__Content__Message">
                            {I18n.t('service_cancellation_label') + ' '}
                            {serviceinvoice.user
                              ? serviceinvoice.user.fname +
                                ' ' +
                                serviceinvoice.user.lname
                              : null}
                            .<br />
                            {' ' + I18n.t('service_cancellation_message')}
                            {currentCancel(serviceinvoice.reports) !==
                            'noRequest' ? (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  currentCancel(serviceinvoice.reports).report,
                                )}></div>
                            ) : null}
                          </div>
                          <div className="serviceDelivery__Content__Actions">
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={() => indorseInvoice('approved')}
                              data-backdrop="static"
                              loading={approveLoading ? approveLoading : null}>
                              {I18n.t('service_cancellation_approve')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                              variant="secondary"
                              type="submit"
                              onClick={() => indorseInvoice('declined')}
                              data-backdrop="static"
                              loading={declineLoading ? declineLoading : null}>
                              {I18n.t('service_cancellation_decline')}
                            </ButtonWithLoading>
                            {errorsId &&
                            errorsId === 'INDORSE_SERVICE_INVOICE_FAIL' ? (
                              <div className="invalid-feedback d-block">
                                {I18n.t('something_went_wrong')}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ) : null}
              {serviceinvoice.status.id === 4 &&
              loggedUserId === serviceUserId ? (
                <div className="col-12">
                  <Card>
                    <Card.Header>{I18n.t('order_actions')}</Card.Header>
                    <Card.Body>
                      <div className="serviceDelivery">
                        <div className="serviceDelivery__Img">
                          <img src={logoIcon} alt="Lancersin" />
                        </div>
                        <div className="serviceDelivery__Content">
                          <div className="serviceDelivery__Content__Message">
                            {I18n.t('hello') + ' '}
                            {serviceinvoice.service.user
                              ? serviceinvoice.service.user.fname
                              : null}
                            <br />
                            {I18n.t('please_dont_start_service')}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ) : null}
              {handover(serviceinvoice.reports) === 'pending' &&
              loggedUserId === serviceBuyerId ? (
                <div className="col-12">
                  <Card>
                    <Card.Header>{I18n.t('order_actions')}</Card.Header>
                    <Card.Body>
                      <div className="alert alert-warning" role="alert">
                        <i className="fas fa-exclamation-circle"></i>{' '}
                        <b>{I18n.t('note') + ': '}</b>
                        {I18n.t('service_delivery_note')}
                      </div>
                      <div className="serviceDelivery">
                        <div className="serviceDelivery__Img">
                          <img src={logoIcon} alt="Lancersin" />
                        </div>
                        <div className="serviceDelivery__Content">
                          <div className="serviceDelivery__Content__Message">
                            {I18n.t('service_delivery_label') + ' '}
                            {serviceinvoice.service.user
                              ? serviceinvoice.service.user.fname +
                                ' ' +
                                serviceinvoice.service.user.lname
                              : null}
                            .<br />
                            {' ' + I18n.t('service_delivery_message')}
                          </div>
                          <div className="serviceDelivery__Content__Actions">
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={() => indorseInvoiceHandover('approved')}
                              data-backdrop="static"
                              loading={approveLoading ? approveLoading : null}>
                              {I18n.t('service_delivery_approve')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                              variant="secondary"
                              type="submit"
                              onClick={() => indorseInvoiceHandover('declined')}
                              data-backdrop="static"
                              loading={declineLoading ? declineLoading : null}>
                              {I18n.t('service_delivery_decline')}
                            </ButtonWithLoading>
                            {errorsId &&
                            errorsId === 'INDORSE_SERVICE_INVOICE_FAIL' ? (
                              <div className="invalid-feedback d-block">
                                {I18n.t('something_went_wrong')}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ) : null}
              <div className="col-lg-4 col-md-5 col-sm-12">
                <Card>
                  <Card.Header>
                    <h2>{I18n.t('order_details')}</h2>
                  </Card.Header>
                  <Card.Body>
                    <div className="Service__Invoice__ServiceCard">
                      <div className="ServiceImage">
                        <Link
                          to={{
                            pathname: `/services/${serviceitem.id}/${serviceitem.title}`,
                          }}>
                          <img
                            src={
                              serviceitem.image.url
                                ? serviceitem.image.url
                                : serviceitem.image
                            }
                            alt={serviceitem.title}
                          />
                        </Link>
                      </div>
                      <div className="ServiceContent">
                        <Link
                          to={{
                            pathname: `/services/${serviceitem.id}/${serviceitem.title}`,
                          }}>
                          {serviceitem.title}
                        </Link>
                        {handover(serviceinvoice.reports) === 'pending' ? (
                          <StatusLabel status_id={9} type="service_invoice" />
                        ) : (
                          <StatusLabel
                            status_id={
                              serviceinvoice.status
                                ? serviceinvoice.status.id
                                : null
                            }
                            type="service_invoice"
                          />
                        )}
                      </div>
                    </div>
                    <div className="Service__Invoice__Details">
                      {loggedUserId === serviceBuyerId ? (
                        <div className="Service__Invoice__Details__row">
                          <div className="label">{I18n.t('order_from')}</div>
                          <Link
                            to={{
                              pathname: `/in/${serviceitem.user.username}`,
                            }}>
                            {serviceitem.user.fname} {serviceitem.user.lname}
                          </Link>
                        </div>
                      ) : (
                        <div className="Service__Invoice__Details__row">
                          <div className="label">{I18n.t('buyer')}</div>
                          <Link
                            to={{
                              pathname: `/in/${serviceinvoice.user.username}`,
                            }}>
                            {serviceinvoice.user.fname}{' '}
                            {serviceinvoice.user.lname}
                          </Link>
                        </div>
                      )}
                      {serviceinvoice.qty ? (
                        <div className="Service__Invoice__Details__row">
                          <div className="label">
                            <h3>
                              <Translate value="total_request" />
                            </h3>
                          </div>
                          <div className="value">{serviceinvoice.qty}</div>
                        </div>
                      ) : null}
                      <div className="Service__Invoice__Details__row">
                        <div className="label">
                          <h4>{I18n.t('total_price')}</h4>
                        </div>
                        <div className="value">
                          {serviceinvoice.due}
                          {I18n.t('dollar')}
                        </div>
                      </div>
                      <div className="Service__Invoice__Details__row">
                        <div className="label">{I18n.t('order_number')}</div>
                        <div className="value">#{serviceinvoice.id}</div>
                      </div>
                      <div className="Service__Invoice__Details__row">
                        {loggedUserId === serviceBuyerId &&
                        serviceinvoice.status.id !== 6 &&
                        serviceinvoice.status.id !== 7 ? (
                          checkReports(serviceinvoice.reports) === 'approved' &&
                          serviceinvoice.status.id === 4 ? (
                            <div className="d-flex flex-column">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('service_cancel_request_sent')}
                              </div>
                            </div>
                          ) : checkReports(serviceinvoice.reports) ===
                              'pending' && serviceinvoice.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('service_cancel_request_sent')}
                              </div>
                            </div>
                          ) : checkReports(serviceinvoice.reports) ===
                              'approved' && serviceinvoice.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('service_cancel_request_sent')}
                              </div>
                            </div>
                          ) : handover(serviceinvoice.reports) === 'pending' &&
                            serviceinvoice.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t(
                                  'handover_request_action_needed_service',
                                )}
                              </div>
                            </div>
                          ) : (
                            <button
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                handleShowCancel();
                              }}>
                              {I18n.t('cancel_order')}
                            </button>
                          )
                        ) : loggedUserId === serviceUserId &&
                          serviceinvoice.status.id !== 4 &&
                          serviceinvoice.status.id !== 6 &&
                          serviceinvoice.status.id !== 7 ? (
                          handover(serviceinvoice.reports) === 'pending' ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('service_handover')}
                              </button>
                              <div className="valid-feedback d-block">
                                {I18n.t('service_handover_success')}
                              </div>
                            </div>
                          ) : checkReports(serviceinvoice.reports) ===
                              'pending' && serviceinvoice.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('service_handover')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_request_cancel_invoice')}
                              </div>
                            </div>
                          ) : checkReports(serviceinvoice.reports) ===
                              'approved' && serviceinvoice.status.id !== 7 ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <button className="btn btn-disabled btn-sm">
                                {I18n.t('service_handover')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_request_cancel_invoice_admin')}
                              </div>
                            </div>
                          ) : handover(serviceinvoice.reports) ===
                            'declined' ? (
                            <div className="d-flex flex-column align-items-start w-50">
                              <ButtonWithLoading
                                variant="success"
                                type="submit"
                                className="btn-sm"
                                onClick={onHandover}
                                data-backdrop="static"
                                loading={
                                  handoverServiceInvoiceLoading
                                    ? handoverServiceInvoiceLoading
                                    : null
                                }>
                                {I18n.t('service_handover')}
                              </ButtonWithLoading>
                              {errorsId &&
                              errorsId === 'HANDOVER_SERVICE_INVOICE_FAIL' ? (
                                <div className="invalid-feedback d-block">
                                  {I18n.t('something_went_wrong')}
                                </div>
                              ) : null}
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_declined_delivery')}
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex flex-column align-items-start w-50">
                              <ButtonWithLoading
                                variant="success"
                                type="submit"
                                className="btn-sm"
                                onClick={onHandover}
                                data-backdrop="static"
                                loading={
                                  handoverServiceInvoiceLoading
                                    ? handoverServiceInvoiceLoading
                                    : null
                                }>
                                {I18n.t('service_handover')}
                              </ButtonWithLoading>
                              {errorsId &&
                              errorsId === 'HANDOVER_SERVICE_INVOICE_FAIL' ? (
                                <div className="invalid-feedback d-block">
                                  {I18n.t('something_went_wrong')}
                                </div>
                              ) : null}
                            </div>
                          )
                        ) : null}
                        {serviceinvoice.status.id !== 6 &&
                        serviceinvoice.status.id !== 7 ? (
                          <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={() => {
                              handleShowReport();
                            }}>
                            <i className="icon icon-report"></i>{' '}
                            {I18n.t('report_problem')}
                          </button>
                        ) : null}
                      </div>
                      <div className="Service__Invoice__Details__row">
                        {loggedUserId === serviceBuyerId &&
                        (serviceinvoice.status.id === 4 ||
                          serviceinvoice.status.id === 5) ? (
                          <div className="d-flex w-100">
                            <ButtonWithLoading
                              variant="success"
                              type="submit"
                              data-backdrop="static"
                              loading={loadingInvoiceStatus}
                              onClick={() =>
                                handleCloseServiceConfirmShow(
                                  serviceinvoice.id,
                                  serviceinvoice.service.title,
                                )
                              }
                              className="w-100 btn-sm">
                              {I18n.t('service_received')}
                            </ButtonWithLoading>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              {!buyerReply && loggedUserId === serviceBuyerId ? (
                loggedUserId === serviceBuyerId &&
                checkReports(serviceinvoice.reports) === 'approved' &&
                serviceinvoice.status.id === 4 ? (
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <Accordion defaultActiveKey={activeId}>
                      <Card
                        className={
                          activeId === '0'
                            ? 'Comments__Container active-panel'
                            : 'Comments__Container'
                        }>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            onClick={() => toggleActive('0')}
                            variant="link"
                            eventKey="0">
                            {I18n.t('please_wait_till_we_review')}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="col-12">
                              <Alert variant="warning">
                                {I18n.t('client_request_cancel_invoice_admin')}
                              </Alert>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                ) : (
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <Accordion defaultActiveKey={activeId}>
                      <Card
                        className={
                          activeId === '0'
                            ? 'Comments__Container active-panel'
                            : 'Comments__Container'
                        }>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            onClick={() => toggleActive('0')}
                            variant="link"
                            eventKey="0">
                            {I18n.t('submit_requirements')}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {serviceitem.requirments !== '' ? (
                              <div className="row">
                                {user.id !== invoice[0].service.user.id &&
                                user.id === invoice[0].user.id &&
                                invoice[0].status.id === 4 &&
                                invoice[0].client_replies.length <= 0 ? (
                                  <div className="col-12">
                                    <Alert variant="warning">
                                      {I18n.t('please_write_clarification')}
                                    </Alert>
                                  </div>
                                ) : null}
                                <div className="col-12 mb-2">
                                  <div className="inner__subtitle">
                                    <h2>{I18n.t('seller_requirements')}</h2>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      serviceitem.requirments,
                                    )}
                                    className="listing__description"></div>
                                </div>
                              </div>
                            ) : user.id !== invoice[0].service.user.id &&
                              user.id === invoice[0].user.id &&
                              invoice[0].status.id === 4 &&
                              invoice[0].client_replies.length <= 0 ? (
                              <div className="row">
                                <div className="col-12">
                                  <Alert variant="warning">
                                    {I18n.t('please_write_requiremens')}
                                  </Alert>
                                </div>
                              </div>
                            ) : null}
                            {user.id !== invoice[0].service.user.id &&
                            user.id === invoice[0].user.id &&
                            invoice[0].status.id === 4 &&
                            invoice[0].client_replies.length <= 0 ? (
                              <Form
                                onSubmit={onSubmitClientReply}
                                className="mt-3">
                                <QuillBox
                                  required
                                  controlId="ClientReply"
                                  label={I18n.t('write_requirements')}
                                  value={reply}
                                  onChange={onClientReplyChange}
                                  onBlur={onClientReplyBlur}
                                  feedbackType="invalid"
                                  feedback={
                                    localErrors.reply ? localErrors.reply : null
                                  }
                                  isInvalid={
                                    (errorsId === ERROR_ACTION_TYPE &&
                                      errors &&
                                      errors.reply) ||
                                    localErrors.reply
                                  }
                                />
                                <Form.Group controlId="CommentFiles">
                                  <Form.Label>
                                    {I18n.t('upload_files')}
                                  </Form.Label>
                                  <small>{I18n.t('service_files_hint')}</small>
                                  <Uploader
                                    accept={[
                                      {
                                        mime: 'application/msword',
                                        ext: 'doc',
                                      },
                                      {
                                        mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                        ext: 'docx',
                                      },
                                      {
                                        mime: 'application/vnd.ms-powerpoint',
                                        ext: 'ppt',
                                      },
                                      {
                                        mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                        ext: 'pptx',
                                      },
                                      {
                                        mime: 'application/vnd.ms-excel',
                                        ext: 'xls',
                                      },
                                      {
                                        mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                        ext: 'xlsx',
                                      },
                                      {
                                        mime: 'application/pdf',
                                        ext: 'pdf',
                                      },
                                      {
                                        mime: 'image/jpeg',
                                        ext: 'jpeg',
                                      },
                                      {
                                        mime: 'image/gif',
                                        ext: 'gif',
                                      },
                                      {
                                        mime: 'image/png',
                                        ext: 'png',
                                      },
                                      {
                                        mime: 'application/rtf',
                                        ext: 'rtf',
                                      },
                                      {
                                        mime: 'text/plain',
                                        ext: 'plain-text',
                                      },
                                      {
                                        mime: 'application/x-rar',
                                        ext: 'rar',
                                      },
                                      {
                                        mime: 'application/zip',
                                        ext: 'zip',
                                      },
                                    ]}
                                    maxFiles={10}
                                    multiple={true}
                                    maxSize={10000000}
                                    sortable={false}
                                    setFilesChosen={setFilesChosen}
                                    setFilesUpload={setFilesUpload}
                                    setUploadErrors={setFilesUploadErrors}
                                    uploadErrors={filesUploadErrors}
                                    setFilesSort={setFiles}
                                    filesSort={files}
                                    filesSortLength={filesSortLength}
                                    filteredErrorsLength={
                                      filteredFilesErrorsLength
                                    }
                                    filteredErrors={filteredFilesErrors}
                                    uploadPath="services"
                                    projectId={
                                      serviceinvoice ? serviceinvoice.id : null
                                    }
                                  />
                                </Form.Group>
                                {errorsId === 'ADD_CLIENT_REPLY_FAIL' &&
                                errorsMsg === 'Network Error' ? (
                                  <ErrorBox
                                    message={I18n.t('network_error_message')}
                                    className="mt-3"
                                  />
                                ) : errorsId === 'ADD_CLIENT_REPLY_FAIL' &&
                                  errorsMsg === 'Other Errors' ? (
                                  <ErrorBox
                                    message={I18n.t('something_error_message')}
                                    className="mt-3"
                                  />
                                ) : null}
                                <GRecaptch
                                  setIsHuman={setIsHuman}
                                  className="mb-3"
                                />
                                <ButtonWithLoading
                                  variant="primary"
                                  type="submit"
                                  onClick={onSubmitClientReply}
                                  data-backdrop="static"
                                  loading={
                                    loadingClientReply
                                      ? loadingClientReply
                                      : null
                                  }>
                                  <Translate value="submit" />
                                </ButtonWithLoading>
                              </Form>
                            ) : null}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
                )
              ) : (
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <FeedbackModal
                    className="CustomPopup"
                    size="lg"
                    show={feedbackModel}
                    onHide={hideFeedbackModal}
                    modalTitle={feedbackModalTitle}
                    backdrop="static"
                    keyboard={false}
                    invoice_id={invoiceId}
                    loggedUserId={loggedUserId}
                    serviceUserId={serviceUserId}
                    status_id={6} // 6 is the status code for completed
                    aria-labelledby="example-modal-sizes-title-lg"
                    loadingInvoiceStatus={loadingInvoiceRating}
                    setConfShow={setConfShow}
                    setConfTitle={setConfTitle}
                    setConfMessage={setConfMessage}
                    setConfStatus={setConfStatus}
                    setDelay={setDelay}
                    note={
                      loggedUserId === serviceBuyerId
                        ? 'service_rate_present_customer'
                        : 'service_rate_present_freelancer'
                    }
                  />
                  <Card>
                    <Card.Header>
                      <h1>{serviceitem.title}</h1>
                    </Card.Header>
                    <Card.Body>
                      {serviceitem.images.length > 0 ||
                      serviceitem.videos.length > 0 ? (
                        <div className="row mb-5">
                          <div className="col-12">
                            <Carousel
                              {...serviceCarouselSettings}
                              className="Service__Carousel">
                              {serviceitem.images.length > 0
                                ? serviceitem.images.map((image, index) => (
                                    <img
                                      src={image.url}
                                      alt={serviceitem.title}
                                      key={index}
                                    />
                                  ))
                                : null}
                              {serviceitem.videos.length > 0
                                ? serviceitem.videos.map((video, index) => (
                                    <ReactPlayer
                                      width="100%"
                                      height="400px"
                                      key={index}
                                      url={video}
                                    />
                                  ))
                                : null}
                            </Carousel>
                          </div>
                        </div>
                      ) : serviceitem.image.url ? (
                        <div className="d-flex justify-content-center mb-3">
                          <img
                            src={serviceitem.image.url}
                            alt={serviceitem.title}
                            className="img-fluid"
                          />
                        </div>
                      ) : (
                        <div className="d-flex justify-content-center mb-3">
                          <img
                            src={serviceitem.image}
                            alt={serviceitem.title}
                            className="img-fluid"
                          />
                        </div>
                      )}
                      {serviceitem.description ? (
                        <div className="row mb-3">
                          <div className="col-12">
                            <h3 className="listing__title">
                              <Translate value="description" />
                            </h3>
                          </div>
                          <div className="col-12 mt-2">
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                serviceitem.description,
                              )}
                              className="listing__description"></div>
                          </div>
                        </div>
                      ) : null}
                      {serviceitem.category ? (
                        <div className="row mb-3">
                          <div className="col-md-6">
                            <span className="listing__title">
                              <Translate value="category" />
                            </span>
                            <span className="listing__description">
                              {serviceitem.category.title}
                            </span>
                          </div>
                          <div className="col-md-6">
                            <span className="listing__title">
                              <Translate value="sub_category" />
                            </span>
                            <span className="listing__description">
                              {serviceitem.sub_category.title}
                            </span>
                          </div>
                        </div>
                      ) : null}
                      {serviceitem.features.length > 0 ? (
                        <div className="row">
                          <div className="col-md-12 my-3">
                            <div className="inner__title">
                              <h2>
                                {I18n.t('service_features')} ({' '}
                                {serviceitem.features.length} )
                              </h2>
                            </div>
                            {serviceitem.features.map((item, index) => (
                              <div className="mt-2" key={index}>
                                <div className="inner__subtitle">
                                  <h2>{item.title}</h2>
                                </div>
                                {item.description ? (
                                  <div className="Service__Features__Content">
                                    <div className="row mb-1">
                                      <div className="col-12">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(
                                            item.description,
                                          )}
                                          className="listing__description"></div>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}
                    </Card.Body>
                  </Card>
                  {(serviceitem.requirments ||
                    serviceitem.requirments !== '') &&
                  serviceinvoice.client_replies.length > 0 ? (
                    <Accordion defaultActiveKey={activeId}>
                      <Card
                        className={
                          activeId === '0'
                            ? 'Comments__Container active-panel'
                            : 'Comments__Container'
                        }>
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            onClick={() => toggleActive('0')}
                            variant="link"
                            eventKey="0">
                            {I18n.t('business_requirements')}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {serviceitem.requirments &&
                            serviceitem.requirments !== '' ? (
                              <div className="row">
                                <div className="col-12 mb-2">
                                  <div className="inner__subtitle">
                                    <h2>{I18n.t('seller_requirements')}</h2>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      serviceitem.requirments,
                                    )}
                                    className="listing__description"></div>
                                </div>
                              </div>
                            ) : null}
                            {serviceinvoice.client_replies.length > 0 ? (
                              <div
                                className={
                                  serviceitem.requirments &&
                                  serviceitem.requirments !== ''
                                    ? 'row mt-3'
                                    : 'row'
                                }>
                                <div className="col-12 mb-2">
                                  <div className="inner__subtitle">
                                    <h2>{I18n.t('buyer_reply')}</h2>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div
                                    dangerouslySetInnerHTML={createMarkup(
                                      buyerReply.reply,
                                    )}
                                    className="listing__description"></div>
                                </div>
                                {buyerReply.files.length > 0 ? (
                                  <div className="col-12">
                                    <h3 className="attachments__title">
                                      <span>{I18n.t('attached_files')}</span>
                                    </h3>
                                    <ul className="ProfileSkills">
                                      {buyerReply.files.map((file, index) => (
                                        <li key={index}>
                                          <span className="file__type">
                                            {file.extension}
                                          </span>
                                          <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            {truncate(file.filename, 20)}
                                          </a>{' '}
                                          -{' '}
                                          <span className="file__size">
                                            ({Size(file.size)})
                                          </span>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ) : null}
                              </div>
                            ) : null}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  ) : null}
                  {feedbacks && feedbacks.length > 0 ? (
                    <Card>
                      <Card.Header>
                        <div>{I18n.t('customer_feedback')}</div>
                      </Card.Header>
                      <Card.Body>
                        <Fragment>
                          <ul className="serviceFeedback__List">
                            {feedbacks.map(item => (
                              <li key={item.id}>
                                {item.user.type === 'Seller' &&
                                item.feedbacks.length > 0 ? (
                                  <Fragment>
                                    <div className="feedback__Buyer">
                                      <Link
                                        className="d-block mb-3"
                                        to={{
                                          pathname: `/services/${
                                            item.feedbackable.id
                                          }/${item.feedbackable.title
                                            .replace(/\s+/g, '-')
                                            .replace(/\//g, '-')
                                            .toLowerCase()}`,
                                        }}>
                                        {item.feedbackable.title}
                                      </Link>
                                      <div className="Comment__userInfo">
                                        <div className="UserPic">
                                          <img
                                            src={item.feedbacks[0].user.image}
                                            alt={
                                              item.feedbacks[0].user.fname +
                                              ' ' +
                                              item.feedbacks[0].user.lname
                                            }
                                          />
                                        </div>
                                        <div className="CommentContent__Details">
                                          <h3>
                                            <Link
                                              to={{
                                                pathname: `/in/${item.feedbacks[0].user.username}`,
                                              }}>
                                              {item.feedbacks[0].user.fname +
                                                ' ' +
                                                item.feedbacks[0].user.lname}
                                            </Link>
                                            <span className="userType">
                                              {I18n.t('client')}
                                            </span>
                                          </h3>
                                          <div className="CommentContent__Details__PostTime">
                                            {item.feedbacks[0].created_at.Date +
                                              ' - ' +
                                              item.feedbacks[0].created_at.Time}
                                          </div>
                                        </div>
                                      </div>
                                      <RatingStarsPreview
                                        rate={item.feedbacks[0].rate}
                                      />
                                      <div className="CommentContent">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(
                                            item.feedbacks[0].comment,
                                          )}></div>
                                      </div>
                                    </div>
                                    <div className="feedback__Seller">
                                      <div className="Comment__userInfo">
                                        <div className="UserPic">
                                          <img
                                            src={item.user.image}
                                            alt={
                                              item.user.fname +
                                              ' ' +
                                              item.user.lname
                                            }
                                          />
                                        </div>
                                        <div className="CommentContent__Details">
                                          <h3>
                                            <Link
                                              to={{
                                                pathname: `/in/${item.user.username}`,
                                              }}>
                                              {item.user.fname +
                                                ' ' +
                                                item.user.lname}
                                            </Link>
                                            <span className="userType">
                                              {I18n.t('freelancer')}
                                            </span>
                                          </h3>
                                          <div className="CommentContent__Details__PostTime">
                                            {item.created_at.Date +
                                              ' - ' +
                                              item.created_at.Time}
                                          </div>
                                        </div>
                                      </div>
                                      <RatingStarsPreview rate={item.rate} />
                                      <div className="CommentContent">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(
                                            item.comment,
                                          )}></div>
                                      </div>
                                    </div>
                                  </Fragment>
                                ) : (
                                  <Fragment>
                                    <div className="feedback__Buyer">
                                      <Link
                                        className="d-block mb-3"
                                        to={{
                                          pathname: `/services/${
                                            item.feedbackable.id
                                          }/${item.feedbackable.title
                                            .replace(/\s+/g, '-')
                                            .replace(/\//g, '-')
                                            .toLowerCase()}`,
                                        }}>
                                        {item.feedbackable.title}
                                      </Link>
                                      <div className="Comment__userInfo">
                                        <div className="UserPic">
                                          <img
                                            src={item.user.image}
                                            alt={
                                              item.user.fname +
                                              ' ' +
                                              item.user.lname
                                            }
                                          />
                                        </div>
                                        <div className="CommentContent__Details">
                                          <h3>
                                            <Link
                                              to={{
                                                pathname: `/in/${item.user.username}`,
                                              }}>
                                              {item.user.fname +
                                                ' ' +
                                                item.user.lname}
                                            </Link>
                                            <span className="userType">
                                              {I18n.t('client')}
                                            </span>
                                          </h3>
                                          <div className="CommentContent__Details__PostTime">
                                            {item.created_at.Date +
                                              ' - ' +
                                              item.created_at.Time}
                                          </div>
                                        </div>
                                      </div>
                                      <RatingStarsPreview rate={item.rate} />
                                      <div className="CommentContent">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(
                                            item.comment,
                                          )}></div>
                                      </div>
                                    </div>
                                    {item.feedbacks.length > 0 ? (
                                      <div className="feedback__Seller">
                                        <div className="Comment__userInfo">
                                          <div className="UserPic">
                                            <img
                                              src={item.feedbacks[0].user.image}
                                              alt={
                                                item.feedbacks[0].user.fname +
                                                ' ' +
                                                item.feedbacks[0].user.lname
                                              }
                                            />
                                          </div>
                                          <div className="CommentContent__Details">
                                            <h3>
                                              <Link
                                                to={{
                                                  pathname: `/in/${item.feedbacks[0].user.username}`,
                                                }}>
                                                {item.feedbacks[0].user.fname +
                                                  ' ' +
                                                  item.feedbacks[0].user.lname}
                                              </Link>
                                              <span className="userType">
                                                {I18n.t('freelancer')}
                                              </span>
                                            </h3>
                                            <div className="CommentContent__Details__PostTime">
                                              {item.feedbacks[0].created_at
                                                .Date +
                                                ' - ' +
                                                item.feedbacks[0].created_at
                                                  .Time}
                                            </div>
                                          </div>
                                        </div>
                                        <RatingStarsPreview
                                          rate={item.feedbacks[0].rate}
                                        />
                                        <div className="CommentContent">
                                          <div
                                            dangerouslySetInnerHTML={createMarkup(
                                              item.feedbacks[0].comment,
                                            )}></div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </Fragment>
                                )}
                              </li>
                            ))}
                          </ul>
                        </Fragment>
                      </Card.Body>
                    </Card>
                  ) : null}
                  {serviceinvoice.status.id !== 3 &&
                  serviceinvoice.status.id !== 4 &&
                  serviceinvoice.status.id !== 7 ? (
                    <ServiceInvoiceComments
                      params={props.match.params}
                      serviceinvoice={serviceinvoice}
                      invoiceId={invoiceId}
                      setConfShow={setConfShow}
                      setConfTitle={setConfTitle}
                      setConfMessage={setConfMessage}
                      setConfStatus={setConfStatus}
                      setDelay={setDelay}
                    />
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <NoPermisions />
      );
    } else {
      return <NoItems />;
    }
  } else {
    return (
      <LockCard
        title="sign_to_view"
        signFor="sign_for_preview"
        fullPage={true}
      />
    );
  }
}
