import { 
    DELETE_PORTFOLIO_SUCCESS,
    DELETE_PORTFOLIO_FAIL,
    DELETE_PORTFOLIO_DATA_LOADING
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors } from '../actions/errorActions';

// DELETE Portfolio Item

export const deleteportfolioitem = ({ id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: DELETE_PORTFOLIO_DATA_LOADING
    });
    axiosInstance.delete(`/api/portfolios?id=${id}`, tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: DELETE_PORTFOLIO_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'DELETE_PORTFOLIO_FAIL'))
            dispatch ({ 
                type: DELETE_PORTFOLIO_FAIL 
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'DELETE_PORTFOLIO_FAIL')),
                dispatch ({ 
                    type: DELETE_PORTFOLIO_FAIL 
                })
            )
        }
    });
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}