import React, { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Spin from "../Spin";
import { I18n } from "react-redux-i18n";
import NoItems from '../NoItems';
import { clearErrors } from "../../actions/errorActions";
import AddingRow from "../../components/AddingRow";
import AddingPaymentModal from "../AddingPaymentModal";
import ConfToast from "../ConfToast";

const MyBankAccount = () => {

    const dispatch = useDispatch();

    const {
        user,
        getUserDataLoading,
        isLoading,

        addingBankAccountLoading,
    } = useSelector((state) => ({
        user: state.userData.user,
        getUserDataLoading: state.userData.getUserDataLoading,
        isLoading: state.auth.isLoading,

        addingBankAccountLoading: state.payments.addingBankAccountLoading,
    }));

    const [lgShow, setLgShow] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };

    return (
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <AddingPaymentModal
                loading={addingBankAccountLoading}
                modalTitle={"bank_account"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
                paymentType="Bank"
            />
            <TitleComponent title={I18n.t('lancersin_withdrawal_accounts')} />
            <MetaComponent />
            <div className="alert alert-warning mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                <p className="my-1">{I18n.t('bank_account_note')}</p>
            </div>
            <AddingRow
                onAdding={() => {
                    handleShow();
                }}
                title={"bank_account"}
                //titleIconClassName={"icon-services"}
                btnText={user && user.bank_account ? "edit_accounnt" : "add_accounnt"}
                btnIconClassName={user && user.bank_account ? "icon-edit" : "icon-plus"}
                forceShowButton={true}
            />
            {
            getUserDataLoading || isLoading ?
                <Spin />
            : user && user.bank_account ?
            <div className="Profile-Card box-shadow BankAccount__Data">
                <div className="userProfile__Contacts">
                    <div className="row">
                        <div className="col-md-6">
                            <div><b>{I18n.t('first_name')}</b> {user.bank_account.fname}</div>
                        </div>
                        <div className="col-md-6">
                            <div><b>{I18n.t('last_name')}</b> {user.bank_account.lname}</div>
                        </div>
                        {
                            user.bank_account.email ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('email')}</b> {user.bank_account.email}</div>
                            </div>
                            : null
                        }
                        {
                            user.bank_account.account_number ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('account_number')}</b> {user.bank_account.account_number}</div>
                            </div>
                            : null
                        }
                        {
                            user.bank_account.iban ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('iban')}</b> {user.bank_account.iban}</div>
                            </div>
                            : null
                        }
                        {
                            user.bank_account.swift_code ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('swift_code')}</b> {user.bank_account.swift_code}</div>
                            </div>
                            : null
                        }
                        {
                            user.bank_account.routing_number ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('routing_number')}</b> {user.bank_account.routing_number}</div>
                            </div>
                            : null
                        }
                        <div className="col-md-6">
                            <div><b>{I18n.t('bank_name')}</b> {user.bank_account.bank_name}</div>
                        </div>
                        <div className="col-md-6">
                            <div><b>{I18n.t('account_currency')}</b> {user.bank_account.currency}</div>
                        </div>
                        <div className="col-md-6">
                            <div><b>{I18n.t('country')}</b> {user.bank_account.country}</div>
                        </div>
                        <div className="col-md-6">
                            <div><b>{I18n.t('city')}</b> {user.bank_account.city}</div>
                        </div>
                        <div className="col-md-6">
                            <div><b>{I18n.t('address')}</b> {user.bank_account.address}</div>
                        </div>
                        {
                            user.bank_account.post_code ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('postcode')}</b> {user.bank_account.post_code}</div>
                            </div>
                            : null
                        }
                        {
                            user.bank_account.province ?
                            <div className="col-md-6">
                                <div><b>{I18n.t('state_province')}</b> {user.bank_account.province}</div>
                            </div>
                            : null
                        }
                        {
                            user.payment_method === "bank_account" ?
                                <div className="col-12 mt-3">
                                    <div className="account__type__types">
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input type="checkbox" id="bankAccountData" name="bankAccountData" value="1" className="form-check-input" checked disabled />
                                                <label title="" htmlFor="bankAccountData" className="form-check-label">{I18n.t('make_default_payment_method')}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </div>
            </div>
            :
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <NoItems
                            title="bank_account"
                            message="no_bank_account"
                        />
                    </div>
                </div>
            </Fragment>
            }
        </Fragment>
    );
};

export default MyBankAccount;
