import React, { Fragment } from 'react';
import { I18n } from 'react-redux-i18n';

const ProposalLimit = (props) => {
    const {
        title,
        message,
        className
    } = props
    return(
        <div className="No__Data">
            <div className={ className ? className + " No__Data__Container" : "No__Data__Container"}>
                <Fragment>
                    <figure>
                        <span className="proposalImg"></span>
                    </figure>
                    <h1> { title ? I18n.t(title) : I18n.t('bids_limit')}</h1>
                    <p>
                        { message ? I18n.t(message) : I18n.t('bids_limit_message')}
                    </p>
                </Fragment>
            </div>
        </div>
    )
}

export default ProposalLimit;