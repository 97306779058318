import { axiosInstance } from '../axiosInstance';
import { GET_ALL_PORTFOLIOS_SUCCESS, GET_ALL_PORTFOLIOS_FAIL, ALL_PORTFOLIOS_ITEMS_LOADING } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getallPortfolios = (user_id = null, publish = null, id = null, page = null, limit = null, cat_id = null, sub_cat_id = null, keywords = null, view_in_home = null) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: ALL_PORTFOLIOS_ITEMS_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/portfolios', 
                {params: { user_id, publish, id, page, limit, cat_id, sub_cat_id, keywords, view_in_home} }
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_ALL_PORTFOLIOS_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_ALL_PORTFOLIOS_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_ALL_PORTFOLIOS_FAIL'));
                dispatch ({ 
                    type: GET_ALL_PORTFOLIOS_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ALL_PORTFOLIOS_FAIL')),
                    dispatch ({ 
                        type: GET_ALL_PORTFOLIOS_FAIL 
                    })
                )
            }
        }
    }
};