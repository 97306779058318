import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from 'react-router-dom';
import { Button, Form, Collapse } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../TextInput";
import { SelectBox } from "../SelectBox";
import { getallServices } from '../../actions/getservicesActions';
import { getProjects } from "../../redux/project/actions/getActions";
import { getArticles } from "../../redux/article/actions/getActions";
import { getallPortfolios } from '../../actions/getportfoliosActions';
import { getFreelancers } from '../../actions/freelancersActions';
import { getContests } from "../../redux/contest/actions/getActions";
import { RESET_PORTFOLIO_ITEM } from "../../actions/types";

export default function Filteration(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  var { 
    portfolioItemAdded,
    cats, 
    statuses, 
    getStatusesLoading, 
    projectsPrices, 
    getProjectsPricesLoading,
    locale,
  } = useSelector((state) => ({
    portfolioItemAdded: state.addportfolioitem.portfolioItem,
    cats: state.category.cats || [],
    statuses: state.status.statuses,
    getStatusesLoading: state.status.getStatusesLoading,
    projectsPrices: state.project.projectsPrices || [],
    getProjectsPricesLoading: state.project.getProjectsPricesLoading,
    locale: state.i18n.locale,
  }));

  var { 
    type,
    open,
    setOpen,
    limit,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  var [category, setCategory] = useState({});
  var [subCategory, setSubCategory] = useState({});
  var [catId, setCatId] = useState("");
  var [subCatId, setSubCatId] = useState("");
  var [status, setStatus] = useState({});
  var [statusId, setStatusId] = useState("");

  //Filteration Inputs
  var [keywords, setKeywords] = useState("");
  var [min_price, setMinPrice] = useState("");
  var [max_price, setMaxPrice] = useState("");
  var [min_days, setMinDays] = useState("");
  var [max_days, setMaxDays] = useState("");
  const [chosenProjectPrice, setChosenProjectPrice] = useState({});
  const [priceId, setPriceId] = useState("");

  var categories = cats.map((item) => {
    return {
      id: item.id,
      value: item.title,
      label: item.title,
    };
  });


  var subCategories = getSubCategories(catId);

  function getSubCategories(catId) {
    if (catId) {
      let targetCat = cats.find((item) => item.id === catId);
      let { sub_categories = [] } = targetCat || {};
      let subsCat = sub_categories.map(({ id, title }) => ({
        id,
        value: title,
        label: title,
      }));
      return subsCat;
    }
    return [];
  }

  function onKeywordsChange(e) {
    setKeywords(e.target.value);
  }
  function onMinPriceChange(e){
    setMinPrice(e.target.value);
  }
  function onMaxPriceChange(e){
    setMaxPrice(e.target.value);
  }
  function onMinDaysChange(e){
    setMinDays(e.target.value);
  }
  function onMaxDaysChange(e){
    setMaxDays(e.target.value);
  }

  function handleCatChange(e) {
    setCatId(e.id);
    setCategory({ label: e.label, value: e.value });

    setSubCatId("");
    setSubCategory("");
  }
  function handleStatusChange(e){
    setStatusId(e.id);
    setStatus({ label: e.label, value: e.value });
  }

  function handleSubcatChange(e) {
    setSubCatId(e.id);
    setSubCategory({ label: e.label, value: e.value });
  }

  function handleChosenProjectPrice(chosenPrice) {
    setPriceId(chosenPrice.id);
    setChosenProjectPrice(chosenPrice);
  }

  function onSubmit(e) {
    e.preventDefault();
    let page = 1;
    const cat_id = catId ? catId : "";
    const sub_cat_id = subCatId ? subCatId : "";
    let status_id = statusId ? statusId : "";
    const price_id = priceId ? priceId : "";
    if(type === "projects"){
      dispatch(getProjects(null, null, page, limit, null, null, price_id, min_days, max_days, cat_id, sub_cat_id, status_id, keywords, locale));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/projects';
      history.push(`${path}?page=${page}&catId=${cat_id}&subCatId=${sub_cat_id}&keywords=${keywords}&priceId=${price_id}&statusId=${status_id}&minDays=${min_days}&maxDays=${max_days}`);
    } else if(type === "services"){
      const publish = 1;
      dispatch(getallServices(null, cat_id, sub_cat_id, status_id = 1, publish, null, page, limit, keywords, min_price, max_price, min_days, max_days));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/services';
      history.push(`${path}?page=${page}&catId=${cat_id}&subCatId=${sub_cat_id}&keywords=${keywords}&minPrice=${min_price}&maxPrice=${max_price}&minDays=${min_days}&maxDays=${max_days}`);
    } else if(type === "articles"){
      status_id = 1;
      dispatch(getArticles(null, null, page, limit, cat_id, sub_cat_id, keywords, status_id));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/articles';
      history.push(`${path}?page=${page}&catId=${cat_id}&subCatId=${sub_cat_id}&keywords=${keywords}`);
    } else if(type === "portfolio"){
      const publish = 1;
      dispatch(getallPortfolios(null, publish, null, page, limit, cat_id, sub_cat_id, keywords, null));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/portfolio';
      history.push(`${path}?page=${page}&catId=${cat_id}&subCatId=${sub_cat_id}&keywords=${keywords}`);
    } else if(type === "freelancers"){
      const plans = [1, 2];
      const name = keywords;
      dispatch(getFreelancers(name, null, null, null, null, plans, page, cat_id, sub_cat_id, null, locale, limit));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/freelancers';
      history.push(`${path}?page=${page}&catId=${cat_id}&subCatId=${sub_cat_id}&keywords=${keywords}`);
    } else if(type === "contests"){
      dispatch(getContests(null, null, page, limit, min_price,  max_price, cat_id, sub_cat_id, keywords, locale));
    }
    setOpen(!open);
  }

  useEffect(() => {
    if(portfolioItemAdded === "success"){
          const page = 1;
          const limit = 30;
          const publish = 1;
          dispatch(getallPortfolios(null, publish, null, page, limit, null, null, null, null));
          dispatch({ 
              type: RESET_PORTFOLIO_ITEM
          });
          setConfShow(true);
          setConfTitle(I18n.t('add_portfolio_item_title'));
          setConfMessage(I18n.t('add_portfolio_success'));
          setConfStatus('Toast__Container__Success');
          setDelay(6000);
      }
  }, [portfolioItemAdded, dispatch, setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  useEffect(() => {
    let page = 1;
    let cat_id = "";
    let sub_cat_id = "";
    let price_id = "";
    let status_id = "";
    let keywords = "";
    let max_days = "";
    let max_price = ""; 
    let min_days = "";
    let min_price = "";
    if(props.page){
        page = props.page;
    }
    if(typeof window != 'undefined'){
        var url_string = window.location.href; 
        var url = new URL(url_string);
        let targetCat = null;
        if(url_string.indexOf("catId=") > -1){
            if(url.searchParams){
              cat_id = url.searchParams.get("catId");
              setCatId(parseInt(cat_id));
              targetCat = cats.find((item) => item.id === parseInt(cat_id));
              if(targetCat){
                setCategory({ label: targetCat.title, value: targetCat.title });
              }
            }
        }else{
          cat_id = "";
          setCatId(cat_id);
        }

        if(url_string.indexOf("subCatId=") > -1){
          if(url.searchParams){
            sub_cat_id = url.searchParams.get("subCatId");
            setSubCatId(parseInt(sub_cat_id));
            let { sub_categories = [] } = targetCat || {};
            if(sub_categories && sub_categories.length > 0){
              let targetSubCat = sub_categories.find((item) => item.id === parseInt(sub_cat_id));
              if(targetSubCat){
                setSubCategory({ label: targetSubCat.title, value: targetSubCat.title })
              }
            }
          }
        }else{
          sub_cat_id = "";
          setSubCatId(sub_cat_id);
        }

        if(url_string.indexOf("keywords=") > -1){
          if(url.searchParams){
            keywords = url.searchParams.get("keywords");
            setKeywords(keywords);
          }
        }else{
          keywords = "";
          setKeywords(keywords);
        }

        if(url_string.indexOf("minPrice=") > -1){
          if(url.searchParams){
            min_price = url.searchParams.get("minPrice");
            setMinPrice(min_price);
          }
        }else{
          min_price = "";
          setMinPrice(min_price);
        }

        if(url_string.indexOf("maxPrice=") > -1){
          if(url.searchParams){
            max_price = url.searchParams.get("maxPrice");
            setMaxPrice(max_price);
          }
        }else{
          max_price = "";
          setMaxPrice(max_price);
        }

        if(url_string.indexOf("minDays=") > -1){
          if(url.searchParams){
            min_days = url.searchParams.get("minDays");
            setMinDays(min_days);
          }
        }else{
          min_days = "";
          setMinDays(min_days);
        }

        if(url_string.indexOf("maxDays=") > -1){
          if(url.searchParams){
            max_days = url.searchParams.get("maxDays");
            setMaxDays(max_days);
          }
        }else{
          max_days = "";
          setMaxDays(max_days);
        }

        if(url_string.indexOf("priceId=") > -1){
          if(url.searchParams){
            price_id = url.searchParams.get("priceId");
            setPriceId(parseInt(keywords));
          }
        }else{
          price_id = "";
          setPriceId(price_id);
        }

        if(url_string.indexOf("statusId=") > -1){
          if(url.searchParams){
            status_id = url.searchParams.get("statusId");
            setStatusId(parseInt(status_id));
          }
        }else{
          status_id = "";
          setStatusId(status_id);
        }
    }
    if(type){
      if(type === "projects"){
        dispatch(getProjects(null, null, page, limit, null, null, price_id, min_days, max_days, cat_id, sub_cat_id, status_id, keywords, locale));
      } else if(type === "services"){
        status_id = 1;
        const publish = 1;
        dispatch(getallServices(null, cat_id, sub_cat_id, status_id, publish, null, page, limit, keywords, min_price, max_price, min_days, max_days));
      } else if(type === "articles"){
        status_id = 1;
        dispatch(getArticles(null, null, page, limit, cat_id, sub_cat_id, keywords, status_id));
      } else if(type === "portfolio"){
        const publish = 1;
        dispatch(getallPortfolios(null, publish, null, page, limit, cat_id, sub_cat_id, keywords, null));
      } else if(type === "freelancers"){
        const plans = [1, 2];
        const name = keywords;
        dispatch(getFreelancers(name, null, null, null, null, plans, page, cat_id, sub_cat_id, null, locale, limit));
      } else if(type === "contests"){
        dispatch(getContests(null, null, page, limit, min_price,  max_price, cat_id, sub_cat_id, keywords, locale));
      }
    }
  }, [props.page, dispatch, locale, limit, type, cats]);
  function onReset(e) {
    e.preventDefault();
    let page = 1;
    setCatId("");
    setSubCatId("");
    setKeywords("");
    setMinPrice("");
    setMaxPrice("");
    setMinDays("");
    setMaxDays("");
    setCategory({ label: "", value: "" });
    setSubCategory({ label: "", value: "" });
    setChosenProjectPrice({});
    setStatusId("");
    setStatus({});
    let status_id = null;
    if(type === "projects"){
      dispatch(getProjects(null, null, page, limit, null, null, null, null, null, null, null, status_id, null, locale));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/projects';
      history.push(`${path}?page=${page}`);
    } else if(type === "services"){
      const publish = 1;
      dispatch(getallServices(null, null, null, status_id = 1, publish, null, page, limit, null, null, null, null, null));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/services';
      history.push(`${path}?page=${page}`);
    } else if(type === "articles"){
      status_id = 1;
      dispatch(getArticles(null, null, page, limit, null, null, null, status_id));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/articles';
      history.push(`${path}?page=${page}`);
    } else if(type === "portfolio"){
      const publish = 1;
      dispatch(getallPortfolios(null, publish, null, page, limit, null, null, null, null));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/portfolio';
      history.push(`${path}?page=${page}`);
    } else if(type === "freelancers"){
      const plans = [1, 2];
      dispatch(getFreelancers(null, null, null, null, null, plans, page, null, null, null, locale, limit));
      const path = typeof window !== 'undefined' ? window.location.pathname : '/freelancers';
      history.push(`${path}?page=${page}`);
    } else if(type === "contests"){
      dispatch(getContests(null, null, page, limit, null,  null, null, null, null, locale));
    }
    setOpen(!open);
  };

  return (
    <div className="SearchCard">
      <div className="accordion">
        <div className={open === true ? "card mb-4" : "card mb-0"} >
            <Collapse in={open}>
              <div>
                <div className="card-body">
                  <Form>
                      <TextInput
                          required={false}
                          controlId="Keywords"
                          label="search_keywords"
                          type="text"
                          name="keywords"
                          feedbackType="invalid"
                          onChange={onKeywordsChange}
                          value={keywords}
                      />

                      <div className="row">
                          <div className="col-md-6">
                            <SelectBox
                                required={false}
                                controlId="Category"
                                name="category"
                                label="category"
                                options={categories}
                                value={category}
                                onChange={handleCatChange}
                                feedbackType="invalid"
                                placeholder={I18n.t("category")}
                            />
                          </div>
                          <div className="col-md-6">
                            <SelectBox
                                required={false}
                                controlId="Sub-Category"
                                label="sub_category"
                                options={subCategories}
                                value={subCategory}
                                onChange={handleSubcatChange}
                                feedbackType="invalid"
                                placeholder={I18n.t("sub_category")}
                                noOptionsMessage={Object.keys(category).length > 0 ? I18n.t('select_no_options') : I18n.t('choose_cat_first')}
                            />
                          </div>
                          {
                            type === "services" || type === "contests" ?
                            <Fragment>
                              <div className="col-md-6">
                                  <TextInput
                                      required={false}
                                      controlId="MinPrice"
                                      label="min_price"
                                      type="number"
                                      name="min_price"
                                      feedbackType="invalid"
                                      onChange={onMinPriceChange}
                                      value={min_price}
                                  />
                              </div>
                              <div className="col-md-6">
                                  <TextInput
                                      required={false}
                                      controlId="MaxPrice"
                                      label="max_price"
                                      type="number"
                                      name="max_price"
                                      feedbackType="invalid"
                                      onChange={onMaxPriceChange}
                                      value={max_price}
                                  />
                              </div>
                            </Fragment>
                            : type === "projects" ?
                            <Fragment>
                              <div className="col-md-6">
                                <SelectBox
                                  loading={getProjectsPricesLoading}
                                  required={false}
                                  controlId="Budget"
                                  name="budget"
                                  label="budget"
                                  options={projectsPrices.map((price) => ({
                                    ...price,
                                    label: price.title,
                                    value: price.title,
                                  }))}
                                  value={chosenProjectPrice}
                                  onChange={handleChosenProjectPrice}
                                  feedbackType="invalid"
                                  placeholder={"budget"}
                                />
                              </div>
                              <div className="col-md-6">
                              <SelectBox
                                  loading={getStatusesLoading}
                                  required={false}
                                  controlId="Status"
                                  name="status"
                                  label="status"
                                  options={statuses.filter(function(item){
                                    if(item.id === 8 || item.id === 5 || item.id === 6){
                                      return true
                                    }
                                    return false
                                  }).map(function (item){ 
                                    if(item.id === 8){
                                      return ({
                                          ...item,
                                          value: I18n.t('receiving_bids'),
                                          label: I18n.t('receiving_bids'),
                                      })
                                    }else if(item.id === 5){
                                      return ({
                                        ...item,
                                        value: I18n.t('in_progress'),
                                        label: I18n.t('in_progress'),
                                      })
                                    }else if(item.id === 6){
                                      return ({
                                        ...item,
                                        value:  I18n.t('closed'),
                                        label:  I18n.t('closed'),
                                      })
                                    }
                                    return false
                                  })}
                                  value={status}
                                  onChange={handleStatusChange}
                                  feedbackType="invalid"
                                  placeholder={I18n.t('status')}
                              />
                              </div>
                            </Fragment>
                            : null
                          }
                          {
                            type === "articles" || type === "portfolio" || type === "freelancers" ?
                            null :
                            <Fragment>
                              <div className="col-md-6">
                                  <TextInput
                                      required={false}
                                      controlId="MinDays"
                                      label="min_days"
                                      type="number"
                                      name="min_days"
                                      feedbackType="invalid"
                                      onChange={onMinDaysChange}
                                      value={min_days}
                                  />
                              </div>
                              <div className="col-md-6">
                                  <TextInput
                                      required={false}
                                      controlId="MaxDays"
                                      label="max_days"
                                      type="number"
                                      name="max_days"
                                      feedbackType="invalid"
                                      onChange={onMaxDaysChange}
                                      value={max_days}
                                  />
                              </div>
                            </Fragment>
                          }
                      </div>
                      <div className="Form__Actions">
                          <Button
                              variant="primary"
                              className="btn "
                              type="submit"
                              onClick={onSubmit}
                              data-backdrop="static"
                          >
                              <Translate value="search" />
                          </Button>
                          <Button
                              variant="secondary"
                              className="btn"
                              type="button"
                              onClick={onReset}
                              data-backdrop="static"
                          >
                              <Translate value="reset" />
                          </Button>
                      </div>
                  </Form>
                </div>   
              </div>      
            </Collapse>
        </div>
      </div>
    </div>
  );
}
