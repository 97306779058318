import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import Filteration from '../Filteration';
import { I18n } from "react-redux-i18n";
import { TitleComponent } from "../TitleComponent";
import AddingArticleModal from '../AddingArticleModal';
import { getArticles } from "../../redux/article/actions/getActions";
import Spin from "../Spin";
import ArticleCard from "../ArticleCard";
import ConfToast from "../ConfToast";
import NoItems from "../NoItems";
import ConnectionLost from "../ConnectionLost";

export default function ArticlesList() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const path = typeof window !== 'undefined' ? window.location.pathname : '/freelancers';
  const {
      isAuthenticated,
      articles = [],
      getArticlesLoading,
      addingArticleLoading,
      totalArticles,
      articlesPerPage,
      errorId,
      errorMessage,
    } = useSelector((state) => ({
      isAuthenticated: state.auth.isAuthenticated,
      articles: state.article.articles,
      getArticlesLoading: state.article.getArticlesLoading,
      addingArticleLoading: state.article.addingArticleLoading,
      totalArticles: state.article.totalArticles,
      articlesPerPage: state.article.articlesPerPage,
      errorId: state.error.id,
      errorMessage: state.error.msg,
  }));
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [toggleAddArticleModel, setToggleAddArticleModel] = useState(false);
  const [articlesLoading, setArticlesLoading] = useState(true);
  const [loadPageNumber, setLoadPageNumber] = useState(true);
  const totalItemsCount = totalArticles;
  const itemsCountPerPage = Number(articlesPerPage);

  useEffect(() => {
    setArticlesLoading(getArticlesLoading);
  }, [getArticlesLoading]);

  function handleHide() {
      setToggleAddArticleModel(!toggleAddArticleModel);
  }

  useEffect(() => {
    let url_string = "";
    let url = "";
    let page = "";
    if(loadPageNumber){
        if(typeof window != 'undefined'){
            if(window.location.href.indexOf("page=") > -1){
                url_string = window.location.href; 
                url = new URL(url_string);
                if(url.searchParams){
                    page = url.searchParams.get("page");
                    setActivePage(parseInt(page));
                    setLoadPageNumber(false);
                }
            }else{
                setActivePage(1);
                history.push(`${path}?page=${1}`);
                setLoadPageNumber(false);
            }
        }
    }else{
        if(typeof window != 'undefined'){
            if(window.location.href.indexOf("page=") > -1){
                url_string = window.location.href; 
                url = new URL(url_string);
                if(url.searchParams){
                    page = url.searchParams.get("page");
                    setActivePage(parseInt(page));
                    setLoadPageNumber(false);
                }
            }
        }
    }
  }, [loadPageNumber, location, history, path]);

  const handlePageChange = (pageNumber) => {
      setActivePage(pageNumber);
      if(typeof window != 'undefined'){
        var url_string = window.location.href; 
        var url = new URL(url_string);
        if(url_string.indexOf("page=") > -1){
            var search_params = url.searchParams;
            if(search_params){
              search_params.set('page', pageNumber);
              url.search = search_params.toString();
              var new_url = url.pathname + url.search;
              history.push(new_url);
            }
        }
      }
  };

  useEffect(() => {
      const page = activePage;
      setActivePage(page);
      const limit = 20;
      const status_id = 1;
      dispatch(getArticles(null, null, page, limit, null, null, null, status_id));
  }, [dispatch, activePage]);

  return (
    <Fragment>
      <TitleComponent title={I18n.t('lancersin_articles')} />
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="breadcrumb__container">
        <div className="container">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{I18n.t('articles')}</li>
              </ol>
          </nav>
        </div>
      </div>
      <div className="container articles__list">
        <div className="row">
            <div className="col-12 mb-4">
                <div className="section__title my-0">
                    <div className="section__title__text">
                        <span className="icon icon-articles"></span>
                        {I18n.t('articles')}
                    </div>
                    <div>
                      {
                        isAuthenticated ?
                        <button className="btn mx-3" onClick={() => {
                          setToggleAddArticleModel(!toggleAddArticleModel);
                        }}>
                            <span className="icon icon-plus"></span>
                            {I18n.t('post_article')}
                        </button>
                        : null
                      }
                      {
                        open === false ?
                        <button className="btn" onClick={() => setOpen(!open)}>
                            <span className="icon icon-search"></span>
                            {I18n.t('advanced_search')}
                        </button>
                        :
                        <button className="btn" onClick={() => setOpen(!open)}>
                            <span className="fas fa-times"></span>
                            {I18n.t('close_search')}
                        </button>
                      }
                    </div>
                    <AddingArticleModal
                        loading={addingArticleLoading}
                        modalTitle={"post_article"}
                        className="CustomPopup"
                        size="lg"
                        show={toggleAddArticleModel}
                        onHide={handleHide}
                        backdrop="static"
                        keyboard={false}
                        aria-labelledby="example-modal-sizes-title-lg"
                        setConfShow={setConfShow}
                        setConfTitle={setConfTitle}
                        setConfMessage={setConfMessage}
                        setConfStatus={setConfStatus}
                        setDelay={setDelay}
                    />
                </div>
            </div>
        </div>  
        {
          loadPageNumber ?
          <Spin />
          :
          <Fragment>
            <div className="row">
              <div className="col-12">
                  <Filteration 
                    page={activePage} 
                    limit={20}
                    type="articles" 
                    open={open}
                    setOpen={setOpen} 
                  />
              </div>
            </div>
            <div className="row articles__content">
              {
                articlesLoading ? 
                  <Spin />
                : errorId === "GET_ARTICLES_FAIL" && errorMessage === "Network Error" ?
                <div className="col-12">
                    <ConnectionLost
                      errorType= "connection"
                      title = "network_error_title"
                      message = "network_error_message"
                    />
                </div>
                : errorId === "GET_ARTICLES_FAIL" ?
                <div className="col-12">
                    <ConnectionLost
                      errorType= "somethingWrong"
                      title = "something_error_title"
                      message = "something_error_message"
                    />
                </div>
                : articles.length > 0 && !articlesLoading ? 
                <Fragment>
                  {
                    articles.map((item) => (
                      <ArticleCard key={`article-#${item.id}`} item={item} />
                    ))
                  }
                  <div className="col-12">
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                  </div>
                </Fragment>
                :
                <div className="col-12">
                {
                    isAuthenticated ?
                    <NoItems
                        title="no_articles_found"
                        message="no_articles_message_with_action"
                        buttonText="post_article"
                        iconClassName="icon icon-plus"
                        onClick={() => {
                            setToggleAddArticleModel(!toggleAddArticleModel);
                        }}
                    />
                    :
                    <NoItems
                        title="no_articles_found"
                        message="no_articles_message"
                    />
                }
                </div>
              }
            </div>
          </Fragment>
        }
      </div>
    </Fragment>
  );
}