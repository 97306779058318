import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
    // GET WITHDRAWAL REQUESTS
    GET_WITHDRAWAL_REQUESTS_LOADING,
    GET_WITHDRAWAL_REQUESTS_SUCCESS,
    GET_WITHDRAWAL_REQUESTS_FAIL,
} = TYPES;

// Get User Withdrawal Requests
export function getWithdrawalRequests(page = null, limit = null, id = null, locale) {
    return async function getWithdrawalRequestsWithDispatch(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
        type: GET_WITHDRAWAL_REQUESTS_LOADING
      });
      try {
          let response = await axiosInstance
          .get(API.WITHDRAWAL_REQUESTS, {params: { page, limit, id } , ...tokenConfig(getState, locale)});
          if(response.status !== 200){
              dispatch({
                type: GET_WITHDRAWAL_REQUESTS_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
            dispatch({
              type: GET_WITHDRAWAL_REQUESTS_SUCCESS,
              payload: response.data
            })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch({
              type: GET_WITHDRAWAL_REQUESTS_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_WITHDRAWAL_REQUESTS_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            dispatch({
              type: GET_WITHDRAWAL_REQUESTS_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_WITHDRAWAL_REQUESTS_FAIL'));
          }
      }
    }
}