// Actions for Adding Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  ADD_FAVOURITE_LOADING,
  ADD_FAVOURITE_SUCCESS,
  ADD_FAVOURITE_FAIL,
} = TYPES;

// ADD Favourite
export function addFavourite( morphType = null, morphID = null, locale ) {
  return async function addFavouriteWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: ADD_FAVOURITE_LOADING
    });
    try {
      const body = {morphType, morphID};
        let response = await axiosInstance
        .post(API.FAVOURITE, body , tokenConfig(getState, locale));
        if(response.status !== 200){
            dispatch({
              type: ADD_FAVOURITE_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: ADD_FAVOURITE_SUCCESS,
            payload: response.data.data,
            status: response.data.response
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch(returnErrors(error.response.data, error.response.status, 'ADD_FAVOURITE_FAIL'));
          dispatch ({ 
              type: ADD_FAVOURITE_FAIL 
          });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_FAVOURITE_FAIL'));
          dispatch ({ 
              type: ADD_FAVOURITE_FAIL 
          })
        }
    }
  }
}
