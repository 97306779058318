import { PROFILE_DATA_LOADING, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Update User Profile

export const updateuserprofile = ({ fname, lname, email, phone, birthday, price_per_hour, position, about, skills, country_id, city_id, image, categories, sub_categories }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: PROFILE_DATA_LOADING
        });
        const body = JSON.stringify({ fname, lname, email, phone, birthday, price_per_hour, position, about, skills, country_id, city_id, image, categories, sub_categories });
        try {
            let response = await axiosInstance.post(
                '/api/update_profile', 
                body, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: UPDATE_PROFILE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: UPDATE_PROFILE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'UPDATE_PROFILE_FAIL'));
                dispatch ({ 
                    type: UPDATE_PROFILE_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'UPDATE_PROFILE_FAIL')),
                    dispatch ({ 
                        type: UPDATE_PROFILE_FAIL 
                    })
                )
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}