import TYPES, { CLEAR_SEND_MESSAGE_SUCCESS, CLEAR_LAST_MESSAGE, CLEAR_MARK_MESSAGE_READ_SUCCESS, CLEAR_MESSAGE_REPLIES, CLEAR_MARK_ALL_MESSAGE_READ_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  // Send Message
  sendMessageLoading: false,
  sendMessageError: "",
  sendMessageSuccess: false,
  // Get Messages
  getMessagesLoading: false,
  getMessagesError: "",
  messages: [],
  unread: 0,
  lastMessage: null,
  getAllMessagesLoading: false,
  getAllMessagesError: "",
  allMessages: [],
  totalAllMessages: 0,
  allMessagesPerPage: 0,
  getRepliesLoading: false,
  getRepliesError: "",
  replies: [],
  totalReplies: 0,
  repliesPerPage: 0,
  // Get Message
  currentMessageLoading: false,
  currentMessageError: "",
  currentMessage: [],
  //Mark Message as Read
  markMessageError: "",
  markMessageSuccess: false,
  //Mark All Messages as Read
  loadingMarkMessagesRead: false,
  markMessagesError: "",
  markMessagesSuccess: false,
};

const {
  SEND_MESSAGE_LOADING,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
  GET_MESSAGES_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_LAST_MESSAGE_SUCCESS,
  GET_ALL_MESSAGES_LOADING,
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES_FAIL,
  GET_REPLIES_LOADING,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_FAIL,
  GET_MESSAGE_DETAILS_LOADING,
  GET_MESSAGE_DETAILS_SUCCESS,
  GET_MESSAGE_DETAILS_FAIL,
  MARK_MESSAGE_READ_SUCCESS,
  MARK_MESSAGE_READ_FAIL,
  MARK_ALL_MESSAGES_READ_LOADING,
  MARK_ALL_MESSAGES_READ_SUCCESS,
  MARK_ALL_MESSAGES_READ_FAIL,
} = TYPES;

export default function projectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Send Message
    case SEND_MESSAGE_LOADING:
      return {
        ...state,
        sendMessageLoading: true,
        sendMessageError: "",
      };
    case SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        sendMessageLoading: false,
        sendMessageError: "",
        sendMessageSuccess: action.payload === "success",
      };
    case SEND_MESSAGE_FAIL:
      return {
        ...state,
        sendMessageLoading: false,
        sendMessageError: action.error,
      };
    case CLEAR_SEND_MESSAGE_SUCCESS:
      return{
        ...state,
        sendMessageSuccess: false,
      }
    // Get User Messages
    case GET_MESSAGES_LOADING:
      return {
        ...state,
        getMessagesLoading: true,
        getMessagesError: "",
      };
    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        getMessagesLoading: false,
        getMessagesError: "",
        messages: action.payload.data,
        unread: action.payload.unread,
      };
    case GET_MESSAGES_FAIL:
      return {
        ...state,
        getMessagesLoading: false,
        getMessagesError: action.error,
      };

    case GET_LAST_MESSAGE_SUCCESS:
      return{
        ...state,
        lastMessage: action.payload,
      };
    case CLEAR_LAST_MESSAGE:
      return{
        ...state,
        lastMessage: null,
      }
    
    // Get All Messages
    case GET_ALL_MESSAGES_LOADING:
      return {
        ...state,
        getAllMessagesLoading: true,
        getAllMessagesError: "",
      };
    case GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        getAllMessagesLoading: false,
        getAllMessagesError: "",
        allMessages: action.payload.data,
        totalAllMessages: action.payload.totalRecords,
        allMessagesPerPage: action.payload.itemsPerPage,
      };
    case GET_ALL_MESSAGES_FAIL:
      return {
        ...state,
        getAllMessagesLoading: false,
        getAllMessagesError: action.error,
      };


    // Get Current Message
    case GET_MESSAGE_DETAILS_LOADING:
      return {
        ...state,
        currentMessageLoading: true,
        currentMessageError: "",
      };
    case GET_MESSAGE_DETAILS_SUCCESS:
      return {
        ...state,
        currentMessageLoading: false,
        currentMessageError: "",
        currentMessage: action.payload.data,
      };
    case GET_MESSAGE_DETAILS_FAIL:
      return {
        ...state,
        currentMessageLoading: false,
        currentMessageError: action.error,
      };


    // Get Replies
    case GET_REPLIES_LOADING:
      return {
        ...state,
        getRepliesLoading: true,
        getRepliesError: "",
      };
    case GET_REPLIES_SUCCESS:
      return {
        ...state,
        getRepliesLoading: false,
        getRepliesError: "",
        replies: action.payload.data,
        totalReplies: action.payload.totalRecords,
        repliesPerPage: action.payload.itemsPerPage,
      };
    case GET_REPLIES_FAIL:
      return {
        ...state,
        getRepliesLoading: false,
        getRepliesError: action.error,
      };
    case CLEAR_MESSAGE_REPLIES:
      return{
        ...state,
        replies: [],
      };
    // Mark as Read
    case MARK_MESSAGE_READ_SUCCESS :
      return{
        ...state,
        markMessageSuccess: action.payload === "success"
      };
    case MARK_MESSAGE_READ_FAIL:
      return{
        ...state,
        markMessageError: action.error,
      }
    case CLEAR_MARK_MESSAGE_READ_SUCCESS:
      return{
        ...state,
        markMessageSuccess: false,
      }
    // Mark All as Read
    case MARK_ALL_MESSAGES_READ_LOADING:
      return{
        ...state,
        loadingMarkMessagesRead: true,
        markMessagesError: "",
      };
    case MARK_ALL_MESSAGES_READ_SUCCESS:
      return{
        ...state,
        loadingMarkMessagesRead: false,
        markMessagesSuccess: action.payload === "success"
      };
    case MARK_ALL_MESSAGES_READ_FAIL:
      return{
        ...state,
        loadingMarkMessagesRead: false,
        markMessagesError: action.error,
      }
    case CLEAR_MARK_ALL_MESSAGE_READ_SUCCESS:
      return{
        ...state,
        markMessagesSuccess: false,
      }
      
    default:
      return state;
  }
}
