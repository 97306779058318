import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../../actions/authActions';
import {Translate, I18n} from 'react-redux-i18n';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {useHistory} from 'react-router-dom';
import {RESET_VERIFICATION} from '../../actions/types';
import ConfToast from '../ConfToast';
import GRecaptch from '../GRecaptch';
const {Control} = Form;
const {Feedback} = Control;

function Signin() {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    locale,
    isAuthenticated,
    errorId,
    errorMsg: {errors = {}},
    loginLoading,
    resetPasswordSuccess,
    savedProject,
  } = useSelector(state => ({
    locale: state.i18n.locale,
    isAuthenticated: state.auth.isAuthenticated,
    errorId: state.error.id,
    errorMsg: state.error.msg,
    loginLoading: state.auth.loginLoading,
    resetPasswordSuccess: state.resetPassword.resetPasswordSuccess,
    savedProject: state.project.savedProject,
  }));

  const [loginID, setLoginID] = useState('');
  const [password, setPassword] = useState('');
  const [localErrors, setLocalErrors] = useState({});
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [isHuman, setIsHuman] = useState(false);
  const [prevPath, setPrevPath] = useState(null);

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.href.indexOf('path=') > -1) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        if (url.searchParams) {
          var path = url.searchParams.get('path');
          setPrevPath(path);
        }
      }
    }
  }, []);

  function handleToast(showToast, title, message, status, delay) {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    array = false,
  }) {
    if (currentValue && !array) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else if (currentValue && array) {
      if (currentValue.length > 0) {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: message,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  useEffect(() => {
    if (resetPasswordSuccess === 'success') {
      setConfShow(true);
      setConfTitle(I18n.t('reset_password'));
      setConfMessage(I18n.t('reset_password_success'));
      setConfStatus('Toast__Container__Success');
      setDelay(6000);
      dispatch({
        type: RESET_VERIFICATION,
      });
    }
  }, [resetPasswordSuccess, dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      if (savedProject && Object.keys(savedProject).length > 0) {
        history.push('/projects/create');
      } else {
        if (prevPath !== null) {
          history.push(prevPath);
        } else {
          history.push('/');
        }
      }
    }
  }, [isAuthenticated, history]);

  function onLoginIDChange(e) {
    setLoginID(e.target.value);
  }

  function onLoginIDBlur() {
    handleItemLocalError({
      propName: 'loginID',
      currentValue: loginID,
      message: I18n.t('username_required'),
    });
  }

  function onPasswordChange(e) {
    setPassword(e.target.value);
  }

  function onPasswordBlur() {
    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
  }

  function handleForgotPassword() {
    if (prevPath !== null) {
      history.push(`/reset-password?path=${prevPath}`);
    } else {
      history.push('/reset-password');
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    handleItemLocalError({
      propName: 'loginID',
      currentValue: loginID,
      message: I18n.t('username_required'),
    });
    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
    if (
      loginID &&
      password &&
      isHuman &&
      !localErrors.loginID &&
      !localErrors.password
    ) {
      //create user object
      const userLogin = {
        loginID,
        password,
        locale,
      };
      //attempt to login
      dispatch(login(userLogin));
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function handleSignup() {
    if (prevPath !== null) {
      history.push(`/signup?path=${prevPath}`);
    } else {
      history.push('/signup');
    }
  }

  return (
    <div className="container">
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="SignContainer__fixed">
        <div className="SignContainer">
          <header>
            <h1 className="mb-3">
              <Translate value="signin" />
            </h1>
          </header>

          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12">
                {errors.response && errorId === 'LOGIN_FAIL' ? (
                  <ul className="popup__wrapper__errors">
                    <li className="popup__wrapper__errorsHead">
                      {' '}
                      {errors.response[0]}{' '}
                    </li>
                  </ul>
                ) : null}
              </div>
              <div className="col-12">
                <div class="form-group">
                  <label>
                    {I18n.t('email_username')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="text"
                    name="loginID"
                    id="loginID"
                    className="form-control"
                    placeholder={I18n.t('email_username')}
                    onChange={onLoginIDChange}
                    onBlur={onLoginIDBlur}
                  />
                  {errors.loginID && errorId === 'LOGIN_FAIL' ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.loginID[0]}
                    </Feedback>
                  ) : localErrors.loginID ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.loginID}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-12">
                <div class="form-group">
                  <label>
                    {I18n.t('password')}{' '}
                    <small className="required">({I18n.t('required')})</small>
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="form-control"
                    placeholder={I18n.t('password')}
                    onChange={onPasswordChange}
                    onBlur={onPasswordBlur}
                  />
                  {errors.password && errorId === 'LOGIN_FAIL' ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {errors.password[0]}
                    </Feedback>
                  ) : localErrors.password ? (
                    <Feedback type="invalid" className="d-block mt-0 mb-2">
                      {localErrors.password}
                    </Feedback>
                  ) : null}
                </div>
              </div>
              <div className="col-12 mb-3">
                <button
                  onClick={() => handleForgotPassword()}
                  type="button"
                  className="forgotPassword">
                  <Translate value="forgot_password" />
                </button>
              </div>
              <div className="col-12">
                <GRecaptch setIsHuman={setIsHuman} className="mb-2" />
              </div>
              <div className="col-12 my-3">
                <ButtonWithLoading
                  variant="primary"
                  type="submit"
                  onClick={onSubmit}
                  data-backdrop="static"
                  loading={loginLoading ? loginLoading : null}>
                  <Translate value="signin" />
                </ButtonWithLoading>
              </div>
            </div>
          </form>

          <footer>
            <p>
              <Translate value="dont_have_account" />
              <button onClick={() => handleSignup()}>
                <Translate value="signup_now" />
              </button>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Signin;
