import { axiosInstance, tokenConfig } from '../axiosInstance';
import { 
    GET_ALL_SERVICES_SUCCESS, 
    GET_ALL_SERVICES_FAIL, 
    ALL_SERVICES_ITEMS_LOADING, 
    GET_RELATED_SERVICES_LOADING,
    GET_RELATED_SERVICES_SUCCESS, 
    GET_RELATED_SERVICES_FAIL, 
    GET_USER_SERVICES_LOADING,
    GET_USER_SERVICES_SUCCESS,
    GET_USER_SERVICES_FAIL
} from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

// export const getallServices = (user_id = null, cat_id = null, sub_cat_id = null, status_id = null, publish = null, id = null, page = null, limit = null, keywords = null, min_price = null, max_price = null, min_days = null, max_days = null) => (dispatch) => {
//     dispatch(setItemsLoading());
//     axiosInstance.get('/api/services', {params: { user_id, cat_id, sub_cat_id, status_id, publish, id, page, limit, keywords, min_price, max_price, min_days, max_days} })
//         .then(res => 
//             dispatch({
//                 type: GET_ALL_SERVICES_SUCCESS,
//                 payload: res.data
//             })
//         )
//         .catch(err => {
//             dispatch(returnErrors(err.response.data, err.response.status, 'GET_ALL_SERVICES_FAIL'))
//             dispatch ({ 
//                 type: GET_ALL_SERVICES_FAIL 
//             })
//         });
// };

export const getallServices = (user_id = null, cat_id = null, sub_cat_id = null, status_id = null, publish = null, id = null, page = null, limit = null, keywords = null, min_price = null, max_price = null, min_days = null, max_days = null) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: ALL_SERVICES_ITEMS_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/services', 
                {params: { user_id, cat_id, sub_cat_id, status_id, publish, id, page, limit, keywords, min_price, max_price, min_days, max_days },
                ...tokenConfig(getState)}
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_ALL_SERVICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_ALL_SERVICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_ALL_SERVICES_FAIL'));
                dispatch ({ 
                    type: GET_ALL_SERVICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ALL_SERVICES_FAIL')),
                    dispatch ({ 
                        type: GET_ALL_SERVICES_FAIL 
                    })
                )
            }
        }
    }
};

// export const getuserServices = (user_id = null, cat_id = null, sub_cat_id = null, status_id = null, publish = null, id = null, page = null, limit = null, keywords = null, min_price = null, max_price = null, min_days = null, max_days = null) => (dispatch) => {
//     dispatch({
//         type: GET_USER_SERVICES_LOADING
//     });
//     axiosInstance.get('/api/services', {params: { user_id, cat_id, sub_cat_id, status_id, publish, id, page, limit, keywords, min_price, max_price, min_days, max_days} })
//         .then(res => 
//             dispatch({
//                 type: GET_USER_SERVICES_SUCCESS,
//                 payload: res.data
//             })
//         )
//         .catch(err => {
//             dispatch(returnErrors(err.response.data, err.response.status, 'GET_USER_SERVICES_FAIL'))
//             dispatch ({ 
//                 type: GET_USER_SERVICES_FAIL 
//             })
//         });
// };

export const getuserServices = (user_id = null, cat_id = null, sub_cat_id = null, status_id = null, publish = null, id = null, page = null, limit = null, keywords = null, min_price = null, max_price = null, min_days = null, max_days = null) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: GET_USER_SERVICES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/services', 
                {params: { user_id, cat_id, sub_cat_id, status_id, publish, id, page, limit, keywords, min_price, max_price, min_days, max_days },
                ...tokenConfig(getState)}
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_USER_SERVICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_USER_SERVICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_SERVICES_FAIL'));
                dispatch ({ 
                    type: GET_USER_SERVICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_SERVICES_FAIL')),
                    dispatch ({ 
                        type: GET_USER_SERVICES_FAIL 
                    })
                )
            }
        }
    }
};

// export const getrelatedServices = (sub_cat_id = null, publish = null, limit = null) => (dispatch) => {
//     dispatch({
//         type: GET_RELATED_SERVICES_LOADING
//     });
//     axiosInstance.get('/api/services', {params: { sub_cat_id, publish, limit} })
//         .then(res => 
//             dispatch({
//                 type: GET_RELATED_SERVICES_SUCCESS,
//                 payload: res.data
//             })
//         )
//         .catch(err => {
//             dispatch(returnErrors(err.response.data, err.response.status, 'GET_ALL_SERVICES_FAIL'))
//             dispatch ({ 
//                 type: GET_RELATED_SERVICES_FAIL 
//             })
//         });
// };

export const getrelatedServices = (sub_cat_id = null, publish = null, limit = null) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: GET_RELATED_SERVICES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/services', 
                {params: { sub_cat_id, publish, limit } }
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_RELATED_SERVICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_RELATED_SERVICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_RELATED_SERVICES_FAIL'));
                dispatch ({ 
                    type: GET_RELATED_SERVICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_RELATED_SERVICES_FAIL')),
                    dispatch ({ 
                        type: GET_RELATED_SERVICES_FAIL 
                    })
                )
            }
        }
    }
};

// export const setItemsLoading = () => {
//     return {
//         type: ALL_SERVICES_ITEMS_LOADING
//     };
// };