import React from 'react';

class Section extends React.Component {
    render() {
        const { 
            sectionClassName,
            h2Content,
            h1Content,
            } = this.props;
        return (
            <section className={sectionClassName}>
                <div className="container">
                    <header className="text-center headers headers--underline sectionHeader">
                        <h4>{h2Content}</h4>
                        <h5>{h1Content}</h5>
                    </header>
                    {this.props.children}
                </div>
            </section>
        );
    }
}

export default Section;