import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Pagination from "react-js-pagination";
import Spin from '../Spin';
import { Link } from 'react-router-dom';
import NoItems from '../NoItems';
import RatingStarsPreview from "../RatingStarsPreview";
import { I18n } from 'react-redux-i18n';
import { Card } from "react-bootstrap";

export default function Feedback(props){

    const {
        feedbacks,
        getFeedbacksLoading,
        feedbacksPerPage,
        totalFeedbacks,
    } = useSelector( (state) => ({
        feedbacks: state.feedbacks.feedbacks,
        getFeedbacksLoading: state.feedbacks.getFeedbacksLoading,
        feedbacksPerPage: state.feedbacks.feedbacksPerPage,
        totalFeedbacks: state.feedbacks.totalFeedbacks,
    }));

    const {
        activePage,
        setActivePage
    } = props;


    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
    }

    const totalItemsCount = totalFeedbacks;
    const itemsCountPerPage = Number(feedbacksPerPage);

    const createMarkup = (text) => {
        return { __html: text };
    };

    return(
        <Card>
            <Card.Header>
                <div>
                    {I18n.t('profile_feedback')}
                </div>
            </Card.Header>
            <Card.Body>
                {
                getFeedbacksLoading ?
                <Spin />
                : feedbacks && feedbacks.length > 0 ?
                <Fragment>
                    <ul className="serviceFeedback__List">
                        {
                            feedbacks.map( item => (
                                item.feedbackable.type === "project" ?
                                <li key={item.id}>
                                    {
                                    item.user.type === "Seller" && item.feedbacks.length > 0 ?
                                        <Fragment>
                                            <div className="feedback__Buyer">
                                                <Link className="d-block mb-3" to={{pathname: `/projects/${item.feedbackable.id}/${item.feedbackable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                    {item.feedbackable.title}
                                                </Link>
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.feedbacks[0].user.image }
                                                            alt={ item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.feedbacks[0].user.username}`}} >
                                                                {item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('client')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.feedbacks[0].created_at.Date + " - " + item.feedbacks[0].created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.feedbacks[0].rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.feedbacks[0].comment )}></div>
                                                </div>
                                            </div>
                                            <div className="feedback__Seller">
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.user.image }
                                                            alt={ item.user.fname + " " + item.user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.user.username}`}} >
                                                                {item.user.fname + " " + item.user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('freelancer')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.created_at.Date + " - " + item.created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.comment )}></div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    :
                                        <Fragment>
                                            <div className="feedback__Buyer">
                                                <Link className="d-block mb-3" to={{pathname: `/projects/${item.feedbackable.id}/${item.feedbackable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                    {item.feedbackable.title}
                                                </Link>
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.user.image }
                                                            alt={ item.user.fname + " " + item.user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.user.username}`}} >
                                                                {item.user.fname + " " + item.user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('client')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.created_at.Date + " - " + item.created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.comment )}></div>
                                                </div>
                                            </div>
                                            { 
                                                item.feedbacks.length > 0 ?
                                                <div className="feedback__Seller">
                                                    <div className="Comment__userInfo">
                                                        <div className="UserPic">
                                                            <img
                                                                src={ item.feedbacks[0].user.image }
                                                                alt={ item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname }
                                                            />
                                                        </div>
                                                        <div className="CommentContent__Details">
                                                            <h3>
                                                                <Link to={{ pathname: `/in/${item.feedbacks[0].user.username}`}} >
                                                                    {item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname}
                                                                </Link>
                                                                <span className='userType'>{I18n.t('freelancer')}</span>
                                                            </h3>
                                                            <div className="CommentContent__Details__PostTime">
                                                                { item.feedbacks[0].created_at.Date + " - " + item.feedbacks[0].created_at.Time }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <RatingStarsPreview rate={item.feedbacks[0].rate} />
                                                    <div className="CommentContent">
                                                        <div dangerouslySetInnerHTML={createMarkup( item.feedbacks[0].comment )}></div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </Fragment>
                                    }
                                </li>
                                :
                                <li key={item.id}>
                                    {
                                    item.user.type === "Seller" && item.feedbacks.length > 0 ?
                                        <Fragment>
                                            <div className="feedback__Buyer">
                                                <Link className="d-block mb-3" to={{pathname: `/services/${item.feedbackable.id}/${item.feedbackable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                    {item.feedbackable.title}
                                                </Link>
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.feedbacks[0].user.image }
                                                            alt={ item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.feedbacks[0].user.username}`}} >
                                                                {item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('client')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.feedbacks[0].created_at.Date + " - " + item.feedbacks[0].created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.feedbacks[0].rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.feedbacks[0].comment )}></div>
                                                </div>
                                            </div>
                                            <div className="feedback__Seller">
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.user.image }
                                                            alt={ item.user.fname + " " + item.user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.user.username}`}} >
                                                                {item.user.fname + " " + item.user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('freelancer')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.created_at.Date + " - " + item.created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.comment )}></div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    :
                                        <Fragment>
                                            <div className="feedback__Buyer">
                                                <Link className="d-block mb-3" to={{pathname: `/services/${item.feedbackable.id}/${item.feedbackable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                    {item.feedbackable.title}
                                                </Link>
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                        <img
                                                            src={ item.user.image }
                                                            alt={ item.user.fname + " " + item.user.lname }
                                                        />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                        <h3>
                                                            <Link to={{ pathname: `/in/${item.user.username}`}} >
                                                                {item.user.fname + " " + item.user.lname}
                                                            </Link>
                                                            <span className='userType'>{I18n.t('client')}</span>
                                                        </h3>
                                                        <div className="CommentContent__Details__PostTime">
                                                            { item.created_at.Date + " - " + item.created_at.Time }
                                                        </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.rate} />
                                                <div className="CommentContent">
                                                    <div dangerouslySetInnerHTML={createMarkup( item.comment )}></div>
                                                </div>
                                            </div>
                                            { 
                                                item.feedbacks.length > 0 ?
                                                <div className="feedback__Seller">
                                                    <div className="Comment__userInfo">
                                                        <div className="UserPic">
                                                            <img
                                                                src={ item.feedbacks[0].user.image }
                                                                alt={ item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname }
                                                            />
                                                        </div>
                                                        <div className="CommentContent__Details">
                                                            <h3>
                                                                <Link to={{ pathname: `/in/${item.feedbacks[0].user.username}`}} >
                                                                    {item.feedbacks[0].user.fname + " " + item.feedbacks[0].user.lname}
                                                                </Link>
                                                                <span className='userType'>{I18n.t('freelancer')}</span>
                                                            </h3>
                                                            <div className="CommentContent__Details__PostTime">
                                                                { item.feedbacks[0].created_at.Date + " - " + item.feedbacks[0].created_at.Time }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <RatingStarsPreview rate={item.feedbacks[0].rate} />
                                                    <div className="CommentContent">
                                                        <div dangerouslySetInnerHTML={createMarkup( item.feedbacks[0].comment )}></div>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </Fragment>
                                    }
                                </li>
                            ))
                        }
                    </ul>
                    <div className="col-12 my-4">
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                </Fragment>
                : <NoItems />
                }
            </Card.Body>
        </Card>
    )
}