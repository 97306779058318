import React, { Fragment, useEffect, useState } from "react";
import { I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import { followUser } from "../../actions/followActions";
import { unfollowUser } from "../../actions/followActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import RatingStarsPreview from "../RatingStarsPreview";
import { getFollowings } from "../../redux/followers/actions/getActions";
import { getProjects } from "../../redux/project/actions/getActions";
import HireUserModal from "../HireUserModal";
import ConfToast from "../ConfToast";

const ProfileCard = (props) => {
  const dispatch = useDispatch();
  const freelancer = props.freelancerdata;
  const { loggedUser, LoadingUser } = props;

  const {
    isAuthenticated,
    user,
    userInfo,
    followings,
    followUserLoading,
    unFollowUserLoading,
    locale,
    getUserProjectsLoading,
    projects = [],

    inviteUserLoading,
  } = useSelector((state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    userInfo: state.userData.user,
    followings: state.followers.followings,
    followUserLoading: state.followUser.followUserLoading,
    unFollowUserLoading: state.followUser.unFollowUserLoading,
    locale: state.i18n.locale,
    getUserProjectsLoading: state.project.getUserProjectsReceivingLoading,
    projects: state.project.userProjectsReceiving,

    inviteUserLoading: state.notifications.inviteUserLoading,
  }));

  const [activePage, setActivePage] = useState(1);
  const [toggleInviteModal, setToggleInviteModal] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if(userInfo && userInfo.memberships && userInfo.memberships.length > 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    } else if(userInfo && userInfo.memberships && userInfo.memberships.length <= 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    }
  }, [userInfo]);

  function handelFollow(id) {
    const action = "follow";
    const followed_id = id;
    //create follow object
    const followData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(followUser(followData));
  };

  function handelUnfollow(id) {
    const action = "unfollow";
    const followed_id = id;
    //create follow object
    const unfollowData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(unfollowUser(unfollowData));
  };

  useEffect(() => {
    if(isAuthenticated){
      const page = 1;
      const limit = 20;
      const user_id = freelancer.id;
      dispatch(getFollowings(page, limit, user_id));
    }
  }, [dispatch, isAuthenticated, freelancer]);

  const { id } = user;
  useEffect(() => {
    if(isAuthenticated && id && isClient){
      let user_id = id;
      const page = activePage;
      const limit = 5;
      const status_id = 8; //Receiving Bid Status Id
      dispatch(getProjects(user_id, null, page, limit, null, null, null, null, null, null, null, status_id, null, locale));
    }
  }, [isAuthenticated, id, dispatch, activePage, locale, isClient]);

  function handleInviteOnClick() {
    setToggleInviteModal(!toggleInviteModal);
  }

  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <HireUserModal
        currentProjects={projects}
        onHide={handleInviteOnClick}
        show={toggleInviteModal}
        modalTitle={"invite_project"}
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={inviteUserLoading}
        activePage={activePage}
        setActivePage={setActivePage}
        getUserProjectsLoading={getUserProjectsLoading}
        userId = {freelancer.id}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <div className="container">
        <div className="Profile-Card box-shadow">
          <div className="row">
            <div className="col-md-9">
              <div className="ProfileData">
                <div className="ProfileImage">
                  <figure>
                    <img src={freelancer.image.url ? freelancer.image.url : freelancer.image} alt={freelancer.fname + ' ' + freelancer.lname} className="img-fluid" />
                  </figure>
                </div>
                <div className="ProfileContent">
                  <h1 className="ProfileName">
                    {freelancer.fname + ' ' + freelancer.lname}{" "}
                    {
                      freelancer.price_per_hour ?
                      <Fragment>
                        <span className="HourRate">{freelancer.price_per_hour}</span>
                        <span>{I18n.t('dollar_per_hour')}</span>
                      </Fragment>
                      : null
                    }
                    
                  </h1>
                  {
                    freelancer.position ?
                    <h3 className="ProfileTitle">{freelancer.position}</h3>
                    : null
                  }
                  <RatingStarsPreview rate={freelancer.rate} />
                  <div className="LocationTime">
                    {
                      freelancer.country ?
                      <address>
                        <span className="LocationFlag">
                          <img src={freelancer.country.flag} alt="" />
                        </span>
                        <span className="Location">
                          {freelancer.city.name}, {freelancer.country.name}
                        </span>
                      </address>
                      : null
                    }
                    {
                      freelancer.localTime ?
                      <time className="Time"><label>{I18n.t('local_time')}:</label> <div><span style={{display: "inline-block", direction: "ltr"}}>{freelancer.localTime.Date}</span> - <span style={{display: "inline-block", direction: "ltr"}}>{freelancer.localTime.Time}</span></div></time>
                      : null
                    }
                  </div>
                  {/*<div className="LastSeen">{I18n.t('last_seen')}: 2 hours ago</div>*/}
                </div>
              </div>
            </div>
            <div className="col-md-3">
              {/*<Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className="HireMe">
                  Hire Me{" "}
                  <span>
                    <i className="icon icon-angle-down"></i>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">
                    Public project
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">
                    Private project
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                parentClassName="InviteContest"
                text="Invite to contest"
                Href="/test"
              />*/}
              {
                isAuthenticated && isClient ?
                    <ButtonWithLoading
                        variant="primary"
                        type="button"
                        className="Follow__User w-100 mb-2"
                        onClick={() =>
                          handleInviteOnClick()
                        }
                        data-backdrop="static"
                        loading={getUserProjectsLoading}
                    >
                      <i className="icon icon-add-friend"></i>{" "}
                      {I18n.t("invite_project")}
                    </ButtonWithLoading>
                : null
              }
              {
                !LoadingUser ?
                loggedUser.id === freelancer.id ?
                null
                :
                <Fragment>
                  {
                    isAuthenticated ?
                      followings.length > 0 && followings[0].id === freelancer.id ?
                        <ButtonWithLoading
                            variant="primary"
                            type="button"
                            className="Follow__User w-100"
                            onClick={() =>
                              handelUnfollow(freelancer.id)
                            }
                            data-backdrop="static"
                            loading={unFollowUserLoading}
                        >
                          <i className="fas fa-heart"></i>{" "}
                          {I18n.t("un_follow")}
                        </ButtonWithLoading>
                      :
                        <ButtonWithLoading
                            variant="primary"
                            type="button"
                            className="Follow__User w-100"
                            onClick={() =>
                              handelFollow(freelancer.id)
                            }
                            data-backdrop="static"
                            loading={followUserLoading}
                        >
                          <i className="far fa-heart"></i>{" "}
                          {I18n.t("follow")}
                        </ButtonWithLoading>
                    : null
                  }
                  <div className="FollowsCount">
                    {freelancer.followersCount} {I18n.t('follower')}
                  </div>
                </Fragment>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ProfileCard;
