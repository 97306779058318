import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';
import { 
    VERIFY_ACCOUNT_LOADING, 
    VERIFY_ACCOUNT_SUCCESS, 
    VERIFY_ACCOUNT_FAIL, 
    SEND_VERIFICATION_LOADING, 
    SEND_VERIFICATION_SUCCESS, 
    SEND_VERIFICATION_FAIL 
} from "./types";

export const verifyAccount = ({ verification_code, locale }) => {
    return async function(dispatch){
        //Headers

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Accept-Language": locale ? locale : "en"
            }
        }
        dispatch(clearErrors());
        dispatch({
            type: VERIFY_ACCOUNT_LOADING
        });
        const body = { verification_code };
        try {
            let response = await axiosInstance.post(
                '/api/verify', 
                body,
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: VERIFY_ACCOUNT_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: VERIFY_ACCOUNT_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if(error.response && error.response.data){
                dispatch(returnErrors(error.response.data, error.response.status, 'VERIFY_ACCOUNT_FAIL'));
                dispatch ({ 
                    type: VERIFY_ACCOUNT_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'VERIFY_ACCOUNT_FAIL')),
                    dispatch ({ 
                        type: VERIFY_ACCOUNT_FAIL 
                    })
                )
            }
        }
    }
};

export const sendVerification = ({ email, type }) => {
    return async function(dispatch){
        //Headers

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        dispatch(clearErrors());
        dispatch({
            type: SEND_VERIFICATION_LOADING
        });
        const body = { email, type };
        try {
            let response = await axiosInstance.post(
                '/api/send_verification_code',
                body,
                config
            );
            if(response.status === 200){
                dispatch({
                    type: SEND_VERIFICATION_SUCCESS,
                    payload: response.data
                });
            }else{
                dispatch({
                    type: SEND_VERIFICATION_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                });
            }
        } catch (error) {
            if(error.response && error.response.data){
                dispatch(returnErrors(error.response.data, error.response.status, 'SEND_VERIFICATION_FAIL'));
                dispatch ({ 
                    type: SEND_VERIFICATION_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'SEND_VERIFICATION_FAIL')),
                    dispatch ({ 
                        type: SEND_VERIFICATION_FAIL 
                    })
                )
            }
        }
    }
};