import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';

// Sentry configuration for Beta Version
// Sentry.init({
//   dsn: 'https://09506e58816d4a72a25767165a02bba4@o1103140.ingest.sentry.io/6129811',
//   integrations: [new BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

//Sentry configuration for Live Version
Sentry.init({
  dsn: 'https://6bf8a3022f49435fb26c8cea7ce35a4c@o1323698.ingest.sentry.io/6581589',
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const root = document.getElementById('root');

ReactDOM.hydrate(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  root,
);
serviceWorker.unregister();
