import {
    PROFILE_DATA_LOADING,
    UPDATE_PROFILE_SUCCESS, 
    UPDATE_PROFILE_FAIL,
    RESET_UPDATE_PROFILE
} from '../actions/types';

const initialState = {
    isLoading: false,
    userUpdated: {},
}

export default function updateuserprofileReducer(state = initialState, action) {
    switch (action.type) {
        case PROFILE_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case UPDATE_PROFILE_SUCCESS:
            localStorage.setItem('loggeduserImage', action.payload.data.image.url ? action.payload.data.image.url : action.payload.data.image);
            return {
                ...state,
                isLoading: false,
                userUpdated: action.payload.data
            };
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case RESET_UPDATE_PROFILE:
            return{
                ...state,
                userUpdated: {}
            }
        ;
        default:
            return state;
    }
}