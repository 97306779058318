import TYPES, { RESET_CONTACT_SUCCESS } from "../actions/types";

const INITIAL_STATE = {

  // Send Contact
  sendContactLoading: false,
  sendContactError: "",
  sendContactSuccess: false,

};

const {
  SEND_CONTACT_LOADING,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
} = TYPES;

export default function contactReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Send Contact
    case SEND_CONTACT_LOADING:
      return {
        ...state,
        sendContactLoading: true,
        sendContactError: "",
      };
    case SEND_CONTACT_SUCCESS:
      return {
        ...state,
        sendContactLoading: false,
        sendContactError: "",
        sendContactSuccess: action.payload === "success",
      };
    case SEND_CONTACT_FAIL:
      return {
        ...state,
        sendContactLoading: false,
        sendContactError: action.error,
      };

    case RESET_CONTACT_SUCCESS:
      return {
        ...state,
        sendContactSuccess: false,
      };

    default:
      return state;
  }
}
