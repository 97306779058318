import { axiosInstance } from '../axiosInstance';
import { GET_FREELANCERS_LOADING, GET_FREELANCERS_FAIL, GET_FREELANCERS_SUCCESS } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getFreelancers = (name = null, username = null, id = null, phone = null, email = null, plans = null, page = null, cat_id = null, sub_cat_id = null, orderBy = null, locale, limit = null) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: GET_FREELANCERS_LOADING
        });
        const body = { name, username, id, phone, email, plans, page, cat_id, sub_cat_id, orderBy, limit };
        const config = {
            headers: {
                "Content-type": "application/json",
                "Accept-Language": locale ? locale : "en"
            }
        }
        try {
            let response = await axiosInstance.post(
                '/api/users', 
                body,
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_FREELANCERS_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_FREELANCERS_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_FREELANCERS_FAIL'));
                dispatch ({ 
                    type: GET_FREELANCERS_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FREELANCERS_FAIL')),
                    dispatch ({ 
                        type: GET_FREELANCERS_FAIL 
                    })
                )
            }
        }
    }
};

// export const getFreelancers = (name = null, username = null, id = null, phone = null, email = null, membership_id = null, plan_id = null, page = null) => (dispatch) => {
//     dispatch({
//         type: GET_FREELANCERS_LOADING
//     });
//     axiosInstance.post('/api/users',{params: { name, username, id, phone, email, membership_id, plan_id, page } })
//     .then( response =>
//         dispatch({
//             type: GET_FREELANCERS_SUCCESS,
//             payload: response.data
//         })
//     ).catch(error => {
//         dispatch(returnErrors(error.response.data, error.response.status, 'GET_FREELANCERS_FAIL'));
//         dispatch ({ 
//             type: GET_FREELANCERS_FAIL 
//         });
//     })
// };

// const exportedObject = {
//     getFreelancers
// }

// export default exportedObject;