import React, {useState, useEffect, Fragment} from 'react';
import { Link } from 'react-router-dom';
import {getrunninginvoices} from '../../actions/getserviceinvoicesActions';
import { useDispatch, useSelector } from "react-redux";
import NoItems from '../NoItems';
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import ConnectionLost from "../ConnectionLost";
import { TitleComponent } from "../../components/TitleComponent";
import { MetaComponent } from "../../components/MetaComponent";
import { I18n } from "react-redux-i18n";


export default function RunningServices() {
    const dispatch = useDispatch();

    const {
        userLoading,
        runninginvoices,
        runningtotalRecords,
        runningitemsPerPage,
        loadingrunninginvoices,
        errorId,
        errorMessage,
    } = useSelector((state) => ({
        userLoading: state.auth.isLoading,
        runninginvoices: state.serviceInvoices.runninginvoices,
        runningtotalRecords: state.serviceInvoices.runningtotalRecords,
        runningitemsPerPage: state.serviceInvoices.runningitemsPerPage,
        loadingrunninginvoices: state.serviceInvoices.loadingrunninginvoices,
        errorId: state.error.id,
        errorMessage: state.error.msg,
    }));

    const [activePage, setActivePage] = useState(1);
    const [loadingServices, setLoadingServices] = useState(true);

    useEffect(() => {
        setLoadingServices(loadingrunninginvoices);
    }, [loadingrunninginvoices]);

    useEffect(() => {
        const page = activePage;
        const limit = 12;
        const running = true;
        dispatch(getrunninginvoices(null, null, page, limit, null, running));
    }, [dispatch, activePage]);

    const handlePageChange = (pageNumber) =>{
        setActivePage(pageNumber);
    }
    //Get Services
    const totalItemsCount = runningtotalRecords;
    const itemsCountPerPage = Number(runningitemsPerPage);
    return(
        <div className="Offers__List">
            <TitleComponent title={I18n.t("lancersin_services_progress")} />
            <MetaComponent />
            <div className="row">
                {
                    loadingServices || userLoading ?
                    <Spin />
                    : errorId === "GET_RUNNING_INVOICES_FAIL" && errorMessage === "Network Error" ?
                    <div className="col-12">
                        <ConnectionLost
                            errorType= "connection"
                            title = "network_error_title"
                            message = "network_error_message"
                        />
                    </div>
                    : errorId === "GET_RUNNING_INVOICES_FAIL" ?
                    <div className="col-12">
                        <ConnectionLost
                            errorType= "somethingWrong"
                            title = "something_error_title"
                            message = "something_error_message"
                        />
                    </div>
                    :
                    runninginvoices.length > 0 && !loadingServices ?
                    <Fragment>
                        {
                            runninginvoices.map( item => (
                                <div className="col-md-6 col-lg-4" key={item.id}>
                                    <div className="services__listitem box-shadow">
                                        <figure>
                                            <Link className="services__imgcontainer" to={{pathname:`/services/invoices/${item.id}/${item.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                                <img src={item.service.image.url ? item.service.image.url : item.service.image} alt={item.service.title} />
                                            </Link>
                                            <figcaption>
                                                <div className="Service__Provider">
                                                    <div className="img-container">
                                                        <img src={item.service.user.image.url ? item.service.user.image.url : item.service.user.image} alt={item.service.user.fname + ' ' + item.service.user.lname} className="img-fluid" />
                                                    </div>
                                                    <p>
                                                        <Link to={{pathname:`/in/${item.service.user.username}`}}>{item.service.user.fname} {item.service.user.lname}</Link>
                                                    </p>
                                                </div>
                                                <h2><Link to={{pathname:`/services/invoices/${item.id}/${item.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>{item.service.title}</Link></h2>
                                            </figcaption>
                                        </figure>
                                        <div className="services__info">
                                            <div className="bought">
                                                <span className="icon icon-deal"></span>{item.qty}
                                            </div>
                                            <div className="price">
                                            <span className="icon icon-price-label"></span>{item.due}$
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </Fragment>
                    :
                    <div className="col-12">
                        <NoItems />
                    </div>
                }
            </div>
        </div>
    )
}