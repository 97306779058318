import { GET_FREELANCERS_LOADING, GET_FREELANCERS_FAIL, GET_FREELANCERS_SUCCESS } from '../actions/types';

const initialState = {
    freelancers: [],
    totalRecords: 0,
    itemsPerPage: 0,
    loadingFreelancers: false
}

export default function freelancersReducer(state = initialState, action) {
    switch (action.type) {
        case GET_FREELANCERS_LOADING: 
            return{
                ...state,
                loadingFreelancers: true
            }
        ;
        case GET_FREELANCERS_SUCCESS:
            return{
                ...state,
                freelancers: action.payload.data,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                loadingFreelancers: false
            }    
        ;
        case GET_FREELANCERS_FAIL:
            return {
                ...state,
                loadingFreelancers: false
            }
        ;
        default:
            return state;
    }
}