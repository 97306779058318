import TYPES, { CLEAR_ADD_FAVOURITE_SUCCESS, CLEAR_DELETE_FAVOURITE_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  // GET Fav Projects
  getFavProjectsLoading: false,
  getFavProjectsError: "",
  favProjects: [],
  totalFavProjects: 0,
  favProjectsPerPage: 0,

  // GET Fav Contests
  getFavContestsLoading: false,
  getFavContestsError: "",
  favContests: [],
  totalFavContests: 0,
  favContestsPerPage: 0,

  // GET Fav Services
  getFavServicesLoading: false,
  getFavServicesError: "",
  favServices: [],
  totalFavServices: 0,
  favServicesPerPage: 0,

  // Add Favourite
  addingFavouriteLoading: false,
  addingFavouriteError: "",
  addingFavouriteSuccess: false,

  // Delete Favourite
  deletingFavouriteLoading: false,
  deletingFavouriteError: "",
  deletingFavouriteSuccess: false,
};

const {
  GET_FAV_PROJECTS_LOADING,
  GET_FAV_PROJECTS_SUCCESS,
  GET_FAV_PROJECTS_FAIL,

  GET_FAV_SERVICES_LOADING,
  GET_FAV_SERVICES_SUCCESS,
  GET_FAV_SERVICES_FAIL,

  GET_FAV_CONTESTS_LOADING,
  GET_FAV_CONTESTS_SUCCESS,
  GET_FAV_CONTESTS_FAIL,

  DELETE_FAVOURITE_LOADING,
  DELETE_FAVOURITE_SUCCESS,
  DELETE_FAVOURITE_FAIL,

  ADD_FAVOURITE_LOADING,
  ADD_FAVOURITE_SUCCESS,
  ADD_FAVOURITE_FAIL,

  RESET_FAVOURITE_ACTIONS,
} = TYPES;

export default function projectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get Favourite Projects
    case GET_FAV_PROJECTS_LOADING:
      return{
        ...state,
        getFavProjectsLoading: true,
        getFavProjectsError: "",
      };
    case GET_FAV_PROJECTS_SUCCESS:
      return{
        ...state,
        getFavProjectsLoading: false,
        getFavProjectsError: "",
        favProjects: action.payload.data,
        totalFavProjects: action.payload.totalRecords,
        favProjectsPerPage: action.payload.itemsPerPage,
      };
    case GET_FAV_PROJECTS_FAIL:
      return{
        ...state,
        getFavProjectsLoading: false,
        getFavProjectsError: action.error,
      };

    // Get Favourite Services
    case GET_FAV_SERVICES_LOADING:
      return{
        ...state,
        getFavServicesLoading: true,
        getFavServicesError: "",
      };
    case GET_FAV_SERVICES_SUCCESS:
      return{
        ...state,
        getFavServicesLoading: false,
        getFavServicesError: "",
        favServices: action.payload.data,
        totalFavServices: action.payload.totalRecords,
        favServicesPerPage: action.payload.itemsPerPage,
      };
    case GET_FAV_SERVICES_FAIL:
      return{
        ...state,
        getFavServicesLoading: false,
        getFavServicesError: action.error,
      };

    // Get Favourite Contests
    case GET_FAV_CONTESTS_LOADING:
      return{
        ...state,
        getFavContestsLoading: true,
        getFavContestsError: "",
      };
    case GET_FAV_CONTESTS_SUCCESS:
      return{
        ...state,
        getFavContestsLoading: false,
        getFavContestsError: "",
        favContests: action.payload.data,
        totalFavContests: action.payload.totalRecords,
        favContestsPerPage: action.payload.itemsPerPage,
      };
    case GET_FAV_CONTESTS_FAIL:
      return{
        ...state,
        getFavContestsLoading: false,
        getFavContestsError: action.error,
      };

    // Add Favourite
    case ADD_FAVOURITE_LOADING:
      return {
        ...state,
        addingFavouriteLoading: true,
        addingFavouriteError: "",
      };
    case ADD_FAVOURITE_SUCCESS:
      return {
        ...state,
        addingFavouriteLoading: false,
        addingFavouriteError: "",
        addingFavouriteSuccess: action.status === "success",
      };
    case ADD_FAVOURITE_FAIL:
      return {
        ...state,
        addingFavouriteLoading: false,
        addingFavouriteError: action.error,
      };
    case CLEAR_ADD_FAVOURITE_SUCCESS:
      return{
        ...state,
        addingFavouriteSuccess: false,
      }

    // Delete Favourite
    case DELETE_FAVOURITE_LOADING:
      return {
        ...state,
        deletingFavouriteLoading: true,
        deletingFavouriteError: "",
      };
    case DELETE_FAVOURITE_SUCCESS:
      return {
        ...state,
        deletingFavouriteLoading: false,
        deletingFavouriteError: "",
        deletingFavouriteSuccess: action.status === "success",
      };
    case DELETE_FAVOURITE_FAIL:
      return {
        ...state,
        deletingFavouriteLoading: false,
        deletingFavouriteError: action.error,
      };
    case CLEAR_DELETE_FAVOURITE_SUCCESS:
      return {
        ...state,
        deletingFavouriteSuccess: false,
      };

    case RESET_FAVOURITE_ACTIONS:
      return {
        ...state,
        ...INITIAL_STATE,
      };

    default:
      return state;
  }
}
