import {
  GET_PORTFOLIO_COMMENTS_SUCCESS,
  GET_PORTFOLIO_COMMENTS_FAIL,
  GET_PORTFOLIO_COMMENTS_LOADING,
  ADD_PORTFOLIO_COMMENT_SUCCESS,
  ADD_PORTFOLIO_COMMENT_FAIL,
  ADD_PORTFOLIO_COMMENT_LOADING,
  EDIT_PORTFOLIO_COMMENT_SUCCESS,
  EDIT_PORTFOLIO_COMMENT_FAIL,
  EDIT_PORTFOLIO_COMMENT_LOADING,
  DELETE_PORTFOLIO_COMMENT_SUCCESS,
  DELETE_PORTFOLIO_COMMENT_FAIL,
  DELETE_PORTFOLIO_COMMENT_LOADING,
} from '../actions/types';
import {axiosInstance} from '../axiosInstance';
import {returnErrors} from '../actions/errorActions';

// GET PORTFOLIO COMMENTS

export const getPortfolioComments =
  (portfolio_id, page, limit) => async dispatch => {
    // Data Loading
    dispatch(setItemsLoading());
    //Request body
    await axiosInstance
      .get(
        `/api/portfolio/comments?portfolio_id=${portfolio_id}&page=${page}&limit=${limit}`,
      )
      .then(res =>
        dispatch({
          type: GET_PORTFOLIO_COMMENTS_SUCCESS,
          payload: res.data,
        }),
      )
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'GET_PORTFOLIO_COMMENTS_FAIL',
            ),
          );
          dispatch({
            type: GET_PORTFOLIO_COMMENTS_FAIL,
          });
        } else {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'GET_PORTFOLIO_COMMENTS_FAIL',
              ),
            ),
            dispatch({
              type: GET_PORTFOLIO_COMMENTS_FAIL,
            })
          );
        }
      });
  };

// ADD PORTFOLIO COMMENT
export const addportfolioitemComment =
  ({comment, portfolio_id}) =>
  (dispatch, getState) => {
    // Data Loading
    dispatch({
      type: ADD_PORTFOLIO_COMMENT_LOADING,
    });
    //Request body
    const body = {comment, portfolio_id};
    axiosInstance
      .post('/api/portfolio/comments', body, tokenConfig(getState))
      .then(res =>
        //console.log(res)
        dispatch({
          type: ADD_PORTFOLIO_COMMENT_SUCCESS,
          payload: res.data,
        }),
      )
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'ADD_PORTFOLIO_COMMENT_FAIL',
            ),
          );
          dispatch({
            type: ADD_PORTFOLIO_COMMENT_FAIL,
          });
        } else {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'ADD_PORTFOLIO_COMMENT_FAIL',
              ),
            ),
            dispatch({
              type: ADD_PORTFOLIO_COMMENT_FAIL,
            })
          );
        }
      });
  };

// DELETE PORTFOLIO COMMENT
export const deleteportfolioitemComment =
  ({id}) =>
  async (dispatch, getState) => {
    // Data Loading
    dispatch({
      type: DELETE_PORTFOLIO_COMMENT_LOADING,
    });
    await axiosInstance
      .delete(`/api/portfolio/comments?id=${id}`, tokenConfig(getState))
      .then(res =>
        dispatch({
          type: DELETE_PORTFOLIO_COMMENT_SUCCESS,
          payload: res.data,
        }),
      )
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'DELETE_PORTFOLIO_COMMENT_FAIL',
            ),
          );
          dispatch({
            type: DELETE_PORTFOLIO_COMMENT_FAIL,
          });
        } else {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'DELETE_PORTFOLIO_COMMENT_FAIL',
              ),
            ),
            dispatch({
              type: DELETE_PORTFOLIO_COMMENT_FAIL,
            })
          );
        }
      });
  };

// EDIT PORTFOLIO COMMENT
export const editportfolioitemComment =
  ({comment, portfolio_id, id}) =>
  (dispatch, getState) => {
    // Data Loading
    dispatch({
      type: EDIT_PORTFOLIO_COMMENT_LOADING,
    });
    //Request body
    const body = {comment, portfolio_id, id};
    axiosInstance
      .post('/api/portfolio/comments', body, tokenConfig(getState))
      .then(res =>
        //console.log(res)
        dispatch({
          type: EDIT_PORTFOLIO_COMMENT_SUCCESS,
          payload: res.data,
        }),
      )
      .catch(error => {
        if (error.response && error.response.data) {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'EDIT_PORTFOLIO_COMMENT_FAIL',
            ),
          );
          dispatch({
            type: EDIT_PORTFOLIO_COMMENT_FAIL,
          });
        } else {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'EDIT_PORTFOLIO_COMMENT_FAIL',
              ),
            ),
            dispatch({
              type: EDIT_PORTFOLIO_COMMENT_FAIL,
            })
          );
        }
      });
  };

// Setup config/headers and token

export const tokenConfig = getState => {
  // Get token from localStorage
  const token = getState().auth.token;
  //Headers
  const config = {
    headers: {
      'Content-type': 'application/json',
    },
  };

  // If token, add to headers

  if (token) {
    config.headers['Authorization'] = 'bearer' + token;
  }

  return config;
};

export const setItemsLoading = () => {
  return {
    type: GET_PORTFOLIO_COMMENTS_LOADING,
  };
};
