import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_ALL_NOTIFICATIONS_LOADING,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATION_READ_LOADING,
  MARK_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAIL,
  MARK_ALL_NOTIFICATIONS_READ_LOADING,
  MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
  MARK_ALL_NOTIFICATIONS_READ_FAIL,
} = TYPES;

// Get User Notifications

export function getNotifications(page = null, limit = null, locale) {
  return async function getNotificationsWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: GET_NOTIFICATIONS_LOADING
    });
    try {
        let response = await axiosInstance
        .get(API.NOTIFICATIONS, {params: { page, limit } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            dispatch({
              type: GET_NOTIFICATIONS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: response.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: GET_NOTIFICATIONS_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_NOTIFICATIONS_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: GET_NOTIFICATIONS_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_NOTIFICATIONS_FAIL'));
        }
    }
  }
}

// Get All Notifications

export function getAllNotifications(page = null, limit = null, locale) {
  return async function getAllNotificationsWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: GET_ALL_NOTIFICATIONS_LOADING
    });
    try {
        let response = await axiosInstance
        .get(API.NOTIFICATIONS, {params: { page, limit } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            dispatch({
              type: GET_ALL_NOTIFICATIONS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_ALL_NOTIFICATIONS_SUCCESS,
            payload: response.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: GET_ALL_NOTIFICATIONS_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_ALL_NOTIFICATIONS_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: GET_ALL_NOTIFICATIONS_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ALL_NOTIFICATIONS_FAIL'));
        }
    }
  }
}

// Mark Notification As Read

export function markAsRead(id = null) {
  return async function markAsReadWithDispatch(dispatch, getState){
    dispatch({
      type: MARK_NOTIFICATION_READ_LOADING
    });
    try {
        let response = await axiosInstance
        .get(`${API.READ + "/" + id}` ,{...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
              type: MARK_NOTIFICATION_READ_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: MARK_NOTIFICATION_READ_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: MARK_NOTIFICATION_READ_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'MARK_NOTIFICATION_READ_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: MARK_NOTIFICATION_READ_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'MARK_NOTIFICATION_READ_FAIL'));
        }
    }
  }
}

// Mark All Notifications As Read

export function markAllNotificationAsRead() {
  return async function markAllNotificationAsReadWithDispatch(dispatch, getState){
    dispatch({
      type: MARK_ALL_NOTIFICATIONS_READ_LOADING
    });
    try {
        let response = await axiosInstance
        .get(`${API.READ}` ,{...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
              type: MARK_ALL_NOTIFICATIONS_READ_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: MARK_ALL_NOTIFICATIONS_READ_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'MARK_ALL_NOTIFICATIONS_READ_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: MARK_ALL_NOTIFICATIONS_READ_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'MARK_ALL_NOTIFICATIONS_READ_FAIL'));
        }
    }
  }
}