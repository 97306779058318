import { DELETE_FILE_LOADING, DELETE_FILE_SUCCESS, DELETE_FILE_FAIL} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Update User Profile

export const deleteFile = ({ model, id, fileURL }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: DELETE_FILE_LOADING
        });
        const body = { model, id, fileURL };
        try {
            let response = await axiosInstance.post(
                '/api/delete/file', 
                body, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: DELETE_FILE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: DELETE_FILE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'DELETE_FILE_FAIL'));
                dispatch ({ 
                    type: DELETE_FILE_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'DELETE_FILE_FAIL')),
                    dispatch ({ 
                        type: DELETE_FILE_FAIL 
                    })
                )                
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}