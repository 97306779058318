import React, {useState, useEffect, useCallback} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import axios from 'axios';
import {ServerURL} from '../Server';
import {I18n} from 'react-redux-i18n';
import {useSelector, useDispatch} from 'react-redux';
import TextInput from '../TextInput';
import {SelectBox} from '../SelectBox';
import QuillBox from '../QuillBox';
import {clearErrors, returnErrors} from '../../actions/errorActions';
import {updateuserprofile} from '../../actions/updateprofileActions';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {Uploader} from '../Upload';
import {CreatableSkills} from '../CreatableSkills';
import DateInput from '../DateInput';
import moment from 'moment';
import AsyncSelect from 'react-select/lib/Async';
import {loadUser} from '../../actions/authActions';
import {RESET_UPDATE_PROFILE, GET_COUNTRIES_FAIL} from '../../actions/types';
import GRecaptch from '../GRecaptch';
import {deleteFile} from '../../actions/deletefileActions';
import ErrorBox from '../ErrorBox';

const {Control} = Form;
const {Feedback} = Control;

var userAge = new Date();
userAge.setFullYear(userAge.getFullYear() - 18);

const axiosInstance = axios.create({
  baseURL: `${ServerURL}`,
  headers: {
    'Accept-Language': 'en',
  },
});

export default function EditingProfileModal(props) {
  const dispatch = useDispatch();

  const {
    cats,
    errorsId,
    errorsMsg: {errors = {}},
    userUpdated,
  } = useSelector(state => ({
    cats: state.category.cats,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    articles: state.article.articles || [],
    user: state.auth.user,
    userUpdated: state.updateprofile.userUpdated,
  }));

  const [id, setId] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [birthday, setBirthDay] = useState(new Date());
  const [pricePerHour, setPricePerHour] = useState('');
  const [position, setPosition] = useState('');
  const [about, setAbout] = useState('');
  const [skills, setSkills] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [cityId, setCityId] = useState('');
  const [selectedCity, setSelectedCity] = useState([]);
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [isHuman, setIsHuman] = useState(false);

  const [image, setImage] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [localErrors, setLocalErrors] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [imagesSortLength, setImagesSortLength] = useState(false);
  const [filteredErrorsLength, setFilteredErrorsLength] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [characterCount, setCharacterCount] = useState(0);

  const filteredErrors = uploadErrors
    ? uploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  useEffect(() => {
    if (filteredErrors.length > 0) {
      setFilteredErrorsLength(true);
    }
  }, [filteredErrors]);

  const {
    modalTitle = '',
    userData,
    loading,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  const getCategories = useCallback(() => {
    if (cats) {
      return cats.map(item => {
        return {
          ...item,
          value: item.title,
          label: item.title,
        };
      });
    }
  }, [cats]);

  const categories = getCategories();

  const getSubCategories = useCallback(categories => {
    if (categories.length > 0) {
      let subCatsArray = [];
      for (let i = 0; i <= categories.length; i++) {
        if (isObject(categories[i]) && categories[i].sub_categories) {
          for (let j = 0; j <= categories[i].sub_categories.length; j++) {
            if (isObject(categories[i].sub_categories[j])) {
              const newItem = {
                ...categories[i].sub_categories[j],
                value: categories[i].sub_categories[j].title,
                label: categories[i].sub_categories[j].title,
              };
              subCatsArray.push(newItem);
            }
          }
        }
      }
      return subCatsArray;
    }
    // }
    return [];
  }, []);

  const subCategories = getSubCategories(category);

  // Countreis & Cities Functions
  const getCountries = useCallback(() => {
    axiosInstance
      .post('/api/countries')
      .then(res => setCountries(res.data.data))
      .catch(error => {
        if (error instanceof Error) {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'GET_COUNTRIES_FAIL',
              ),
            ),
            dispatch({
              type: GET_COUNTRIES_FAIL,
            })
          );
        } else {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'GET_COUNTRIES_FAIL',
            ),
          );
          dispatch({
            type: GET_COUNTRIES_FAIL,
          });
        }
      });
  }, [dispatch]);

  function countryChange(inputValue) {
    // whole object of selected option
    setCountryId(inputValue.value);
    setSelectedCountry(inputValue);
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //Request body
    const body = JSON.stringify({keyword: inputValue.label});
    axiosInstance
      .post('/api/countries', body, config)
      .then(res => setCities(res.data.data[0].cities))
      .catch(error => {
        if (error instanceof Error) {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'GET_COUNTRIES_FAIL',
              ),
            ),
            dispatch({
              type: GET_COUNTRIES_FAIL,
            })
          );
        } else {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'GET_COUNTRIES_FAIL',
            ),
          );
          dispatch({
            type: GET_COUNTRIES_FAIL,
          });
        }
      });
  }

  function cityChange(inputValue) {
    // whole object of selected option
    setCityId(inputValue.value);
    setSelectedCity(inputValue);
  }

  const loadCountryOptions = (inputValue, callback) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    //Request body
    const body = JSON.stringify({keyword: inputValue});
    axiosInstance
      .post('/api/countries', body, config)
      .then(res => setCountries(res.data.data))
      .catch(error => {
        if (error instanceof Error) {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
            dispatch(
              returnErrors(
                errorParsed.message,
                'Network Error',
                'GET_COUNTRIES_FAIL',
              ),
            ),
            dispatch({
              type: GET_COUNTRIES_FAIL,
            })
          );
        } else {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'GET_COUNTRIES_FAIL',
            ),
          );
          dispatch({
            type: GET_COUNTRIES_FAIL,
          });
        }
      });
    setTimeout(() => {
      callback(filterCountries());
    }, 1000);
  };

  function setCurrentUserCountry(country) {
    setCities(country.cities);
    setSelectedCountry({
      ...country,
      label: country.name,
      value: country.id,
    });
  }

  const setUserCountryCity = useCallback(
    country => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      //Request body
      const body = JSON.stringify({id: country});
      axiosInstance
        .post('/api/countries', body, config)
        .then(res => setCurrentUserCountry(res.data.data[0]))
        .catch(error => {
          if (error instanceof Error) {
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
              dispatch(
                returnErrors(
                  errorParsed.message,
                  'Network Error',
                  'GET_COUNTRIES_FAIL',
                ),
              ),
              dispatch({
                type: GET_COUNTRIES_FAIL,
              })
            );
          } else {
            dispatch(
              returnErrors(
                error.response.data,
                error.response.status,
                'GET_COUNTRIES_FAIL',
              ),
            );
            dispatch({
              type: GET_COUNTRIES_FAIL,
            });
          }
        });
    },
    [dispatch],
  );

  const countriesMap = countries.map(item => {
    return {
      ...item,
      label: item.name,
      value: item.id,
    };
  });
  const citiesMap = cities.map(item => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const filterCountries = () => {
    return countries.map(item => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      };
    });
  };

  const filterCities = inputValue => {
    return citiesMap.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase()),
    );
  };
  const loadCityOptions = (inputValue, callback) => {
    setTimeout(() => {
      callback(filterCities(inputValue));
    }, 1000);
  };

  function onFnameChange(e) {
    setFname(e.target.value);
  }

  function onFnameBlur() {
    handleItemLocalError({
      propName: 'fname',
      currentValue: fname,
      message: I18n.t('fname_required'),
    });
  }

  function onLnameChange(e) {
    setLname(e.target.value);
  }

  function onLnameBlur() {
    handleItemLocalError({
      propName: 'lname',
      currentValue: lname,
      message: I18n.t('lname_required'),
    });
  }

  function onPositionChange(e) {
    setPosition(e.target.value);
  }

  function onPositionBlur() {
    handleItemLocalError({
      propName: 'position',
      currentValue: position,
      message: I18n.t('position_required'),
    });
  }

  function onBirthDayBlur() {
    handleItemLocalError({
      propName: 'birthday',
      currentValue: birthday,
      message: I18n.t('birthdate_required'),
      dateObject: true,
    });
  }

  function onPricePerHourChange(e) {
    setPricePerHour(e.target.value);
  }

  function onPricePerHourBlur() {
    handleItemLocalError({
      propName: 'pricePerHour',
      currentValue: pricePerHour,
      message: I18n.t('hourly_price_required'),
    });
  }

  function onPhoneChange(e) {
    setPhone(e.target.value);
  }

  function onPhoneBlur() {
    handleItemLocalError({
      propName: 'phone',
      currentValue: phone,
      message: I18n.t('mobile_required'),
    });
  }

  useEffect(() => {
    if (about) {
      setCharacterCount(about.replace(/<(.|\n)*?>/g, '').trim().length);
    }
  }, [about]);

  function onAboutBlur() {
    if (about) {
      if (about.replace(/<(.|\n)*?>/g, '').trim().length < 150) {
        setLocalErrors(errors => ({
          ...errors,
          about: I18n.t('about_limit'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          about: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        about: I18n.t('description_required'),
      }));
    }
  }

  function handleAboutChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setAbout('');
    } else {
      setAbout(value);
    }
  }

  function handleCatChange(e) {
    setCategory(e);
  }

  function onCatBlur() {
    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
      uploadArray: false,
    });
  }

  function countryBlur() {
    handleItemLocalError({
      propName: 'country',
      currentValue: selectedCountry,
      message: I18n.t('country_required'),
      uploadArray: false,
    });
  }

  function cityBlur() {
    handleItemLocalError({
      propName: 'city',
      currentValue: selectedCity,
      message: I18n.t('city_required'),
      uploadArray: false,
    });
  }

  function handleSubcatChange(e) {
    setSubCategory(e);
  }

  function onSubCatBlur() {
    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
      uploadArray: false,
    });
  }

  function handleSkillsChange(skills) {
    setSkills(skills);
  }

  function onSkillsBlur() {
    handleItemLocalError({
      propName: 'skills',
      currentValue: skills,
      message: I18n.t('skills_required'),
      uploadArray: false,
    });
  }

  const handleHide = useCallback(() => {
    setLocalErrors({});
    setUploadErrors([]);
    props.onHide();
    dispatch(clearErrors());
  }, [props, dispatch]);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  function isObject(val) {
    return typeof val === 'object';
  }
  function handleItemLocalError({
    propName,
    currentValue,
    message,
    uploadArray,
    dateObject,
  }) {
    if (currentValue) {
      if (
        isObject(currentValue) &&
        !Array.isArray(currentValue) &&
        !dateObject
      ) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else if (Array.isArray(currentValue) && uploadArray === true) {
        if (currentValue.length <= 0) {
          const noImageError = {
            code: 'NoImage',
            message: message,
          };
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setUploadErrors(filtered);
        } else {
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          setUploadErrors(filtered);
        }
      } else if (Array.isArray(currentValue) && uploadArray === false) {
        if (currentValue.length <= 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    handleItemLocalError({
      propName: 'fname',
      currentValue: fname,
      message: I18n.t('fname_required'),
    });

    handleItemLocalError({
      propName: 'lname',
      currentValue: lname,
      message: I18n.t('lname_required'),
    });

    handleItemLocalError({
      propName: 'position',
      currentValue: position,
      message: I18n.t('position_required'),
    });

    handleItemLocalError({
      propName: 'birthday',
      currentValue: birthday,
      message: I18n.t('birthdate_required'),
      dateObject: true,
    });

    handleItemLocalError({
      propName: 'pricePerHour',
      currentValue: pricePerHour,
      message: I18n.t('hourly_price_required'),
    });

    handleItemLocalError({
      propName: 'phone',
      currentValue: phone,
      message: I18n.t('mobile_required'),
    });

    handleItemLocalError({
      propName: 'country',
      currentValue: selectedCountry,
      message: I18n.t('country_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: 'city',
      currentValue: selectedCity,
      message: I18n.t('city_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: 'image',
      currentValue: image,
      message: I18n.t('profile_image_required'),
      uploadArray: true,
    });

    onAboutBlur();

    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: 'skills',
      currentValue: skills,
      message: I18n.t('skills_required'),
      uploadArray: false,
    });

    if (
      fileUpload === false &&
      fileChosen === false &&
      fname &&
      lname &&
      position &&
      birthday &&
      pricePerHour &&
      email &&
      phone &&
      countryId &&
      cityId &&
      image[0] &&
      about &&
      category.length > 0 &&
      subCategory.length > 0 &&
      skills.length > 0 &&
      isHuman &&
      !localErrors.fname &&
      !localErrors.lname &&
      !localErrors.position &&
      !localErrors.birthday &&
      !localErrors.pricePerHour &&
      !localErrors.phone &&
      !localErrors.country &&
      !localErrors.city &&
      !localErrors.image &&
      !localErrors.about &&
      !localErrors.category &&
      !localErrors.subCategory &&
      !localErrors.skills
    ) {
      if (deletedFiles.length > 0) {
        deletedFiles.map(item => {
          const model = 'users';
          const id = userData ? userData.id : null;
          const fileURL = item;
          const fileData = {
            model,
            id,
            fileURL,
          };
          return dispatch(deleteFile(fileData));
        });
      }
      let itemData = {
        fname,
        lname,
        position,
        birthday,
        price_per_hour: pricePerHour,
        email,
        phone,
        country_id: countryId,
        city_id: cityId,
        image: image[0] ? image[0].source : '',
        about,
        categories: category.map(item => item.id),
        sub_categories: subCategory.map(item => item.id),
        skills,
        id,
      };
      dispatch(updateuserprofile(itemData));
    } else if (fileUpload === true || fileChosen === true) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        10000,
      );
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Error',
        10000,
      );
    }
  }

  useEffect(() => {
    if (image.length > 0) {
      setImagesSortLength(true);
    }
  }, [image]);

  useEffect(() => {
    if (userData) {
      setId(userData.id ? userData.id : '');
      setFname(userData.fname ? userData.fname : '');
      setLname(userData.lname ? userData.lname : '');
      setEmail(userData.email ? userData.email : '');
      setPhone(userData.phone ? userData.phone : '');
      setUsername(userData.username ? userData.username : '');
      setPosition(userData.position ? userData.position : '');
      setPricePerHour(userData.price_per_hour ? userData.price_per_hour : '');
      setAbout(userData.about ? userData.about : '');
      setCountryId(
        userData.country
          ? userData.country.id
            ? userData.country.id
            : ''
          : '',
      );
      setUserCountryCity(
        userData.country
          ? userData.country.id
            ? userData.country.id
            : null
          : null,
      );
      // setSelectedCountry(
      //     userData.country ?
      //     {
      //         ...userData.country,
      //         label: userData.country.name,
      //         value: userData.country.id
      //     }
      //     : []
      // );
      setCityId(
        userData.city ? (userData.city.id ? userData.city.id : '') : '',
      );
      setSelectedCity(
        userData.city
          ? {
              label: userData.city.name,
              value: userData.city.id,
            }
          : [],
      );
      setSkills(userData.skills ? userData.skills : []);
      const todayDate = new Date();
      setBirthDay(
        userData.birthday ? moment(userData.birthday).toDate() : todayDate,
      );
      getCountries();
      if (userData.categories.length > 0) {
        let choosenCategories = userData.categories.map(item => {
          const choosenCategory = cats.find(i => i.id === item.id);
          return {
            ...choosenCategory,
            value: choosenCategory
              ? choosenCategory.title
                ? choosenCategory.title
                : null
              : null,
            label: choosenCategory
              ? choosenCategory.title
                ? choosenCategory.title
                : null
              : null,
          };
        });
        setCategory(choosenCategories);
      }
      if (userData.sub_categories.length > 0 && cats.length > 0) {
        let choosenSubCategories = userData.sub_categories.map(item => {
          const itemCategory = cats.find(i => i.id === item.cat_id);
          const choosenSubCategory = itemCategory.sub_categories.find(
            i => i.id === item.id,
          );
          return {
            ...choosenSubCategory,
            value: choosenSubCategory
              ? choosenSubCategory.title
                ? choosenSubCategory.title
                : null
              : null,
            label: choosenSubCategory
              ? choosenSubCategory.title
                ? choosenSubCategory.title
                : null
              : null,
          };
        });
        setSubCategory(choosenSubCategories);
      }
      if (isObject(userData.image) && userData.image.url.includes(`uploads/`)) {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: userData.image.size,
          name: userData.image.filename,
          origin: userData.image.filename,
          path: userData.image.filename,
          percent: 100,
          preview: userData.image.url,
          size: userData.image.size,
          source: userData.image.url,
          total: userData.image.size,
          type: `image/${userData.image.extension}`,
        };
        setImage(imageCopy);
      }
    }
  }, [
    userData,
    getCategories,
    getSubCategories,
    cats,
    getCountries,
    setUserCountryCity,
  ]);

  useEffect(() => {
    if (
      errorsId !== 'UPDATE_PROFILE_FAIL' &&
      loading === false &&
      Object.keys(userUpdated).length > 0
    ) {
      setDeletedFiles([]);
      dispatch(loadUser());
      dispatch({
        type: RESET_UPDATE_PROFILE,
      });
      handleHide();
      handleToast(
        true,
        I18n.t('profile_update_title'),
        I18n.t('profile_update_success'),
        'Toast__Container__Success',
        6000,
      );
    }
  }, [errorsId, loading, userUpdated, handleToast, dispatch, handleHide]);

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props['aria-labelledby']}>
      <Form>
        <Modal.Header>
          <Modal.Title>{I18n.t(modalTitle)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="alert alert-danger" role="alert">
                {I18n.t('adding_warning')}
              </div>
            </div>
            <div className="col-md-6">
              <TextInput
                required
                controlId="fName"
                label="first_name"
                type="text"
                name="fname"
                feedbackType="invalid"
                onChange={onFnameChange}
                onBlur={onFnameBlur}
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.fname) ||
                  localErrors.fname
                }
                value={fname}
                feedback={
                  localErrors.fname
                    ? localErrors.fname
                    : errors.fname
                    ? errors.fname[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <TextInput
                required
                controlId="lName"
                label="last_name"
                type="text"
                name="lname"
                feedbackType="invalid"
                onChange={onLnameChange}
                onBlur={onLnameBlur}
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.lname) ||
                  localErrors.lname
                }
                value={lname}
                feedback={
                  localErrors.lname
                    ? localErrors.lname
                    : errors.lname
                    ? errors.lname[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <TextInput
                required={false}
                disabled
                controlId="userName"
                label="username"
                type="text"
                name="userName"
                feedbackType="invalid"
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.username) ||
                  localErrors.username
                }
                value={username}
                feedback={
                  localErrors.username
                    ? localErrors.username
                    : errors.username
                    ? errors.username[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <TextInput
                required
                controlId="Position"
                label="position"
                type="text"
                name="Position"
                feedbackType="invalid"
                onChange={onPositionChange}
                onBlur={onPositionBlur}
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.position) ||
                  localErrors.position
                }
                value={position}
                feedback={
                  localErrors.position
                    ? localErrors.position
                    : errors.position
                    ? errors.position[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <Form.Group controlId="Country">
                <Form.Label>
                  {I18n.t('country')}
                  <small className="required"> ({I18n.t('required')})</small>
                </Form.Label>
                <AsyncSelect
                  loadOptions={loadCountryOptions}
                  value={selectedCountry}
                  defaultOptions={countriesMap}
                  onChange={countryChange}
                  onBlur={countryBlur}
                  className={localErrors.country ? 'invalid' : ''}
                />
                {localErrors.country ? (
                  <Feedback className="d-block" type="invalid">
                    {localErrors.country}
                  </Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <Form.Group controlId="City">
                <Form.Label>
                  {I18n.t('city')}
                  <small className="required"> ({I18n.t('required')})</small>
                </Form.Label>
                <AsyncSelect
                  value={selectedCity}
                  loadOptions={loadCityOptions}
                  defaultOptions={citiesMap}
                  onChange={cityChange}
                  onBlur={cityBlur}
                  className={localErrors.city ? 'invalid' : ''}
                  //noOptionsMessage={countryName !== "" ? I18n.t('choose_cat_first') : I18n.t('select_no_options')}
                />
                {localErrors.city ? (
                  <Feedback className="d-block" type="invalid">
                    {localErrors.city}
                  </Feedback>
                ) : null}
              </Form.Group>
            </div>
            <div className="col-md-6">
              <TextInput
                required={false}
                disabled
                controlId="Email"
                label="email_address"
                type="email"
                name="email"
                feedbackType="invalid"
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.email) ||
                  localErrors.email
                }
                value={email}
                feedback={
                  localErrors.email
                    ? localErrors.email
                    : errors.email
                    ? errors.email[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <TextInput
                required
                controlId="Mobile"
                label="mobile"
                type="tel"
                name="phone"
                feedbackType="invalid"
                onChange={onPhoneChange}
                onBlur={onPhoneBlur}
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.phone) ||
                  localErrors.phone
                }
                value={phone}
                className="mobile__input"
                appendText={
                  selectedCountry
                    ? selectedCountry.calling_code
                      ? selectedCountry.calling_code
                      : null
                    : null
                }
                feedback={
                  localErrors.phone
                    ? localErrors.phone
                    : errors.phone
                    ? errors.phone[0]
                    : null
                }
              />
            </div>
            <div className="col-md-6">
              <DateInput
                required
                controlId="Birthdate"
                label="birth_date"
                value={birthday}
                dateFormat="dd MMMM yyyy"
                onChange={setBirthDay}
                onBlur={onBirthDayBlur}
                feedback={
                  localErrors
                    ? localErrors.birthday
                      ? localErrors.birthday
                      : null
                    : null
                }
                isInvalid={
                  localErrors ? (localErrors.birthday ? true : false) : false
                }
                showMonthDropdown
                showYearDropdown
                dateFilter={userAge}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                required
                controlId="PricePerHour"
                label="hourly_price"
                type="numder"
                name="PricePerHour"
                feedbackType="invalid"
                onChange={onPricePerHourChange}
                onBlur={onPricePerHourBlur}
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' &&
                    errors.price_per_hour) ||
                  localErrors.pricePerHour
                }
                value={(Number(pricePerHour) || '').toString()}
                prepandText="dollar"
                feedback={
                  localErrors.pricePerHour
                    ? localErrors.pricePerHour
                    : errors.price_per_hour
                    ? errors.price_per_hour[0]
                    : null
                }
              />
            </div>
            <div className="col-md-12">
              <Form.Group>
                <Form.Label>
                  {I18n.t('profile_image')}
                  <small className="required"> ({I18n.t('required')})</small>
                </Form.Label>
                <Uploader
                  accept={[
                    {
                      mime: 'image/jpeg',
                      ext: 'jpeg',
                    },
                    {
                      mime: 'image/jpg',
                      ext: 'jpg',
                    },
                    {
                      mime: 'image/png',
                      ext: 'png',
                    },
                  ]}
                  maxFiles={1}
                  multiple={false}
                  maxSize={5000000}
                  sortable={false}
                  setFilesChosen={setFileChosen}
                  setFilesUpload={setFileUpload}
                  setUploadErrors={setUploadErrors}
                  uploadErrors={uploadErrors}
                  setFilesSort={setImage}
                  filesSort={image}
                  filesSortLength={imagesSortLength}
                  filteredErrorsLength={filteredErrorsLength}
                  filteredErrors={filteredErrors}
                  uploadPath="users"
                  projectId={userData ? userData.id : null}
                  deletedFiles={deletedFiles}
                  setDeletedFiles={setDeletedFiles}
                  // maxWidth={300}
                  // maxHeight={300}
                  optimize={1}
                />
              </Form.Group>
              {/*
                            errorsId === "DELETE_FILE_FAIL" && deletedFiles.length > 0 ?
                            <ErrorBox message={I18n.t('delete_file_error')} />
                            : null
                        */}
            </div>
            <div className="col-md-12">
              <QuillBox
                required
                controlId="About"
                label="about_user"
                value={about}
                onChange={handleAboutChange}
                onBlur={onAboutBlur}
                feedbackType="invalid"
                minlength="150"
                characterCount={characterCount}
                feedback={
                  localErrors.about
                    ? localErrors.about
                    : errors.about
                    ? errors.about[0]
                    : null
                }
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.about) ||
                  localErrors.about
                }
              />
            </div>
            <div className="col-md-12">
              <SelectBox
                required
                isMulti={true}
                controlId="Category"
                name="category"
                label="category"
                options={categories}
                value={category}
                onChange={handleCatChange}
                onBlur={onCatBlur}
                feedback={
                  localErrors.category
                    ? localErrors.category
                    : errors.category
                    ? errors.category[0]
                    : null
                }
                feedbackType="invalid"
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.cat_id) ||
                  localErrors.category
                }
                placeholder={I18n.t('category')}
              />
            </div>
            <div className="col-md-12">
              <SelectBox
                required
                isMulti={true}
                controlId="Sub-Category"
                name="sub_category"
                label="sub_category"
                options={subCategories}
                value={subCategory}
                onChange={handleSubcatChange}
                onBlur={onSubCatBlur}
                feedback={
                  localErrors.subCategory
                    ? localErrors.subCategory
                    : errors.subCategory
                    ? errors.subCategory[0]
                    : null
                }
                feedbackType="invalid"
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.cat_id) ||
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.sub_cat_id) ||
                  localErrors.subCategory
                }
                placeholder={I18n.t('sub_category')}
                noOptionsMessage={
                  category
                    ? Object.keys(category).length > 0
                      ? I18n.t('select_no_options')
                      : I18n.t('choose_cat_first')
                    : null
                }
              />
            </div>
            <div className="col-md-12">
              <CreatableSkills
                onChange={handleSkillsChange}
                onBlur={onSkillsBlur}
                feedback={
                  localErrors.skills
                    ? localErrors.skills
                    : errors.skills
                    ? errors.skills[0]
                    : null
                }
                feedbackType="invalid"
                isInvalid={
                  (errorsId === 'UPDATE_PROFILE_FAIL' && errors.skills) ||
                  localErrors.skills
                }
                required
                value={skills}
              />
            </div>
          </div>

          <GRecaptch setIsHuman={setIsHuman} />

          {errorsId === 'UPDATE_PROFILE_FAIL' && errors === 'Network Error' ? (
            <ErrorBox message={I18n.t('network_error_message')} />
          ) : errorsId === 'UPDATE_PROFILE_FAIL' &&
            errors === 'Other Errors' ? (
            <ErrorBox message={I18n.t('something_error_message')} />
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
            variant="primary"
            type="submit"
            onClick={onSubmit}
            data-backdrop="static"
            loading={loading ? loading : null}>
            {I18n.t('submit')}
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}
            disabled={loading}>
            {I18n.t('close')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
