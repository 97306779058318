import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import { I18n } from "react-redux-i18n";
import { truncate } from "../ReduceFileName";
import { Size } from "../CalculateSize";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { Form, Dropdown, Card, Col } from "react-bootstrap";
import { addProjectComment } from "../../redux/project/actions/addActions";
import { getProjectComments } from "../../redux/project/actions/getActions";
import { clearErrors } from '../../actions/errorActions';
import Spin from "../Spin";
import ErrorBox from "../ErrorBox";
import { Uploader } from "../Upload";
import GRecaptch from "../GRecaptch";
import ADD_PROJECT_COMMENT_FAIL from "../../redux/project/actions/types";
import NoItems from "../NoItems";
import QuillBox from "../QuillBox";
import ReportContentModal from "../ReportContentModal";

export default function ProjectComments(props){
    const dispatch = useDispatch();

    const {
        // Project
        getProjectByIdLoading,

        // Project Comments
        projectComments,
        getProjectCommentsLoading,
        addingProjectCommentLoading,
        addingProjectCommentSuccess,
        totalComments,
        CommentsPerPage,
        projectLastComment,

        projectBidReports,
    
        loggedUser,

        postReportLoading,

        lastNotification,
    
        // errors
        errorsId,
        errorsMsg
    } = useSelector((state) => ({
        getProjectByIdLoading: state.project.getProjectByIdLoading,

        projectComments: state.project.projectComments,
        getProjectCommentsLoading: state.project.getProjectCommentsLoading,
        addingProjectCommentLoading: state.project.addingProjectCommentLoading,
        addingProjectCommentSuccess: state.project.addingProjectCommentSuccess,
        totalComments: state.project.totalCommentsRecords,
        CommentsPerPage: state.project.commentsPerPage,
        projectLastComment: state.project.projectLastComment,

        projectBidReports: state.project.projectBidReports,
    
        loggedUser: state.auth.user,

        postReportLoading: state.reportsTypes.isLoading,

        lastNotification: state.notifications.lastNotification,
    
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
    }));

    const [commentsActivePage, setCommentsActivePage] = useState(1);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState(projectComments);
    const [localErrors, setLocalErrors] = useState({});
    const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_PROJECT_COMMENT_FAIL);
    const [returnedErrors, setReturnedErrors] = useState({});
    const [showReport, setShowReport] = useState(false);
    const [commentId, setCommentId] = useState();

    const [files, setFiles] = useState([]);
    const [filesUpload, setFilesUpload] = useState(false);
    const [filesChosen, setFilesChosen] = useState(false);
    const [filesUploadErrors, setFilesUploadErrors] = useState([]);
    const [filesSortLength, setFilesSortLength] = useState(false);
    const [filteredFilesErrorsLength, setFilteredFilesErrorsLength] = useState(false);
    const [deletedFiles, setDeletedFiles] = useState([]);
    const [isHuman, setIsHuman] = useState(false);

    const filteredFilesErrors = filesUploadErrors ? filesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
    ) : null;

    useEffect(()=> {
        if(filteredFilesErrors.length > 0){
            setFilteredFilesErrorsLength(true) 
        }
    }, [filteredFilesErrors]);

    useEffect(() => {
        if(errorsId && errorsMsg){
            setErrorActionType(errorsId);
            setReturnedErrors(errorsMsg.errors);
        }
    }, [errorsId, errorsMsg]);

    useEffect(() => {
        if(files.length > 0){
            setFilesSortLength(true) 
        }
    }, [files]);

    const totalCommentsCount = totalComments;
    const commentsCountPerPage = Number(CommentsPerPage);
    const {
        projectDetails,
        isOwnerOfThisProject,
        isProjectReceivingBids,
        isOwnerOfSelectedBid,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay,
    } = props;

    const { id } = projectDetails;

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

    useEffect(() => {
        if (projectComments?.length > 0) {
          setComments(projectComments);
        }
    }, [projectComments]);

    useEffect(() => {
        if(projectDetails && !getProjectByIdLoading){
          if ((isOwnerOfThisProject || isOwnerOfSelectedBid) && !isProjectReceivingBids) {
            const page = 1;
            const limit = 10;
            dispatch(getProjectComments(id, page, limit));
          }
        }
    }, [projectDetails, dispatch, getProjectByIdLoading, id, isOwnerOfThisProject, isOwnerOfSelectedBid, isProjectReceivingBids]);

    useEffect(() => {
      if(lastNotification && lastNotification.notification_content.type === "CreateProjectComment" && window.location.href.indexOf("/projects") > -1 && lastNotification.notification_content.content.project.id === id){
          const page = 1;
          const limit = 10;
          dispatch(getProjectComments(id, page, limit));
          setCommentsActivePage(1);
      }
    }, [lastNotification, dispatch, id]);

    useEffect(() => {
      if(projectLastComment && projectDetails && !getProjectByIdLoading){
        if ((isOwnerOfThisProject || isOwnerOfSelectedBid) && !isProjectReceivingBids) {
          const page = 1;
          const limit = 10;
          dispatch(getProjectComments(id, page, limit));
        }
      }
    },[projectLastComment, projectDetails, getProjectByIdLoading, isOwnerOfThisProject, isOwnerOfSelectedBid, isProjectReceivingBids, dispatch, id]);
    
    useEffect(() => {
        if (addingProjectCommentSuccess) {
            setComment("");
            setLocalErrors({});
            setFilesChosen(false);
            setFilesUpload(false);
            setFilesUploadErrors([]);
            setFiles([]);
            setFilesSortLength(false);
            setFilteredFilesErrorsLength(false);
            setDeletedFiles([]);
            handleToast(true, I18n.t('thank_you'), I18n.t('comment_success'), 'Toast__Container__Success', 6000);
            dispatch(clearErrors());
        }
    }, [addingProjectCommentSuccess, dispatch, handleToast]);

    function handleCommentsPageChange(pageNumber) {
        if(pageNumber !== commentsActivePage){
          const page = pageNumber;
          const limit = 10;
          dispatch(getProjectComments(id, page, limit));
          setCommentsActivePage(pageNumber);
        }
    };

    function handleCommentChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setComment("");
        } else {
            setComment(value);
        }
    };

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: undefined,
            }));
        }else{
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
          }));
        }
    }

    function onCommentBlur(){
        handleItemLocalError({
            propName: "commentError",
            currentValue: comment,
            message: I18n.t('comment_required')
        });
    }
    
    function addComment(e) {
        e.preventDefault();
        //create comment object
        handleItemLocalError({
            propName: "commentError",
            currentValue: comment,
            message: I18n.t('comment_required')
        });
        const commentData = {
          comment,
          projectId: id,
          commentfiles: files.map(item => item.source),
        };
        if (
            filesUpload === false &&
            filesChosen === false &&
            comment &&
            isHuman
        ){
          //attempt to add comment
          dispatch(addProjectComment(commentData));
        } else{
          filesUpload === true ||
          filesChosen === true
          ?
          handleToast(true, I18n.t('upload_notification_title'), I18n.t('upload_notification'), 'Toast__Container__Warning', 10000)
          :
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 10000)
        }
    };
    
    function renderComments() {
        if (getProjectCommentsLoading) {
          return <Spin />;
        } 

        if (errorsId === "GET_PROJECT_COMMENTS_FAIL") {
          return <ErrorBox message={I18n.t('something_went_wrong')} />;
        } 

        if (comments.length > 0 && !getProjectCommentsLoading) {
          return comments.map((comment) => renderComment(comment));
        }

        return <NoItems title="no_comments_title" message="no_comments_message" className="No__Shadow" />;
    }
    
    function renderComment(comment) {
        const { user, files } = comment;
        return (
          <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion" key={comment.id}>
            <header>
              {
                user.id === loggedUser.id ? 
                null : 
                <Dropdown>
                  <Dropdown.Toggle variant="btn-default">
                    <span className="fas fa-ellipsis-h"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleShowReport(comment.id)}
                    >
                      <span className="far fa-flag"></span>{I18n.t('report_content')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              }
              <div className="ProfileImage">
                <figure>
                  <img src={user.image} alt={user.username} className="img-fluid" />
                </figure>
              </div>
              <div className="ProfileContent">
                <div className="ProfileContent__Details">
                  {
                    comment.country ?
                    <address>
                      <span className="LocationFlag">
                        <img src={user.country.flag} alt={user.country.name} />
                      </span>
                    </address>
                    : null
                  }
                  <h2 className="ProfileName">
                    <Link to={{ pathname: `/in/${user.username}` }}>
                      {user.fname} {user.lname}
                    </Link>
                  </h2>
                </div>
                <time className="Time">
                  <span className="icon icon-calender-time"></span> {comment.created_at.Date} - {comment.created_at.Time}
                </time>
              </div>
            </header>
            <div className="ProjectDetails__Proposal__Content">
              <div dangerouslySetInnerHTML={createMarkup(comment.comment)}></div>
              {
                comment.files ?
                files.length > 0 ?
                  <div className="ProjectDetails__Proposal__Content__attachments">
                  <h3>{I18n.t('attachments')}:</h3>
                  <ul>
                    {files.map(
                      (file, index) => (
                        <li key={index}>
                          <a href={file.url} target="_blank" rel="noopener noreferrer">
                            <span className="fas fa-paperclip"></span>
                            {truncate(
                              file.filename,
                              20
                            )}
                          </a>
                          <span className="file__size ms-1">
                            ({Size(file.size)})
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                : null
                : null
              }
            </div>
          </div>
        );
    }

    const createMarkup = (text) => {
        return { __html: text };
    };

    function handleShowReport(id) {
      setCommentId(id);
      setShowReport(true);
    };
    function handleHideReport() {
      setShowReport(false);
    };

    function handover(reports){
      const handoverRequests = reports.filter( function(item){
        if(item.type === "handover"){
          return true;
        }
        return false;
      }).map(function(item) { return item });
  
      if(handoverRequests.length > 0){
        const maxId = Math.max(...handoverRequests.map(item => item.id));
        const currentHandover = handoverRequests.filter( function(item){
          if(item.id === maxId){
            return true;
          }
          return false;
        }).map(function(item) { return item });
        if(currentHandover[0].status === "pending"){
          return "pending"
        }
        else if(currentHandover[0].status === "declined"){
          return "declined"
        }
        return "noHandover";
      }
    }

    return(
        <Card>
            <Card.Header>
                <div>
                    <span className="icon icon-feedback"></span> {I18n.t("project_discussions")}
                </div>
            </Card.Header>
            <Card.Body>
              <ReportContentModal
                onHide={handleHideReport}
                show={showReport}
                className="CustomPopup"
                size="lg"
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                loading={postReportLoading}
                model="project_comments"
                model_id={commentId}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
              />
              <div className="row">
                  {
                    (handover(projectBidReports) === "pending" && isOwnerOfThisProject) ||  projectDetails.status.id === 7 ?
                    null
                    :
                    <div className="col-12 mb-5">
                        <Form>
                            <QuillBox
                                required
                                controlId="Comment"
                                label="add_comment"
                                value={comment}
                                onChange={handleCommentChange}
                                onBlur={onCommentBlur}
                                feedbackType="invalid"
                                feedback={ localErrors.commentError ? localErrors.commentError : returnedErrors ? returnedErrors.comment ? returnedErrors.comment[0] : null : null}
                                isInvalid={ (errorsId === ERROR_ACTION_TYPE && returnedErrors ? returnedErrors.comment ? true : false : false) || localErrors.commentError}
                            />
                            <Form.Row>
                                <Col>
                                    <Form.Group controlId="CommentFiles">
                                        <Form.Label>{I18n.t("upload_files")}</Form.Label>
                                        <small>{I18n.t('project_files_hint')}</small>
                                        <Uploader 
                                            accept={[
                                                {
                                                mime: "application/msword",
                                                ext: "doc"
                                                }, 
                                                {
                                                mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                ext: "docx"
                                                }, 
                                                {
                                                mime: "application/vnd.ms-powerpoint",
                                                ext: "ppt"
                                                },
                                                {
                                                mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                ext: "pptx"
                                                },
                                                {
                                                mime: "application/vnd.ms-excel",
                                                ext: "xls"
                                                },
                                                {
                                                mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                ext: "xlsx"
                                                },
                                                {
                                                mime: "application/pdf",
                                                ext: "pdf"
                                                },
                                                {
                                                mime: "image/jpeg",
                                                ext: "jpeg"
                                                },
                                                {
                                                mime: "image/gif",
                                                ext: "gif"
                                                },
                                                {
                                                mime: "image/png",
                                                ext: "png"
                                                },
                                                {
                                                mime: "application/rtf",
                                                ext: "rtf"
                                                },
                                                {
                                                mime: "text/plain",
                                                ext: "plain-text"
                                                },
                                                {
                                                mime: "application/x-rar",
                                                ext: "rar"
                                                },
                                                {
                                                mime: "application/zip",
                                                ext: "zip"
                                                },
                                            ]}
                                            maxFiles={10} 
                                            multiple={true}
                                            maxSize={100000000}
                                            sortable={false}
                                            setFilesChosen={setFilesChosen}
                                            setFilesUpload={setFilesUpload}
                                            setUploadErrors={setFilesUploadErrors}
                                            uploadErrors={filesUploadErrors}
                                            setFilesSort={setFiles}
                                            filesSort={files}
                                            filesSortLength={filesSortLength}
                                            filteredErrorsLength={filteredFilesErrorsLength}
                                            filteredErrors={filteredFilesErrors}
                                            uploadPath="project_comments"
                                            projectId= {projectDetails ? projectDetails.id : null}
                                            deletedFiles={deletedFiles}
                                            setDeletedFiles={setDeletedFiles}
                                            optimize={1}
                                        />
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row className="mb-4">
                                <Col>
                                    <GRecaptch
                                        setIsHuman={setIsHuman}
                                        className="mt-3"
                                    />
                                </Col>
                            </Form.Row>
                            {
                              errorsId === "ADD_PROJECT_COMMENT_FAIL" ?
                              <div className="row">
                                <div className="col-12">
                                  <ErrorBox message={I18n.t('something_went_wrong')} />
                                </div>
                              </div>
                              : null
                            }
                            <ButtonWithLoading
                            variant="primary"
                            type="submit"
                            onClick={addComment}
                            data-backdrop="static"
                            loading={addingProjectCommentLoading ? addingProjectCommentLoading : null}
                            >
                            {I18n.t('add')}
                            </ButtonWithLoading>
                        </Form>
                    </div>
                  }
                  <div className="col-12">
                      {
                          comments.length > 0 && projectLastComment ?
                          comments.some(e => e.id === projectLastComment.id) ? null :
                          (
                          <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion" key={projectLastComment.id}>
                              <header>
                              { 
                                  loggedUser.id === projectLastComment.user.id ?
                                  null :
                                  <Dropdown>
                                  <Dropdown.Toggle variant="btn-default">
                                      <span className="fas fa-ellipsis-h"></span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                      <Dropdown.Item>
                                      <span className="far fa-flag"></span>{I18n.t('report_content')}
                                      </Dropdown.Item>
                                  </Dropdown.Menu>
                                  </Dropdown>
                              }
                              <div className="ProfileImage">
                                  <figure>
                                  <img src={projectLastComment.user.image} alt={projectLastComment.user.username} className="img-fluid" />
                                  </figure>
                              </div>
                              <div className="ProfileContent">
                                  <div className="ProfileContent__Details">
                                  { 
                                    projectLastComment.user.country ?
                                    <address>
                                        <span className="LocationFlag">
                                        <img src={projectLastComment.user.country.flag} alt={projectLastComment.user.country.name} />
                                        </span>
                                    </address>
                                    : null 
                                  }
                                  <h2 className="ProfileName">
                                      <Link to={{ pathname: `/in/${projectLastComment.user.username}` }}>
                                      {projectLastComment.user.fname} {projectLastComment.user.lname}
                                      </Link>
                                  </h2>
                                  </div>
                                  <time className="Time">
                                  <span className="icon icon-calender-time"></span> {projectLastComment.created_at.Date} - {projectLastComment.created_at.Time}
                                  </time>
                              </div>
                              </header>
                              <div className="ProjectDetails__Proposal__Content">
                              <div dangerouslySetInnerHTML={createMarkup(projectLastComment.comment)}></div>
                              {
                                  projectLastComment.files ? 
                                  (
                                      projectLastComment.files.length > 0 ?
                                      <div className="ProjectDetails__Proposal__Content__attachments">
                                      <h3>{I18n.t('attachments')}:</h3>
                                      <ul>
                                      {projectLastComment.files.map(
                                          (file, index) => (
                                          <li key={index}>
                                              <a href={file.url} target="_blank" rel="noopener noreferrer">
                                              <span className="fas fa-paperclip"></span>
                                              {truncate(
                                                  file.filename,
                                                  20
                                              )}
                                              </a>
                                              <span className="file__size ms-1">
                                              ({Size(file.size)})
                                              </span>
                                          </li>
                                          )
                                      )}
                                      </ul>
                                  </div>
                                  : null
                                  ) : null
                              }
                              </div>
                          </div>
                          )
                          : null
                      }
                      {renderComments()}
                      {comments.length > 0 && !getProjectCommentsLoading ? (
                      <div className="col-12 my-4">
                          <Pagination
                          activePage={commentsActivePage}
                          itemsCountPerPage={commentsCountPerPage}
                          totalItemsCount={totalCommentsCount}
                          pageRangeDisplayed={10}
                          onChange={handleCommentsPageChange}
                          itemClass="page-item"
                          linkClass="page-link"
                          />
                      </div>
                      ) : null}
                  </div>
              </div>
            </Card.Body>
        </Card>
    )
}