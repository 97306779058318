//Live API LINK
//https://api.lancersin.com
//Live IP
//pusher.lancersin.com
//Beta API LINK
//https://betaapi.lancersin.com
//Beta IP
//betapusher.lancersin.com
export const ServerURL = 'https://api.lancersin.com';
export const ServerIP = 'pusher.lancersin.com';
export const WebsiteLink = 'https://lancersin.com';
