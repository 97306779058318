import React, {Fragment} from 'react';
import { useSelector } from 'react-redux';
import Pagination from "react-js-pagination";
import NoItems from '../NoItems';
import ServiceCard from "../ServiceCard";
import Spin from '../Spin';

export default function Services(props) {

    const {
        totalRecords,
        itemsPerPage,
        loadingallservices,
        allservices,
    } = useSelector((state) => ({
        totalRecords: state.services.totalRecords,
        itemsPerPage: state.services.itemsPerPage,
        loadingallservices: state.services.loadingallservices,
        allservices: state.services.allservices,
    }));

    const {
        activePage,
        setActivePage
    } = props;    

    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
    }
    
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);
    return(
        <Fragment>
            <div className="Dashboard__tasks__List">
                {
                    loadingallservices ?
                    <div className="fullPageLoading">
                        <Spin />
                    </div>
                    : allservices.length > 0 ?
                    <div className="row">
                        {
                            allservices.map( item => (
                                <ServiceCard key={item.id} item={item} />
                            ))
                        }
                        <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>
                    : <NoItems />
                }
            </div>
        </Fragment>
    )
}