import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { I18n } from 'react-redux-i18n';
import { getreportsTypes } from "../../actions/reportsActions";
import { postReport } from "../../actions/reportsActions";
import QuillBox from "../QuillBox";
import { POST_REPORT_RESET } from "../../actions/types";
import TextInput from "../TextInput";
import { SelectBox } from "../SelectBox";
import { clearErrors } from "../../actions/errorActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import GRecaptch from "../GRecaptch";

export default function ReportContentModal(props){
    const dispatch = useDispatch();

    const {
        errorsId,
        errorsMsg,
        reportTypes,
        postReportDone,
        locale,
      } = useSelector((state) => ({
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
        reportTypes: state.reportsTypes.reportTypes,
        postReportDone: state.reportsTypes.postReport,
        locale: state.i18n.locale,
    }));

    const [reportTitle, setReportTitle] = useState("");
    const [report, setReport] = useState("");
    const [type, setType] = useState({});
    const [errors, setErrors] = useState([]);
    const [localErrors, setLocalErrors] = useState({});
    const [isHuman, setIsHuman] = useState(false);

    const {
        model,
        model_id,
        loading,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay,
        onHide
    } = props;

    useEffect(() => {
        dispatch(getreportsTypes(locale));
    }, [dispatch, locale]);

    const handleHide = useCallback(() => {
        setLocalErrors({});
        onHide();
        dispatch(clearErrors());
    }, [dispatch, onHide]);

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

    useEffect(() => {
        if (Object.keys(errorsMsg).length > 0){
          setErrors(errorsMsg.errors);
        };
    }, [errorsMsg]);

    useEffect(() => {
        if (postReportDone){
            handleHide();
            handleToast(true, I18n.t('report_content_title'), I18n.t('report_content_success'), 'Toast__Container__Success', 6000);
            dispatch({
                type: POST_REPORT_RESET
            });
            setReportTitle("");
            setReport("");
            setType({});
            setErrors([]);
            setLocalErrors({});
            setIsHuman(false);
        };
    }, [postReportDone, dispatch, handleHide, handleToast]);

    function isObject(val) {
        return (typeof val === 'object');
    }

    function getTypes() {
        if (reportTypes) {
          return reportTypes.map((item) => {
            return {
              ...item,
              value: item.name,
              label: item.name,
            };
          });
        }
        return [];
    }

    const reportsTypes = getTypes();

    function handleItemLocalError({ propName, currentValue, message, uploadArray }) {
        if(currentValue){
            if(isObject(currentValue) && !Array.isArray(currentValue)){
                if (Object.keys(currentValue).length > 0) {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                    }));
                } else {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: message,
                    }));
                }
            }else if(Array.isArray(currentValue) && uploadArray === false){
                if (currentValue.length <= 0) {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: message,
                    }));
                }else{
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                    }));
                }
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                }));
            }
        }else{
            setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
            }));
        }
    }

    function onTitleChange(e) {
        setReportTitle(e.target.value);
    }

    function onTitleBlur(){
        handleItemLocalError({
            propName: "title",
            currentValue: reportTitle,
            message: I18n.t('title_required'),
        });
    }

    function handleReportChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setReport("");
        } else {
            setReport(value);
        }
    }

    function onReportBlur(){
        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('description_required'),
        });
    }

    function handleTypeChange(e) {
        setType(e);
    }

    function onTypeBlur(){
        handleItemLocalError({
          propName: "type",
          currentValue: type,
          message: I18n.t('reason_required'),
        });
    }

    function onSubmit(e) {
        e.preventDefault();

        handleItemLocalError({
            propName: "type",
            currentValue: type,
            message: I18n.t('reason_required'),
        });

        handleItemLocalError({
            propName: "title",
            currentValue: reportTitle,
            message: I18n.t('title_required'),
        });

        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('description_required'),
        });
        if(
            reportTitle !== "" &&
            Object.keys(type).length > 0 &&
            report !== "" &&
            isHuman
            ){
            const reportData = {
            type_id: type.id,
            report,
            title: reportTitle,
            model,
            model_id,
            };
            dispatch(postReport(reportData));
        } else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
    };

    return(
        <Modal
            className={props.className}
            size={props.size}
            show={props.show}
            onHide={props.onHide}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            aria-labelledby={props["aria-labelledby"]}
            >
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18n.t('report_content')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectBox
                                required
                                controlId="Reason"
                                name="reason"
                                label={I18n.t('reason')}
                                options={reportsTypes}
                                value={type}
                                onChange={handleTypeChange}
                                onBlur={onTypeBlur}
                                feedbackType="invalid"
                                feedback={localErrors.type ? localErrors.type : null}
                                isInvalid={(errorsId === "POST_REPORT_FAIL" && errors.type_id) || localErrors.type}
                                placeholder={I18n.t("reason")}
                            />
                        </div>
                    </div>
                    <TextInput
                        required
                        controlId="Title"
                        label="report_title"
                        type="text"
                        name="title"
                        onChange={onTitleChange}
                        onBlur={onTitleBlur}
                        value={reportTitle}
                        feedbackType="invalid"
                        feedback={ localErrors.title ? localErrors.title : null }
                        isInvalid={(errorsId === "POST_REPORT_FAIL" && errors.title) || localErrors.title}
                    />
                    <QuillBox
                        required
                        controlId="Description"
                        label="report_description"
                        value={report}
                        onChange={handleReportChange}
                        onBlur={onReportBlur}
                        feedbackType="invalid"
                        feedback={localErrors.report ? localErrors.report : null}
                        isInvalid={(errorsId === "POST_REPORT_FAIL" && errors.report) || localErrors.report}
                    />

                    <GRecaptch
                        setIsHuman={setIsHuman}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonWithLoading
                        variant="primary"
                        type="submit"
                        onClick={onSubmit}
                        data-backdrop="static"
                        loading={loading ? loading : null}
                    >
                        {I18n.t('submit')}
                    </ButtonWithLoading>
                    <Button
                        variant="secondary"
                        className="btn btn-secondary"
                        onClick={handleHide}
                    >
                        {I18n.t('close')}
                    </Button>
                </Modal.Footer>
            </Form>
            </Modal>
    )
}