import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import AsyncSelect from 'react-select/lib/Async';
import { Translate, I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../TextInput";
import { clearErrors, returnErrors } from "../../actions/errorActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { addPaypalAccount, addBankAccount, selectPaymentMethod, requestWithdrawal } from "../../redux/payments/actions/addActions";
import ErrorBox from "../ErrorBox";
import GRecaptch from "../GRecaptch";
import axios from "axios";
import { ServerURL } from "../Server";
import { GET_COUNTRIES_FAIL } from '../../actions/types';
import { CLEAR_ADD_PAYPAL_SUCCESS, CLEAR_ADD_BANK_ACCOUNT_SUCCESS, CLEAR_PAYMENT_METHOD_SUCCESS, CLEAR_REQUEST_WITHDRAWAL_SUCCESS } from "../../redux/payments/actions/types";
import { getWithdrawalRequests } from "../../redux/payments/actions/getActions";
import { getUserData } from '../../redux/userData/actions/getActions';

const { Control } = Form;
const { Feedback } = Control;

const axiosInstance = axios.create({
  baseURL: `${ServerURL}`,
  headers: {
    "Accept-Language": "en",
  },
});

export default function AddingPaymentModal(props) {
  const dispatch = useDispatch();

  const {
    user,
    isAuthenticated,
    errorsId,
    errorsMsg: { errors = {} },
    locale,

    addingPaypalAccountSuccess,
    addingBankAccountSuccess,
    requestWithdrawalSuccess,
    selectPaymentMethodLoading,
    selectPaymentMethodSuccess,
  } = useSelector((state) => ({
    user: state.userData.user,
    isAuthenticated: state.auth.isAuthenticated,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    locale: state.i18n.locale,

    addingPaypalAccountSuccess: state.payments.addingPaypalAccountSuccess,
    addingBankAccountSuccess: state.payments.addingBankAccountSuccess,
    requestWithdrawalSuccess: state.payments.requestWithdrawalSuccess,
    selectPaymentMethodLoading: state.payments.selectPaymentMethodLoading,
    selectPaymentMethodSuccess: state.payments.selectPaymentMethodSuccess,
  }));

  const [localErrors, setLocalErrors] = useState({});

  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [IBAN, setIBAN] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [province, setProvince] = useState("");
  const [paypalMethod, setPaypalMethod] = useState(false);
  const [bankMethod, setBankMethod] = useState(false);
  const [amount, setAmount] = useState("");
  const [isHuman, setIsHuman] = useState(false);


  const {
    modalTitle,
    loading,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
    paymentType,
    show
  } = props;

  // Countreis & Cities Functions
  const getCountries = useCallback(() => {
    axiosInstance.post('/api/countries')
    .then(res => 
        setCountries(res.data.data)
    )
    .catch(error => {
        if (error instanceof Error) {
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_COUNTRIES_FAIL')),
                dispatch ({ 
                    type: GET_COUNTRIES_FAIL 
                })
            )
        }else{
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_COUNTRIES_FAIL'));
            dispatch ({ 
                type: GET_COUNTRIES_FAIL 
            });
        }
    });
  }, [dispatch]);

  const filterCountries = () => {
    return countries.map( item => {
        return {
            ...item,
            label: item.name,
            value: item.id
        }
    })
  };

  const loadCountryOptions = (inputValue, callback) => {
      const config = {
          headers: {
              'Content-Type': 'application/json'
          }
      }
      //Request body
      const body = JSON.stringify({keyword: inputValue});
      axiosInstance.post('/api/countries', body, config)
      .then(res =>
          setCountries(res.data.data)
      )
      .catch(error => {
          if (error instanceof Error) {
              let errorJson = JSON.stringify(error);
              let errorParsed = JSON.parse(errorJson);
              return (
                  dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_COUNTRIES_FAIL')),
                  dispatch ({ 
                      type: GET_COUNTRIES_FAIL 
                  })
              )
          }else{
              dispatch(returnErrors(error.response.data, error.response.status, 'GET_COUNTRIES_FAIL'));
              dispatch ({ 
                  type: GET_COUNTRIES_FAIL 
              });
          }
      });
      setTimeout(() => {
        callback(filterCountries());
      }, 1000);
  };

  const countriesMap = countries.map( item => {
    return {
        ...item,
        label: item.name,
        value: item.id
    }
  });

  useEffect(() => {
    if(isAuthenticated){
      getCountries();
    }
  }, [getCountries, isAuthenticated]);

  function handleItemLocalError({ propName, currentValue, message,type }) {
    if(currentValue && type !== "amount"){
        if(Array.isArray(currentValue)){
            if (currentValue.length <= 0) {
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: message,
            }));
            }else{
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: undefined,
            }));
            }
        }else{
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: undefined,
            }));
        }
    }else if(currentValue && type && type === "amount"){
      if(user && user.payment_method){
        if(user.payment_method === "paypal"){
          if(amount < 25){
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: I18n.t('paypal_note'),
            }));
          } else{
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: undefined,
            }));
          }
        } else if(user.payment_method === "bank_account"){
          if(amount < 100){
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: I18n.t('bank_account_note'),
            }));
          } else{
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: undefined,
            }));
          }
        }
      }
    }else{
        setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
        }));
    }
  }

  function ValidateEmail(mail){
    const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    if (!mail || regex.test(mail) === false){
        if(!mail && paymentType === "PayPal"){
            setLocalErrors((errors) => ({
                ...errors,
                "email": I18n.t('email_required'),
            }));
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                "email": I18n.t('enter_valid_email'),
            }));
        }
        return false;
    }
    setLocalErrors((errors) => ({
        ...errors,
        "email": undefined,
    }));
    return true;
    
  }

  function onFnameChange(e) {
    setFname(e.target.value);
  }

  function onFnameBlur(){
      handleItemLocalError({
          propName: "fname",
          currentValue: fname,
          message: I18n.t('fname_required'),
      });
  }

  function onLnameChange(e) {
      setLname(e.target.value);
  }

  function onLnameBlur(){
      handleItemLocalError({
          propName: "lname",
          currentValue: lname,
          message: I18n.t('lname_required'),
      });
  }

  function onEmailChange(e){
    setEmail(e.target.value);
  }

  function onEmailBlur(){
      ValidateEmail(email);
  }

  function onAccountNumberChange(e) {
    setAccountNumber(e.target.value);
  }

  function onIBANChange(e) {
    setIBAN(e.target.value);
  }

  function onSwiftChange(e) {
    setSwiftCode(e.target.value);
  }

  function onRoutingChange(e) {
    setRoutingNumber(e.target.value);
  }

  function countryChange(inputValue){ // whole object of selected option 
    setSelectedCountry(inputValue);
  };

  function countryBlur(){
    handleItemLocalError({
        propName: "country",
        currentValue: selectedCountry,
        message: I18n.t('country_required'),
    });
  }

  function onBankNameChange(e) {
    setBankName(e.target.value);
  }

  function onBankNameBlur(){
      handleItemLocalError({
          propName: "bankName",
          currentValue: bankName,
          message: I18n.t('bankName_required'),
      });
  }

  function onCurrencyChange(e) {
    setCurrency(e.target.value);
  }

  function onCurrencyBlur(){
      handleItemLocalError({
          propName: "currency",
          currentValue: currency,
          message: I18n.t('currency_required'),
      });
  }

  function onCityChange(e) {
    setCity(e.target.value);
  }

  function onCityBlur(){
      handleItemLocalError({
          propName: "city",
          currentValue: city,
          message: I18n.t('city_required'),
      });
  }

  function onAddressChange(e) {
    setAddress(e.target.value);
  }

  function onAddressBlur(){
      handleItemLocalError({
          propName: "address",
          currentValue: address,
          message: I18n.t('address_required'),
      });
  }

  function onPostcodeChange(e) {
    setPostcode(e.target.value);
  }

  function onProvinceChange(e) {
    setProvince(e.target.value);
  }

  const handleHide = useCallback(
    () => {
      setLocalErrors({});
      props.onHide();
      dispatch(clearErrors());
    },
    [props, dispatch]
  );

  const handleToast = useCallback((showToast, title, message, status, delay) => {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  function onSubmit(e) {
    e.preventDefault();
    if(paymentType === "PayPal"){
      ValidateEmail(email);
      if (
        email &&
        isHuman &&
        !localErrors.email
      ) {
          let itemData = {
            email,
          };
          dispatch(addPaypalAccount(itemData));
          if(paypalMethod){
            let methodData = {
              payment_method: "paypal",
            };
            dispatch(selectPaymentMethod(methodData));
          }
      } else if(
          !isHuman
      ) {
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('verify_robot'), 'Toast__Container__Error', 10000)
      } else{
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Error', 10000)
      }
    }else if(paymentType === "Bank"){
      handleItemLocalError({
          propName: "fname",
          currentValue: fname,
          message: I18n.t('fname_required'),
      });
      handleItemLocalError({
          propName: "lname",
          currentValue: lname,
          message: I18n.t('lname_required'),
      });
      handleItemLocalError({
          propName: "country",
          currentValue: selectedCountry,
          message: I18n.t('country_required'),
      });
      handleItemLocalError({
          propName: "bankName",
          currentValue: bankName,
          message: I18n.t('bankName_required'),
      });
      handleItemLocalError({
          propName: "currency",
          currentValue: currency,
          message: I18n.t('currency_required'),
      });
      handleItemLocalError({
          propName: "city",
          currentValue: city,
          message: I18n.t('city_required'),
      });
      handleItemLocalError({
          propName: "address",
          currentValue: address,
          message: I18n.t('address_required'),
      });
      if (
        fname &&
        lname &&
        Object.keys(selectedCountry).length > 0 &&
        bankName &&
        currency &&
        city &&
        address &&
        isHuman &&
        !localErrors.fname &&
        !localErrors.lname &&
        !localErrors.country &&
        !localErrors.bankName &&
        !localErrors.currency &&
        !localErrors.city &&
        !localErrors.address
      ) {
          let itemData = {
            fname,
            lname,
            email,
            account_number: accountNumber,
            iban: IBAN,
            swift_code: swiftCode,
            routing_number: routingNumber,
            bank_name: bankName,
            address,
            city,
            post_code: postcode,
            province,
            country: selectedCountry.label,
            currency
          };
          dispatch(addBankAccount(itemData));
          if(bankMethod){
            let methodData = {
              payment_method: "bank_account",
            };
            dispatch(selectPaymentMethod(methodData));
          }
      } else if(
          !isHuman
      ) {
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('verify_robot'), 'Toast__Container__Error', 10000)
      } else{
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Error', 10000)
      }
    } else{
      handleItemLocalError({
        propName: "amount",
        currentValue: amount,
        message: I18n.t('amount_required'),
        type: "amount"
      });
      if (
        amount &&
        isHuman &&
        !localErrors.amount
      ) {
          let itemData = {
            amount
          };
          dispatch(requestWithdrawal(itemData));
      } else if(
          !isHuman
      ) {
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('verify_robot'), 'Toast__Container__Error', 10000)
      } else{
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Error', 10000)
      }
    }
  }

  function onChangePayPalMethod(){
      setPaypalMethod(!paypalMethod);
  };

  function onChangeBankMethod(){
    setBankMethod(!bankMethod);
  };

  function onAmountChange(e) {
    e.preventDefault();
    setAmount(Math.abs(e.target.value));
  }

  function onAmountBlur(){
    handleItemLocalError({
      propName: "amount",
      currentValue: amount,
      message: I18n.t('amount_required'),
      type: "amount"
    });
  }

  useEffect(() => {
    if(user && user.paypal_email && paymentType === "PayPal"){
      setEmail(user.paypal_email);
    } else if (user && user.bank_account && paymentType === "Bank"){
      if(user.bank_account.fname){
        setFname(user.bank_account.fname);
      };
      if(user.bank_account.lname){
        setLname(user.bank_account.lname);
      };
      if(user.bank_account.email){
        setEmail(user.bank_account.email);
      };
      if(user.bank_account.account_number){
        setAccountNumber(user.bank_account.account_number);
      };
      if(user.bank_account.iban){
        setIBAN(user.bank_account.iban);
      };
      if(user.bank_account.swift_code){
        setSwiftCode(user.bank_account.swift_code);
      };
      if(user.bank_account.routing_number){
        setRoutingNumber(user.bank_account.routing_number);
      };
      if(user.bank_account.bank_name){
        setBankName(user.bank_account.bank_name);
      };
      if(user.bank_account.currency){
        setCurrency(user.bank_account.currency);
      };
      if(user.bank_account.country){
        setSelectedCountry(
          {
              label: user.bank_account.country,
              value: user.bank_account.country 
          }
        );
      };
      if(user.bank_account.city){
        setCity(user.bank_account.city);
      };
      if(user.bank_account.address){
        setAddress(user.bank_account.address);
      };
      if(user.bank_account.post_code){
        setPostcode(user.bank_account.post_code);
      };
      if(user.bank_account.province){
        setProvince(user.bank_account.province);
      };
    }
    if(user && user.payment_method){
      if(user.payment_method === "paypal"){
        setPaypalMethod(true);
      } else if(user.payment_method === "bank_account"){
        setBankMethod(true);
      }
    }
  }, [user, paymentType, show]);

  useEffect(() => {
    if (errorsId !== "ADD_PAYPAL_FAIL" && !loading && addingPaypalAccountSuccess){
        dispatch(getUserData(locale));
        dispatch({
          type: CLEAR_ADD_PAYPAL_SUCCESS
        });
        handleHide();
        if(user && user.paypal_email){
          handleToast(true, I18n.t('paypal_accounnt'), I18n.t('paypal_account_edit_success'), 'Toast__Container__Success', 6000);
        }else{
          handleToast(true, I18n.t('paypal_accounnt'), I18n.t('paypal_account_success'), 'Toast__Container__Success', 6000);          
        }
        setEmail("");
        setPaypalMethod(false);
        setBankMethod(false);
        setIsHuman(false);
    };
  }, [errorsId, loading, addingPaypalAccountSuccess, dispatch, handleHide, handleToast, user, locale]);

  useEffect(() => {
    if (errorsId !== "SELECT_PAYMENT_METHOD_FAIL" && !selectPaymentMethodLoading && selectPaymentMethodSuccess){
        dispatch({
          type: CLEAR_PAYMENT_METHOD_SUCCESS
        });
    };
  }, [errorsId, selectPaymentMethodLoading, selectPaymentMethodSuccess, dispatch]);

  useEffect(() => {
    if (errorsId !== "ADD_BANK_ACCOUNT_FAIL" && !loading && addingBankAccountSuccess){
      dispatch(getUserData(locale));
      dispatch({
        type: CLEAR_ADD_BANK_ACCOUNT_SUCCESS
      });
      handleHide();
      if(user && user.bank_account){
        handleToast(true, I18n.t('bank_account'), I18n.t('bank_account_edit_success'), 'Toast__Container__Success', 6000);
      }else{
        handleToast(true, I18n.t('bank_account'), I18n.t('bank_account_success'), 'Toast__Container__Success', 6000);
      }
      setFname("");
      setLname("");
      setEmail("");
      setAccountNumber("");
      setIBAN("");
      setSwiftCode("");
      setRoutingNumber("");
      setBankName("");
      setCurrency("");
      setSelectedCountry([]);
      setCity("");
      setAddress("");
      setPostcode("");
      setProvince("");
      setBankMethod(false);
      setPaypalMethod(false);
      setIsHuman(false);
    };
  }, [errorsId, loading, addingBankAccountSuccess, dispatch, handleHide, handleToast, user, locale]);

  useEffect(() => {
    if (errorsId !== "REQUEST_WITHDRAWAL_FAIL" && !loading && requestWithdrawalSuccess){
      const page = 1;
      const limit = 20;
      dispatch(getWithdrawalRequests(page, limit, null, locale));
      dispatch({
        type: CLEAR_REQUEST_WITHDRAWAL_SUCCESS
      });
      handleHide();
      handleToast(true, I18n.t('withdrawal_request'), I18n.t('withdrawal_request_success'), 'Toast__Container__Success', 6000);
      setAmount("");
    };
  }, [errorsId, loading, requestWithdrawalSuccess, dispatch, handleHide, handleToast, locale]);
  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props["aria-labelledby"]}
    >
      
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            paymentType === "Bank" ?
            <div className="alert alert-warning mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                <p className="my-1">{I18n.t('bank_data_note')}</p>
            </div>
            : null
          }
          {
            paymentType === "PayPal" ?
            <Fragment>
              <TextInput
                required
                controlId="email"
                label="email_address"
                feedbackType="invalid"
                type="email" 
                name="email" 
                placeholder={I18n.t('email_placeholder')} 
                onChange={onEmailChange} 
                onBlur={onEmailBlur}
                isInvalid={(errorsId === "ADD_PAYPAL_FAIL" && errors.email) || localErrors.email}
                value={email}
                feedback={ localErrors.email ? localErrors.email : errors.email ? errors.email[0] : null }
              />
              <div className="account__type__types">
                  <div className="form-group">
                      <div className="form-check">
                          <input type="checkbox" id="PayPal" name="PayPal" value="1" className="form-check-input" onChange={onChangePayPalMethod} checked={paypalMethod ? true : false} />
                          <label title="" htmlFor="PayPal" className="form-check-label">{I18n.t('make_default_payment_method')}</label>
                      </div>
                  </div>
              </div>
            </Fragment>
            : paymentType === "Bank" ?
            <div className="row">
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="fName"
                        label="first_name"
                        type="text"
                        name="fname"
                        feedbackType="invalid"
                        onChange={onFnameChange}
                        onBlur={onFnameBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.fname) || localErrors.fname}
                        value={fname}
                        feedback={ localErrors.fname ? localErrors.fname : errors.fname ? errors.fname[0] : null }
                    />
                </div>
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="lName"
                        label="last_name"
                        type="text"
                        name="lname"
                        feedbackType="invalid"
                        onChange={onLnameChange}
                        onBlur={onLnameBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.lname) || localErrors.lname}
                        value={lname}
                        feedback={ localErrors.lname ? localErrors.lname : errors.lname ? errors.lname[0] : null }
                    />
                </div>
                <div className="col-md-6">
                    <TextInput
                      required={false}
                      controlId="email"
                      label="email_address"
                      feedbackType="invalid"
                      type="email" 
                      name="email" 
                      placeholder={I18n.t('email_placeholder')} 
                      onChange={onEmailChange} 
                      onBlur={onEmailBlur}
                      isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.email) || localErrors.email}
                      value={email}
                      feedback={ localErrors.email ? localErrors.email : errors.email ? errors.email[0] : null }
                    />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="aNumber"
                      label="account_number"
                      type="text"
                      name="aNumber"
                      onChange={onAccountNumberChange}
                      value={accountNumber}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="IBAN"
                      label="iban"
                      type="text"
                      name="IBAN"
                      onChange={onIBANChange}
                      value={IBAN}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="Swift"
                      label="swift_code"
                      type="text"
                      name="Swift"
                      onChange={onSwiftChange}
                      value={swiftCode}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="Routing"
                      label="routing_number"
                      type="text"
                      name="Routing"
                      onChange={onRoutingChange}
                      value={routingNumber}
                  />
                </div>
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="BankName"
                        label="bank_name"
                        type="text"
                        name="BankName"
                        feedbackType="invalid"
                        onChange={onBankNameChange}
                        onBlur={onBankNameBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.bankName) || localErrors.bankName}
                        value={bankName}
                        feedback={ localErrors.bankName ? localErrors.bankName : errors.bank_name ? errors.bank_name[0] : null }
                    />
                </div>
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="accountCurrency"
                        label="account_currency"
                        type="text"
                        name="accountCurrency"
                        feedbackType="invalid"
                        onChange={onCurrencyChange}
                        onBlur={onCurrencyBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.currency) || localErrors.currency}
                        value={currency}
                        feedback={ localErrors.currency ? localErrors.currency : errors.currency ? errors.currency[0] : null }
                    />
                </div>
                <div className="col-md-6">
                    <Form.Group controlId="Country">
                        <Form.Label>{I18n.t('country')}<span className="required">*</span></Form.Label>
                        <AsyncSelect 
                            loadOptions={loadCountryOptions}
                            value={selectedCountry}
                            defaultOptions={countriesMap}
                            onChange={countryChange}
                            onBlur={countryBlur}
                            className={localErrors.country ? "invalid" : ""}
                        />
                        {
                            localErrors.country ? (
                                <Feedback className="d-block" type="invalid">
                                    {localErrors.country}
                                </Feedback>
                            ) : null
                        }
                    </Form.Group>
                </div>
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="city"
                        label="city"
                        type="text"
                        name="city"
                        feedbackType="invalid"
                        onChange={onCityChange}
                        onBlur={onCityBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.city) || localErrors.city}
                        value={city}
                        feedback={ localErrors.city ? localErrors.city : errors.city ? errors.city[0] : null }
                    />
                </div>
                <div className="col-md-6">
                    <TextInput
                        required
                        controlId="address"
                        label="address"
                        type="text"
                        name="address"
                        feedbackType="invalid"
                        onChange={onAddressChange}
                        onBlur={onAddressBlur}
                        isInvalid={(errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors.address) || localErrors.address}
                        value={address}
                        feedback={ localErrors.address ? localErrors.address : errors.address ? errors.address[0] : null }
                    />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="postcode"
                      label="postcode"
                      type="text"
                      name="postcode"
                      onChange={onPostcodeChange}
                      value={postcode}
                  />
                </div>
                <div className="col-md-6">
                  <TextInput
                      required={false}
                      controlId="province"
                      label="state_province"
                      type="text"
                      name="province"
                      onChange={onProvinceChange}
                      value={province}
                  />
                </div>
                <div className="col-md-6">
                  <div className="account__type__types">
                      <div className="form-group">
                          <div className="form-check">
                              <input type="checkbox" id="bankAccount" name="bankAccount" value="1" className="form-check-input" onChange={onChangeBankMethod} checked={bankMethod ? true : false} />
                              <label title="" htmlFor="bankAccount" className="form-check-label">{I18n.t('make_default_payment_method')}</label>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            :
            <Fragment>
              {
                user && !user.paypal_email && !user.bank_name ?
                <div className="alert alert-danger mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('add_withdrawal_account')}</p>
                </div>
                : null
              }
              {
                user && user.payment_method && user.payment_method === "bank_account" && user.withdrawable_balances &&  user.withdrawable_balances.balance < 100 ?
                <div className="alert alert-danger mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('withdrawal_bank_default_balance_note')}</p>
                </div>
                : user && user.payment_method && user.payment_method === "bank_account" ?
                <div className="alert alert-warning mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('withdrawal_bank_default_note')}</p>
                </div>
                : user && user.payment_method && user.payment_method === "paypal" && user.withdrawable_balances &&  user.withdrawable_balances.balance > 100 ?
                <div className="alert alert-warning mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('withdrawal_paypal_default_note')}</p>
                </div>
                : user && !user.payment_method ?
                <div className="alert alert-danger mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('no_withdrawal_default_note')}</p>
                </div>
                : null
              }
              {
                user && user.withdrawable_balances &&  user.withdrawable_balances.balance < 25 ?
                <div className="alert alert-danger mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                    <p className="my-1">{I18n.t('withdrawal_balance_note')}</p>
                </div>
                : null
              }
              <TextInput
                required
                controlId="amount"
                label="amount"
                feedbackType="invalid"
                type="number" 
                name="amount" 
                disabled={ (user && !user.paypal_email && !user.bank_name) || (user && !user.payment_method) || (user && user.withdrawable_balances &&  user.withdrawable_balances.balance < 25) || (user && user.payment_method && user.payment_method === "bank_account" && user.withdrawable_balances &&  user.withdrawable_balances.balance < 100) }
                onChange={onAmountChange} 
                onBlur={onAmountBlur}
                isInvalid={(errorsId === "REQUEST_WITHDRAWAL_FAIL" && errors.amount) || localErrors.amount}
                value={(Number(amount) || '').toString()}
                feedback={ localErrors.amount ? localErrors.amount : errors.amount ? errors.amount[0] : null }
              />
            </Fragment>
          }

          <GRecaptch
              setIsHuman={setIsHuman}
              className="mt-3"
          />

          {
            errorsId === "ADD_PAYPAL_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "ADD_PAYPAL_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "ADD_BANK_ACCOUNT_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : errorsId === "REQUEST_WITHDRAWAL_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "REQUEST_WITHDRAWAL_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : null
          }
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
          variant="primary"
          type="submit"
          onClick={onSubmit}
          data-backdrop="static"
          loading={loading ? loading : null}
          >
              <Translate value={ paymentType === "Withdrawal" ? "submit" : "save"} />
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}
            disabled={loading}
          >
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
