// Actions for Adding Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  // ADD/EDIT PAYPAL ACCOUNT
  ADD_PAYPAL_LOADING,
  ADD_PAYPAL_SUCCESS,
  ADD_PAYPAL_FAIL,
  // ADD/EDIT BANK ACCOUNT
  ADD_BANK_ACCOUNT_LOADING,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAIL,
  // SELECT PAYMENT METHOD
  SELECT_PAYMENT_METHOD_LOADING,
  SELECT_PAYMENT_METHOD_SUCCESS,
  SELECT_PAYMENT_METHOD_FAIL,
  // REQUEST WITHDRAWAL
  REQUEST_WITHDRAWAL_LOADING,
  REQUEST_WITHDRAWAL_SUCCESS,
  REQUEST_WITHDRAWAL_FAIL,
} = TYPES;

// ADD/EDIT PAYPAL ACCOUNT
export function addPaypalAccount(data) {
  return async function addPaypalAccountWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: ADD_PAYPAL_LOADING,
    });
    try {
        let response = await axiosInstance.post(
          API.PAYPAL, 
          data, 
          tokenConfig(getState)
        );
        if(response.status !== 200){
            dispatch({
                type: ADD_PAYPAL_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: ADD_PAYPAL_SUCCESS,
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'ADD_PAYPAL_FAIL'));
            dispatch ({ 
                type: ADD_PAYPAL_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_PAYPAL_FAIL')),
              dispatch ({ 
                  type: ADD_PAYPAL_FAIL 
              })
          )
        }
    }
  };
}

// ADD/EDIT BANK ACCOUNT ACCOUNT
export function addBankAccount(data) {
  return async function addBankAccountWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: ADD_BANK_ACCOUNT_LOADING,
    });
    try {
        let response = await axiosInstance.post(
          API.BANK_ACCOUNT, 
          data, 
          tokenConfig(getState)
        );
        if(response.status !== 200){
            dispatch({
                type: ADD_BANK_ACCOUNT_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: ADD_BANK_ACCOUNT_SUCCESS,
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'ADD_BANK_ACCOUNT_FAIL'));
            dispatch ({ 
                type: ADD_BANK_ACCOUNT_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_BANK_ACCOUNT_FAIL')),
              dispatch ({ 
                  type: ADD_BANK_ACCOUNT_FAIL 
              })
          )
        }
    }
  };
}

// SELECT PAYMENT METHOD
export function selectPaymentMethod(data) {
  return async function selectPaymentMethodWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: SELECT_PAYMENT_METHOD_LOADING,
    });
    try {
        let response = await axiosInstance.post(
          API.PAYMENT_METHOD, 
          data, 
          tokenConfig(getState)
        );
        if(response.status !== 200){
            dispatch({
                type: SELECT_PAYMENT_METHOD_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: SELECT_PAYMENT_METHOD_SUCCESS,
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'SELECT_PAYMENT_METHOD_FAIL'));
            dispatch ({ 
                type: SELECT_PAYMENT_METHOD_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'SELECT_PAYMENT_METHOD_FAIL')),
              dispatch ({ 
                  type: SELECT_PAYMENT_METHOD_FAIL 
              })
          )
        }
    }
  };
}

// REQUEST WITHDRAWAL
export function requestWithdrawal(data) {
  return async function requestWithdrawalWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: REQUEST_WITHDRAWAL_LOADING,
    });
    try {
        let response = await axiosInstance.post(
          API.REQUEST_WITHDRAWAL, 
          data, 
          tokenConfig(getState)
        );
        if(response.status !== 200){
            dispatch({
                type: REQUEST_WITHDRAWAL_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: REQUEST_WITHDRAWAL_SUCCESS,
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'REQUEST_WITHDRAWAL_FAIL'));
            dispatch ({ 
                type: REQUEST_WITHDRAWAL_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'REQUEST_WITHDRAWAL_FAIL')),
              dispatch ({ 
                  type: REQUEST_WITHDRAWAL_FAIL 
              })
          )
        }
    }
  };
}