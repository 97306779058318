// Actions for Deleting Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  DELETE_ARTICLE_LOADING,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,
} = TYPES;

export function deleteArticleLoading() {
  return {
    type: DELETE_ARTICLE_LOADING,
  };
}

export function deleteArticleSuccess(id) {
  return {
    type: DELETE_ARTICLE_SUCCESS,
    id,
  };
}

export function deleteArticleFail(error) {
  return {
    type: DELETE_ARTICLE_FAIL,
    error,
  };
}

export function deleteArticle({ id }) {
  return function deleteArticleWithDispatch(dispatch, getState) {
    dispatch(deleteArticleLoading());
    axiosInstance
      .delete(`${API.ARTICLES}?id=${id}`, tokenConfig(getState))
      .then((res) => {
        dispatch(deleteArticleSuccess(id));
      })
      .catch((error) => {
        dispatch(deleteArticleFail(error.response.data));
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            DELETE_ARTICLE_FAIL
          )
        );
      });
  };
}

const exportedObject = {
  deleteArticleLoading,
  deleteArticleSuccess,
  deleteArticleFail,
  deleteArticle,
};

export default exportedObject;
