import { 
    FOLLOW_USER_SUCCESS,
    FOLLOW_USER_FAIL,
    FOLLOW_USER_LOADING,
    FOLLOWERS_SUCCESS,
    FOLLOWERS_FAIL,
    FOLLOWERS_LOADING,
    FOLLOWINGS_SUCCESS,
    FOLLOWINGS_FAIL,
    FOLLOWINGS_LOADING,
    UNFOLLOW_USER_SUCCESS,
    UNFOLLOW_USER_FAIL,
    UNFOLLOW_USER_LOADING
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors } from '../actions/errorActions';

// FOLLOW USER
export const followUser = ({ action, followed_id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: FOLLOW_USER_LOADING
    });
    //Request body
    const body = { action, followed_id };
    axiosInstance.post('/api/follow', body, tokenConfig(getState))
    .then( res => {
            dispatch ({
                type: FOLLOW_USER_SUCCESS,
                payload: res.data
            })
            dispatch({
                type: FOLLOWINGS_LOADING
            });
            axiosInstance.get('/api/followings', tokenConfig(getState))
            .then( res => 
                dispatch ({
                    type: FOLLOWINGS_SUCCESS,
                    payload: res.data
                })
            )
        }
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'FOLLOW_USER_FAIL'))
            dispatch ({ 
                type: FOLLOW_USER_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'FOLLOW_USER_FAIL')),
                dispatch ({ 
                    type: FOLLOW_USER_FAIL 
                })
            )
        }
    });
};

// UNFOLLOW USER
export const unfollowUser = ({ action, followed_id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: UNFOLLOW_USER_LOADING
    });
    //Request body
    const body = { action, followed_id };
    axiosInstance.post('/api/follow', body, tokenConfig(getState))
    .then( res => {
            dispatch ({
                type: UNFOLLOW_USER_SUCCESS,
                payload: res.data
            })
            dispatch({
                type: FOLLOWINGS_LOADING
            });
            axiosInstance.get('/api/followings', tokenConfig(getState))
            .then( res => 
                dispatch ({
                    type: FOLLOWINGS_SUCCESS,
                    payload: res.data
                })
            )
        }
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'UNFOLLOW_USER_FAIL'))
            dispatch ({ 
                type: UNFOLLOW_USER_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'UNFOLLOW_USER_FAIL')),
                dispatch ({ 
                    type: UNFOLLOW_USER_FAIL 
                })
            )
        }
    });
};

// GET FOLLOWERS THAT FOLLOWED THE CURRENT USER
export const getFollowers = () => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: FOLLOWERS_LOADING
    });
    axiosInstance.get('/api/followers', tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: FOLLOWERS_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'FOLLOWERS_FAIL'))
            dispatch ({ 
                type: FOLLOWERS_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'FOLLOWERS_FAIL')),
                dispatch ({ 
                    type: FOLLOWERS_FAIL 
                })
            )
        }
    });
};

// GET FOLLOWERS THAT FOLLOWED BY CURRENT USER
export const getFollowings = () => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: FOLLOWINGS_LOADING
    });
    axiosInstance.get('/api/followings', tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: FOLLOWINGS_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'FOLLOWINGS_FAIL'))
            dispatch ({ 
                type: FOLLOWINGS_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'FOLLOWINGS_FAIL')),
                dispatch ({ 
                    type: FOLLOWINGS_FAIL 
                })
            )
        }
    });
};

// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}