import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Translate, I18n } from "react-redux-i18n";
import { Button, Alert } from "react-bootstrap";
//import QuillBox from "../QuillBox";
import TextInput from "../TextInput";
import { ADD_SERVICE_FAIL } from "../../actions/types";

export default function AddingFeature({ addFeature, featureForm = false, warning = "", warningType = "" }) {

  const { 
    errorsId, 
    errorsMsg: { returnedErrors = {} },
  } = useSelector((state) => ({
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
  }));

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const [price, setPrice] = useState("");
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_SERVICE_FAIL);
  const [localErrors, setLocalErrors] = useState({});

  useEffect(() => {
    if(errorsId && returnedErrors){
        setErrorActionType(errorsId);
    }
  }, [errorsId, returnedErrors]);

  function handleTitle(e) {
    e.preventDefault();
    setTitle(e.target.value);
  }

  // function handleDescChange(value = "") {
  //   if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
  //     setDescription("");
  //   } else {
  //     setDescription(value);
  //   }
  // }

  function handleItemLocalError({ propName, currentValue, message }) {
    if(currentValue){
      setLocalErrors((errors) => ({
        ...errors,
        [propName]: undefined,
      }));
    }else{
      setLocalErrors((errors) => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function handleDeadlineChange(e) {
    e.preventDefault();
    setDeadline(Math.abs(e.target.value));
  }

  function handlePriceChange(e) {
    e.preventDefault();
    setPrice(Math.abs(e.target.value));
  }

  function onDescriptionBlur(){
    handleItemLocalError({
      propName: "description",
      currentValue: title,
      message: I18n.t('description_required'),
    });
  }

  function onDeadlineBlur(){
    handleItemLocalError({
      propName: "deadline",
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });
  }

  function onPriceBlur(){
    handleItemLocalError({
      propName: "price",
      currentValue: price,
      message: I18n.t('price_required'),
    });
  }

  function handelAddFeature() {
    handleItemLocalError({
      propName: "description",
      currentValue: title,
      message: I18n.t('description_required'),
    });

    handleItemLocalError({
      propName: "deadline",
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });

    handleItemLocalError({
      propName: "price",
      currentValue: price,
      message: I18n.t('price_required'),
    });

    if (title && price && deadline) {
      setTitle("");
      setDescription("");
      setPrice("");
      setDeadline("");
      setLocalErrors({});
      addFeature({ title, description, deadline, price });
    }
  }

  return (
    <section
      style={{
        display: featureForm === true ? "block" : "none",
      }}
      className="Service__Feature__Form"
    >
      {
        warning ? 
        <Alert variant={warningType}>
          {I18n.t(warning)}
        </Alert>
        : null
      }
      <TextInput
        required
        controlId="FeatureTitle"
        label="description"
        type="text"
        name="featureTitle"
        desc="feature_title_placeholder"
        value={title}
        onChange={handleTitle}
        onBlur={onDescriptionBlur}
        feedbackType="invalid"
        feedback={localErrors.description ? localErrors.description : null}
        isInvalid={localErrors.description}
      />

      <TextInput
        required
        controlId="FeatureDelivery"
        label={I18n.t("number_to_deliver_service") + " " + I18n.t("increase_by")}
        prepandText="day_s"
        type="number"
        name="featureDays"
        value={(Number(deadline) || '').toString()}
        onChange={handleDeadlineChange}
        onBlur={onDeadlineBlur}
        feedbackType="invalid"
        feedback={localErrors.deadline ? localErrors.deadline : null}
        isInvalid={localErrors.deadline}
      />

      <div className="row">
        <div className="col-md-6">
          <TextInput
            required
            controlId="FeaturePrice"
            label={I18n.t("service_price") + " " + I18n.t("increase_by")}
            prepandText="dollar"
            type="number"
            name="featurePrice"
            value={(Number(price) || '').toString()}
            onChange={handlePriceChange}
            onBlur={onPriceBlur}
            feedbackType="invalid"
            feedback={localErrors.price ? localErrors.price : returnedErrors.price ? returnedErrors.price[0] : null}
            isInvalid={(errorsId === ERROR_ACTION_TYPE && returnedErrors.price) || localErrors.price}
          />
        </div>

        <div className="col-md-6">
          <TextInput
            required={false}
            controlId="WillReceive"
            label="you_will_receive"
            type="text"
            name="title"
            disabled
            value={price - (price * 20) / 100}
            prepandText="dollar"
          />
        </div>
      </div>

      {/*<QuillBox
        controlId="FeatureDescription"
        label="feature_description"
        value={description}
        onChange={handleDescChange}
        required={false}
        placeholder="feature_desc_placeholder"
      />*/}

      <Button
        variant="secondary"
        type="button"
        onClick={handelAddFeature}
        data-backdrop="static"
      >
        <Translate value="add" />
      </Button>
    </section>
  );
}
