import { 
    GET_PAGES_LOADING,
    GET_PAGES_SUCCESS,
    GET_PAGES_FAIL 
} from "../actions/types";

const initialState = {
    pages: [],
    getPagesLoading: false,
}

export default function getpagesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PAGES_LOADING: 
            return{
                ...state,
                getPagesLoading: true
            }
        ;
        case GET_PAGES_SUCCESS:
            return{
                ...state,
                pages: action.payload.data,
                getPagesLoading: false
            }    
        ;
        case GET_PAGES_FAIL:
            return{
                ...state,
                getPagesLoading: false
            }
        ;
        default:
            return state;
    }
}