import TYPES from "./types";
import { axiosInstance } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_SERVICE_FEEDBACK_LOADING,
  GET_SERVICE_FEEDBACK_SUCCESS,
  GET_SERVICE_FEEDBACK_FAIL,
} = TYPES;

// Get Service Feedback

export function getServiceFeedback(id, page, limit) {
  return async function getServiceFeedbackWithDispatch(dispatch) {
    dispatch(clearErrors());
    dispatch({
      type: GET_SERVICE_FEEDBACK_LOADING,
    });
    try {
        let response = await axiosInstance.get(`${API.SERVICE_FEEDBACK}?id=${id}&page=${page}&limit=${limit}`);
        if(response.status !== 200){
            dispatch({
                type: GET_SERVICE_FEEDBACK_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_SERVICE_FEEDBACK_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_SERVICE_FEEDBACK_FAIL'));
            dispatch ({ 
                type: GET_SERVICE_FEEDBACK_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_SERVICE_FEEDBACK_FAIL')),
              dispatch ({ 
                  type: GET_SERVICE_FEEDBACK_FAIL 
              })
          )
        }
    }
  };
}
