import { 
    GET_RUNNING_INVOICES_LOADING, 
    GET_RUNNING_INVOICES_SUCCESS, 
    GET_RUNNING_INVOICES_FAIL,
    GET_CLOSED_INVOICES_LOADING, 
    GET_CLOSED_INVOICES_SUCCESS, 
    GET_CLOSED_INVOICES_FAIL,
    GET_CANCELLED_INVOICES_LOADING, 
    GET_CANCELLED_INVOICES_SUCCESS, 
    GET_CANCELLED_INVOICES_FAIL,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Running Invoices
export const getrunninginvoices = (service_id = null, id = null, page = null, limit = null, closed = null, running = null) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: GET_RUNNING_INVOICES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                `/api/services/invoices?service_id=${service_id ? service_id : ''}&id=${id ? id : ''}&page=${page ? page : ''}&limit=${limit ? limit : ''}&closed=${closed ? closed : ''}&running=${running ? running : ''}`, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_RUNNING_INVOICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_RUNNING_INVOICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_RUNNING_INVOICES_FAIL'));
                dispatch ({ 
                    type: GET_RUNNING_INVOICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_RUNNING_INVOICES_FAIL')),
                    dispatch ({ 
                        type: GET_RUNNING_INVOICES_FAIL 
                    })
                )
            }
        }
    }
};

// Closed Invoices
export const getclosedinvoices = (service_id = null, id = null, status_id = null,  page = null, limit = null, closed = null, running = null) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: GET_CLOSED_INVOICES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                `/api/services/invoices?service_id=${service_id ? service_id : ''}&status_id=${status_id ? status_id : ''}&id=${id ? id : ''}&page=${page ? page : ''}&limit=${limit ? limit : ''}&closed=${closed ? closed : ''}&running=${running ? running : ''}`, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_CLOSED_INVOICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_CLOSED_INVOICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_CLOSED_INVOICES_FAIL'));
                dispatch ({ 
                    type: GET_CLOSED_INVOICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_CLOSED_INVOICES_FAIL')),
                    dispatch ({ 
                        type: GET_CLOSED_INVOICES_FAIL 
                    })
                )
            }
        }
    }
};

// Closed Invoices
export const getcancelledinvoices = (service_id = null, id = null, status_id = null,  page = null, limit = null, closed = null, running = null) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: GET_CANCELLED_INVOICES_LOADING
        });
        try {
            let response = await axiosInstance.get(
                `/api/services/invoices?service_id=${service_id ? service_id : ''}&status_id=${status_id ? status_id : ''}&id=${id ? id : ''}&page=${page ? page : ''}&limit=${limit ? limit : ''}&closed=${closed ? closed : ''}&running=${running ? running : ''}`, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: GET_CANCELLED_INVOICES_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_CANCELLED_INVOICES_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_CANCELLED_INVOICES_FAIL'));
                dispatch ({ 
                    type: GET_CANCELLED_INVOICES_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_CANCELLED_INVOICES_FAIL')),
                    dispatch ({ 
                        type: GET_CANCELLED_INVOICES_FAIL 
                    })
                )
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }
    return config;

}