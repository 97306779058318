import {
    FREELANCER_PROFILE_SUCCESS,
    FREELANCER_PROFILE_FAIL,
    FREELANCER_PROFILE_LOADING
} from '../actions/types';

const initialState = {
    isLoading: false,
    freelancer: []
};

export default function freelancerprofileReducer(state = initialState, action) {
    switch(action.type){
        case FREELANCER_PROFILE_LOADING: 
            return{
                ...state,
                isLoading: true,
            };
        case FREELANCER_PROFILE_SUCCESS:
            return {
                ...state,
                freelancer: action.payload.data,
                isLoading: false
            };
        case FREELANCER_PROFILE_FAIL:
            return {
                ...state,
                isLoading: false,
        };
        default: 
            return state;
    };
};

