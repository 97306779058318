import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import {useHistory} from "react-router-dom";
import { Translate, I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "../TextInput";
import { SelectBox } from "../SelectBox";
import QuillBox from "../QuillBox";
import { CreatableKeywords } from "../CreatableKeywords";
import { clearErrors } from "../../actions/errorActions";
import { addArticle } from "../../redux/article/actions/addActions";
import { editArticle } from "../../redux/article/actions/editActions";
import { getArticleById } from "../../redux/article/actions/getActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { Uploader } from "../Upload";
import TYPES from "../../redux/article/actions/types";
import ErrorBox from "../ErrorBox";
import GRecaptch from "../GRecaptch";
import { deleteFile } from "../../actions/deletefileActions";
import { ServerURL } from "../Server";


const { ADD_ARTICLE_FAIL, EDIT_ARTICLE_FAIL } = TYPES;

export default function AddingArticleModal(props) {
  const dispatch = useDispatch();

  const {
    cats,
    errorsId,
    errorsMsg: { errors = {} },
    addedArticle,
    addingArticleSuccess,
    editingArticleSuccess,
  } = useSelector((state) => ({
    cats: state.category.cats,
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    articles: state.article.articles || [],
    user: state.auth.user,
    addedArticle: state.article.addedArticle,
    addingArticleSuccess: state.article.addingArticleSuccess,
    editingArticleSuccess: state.article.editingArticleSuccess,
  }));

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState({});
  const [image, setImage] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [localErrors, setLocalErrors] = useState({});
  const [keywords, setKeywords] = useState([]);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [imagesSortLength, setImagesSortLength] = useState(false);
  const [filteredErrorsLength, setFilteredErrorsLength] = useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [characterCount, setCharacterCount] = useState(0);
  const [titleCharacterCount, setTitleCharacterCount] = useState(0);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isHuman, setIsHuman] = useState(false);

  const [id, setId] = useState("");

  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_ARTICLE_FAIL);

  const filteredErrors = uploadErrors ? uploadErrors.reduce(
      (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
      [],
  ) : null;

  useEffect(()=> {
      if(filteredErrors.length > 0){
        setFilteredErrorsLength(true) 
      }
  }, [filteredErrors]);

  const {
    modalTitle,
    articleDetails,
    loading,
    params,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay
  } = props;

  const getCategories = useCallback(
    () => {
      if (cats) {
        return cats.map((item) => {
          return {
            ...item,
            value: item.title,
            label: item.title,
          };
        });
      }
    },
    [cats]
  );

  const categories = getCategories();

  const getSubCategories = useCallback(
    (catId) => {
      if (catId) {
        let targetCat = cats.find((item) => item.id === catId);
        let { sub_categories = [] } = targetCat || {};
        let subsCat = sub_categories.map((item) => ({
          ...item,
          value: item.title,
          label: item.title,
        }));
        return subsCat;
      }
      return [];
    },
    [cats]
  );

  const subCategories = getSubCategories(category.id);

  

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onTitleBlur(){
    if(title){
      if(title.replace(/<(.|\n)*?>/g, "").trim().length < 25){
        setLocalErrors((errors) => ({
          ...errors,
          title: I18n.t('title_limit'),
        }));
      }else{
        setLocalErrors((errors) => ({
          ...errors,
          title: undefined,
        }));
      }
    }else{
      setLocalErrors((errors) => ({
        ...errors,
        title: I18n.t('title_required'),
      }));
    }
  }

  function handleCatChange(e) {
    setCategory(e);
    setSubCategory(null);
  }

  function onCatBlur(){
    handleItemLocalError({
      propName: "category",
      currentValue: category,
      message: I18n.t('category_required'),
      uploadArray: false,
    });
  }

  function handleSubcatChange(e) {
    setSubCategory(e);
  }

  function onSubCatBlur(){
    handleItemLocalError({
      propName: "subCategory",
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });
  }

  useEffect(() => {
    if(description){
      setCharacterCount(description.replace(/<(.|\n)*?>/g, "").trim().length);
    }
  }, [description]);

  useEffect(() => {
    if(title){
      setTitleCharacterCount(title.replace(/<(.|\n)*?>/g, "").trim().length);
    }
  }, [title]);

  function handleDescriptionChange(value) {
    value = value || "";
    if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      setDescription("");
    } else {
      setDescription(value);
    }
  }

  function onDescriptionBlur(){
    if(description){
      if(description.replace(/<(.|\n)*?>/g, "").trim().length < 250){
        setLocalErrors((errors) => ({
          ...errors,
          description: I18n.t('description_limit'),
        }));
      }else{
        setLocalErrors((errors) => ({
          ...errors,
          description: undefined,
        }));
      }
    }else{
      setLocalErrors((errors) => ({
        ...errors,
        description: I18n.t('description_required'),
      }));
    }
  }

  function handleKeywordsChange(keywords) {
    setKeywords(keywords);
  }

  function onKeywordsBlur(){
    handleItemLocalError({
      propName: "keywords",
      currentValue: keywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });
  }

  const handleHide = useCallback(
    () => {
      setLocalErrors({});
      setUploadErrors([]);
      props.onHide();
      dispatch(clearErrors());
    },
    [props, dispatch]
  );

  function handleToast(showToast, title, message, status, delay){
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }
  
  function isObject(val) {
      return (typeof val === 'object');
  }
  function handleItemLocalError({ propName, currentValue, message, uploadArray }) {
    if(currentValue){
      if(isObject(currentValue) && !Array.isArray(currentValue)){
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
          }));
        }
      }else if(Array.isArray(currentValue) && uploadArray === true){
        if (currentValue.length <= 0) {
          const noImageError = {
              code: 'NoImage',
              message: message,
          };
          const filtered = uploadErrors.filter(function(value, index, arr){ 
              return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setUploadErrors(filtered);
        } else {
          const filtered = uploadErrors.filter(function(value, index, arr){ 
              return value.code !== 'NoImage';
          });
          setUploadErrors(filtered);
        }
      }else if(Array.isArray(currentValue) && uploadArray === false){
        if (currentValue.length <= 0) {
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
          }));
        }else{
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      }else{
        setLocalErrors((errors) => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    }else{
      setLocalErrors((errors) => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    
    onTitleBlur();

    handleItemLocalError({
      propName: "category",
      currentValue: category,
      message: I18n.t('category_required'),
      uploadArray: false,
    });

    handleItemLocalError({
      propName: "subCategory",
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });

    onDescriptionBlur();

    handleItemLocalError({
      propName: "image",
      currentValue: image,
      message: I18n.t('image_required'),
      uploadArray: true,
    });

    handleItemLocalError({
      propName: "keywords",
      currentValue: keywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });

    if (
      (fileUpload === false) &&
      (fileChosen === false) &&
      title &&
      description &&
      category &&
      subCategory && 
      image[0] &&
      keywords.length > 0 &&
      isHuman &&
      !localErrors.title &&
      !localErrors.description &&
      !localErrors.category &&
      !localErrors.subCategory &&
      !localErrors.image &&
      !localErrors.keywords
    ) {
      if(deletedFiles.length > 0){
        deletedFiles.map( item => {
          const model = "articles";
          const id = articleDetails ? articleDetails.id : null;
          const fileURL = item;
          const fileData = {
              model,
              id,
              fileURL
          };
          return dispatch(deleteFile(fileData));
        })
      }
      if(id){
        let itemData = {
          title,
          cat_id: category.id,
          sub_cat_id: subCategory.id,
          keywords,
          description,
          image: image[0] ? image[0].source : "",
          id
        };
        dispatch(editArticle(itemData));
      }else{
        let itemData = {
          title,
          cat_id: category.id,
          sub_cat_id: subCategory.id,
          keywords,
          description,
          image: image[0] ? image[0].source : "",
          id
        };
        dispatch(addArticle(itemData));
      }
    } else if(
      fileUpload === true ||
      fileChosen === true
    ) {
      handleToast(true, I18n.t('upload_notification_title'), I18n.t('upload_notification'), 'Toast__Container__Warning', 10000)
    } else if(
        !isHuman
    ) {
        handleToast(true, I18n.t('complete_required_info_title'), I18n.t('verify_robot'), 'Toast__Container__Error', 10000)
    } else{
        handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Error', 10000)
    }
  }

  useEffect(() => {
      if(image.length > 0){
          setImagesSortLength(true) 
      }
  }, [image]);

  useEffect(() => {
    if (articleDetails) {
      setErrorActionType(EDIT_ARTICLE_FAIL);
    }
  }, [articleDetails]);

  useEffect(() => {
    if (articleDetails) {
      setTitle(articleDetails.title);
      setDescription(articleDetails.description);
      let categories = getCategories();
      if(articleDetails.category){
          let category =
          categories.find(({ id }) => id === articleDetails.category.id) || [];
          setCategory(category);
          if(articleDetails.sub_category){
            let subCat = getSubCategories(articleDetails.category.id).filter(
              ({ id }) => id === articleDetails.sub_category.id
            );
            setSubCategory(subCat[0]);
          }
      }
      const articleKeywords = articleDetails.keywords ? articleDetails.keywords.split(',') : null;
      setKeywords(articleKeywords);
      if(isObject(articleDetails.image) && articleDetails.image.url.includes(`uploads/`)){
        let imageCopy = [];
        imageCopy[0] = {
          loaded: articleDetails.image.size,
          name: articleDetails.image.filename,
          origin: articleDetails.image.filename,
          path: articleDetails.image.filename,
          percent: 100,
          preview: articleDetails.image.url,
          size: articleDetails.image.size,
          source: articleDetails.image.url,
          total: articleDetails.image.size,
          type: `image/${articleDetails.image.extension}`
        };
        setImage(imageCopy);
      }else if(articleDetails.image.includes(`lancersin/public/`)){
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 2139,
          name: "no-image.png",
          origin: "no-image.png",
          path: "no-image.png",
          percent: 100,
          preview: articleDetails.image,
          size: 2139,
          source: articleDetails.image,
          total: 2139,
          type: "image/png"
        };
        setImage(imageCopy);
      }else{
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 100257,
          name: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
          origin: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
          path: "9adc8e7af80055c55ea1991f523f0268noImage.jpg",
          percent: 100,
          preview: `${ServerURL}/public/images/no-image.png`,
          size: 100257,
          source: `${ServerURL}/public/images/no-image.png`,
          total: 100257,
          type: "image/jpg"
        };
        setImage(imageCopy);
      }
      setId(articleDetails.id);
    }
  }, [articleDetails, getCategories, getSubCategories]);

  const history = useHistory();
  useEffect(() => {
    if (errorsId === null && loading === false && addingArticleSuccess === true && addedArticle.length >= 0){
      const newArticle = addedArticle[0];
      setDeletedFiles([]);
      if(params){
        const { id } = params;
        handleHide();
        const ItemData = {
          id,
        };
        dispatch(getArticleById(ItemData));
      }else{
        return (
        history.push(`/articles/${newArticle.id}/${newArticle.title
          .replace(/\s+/g, "-")
          .replace(/\//g, "-")
          .toLowerCase()}`)
        )
      }
    };
  }, [errorsId, loading, addingArticleSuccess, addedArticle, dispatch, handleHide, params, history]);
  useEffect(() => {
    if (errorsId === null && loading === false && editingArticleSuccess === true && addedArticle.length >= 0){
      setDeletedFiles([]);
      if(params){
        const { id } = params;
        handleHide();
        const ItemData = {
          id,
        };
        dispatch(getArticleById(ItemData));
      }
    };
  }, [errorsId, loading, editingArticleSuccess, addedArticle, dispatch, handleHide, params]);
  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props["aria-labelledby"]}
    >
      
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextInput
            required
            controlId="Title"
            label="title"
            type="text"
            name="title"
            feedbackType="invalid"
            onChange={onTitleChange}
            onBlur={onTitleBlur}
            isInvalid={(errorsId === ERROR_ACTION_TYPE && errors.title) || localErrors.title}
            value={title}
            minlength="25"
            characterCount={titleCharacterCount}
            feedback={ localErrors.title ? localErrors.title : errors.title ? errors.title[0] : null }
          />

          <div className="row">
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Category"
                name="category"
                label="category"
                options={categories}
                value={category}
                onChange={handleCatChange}
                onBlur={onCatBlur}
                feedback={localErrors.category ? localErrors.category : errors.cat_id ? errors.cat_id[0] : null}
                feedbackType="invalid"
                isInvalid={(errorsId === ERROR_ACTION_TYPE && errors.cat_id) || localErrors.category}
                placeholder={I18n.t("category")}
              />
            </div>
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Sub-Category"
                name="sub_category"
                label="sub_category"
                options={subCategories}
                value={subCategory}
                onChange={handleSubcatChange}
                onBlur={onSubCatBlur}
                feedback={localErrors.subCategory ? localErrors.subCategory : errors.sub_cat_id ? errors.sub_cat_id[0] : null}
                feedbackType="invalid"
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && errors.cat_id) ||
                  (errorsId === ERROR_ACTION_TYPE && errors.sub_cat_id) ||
                  localErrors.subCategory
                }
                placeholder={I18n.t("sub_category")}
                noOptionsMessage={Object.keys(category).length > 0 ? I18n.t('select_no_options') : I18n.t('choose_cat_first')}
              />
            </div>
          </div>

          <QuillBox
            required
            controlId="Description"
            label="description"
            value={description}
            onChange={handleDescriptionChange}
            onBlur={onDescriptionBlur}
            minlength="250"
            characterCount={characterCount}
            feedbackType="invalid"
            feedback={localErrors.description ? localErrors.description : errors.description ? errors.description[0] : null}
            isInvalid={(errorsId === ERROR_ACTION_TYPE && errors.description) || localErrors.description}
          />

          <CreatableKeywords
            onChange={handleKeywordsChange}
            feedback={localErrors.keywords ? localErrors.keywords : errors.keywords ? errors.keywords[0] : null}
            feedbackType="invalid"
            isInvalid={(errorsId === ERROR_ACTION_TYPE && errors.keywords) || localErrors.keywords}
            value={keywords}
            onBlur={onKeywordsBlur}
          />

          <Form.Group>
            <Form.Label>
              {I18n.t("article_image")}
              <span className="required" style={{ opacity: 1}}>
                *
              </span>
            </Form.Label>
            <Uploader 
              accept={[
                {
                  mime: "image/jpeg",
                  ext: "jpeg"
                }, 
                {
                  mime: "image/jpg",
                  ext: "jpg"
                }, 
                {
                  mime: "image/png",
                  ext: "png"
                }
              ]}
              maxFiles={1} 
              multiple={false}
              maxSize={1000000}
              sortable={false}
              setFilesChosen={setFileChosen}
              setFilesUpload={setFileUpload}
              setUploadErrors={setUploadErrors}
              uploadErrors={uploadErrors}
              setFilesSort={setImage}
              filesSort={image}
              filesSortLength={imagesSortLength}
              filteredErrorsLength={filteredErrorsLength}
              filteredErrors={filteredErrors}
              uploadPath="articles"
              projectId= {articleDetails ? articleDetails.id : null}
              deletedFiles={deletedFiles}
              setDeletedFiles={setDeletedFiles}
              optimize={1}
            />
          </Form.Group>
          {
            errorsId === "DELETE_FILE_FAIL" && deletedFiles.length > 0 ?
            <ErrorBox message={I18n.t('delete_file_error')} />
            : null
          }

          <GRecaptch
              setIsHuman={setIsHuman}
              className="mt-3"
          />

          {
            errorsId === "ADD_ARTICLE_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "ADD_ARTICLE_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : errorsId === "EDIT_ARTICLE_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "EDIT_ARTICLE_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : null
          }

        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
          variant="primary"
          type="submit"
          onClick={onSubmit}
          data-backdrop="static"
          loading={loading ? loading : null}
          >
              <Translate value="submit" />
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}
            disabled={loading}
          >
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
