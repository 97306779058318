import React from "react";
import { Translate } from "react-redux-i18n";

export default function AddingRow({
  onAdding,
  title,
  titleIconClassName,
  btnText,
  btnIconClassName,
  isClient,
  type,
  forceShowButton,
}) {
  return (
    <div className="row">
      <div className="col-12">
        <div className="section__title">
          <div className="section__title__text">
            {titleIconClassName && titleIconClassName.length > 0 ? (
              <span className={`icon ${titleIconClassName}`}></span>
            ) : null}
            <h1><Translate value={title} /></h1>
          </div>
          {
            onAdding && btnText && ((isClient && type === "projects") || (!isClient && type === "services")) ? (
              <button onClick={onAdding} className="btn">
                {btnIconClassName && btnIconClassName.length > 0 ? (
                  <span className={`icon ${btnIconClassName}`}></span>
                ) : null}
                {btnText && btnText.length > 0 ? (
                  <h2><Translate value={btnText} /></h2>
                ) : null}
              </button>
            ) 
            : null
          }
          {
            onAdding && btnText && forceShowButton ? (
              <button onClick={onAdding} className="btn">
                {btnIconClassName && btnIconClassName.length > 0 ? (
                  <span className={`icon ${btnIconClassName}`}></span>
                ) : null}
                {btnText && btnText.length > 0 ? (
                  <h2><Translate value={btnText} /></h2>
                ) : null}
              </button>
            )
            : null
          }
        </div>
      </div>
    </div>
  );
}
