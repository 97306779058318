import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {useDispatch} from 'react-redux';
import {clearErrors} from '../../actions/errorActions';

export default function CloseServiceModal(props) {
  const dispatch = useDispatch();
  const {modalTitle, loading, messageHeading, messageBody} = props;

  function handleHide() {
    props.onHide();
    dispatch(clearErrors());
  }
  function handleClose(e) {
    e.preventDefault();
    props.onClose();
  }

  const createCommentMarkup = comment => {
    return {__html: comment};
  };

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props['aria-labelledby']}>
      <Form>
        <Modal.Header>
          <Modal.Title>{I18n.t(modalTitle)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">{I18n.t(messageHeading)}</div>
          <div className="mt-3 text-center">
            {I18n.t('service_title')}:{' '}
            <span
              dangerouslySetInnerHTML={createCommentMarkup(messageBody)}></span>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
            variant="primary"
            type="submit"
            onClick={handleClose}
            data-backdrop="static"
            loading={loading ? loading : null}>
            {I18n.t('yes')}
          </ButtonWithLoading>
          <Button variant="danger" onClick={handleHide} disabled={loading}>
            {I18n.t('no')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
