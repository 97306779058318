import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import logo from '../../../imgs/logoOfSign.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors } from '../../../actions/errorActions';
import { Translate, I18n } from "react-redux-i18n";
import ButtonWithLoading from "../../Buttons/ButtonWithLoading";
import { sendVerification } from '../../../actions/verifyaccountActions';
import { resetPassword } from '../../../actions/resetpasswordActions';
import { RESET_VERIFICATION } from '../../../actions/types';
import GRecaptch from "../../GRecaptch";
const { Control } = Form;
const { Feedback } = Control;

export default function ForgotPassword (props) {
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [localErrors, setLocalErrors] = useState({});
    const [isHuman, setIsHuman] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const {
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay
    } = props;

    const {
        user,
        errorMsg: { errors = {} },
        errorId,
        sendVerificationLoading,
        sendVerificationSuccess,
        resetPasswordLoading,
        resetPasswordSuccess,
    } = useSelector((state) => ({
        user: state.auth.user,
        errorMsg: state.error.msg,
        errorId: state.error.id,
        sendVerificationLoading: state.verifyAccount.sendVerificationLoading,
        sendVerificationSuccess: state.verifyAccount.sendVerificationSuccess,
        resetPasswordLoading: state.resetPassword.resetPasswordLoading,
        resetPasswordSuccess: state.resetPassword.resetPasswordSuccess,
    }));

    const currentLocation = typeof window !== 'undefined' ? window.location.pathname : '/';

    useEffect(() => {
        if(currentLocation.includes("profile") && user){
            setEmail(user.email);
        }
    }, [currentLocation, user]);

    useEffect(() => {
        if(sendVerificationSuccess === "success"){
            setCodeSent(true);
        }
    }, [sendVerificationSuccess]);

    const onForgotHide = useCallback(() => {
        props.onForgotPasswordHide(false, null); 
        dispatch(clearErrors())
    }, [dispatch, props]);

    useEffect(() => {
        if(resetPasswordSuccess === "success"){
            onForgotHide(); 
            setConfShow(true);
            setConfTitle(I18n.t('reset_password'));
            setConfMessage(I18n.t('reset_password_success'));
            setConfStatus('Toast__Container__Success');
            setDelay(6000);
            dispatch({
                type: RESET_VERIFICATION
            });
            setCodeSent(false);
        }
    }, [resetPasswordSuccess, dispatch, onForgotHide, setConfShow, setConfMessage, setConfStatus, setConfTitle, setDelay]);

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
              ...errors,
              [propName]: undefined,
            }));
        }else{
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
          }));
        }
    }

    function onEmailChange(e){
        setEmail(e.target.value);
    }

    function onEmailBlur(){
        handleItemLocalError({
            propName: "email",
            currentValue: email,
            message: I18n.t('email_required'),
        });
    }

    function onCodeChange(e){
        setCode(e.target.value);
    }

    function onCodeBlur(){
        handleItemLocalError({
            propName: "code",
            currentValue: code,
            message: I18n.t('verification_required'),
        });
    }

    function onPasswordChange(e){
        setPassword(e.target.value);
    }

    function onPasswordBlur(){
        handleItemLocalError({
            propName: "password",
            currentValue: password,
            message: I18n.t('password_required'),
        });
    }

    function onRePasswordChange(e){
        setRepassword(e.target.value);
    }

    function onRePasswordBlur(){
        if(repassword){
            if(repassword !== password){
                setLocalErrors((errors) => ({
                    ...errors,
                    repassword: I18n.t('repassword_password_required'),
                }));
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    repassword: undefined,
                })); 
            }
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                repassword: I18n.t('repassword_required'),
            }));
        }
    }

    function handleToast(showToast, title, message, status, delay){
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    };

    function onSendCode(e){
        e.preventDefault();

        handleItemLocalError({
            propName: "email",
            currentValue: email,
            message: I18n.t('email_required'),
        });

        if(
            email &&
            isHuman
        ){
            const type = "verification-change-password";
            const itemData = {
                email,
                type
            };
            dispatch(clearErrors());
            dispatch(sendVerification(itemData));
        }else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
        
    };

    function onResetPassword(e){
        e.preventDefault();

        handleItemLocalError({
            propName: "code",
            currentValue: code,
            message: I18n.t('verification_required'),
        });

        handleItemLocalError({
            propName: "password",
            currentValue: password,
            message: I18n.t('password_required'),
        });
        if(repassword){
            if(repassword !== password){
                setLocalErrors((errors) => ({
                    ...errors,
                    repassword: I18n.t('repassword_password_required'),
                }));
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    repassword: undefined,
                })); 
            }
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                repassword: I18n.t('repassword_required'),
            }));
        }

        if(
            code &&
            password &&
            repassword &&
            isHuman
        ){
            const itemData = {
                verification_code: code,
                password,
                password_confirmation: repassword
            };
            dispatch(clearErrors());
            dispatch(resetPassword(itemData));
        }else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
        
    };

    return (
        <Fragment>
            <Modal className="CustomPopup" size="lg" show={props.forgotPasswordShow} onHide={() => {onForgotHide()}} aria-labelledby="example-modal-sizes-title-lg">
                <div className="popup__wrapper">
                    <div className="popup__wrapper__container">

                        <header>
                            <figure className="header__logo">
                                <img className="img-fluid" alt="" src={logo} />
                            </figure>
                            {
                                currentLocation.includes("profile") ?
                                <h1>{I18n.t('change_password')}</h1>
                                : <h1>{I18n.t('reset_password')}</h1>
                            }
                        </header>
                        {
                            codeSent ?
                                <form>
                                    <p className="text-center">{I18n.t('verification_sent')}</p>
                                    <div className="input__container">
                                        <div className="icon__container">
                                            <i className="icon-verify" />
                                        </div>
                                        <div className="dividerBTW" />
                                        <input 
                                            type="number" 
                                            name="code" 
                                            id="code" 
                                            placeholder={I18n.t('verfication_code')} 
                                            onChange={onCodeChange} 
                                            onBlur={onCodeBlur}
                                        />
                                    </div>
                                    {
                                        errors.verification_code ? 
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{errors.verification_code[0]}</Feedback>
                                        : localErrors.code ?
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{localErrors.code}</Feedback>
                                        :
                                        null
                                    }
                                    <div className="input__container">
                                        <div className="icon__container">
                                            <i className="icon-lock" />
                                        </div>
                                        <div className="dividerBTW" />
                                        <input 
                                            type="password" 
                                            name="password" 
                                            id="password" 
                                            placeholder={I18n.t('password')} 
                                            onChange={onPasswordChange} 
                                            onBlur={onPasswordBlur} 
                                        />
                                    </div>
                                    {
                                        errors.password ? 
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{errors.password[0]}</Feedback>
                                        : localErrors.password ?
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{localErrors.password}</Feedback>
                                        :
                                        null
                                    }

                                    <div className="input__container">
                                        <div className="icon__container">
                                            <i className="icon-lock" />
                                        </div>
                                        <div className="dividerBTW" />
                                        <input 
                                            type="password" 
                                            name="repassword" 
                                            id="repassword" 
                                            placeholder={I18n.t('repassword')} 
                                            onChange={onRePasswordChange} 
                                            onBlur={onRePasswordBlur} 
                                        />
                                    </div>
                                    {
                                        errors.password_confirmation ? 
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{errors.password_confirmation[0]}</Feedback>
                                        : localErrors.repassword ?
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{localErrors.repassword}</Feedback>
                                        :
                                        null
                                    }

                                    <GRecaptch
                                        setIsHuman={setIsHuman}
                                        className="mb-2"
                                    />

                                    <ButtonWithLoading
                                        variant="primary"
                                        type="submit"
                                        onClick={onResetPassword}
                                        data-backdrop="static"
                                        loading={resetPasswordLoading}
                                    >
                                        <Translate value="change_password" />
                                    </ButtonWithLoading>
                                </form>
                            :
                                <form>
                                    {
                                        currentLocation.includes("profile") ?
                                        null
                                        :
                                        <div className="input__container">
                                            <div className="icon__container">
                                                <i className="icon-envelope" />
                                            </div>
                                            <div className="dividerBTW" />
                                            <input 
                                                type="email" 
                                                name="email" 
                                                id="email" 
                                                placeholder={I18n.t('email_address')} 
                                                onChange={onEmailChange} 
                                                onBlur={onEmailBlur}
                                            />
                                        </div>
                                    }
                                    {
                                        errors && errorId === "SEND_VERIFICATION_FAIL" ? 
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{errors[0]}</Feedback>
                                        : localErrors.email ?
                                        <Feedback type="invalid" className="d-block mt-0 mb-2">{localErrors.email}</Feedback>
                                        :
                                        null
                                    }

                                    <GRecaptch
                                        setIsHuman={setIsHuman}
                                        className="mb-2"
                                    />

                                    <ButtonWithLoading
                                        variant="primary"
                                        type="submit"
                                        onClick={onSendCode}
                                        data-backdrop="static"
                                        loading={sendVerificationLoading}
                                    >
                                        <Translate value="send_code" />
                                    </ButtonWithLoading>
                                </form>
                        }
                    </div>
                </div>
            </Modal>
        </Fragment>
    );
}