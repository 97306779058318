// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_FOLLOWERS_LOADING,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  GET_FOLLOWINGS_LOADING,
  GET_FOLLOWINGS_SUCCESS,
  GET_FOLLOWINGS_FAIL,
} = TYPES;

// Get Followers

export function getFollowers(page = null, limit = null, user_id = null) {
  return async function getFollowersWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: GET_FOLLOWERS_LOADING,
    });
    try {
        let response = await axiosInstance
        .get(API.FOLLOWERS, {params: { page, limit, user_id } , ...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
                type: GET_FOLLOWERS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_FOLLOWERS_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_FOLLOWERS_FAIL'));
            dispatch ({ 
                type: GET_FOLLOWERS_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FOLLOWERS_FAIL')),
              dispatch ({ 
                  type: GET_FOLLOWERS_FAIL 
              })
          )
        }
    }
  };
}

// Get Followings

export function getFollowings(page = null, limit = null, user_id = null) {
  return async function getFollowingsWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: GET_FOLLOWINGS_LOADING,
    });
    try {
        let response = await axiosInstance
        .get(API.FOLLOWINGS, {params: { page, limit, user_id } , ...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
                type: GET_FOLLOWINGS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_FOLLOWINGS_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_FOLLOWINGS_FAIL'));
            dispatch ({ 
                type: GET_FOLLOWINGS_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FOLLOWINGS_FAIL')),
              dispatch ({ 
                  type: GET_FOLLOWINGS_FAIL 
              })
          )
        }
    }
  };
}

const exportedObject = {
  getFollowers,
  getFollowings
};

export default exportedObject;
