import { SET_LANGUAGE_ARABIC, SET_LANGUAGE_ENGLISH } from '../actions/types';
import { setLocale } from "react-redux-i18n";

const initialState = {
    langItem: typeof window !== 'undefined' && localStorage.getItem('lang'),
    lang: ''
}

export default function langReducer(state = initialState, action){
    switch (action.type){
        case SET_LANGUAGE_ARABIC:
            localStorage.setItem('lang', 'العربية');
            setLocale('ar');
            return {
                ...state,
                lang: 'العربية',
            };
        case SET_LANGUAGE_ENGLISH:
            localStorage.setItem('lang', 'English');
            setLocale('en');
            return {
                ...state,
                lang: 'English',
            };
        default: 
            return state;
    }
}