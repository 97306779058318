import { SET_LANGUAGE_ARABIC, SET_LANGUAGE_ENGLISH } from './types.js';
import { setLocale } from "react-redux-i18n";
import { supportedLocales, fallbackLocale } from "../config/i18n";

export const setLangEnglish = () => dispatch => {
    dispatch(
        {
            type: SET_LANGUAGE_ENGLISH,
        }
        
    );
};

export const setLangArabic = () => dispatch => {
    dispatch(
        {
            type: SET_LANGUAGE_ARABIC,
        }
    );
};

export function setLocaleWithFallback(desiredLocale) {
    const finalLocale = Object.keys(supportedLocales).includes(desiredLocale)
      ? desiredLocale
      : fallbackLocale;
    return dispatch => dispatch(setLocale(finalLocale));
}