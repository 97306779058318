import TYPES, { CLEAR_ADD_PROJECT_SUCCESS } from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";
import { CLEAR_FEEDBACKS } from "../../../redux/feedbacks/actions/types";

const {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,

  GET_PROJECT_BY_ID_LOADING,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_FAIL,

  GET_USER_PROJECTS_RECEIVING_BIDS_LOADING,
  GET_USER_PROJECTS_RECEIVING_BIDS_SUCCESS,
  GET_USER_PROJECTS_RECEIVING_BIDS_FAIL,
  GET_USER_PROJECTS_IN_PROGRESS_LOADING,
  GET_USER_PROJECTS_IN_PROGRESS_SUCCESS,
  GET_USER_PROJECTS_IN_PROGRESS_FAIL,
  GET_USER_PROJECTS_CLOSED_LOADING,
  GET_USER_PROJECTS_CLOSED_SUCCESS,
  GET_USER_PROJECTS_CLOSED_FAIL,
  GET_USER_PROJECTS_CANCELLED_LOADING,
  GET_USER_PROJECTS_CANCELLED_SUCCESS,
  GET_USER_PROJECTS_CANCELLED_FAIL,

  GET_PROJECTS_PRICES_LOADING,
  GET_PROJECTS_PRICES_SUCCESS,
  GET_PROJECTS_PRICES_FAIL,

  GET_PROJECT_BIDS_LOADING,
  GET_PROJECT_BIDS_SUCCESS,
  GET_PROJECT_BIDS_FAIL,

  GET_PROJECT_COMMENTS_LOADING,
  GET_PROJECT_COMMENTS_SUCCESS,
  GET_PROJECT_COMMENTS_FAIL,

  PROJECT_BID_REPORTS_LOADING,
  PROJECT_BID_REPORTS_SUCCESS,
  PROJECT_BID_REPORTS_FAIL,
} = TYPES;

// Get Projects

export function getProjects(user_id = null, id = null, page = null, limit = null, running = null, closed = null, price_id = null, min_days = null, max_days = null, cat_id = null, sub_cat_id = null, status_id = null, keywords = null, locale) {
  return async function getProjectsWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: CLEAR_FEEDBACKS
    });
    if(user_id && status_id === 8){
      dispatch({
          type: GET_USER_PROJECTS_RECEIVING_BIDS_LOADING
      });
    }else if(user_id && status_id === 5){
      dispatch({
        type: GET_USER_PROJECTS_IN_PROGRESS_LOADING
      });
    }else if(user_id && status_id === 6){
      dispatch({
        type: GET_USER_PROJECTS_CLOSED_LOADING
      });
    }else if(user_id && status_id === 7){
      dispatch({
        type: GET_USER_PROJECTS_CANCELLED_LOADING
      });
    }else if(id){
      dispatch({
          type: GET_PROJECT_BY_ID_LOADING
      });
      dispatch({
        type: CLEAR_ADD_PROJECT_SUCCESS
      });
    }else{
      dispatch({
        type: GET_PROJECTS_LOADING
      });
    }
    try {
        let response = await axiosInstance
        .get(API.PROJECTS, {params: { user_id, id, page, limit, running, closed, price_id, min_days, max_days, cat_id, sub_cat_id, status_id, keywords } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            if(user_id && status_id === 8 ){
              dispatch({
                type: GET_USER_PROJECTS_RECEIVING_BIDS_FAIL,
              });
            } else if(user_id && status_id === 5){
              dispatch({
                type: GET_USER_PROJECTS_IN_PROGRESS_FAIL,
              });
            }else if(user_id && status_id === 6){
              dispatch({
                type: GET_USER_PROJECTS_CLOSED_FAIL,
              });
            }else if(user_id && status_id === 7){
              dispatch({
                type: GET_USER_PROJECTS_CANCELLED_FAIL,
              });
            }else if(id){
              dispatch({
                type: GET_PROJECT_BY_ID_FAIL,
            });
            }else{
              dispatch({
                type: GET_PROJECTS_FAIL,
              });
            }
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
            if(user_id && status_id){
              if (status_id === 8) {
                dispatch({
                    type: GET_USER_PROJECTS_RECEIVING_BIDS_SUCCESS,
                    payload: response.data
                })
              } else if (status_id === 5) {
                dispatch({
                  type: GET_USER_PROJECTS_IN_PROGRESS_SUCCESS,
                  payload: response.data
                })
              } else if (status_id === 6){
                dispatch({
                  type: GET_USER_PROJECTS_CLOSED_SUCCESS,
                  payload: response.data
                })
              } else if (status_id === 7){
                dispatch({
                  type: GET_USER_PROJECTS_CANCELLED_SUCCESS,
                  payload: response.data
                })
              }
            }else if(id){
              dispatch({
                  type: GET_PROJECT_BY_ID_SUCCESS,
                  payload: response.data.data
              })
            }else{
              dispatch({
                type: GET_PROJECTS_SUCCESS,
                payload: response.data
              })
            }
        }
    } catch (error) {
        if (error.response && error.response.data) {
          if(user_id && status_id === 8 ){
            dispatch({
              type: GET_USER_PROJECTS_RECEIVING_BIDS_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_PROJECTS_RECEIVING_BIDS_FAIL'));
          } else if(user_id && status_id === 5){
            dispatch({
              type: GET_USER_PROJECTS_IN_PROGRESS_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_PROJECTS_IN_PROGRESS_FAIL'));
          }else if(user_id && status_id === 6){
            dispatch({
              type: GET_USER_PROJECTS_CLOSED_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_PROJECTS_CLOSED_FAIL'));
          }else if(user_id && status_id === 7){
            dispatch({
              type: GET_USER_PROJECTS_CANCELLED_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_PROJECTS_CANCELLED_FAIL'));
          }else if(id){
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PROJECT_BY_ID_FAIL'));
            dispatch ({ 
                type: GET_PROJECT_BY_ID_FAIL 
            });
          }else{
            dispatch({
              type: GET_PROJECTS_FAIL
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PROJECTS_FAIL'));
          }
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          if(user_id && status_id === 8 ){
            dispatch({
              type: GET_USER_PROJECTS_RECEIVING_BIDS_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_PROJECTS_RECEIVING_BIDS_FAIL'));
          } else if(user_id && status_id === 5){
            dispatch({
              type: GET_USER_PROJECTS_IN_PROGRESS_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_PROJECTS_IN_PROGRESS_FAIL'));
          }else if(user_id && status_id === 6){
            dispatch({
              type: GET_USER_PROJECTS_CLOSED_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_PROJECTS_CLOSED_FAIL'));
          }else if(user_id && status_id === 7){
            dispatch({
              type: GET_USER_PROJECTS_CANCELLED_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_PROJECTS_CANCELLED_FAIL'));
          }else if(id){
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PROJECT_BY_ID_FAIL'));
            dispatch ({ 
                type: GET_PROJECT_BY_ID_FAIL 
            })
          }else{
            dispatch({
              type: GET_PROJECTS_FAIL
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PROJECTS_FAIL'));
          }
        }
    }
  }
}

// Get Projects Prices

export function getProjectsPrices() {
  return async function getProjectsPricesWithDispatch(dispatch) {
    dispatch(clearErrors());
    dispatch({
      type: GET_PROJECTS_PRICES_LOADING,
    });
    try {
        let response = await axiosInstance.get(API.PROJECTS_PRICES);
        if(response.status !== 200){
            dispatch({
                type: GET_PROJECTS_PRICES_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_PROJECTS_PRICES_SUCCESS,
            payload: response.data.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PROJECTS_PRICES_FAIL'));
            dispatch ({ 
                type: GET_PROJECTS_PRICES_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PROJECTS_PRICES_FAIL')),
              dispatch ({ 
                  type: GET_PROJECTS_PRICES_FAIL 
              })
          )
        }
    }
  };
}

// Get Project Bids

export function getProjectBids(id, page, limit) {
  return async function getProjectBidsWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: GET_PROJECT_BIDS_LOADING,
    });
    try {
        let response = await axiosInstance.get(`${API.PROJECT_BIDS}?project_id=${id}&page=${page}&limit=${limit}`, tokenConfig(getState));
        if(response.status !== 200){
            dispatch({
                type: GET_PROJECT_BIDS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_PROJECT_BIDS_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PROJECT_BIDS_FAIL'));
            dispatch ({ 
                type: GET_PROJECT_BIDS_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PROJECT_BIDS_FAIL')),
              dispatch ({ 
                  type: GET_PROJECT_BIDS_FAIL 
              })
          )
        }
    }
  };
}

// Get Project Comments

export function getProjectComments(id = null, page = null, limit = null){
  return async function getProjectCommentsWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: GET_PROJECT_COMMENTS_LOADING,
    });
    try {
        let response = await axiosInstance.get(`/api/projects/comments?project_id=${id}&page=${page}&limit=${limit}`, tokenConfig(getState));
        if(response.status !== 200){
            dispatch({
                type: GET_PROJECT_COMMENTS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_PROJECT_COMMENTS_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PROJECT_COMMENTS_FAIL'));
            dispatch ({ 
                type: GET_PROJECT_COMMENTS_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PROJECT_COMMENTS_FAIL')),
              dispatch ({ 
                  type: GET_PROJECT_COMMENTS_FAIL 
              })
          )
        }
    }
  };
};

// Get Project Bid Reports
export function getBidReports(project_bid_id = null){
  return async function getBidReportsWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: PROJECT_BID_REPORTS_LOADING,
    });
    try {
        let response = await axiosInstance.get(`${API.BID_REPORTS}?project_bid_id=${project_bid_id}`, 
          tokenConfig(getState)
        );
        if(response.status !== 200){
            dispatch({
                type: PROJECT_BID_REPORTS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: PROJECT_BID_REPORTS_SUCCESS,
            payload: response.data
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'PROJECT_BID_REPORTS_FAIL'));
            dispatch ({ 
                type: PROJECT_BID_REPORTS_FAIL 
            });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'PROJECT_BID_REPORTS_FAIL')),
              dispatch ({ 
                  type: PROJECT_BID_REPORTS_FAIL 
              })
          )
        }
    }
  };
};

const exportedObject = {
  getProjects,
  getProjectsPrices,
  getProjectBids,
  getProjectComments,
  getBidReports
};

export default exportedObject;
