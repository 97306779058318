import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import ConnectionLost from '../ConnectionLost';
import {MetaComponent} from '../MetaComponent';
import MainSlider from './MainSlider';
import HowItWorks from './HowItWorks';
import WhyLancersin from './WhyLancersin';
import FAQ from './FAQ';
import Partners from './Partners';
import {TitleComponent} from '../TitleComponent';
import {I18n} from 'react-redux-i18n';

export default function HomeTwo() {
  const {errorMessage} = useSelector(state => ({
    errorMessage: state.error.msg,
  }));

  return errorMessage && errorMessage === 'Network Error' ? (
    <div className="container">
      <div className="row">
        <div className="col-12 Dashboard__tasks__List">
          <ConnectionLost
            errorType="connection"
            title="network_error_title"
            message="network_error_message"
          />
        </div>
      </div>
    </div>
  ) : (
    <Fragment>
      <TitleComponent title={I18n.t('lancersin_home_title')} />
      <MetaComponent />
      <MainSlider />
      <Partners />
      <HowItWorks />
      <WhyLancersin />
      <FAQ />
    </Fragment>
  );
}
