// Actions for Adding Behavior

import TYPES from "./types";
import { axiosInstance } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  SEND_CONTACT_LOADING,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAIL,
} = TYPES;

export const sendContact = (name = null, email = null, phone = null, type = null, subject = null, message = null) => {
  return async function sendContactWithDispatch(dispatch){
      dispatch(clearErrors());
      dispatch({
          type: SEND_CONTACT_LOADING
      });
      try {
            const body = {name, email, phone, type, subject, message};
          let response = await axiosInstance
          .post(API.CONTACT, body);
          if(response.status !== 200){
              dispatch({
                  type: SEND_CONTACT_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: SEND_CONTACT_SUCCESS,
                  payload: response.data.data
              })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'SEND_CONTACT_FAIL'));
            dispatch ({ 
                type: SEND_CONTACT_FAIL 
            });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'SEND_CONTACT_FAIL')),
                dispatch ({ 
                    type: SEND_CONTACT_FAIL 
                })
            )
          }
      }
  }
};

const exportedObject = {
    sendContact
};

export default exportedObject;
