import React from 'react';
import Carousel from 'react-slick';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {I18n} from 'react-redux-i18n';

export default function HomeSlider() {
  const {lang} = useSelector(state => ({
    lang: state.lang.lang,
  }));

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: lang === 'English' ? false : true,
  };

  return (
    <Carousel {...settings}>
      <div className="slideItem">
        <div className="slideItem__Content">
          <h2>{I18n.t('clients_tab')}</h2>
          <h1>{I18n.t('client_slide_project_head')}</h1>
          <p>{I18n.t('client_slide_project_desc')}</p>
          <Link
            to={'/projects/create'}
            aria-label={I18n.t('post_project')}
            className="btn btn-primary">
            <i className="icon icon-plus"></i>
            {I18n.t('post_project')}
          </Link>
        </div>
        <img
          src="images/projects.png"
          alt={I18n.t('client_slide_project_desc')}
          loading="lazy"
        />
      </div>
      <div className="slideItem">
        <div className="slideItem__Content">
          <h2>{I18n.t('clients_tab')}</h2>
          <h1>{I18n.t('client_slide_service_head')}</h1>
          <p>{I18n.t('client_slide_service_desc')}</p>
          <Link
            to={'/services'}
            aria-label={I18n.t('browse_services')}
            className="btn btn-primary">
            {I18n.t('browse_services')}
          </Link>
        </div>
        <img
          src="images/services.png"
          alt={I18n.t('client_slide_service_desc')}
          loading="lazy"
        />
      </div>
      <div className="slideItem">
        <div className="slideItem__Content">
          <h2>{I18n.t('freelancers_tab')}</h2>
          <h1>{I18n.t('freelancer_slide_project_head')}</h1>
          <p>{I18n.t('freelancer_slide_project_desc')}</p>
          <Link
            to={'/signup'}
            aria-label={I18n.t('signup')}
            className="btn btn-primary">
            {I18n.t('signup')}
          </Link>
        </div>
        <img
          src="images/projects-freelancer.png"
          alt={I18n.t('freelancer_slide_project_desc')}
          loading="lazy"
        />
      </div>
      <div className="slideItem">
        <div className="slideItem__Content">
          <h2>{I18n.t('freelancers_tab')}</h2>
          <h1>{I18n.t('freelancer_slide_service_head')}</h1>
          <p>{I18n.t('freelancer_slide_service_desc')}</p>
          <Link
            to={'/signup'}
            aria-label={I18n.t('signup')}
            className="btn btn-primary">
            {I18n.t('signup')}
          </Link>
        </div>
        <img
          src="images/services-freelancer.png"
          alt={I18n.t('freelancer_slide_service_desc')}
          loading="lazy"
        />
      </div>
    </Carousel>
  );
}
