import React, {useEffect, Fragment, useState, useCallback} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import Pagination from 'react-js-pagination';
import {useDispatch, useSelector} from 'react-redux';
import {I18n} from 'react-redux-i18n';
import {getProjectBids} from '../../redux/project/actions/getActions';
import {addProjectBid} from '../../redux/project/actions/addActions';
import {clearErrors} from '../../actions/errorActions';
import {Form, Col, Card, Button} from 'react-bootstrap';
import TextInput from '../TextInput';
import RatingStarsPreview from '../RatingStarsPreview';
import ErrorBox from '../ErrorBox';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import Spin from '../Spin';
import {truncate} from '../ReduceFileName';
import {Size} from '../CalculateSize';
import {Uploader} from '../Upload';
import ADD_PROJECT_BID_FAIL from '../../redux/project/actions/types';
import GRecaptch from '../GRecaptch';
import NoItems from '../NoItems';
import ReportContentModal from '../ReportContentModal';
import QuillBox from '../QuillBox';
import ProposalAdded from './ProposalAdded';
import ProposalLimit from './ProposalLimit';
import ConfirmProposalModal from './ConfirmProposalModal';
import {ServerURL} from '../Server';
import SendMessageModal from '../SendMessageModal';
import {getUserData} from '../../redux/userData/actions/getActions';

export default function ProjectProposals(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    user,
    getUserDataLoading,
    locale,
    isAuthenticated,
    // Project
    getProjectByIdLoading,

    // Project Bids
    projectBids,
    getProjectBidsLoading,

    // Add Project Bid
    addingProjectBidLoading,
    addingProjectBidSuccess,

    // Select Project Bid
    selectProjectBidLoading,
    selectProjectBidSuccess,
    totalRecords,
    itemsPerPage,

    loggedUser,

    postReportLoading,

    // errors
    errorsId,
    errorsMsg,

    //Send Message
    sendMessageLoading,

    lastNotification,
  } = useSelector(state => ({
    user: state.userData.user,
    getUserDataLoading: state.userData.getUserDataLoading,
    locale: state.i18n.locale,
    isAuthenticated: state.auth.isAuthenticated,
    getProjectByIdLoading: state.project.getProjectByIdLoading,

    projectBids: state.project.projectBids,
    getProjectBidsLoading: state.project.getProjectBidsLoading,

    addingProjectBidLoading: state.project.addingProjectBidLoading,
    addingProjectBidSuccess: state.project.addingProjectBidSuccess,

    selectProjectBidLoading: state.project.selectProjectBidLoading,
    selectProjectBidSuccess: state.project.selectProjectBidSuccess,
    totalRecords: state.project.totalBidsRecords,
    itemsPerPage: state.project.bidsPerPage,

    loggedUser: state.auth.user,

    postReportLoading: state.reportsTypes.isLoading,

    errorsId: state.error.id,
    errorsMsg: state.error.msg,

    sendMessageLoading: state.messages.sendMessageLoading,

    lastNotification: state.notifications.lastNotification,
  }));
  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);
  const {
    projectDetails,
    isOwnerOfThisProject,
    isOwnerOfSelectedBid,
    isProjectReceivingBids,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  const {price, id, made_bid} = projectDetails;

  const [activePage, setActivePage] = useState(1);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [proposals, setProposals] = useState(projectBids);
  const [proposalDeliverDays, setProposalDeliverDays] = useState('');
  const [proposalAmount, setProposalAmount] = useState('');
  const [proposalDescription, setProposalDescription] = useState('');
  const [localErrors, setLocalErrors] = useState({});
  //const [hideProposal, setHideProposal] = useState(false);
  const hideProposal = false;
  const [ERROR_ACTION_TYPE, setErrorActionType] =
    useState(ADD_PROJECT_BID_FAIL);
  const [returnedErrors, setReturnedErrors] = useState({});
  const [showReport, setShowReport] = useState(false);
  const [proposalId, setProposalId] = useState(null);
  const [proposalUserId, setProposalUserId] = useState(null);
  const [madeBid, setMadeBid] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState({});
  const [showProposalConfirm, setShowProposalConfirm] = useState(false);
  const handleProposalConfirmClose = () => setShowProposalConfirm(false);
  const handleProposalConfirmShow = content => {
    setSelectedProposal(content);
    setShowProposalConfirm(true);
  };

  const [files, setFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState(false);
  const [filesChosen, setFilesChosen] = useState(false);
  const [filesUploadErrors, setFilesUploadErrors] = useState([]);
  const [filesSortLength, setFilesSortLength] = useState(false);
  const [filteredFilesErrorsLength, setFilteredFilesErrorsLength] =
    useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);
  const [isHuman, setIsHuman] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const filteredFilesErrors = filesUploadErrors
    ? filesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  useEffect(() => {
    if (filteredFilesErrors.length > 0) {
      setFilteredFilesErrorsLength(true);
    }
  }, [filteredFilesErrors]);

  useEffect(() => {
    if (made_bid || addingProjectBidSuccess) {
      setMadeBid(true);
    }
    if (addingProjectBidSuccess && isAuthenticated) {
      dispatch(getUserData(locale));
    }
  }, [addingProjectBidSuccess, made_bid, isAuthenticated, locale, dispatch]);

  useEffect(() => {
    if (files.length > 0) {
      setFilesSortLength(true);
    }
  }, [files]);

  useEffect(() => {
    if (projectDetails && !getProjectByIdLoading) {
      if (isProjectReceivingBids) {
        const page = activePage;
        const limit = 10;
        dispatch(getProjectBids(id, page, limit));
      } else if (!isOwnerOfThisProject && !isOwnerOfSelectedBid) {
        const page = activePage;
        const limit = 10;
        dispatch(getProjectBids(id, page, limit));
      }
    }
  }, [
    projectDetails,
    dispatch,
    getProjectByIdLoading,
    id,
    isOwnerOfThisProject,
    isOwnerOfSelectedBid,
    isProjectReceivingBids,
    activePage,
  ]);

  useEffect(() => {
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'CreateBid' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id === id
    ) {
      const page = 1;
      const limit = 10;
      dispatch(getProjectBids(id, page, limit));
      setActivePage(1);
    }
  }, [lastNotification, dispatch, id]);

  useEffect(() => {
    if (projectBids?.length > 0) {
      setProposals(projectBids);
    }
  }, [projectBids]);

  useEffect(() => {
    if (errorsId && errorsMsg) {
      setErrorActionType(errorsId);
      setReturnedErrors(errorsMsg.errors);
    }
  }, [errorsId, errorsMsg]);

  const getMaxPrice = str => {
    const value = str.split('-')[1];
    return Number(value.replace(/\s+/g, ' ').trim());
  };
  const getMinPrice = str => {
    const value = str.split('-')[0];
    return Number(value.replace(/\s+/g, ' ').trim());
  };

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
  }

  useEffect(() => {
    if (selectProjectBidSuccess) {
      setShowProposalConfirm(false);
    }
  }, [selectProjectBidSuccess]);

  function handleShowMessage(proposal) {
    setProposalId(proposal.id);
    setProposalUserId(proposal.user.id);
    setShowMessageModal(true);
  }
  function handleHideMessage() {
    setProposalId(null);
    setShowMessageModal(false);
  }

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    if (addingProjectBidSuccess) {
      setProposalDeliverDays('');
      setProposalAmount('');
      setProposalDescription('');
      setLocalErrors({});
      setFilesChosen(false);
      setFilesUpload(false);
      setFilesUploadErrors([]);
      setFiles([]);
      setFilesSortLength(false);
      setFilteredFilesErrorsLength(false);
      setDeletedFiles([]);
      dispatch(clearErrors());
      handleToast(
        true,
        I18n.t('thank_you'),
        I18n.t('proposal_success'),
        'Toast__Container__Success',
        6000,
      );
    }
  }, [addingProjectBidSuccess, dispatch, handleToast, id]);

  function handleItemLocalError({propName, currentValue, message}) {
    if (currentValue) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function setAmountError(val) {
    const proposalMaxPrice = getMaxPrice(price.title);
    const proposalMinPrice = getMinPrice(price.title);
    if (val === '') {
      return I18n.t('bid_amount_required');
    }
    if (val > proposalMaxPrice) {
      return (
        I18n.t('amount_less') + ' ' + proposalMaxPrice + ' ' + I18n.t('dollar')
      );
    }
    if (val < proposalMinPrice) {
      return (
        I18n.t('amount_greater') +
        ' ' +
        proposalMinPrice +
        ' ' +
        I18n.t('dollar')
      );
    }
    return null;
  }

  function onEstimateBlur() {
    handleItemLocalError({
      propName: 'estimateError',
      currentValue: proposalDeliverDays,
      message: I18n.t('estimate_required'),
    });
  }

  function onAmountBlur() {
    const newObj = setAmountError(proposalAmount);
    if (newObj) {
      setLocalErrors(errors => ({
        ...errors,
        amountError: newObj,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        amountError: undefined,
      }));
    }
  }

  function handleDescriptionChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setProposalDescription('');
    } else {
      setProposalDescription(value);
    }
  }

  const createMarkup = text => {
    return {__html: text};
  };

  function onDescriptionBlur() {
    handleItemLocalError({
      propName: 'descriptionError',
      currentValue: proposalDescription,
      message: I18n.t('description_required'),
    });
  }

  function handleProposalSubmit(e) {
    e.preventDefault();

    const newObj = setAmountError(proposalAmount);
    if (newObj) {
      setLocalErrors(errors => ({
        ...errors,
        amountError: newObj,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        amountError: undefined,
      }));
    }

    handleItemLocalError({
      propName: 'estimateError',
      currentValue: proposalDeliverDays,
      message: I18n.t('estimate_required'),
    });

    handleItemLocalError({
      propName: 'descriptionError',
      currentValue: proposalDescription,
      message: I18n.t('description_required'),
    });
    let data = {
      deadline: proposalDeliverDays,
      price: proposalAmount,
      description: proposalDescription,
      files: files.map(item => item.source),
      hide: hideProposal === true ? 1 : 0,
      project_id: id,
    };

    if (
      filesUpload === false &&
      filesChosen === false &&
      proposalDeliverDays &&
      proposalAmount &&
      setAmountError(proposalAmount) === null &&
      proposalDescription &&
      isHuman
    ) {
      dispatch(addProjectBid(data));
    } else if (filesUpload === true || filesChosen === true) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        10000,
      );
    }
  }

  function onSignInClick() {
    history.push(`/signin?path=${location.pathname}`);
  }

  function onSignUpClick() {
    history.push(`/signup?path=${location.pathname}`);
  }

  // function handleHideProposal(){
  //     setHideProposal(!hideProposal);
  // }
  useEffect(() => {
    if (redirect) {
      window.location.replace(
        `${ServerURL}/payment?payable_type=project&payable_id=${selectedProposal.id}&lang=${locale}`,
      );
    }
  }, [redirect, selectedProposal, locale]);

  function handlePickProposal() {
    setRedirect(true);
  }

  function renderProposals() {
    if (getProjectBidsLoading || selectProjectBidLoading) {
      return <Spin />;
    }

    if (errorsId === 'GET_PROJECT_BIDS_FAIL') {
      return <ErrorBox message={I18n.t('something_went_wrong')} />;
    }

    if (proposals.length > 0) {
      return proposals.map(proposal => renderProposal(proposal));
    }

    if (isOwnerOfThisProject) {
      return (
        <NoItems
          title="no_proposals_title"
          message="no_proposals_owner_message"
          className="No__Shadow"
        />
      );
    }

    return (
      <NoItems
        title="no_proposals_title"
        message="no_proposals_message"
        className="No__Shadow"
      />
    );
  }

  function renderProposal(proposal) {
    const {user, files} = proposal;
    return (
      <div className="ProjectDetails__Proposal__Card" key={proposal.id}>
        <header className="d-flex flex-wrap justify-content-between">
          <div className="ProfileData mb-2">
            <div className="ProfileImage">
              <figure>
                <img
                  src={user.image.url ? user.image.url : user.image}
                  alt={user.fname + ' ' + user.lname}
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="ProfileContent">
              <div className="ProfileContent__Details">
                {user.country ? (
                  <address>
                    <span className="LocationFlag">
                      <img src={user.country.flag} alt={user.country.name} />
                    </span>
                  </address>
                ) : null}
                <h2 className="ProfileName">
                  <Link to={{pathname: `/in/${user.username}`}}>
                    {user.fname} {user.lname}
                  </Link>
                  <RatingStarsPreview rate={user.rate} />
                </h2>
              </div>
              <div className="ProfileContent__Cost">
                <div className="ProfileContent__Cost__Price">
                  {proposal.price}
                  <span>{I18n.t('dollar')}</span>
                </div>
                <div className="ProfileContent__Cost__Days">
                  / {proposal.deadline} {I18n.t('day_s') + ' - '}
                </div>
                <time className="Time">
                  <span className="icon icon-calender-time"></span>{' '}
                  {proposal.created_at.Date} - {proposal.created_at.Time}
                </time>
              </div>
              {/*<div className="ProfileContent__Cats">
                        <ul>
                            <li>
                            <span className="icon icon-business-case"></span> Design art &
                            multimedia
                            </li>
                            <li>
                            <span className="icon icon-business-case"></span> Website &
                            Application Development
                            </li>
                        </ul>
                        </div>*/}
            </div>
          </div>
          {loggedUser.id !== proposal.user.id ||
          (isOwnerOfThisProject && isProjectReceivingBids) ? (
            <div className="ProposalActions">
              {isOwnerOfThisProject && isProjectReceivingBids ? (
                <Fragment>
                  <Button
                    onClick={() => handleProposalConfirmShow(proposal)}
                    variant="primary"
                    className="mb-2">
                    <span className="icon icon-proposals-sent"></span>
                    {I18n.t('pick_proposal')}
                  </Button>
                  <Button
                    onClick={() => {
                      handleShowMessage(proposal);
                    }}
                    variant="secondary"
                    className="mx-2 mb-2">
                    <span className="icon icon-envelope"></span>
                    {I18n.t('send_inquiry')}
                  </Button>
                </Fragment>
              ) : null}
              {isAuthenticated ? (
                <Button
                  onClick={() => handleShowReport(proposal.id)}
                  variant="secondary"
                  className="mb-2">
                  <span className="far fa-flag"></span>
                  {I18n.t('report_content')}
                </Button>
              ) : null}
            </div>
          ) : null}
        </header>
        <div className="ProjectDetails__Proposal__Content">
          <div
            dangerouslySetInnerHTML={createMarkup(proposal.description)}></div>
          {files.length > 0 ? (
            <div className="ProjectDetails__Proposal__Content__attachments">
              <h3>{I18n.t('attachments')}:</h3>
              <ul>
                {files.map((file, index) => (
                  <li key={index}>
                    <a
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer">
                      <span className="fas fa-paperclip"></span>
                      {truncate(file.filename, 20)}
                    </a>
                    <span className="file__size ms-1">({Size(file.size)})</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  function handleShowReport(id) {
    setProposalId(id);
    setShowReport(true);
  }
  function handleHideReport() {
    setProposalId(null);
    setShowReport(false);
  }
  return (
    <Fragment>
      <SendMessageModal
        onHide={handleHideMessage}
        show={showMessageModal}
        modalTitle={
          I18n.t('inquiry_about') +
          ' ' +
          I18n.t('your_proposal') +
          ' ' +
          projectDetails.title
        }
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={sendMessageLoading}
        morphType="project_bids"
        morphID={proposalId}
        userID={proposalUserId}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <ConfirmProposalModal
        onHide={handleProposalConfirmClose}
        onChoose={handlePickProposal}
        show={showProposalConfirm}
        modalTitle={'select_bid'}
        className="CustomPopup"
        size="md"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={selectProjectBidLoading}
        messageHeading="select_bid_conf"
        selectedProposal={selectedProposal}
      />
      <ReportContentModal
        onHide={handleHideReport}
        show={showReport}
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={postReportLoading}
        model="project_bids"
        model_id={proposalId}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      {!isOwnerOfThisProject && isProjectReceivingBids ? (
        <Card>
          <Card.Header>
            <div>
              <span className="icon icon-proposals-limit"></span>{' '}
              {I18n.t('submit_proposal')}
            </div>
          </Card.Header>
          <Card.Body>
            <div className="row">
              {getUserDataLoading ? (
                <Spin />
              ) : madeBid ? (
                <div className="col-12">
                  <ProposalAdded />
                </div>
              ) : user &&
                user.bids &&
                Object.keys(user.bids).length > 0 &&
                user.bids.remaining <= 0 ? (
                <div className="col-12">
                  <ProposalLimit />
                </div>
              ) : isAuthenticated ? (
                <Fragment>
                  <div className="col-12">
                    <div className="alert alert-danger" role="alert">
                      {I18n.t('bids_warning')}
                    </div>
                  </div>
                  <div className="col-12">
                    {errorsId === 'ADD_PROJECT_BID_FAIL' &&
                    Array.isArray(returnedErrors) ? (
                      <ErrorBox message={returnedErrors[0]} />
                    ) : null}
                    <Form>
                      <Form.Row>
                        <Col md={4}>
                          <TextInput
                            required
                            type="number"
                            controlId="estimatedTime"
                            label={I18n.t('number_to_deliver')}
                            value={proposalDeliverDays}
                            onChange={e =>
                              setProposalDeliverDays(e.target.value)
                            }
                            onBlur={onEstimateBlur}
                            isInvalid={
                              (errorsId === ERROR_ACTION_TYPE && returnedErrors
                                ? returnedErrors.deadline
                                  ? true
                                  : false
                                : false) || localErrors.estimateError
                            }
                            feedback={
                              localErrors.estimateError
                                ? localErrors.estimateError
                                : returnedErrors
                                ? returnedErrors.deadline
                                  ? returnedErrors.deadline[0]
                                  : null
                                : null
                            }
                            prepandText={I18n.t('day_s')}
                            inputExtraProps={{
                              min: 1,
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <TextInput
                            required
                            type="number"
                            controlId="budget"
                            label={I18n.t('bid_amount')}
                            value={proposalAmount}
                            onChange={e => setProposalAmount(e.target.value)}
                            prepandText={I18n.t('dollar')}
                            onBlur={onAmountBlur}
                            isInvalid={
                              (errorsId === ERROR_ACTION_TYPE && returnedErrors
                                ? returnedErrors.price
                                  ? true
                                  : false
                                : false) || localErrors.amountError
                            }
                            feedback={
                              localErrors.amountError
                                ? localErrors.amountError
                                : returnedErrors
                                ? returnedErrors.price
                                  ? returnedErrors.price[0]
                                  : null
                                : null
                            }
                            inputExtraProps={{
                              min: 1,
                            }}
                          />
                        </Col>
                        <Col md={4}>
                          <TextInput
                            name="price"
                            controlId="ServiceWillReceive"
                            label={I18n.t('you_will_receive')}
                            type="number"
                            value={proposalAmount - (proposalAmount * 20) / 100}
                            prepandText={I18n.t('dollar')}
                            required={false}
                            disabled
                          />
                        </Col>
                      </Form.Row>
                      <QuillBox
                        required
                        controlId="coverLetter"
                        label="bid_description"
                        value={proposalDescription}
                        onChange={handleDescriptionChange}
                        onBlur={onDescriptionBlur}
                        feedbackType="invalid"
                        feedback={
                          localErrors.descriptionError
                            ? localErrors.descriptionError
                            : returnedErrors
                            ? returnedErrors.description
                              ? returnedErrors.description[0]
                              : null
                            : null
                        }
                        isInvalid={
                          (errorsId === ERROR_ACTION_TYPE && returnedErrors
                            ? returnedErrors.description
                              ? true
                              : false
                            : false) || localErrors.descriptionError
                        }
                      />
                      {/*<Form.Row>
                                                <Col>
                                                <Form.Check 
                                                    type="switch"
                                                    id="hideProposal"
                                                    label={I18n.t('hide_proposal')}
                                                    onChange={handleHideProposal}
                                                />
                                                <small>{I18n.t('hide_proposal_desc')}</small>
                                                </Col>
                                            </Form.Row>*/}
                      <Form.Row>
                        <Col>
                          <Form.Group controlId="ProposalAttachments">
                            <Form.Label>{I18n.t('upload_files')}</Form.Label>
                            <Uploader
                              accept={[
                                {
                                  mime: 'application/msword',
                                  ext: 'doc',
                                },
                                {
                                  mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                  ext: 'docx',
                                },
                                {
                                  mime: 'application/vnd.ms-powerpoint',
                                  ext: 'ppt',
                                },
                                {
                                  mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                  ext: 'pptx',
                                },
                                {
                                  mime: 'application/vnd.ms-excel',
                                  ext: 'xls',
                                },
                                {
                                  mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                  ext: 'xlsx',
                                },
                                {
                                  mime: 'application/pdf',
                                  ext: 'pdf',
                                },
                                {
                                  mime: 'image/jpeg',
                                  ext: 'jpeg',
                                },
                                {
                                  mime: 'image/gif',
                                  ext: 'gif',
                                },
                                {
                                  mime: 'image/png',
                                  ext: 'png',
                                },
                              ]}
                              maxFiles={10}
                              multiple={true}
                              maxSize={1000000}
                              sortable={false}
                              setFilesChosen={setFilesChosen}
                              setFilesUpload={setFilesUpload}
                              setUploadErrors={setFilesUploadErrors}
                              uploadErrors={filesUploadErrors}
                              setFilesSort={setFiles}
                              filesSort={files}
                              filesSortLength={filesSortLength}
                              filteredErrorsLength={filteredFilesErrorsLength}
                              filteredErrors={filteredFilesErrors}
                              uploadPath="project_bids"
                              projectId={
                                projectDetails ? projectDetails.id : null
                              }
                              deletedFiles={deletedFiles}
                              setDeletedFiles={setDeletedFiles}
                              optimize={1}
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row className="mb-4">
                        <Col>
                          <GRecaptch setIsHuman={setIsHuman} className="mt-3" />
                        </Col>
                      </Form.Row>
                      <ButtonWithLoading
                        loading={addingProjectBidLoading}
                        variant="primary"
                        type="submit"
                        onClick={handleProposalSubmit}>
                        {I18n.t('submit_the_proposal')}
                      </ButtonWithLoading>
                    </Form>
                  </div>
                </Fragment>
              ) : (
                <div className="col-12">
                  <div className="SignTo">
                    <span className="icon icon-lock"></span>
                    <h4>{I18n.t('join_bids')}</h4>
                    <p>
                      {I18n.t('add_bid_lock', {
                        fname: projectDetails.user.fname,
                        lname: projectDetails.user.lname,
                      })}
                    </p>
                    <div className="SignTo__Actions">
                      <button
                        className="btn btn-primary"
                        onClick={onSignInClick}>
                        <span className="icon icon-user"></span>{' '}
                        {I18n.t('signin')}
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={onSignUpClick}>
                        <span className="icon icon-add-user"></span>{' '}
                        {I18n.t('signup')}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      ) : null}
      <Card>
        <Card.Header>
          <div>
            <span className="icon icon-proposals-sent"></span>{' '}
            {I18n.t('proposals')}
          </div>
        </Card.Header>
        <Card.Body>
          <div className="row">
            {errorsId === 'SELECT_PROJECT_BID_FAIL' ? (
              <div className="col-12">
                <ErrorBox message={I18n.t('something_went_wrong')} />
              </div>
            ) : null}
            <div className="col-12">
              {renderProposals()}
              {proposals.length > 0 && getProjectBidsLoading === false ? (
                <div className="col-12 my-4">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={10}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              ) : null}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Fragment>
  );
}
