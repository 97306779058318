import TYPES from "../actions/types";

const INITIAL_STATE = {
  // Followers
  getFollowersLoading: false,
  getFollowersError: "",
  followers: [],
  totalFollowers: 0,
  followersPerPage: 0,
  // Followings
  getFollowingsLoading: false,
  getFollowingsError: "",
  followings: [],
  totalFollowings: 0,
  followingsPerPage: 0,
};

const {
  // Followers
  GET_FOLLOWERS_LOADING,
  GET_FOLLOWERS_SUCCESS,
  GET_FOLLOWERS_FAIL,
  // Followings
  GET_FOLLOWINGS_LOADING,
  GET_FOLLOWINGS_SUCCESS,
  GET_FOLLOWINGS_FAIL,
} = TYPES;

export default function followersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get Followers
    case GET_FOLLOWERS_LOADING:
      return {
        ...state,
        getFollowersLoading: true,
        getFollowersError: "",
      };
    case GET_FOLLOWERS_SUCCESS:
      return {
        ...state,
        getFollowersLoading: false,
        getFollowersError: "",
        followers: action.payload.data,
        totalFollowers: action.payload.totalRecords,
        followersPerPage: action.payload.itemsPerPage,
      };
    case GET_FOLLOWERS_FAIL:
      return {
        ...state,
        getFollowersLoading: false,
        getFollowersError: action.error,
      };
    // Get Followings
    case GET_FOLLOWINGS_LOADING:
      return {
        ...state,
        getFollowingsLoading: true,
        getFollowingsError: "",
      };
    case GET_FOLLOWINGS_SUCCESS:
      return {
        ...state,
        getFollowingsLoading: false,
        getFollowingsError: "",
        followings: action.payload.data,
        totalFollowings: action.payload.totalRecords,
        followingsPerPage: action.payload.itemsPerPage,
      };
    case GET_FOLLOWINGS_FAIL:
      return {
        ...state,
        getFollowingsLoading: false,
        getFollowingsError: action.error,
      };
    default:
      return state;
  }
}
