import React from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {useDispatch, useSelector} from 'react-redux';
import {clearErrors} from '../../actions/errorActions';
import {Link} from 'react-router-dom';
import RatingStarsPreview from '../RatingStarsPreview';
import {truncate} from '../ReduceFileName';
import {Size} from '../CalculateSize';
import ErrorBox from '../ErrorBox';

export default function ConfirmProposalModal(props) {
  const dispatch = useDispatch();

  const {
    // errors
    errorStatus,
    errorsId,
  } = useSelector(state => ({
    errorStatus: state.error.status,
    errorsId: state.error.id,
  }));

  const {modalTitle, loading, messageHeading, selectedProposal} = props;

  function handleHide() {
    props.onHide();
    dispatch(clearErrors());
  }
  function handleChoose(e) {
    e.preventDefault();
    props.onChoose();
  }

  const createMarkup = text => {
    return {__html: text};
  };
  const {user, files} = selectedProposal;
  if (selectedProposal && Object.keys(selectedProposal).length > 0) {
    return (
      <Modal
        className={props.className}
        size={props.size}
        show={props.show}
        onHide={props.onHide}
        backdrop={props.backdrop}
        keyboard={props.keyboard}
        aria-labelledby={props['aria-labelledby']}>
        <Form>
          <Modal.Header>
            <Modal.Title>{I18n.t(modalTitle)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(errorStatus === 403 || errorStatus === 401) &&
            errorsId === 'SELECT_PROJECT_BID_FAIL' ? (
              <ErrorBox message={I18n.t('authentication_error')} />
            ) : errorsId === 'SELECT_PROJECT_BID_FAIL' ? (
              <ErrorBox message={I18n.t('something_went_wrong')} />
            ) : null}
            <h4 className="mb-3">{I18n.t(messageHeading)}</h4>
            <div className="ProjectDetails__Proposal__Card">
              <header>
                <div className="ProfileImage">
                  <figure>
                    <img
                      src={user.image.url ? user.image.url : user.image}
                      alt={user.fname + ' ' + user.lname}
                      className="img-fluid"
                    />
                  </figure>
                </div>
                <div className="ProfileContent">
                  <div className="ProfileContent__Details">
                    {user.country ? (
                      <address>
                        <span className="LocationFlag">
                          <img
                            src={user.country.flag}
                            alt={user.country.name}
                          />
                        </span>
                      </address>
                    ) : null}
                    <h2 className="ProfileName">
                      <Link to={{pathname: `/in/${user.username}`}}>
                        {user.fname} {user.lname}
                      </Link>
                      <RatingStarsPreview rate={user.rate} />
                    </h2>
                  </div>
                  <div className="ProfileContent__Cost">
                    <div className="ProfileContent__Cost__Price">
                      {selectedProposal.price} <span>{I18n.t('dollar')}</span>
                    </div>
                    <div className="ProfileContent__Cost__Days">
                      / {selectedProposal.deadline} {I18n.t('day_s') + ' - '}
                    </div>
                    <time className="Time">
                      <span className="icon icon-calender-time"></span>{' '}
                      {selectedProposal.created_at.Date} -{' '}
                      {selectedProposal.created_at.Time}
                    </time>
                  </div>
                  {/*<div className="ProfileContent__Cats">
                                    <ul>
                                    <li>
                                        <span className="icon icon-business-case"></span> Design art &
                                        multimedia
                                    </li>
                                    <li>
                                        <span className="icon icon-business-case"></span> Website &
                                        Application Development
                                    </li>
                                    </ul>
                                </div>*/}
                </div>
              </header>
              <div className="ProjectDetails__Proposal__Content">
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    selectedProposal.description,
                  )}></div>
                {files.length > 0 ? (
                  <div className="ProjectDetails__Proposal__Content__attachments">
                    <h3>{I18n.t('attachments')}:</h3>
                    <ul>
                      {files.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="fas fa-paperclip"></span>
                            {truncate(file.filename, 20)}
                          </a>
                          <span className="file__size ms-1">
                            ({Size(file.size)})
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <ButtonWithLoading
              variant="secondary"
              type="submit"
              onClick={handleChoose}
              data-backdrop="static"
              className="btn btn-secondary"
              loading={loading ? loading : null}>
              {I18n.t('yes')}
            </ButtonWithLoading>
            <Button variant="danger" onClick={handleHide} disabled={loading}>
              {I18n.t('no')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  } else {
    return null;
  }
}
