// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  SEND_MESSAGE_LOADING,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAIL,
} = TYPES;

// Send Message

export function postMessage(message = null, to = null, morphType = null, morphID = null, files = null, locale) {
  return async function postMessageWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: SEND_MESSAGE_LOADING
    });
    try {
        const body = {message, to, morphType, morphID, files};
        let response = await axiosInstance
        .post(API.MESSAGES, body , tokenConfig(getState, locale));
        if(response.status !== 200){
            dispatch({
              type: SEND_MESSAGE_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: SEND_MESSAGE_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'SEND_MESSAGE_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: SEND_MESSAGE_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'SEND_MESSAGE_FAIL'));
        }
    }
  }
}

//Reply To Admin
export function adminReply(message = null, conversation_id = null, files = null, locale) {
  return async function adminReplyWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: SEND_MESSAGE_LOADING
    });
    try {
        const body = {message, conversation_id, files};
        let response = await axiosInstance
        .post(API.REPLYADMIN, body , tokenConfig(getState, locale));
        if(response.status !== 200){
            dispatch({
              type: SEND_MESSAGE_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: SEND_MESSAGE_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: SEND_MESSAGE_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'SEND_MESSAGE_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: SEND_MESSAGE_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'SEND_MESSAGE_FAIL'));
        }
    }
  }
}
