import React, { useState, useEffect, Fragment, useCallback } from "react";
import CreatableInput from "../CreatableInput";
import { axiosInstance } from '../../axiosInstance';
import { GET_SKILLS_FAIL } from '../../actions/types';
import { returnErrors } from "../../actions/errorActions";
import { useDispatch } from "react-redux";


export function CreatableSkills({
  onChange,
  onBlur,
  isInvalid,
  feedback,
  required = true,
  value,
}) {

  const dispatch = useDispatch();

  const [userSkills, setUserSkills] = useState([]);

  const getSkills = useCallback(() => {
    axiosInstance.get('/api/skills')
    .then(res => 
      setUserSkills(res.data.data)
    )
    .catch(error => {
      if (error instanceof Error) {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_SKILLS_FAIL')),
              dispatch ({ 
                  type: GET_SKILLS_FAIL 
              })
          )
      }else{
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_SKILLS_FAIL'));
          dispatch ({ 
              type: GET_SKILLS_FAIL 
          });
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getSkills();
  }, [getSkills]);

  const options = mapSkills(userSkills);
  value = mapSkills(value || []);

  function mapSkills(skills) {
    return skills.map((skill) => ({ label: skill, value: skill }));
  }

  function unmapSkills(skills) {
    return skills.map((skill) => skill.value);
  }

  function handleSkillsChange(skills) {
    onChange && onChange(unmapSkills(skills));
  }
  return (
    <Fragment>
      <CreatableInput
        isMulti
        required={required}
        name="skills"
        controlId="Skills"
        label="skills"
        hint="skills_desc"
        onBlur={onBlur}
        onChange={handleSkillsChange}
        feedback={feedback}
        feedbackType="invalid"
        placeholder="add_skills"
        isInvalid={isInvalid}
        options={options}
        value={value}
      />
    </Fragment>
  );
}
