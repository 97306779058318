import React, {useState, Fragment, useEffect, useCallback} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Modal, Button, Form, Table} from 'react-bootstrap';
import arrayMove from 'array-move';
import {Translate, I18n} from 'react-redux-i18n';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {useDispatch, useSelector} from 'react-redux';
import {clearErrors} from '../../actions/errorActions';
import {addserviceitem} from '../../actions/addserviceActions';
import TextInput from '../TextInput';
import {SelectBox} from '../SelectBox';
import QuillBox from '../QuillBox';
import AddingFeature from '../AddingFeature';
import {getfreelancerServiceItem} from '../../actions/freelancerserviceitemActions';
import {deleteServiceFeature} from '../../actions/deleteserviceActions';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {Uploader} from '../Upload';
import {ADD_SERVICE_FAIL} from '../../actions/types';
import ErrorBox from '../ErrorBox';
import GRecaptch from '../GRecaptch';
import {CreatableKeywords} from '../CreatableKeywords';
import {deleteFile} from '../../actions/deletefileActions';
import {ServerURL} from '../Server';

// function createMarkup(text) {
//   return { __html: text };
// }

export default function AddingServiceModal(props) {
  const dispatch = useDispatch();

  const {
    serviceItem,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
  } = props;

  const {
    cats,
    errorsId,
    errorsMsg: {returnedErrors = {}},
    isLoading,
    serviceaddedItem,
    addServiceSuccess,
    editServiceSuccess,
  } = useSelector(state => ({
    cats: state.category.cats || [],
    errorsId: state.error.id,
    errorsMsg: state.error.msg,
    isLoading: state.addserviceitem.isLoading,
    serviceaddedItem: state.addserviceitem.serviceItem,
    addServiceSuccess: state.addserviceitem.addServiceSuccess,
    editServiceSuccess: state.addserviceitem.editServiceSuccess,
  }));

  const [category, setCategory] = useState({});
  const [subCategory, setSubCategory] = useState({});
  const [video, setVideo] = useState('');
  const [videos, setVideos] = useState([]);
  const [featureButton, setFeatureButton] = useState(false);
  const [featureForm, setFeatureForm] = useState(false);
  const [requirments, setRequirments] = useState('');
  const [serviceKeywords, setServiceKeywords] = useState([]);
  const [publish, setPublish] = useState(true);
  const [isHuman, setIsHuman] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [titleCharacterCount, setTitleCharacterCount] = useState(0);

  const [features, setFeatures] = useState([]);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [deadline, setDeadline] = useState('');
  const [id, setId] = useState('');
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_SERVICE_FAIL);

  const [image, setImage] = useState([]);
  const [fileUpload, setFileUpload] = useState(false);
  const [fileChosen, setFileChosen] = useState(false);
  const [localErrors, setLocalErrors] = useState({});
  const [uploadErrors, setUploadErrors] = useState([]);
  const [imageSortLength, setImageSortLength] = useState(false);
  const [filteredErrorsLength, setFilteredErrorsLength] = useState(false);
  const [deletedFile, setDeletedFile] = useState([]);

  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState(false);
  const [imagesChosen, setImagesChosen] = useState(false);
  const [imagesUploadErrors, setImagesUploadErrors] = useState([]);
  const [imagesSortLength, setImagesSortLength] = useState(false);
  const [filteredImagesErrorsLength, setFilteredImagesErrorsLength] =
    useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const filteredErrors = uploadErrors
    ? uploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  const filteredImagesErrors = imagesUploadErrors
    ? imagesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  useEffect(() => {
    if (filteredErrors.length > 0) {
      setFilteredErrorsLength(true);
    }
  }, [filteredErrors]);

  useEffect(() => {
    if (errorsId && returnedErrors) {
      setErrorActionType(errorsId);
    }
  }, [errorsId, returnedErrors]);

  useEffect(() => {
    if (filteredImagesErrors.length > 0) {
      setFilteredImagesErrorsLength(true);
    }
  }, [filteredImagesErrors]);

  useEffect(() => {
    if (image.length > 0) {
      setImageSortLength(true);
    }
  }, [image]);

  useEffect(() => {
    if (images.length > 0) {
      setImagesSortLength(true);
    }
  }, [images]);

  const {modalTitle = ''} = props;

  const getCategories = useCallback(() => {
    if (cats) {
      return cats.map(item => {
        return {
          ...item,
          value: item.title,
          label: item.title,
        };
      });
    }
    return [];
  }, [cats]);

  const categories = getCategories();

  const catId = category.id;

  const getSubCategories = useCallback(
    catId => {
      if (catId) {
        let targetCat = cats.find(item => item.id === catId);
        let {sub_categories = []} = targetCat || {};
        let subsCat = sub_categories.map(item => ({
          ...item,
          value: item.title,
          label: item.title,
        }));
        return subsCat;
      }
      return [];
    },
    [cats],
  );

  const subCategories = getSubCategories(catId);

  function handledeleteVideo(e) {
    const newVideos = videos.filter((i, index) => index !== e);
    setVideos(newVideos);
  }

  //Sorting Videos
  const SortableVideoItem = SortableElement(({value, id}) => (
    <tr className="ListItemDragabolRow">
      <td>{id + 1}</td>
      <td>{value}</td>
      <td>
        <button
          className="deleteItem"
          type="button"
          onClick={() => handledeleteVideo(id)}>
          <span className="icon icon-delete"></span>
          <Translate value="delete" />
        </button>
      </td>
    </tr>
  ));

  const SortableVideoList = SortableContainer(({videos_sort}) => {
    return (
      <tbody className="ListDragabolTable">
        {videos_sort.map((value, index) => (
          <SortableVideoItem
            key={index}
            index={index}
            value={value}
            id={index}
          />
        ))}
      </tbody>
    );
  });

  function onTitleChange(e) {
    setTitle(e.target.value);
  }

  function onTitleBlur() {
    if (title) {
      if (title.replace(/<(.|\n)*?>/g, '').trim().length < 25) {
        setLocalErrors(errors => ({
          ...errors,
          title: I18n.t('title_limit'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          title: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        title: I18n.t('title_required'),
      }));
    }
  }

  function handleCatChange(e) {
    setCategory(e);
    setSubCategory(null);
  }

  function onCatBlur() {
    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });
  }

  function handleSubcatChange(e) {
    setSubCategory(e);
  }

  function onSubCatBlur() {
    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });
  }

  useEffect(() => {
    if (title) {
      setTitleCharacterCount(title.replace(/<(.|\n)*?>/g, '').trim().length);
    }
  }, [title]);

  useEffect(() => {
    if (description) {
      setCharacterCount(description.replace(/<(.|\n)*?>/g, '').trim().length);
    }
  }, [description]);

  function handleDescriptionChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setDescription('');
    } else {
      setDescription(value);
    }
  }

  function onDescriptionBlur() {
    if (description) {
      if (description.replace(/<(.|\n)*?>/g, '').trim().length < 250) {
        setLocalErrors(errors => ({
          ...errors,
          description: I18n.t('description_limit'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          description: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        description: I18n.t('description_required'),
      }));
    }
  }

  function handleRequirementsChange(value) {
    value = value || '';

    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setRequirments('');
    } else {
      setRequirments(value);
    }
  }

  function handleVideo(e) {
    setVideo(e.target.value);
  }

  function isYoutubeVideo(link) {
    return link.match(
      /\/\/(?:www\.)?youtu(?:\.be|be\.com)\/(?:watch\?v=|embed\/)?([a-z0-9_-]+)/i,
    );
  }

  function isVimeoVideo(link) {
    return link.match(/\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i);
  }

  function isVideoLink(link) {
    return isYoutubeVideo(link) || isVimeoVideo(link);
  }

  function handelAddVideoTo(e) {
    e.preventDefault();
    var url = isVideoLink(video);

    if (url) {
      setVideos([...videos, video]);
      setLocalErrors(errors => ({
        ...errors,
        video: undefined,
      }));
      setVideo('');
    } else {
      setLocalErrors(errors => ({
        ...errors,
        video: I18n.t('valid_video'),
      }));
    }
  }

  function handleDeadline(e) {
    e.preventDefault();
    setDeadline(Math.abs(e.target.value));
  }

  function onDeadlineBlur() {
    handleItemLocalError({
      propName: 'deadline',
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });
  }

  function handlePrice(e) {
    e.preventDefault();
    setPrice(Math.abs(e.target.value));
  }

  function onPriceBlur() {
    handleItemLocalError({
      propName: 'price',
      currentValue: price,
      message: I18n.t('price_required'),
      //type: "price",
      //typeMessage: I18n.t('service_price_limit'),
    });
  }

  function onSortVideosEnd({oldIndex, newIndex}) {
    setVideos(videos => arrayMove(videos, oldIndex, newIndex));
  }

  function handleKeywordsChange(keywords) {
    setServiceKeywords(keywords);
  }

  function onKeywordsBlur() {
    handleItemLocalError({
      propName: 'keywords',
      currentValue: serviceKeywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });
  }

  function togglePublish() {
    setPublish(!publish);
  }

  function handleFeatureForm() {
    setFeatureForm(!featureForm);
    setFeatureButton(!featureButton);
  }

  function handelAddFeature(feature) {
    setFeatures([feature, ...features]);
  }

  function handledeleteFeature(item, e) {
    if (item.id) {
      const {id} = item;
      dispatch(deleteServiceFeature({id}));
      const newFeatures = features.filter((i, index) => index !== e);
      setFeatures(newFeatures);
    } else {
      const newFeatures = features.filter((i, index) => index !== e);
      setFeatures(newFeatures);
    }
  }

  const handleHide = useCallback(() => {
    setLocalErrors({});
    props.onHide && props.onHide();
    dispatch(clearErrors());
  }, [dispatch, props]);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  function isObject(val) {
    return typeof val === 'object';
  }
  function handleItemLocalError({
    propName,
    currentValue,
    message,
    uploadArray,
    type,
    typeMessage,
  }) {
    if (currentValue) {
      if (isObject(currentValue) && !Array.isArray(currentValue)) {
        if (Object.keys(currentValue).length > 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        }
      } else if (Array.isArray(currentValue) && uploadArray === true) {
        if (currentValue.length <= 0) {
          const noImageError = {
            code: 'NoImage',
            message: message,
          };
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          filtered.push(noImageError);
          setUploadErrors(filtered);
        } else {
          const filtered = uploadErrors.filter(function (value, index, arr) {
            return value.code !== 'NoImage';
          });
          setUploadErrors(filtered);
        }
      } else if (Array.isArray(currentValue) && uploadArray === false) {
        if (currentValue.length <= 0) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: message,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else if (type === 'price') {
        if (currentValue > 5) {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: typeMessage,
          }));
        } else {
          setLocalErrors(errors => ({
            ...errors,
            [propName]: undefined,
          }));
        }
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onSubmit(e) {
    e.preventDefault();

    onTitleBlur();

    handleItemLocalError({
      propName: 'category',
      currentValue: category,
      message: I18n.t('category_required'),
    });

    handleItemLocalError({
      propName: 'subCategory',
      currentValue: subCategory,
      message: I18n.t('subcategory_required'),
    });

    onDescriptionBlur();

    handleItemLocalError({
      propName: 'image',
      currentValue: image,
      message: I18n.t('image_required'),
      uploadArray: true,
    });

    handleItemLocalError({
      propName: 'deadline',
      currentValue: deadline,
      message: I18n.t('deadline_required'),
    });

    handleItemLocalError({
      propName: 'price',
      currentValue: price,
      message: I18n.t('price_required'),
      //type: "price",
      //typeMessage: I18n.t('service_price_limit'),
    });

    handleItemLocalError({
      propName: 'keywords',
      currentValue: serviceKeywords,
      message: I18n.t('keywords_required'),
      uploadArray: false,
    });

    Array.prototype.equals = function (array) {
      // if the other array is a falsy value, return
      if (!array) return false;

      // compare lengths - can save a lot of time
      if (this.length !== array.length) return false;

      for (var i = 0, l = this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
          // recurse into the nested arrays
          if (!this[i].equals(array[i])) return false;
        } else if (this[i] !== array[i]) {
          // Warning - two different object instances will never be equal: {x:20} != {x:20}
          return false;
        }
      }
      return true;
    };
    // Hide method from for-in loops
    Object.defineProperty(Array.prototype, 'equals', {enumerable: false});

    if (
      fileUpload === false &&
      fileChosen === false &&
      imagesUpload === false &&
      imagesChosen === false &&
      title &&
      description &&
      deadline &&
      category &&
      subCategory &&
      image.length > 0 &&
      price && // price <= 10
      isHuman &&
      !localErrors.title &&
      !localErrors.category &&
      !localErrors.subCategory &&
      !localErrors.description &&
      !localErrors.image &&
      !localErrors.deadline &&
      !localErrors.price &&
      !localErrors.keywords
    ) {
      if (deletedFiles.length > 0) {
        deletedFiles.map(item => {
          const model = 'services';
          const id = serviceItem ? serviceItem.id : null;
          const fileURL = item;
          const fileData = {
            model,
            id,
            fileURL,
          };
          return dispatch(deleteFile(fileData));
        });
      }
      let images_sort = [];
      let keywords = serviceKeywords;
      const array1 = serviceItem
        ? serviceItem.images.map(item => item.url)
        : [];
      const array2 = images.map(item => item.preview);
      if (images.length > 0) {
        array1.equals(array2)
          ? (images_sort = [])
          : (images_sort = images.map(item => item.source));
      }
      let itemData = {
        title,
        price,
        description,
        requirments,
        deadline,
        publish: publish === true ? 1 : 0,
        cat_id: category.id,
        sub_cat_id: subCategory.id,
        images_sort,
        keywords,
        image: image[0].source,
        images: images.map(item => item.source),
        videos,
        features,
        id,
      };
      dispatch(addserviceitem(itemData));
    } else if (
      fileUpload === true ||
      fileChosen === true ||
      imagesUpload === true ||
      imagesChosen === true
    ) {
      handleToast(
        true,
        I18n.t('upload_notification_title'),
        I18n.t('upload_notification'),
        'Toast__Container__Warning',
        6000,
      );
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Error',
        10000,
      );
    }
  }
  const history = useHistory();
  // useEffect(() => {
  //   if (errorsId === null && isLoading === false && Object.keys(serviceaddedItem).length !== 0){
  //     setDeletedFiles([]);
  //     const id = serviceaddedItem.id;
  //     const ItemData = {
  //       id
  //     };
  //     return (
  //     history.push(`/services/${serviceaddedItem.id}/${serviceaddedItem.title
  //       .replace(/\s+/g, "-")
  //       .replace(/\//g, "-")
  //       .toLowerCase()}`),
  //     dispatch(getfreelancerServiceItem(ItemData)),
  //     handleHide()
  //     )
  //   };
  // },[serviceaddedItem, dispatch, errorsId, handleHide, history, isLoading]);

  useEffect(() => {
    if (
      errorsId === null &&
      isLoading === false &&
      Object.keys(serviceaddedItem).length !== 0 &&
      (editServiceSuccess || addServiceSuccess)
    ) {
      setDeletedFiles([]);
      const id = serviceaddedItem.id;
      const ItemData = {
        id,
      };
      return (
        history.push(
          `/services/${serviceaddedItem.id}/${serviceaddedItem.title
            .replace(/\s+/g, '-')
            .replace(/\//g, '-')
            .toLowerCase()}`,
        ),
        dispatch(getfreelancerServiceItem(ItemData)),
        handleHide()
      );
    }
  }, [
    serviceaddedItem,
    dispatch,
    errorsId,
    handleHide,
    history,
    isLoading,
    editServiceSuccess,
    addServiceSuccess,
  ]);

  useEffect(() => {
    if (errorsId === 'DELETE_FILE_FAIL') {
      handleToast(
        true,
        I18n.t('delete_file'),
        I18n.t('delete_file_error'),
        'Toast__Container__Error',
        6000,
      );
    }
  }, [errorsId, handleToast]);

  useEffect(() => {
    if (serviceItem) {
      setTitle(serviceItem.title);
      setId(serviceItem.id);
      setPrice(serviceItem.price);
      setDescription(serviceItem.description);
      setRequirments(serviceItem.requirments);
      setDeadline(serviceItem.deadline);
      setPublish(serviceItem.publish === 1 ? true : false);
      if (serviceItem.category && serviceItem.category.id) {
        let categories = getCategories();
        let category =
          categories.find(({id}) => id === serviceItem.category.id) || [];
        setCategory(category);
        if (serviceItem.sub_category) {
          let subCat = getSubCategories(serviceItem.category.id).filter(
            ({id}) => id === serviceItem.sub_category.id,
          );
          setSubCategory(subCat[0]);
        }
      }
      setServiceKeywords(serviceItem.keywords);
      if (
        isObject(serviceItem.image) &&
        serviceItem.image.url.includes(`uploads/`)
      ) {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: serviceItem.image.size,
          name: serviceItem.image.filename,
          origin: serviceItem.image.filename,
          path: serviceItem.image.filename,
          percent: 100,
          preview: serviceItem.image.url,
          size: serviceItem.image.size,
          source: serviceItem.image.url,
          total: serviceItem.image.size,
          type: `image/${serviceItem.image.extension}`,
        };
        setImage(imageCopy);
      } else if (serviceItem.image.includes(`lancersin/public/`)) {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 2139,
          name: 'no-image.png',
          origin: 'no-image.png',
          path: 'no-image.png',
          percent: 100,
          preview: serviceItem.image,
          size: 2139,
          source: serviceItem.image,
          total: 2139,
          type: 'image/png',
        };
        setImage(imageCopy);
      } else {
        let imageCopy = [];
        imageCopy[0] = {
          loaded: 100257,
          name: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          origin: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          path: '9adc8e7af80055c55ea1991f523f0268noImage.jpg',
          percent: 100,
          preview: `${ServerURL}/public/images/no-image.png`,
          size: 100257,
          source: `${ServerURL}/public/images/no-image.png`,
          total: 100257,
          type: 'image/jpg',
        };
        setImage(imageCopy);
      }
      setImages(
        serviceItem.images.map(item => ({
          loaded: item.size,
          name: item.filename,
          origin: item.filename,
          path: item.filename,
          percent: 100,
          preview: item.url,
          size: item.size,
          source: item.url,
          total: item.size,
          type: `image/${item.extension}`,
        })),
      );
      setVideos(serviceItem.videos);
      setFeatures(serviceItem.features);
    }
  }, [serviceItem, getCategories, getSubCategories]);

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props['aria-labelledby']}>
      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div
                className="alert alert-warning mb-4 d-flex justify-content-between align-items-center flex-wrap"
                role="alert">
                <p className="my-1">{I18n.t('watch_service_video') + ' '}</p>
                <a
                  href="https://youtu.be/YEFeOtnQdZ0"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="videoBTn btn-success btn-sm my-1">
                  <i className="fas fa-play"></i> {I18n.t('watch_video')}
                </a>
              </div>
            </div>
            <div className="col-12">
              <div className="alert alert-danger" role="alert">
                {I18n.t('adding_warning')}
              </div>
            </div>
          </div>
          <TextInput
            required
            controlId="Title"
            label={I18n.t('service_title')}
            type="text"
            name="title"
            onChange={onTitleChange}
            onBlur={onTitleBlur}
            value={title}
            minlength="25"
            characterCount={titleCharacterCount}
            desc="service_title_placeholder"
            feedbackType="invalid"
            feedback={
              localErrors.title
                ? localErrors.title
                : returnedErrors.title
                ? returnedErrors.title[0]
                : null
            }
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.title) ||
              localErrors.title
            }
          />

          <div className="row">
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Category"
                name="category"
                label={I18n.t('category')}
                options={categories}
                value={category}
                onChange={handleCatChange}
                onBlur={onCatBlur}
                feedbackType="invalid"
                feedback={
                  localErrors.category
                    ? localErrors.category
                    : returnedErrors.cat_id
                    ? returnedErrors.cat_id[0]
                    : null
                }
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && returnedErrors.cat_id) ||
                  localErrors.category
                }
                placeholder={I18n.t('category')}
              />
            </div>
            <div className="col-md-6">
              <SelectBox
                required
                controlId="Sub-Category"
                label={I18n.t('sub_category')}
                options={subCategories}
                value={subCategory}
                onChange={handleSubcatChange}
                onBlur={onSubCatBlur}
                feedbackType="invalid"
                feedback={
                  localErrors.subCategory
                    ? localErrors.subCategory
                    : returnedErrors.sub_cat_id
                    ? returnedErrors.sub_cat_id[0]
                    : null
                }
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && returnedErrors.cat_id) ||
                  (errorsId === ERROR_ACTION_TYPE &&
                    returnedErrors.sub_cat_id) ||
                  localErrors.subCategory
                }
                placeholder={I18n.t('sub_category')}
                noOptionsMessage={
                  Object.keys(category).length > 0
                    ? I18n.t('select_no_options')
                    : I18n.t('choose_cat_first')
                }
              />
            </div>
          </div>

          <TextInput
            name="deadline"
            onChange={handleDeadline}
            onBlur={onDeadlineBlur}
            controlId="ServiceDelivery"
            label={I18n.t('number_to_deliver_service')}
            desc="buyer_can_cancel_service"
            type="number"
            value={(Number(deadline) || '').toString()}
            required
            feedbackType="invalid"
            feedback={
              localErrors.deadline
                ? localErrors.deadline
                : returnedErrors.deadline
                ? returnedErrors.deadline[0]
                : null
            }
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.deadline) ||
              localErrors.deadline
            }
            prepandText={I18n.t('day_s')}
          />

          <div className="row">
            <div className="col-md-6">
              <TextInput
                name="price"
                onChange={handlePrice}
                onBlur={onPriceBlur}
                controlId="ServicePrice"
                label={I18n.t('service_price')}
                type="number"
                value={(Number(price) || '').toString()}
                required
                feedbackType="invalid"
                feedback={
                  localErrors.price
                    ? localErrors.price
                    : returnedErrors.price
                    ? returnedErrors.price[0]
                    : null
                }
                isInvalid={
                  (errorsId === ERROR_ACTION_TYPE && returnedErrors.price) ||
                  localErrors.price
                }
                prepandText={I18n.t('dollar')}
              />
            </div>
            <div className="col-md-6">
              <TextInput
                name="price"
                controlId="ServiceWillReceive"
                label={I18n.t('you_will_receive')}
                type="number"
                value={price - (price * 20) / 100}
                prepandText={I18n.t('dollar')}
                required={false}
                disabled
              />
            </div>
          </div>

          <QuillBox
            required
            controlId="Description"
            label={I18n.t('service_description')}
            value={description}
            minlength="250"
            characterCount={characterCount}
            placeholder={I18n.t('service_desc_placeholder')}
            onChange={handleDescriptionChange}
            onBlur={onDescriptionBlur}
            feedbackType="invalid"
            feedback={
              localErrors.description
                ? localErrors.description
                : returnedErrors.description
                ? returnedErrors.description[0]
                : null
            }
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.description) ||
              localErrors.description
            }
          />

          <QuillBox
            required={false}
            controlId="Requirements"
            label={I18n.t('requirements_title')}
            placeholder={I18n.t('requirements_description')}
            value={requirments}
            onChange={handleRequirementsChange}
            feedbackType="invalid"
            feedback={localErrors.requirments ? localErrors.requirments : null}
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.requirments) ||
              localErrors.requirments
            }
          />

          <Form.Group controlId="SmallImage">
            <Form.Label>
              <Translate value="thumbnail_image" />
              <small className="required"> ({I18n.t('required')})</small>
            </Form.Label>
            <Uploader
              accept={[
                {
                  mime: 'image/jpeg',
                  ext: 'jpeg',
                },
                {
                  mime: 'image/jpg',
                  ext: 'jpg',
                },
                {
                  mime: 'image/png',
                  ext: 'png',
                },
              ]}
              maxFiles={1}
              multiple={false}
              maxSize={5000000}
              sortable={false}
              setFilesChosen={setFileChosen}
              setFilesUpload={setFileUpload}
              setUploadErrors={setUploadErrors}
              uploadErrors={uploadErrors}
              setFilesSort={setImage}
              filesSort={image}
              filesSortLength={imageSortLength}
              filteredErrorsLength={filteredErrorsLength}
              filteredErrors={filteredErrors}
              uploadPath="services"
              projectId={serviceItem ? serviceItem.id : null}
              deletedFiles={deletedFile}
              setDeletedFiles={setDeletedFile}
              optimize={1}
            />
          </Form.Group>
          {errorsId === 'DELETE_FILE_FAIL' && deletedFile.length > 0 ? (
            <ErrorBox message={I18n.t('delete_file_error')} />
          ) : null}

          <Form.Group controlId="ServiceImages">
            <Form.Label>
              <Translate value="service_images" />
              <small className="optional"> ({I18n.t('optional')})</small>
            </Form.Label>
            <div className="alert alert-primary" role="alert">
              {I18n.t('service_images_desc')}
            </div>
            <small>
              <Translate value="sort_message" />
            </small>
            <Uploader
              accept={[
                {
                  mime: 'image/jpeg',
                  ext: 'jpeg',
                },
                {
                  mime: 'image/jpg',
                  ext: 'jpg',
                },
                {
                  mime: 'image/png',
                  ext: 'png',
                },
              ]}
              maxFiles={5}
              multiple={true}
              maxSize={5000000}
              sortable={true}
              setFilesChosen={setImagesChosen}
              setFilesUpload={setImagesUpload}
              setUploadErrors={setImagesUploadErrors}
              uploadErrors={imagesUploadErrors}
              setFilesSort={setImages}
              filesSort={images}
              filesSortLength={imagesSortLength}
              filteredErrorsLength={filteredImagesErrorsLength}
              filteredErrors={filteredImagesErrors}
              uploadPath="services"
              projectId={serviceItem ? serviceItem.id : null}
              deletedFiles={deletedFiles}
              setDeletedFiles={setDeletedFiles}
              optimize={1}
            />
          </Form.Group>
          {errorsId === 'DELETE_FILE_FAIL' && deletedFiles.length > 0 ? (
            <ErrorBox message={I18n.t('delete_file_error')} />
          ) : null}

          <TextInput
            type="text"
            controlId="ServiceVideos"
            name="ServiceVideos"
            label={I18n.t('video_samples')}
            desc={I18n.t('sort_data_message')}
            placeholder={I18n.t('video_placeholder')}
            warning="video_add_message"
            warningType="warning"
            value={video}
            required={false}
            onChange={handleVideo}
            feedbackType="invalid"
            feedback={localErrors.video ? localErrors.video : null}
            isInvalid={localErrors.video}
          />
          <Button
            variant="secondary"
            className="mb-3"
            type="button"
            onClick={handelAddVideoTo}
            data-backdrop="static">
            <Translate value="add" />
          </Button>
          {videos && videos.length > 0 ? (
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <Translate value="link" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              {videos && videos.length > 0 ? (
                <SortableVideoList
                  videos_sort={videos}
                  onSortEnd={onSortVideosEnd}
                  distance={1}
                />
              ) : null}
            </Table>
          ) : null}

          <CreatableKeywords
            onChange={handleKeywordsChange}
            feedback={
              localErrors.keywords
                ? localErrors.keywords
                : returnedErrors.keywords
                ? returnedErrors.keywords[0]
                : null
            }
            feedbackType="invalid"
            isInvalid={
              (errorsId === ERROR_ACTION_TYPE && returnedErrors.keywords) ||
              localErrors.keywords
            }
            value={serviceKeywords}
            onBlur={onKeywordsBlur}
          />
          <Form.Check
            type="switch"
            id="publishItem"
            label={<Translate value="hide_from_services" />}
            name="publish"
            className="my-4"
            onChange={togglePublish}
            checked={!publish}
          />
          {I18n.t('service_features_hint') + ' '}
          <Link
            to="/service-improvements"
            target="_blank"
            rel="noopener noreferrer"
            className="custom-link">
            {I18n.t('learn_how_use')}
          </Link>
          <h3
            className={
              featureButton
                ? 'head-title mb-0 Service__Feature__Title__Open'
                : 'head-title mb-3'
            }>
            <Translate value="service_features" />
            <button className="btn" type="button" onClick={handleFeatureForm}>
              {featureButton === false ? (
                <Fragment>
                  <span className="icon icon-plus"></span>
                  <Translate value="add_feature" />
                </Fragment>
              ) : (
                <Fragment>
                  <span className="icon icon-minus-small"></span>
                  <Translate value="hide" />
                </Fragment>
              )}
            </button>
          </h3>

          <AddingFeature
            featureForm={featureForm}
            addFeature={handelAddFeature}
            warning="feature_add_message"
            warningType="warning"
          />

          {features && features.length > 0 ? (
            <Table striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <Translate value="description" />
                  </th>
                  {/*<th>
                    <Translate value="description" />
                  </th>*/}
                  <th>
                    <Translate value="day_s" />
                  </th>
                  <th>
                    <Translate value="price" />
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {features.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    {/*<td>
                      {item.description ? (
                        <div
                          dangerouslySetInnerHTML={createMarkup(
                            item.description
                          )}
                        ></div>
                      ) : null}
                    </td>*/}
                    <td>{item.deadline}</td>
                    <td>{item.price}</td>
                    <td>
                      <button
                        className="deleteItem"
                        type="button"
                        onClick={() => handledeleteFeature(item, index)}>
                        <span className="icon icon-delete"></span>
                        <Translate value="delete" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}

          <GRecaptch setIsHuman={setIsHuman} />

          {errorsId === 'ADD_SERVICE_FAIL' &&
          returnedErrors === 'Network Error' ? (
            <ErrorBox message={I18n.t('network_error_message')} />
          ) : errorsId === 'ADD_SERVICE_FAIL' &&
            returnedErrors === 'Other Errors' ? (
            <ErrorBox message={I18n.t('something_error_message')} />
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
            variant="primary"
            type="submit"
            onClick={onSubmit}
            data-backdrop="static"
            loading={props.isLoading ? props.isLoading : null}>
            <Translate value="submit" />
          </ButtonWithLoading>
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}>
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
