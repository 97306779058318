import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CreatableInput from "../CreatableInput";
import { axiosInstance } from "../../axiosInstance";
import { GET_KEYWORDS_FAIL } from "../../actions/types";
import { returnErrors } from "../../actions/errorActions";

export function getKeywords() {
  return axiosInstance.get('/api/keywords')
}

export function CreatableKeywords({
  onChange,
  isInvalid,
  feedback,
  required = true,
  value,
  onBlur
}) {
  const dispatch = useDispatch();
  var [allKeywords, setAllKeywords] = useState([]);
  var options = mapKeywords(allKeywords);
  value = mapKeywords(value || []);

  function mapKeywords(keywords) {
    return keywords.map((keyword) => ({ label: keyword, value: keyword }));
  }

  function unmapKeywords(keywords) {
    return keywords.map((keyword) => keyword.value);
  }

  function handleKeywordsChange(keywords) {
    onChange && onChange(unmapKeywords(keywords));
  }

  //Get Keywords
  useEffect(() => {
    getKeywords().then(res => 
      setAllKeywords(res.data.data)
    )
    .catch(error => {
      if (error instanceof Error) {
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          return (
              dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_KEYWORDS_FAIL')),
              dispatch ({ 
                  type: GET_KEYWORDS_FAIL 
              })
          )
      }else{
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_KEYWORDS_FAIL'));
          dispatch ({ 
              type: GET_KEYWORDS_FAIL 
          });
      }
    });
  }, [dispatch]);

  return (
    <CreatableInput
      isMulti
      required={required}
      name="keywords"
      onChange={handleKeywordsChange}
      controlId="Keywords"
      label="search_keywords"
      feedback={feedback}
      feedbackType="invalid"
      placeholder="add_keywords"
      isInvalid={isInvalid}
      options={options}
      value={value}
      onBlur={onBlur}
    />
  );
}
