import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import AddingRow from "../AddingRow";
import AddingProjectModal from "../AddingProjectModal";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import ProjectCard from "../ProjectCard";
import { getProjects } from "../../redux/project/actions/getActions";
import { I18n } from "react-redux-i18n";
import ConfToast from "../ConfToast";
import InProgressProjects from "./InProgressProjects";
import ClosedProjects from "./ClosedProjects";
import CancelledProjects from "./CancelledProjects";
import { CLEAR_DELETE_PROJECT_SUCCESS } from "../../redux/project/actions/types";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";

const MyProjects = (props) => {
  const dispatch = useDispatch();
  const {
    projects = [],
    getUserProjectsLoading,
    addingProjectLoading,
    addingProjectSuccess,
    deletingProjectSuccess,
    user,
    totalRecords,
    itemsPerPage,
    isLoading,
    errorId,
    errorMessage,
    locale,
    userInfo,
  } = useSelector((state) => ({
    projects: state.project.userProjectsReceiving,
    getUserProjectsLoading: state.project.getUserProjectsReceivingLoading,
    addingProjectLoading: state.project.addingProjectLoading,
    addingProjectSuccess: state.project.addingProjectSuccess,
    deletingProjectSuccess: state.project.deletingProjectSuccess,
    user: state.auth.user,
    isLoading: state.auth.isLoading,
    totalRecords: state.project.receivingTotalRecords,
    itemsPerPage: state.project.receivingItemsPerPage,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    locale: state.i18n.locale,
    userInfo: state.userData.user,
  }));

  const [isAddingProjectSuccess, setAddingProjectSuccess] = useState(addingProjectSuccess);
  const [toggleAddProjectModel, setToggleAddProjectModel] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if(userInfo && userInfo.memberships && userInfo.memberships.length > 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    } else if(userInfo && userInfo.memberships && userInfo.memberships.length <= 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    }
  }, [userInfo]);

  useEffect(() => {
    setLoadingProjects(getUserProjectsLoading);
  }, [getUserProjectsLoading]);

  function handleHide() {
    setToggleAddProjectModel(!toggleAddProjectModel);
  }

  const handleToast = useCallback((showToast, title, message, status, delay) => {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const { id } = user;

  useEffect(() => {
    if (id) {
      let user_id = id;
      const page = activePage;
      const limit = 10;
      const status_id = 8; //Receiving Bid Status Id
      dispatch(getProjects(user_id, null, page, limit, null, null, null, null, null, null, null, status_id, null, locale));
    }
  }, [id, dispatch, locale, activePage]);

  useEffect(() => {
    setAddingProjectSuccess(addingProjectSuccess);
  }, [addingProjectSuccess]);

  useEffect(()=>{
    if(deletingProjectSuccess === true){
      setTimeout(() => {
        handleToast(true, I18n.t('delete_project'), I18n.t('delete_project_success'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_DELETE_PROJECT_SUCCESS
      });
    }
  }, [deletingProjectSuccess, handleToast, dispatch]);

  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="container">
        <div className="row">
          <div className="col-12 Dashboard__tasks__List">
              <AddingRow
              onAdding={() => {
                setToggleAddProjectModel(!toggleAddProjectModel);
              }}
              isClient={isClient}
              type="projects"
              title={"projects"}
              titleIconClassName={"icon-projects"}
              btnText={"add_project"}
              btnIconClassName={"icon-plus"}
            />
            <AddingProjectModal
              actionLoading={isAddingProjectSuccess}
              loading={addingProjectLoading}
              modalTitle={"add_project"}
              className="CustomPopup"
              size="lg"
              show={toggleAddProjectModel}
              onHide={handleHide}
              backdrop="static"
              keyboard={false}
              aria-labelledby="example-modal-sizes-title-lg"
            />
            <Tabs id="JoinedStats" unmountOnExit={true}>
              {
                isClient ?
                <Tab eventKey="MyProjects" title={I18n.t('receiving_bids')}>
                  <TitleComponent title={I18n.t('lancersin_projects_receiving')} />
                  <MetaComponent />
                  <div className="row">
                    {
                      loadingProjects || isLoading ?
                        <Spin />
                      : errorId === "GET_USER_PROJECTS_RECEIVING_BIDS_FAIL" && errorMessage === "Network Error" ?
                      <div className="col-12">
                          <ConnectionLost
                            errorType= "connection"
                            title = "network_error_title"
                            message = "network_error_message"
                          />
                      </div>
                      : errorId === "GET_USER_PROJECTS_RECEIVING_BIDS_FAIL" ?
                      <div className="col-12">
                          <ConnectionLost
                            errorType= "somethingWrong"
                            title = "something_error_title"
                            message = "something_error_message"
                          />
                      </div>
                      : projects.length > 0 &&  !loadingProjects ?
                      <Fragment>
                        {
                          projects.map((item) => (
                            <ProjectCard key={`project-#${item.id}`} item={item} />
                          ))
                        }
                        <div className="col-12 my-4">
                          <Pagination
                            activePage={activePage}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </div>
                      </Fragment>
                      :
                      <div className="col-12">
                        <NoItems
                          title="no_projects_found"
                          message="no_projects_message_with_action"
                          buttonText="add_project"
                          iconClassName="icon icon-plus"
                          onClick={() => {
                            setToggleAddProjectModel(true);
                          }}
                        />
                      </div>
                    }
                  </div>
                </Tab>
                : null
              }
              <Tab eventKey="RunningProjects" title={I18n.t('in_progress')}>
                <InProgressProjects />
              </Tab>
              <Tab eventKey="ClosedProjects" title={I18n.t('closed_projects')}>
                <ClosedProjects />
              </Tab>
              <Tab eventKey="CancelledProjects" title={I18n.t('cancelled_projects')}>
                <CancelledProjects />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyProjects;
