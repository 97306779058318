import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Link} from 'react-router-dom';

function WhyLancersin() {
  return (
    <section className="WhyLancersin">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{I18n.t('why_lancersin')}</h2>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <section className="mb-5">
              <h3>{I18n.t('clients_tab')}</h3>
              <div className="row flex-row-reverse-mobile">
                <div className="col-md-7 mb-3">
                  <ul className="FeaturesList">
                    <li>
                      <h4>{I18n.t('why_client_head_one')}</h4>
                      <p>{I18n.t('why_client_desc_one')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_client_head_two')}</h4>
                      <p>{I18n.t('why_client_desc_two')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_client_head_three')}</h4>
                      <p>{I18n.t('why_client_desc_three')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_client_head_four')}</h4>
                      <p>{I18n.t('why_client_desc_four')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_client_head_five')}</h4>
                      <p>{I18n.t('why_client_desc_five')}</p>
                    </li>
                  </ul>
                </div>
                <div className="col-md-5 mb-3">
                  <img
                    src="images/why-client.png"
                    className="img-fluid FlipImgArabic"
                    loading="lazy"
                    alt={I18n.t('why_lancersin')}
                  />
                </div>
              </div>
            </section>
            <section>
              <h3>{I18n.t('freelancers_tab')}</h3>
              <div className="row">
                <div className="col-md-5 mb-3">
                  <img
                    src="images/why-freelancer.png"
                    className="img-fluid FlipImgArabic"
                    loading="lazy"
                    alt={I18n.t('why_lancersin')}
                  />
                </div>
                <div className="col-md-7 mb-3">
                  <ul className="FeaturesList">
                    <li>
                      <h4>{I18n.t('why_freelancer_head_one')}</h4>
                      <p>{I18n.t('why_freelancer_desc_one')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_freelancer_head_two')}</h4>
                      <p>{I18n.t('why_freelancer_desc_two')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_freelancer_head_three')}</h4>
                      <p>{I18n.t('why_freelancer_desc_three')}</p>
                    </li>
                    <li>
                      <h4>{I18n.t('why_freelancer_head_four')}</h4>
                      <p>{I18n.t('why_freelancer_desc_four')}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyLancersin;
