import {
    EDIT_PORTFOLIO_DATA_LOADING,
    EDIT_PORTFOLIO_SUCCESS, 
    EDIT_PORTFOLIO_FAIL
} from '../actions/types';

const initialState = {
    isLoading: false,
    portfolioItemEdited: {},
}

export default function editportfolioitemReducer(state = initialState, action) {
    switch (action.type) {
        case EDIT_PORTFOLIO_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case EDIT_PORTFOLIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                portfolioItemEdited: action.payload.data
            };
        case EDIT_PORTFOLIO_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}