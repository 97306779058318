// Action Types

/**
 * createTypes is a function to create CONSTANT action types instead of regular strings to prevent typo mistakes.
 * @param  {array} strings
 */
function createTypes(strings, ...types) {
  types = [...strings, types];
  types = types.join('\n');
  types = types.split('\n');
  const TYPES = {};
  for (let type of types) {
    if (type === '') {
      continue;
    }
    TYPES[type] = type;
  }
  return TYPES;
}

/**
 * createOurSteps is a function to create the four types for given action.
 * @method
 * @param {string} PREFEX - The PREFEX for each generated type.
 */
function createSteps(PREFEX) {
  const OUR_STEPS = ['loading', 'success', 'fail'];

  const OUR_STEP_CREATED = [PREFEX.toUpperCase()];

  OUR_STEPS.forEach(STEP =>
    OUR_STEP_CREATED.push(`${PREFEX}_${STEP}`.toUpperCase()),
  );

  return OUR_STEP_CREATED.join('\n');
}

const types = [
  'GET_PROJECTS',
  'ADD_PROJECT',
  'DELETE_PROJECT',
  'EDIT_PROJECT',
  'GET_USER_PROJECTS',
  'GET_USER_PROJECTS_RECEIVING_BIDS',
  'GET_USER_PROJECTS_IN_PROGRESS',
  'GET_USER_PROJECTS_CLOSED',
  'GET_USER_PROJECTS_CANCELLED',
  'GET_PROJECT_BY_ID',
  'GET_PROJECTS_PRICES',
  'ADD_PROJECT_BID',
  'GET_PROJECT_BIDS',
  'SELECT_PROJECT_BID',
  'GET_PROJECT_COMMENTS',
  'ADD_PROJECT_COMMENT',
  'DELETE_PROJECT_COMMENT',
  'EDIT_PROJECT_COMMENT',
  'GET_PROJECT_LAST_COMMENT',
  'CLOSE_PROJECT',
  'RATE_PROJECT',
  'REQUEST_CLOSE_PROJECT',
  'HANDOVER_PROJECT_BID',
  'INDORSE_PROJECT_BID',
  'PROJECT_BID_REPORTS',
  'REPORT_PROJECT_BID',
  'CANCEL_PROJECT_BID',
];

export default createTypes`
RESET_PROJECTS_ACTIONS

${types.map(createSteps).join('\n')}
`;

export const CLEAR_ADD_PROJECT_SUCCESS = 'CLEAR_ADD_PROJECT_SUCCESS';
export const CLEAR_CURRENT_PROJECT = 'CLEAR_CURRENT_PROJECT';
export const CLEAR_DELETE_PROJECT_SUCCESS = 'CLEAR_DELETE_PROJECT_SUCCESS';
export const CLEAR_ADD_PROJECT_BID_SUCCESS = 'CLEAR_ADD_PROJECT_BID_SUCCESS';
export const CLEAR_SELECT_PROJECT_BID_SUCCESS =
  'CLEAR_SELECT_PROJECT_BID_SUCCESS';
export const CLEAR_CLOSE_PROJECT_SUCCESS = 'CLEAR_CLOSE_PROJECT_SUCCESS';
export const CLEAR_RATE_PROJECT_SUCCESS = 'CLEAR_RATE_PROJECT_SUCCESS';
export const CLEAR_REQUEST_CLOSE_PROJECT_SUCCESS =
  'CLEAR_REQUEST_CLOSE_PROJECT_SUCCESS';
export const CLEAR_HANDOVER_PROJECT_BID_SUCCESS =
  'CLEAR_HANDOVER_PROJECT_BID_SUCCESS';
export const CLEAR_INDORSE_PROJECT_BID_SUCCESS =
  'CLEAR_INDORSE_PROJECT_BID_SUCCESS';
export const CLEAR_REPORT_PROJECT_BID_SUCCESS =
  'CLEAR_REPORT_PROJECT_BID_SUCCESS';
export const CLEAR_CANCEL_PROJECT_BID_SUCCESS =
  'CLEAR_CANCEL_PROJECT_BID_SUCCESS';
export const CLEAR_PROJECT_BID_REPORTS = 'CLEAR_PROJECT_BID_REPORTS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const SAVE_PROJECT = 'SAVE_PROJECT';
export const CLEAR_SAVED_PROJECT = 'CLEAR_SAVED_PROJECT';
