import { 
    ADD_PORTFOLIO_LIKE_SUCCESS,
    ADD_PORTFOLIO_LIKE_FAIL,
    ADD_PORTFOLIO_LIKE_LOADING,
    GET_PORTFOLIO_LIKES_SUCCESS,
    GET_PORTFOLIO_LIKES_FAIL,
    GET_PORTFOLIO_LIKES_LOADING,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors } from '../actions/errorActions';

// ADD PORTFOLIO LIKE
export const addportfolioitemLike = ({ action, portfolio_id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: ADD_PORTFOLIO_LIKE_LOADING
    });
    //Request body
    const body = { action, portfolio_id };
    axiosInstance.post('/api/portfolio/likes', body, tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: ADD_PORTFOLIO_LIKE_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'ADD_PORTFOLIO_LIKE_FAIL'))
            dispatch ({ 
                type: ADD_PORTFOLIO_LIKE_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_PORTFOLIO_LIKE_FAIL')),
                dispatch ({ 
                    type: ADD_PORTFOLIO_LIKE_FAIL 
                })
            )
        }
    });
};

// ADD PORTFOLIO LIKE
export const getportfolioitemLikes = ({ portfolio_id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: GET_PORTFOLIO_LIKES_LOADING
    });
    //Request body
    const body = { portfolio_id };
    axiosInstance.get('/api/portfolio/likes', {params: body})
    .then( res => 
        dispatch ({
            type: GET_PORTFOLIO_LIKES_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_PORTFOLIO_LIKES_FAIL'))
            dispatch ({ 
                type: GET_PORTFOLIO_LIKES_FAIL
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PORTFOLIO_LIKES_FAIL')),
                dispatch ({ 
                    type: GET_PORTFOLIO_LIKES_FAIL 
                })
            )
        }
    });
};

// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}