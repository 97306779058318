const PROJECTS = "/api/projects";
const PROJECT_COMMENTS = PROJECTS + "/comments";
const CLOSE_PROJECT = PROJECTS + "/close";
const REQUEST_CLOSE_PROJECT = PROJECTS + "/request/close";
const CREATE_PROJECT_BID = PROJECTS + "/bids/create";
const PROJECT_BIDS = PROJECTS + "/bids";
const PROJECTS_PRICES = "/api/project_prices";
const PROJECT_BID_SELECT = PROJECT_BIDS + "/select";
const FEEDBACK = "/api/feedbacks";
const HANDOVER_PROJECT_BID = PROJECT_BIDS + "/handover";
const INDORSE_PROJECT_BID = PROJECT_BIDS + "/indorse";
const BID_REPORTS = PROJECT_BIDS + "/reports";
const REPORT_PROJECT_BID = PROJECT_BIDS + "/abuse";
const CANCEL_PROJECT_BID = PROJECT_BIDS + "/cancel";

const exportedObject = {
  PROJECTS,
  PROJECT_COMMENTS,
  CLOSE_PROJECT,
  REQUEST_CLOSE_PROJECT,
  CREATE_PROJECT_BID,
  PROJECT_BIDS,
  PROJECTS_PRICES,
  PROJECT_BID_SELECT,
  FEEDBACK,
  HANDOVER_PROJECT_BID,
  INDORSE_PROJECT_BID,
  BID_REPORTS,
  REPORT_PROJECT_BID,
  CANCEL_PROJECT_BID
};

export default exportedObject;
