import {
    DELETE_SERVICE_SUCCESS,
    DELETE_SERVICE_FAIL,
    DELETE_SERVICE_DATA_LOADING,
    DELETE_SERVICE_FEATURE_LOADING,
    DELETE_SERVICE_FEATURE_SUCCESS,
    DELETE_SERVICE_FEATURE_FAIL,
    RESET_DELETE_SERVICE,
} from '../actions/types';

const initialState = {
    isLoading: false,
    serviceItemDeleted: false,
    deleteFeatureLoading: false,
    deleteFeatureSuccess: '',
}

export default function deleteserviceReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_SERVICE_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case DELETE_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceItemDeleted: action.payload.data === "success"
            };
        case DELETE_SERVICE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case DELETE_SERVICE_FEATURE_LOADING:
            return {
                ...state,
                deleteFeatureLoading: true,
            };
        case DELETE_SERVICE_FEATURE_SUCCESS:
            return {
                ...state,
                deleteFeatureLoading: false,
                deleteFeatureSuccess: action.payload.data,
            };
        case DELETE_SERVICE_FEATURE_FAIL:
            return {
                ...state,
                deleteFeatureLoading: false,
            };
        case RESET_DELETE_SERVICE:
            return {
                isLoading: false,
                serviceItemDeleted: false,
                deleteFeatureLoading: false,
                deleteFeatureSuccess: '',
            };
        default:
            return state;
    }
}