import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors } from "../../actions/errorActions";
import { inviteUser } from "../../redux/notification/actions/addActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import ErrorBox from "../ErrorBox";
import GRecaptch from "../GRecaptch";
import StatusLabel from "../../StatusLabel";
import InfiniteScroll from 'react-infinite-scroll-component';
import Spin from "../Spin";
import { CLEAR_INVITE_USER_SUCCESS } from "../../redux/notification/actions/types";
import NoItems from '../NoItems';
import AddingProjectModal from "../AddingProjectModal";

export default function HireUserModal(props) {
  const dispatch = useDispatch();

  const {
    errorsId,
    errorsMsg: { errors = {} },

    totalRecords,

    inviteUserSuccess,

    addingProjectLoading,
  } = useSelector((state) => ({
    errorsId: state.error.id,
    errorsMsg: state.error.msg,

    totalRecords: state.project.receivingTotalRecords,

    inviteUserSuccess: state.notifications.inviteUserSuccess,

    addingProjectLoading: state.project.addingProjectLoading,
  }));


  const [isHuman, setIsHuman] = useState(false);
  const [projects, setProjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [ids, setIds] = useState([]);
  const [toggleAddProjectModel, setToggleAddProjectModel] = useState(false);

  function handleProjectModalHide() {
    setToggleAddProjectModel(!toggleAddProjectModel);
  }


  const {
    modalTitle,
    loading,
    currentProjects,
    setConfShow,
    setConfTitle,
    setConfMessage,
    setConfStatus,
    setDelay,
    activePage,
    setActivePage,
    getUserProjectsLoading,
    userId
  } = props;

  useEffect(() => {
    if(projects.length < totalRecords){
      setHasMore(true);
    }else{
      setHasMore(false);
    }
  }, [projects, totalRecords]);

  const createMarkup = (text) => {
    return { __html: text };
  };

  // useEffect(() => {
  //   let newProjects = projects;
  //   if(currentProjects.length > 0){
  //     currentProjects.map( item => {
  //       if(newProjects.some(function(el) {
  //         return el.id === item.id;
  //       })){
  //         return false;
  //       } else{
  //         newProjects.push(item);
  //       }
  //     });
  //     setProjects(newProjects);
  //   }

  // }, [currentProjects]);

  useEffect(() => {
    if (currentProjects.length > 0) {
        var ids = new Set(projects.map(item => item.id));
        var merged = [...projects, ...currentProjects.filter(item => !ids.has(item.id))];
        setProjects(merged);
    }
  }, [currentProjects, getUserProjectsLoading, projects]);

  const handleHide = useCallback(
    () => {
      props.onHide();
      dispatch(clearErrors());
    },
    [props, dispatch]
  );

  const handleToast = useCallback((showToast, title, message, status, delay) =>{
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  function onSubmit(e) {
    e.preventDefault();
    
    if (
      isHuman &&
      ids.length > 0 &&
      userId
    ) {
        const morphType = "projects";
        const morphIDs = ids.map(id => {
          return Number(id);
        });
        const user_id = userId;
        dispatch(inviteUser(
          morphType,
          morphIDs,
          user_id
        ));
    } else{
      handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 10000)
    }
  }

  useEffect(()=>{
    if(inviteUserSuccess){
        dispatch({
          type: CLEAR_INVITE_USER_SUCCESS
        });
        handleHide();
        setTimeout(() => {
          handleToast(true, I18n.t('invite_project'), I18n.t('invite_project_success'), 'Toast__Container__Success', 6000);
        }, 2000);
    }
  }, [inviteUserSuccess, dispatch, handleToast, handleHide]);

  function fetchMoreData() {
    setTimeout(() => {
      setActivePage(activePage + 1);
    }, 1500);
  };

  function onChangeProjects(e){
    const projectID = e.target.value;
    if (ids.includes(projectID)){
        const index = ids.indexOf(projectID);
        if (index > -1) {
            ids.splice(index, 1);
         }
    }else{
        setIds(ids.concat(projectID));
    }
};


  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props["aria-labelledby"]}
    >

      <AddingProjectModal
        modalTitle={"add_project"}
        className="CustomPopup"
        size="lg"
        show={toggleAddProjectModel}
        onHide={handleProjectModalHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={addingProjectLoading}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />

      <Form>
        <Modal.Header closeButton>
          <Modal.Title>
            <Translate value={modalTitle} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            projects.length > 0 ?
            <Fragment>
              <div
              id="scrollableDiv"
              style={{
              height: projects.length > 5 ? 450 : 270,
              overflow: 'auto',
              }}
            >
              <InfiniteScroll
              dataLength={projects.length}
              next={fetchMoreData}
              inverse={false}
              hasMore={hasMore}
              loader={<Spin />}
              style={{ display: 'flex', width: '100%' }}
              scrollableTarget="scrollableDiv"
              >
                  <div className="Dashboard__tasks__List Invite__User">
                      {
                        projects.map( item => (
                          <div className="col-12">
                            <div className="Dashboard__Project">
                              <div className="form-group">
                                  <div className="form-check">
                                      <input type="checkbox" id={item.id} name={item.title} value={item.id} className="form-check-input" onChange={onChangeProjects} />
                                      <label title="" htmlFor={item.id} className="form-check-label">
                                        <h2>
                                          <div>
                                            <Link
                                              to={{ pathname: `/projects/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {item.title}
                                            </Link>
                                            <StatusLabel status_id={item.status.id} type="project" />
                                          </div>
                                          <div className="Dashboard__Project__Budget">
                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{I18n.t('project_budget')}</Tooltip>}>
                                              <div className="Owner__Name">
                                                <span className="icon icon-budget"></span>{item.price} {I18n.t('dollar')}
                                              </div>
                                            </OverlayTrigger>
                                          </div>
                                        </h2>
                                        {
                                          item.description ?
                                          <div className="Dashboard__Project__Description" dangerouslySetInnerHTML={createMarkup(
                                            item.description
                                          )}>
                                          </div>
                                          : null
                                        }
                                      </label>
                                  </div>
                              </div>
                            </div>
                          </div>
                        ))
                      }
                  </div>
              </InfiniteScroll>
            </div>
            <GRecaptch
                setIsHuman={setIsHuman}
                className="mt-3 px-3"
            />
          </Fragment>
          : 
          <NoItems
            title="no_projects_found"
            message="no_projects_Invite_with_action"
            buttonText="add_project"
            iconClassName="icon icon-plus"
            onClick={() => {
              setToggleAddProjectModel(true);
            }}
          />
          }

          {
            errorsId === "INVITE_USER_FAIL" && errors === "Network Error" ?
              <ErrorBox message={I18n.t('network_error_message')} />
            : errorsId === "INVITE_USER_FAIL" && errors === "Other Errors" ?
              <ErrorBox message={I18n.t('something_error_message')} />
            : null
          }

        </Modal.Body>

        <Modal.Footer>
          {
            projects.length > 0 ?
              <ButtonWithLoading
              variant="primary"
              type="submit"
              onClick={onSubmit}
              data-backdrop="static"
              loading={loading ? loading : null}
              >
                  <Translate value="send_invitation" />
              </ButtonWithLoading>
            : null
          }
          <Button
            variant="secondary"
            className="btn btn-secondary"
            onClick={handleHide}
            disabled={loading}
          >
            <Translate value="close" />
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
