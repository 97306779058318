import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import TextInput from "../TextInput";
import { SelectBox } from "../SelectBox";
import QuillBox from "../QuillBox";
import ErrorBox from "../ErrorBox";
import GRecaptch from "../GRecaptch";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { RESET_CONTACT_SUCCESS } from "../../redux/contact/actions/types";
import { sendContact } from "../../redux/contact/actions/addActions";
import ConfToast from "../ConfToast";
import { clearErrors } from '../../actions/errorActions';
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";

export default function Contact(){
    const dispatch = useDispatch();

    const {
        errorsId,
        errorsMsg: { errors = {} },
        sendContactLoading,
        sendContactSuccess,
    } = useSelector((state) => ({
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
        sendContactLoading: state.contact.sendContactLoading,
        sendContactSuccess: state.contact.sendContactSuccess,
    }));

    const [localErrors, setLocalErrors] = useState({});
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [type, setType] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [isHuman, setIsHuman] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, []);

    useEffect(() => {
        if (sendContactSuccess) {
            setName("");
            setEmail("");
            setPhone("");
            setType("");
            setSubject("");
            setMessage("");
            setLocalErrors({});
            handleToast(true, I18n.t('sending_message'), I18n.t('message_sent__success'), 'Toast__Container__Success', 6000);
            dispatch(clearErrors());
            dispatch({
              type: RESET_CONTACT_SUCCESS
            });
        }
    }, [sendContactSuccess, dispatch, handleToast]);

    function ValidateEmail(mail){
        const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        if (!mail || regex.test(mail) === false){
            if(!mail){
                setLocalErrors((errors) => ({
                    ...errors,
                    "email": I18n.t('email_required'),
                }));
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    "email": I18n.t('enter_valid_email'),
                }));
            }
            return false;
        }
        setLocalErrors((errors) => ({
            ...errors,
            "email": undefined,
        }));
        return true;
        
    }

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: undefined,
            }));
        }else{
          setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
          }));
        }
    }

    function onNameChange(e) {
        setName(e.target.value);
    }
    
    function onNameBlur(){
        handleItemLocalError({
          propName: "name",
          currentValue: name,
          message: I18n.t('fullname_required'),
        });
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }
    
    function onEmailBlur(){
        ValidateEmail(email);
    }

    function onPhoneChange(e) {
        setPhone(e.target.value);
    }

    function handleTypeChange(e) {
        setType(e);
    }
    
    function onTypeBlur(){
        handleItemLocalError({
          propName: "type",
          currentValue: type,
          message: I18n.t('type_required'),
        });
    }

    function onSubjectChange(e) {
        setSubject(e.target.value);
    }
    
    function onSubjectBlur(){
        handleItemLocalError({
          propName: "subject",
          currentValue: subject,
          message: I18n.t('subject_required'),
        });
    }

    function handleMessageChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
          setMessage("");
        } else {
          setMessage(value);
        }
    }
    
    function onMessageBlur(){
        handleItemLocalError({
          propName: "message",
          currentValue: message,
          message: I18n.t('message_required'),
        });
    }

    const types = [
        {
            value: I18n.t('message_type_issue'),
            label: I18n.t('message_type_issue')
        },
        {
            value: I18n.t('message_type_request'),
            label: I18n.t('message_type_request')
        },
        {
            value: I18n.t('message_type_suggestion'),
            label: I18n.t('message_type_suggestion')
        },
        {
            value: I18n.t('message_type_inquiry'),
            label: I18n.t('message_type_inquiry')
        },
        {
            value: I18n.t('message_type_other'),
            label: I18n.t('message_type_other')
        }
    ]

    function onSubmit(e) {
        e.preventDefault();
        handleItemLocalError({
          propName: "name",
          currentValue: name,
          message: I18n.t('fullname_required'),
        });

        ValidateEmail(email);
    
        handleItemLocalError({
          propName: "type",
          currentValue: type,
          message: I18n.t('type_required'),
        });

        handleItemLocalError({
            propName: "subject",
            currentValue: subject,
            message: I18n.t('subject_required'),
        });
    
        handleItemLocalError({
          propName: "message",
          currentValue: message,
          message: I18n.t('message_required'),
        });
    
        if (
          name &&
          email &&
          type &&
          subject &&
          message &&
          isHuman
        ) {
            const messageType = type.value;
            dispatch(sendContact(name, email, phone, messageType, subject, message));
        } else{
          handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 10000)
        }
      }

    return(
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <TitleComponent title={I18n.t('lancersin_contact')} />
            <MetaComponent />
            <div className="breadcrumb__container">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{I18n.t('contact')}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container webPages">
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <Card.Header>
                                <h1>{I18n.t('contact')}</h1>
                            </Card.Header>
                            <Card.Body>
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextInput
                                            required
                                            controlId="Name"
                                            label="full_name"
                                            type="text"
                                            name="name"
                                            feedbackType="invalid"
                                            onChange={onNameChange}
                                            onBlur={onNameBlur}
                                            isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.name) || localErrors.name}
                                            value={name}
                                            feedback={ localErrors.name ? localErrors.name : errors.name ? errors.name[0] : null }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            required
                                            controlId="Email"
                                            label="email"
                                            type="email"
                                            name="email"
                                            feedbackType="invalid"
                                            onChange={onEmailChange}
                                            onBlur={onEmailBlur}
                                            isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.email) || localErrors.email}
                                            value={email}
                                            feedback={ localErrors.email ? localErrors.email : errors.email ? errors.email[0] : null }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <TextInput
                                            required={false}
                                            controlId="Phone"
                                            label="mobile"
                                            type="number"
                                            name="phone"
                                            feedbackType="invalid"
                                            onChange={onPhoneChange}
                                            isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.phone) || localErrors.phone}
                                            value={phone}
                                            feedback={ localErrors.phone ? localErrors.phone : errors.phone ? errors.phone[0] : null }
                                        />
                                    </div>
                                    <div className="col-md-6">
                                    <SelectBox
                                        required
                                        controlId="Type"
                                        name="type"
                                        label="message_type"
                                        options={types}
                                        value={type}
                                        onChange={handleTypeChange}
                                        onBlur={onTypeBlur}
                                        feedback={localErrors.type ? localErrors.type : errors.type ? errors.type[0] : null}
                                        feedbackType="invalid"
                                        isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.type) || localErrors.type}
                                        placeholder={I18n.t("message_type")}
                                    />
                                    </div>
                                    <div className="col-12">
                                        <TextInput
                                            required
                                            controlId="Subject"
                                            label="message_title"
                                            type="text"
                                            name="subject"
                                            feedbackType="invalid"
                                            onChange={onSubjectChange}
                                            onBlur={onSubjectBlur}
                                            isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.subject) || localErrors.subject}
                                            value={subject}
                                            feedback={ localErrors.subject ? localErrors.subject : errors.subject ? errors.subject[0] : null }
                                        />
                                    </div>
                                </div>
                                { typeof window !== 'undefined' &&
                                    <>
                                        <QuillBox
                                            required
                                            controlId="Message"
                                            label="message"
                                            value={message}
                                            onChange={handleMessageChange}
                                            onBlur={onMessageBlur}
                                            feedbackType="invalid"
                                            feedback={localErrors.message ? localErrors.message : errors.message ? errors.message[0] : null}
                                            isInvalid={(errorsId === "SEND_CONTACT_FAIL" && errors.message) || localErrors.message}
                                        />
                                
                                        <GRecaptch
                                            setIsHuman={setIsHuman}
                                            className="mt-3"
                                        />
                                    </>
                                }
                        
                                {
                                    errorsId === "SEND_CONTACT_FAIL" && errors === "Network Error" ?
                                    <ErrorBox message={I18n.t('network_error_message')} />
                                    : errorsId === "SEND_CONTACT_FAIL" && errors === "Other Errors" ?
                                    <ErrorBox message={I18n.t('something_error_message')} />
                                    : errorsId === "SEND_CONTACT_FAIL" && errors === "Network Error" ?
                                    <ErrorBox message={I18n.t('network_error_message')} />
                                    : errorsId === "SEND_CONTACT_FAIL" && errors === "Other Errors" ?
                                    <ErrorBox message={I18n.t('something_error_message')} />
                                    : null
                                }
                        
                                <ButtonWithLoading
                                    variant="primary"
                                    type="submit"
                                    onClick={onSubmit}
                                    data-backdrop="static"
                                    className="mt-3"
                                    loading={sendContactLoading ? sendContactLoading : null}
                                >
                                    <Translate value="submit" />
                                </ButtonWithLoading>
                            </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}