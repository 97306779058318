import TYPES, {
  CLEAR_ADD_PROJECT_SUCCESS,
  CLEAR_DELETE_PROJECT_SUCCESS,
  CLEAR_ADD_PROJECT_BID_SUCCESS,
  CLEAR_SELECT_PROJECT_BID_SUCCESS,
  CLEAR_CURRENT_PROJECT,
  CLEAR_CLOSE_PROJECT_SUCCESS,
  CLEAR_REQUEST_CLOSE_PROJECT_SUCCESS,
  CLEAR_RATE_PROJECT_SUCCESS,
  CLEAR_HANDOVER_PROJECT_BID_SUCCESS,
  CLEAR_INDORSE_PROJECT_BID_SUCCESS,
  CLEAR_REPORT_PROJECT_BID_SUCCESS,
  CLEAR_CANCEL_PROJECT_BID_SUCCESS,
  CLEAR_PROJECT_BID_REPORTS,
  CLEAR_PROJECTS,
  SAVE_PROJECT,
  CLEAR_SAVED_PROJECT,
} from '../actions/types';

const INITIAL_STATE = {
  // Projects
  getProjectsLoading: false,
  getProjectsReceivingLoading: false,
  getProjectsProgressLoading: false,
  getProjectsClosedLoading: false,
  getProjectsError: '',
  getProjectSuccess: false,
  projects: [],
  totalProjects: 0,
  projectsPerPage: 0,

  // User Projects
  userProjects: [],

  // User Projects Receiving Bids
  getUserProjectsReceivingLoading: false,
  userProjectsReceiving: [],
  getUserProjectsReceivingError: '',
  receivingTotalRecords: 0,
  receivingItemsPerPage: 0,

  // User Projects In Progress
  userProjectsProgress: [],
  getUserProjectsProgressLoading: false,
  getUserProjectsProgressError: '',
  progressTotalRecords: 0,
  progressItemsPerPage: 0,

  // User Projects Closed
  userProjectsClosed: [],
  getUserProjectsClosedLoading: false,
  getUserProjectsClosedError: '',
  closedTotalRecords: 0,
  closedItemsPerPage: 0,

  // User Projects Cancelled
  userProjectsCancelled: [],
  getUserProjectsCancelledLoading: false,
  getUserProjectsCancelledError: '',
  cancelledTotalRecords: 0,
  cancelledItemsPerPage: 0,

  addedProject: [],
  addedProjectComment: [],
  totalRecords: 0,
  itemsPerPage: 0,

  // Add Project
  addingProjectLoading: false,
  addingProjectError: '',
  addingProjectSuccess: false,

  //   Delete Project
  deletingProjectLoading: false,
  deletingProjectError: '',
  deletingProjectSuccess: false,

  //   Edit Project
  editingProjectLoading: false,
  editingProjectError: '',
  editingProjectSuccess: false,

  // Get User Projects
  getUserProjectsLoading: false,
  getUserProjectsError: '',

  // Get Project
  getProjectByIdLoading: false,
  getProjectByIdError: '',
  currentProject: null,

  // Get Projects Prices
  getProjectsPricesLoading: false,
  projectsPrices: [],

  // Add Project Bid
  addingProjectBidLoading: false,
  addingProjectBidError: '',
  addingProjectBidSuccess: false,

  // Select Project Bid
  selectProjectBidLoading: false,
  selectProjectBidError: '',
  selectProjectBidSuccess: false,

  // Get Project Bids
  getProjectBidsLoading: false,
  getProjectBidsError: '',
  getProjectbidsSuccess: false,
  projectBids: [],
  totalBidsRecords: 0,
  bidsPerPage: 0,

  // Add Project Comment
  addingProjectCommentLoading: false,
  addingProjectCommentError: '',
  addingProjectCommentSuccess: false,

  // Get Project Comments
  getProjectCommentsLoading: false,
  projectComments: [],
  getProjectCommentsError: '',
  totalCommentsRecords: 0,
  commentsPerPage: 0,
  projectLastComment: null,

  // Rate Project Freelancer
  rateProjectLoading: false,
  rateProjectError: '',
  rateProjectSuccess: false,

  // Close Project
  closeProjectLoading: false,
  closeProjectError: '',
  closeProjectSuccess: false,

  // Close Project
  requestCloseProjectLoading: false,
  requestCloseProjectError: '',
  requestCloseProjectSuccess: false,

  //Handover Project Bid
  handoverProjectBidLoading: false,
  handoverProjectBidError: '',
  handoverProjectBidSuccess: false,

  //Indorse Project Bid
  indorseProjectBidLoading: false,
  indorseProjectBidError: '',
  indorseProjectBidSuccess: false,

  // Report Project Bid
  reportProjectBidLoading: false,
  reportProjectBidError: '',
  reportProjectBidSuccess: false,

  //Project Bid Reports
  projectBidReportsLoading: false,
  projectBidReportsError: '',
  projectBidReports: [],

  // Cancel Project Bid
  cancelProjectBidLoading: false,
  cancelProjectBidError: '',
  cancelProjectBidSuccess: false,

  // Saved Project
  savedProject: {},
};

const {
  GET_PROJECTS_LOADING,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAIL,

  GET_PROJECT_BY_ID_LOADING,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_BY_ID_FAIL,

  GET_USER_PROJECTS_LOADING,
  GET_USER_PROJECTS_SUCCESS,
  GET_USER_PROJECTS_FAIL,
  GET_USER_PROJECTS_RECEIVING_BIDS_LOADING,
  GET_USER_PROJECTS_RECEIVING_BIDS_SUCCESS,
  GET_USER_PROJECTS_RECEIVING_BIDS_FAIL,
  GET_USER_PROJECTS_IN_PROGRESS_LOADING,
  GET_USER_PROJECTS_IN_PROGRESS_SUCCESS,
  GET_USER_PROJECTS_IN_PROGRESS_FAIL,
  GET_USER_PROJECTS_CLOSED_LOADING,
  GET_USER_PROJECTS_CLOSED_SUCCESS,
  GET_USER_PROJECTS_CLOSED_FAIL,
  GET_USER_PROJECTS_CANCELLED_LOADING,
  GET_USER_PROJECTS_CANCELLED_SUCCESS,
  GET_USER_PROJECTS_CANCELLED_FAIL,

  GET_PROJECTS_PRICES_LOADING,
  GET_PROJECTS_PRICES_SUCCESS,
  GET_PROJECTS_PRICES_FAIL,

  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,

  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,

  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,

  ADD_PROJECT_BID_LOADING,
  ADD_PROJECT_BID_SUCCESS,
  ADD_PROJECT_BID_FAIL,

  GET_PROJECT_BIDS_LOADING,
  GET_PROJECT_BIDS_SUCCESS,
  GET_PROJECT_BIDS_FAIL,

  SELECT_PROJECT_BID_LOADING,
  SELECT_PROJECT_BID_SUCCESS,
  SELECT_PROJECT_BID_FAIL,

  ADD_PROJECT_COMMENT_LOADING,
  ADD_PROJECT_COMMENT_SUCCESS,
  ADD_PROJECT_COMMENT_FAIL,

  GET_PROJECT_COMMENTS_LOADING,
  GET_PROJECT_COMMENTS_SUCCESS,
  GET_PROJECT_COMMENTS_FAIL,
  GET_PROJECT_LAST_COMMENT_SUCCESS,

  CLOSE_PROJECT_LOADING,
  CLOSE_PROJECT_SUCCESS,
  CLOSE_PROJECT_FAIL,

  REQUEST_CLOSE_PROJECT_LOADING,
  REQUEST_CLOSE_PROJECT_SUCCESS,
  REQUEST_CLOSE_PROJECT_FAIL,

  RATE_PROJECT_LOADING,
  RATE_PROJECT_SUCCESS,
  RATE_PROJECT_FAIL,

  //Handover Project Bid
  HANDOVER_PROJECT_BID_LOADING,
  HANDOVER_PROJECT_BID_SUCCESS,
  HANDOVER_PROJECT_BID_FAIL,

  //Indorse Project Bid
  INDORSE_PROJECT_BID_LOADING,
  INDORSE_PROJECT_BID_SUCCESS,
  INDORSE_PROJECT_BID_FAIL,

  //Report Project Bid
  REPORT_PROJECT_BID_LOADING,
  REPORT_PROJECT_BID_SUCCESS,
  REPORT_PROJECT_BID_FAIL,

  //Get Project Bids Reports
  PROJECT_BID_REPORTS_LOADING,
  PROJECT_BID_REPORTS_SUCCESS,
  PROJECT_BID_REPORTS_FAIL,

  //Cancel Project Bid
  CANCEL_PROJECT_BID_LOADING,
  CANCEL_PROJECT_BID_SUCCESS,
  CANCEL_PROJECT_BID_FAIL,

  RESET_PROJECTS_ACTIONS,
} = TYPES;

export default function projectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get Projects
    case GET_PROJECTS_LOADING:
      return {
        ...state,
        getProjectsLoading: true,
        getProjectsError: '',
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        getProjectsLoading: false,
        getProjectsError: '',
        projects: action.payload.data,
        totalProjects: action.payload.totalRecords,
        projectsPerPage: action.payload.itemsPerPage,
      };
    case GET_PROJECTS_FAIL:
      return {
        ...state,
        getProjectLoading: false,
        getProjectError: action.error,
      };

    // Get User Projects
    case GET_USER_PROJECTS_LOADING:
      return {
        ...state,
        getUserProjectsLoading: true,
        getUserProjectsError: '',
      };
    case GET_USER_PROJECTS_SUCCESS:
      return {
        ...state,
        getUserProjectsLoading: false,
        getUserProjectsError: '',
        userProjects: action.payload.data,
        totalRecords: action.payload.totalRecords,
        itemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_PROJECTS_RECEIVING_BIDS_LOADING:
      return {
        ...state,
        getUserProjectsReceivingLoading: true,
      };
    case GET_USER_PROJECTS_RECEIVING_BIDS_SUCCESS:
      return {
        ...state,
        getUserProjectsReceivingLoading: false,
        getUserProjectsReceivingError: '',
        userProjectsReceiving: action.payload.data,
        receivingTotalRecords: action.payload.totalRecords,
        receivingItemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_PROJECTS_RECEIVING_BIDS_FAIL:
      return {
        ...state,
        getUserProjectsReceivingLoading: false,
        getUserProjectsReceivingError: action.error,
      };
    case GET_USER_PROJECTS_IN_PROGRESS_LOADING:
      return {
        ...state,
        getUserProjectsProgressLoading: true,
      };
    case GET_USER_PROJECTS_IN_PROGRESS_SUCCESS:
      return {
        ...state,
        getUserProjectsProgressLoading: false,
        getUserProjectsError: '',
        userProjectsProgress: action.payload.data,
        progressTotalRecords: action.payload.totalRecords,
        progressItemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_PROJECTS_IN_PROGRESS_FAIL:
      return {
        ...state,
        getUserProjectsProgressLoading: false,
        getUserProjectsReceivingError: action.error,
      };
    case GET_USER_PROJECTS_CLOSED_LOADING:
      return {
        ...state,
        getUserProjectsClosedLoading: true,
      };
    case GET_USER_PROJECTS_CLOSED_SUCCESS:
      return {
        ...state,
        getUserProjectsClosedLoading: false,
        getUserProjectsError: '',
        userProjectsClosed: action.payload.data,
        closedTotalRecords: action.payload.totalRecords,
        closedItemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_PROJECTS_CLOSED_FAIL:
      return {
        ...state,
        getUserProjectsClosedLoading: false,
        getUserProjectsClosedError: action.error,
      };
    case GET_USER_PROJECTS_CANCELLED_LOADING:
      return {
        ...state,
        getUserProjectsCancelledLoading: true,
      };
    case GET_USER_PROJECTS_CANCELLED_SUCCESS:
      return {
        ...state,
        getUserProjectsCancelledLoading: false,
        getUserProjectsCancelledError: '',
        userProjectsCancelled: action.payload.data,
        cancelledTotalRecords: action.payload.totalRecords,
        cancelledItemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_PROJECTS_CANCELLED_FAIL:
      return {
        ...state,
        getUserProjectsCancelledLoading: false,
        getUserProjectsCancelledError: action.error,
      };
    case GET_USER_PROJECTS_FAIL:
      return {
        ...state,
        getUserProjectsLoading: false,
        getUserProjectsError: action.error,
      };

    // Get User Project By ID
    case GET_PROJECT_BY_ID_LOADING:
      return {
        ...state,
        getProjectByIdLoading: true,
        getProjectByIdError: '',
        projectBids: [],
        projectComments: [],
      };
    case GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        getProjectByIdLoading: false,
        getProjectByIdError: '',
        currentProject: action.payload[0],
      };
    case GET_PROJECT_BY_ID_FAIL:
      return {
        ...state,
        getProjectByIdLoading: false,
        getProjectByIdError: action.error,
      };
    case CLEAR_CURRENT_PROJECT:
      return {
        ...state,
        currentProject: null,
      };

    // Get Projects Prices
    case GET_PROJECTS_PRICES_LOADING:
      return {
        ...state,
        getProjectsPricesLoading: true,
      };
    case GET_PROJECTS_PRICES_SUCCESS:
      return {
        ...state,
        getProjectsPricesLoading: false,
        projectsPrices: action.payload,
      };
    case GET_PROJECTS_PRICES_FAIL:
      return {
        ...state,
        getProjectsPricesLoading: false,
        getProjectsPricesError: action.error,
      };

    // Add Project
    case ADD_PROJECT_LOADING:
      return {
        ...state,
        addingProjectLoading: true,
        addingProjectError: '',
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addingProjectLoading: false,
        addingProjectError: '',
        addedProject: [action.payload],
        addingProjectSuccess: action.status === 'success',
      };
    case ADD_PROJECT_FAIL:
      return {
        ...state,
        addingProjectLoading: false,
        addingProjectError: action.error,
      };
    case CLEAR_ADD_PROJECT_SUCCESS:
      return {
        ...state,
        addingProjectSuccess: false,
        editingProjectSuccess: false,
      };

    // Edit Project
    case EDIT_PROJECT_LOADING:
      return {
        ...state,
        editingProjectLoading: true,
        editingProjectError: '',
      };
    case EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        editingProjectLoading: false,
        editingProjectError: '',
        addedProject: [action.payload],
        editingProjectSuccess: action.status === 'success',
      };
    case EDIT_PROJECT_FAIL:
      return {
        ...state,
        editingProjectLoading: false,
        editingProjectError: action.error,
      };

    // Delete Project
    case DELETE_PROJECT_LOADING:
      return {
        ...state,
        deletingProjectLoading: true,
        deletingProjectError: '',
      };
    case DELETE_PROJECT_SUCCESS:
      let newProjects = state.projects.filter(
        project => project.id !== action.id,
      );
      return {
        ...state,
        deletingProjectLoading: false,
        deletingProjectError: '',
        projects: newProjects,
        deletingProjectSuccess: action.status === 'success',
      };
    case DELETE_PROJECT_FAIL:
      return {
        ...state,
        deletingProjectLoading: false,
        deletingProjectError: action.error,
      };
    case CLEAR_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deletingProjectSuccess: false,
      };

    // Add Project Bid
    case ADD_PROJECT_BID_LOADING:
      return {
        ...state,
        addingProjectBidLoading: true,
      };
    case ADD_PROJECT_BID_SUCCESS:
      return {
        ...state,
        addingProjectBidLoading: false,
        addingProjectBidError: '',
        addingProjectBidSuccess: true,
      };
    case ADD_PROJECT_BID_FAIL:
      return {
        ...state,
        addingProjectBidLoading: false,
        addingProjectBidError: action.error,
      };
    case CLEAR_ADD_PROJECT_BID_SUCCESS:
      return {
        ...state,
        addingProjectBidSuccess: false,
      };

    // Get Project Bids
    case GET_PROJECT_BIDS_LOADING:
      return {
        ...state,
        getProjectBidsLoading: true,
        projectBids: [],
      };
    case GET_PROJECT_BIDS_SUCCESS:
      return {
        ...state,
        getProjectBidsLoading: false,
        getProjectBidsError: '',
        projectBids: action.payload.data,
        totalBidsRecords: action.payload.totalRecords,
        bidsPerPage: action.payload.itemsPerPage,
      };
    case GET_PROJECT_BIDS_FAIL:
      return {
        ...state,
        getProjectBidsLoading: false,
        getProjectBidsError: action.error,
        projectBids: [],
      };

    case RESET_PROJECTS_ACTIONS:
      return {
        ...state,
        ...INITIAL_STATE,
        projects: state.projects,
      };

    /*
      SELECT_PROJECT_BID_LOADING,
      SELECT_PROJECT_BID_SUCCESS,
      SELECT_PROJECT_BID_FAIL,
      SELECT_PROJECT_BID,
      
      */

    case SELECT_PROJECT_BID_LOADING:
      return {
        ...state,
        selectProjectBidLoading: true,
        selectProjectBidError: '',
      };
    case SELECT_PROJECT_BID_SUCCESS:
      return {
        ...state,
        selectProjectBidLoading: false,
        selectProjectBidError: '',
        selectProjectBidSuccess: true,
      };
    case SELECT_PROJECT_BID_FAIL:
      return {
        ...state,
        selectProjectBidLoading: false,
        selectProjectBidError: action.error,
      };
    case CLEAR_SELECT_PROJECT_BID_SUCCESS:
      return {
        ...state,
        selectProjectBidSuccess: false,
      };

    // Add Project Comment
    case ADD_PROJECT_COMMENT_LOADING:
      return {
        ...state,
        addingProjectCommentLoading: true,
        addingProjectCommentError: '',
      };
    case ADD_PROJECT_COMMENT_SUCCESS:
      return {
        ...state,
        addingProjectCommentLoading: false,
        addingProjectCommentError: '',
        addedProjectComment: [action.payload.data],
        addingProjectCommentSuccess: true,
      };
    case ADD_PROJECT_COMMENT_FAIL:
      return {
        ...state,
        addingProjectCommentLoading: false,
        addingProjectCommentError: action.error,
      };

    // Get Project Comments
    case GET_PROJECT_COMMENTS_LOADING:
      return {
        ...state,
        getProjectCommentsLoading: true,
        addingProjectCommentSuccess: false,
      };
    case GET_PROJECT_COMMENTS_SUCCESS:
      return {
        ...state,
        getProjectCommentsLoading: false,
        projectComments: action.payload.data,
        totalCommentsRecords: action.payload.totalRecords,
        commentsPerPage: action.payload.itemsPerPage,
      };
    case GET_PROJECT_COMMENTS_FAIL:
      return {
        ...state,
        getProjectCommentsLoading: false,
        getProjectCommentsError: action.error,
      };
    case GET_PROJECT_LAST_COMMENT_SUCCESS:
      return {
        ...state,
        projectLastComment: action.payload,
      };

    /*
    RATE_PROJECT_FREELANCER
    */
    case RATE_PROJECT_LOADING:
      return {
        ...state,
        rateProjectLoading: true,
        rateProjectError: '',
      };
    case RATE_PROJECT_SUCCESS:
      return {
        ...state,
        rateProjectLoading: false,
        rateProjectError: '',
        rateProjectSuccess: true,
      };
    case RATE_PROJECT_FAIL:
      return {
        ...state,
        rateProjectLoading: false,
        rateProjectError: action.error,
      };
    case CLEAR_RATE_PROJECT_SUCCESS:
      return {
        ...state,
        rateProjectSuccess: false,
      };

    /*
    CLOSE_PROJECT
    */

    case CLOSE_PROJECT_LOADING:
      return {
        ...state,
        closeProjectLoading: true,
        closeProjectError: '',
      };
    case CLOSE_PROJECT_SUCCESS:
      return {
        ...state,
        closeProjectLoading: false,
        closeProjectError: '',
        closeProjectSuccess: true,
      };
    case CLOSE_PROJECT_FAIL:
      return {
        ...state,
        closeProjectLoading: false,
        closeProjectError: action.error,
      };
    case CLEAR_CLOSE_PROJECT_SUCCESS:
      return {
        ...state,
        closeProjectSuccess: false,
      };

    /*
    REQUEST_CLOSE_PROJECT
    */

    case REQUEST_CLOSE_PROJECT_LOADING:
      return {
        ...state,
        requestCloseProjectLoading: true,
        requestCloseProjectError: '',
      };
    case REQUEST_CLOSE_PROJECT_SUCCESS:
      return {
        ...state,
        requestCloseProjectLoading: false,
        requestCloseProjectError: '',
        requestCloseProjectSuccess: true,
      };
    case REQUEST_CLOSE_PROJECT_FAIL:
      return {
        ...state,
        requestCloseProjectLoading: false,
        requestCloseProjectError: action.error,
      };
    case CLEAR_REQUEST_CLOSE_PROJECT_SUCCESS:
      return {
        ...state,
        requestCloseProjectSuccess: false,
      };

    //Handover Project Bid
    case HANDOVER_PROJECT_BID_LOADING:
      return {
        ...state,
        handoverProjectBidLoading: true,
        handoverProjectBidError: '',
      };
    case HANDOVER_PROJECT_BID_SUCCESS:
      return {
        ...state,
        handoverProjectBidLoading: false,
        handoverProjectBidError: '',
        handoverProjectBidSuccess: true,
      };
    case HANDOVER_PROJECT_BID_FAIL:
      return {
        ...state,
        handoverProjectBidLoading: false,
        handoverProjectBidError: action.error,
      };
    case CLEAR_HANDOVER_PROJECT_BID_SUCCESS:
      return {
        ...state,
        handoverProjectBidSuccess: false,
      };
    //Indorse Project Bid
    case INDORSE_PROJECT_BID_LOADING:
      return {
        ...state,
        indorseProjectBidLoading: true,
        indorseProjectBidError: '',
      };
    case INDORSE_PROJECT_BID_SUCCESS:
      return {
        ...state,
        indorseProjectBidLoading: false,
        indorseProjectBidError: '',
        indorseProjectBidSuccess: true,
      };
    case INDORSE_PROJECT_BID_FAIL:
      return {
        ...state,
        indorseProjectBidLoading: false,
        indorseProjectBidError: action.error,
      };
    case CLEAR_INDORSE_PROJECT_BID_SUCCESS:
      return {
        ...state,
        indorseProjectBidSuccess: false,
      };
    //Report Project bid
    case REPORT_PROJECT_BID_LOADING:
      return {
        ...state,
        reportProjectBidLoading: true,
        reportProjectBidError: '',
      };
    case REPORT_PROJECT_BID_SUCCESS:
      return {
        ...state,
        reportProjectBidLoading: false,
        reportProjectBidError: '',
        reportProjectBidSuccess: true,
      };
    case REPORT_PROJECT_BID_FAIL:
      return {
        ...state,
        reportProjectBidLoading: false,
        reportProjectBidError: action.error,
      };
    case CLEAR_REPORT_PROJECT_BID_SUCCESS:
      return {
        ...state,
        reportProjectBidSuccess: false,
      };
    // Get Project Bid Reports
    case PROJECT_BID_REPORTS_LOADING:
      return {
        ...state,
        projectBidReportsLoading: true,
        projectBidReportsError: '',
      };
    case PROJECT_BID_REPORTS_SUCCESS:
      return {
        ...state,
        projectBidReportsLoading: false,
        projectBidReportsError: '',
        projectBidReports: action.payload.data,
      };
    case PROJECT_BID_REPORTS_FAIL:
      return {
        ...state,
        projectBidReportsLoading: false,
        projectBidReportsError: action.error,
      };
    case CLEAR_PROJECT_BID_REPORTS:
      return {
        ...state,
        projectBidReportsError: '',
        projectBidReports: [],
      };
    //Cancel Project Bid
    case CANCEL_PROJECT_BID_LOADING:
      return {
        ...state,
        cancelProjectBidLoading: true,
        cancelProjectBidError: '',
      };
    case CANCEL_PROJECT_BID_SUCCESS:
      return {
        ...state,
        cancelProjectBidLoading: false,
        cancelProjectBidError: '',
        cancelProjectBidSuccess: true,
      };
    case CANCEL_PROJECT_BID_FAIL:
      return {
        ...state,
        cancelProjectBidLoading: false,
        cancelProjectBidError: action.error,
      };
    case CLEAR_CANCEL_PROJECT_BID_SUCCESS:
      return {
        ...state,
        cancelProjectBidSuccess: false,
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        projects: [],
        totalProjects: 0,
        projectsPerPage: 0,
      };
    case SAVE_PROJECT:
      return {
        ...state,
        savedProject: action.payload,
      };
    case CLEAR_SAVED_PROJECT:
      return {
        ...state,
        savedProject: {},
      };
    default:
      return state;
  }
}
