// Actions for Adding Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  ADD_ARTICLE_LOADING,
  ADD_ARTICLE_SUCCESS,
  ADD_ARTICLE_FAIL,
} = TYPES;

export const addArticle = ({ title, cat_id, sub_cat_id, keywords, description, image, id }) => {
  return async function(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
          type: ADD_ARTICLE_LOADING
      });
      const body = {title, cat_id, sub_cat_id, keywords, description, image, id}
      try {
          let response = await axiosInstance.post(
            API.ARTICLES, 
            body, 
            tokenConfig(getState)
          );
          if(response.status !== 200){
              dispatch({
                  type: ADD_ARTICLE_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: ADD_ARTICLE_SUCCESS,
                  payload: response.data
              })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'ADD_ARTICLE_FAIL'));
            dispatch ({ 
                type: ADD_ARTICLE_FAIL 
            });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_ARTICLE_FAIL')),
                dispatch ({ 
                    type: ADD_ARTICLE_FAIL 
                })
            )
          }
      }
  }
};

const exportedObject = {
  addArticle
};

export default exportedObject;
