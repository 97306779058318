import TYPES from "../actions/types";

const INITIAL_STATE = {
  // Contests
  getContestsLoading: false,
  getContestsReceivingLoading: false,
  getContestsProgressLoading: false,
  getContestsClosedLoading: false,
  getContestsError: "",
  contests: [],
  totalContests: 0,
  contestsPerPage: 0,
};

const {
  GET_CONTESTS_LOADING,
  GET_CONTESTS_SUCCESS,
  GET_CONTESTS_FAIL,
} = TYPES;

export default function contestsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get Contests
    case GET_CONTESTS_LOADING:
      return {
        ...state,
        getContestsLoading: true,
        getContestsError: "",
      };
    case GET_CONTESTS_SUCCESS:
      return {
        ...state,
        getContestsLoading: false,
        getContestsError: "",
        contests: action.payload.data,
        totalContests: action.payload.totalRecords,
        contestsPerPage: action.payload.itemsPerPage,
      };
    case GET_CONTESTS_FAIL:
      return {
        ...state,
        getContestsLoading: false,
        getContestsError: action.error,
      };

    default:
      return state;
  }
}
