import React from 'react';
import { I18n } from 'react-redux-i18n';

export default function StatusLabel({status_id, type}){
    return (
        status_id && type && type === "project" ?
            <div 
            className={ 
                "Project__Status " + 
                (status_id === 8 ? 
                "Project__Status__Receiving__Bids" 
                : status_id === 5 ?
                "Project__Status__In__Progress"
                : status_id === 6 ?
                "Project__Status__Active"
                : status_id === 2 ?
                "Project__Status__In__Active"
                : status_id === 1 ?
                "Project__Status__Active"
                : status_id === 9 ?
                "Project__Status__Delivered"
                : status_id === 7 ?
                "Project__Status__Closed"
                : null
                )
            }>
            { 
                status_id === 8 ?
                I18n.t('receiving_bids')
                : status_id === 5 ?
                I18n.t('in_progress')
                : status_id === 6 ?
                I18n.t('closed')
                : status_id === 2 ?
                I18n.t('in_active')
                : status_id === 1 ?
                I18n.t('active')
                : status_id === 9 ?
                I18n.t('delivered')
                : status_id === 7 ?
                I18n.t('canceled')
                : null
            }
            </div>
        : status_id && type && type === "service_invoice" ?
            <div 
            className={ 
                "Project__Status " + 
                (status_id === 4 ? 
                "Project__Status__Receiving__Bids" 
                : status_id === 5 ?
                "Project__Status__In__Progress"
                : status_id === 6 ?
                "Project__Status__Active"
                : status_id === 7 ?
                "Project__Status__Closed"
                : status_id === 9 ?
                "Project__Status__Delivered"
                : null
                )
            }>
            { 
                status_id === 4 ?
                I18n.t('waiting_requirement')
                : status_id === 5 ?
                I18n.t('in_progress')
                : status_id === 6 ?
                I18n.t('closed')
                : status_id === 7 ?
                I18n.t('canceled')
                : status_id === 9 ?
                I18n.t('delivered')
                : null
            }
            </div>
        : status_id && type && type === "withdrawal" ?
            <div 
            className={ 
                "Project__Status " + 
                (status_id === "0" ? 
                "Project__Status__Receiving__Bids"
                : status_id === "1" ?
                "Project__Status__Active"
                : null
                )
            }>
                { 
                    status_id === "0" ?
                    I18n.t('withdrawal_pending')
                    : status_id === "1" ?
                    I18n.t('withdrawal_sent')
                    : null
                }
            </div>
        : null
    )
}