import React, {useEffect, useState, useCallback} from 'react';
import {Form, InputGroup} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';
import DatePicker from 'react-datepicker';
import DatePickerInput from './DatePickerInput';
import 'react-datepicker/dist/react-datepicker.css';

const {Group, Label, Control} = Form;
const {Feedback} = Control;
const {Prepend, Text} = InputGroup;

export default function DateInput({
  name,
  minDate,
  onChange,
  onBlur,
  controlId = '',
  label = '',
  desc = '',
  value = '',
  feedback = '',
  feedbackType = 'invalid',
  placeholder = '',
  prepandText = '',
  required = true,
  isInvalid = false,
  disabled = false,
  dateFormat = '',
  showTimeSelect = false,
  inputExtraProps = {},
  showMonthDropdown = false,
  showYearDropdown = false,
  dateFilter = null,
}) {
  placeholder = placeholder ? I18n.t(placeholder) : '';

  var [selected, setSelected] = useState();

  const onDateChange = useCallback(
    date => {
      setSelected(date);
      onChange(date);
    },
    [onChange],
  );

  useEffect(() => {
    if (value) {
      onDateChange(value);
    }
  }, [value, onDateChange]);

  return (
    <Group controlId={controlId}>
      <Label>
        <Translate value={label} />
        {required ? (
          <small className="required"> ({I18n.t('required')})</small>
        ) : (
          <small className="optional"> ({I18n.t('optional')})</small>
        )}
      </Label>

      {desc ? (
        <small>
          <Translate value={desc} />
        </small>
      ) : null}

      <InputGroup className="mb-2">
        {prepandText ? (
          <Prepend>
            <Text>
              <Translate value={prepandText} />
            </Text>
          </Prepend>
        ) : null}

        <DatePicker
          minDate={minDate}
          disabled={disabled}
          name={name}
          onChange={onDateChange}
          selected={selected}
          placeholderText={placeholder}
          className={
            isInvalid
              ? 'form-control custom-date-input is-invalid'
              : 'form-control custom-date-input'
          }
          showTimeSelect={showTimeSelect}
          dateFormat={dateFormat}
          onBlur={onBlur}
          isInvalid={isInvalid}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          {...inputExtraProps}
          customInput={<DatePickerInput />}
          filterDate={
            dateFilter
              ? d => {
                  return dateFilter > d;
                }
              : null
          }
        />

        {isInvalid && feedback ? (
          <Feedback
            type={feedbackType}
            className={isInvalid ? 'd-block' : null}>
            {feedback}
          </Feedback>
        ) : null}
      </InputGroup>
    </Group>
  );
}
