import React, { Fragment, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Spin from "../Spin";
import { I18n } from "react-redux-i18n";
import NoItems from '../NoItems';
import { clearErrors } from "../../actions/errorActions";
import AddingRow from "../../components/AddingRow";
import AddingPaymentModal from "../AddingPaymentModal";
import ConfToast from "../ConfToast";
import MyBankAccount from "./MyBankAccount";
import MyWithdrawalRequests from "./MyWithdrawalRequests";

const Withdrawals = () => {

    const dispatch = useDispatch();

    const {
        user,
        getUserDataLoading,
        isLoading,

        addingPaypalAccountLoading,
    } = useSelector((state) => ({
        user: state.userData.user,
        getUserDataLoading: state.userData.getUserDataLoading,
        isLoading: state.auth.isLoading,

        addingPaypalAccountLoading: state.payments.addingPaypalAccountLoading,
    }));

    const [lgShow, setLgShow] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };

    return (
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <AddingPaymentModal
                loading={addingPaypalAccountLoading}
                modalTitle={"paypal_accounnt"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
                paymentType="PayPal"
            />
            <div className="container">
                <div className="row">
                    <div className="col-12 Dashboard__tasks__List">
                        <Tabs defaultActiveKey="paypal" id="JoinedStats" unmountOnExit={true}>
                            <Tab eventKey="paypal" title={I18n.t('paypal')}>
                                <TitleComponent title={I18n.t('lancersin_withdrawal_accounts')} />
                                <MetaComponent />
                                <div className="alert alert-warning mb-4 mt-0 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                                    <p className="my-1">{I18n.t('paypal_note')}</p>
                                </div>
                                <AddingRow
                                    onAdding={() => {
                                        handleShow();
                                    }}
                                    title={"paypal_accounnt"}
                                    //titleIconClassName={"icon-services"}
                                    btnText={user && user.paypal_email ? "edit_accounnt" : "add_accounnt"}
                                    btnIconClassName={user && user.paypal_email ? "icon-edit" : "icon-plus"}
                                    forceShowButton={true}
                                />
                                    {
                                    getUserDataLoading || isLoading ?
                                        <Spin />
                                    : user && user.paypal_email ?
                                    <div className="Profile-Card box-shadow">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="userProfile__Contacts">
                                                    <div><b>{I18n.t('email')}:</b> {user.paypal_email}</div>
                                                </div>
                                            </div>
                                            {
                                                user.payment_method === "paypal" ?
                                                    <div className="col-12 mt-3">
                                                        <div className="account__type__types">
                                                            <div className="form-group">
                                                                <div className="form-check">
                                                                    <input type="checkbox" id="bankAccountData" name="bankAccountData" value="1" className="form-check-input" checked disabled />
                                                                    <label title="" htmlFor="bankAccountData" className="form-check-label">{I18n.t('make_default_payment_method')}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    </div>
                                    :
                                    <Fragment>
                                        <div className="row">
                                            <div className="col-12">
                                                <NoItems
                                                    title="paypal"
                                                    message="no_paypal_account"
                                                />
                                            </div>
                                        </div>
                                    </Fragment>
                                    }
                            </Tab>
                            <Tab eventKey="bankAccount" title={I18n.t('bank_account')}>
                                <MyBankAccount />
                            </Tab>
                            <Tab eventKey="withdrawalRequests" title={I18n.t('withdrawal_requests')}>
                                <MyWithdrawalRequests />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Withdrawals;
