import {
    PORTFOLIO_DATA_LOADING,
    ADD_PORTFOLIO_SUCCESS, 
    ADD_PORTFOLIO_FAIL,
    RESET_PORTFOLIO_ITEM
} from '../actions/types';

const initialState = {
    isLoading: false,
    portfolioItem: "",
}

export default function addportfolioitemReducer(state = initialState, action) {
    switch (action.type) {
        case PORTFOLIO_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case ADD_PORTFOLIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                portfolioItem: action.payload.data
            };
        case ADD_PORTFOLIO_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case RESET_PORTFOLIO_ITEM: 
            return{
                ...state,
                portfolioItem: "",
            };
        default:
            return state;
    }
}