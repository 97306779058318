import { GET_ALL_PORTFOLIOS_SUCCESS, ALL_PORTFOLIOS_ITEMS_LOADING, GET_ALL_PORTFOLIOS_FAIL } from '../actions/types';

const initialState = {
    allportfolios: [],
    totalRecords: 0,
    itemsPerPage: '0',
    loadingallportfolios: false
}

export default function portfoliosReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ALL_PORTFOLIOS_SUCCESS:
            return{
                ...state,
                allportfolios: action.payload.data,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                loadingallportfolios: false
            }    
        ;
        case ALL_PORTFOLIOS_ITEMS_LOADING: 
            return{
                ...state,
                loadingallportfolios: true
            }
        ;
        case GET_ALL_PORTFOLIOS_FAIL:
            return {
                ...state,
                loadingallportfolios: false
            }
        ;
        default:
            return state;
    }
}