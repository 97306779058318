import React, {useState} from 'react';
import {I18n} from 'react-redux-i18n';
import {Tabs, Tab, Accordion, Card} from 'react-bootstrap';

function FAQ() {
  const [generalActiveId, setGeneralActiveId] = useState('0');
  const [projectActiveId, setProjectActiveId] = useState('0');
  const [serviceActiveId, setServiceActiveId] = useState('0');
  function toggleGeneralActive(id) {
    if (generalActiveId === id) {
      setGeneralActiveId(null);
    } else {
      setGeneralActiveId(id);
    }
  }
  function toggleProjectActive(id) {
    if (projectActiveId === id) {
      setProjectActiveId(null);
    } else {
      setProjectActiveId(id);
    }
  }
  function toggleServiceActive(id) {
    if (serviceActiveId === id) {
      setServiceActiveId(null);
    } else {
      setServiceActiveId(id);
    }
  }
  return (
    <section className="HowWorks">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{I18n.t('faq')}</h2>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Tabs id="HowTabs" unmountOnExit={true}>
              <Tab eventKey="General" title={I18n.t('general')}>
                <Accordion
                  defaultActiveKey={generalActiveId ? generalActiveId : '0'}>
                  <Card
                    className={generalActiveId === '0' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('0')}
                      eventKey="0">
                      {I18n.t('faq_q1')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>{I18n.t('faq_a1')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '1' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('1')}
                      eventKey="1">
                      {I18n.t('faq_q2')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>{I18n.t('faq_a2')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '2' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('2')}
                      eventKey="2">
                      {I18n.t('faq_q3')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>{I18n.t('faq_a3')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '3' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('3')}
                      eventKey="3">
                      {I18n.t('faq_q4')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>{I18n.t('faq_a4')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '4' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('4')}
                      eventKey="4">
                      {I18n.t('faq_q5')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>{I18n.t('faq_a5')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '5' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('5')}
                      eventKey="5">
                      {I18n.t('faq_q6')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>{I18n.t('faq_a6')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={generalActiveId === '6' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleGeneralActive('6')}
                      eventKey="6">
                      {I18n.t('faq_q7')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>{I18n.t('faq_a7')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>
              <Tab eventKey="Projects" title={I18n.t('projects')}>
                <Accordion
                  defaultActiveKey={projectActiveId ? projectActiveId : '0'}>
                  <Card
                    className={projectActiveId === '0' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleProjectActive('0')}
                      eventKey="0">
                      {I18n.t('faq_q8')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>{I18n.t('faq_a8')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={projectActiveId === '1' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleProjectActive('1')}
                      eventKey="1">
                      {I18n.t('faq_q9')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>{I18n.t('faq_a9')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={projectActiveId === '2' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleProjectActive('2')}
                      eventKey="2">
                      {I18n.t('faq_q10')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>{I18n.t('faq_a10')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={projectActiveId === '3' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleProjectActive('3')}
                      eventKey="3">
                      {I18n.t('faq_q11')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>{I18n.t('faq_a11')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>
              <Tab eventKey="Services" title={I18n.t('services')}>
                <Accordion
                  defaultActiveKey={serviceActiveId ? serviceActiveId : '0'}>
                  <Card
                    className={serviceActiveId === '0' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleServiceActive('0')}
                      eventKey="0">
                      {I18n.t('faq_q12')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>{I18n.t('faq_a12')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={serviceActiveId === '1' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleServiceActive('1')}
                      eventKey="1">
                      {I18n.t('faq_q13')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>{I18n.t('faq_a13')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={serviceActiveId === '2' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleServiceActive('2')}
                      eventKey="2">
                      {I18n.t('faq_q14')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>{I18n.t('faq_a14')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={serviceActiveId === '3' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleServiceActive('3')}
                      eventKey="3">
                      {I18n.t('faq_q15')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>{I18n.t('faq_a15')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card
                    className={serviceActiveId === '4' ? 'active-panel' : ''}>
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => toggleServiceActive('4')}
                      eventKey="4">
                      {I18n.t('faq_q16')}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>{I18n.t('faq_a16')}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
