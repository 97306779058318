// Action Types

/**
 * createTypes is a function to create CONSTANT action types instead of regular strings to prevent typo mistakes.
 * @param  {array} strings
 */
function createTypes(strings, ...types) {
  types = [...strings, types];
  types = types.join("\n");
  types = types.split("\n");
  const TYPES = {};
  for (let type of types) {
    if (type === "") {
      continue;
    }
    TYPES[type] = type;
  }
  return TYPES;
}

/**
 * createOurSteps is a function to create the four types for given action.
 * @method
 * @param {string} PREFEX - The PREFEX for each generated type.
 */
function createSteps(PREFEX) {
  const OUR_STEPS = ["loading", "success", "fail"];

  const OUR_STEP_CREATED = [PREFEX.toUpperCase()];

  OUR_STEPS.forEach((STEP) =>
    OUR_STEP_CREATED.push(`${PREFEX}_${STEP}`.toUpperCase())
  );

  return OUR_STEP_CREATED.join("\n");
}

const types = [
  "GET_NOTIFICATIONS",
  "GET_LAST_NOTIFICATION",
  "GET_ALL_NOTIFICATIONS",
  "MARK_NOTIFICATION_READ",
  "INVITE_USER",
  "MARK_ALL_NOTIFICATIONS_READ",
];

export default createTypes`
RESET_PROJECTS_ACTIONS

${types.map(createSteps).join("\n")}
`;

export const CLEAR_LAST_NOTIFICATION = "CLEAR_LAST_NOTIFICATION";
export const CLEAR_MARK_NOTIFICATION_READ_SUCCESS = "CLEAR_MARK_NOTIFICATION_READ_SUCCESS";
export const CLEAR_INVITE_USER_SUCCESS = "CLEAR_INVITE_USER_SUCCESS";
export const CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS = "CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS";
