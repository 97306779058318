import React, {useState, useEffect, Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import ReactPlayer from 'react-player';
import {Num} from '../Numbers';
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';
import {getfreelancerPortfolioItem} from '../../actions/freelancerportfolioitemActions';
import {getPortfolioComments} from '../../actions/portfoliocommentsActions';
import {
  getportfolioitemLikes,
  addportfolioitemLike,
} from '../../actions/portfoliolikesActions';
import {followUser, unfollowUser} from '../../actions/followActions';
import {deleteportfolioitem} from '../../actions/deleteportfolioActions';
import Spin from '../Spin';
import NoItems from '../NoItems';
import ConfToast from '../ConfToast';
import {MetaComponent} from '../../components/MetaComponent';
import PortfolioItemComments from './PortfolioItemComments';
import {TitleComponent} from '../TitleComponent';
import DeleteModal from '../DeleteModal';
import AddingPortfolioItem from '../AddingPortfolioModal';
import {RESET_PORTFOLIO_ITEM} from '../../actions/types';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import ReportContentModal from '../ReportContentModal';
import NoPermisions from '../NoPermissions';
import RatingStarsPreview from '../RatingStarsPreview';
import ConnectionLost from '../ConnectionLost';
import {getFollowings} from '../../redux/followers/actions/getActions';
import {
  CLEAR_UNFOLLOW_USER_SUCCESS,
  CLEAR_FOLLOW_USER_SUCCESS,
} from '../../actions/types';
import StatusLabel from '../../StatusLabel';

export default function MyPortfolioDetails(props) {
  const dispatch = useDispatch();

  const {id} = props.match.params;

  const {
    isAuthenticated,
    user,
    freelancerportfolioitem,
    loadingportfolioitem,
    portfolioitemComments,
    isLoading,
    portfolioItemEdited,
    addPortfolioLikeLoading,
    portfolioitemLikeAdded,
    portfolioLikesLoading,
    portfolioitemLikes,
    followUserLoading,
    followDone,
    unFollowUserLoading,
    unfollowDone,
    followings,
    deletePortfolioItemLoading,
    portfolioItemDeleted,
    postReportLoading,
    errorId,
    errorMessage,
    locale,
    lastNotification,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    freelancerportfolioitem: state.freelancerportfolioitem.portfolioitem,
    loadingportfolioitem: state.freelancerportfolioitem.loadingportfolioitem,
    portfolioitemComments: state.portfolioitemcomments.portfolioitemComments,
    isLoading: state.addportfolioitem.isLoading,
    portfolioItemEdited: state.addportfolioitem.portfolioItem,
    addPortfolioLikeLoading: state.portfolioitemLikes.addPortfolioLikeLoading,
    portfolioitemLikeAdded: state.portfolioitemLikes.portfolioitemLikeAdded,
    portfolioLikesLoading: state.portfolioitemLikes.portfolioLikesLoading,
    portfolioitemLikes: state.portfolioitemLikes.portfolioitemLikes,
    followUserLoading: state.followUser.followUserLoading,
    followDone: state.followUser.followDone,
    unFollowUserLoading: state.followUser.unFollowUserLoading,
    unfollowDone: state.followUser.unfollowDone,
    followings: state.followers.followings,
    deletePortfolioItemLoading: state.deleteportfolioItem.isLoading,
    portfolioItemDeleted: state.deleteportfolioItem.portfolioItemDeleted,
    postReportLoading: state.reportsTypes.isLoading,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    locale: state.i18n.locale,
    lastNotification: state.notifications.lastNotification,
  }));

  useEffect(() => {
    if (id) {
      const ItemData = {
        id,
        locale,
      };
      const portfolio_id = id;
      const page = 1;
      const limit = 30;
      dispatch(getfreelancerPortfolioItem(ItemData));
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      dispatch(getportfolioitemLikes({portfolio_id}));
      setPortfolioItemId(id);
    }
  }, [id, dispatch, locale]);

  useEffect(() => {
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'CommentPortfolio' &&
      window.location.href.indexOf('/portfolio') > -1 &&
      lastNotification.notification_content.content.portfolio.id.toString() ===
        id
    ) {
      const portfolio_id = id;
      const page = 1;
      const limit = 30;
      dispatch(getPortfolioComments(portfolio_id, page, limit));
    }
  }, [lastNotification, dispatch, id]);

  useEffect(() => {
    if (isAuthenticated && freelancerportfolioitem.length > 0) {
      const page = 1;
      const limit = 20;
      const user_id = freelancerportfolioitem[0].user.id;
      dispatch(getFollowings(page, limit, user_id));
    }
  }, [dispatch, isAuthenticated, freelancerportfolioitem]);

  const [portfolioItemId, setPortfolioItemId] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [loadingPortfolioItem, setLoadingPortfolioItem] = useState(true);
  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = () => setShowDeleteConfirm(true);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    setLoadingPortfolioItem(loadingportfolioitem);
  }, [loadingportfolioitem]);

  const portfolioitem = freelancerportfolioitem[0];

  function handleEditOnClick() {
    setToggleEditModal(!toggleEditModal);
  }
  function handleLike() {
    const action = 'like';
    const portfolio_id = id;
    //create like object
    const likeData = {
      action,
      portfolio_id,
    };
    //attempt to like item
    dispatch(addportfolioitemLike(likeData));
  }
  function handelFollow(id) {
    const action = 'follow';
    const followed_id = id;
    //create follow object
    const followData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(followUser(followData));
  }
  function handelUnfollow(id) {
    const action = 'unfollow';
    const followed_id = id;
    //create follow object
    const unfollowData = {
      action,
      followed_id,
    };
    //attempt to follow user
    dispatch(unfollowUser(unfollowData));
  }
  function handleShowReport() {
    setShowReport(true);
  }
  function handleHideReport() {
    setShowReport(false);
  }

  function handleDeleteOnClick() {
    dispatch(deleteportfolioitem({id: portfolioItemId}));
  }

  useEffect(() => {
    if (portfolioItemDeleted === 'success') {
      setRedirect(!redirect);
    }
  }, [portfolioItemDeleted, redirect]);

  useEffect(() => {
    if (followDone) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('following') + ' ' + portfolioitem.user.fname,
          I18n.t('following_success') +
            ' ' +
            portfolioitem.user.fname +
            ' ' +
            I18n.t('successfull'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      dispatch({
        type: CLEAR_FOLLOW_USER_SUCCESS,
      });
      const ItemData = {
        id,
      };
      const portfolio_id = id;
      const page = 1;
      const limit = 30;
      dispatch(getfreelancerPortfolioItem(ItemData));
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      dispatch(getportfolioitemLikes({portfolio_id}));
    } else if (unfollowDone) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('unfollowing') + ' ' + portfolioitem.user.fname,
          I18n.t('unfollowing_success') +
            ' ' +
            portfolioitem.user.fname +
            ' ' +
            I18n.t('successfull'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      dispatch({
        type: CLEAR_UNFOLLOW_USER_SUCCESS,
      });
      const ItemData = {
        id,
      };
      const portfolio_id = id;
      const page = 1;
      const limit = 30;
      dispatch(getfreelancerPortfolioItem(ItemData));
      dispatch(getPortfolioComments(portfolio_id, page, limit));
      dispatch(getportfolioitemLikes({portfolio_id}));
    }
  }, [followDone, unfollowDone, handleToast, id, dispatch, portfolioitem]);

  useEffect(() => {
    if (portfolioitemLikeAdded === 'success') {
      const portfolio_id = id;
      const ItemData = {
        id,
      };
      setTimeout(() => {
        setConfShow(true);
        setConfTitle(I18n.t('like_portfolio'));
        setConfMessage(I18n.t('like_portfolio_success'));
        setConfStatus('Toast__Container__Success');
        setDelay(6000);
      }, 3000);
      dispatch({
        type: RESET_PORTFOLIO_ITEM,
      });
      dispatch(getportfolioitemLikes({portfolio_id}));
      dispatch(getfreelancerPortfolioItem(ItemData));
    }
  }, [portfolioitemLikeAdded, dispatch, id]);

  useEffect(() => {
    if (portfolioItemEdited === 'success') {
      window.location.reload(setTimeout(3000));
    }
  }, [portfolioItemEdited]);

  function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html.replace(/&nbsp;/g, '');
    return tmp.textContent || tmp.innerText || '';
  }

  if (redirect) {
    return <Redirect to="/dashboard/portfolio" />;
  }

  if (loadingPortfolioItem) {
    return <Spin />;
  }

  if (
    errorId === 'GET_PORTFOLIO_ITEM_FAIL' &&
    errorMessage === 'Network Error'
  ) {
    return (
      <div className="PortfolioDetails">
        <div className="container InnerPageContainer mt-4">
          <div className="row">
            <div className="col-12">
              <ConnectionLost
                errorType="connection"
                title="network_error_title"
                message="network_error_message"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (errorId === 'GET_PORTFOLIO_ITEM_FAIL') {
    return (
      <div className="PortfolioDetails">
        <div className="container InnerPageContainer mt-4">
          <div className="row">
            <div className="col-12">
              <ConnectionLost
                errorType="somethingWrong"
                title="something_error_title"
                message="something_error_message"
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else if (freelancerportfolioitem.length > 0 && !loadingPortfolioItem) {
    const createMarkup = () => {
      return {__html: portfolioitem.description};
    };
    return (
      <Fragment>
        <TitleComponent
          title={
            I18n.t('lancersin_portfolio') +
            ' - ' +
            portfolioitem.title.substring(0, 25)
          }
        />
        <MetaComponent
          keywords={
            portfolioitem.keywords && portfolioitem.keywords.length >= 3
              ? portfolioitem.keywords
              : portfolioitem.title
          }
          description={stripHtml(portfolioitem.description).substring(0, 150)}
        />
        <ReportContentModal
          onHide={handleHideReport}
          show={showReport}
          className="CustomPopup"
          size="lg"
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          loading={postReportLoading}
          model="portfolios"
          model_id={id}
          setConfShow={setConfShow}
          setConfTitle={setConfTitle}
          setConfMessage={setConfMessage}
          setConfStatus={setConfStatus}
          setDelay={setDelay}
        />
        <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
        />
        <DeleteModal
          onHide={handleDeleteConfirmClose}
          onDelete={handleDeleteOnClick}
          show={showDeleteConfirm}
          modalTitle={'delete_portfolio'}
          className="CustomPopup"
          size="md"
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          loading={deletePortfolioItemLoading}
          messageHeading="delete_conf"
          messageBody={portfolioitem.title}
        />
        <AddingPortfolioItem
          portfolioItem={portfolioitem}
          modalTitle={'edit_portfolio_title'}
          className="CustomPopup"
          size="lg"
          show={toggleEditModal}
          onHide={handleEditOnClick}
          backdrop="static"
          keyboard={false}
          aria-labelledby="example-modal-sizes-title-lg"
          isLoading={isLoading}
          setConfShow={setConfShow}
          setConfTitle={setConfTitle}
          setConfMessage={setConfMessage}
          setConfStatus={setConfStatus}
          setDelay={setDelay}
        />
        {portfolioitem.publish === 0 && user.id !== portfolioitem.user.id ? (
          <NoPermisions />
        ) : (
          <Fragment>
            <div className="breadcrumb__container">
              <div className="container">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">{I18n.t('home')}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/portfolio">{I18n.t('portfolio')}</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {portfolioitem.title}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
            <div className="PortfolioDetails">
              <div className="container InnerPageContainer mt-4">
                <div className="row">
                  <div className="col-md-3">
                    <div className="Profile-Card box-shadow">
                      <div className="row">
                        <div className="col-12">
                          <div className="ProfileData">
                            <div className="ProfileImage">
                              <figure>
                                <img
                                  src={portfolioitem.user.image}
                                  alt={
                                    portfolioitem.user.fname +
                                    ' ' +
                                    portfolioitem.user.lname
                                  }
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                            <div className="ProfileContent">
                              <h2 className="ProfileName">
                                {portfolioitem.title}
                              </h2>
                              {portfolioitem.user.id === user.id ? (
                                portfolioitem.status_id ? (
                                  <div className="row my-3">
                                    <div className="col-12">
                                      <div className="Service__Status">
                                        <span className="listing__title">
                                          <Translate value="status" />:
                                        </span>
                                        <StatusLabel
                                          status_id={
                                            portfolioitem.status_id
                                              ? portfolioitem.status_id
                                              : null
                                          }
                                          type="project"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : null
                              ) : null}
                              <h3 className="ProfileTitle">
                                <Translate value="by" />:{' '}
                                <Link
                                  to={{
                                    pathname: `/in/${portfolioitem.user.username}`,
                                  }}>
                                  {portfolioitem.user.fname}{' '}
                                  {portfolioitem.user.lname}
                                </Link>
                              </h3>
                              <RatingStarsPreview
                                rate={portfolioitem.user.rate}
                              />
                              {portfolioitem.user.country ? (
                                <div className="LocationTime">
                                  <address>
                                    {portfolioitem.user.country.flag ? (
                                      <span className="LocationFlag">
                                        <img
                                          src={portfolioitem.user.country.flag}
                                          alt={
                                            portfolioitem.user.country.name
                                              ? portfolioitem.user.country.name
                                              : ''
                                          }
                                        />
                                      </span>
                                    ) : null}
                                    <span className="Location">
                                      {portfolioitem.user.city
                                        ? portfolioitem.user.city.name
                                        : null}
                                      , {portfolioitem.user.country.name}
                                    </span>
                                  </address>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          {isAuthenticated &&
                          portfolioitem.user.id !== user.id ? (
                            <Fragment>
                              {followings.length > 0 &&
                              followings[0].id === portfolioitem.user.id ? (
                                <ButtonWithLoading
                                  variant="primary"
                                  className="Follow__User"
                                  type="button"
                                  onClick={() =>
                                    handelUnfollow(portfolioitem.user.id)
                                  }
                                  data-backdrop="static"
                                  loading={
                                    unFollowUserLoading
                                      ? unFollowUserLoading
                                      : loadingPortfolioItem
                                      ? loadingPortfolioItem
                                      : null
                                  }>
                                  <i className="fas fa-heart"></i>{' '}
                                  <Translate value="un_follow" />
                                </ButtonWithLoading>
                              ) : (
                                <ButtonWithLoading
                                  variant="primary"
                                  className="Follow__User"
                                  type="button"
                                  onClick={() =>
                                    handelFollow(portfolioitem.user.id)
                                  }
                                  data-backdrop="static"
                                  loading={
                                    followUserLoading
                                      ? followUserLoading
                                      : loadingPortfolioItem
                                      ? loadingPortfolioItem
                                      : null
                                  }>
                                  <i className="far fa-heart"></i>{' '}
                                  <Translate value="follow" />
                                </ButtonWithLoading>
                              )}
                            </Fragment>
                          ) : null}
                          <div className="FollowsCount">
                            <h4>
                              <Translate value="followers" />
                            </h4>
                            : {portfolioitem.user.followersCount}
                          </div>
                        </div>
                      </div>
                    </div>
                    {portfolioitem.skills.length > 0 ? (
                      <div className="Profile-Card box-shadow p-0">
                        <div className="Profile-Card__title">
                          <span className="icon icon-skills"></span>{' '}
                          <Translate value="skills" />
                        </div>
                        <ul className="ProfileSkills p-3 pb-0">
                          {portfolioitem.skills.map((skill, index) => {
                            return <li key={index}>{skill}</li>;
                          })}
                        </ul>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-9">
                    <Card>
                      <Card.Header>
                        <div>
                          <h1>{portfolioitem.title}</h1>
                          {portfolioitem.user.id === user.id ? (
                            portfolioitem.publish === 1 &&
                            portfolioitem.status_id === 1 ? (
                              <OverlayTrigger
                                key="published"
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-published">
                                    {I18n.t('any_one_portfolio')}
                                  </Tooltip>
                                }>
                                <i className="far fa-eye mx-1"></i>
                              </OverlayTrigger>
                            ) : (
                              <OverlayTrigger
                                key="hidden"
                                placement="top"
                                overlay={
                                  <Tooltip id="tooltip-hidden">
                                    {I18n.t('no_one_portfolio')}
                                  </Tooltip>
                                }>
                                <i className="far fa-eye-slash mx-1"></i>
                              </OverlayTrigger>
                            )
                          ) : null}
                        </div>
                        {portfolioitem.user.id === user.id ? (
                          <div>
                            <button className="btn" onClick={handleEditOnClick}>
                              <span className="icon icon-edit"></span>
                              <Translate value="edit" />
                            </button>
                            <button
                              className="btn"
                              onClick={handleDeleteConfirmShow}>
                              <span className="icon icon-delete"></span>
                              <Translate value="delete" />
                            </button>
                          </div>
                        ) : isAuthenticated ? (
                          <div>
                            <button
                              className="btn"
                              onClick={() => {
                                handleShowReport();
                              }}>
                              <span className="icon icon-report"></span>{' '}
                              <Translate value="report_content" />
                            </button>
                          </div>
                        ) : null}
                      </Card.Header>
                      <Card.Body>
                        {portfolioitem.images.length <= 0 &&
                        portfolioitem.video_url === null ? (
                          <img
                            src={
                              portfolioitem.image.url
                                ? portfolioitem.image.url
                                : portfolioitem.image
                            }
                            alt={portfolioitem.title}
                            className="img-fluid mx-auto d-block"
                          />
                        ) : null}
                        {portfolioitem.images
                          ? portfolioitem.images.map((image, index) => {
                              return (
                                <img
                                  key={index}
                                  src={image.url}
                                  alt={portfolioitem.title}
                                  className={
                                    portfolioitem.spacing === 1
                                      ? 'img-fluid PortfolioDetails__itemMargin d-block ml-auto mr-auto'
                                      : 'img-fluid d-block ml-auto mr-auto'
                                  }
                                />
                              );
                            })
                          : null}
                        {portfolioitem.video_url ? (
                          <ReactPlayer
                            width="100%"
                            height="400px"
                            className="mt-3"
                            url={portfolioitem.video_url}
                          />
                        ) : null}
                        {portfolioitem.description ? (
                          <div
                            dangerouslySetInnerHTML={createMarkup()}
                            className="text-center mt-3"></div>
                        ) : null}
                        {portfolioitem.preview_url ? (
                          <div className="text-center mt-3">
                            <a
                              className="btn btn-primary"
                              href={portfolioitem.preview_url}
                              target="_blank"
                              rel="noopener noreferrer">
                              <Translate value="live_preview" />
                            </a>
                          </div>
                        ) : null}
                        <div className="PortfolioDetails__ItemCard">
                          {isAuthenticated ? (
                            addPortfolioLikeLoading || portfolioLikesLoading ? (
                              <Spin />
                            ) : portfolioitemLikes.find(
                                item => item.user.id === user.id,
                              ) ? (
                              <button className="PortfolioDetails__ItemCard__LikeButton__dimmed">
                                <span className="icon icon-like"></span>
                              </button>
                            ) : (
                              <button
                                className="PortfolioDetails__ItemCard__LikeButton"
                                onClick={handleLike}>
                                <span className="icon icon-like"></span>
                              </button>
                            )
                          ) : null}
                          <h1>{portfolioitem.title}</h1>
                          <div className="PortfolioDetails__ItemCard__ItemStats">
                            <div className="PortfolioDetails__ItemCard__ItemStats__Item">
                              <span className="icon icon-like"></span>
                              {Num(portfolioitem.likes_count)}
                            </div>
                            <div className="PortfolioDetails__ItemCard__ItemStats__Item">
                              <span className="icon icon-view"></span>
                              {Num(portfolioitem.views)}
                            </div>
                            <div className="PortfolioDetails__ItemCard__ItemStats__Item">
                              <span className="icon icon-comment"></span>
                              {Num(portfolioitemComments.length)}
                            </div>
                          </div>
                          <p className="text-center">
                            <Translate value="published_on" />:{' '}
                            {portfolioitem.created_at.Date}
                          </p>
                        </div>
                      </Card.Body>
                    </Card>
                    <PortfolioItemComments
                      params={props.match.params}
                      portfolioItemId={portfolioItemId}
                      portfolioItem={portfolioitem}
                      setConfShow={setConfShow}
                      setConfTitle={setConfTitle}
                      setConfMessage={setConfMessage}
                      setConfStatus={setConfStatus}
                      setDelay={setDelay}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </Fragment>
    );
  } else {
    return (
      <div className="PortfolioDetails">
        <div className="container InnerPageContainer mt-4">
          <div className="row">
            <NoItems />
          </div>
        </div>
      </div>
    );
  }
}
