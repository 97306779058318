import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Card({ hide, item }) {
  const createMarkup = (text) => {
    return { __html: text };
  };
  const {
    lang,
  } = useSelector((state) => ({
    lang: state.lang.lang,
  }))
  return (
    <div className="col-sm-6 col-md-4">
      <article>
          <header className="headers headers--underline sectionHeader">
              <figure>
                  <Link
                    to={{ pathname: `/articles/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }}
                  >
                    <img src={item.image.url ? item.image.url : item.image} alt="" className="img-fluid" />
                  </Link>
              </figure>
              <h1 className="text-center">
                  <Link
                    to={{ pathname: `/articles/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }}
                  >
                    {item.title}
                  </Link>
              </h1>
          </header>
          <div className="articles__description">
            <p className="text-center" dangerouslySetInnerHTML={createMarkup(
              item.description.replace(/(<([^>]+)>)/gi, "")
            )}></p>
          </div>
          <Link
            to={{ pathname: `/articles/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }}
          >
            <i className={lang === "English" ? "icon-arrow-right goToTarget" : "icon-arrow-left goToTarget"} />
          </Link>
      </article> 
    </div>
  );
}
