import React, {Fragment, useEffect, useState, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import AddingRow from '../AddingRow';
import {getReplies} from '../../redux/messages/actions/getActions';
import Spin from '../Spin';
import NoItems from '../NoItems';
import {I18n} from 'react-redux-i18n';
import QuillBox from '../QuillBox';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {Form, Col} from 'react-bootstrap';
import GRecaptch from '../GRecaptch';
import ConfToast from '../ConfToast';
import ErrorBox from '../ErrorBox';
import {postMessage, adminReply} from '../../redux/messages/actions/addActions';
import {
  getAllMessages,
  markMessageAsRead,
} from '../../redux/messages/actions/getActions';
import {
  CLEAR_SEND_MESSAGE_SUCCESS,
  CLEAR_LAST_MESSAGE,
} from '../../redux/messages/actions/types';
import TYPES from '../../redux/messages/actions/types';
import {clearErrors} from '../../actions/errorActions';
import InfiniteScroll from 'react-infinite-scroll-component';
import {CLEAR_MESSAGE_REPLIES} from '../../redux/messages/actions/types';
import logoIcon from '../../imgs/logo-icon.png';
import {MetaComponent} from '../MetaComponent';
import {TitleComponent} from '../../components/TitleComponent';
import {Uploader} from '../Upload';
import {deleteFile} from '../../actions/deletefileActions';
import {truncate} from '../ReduceFileName';
import {Size} from '../CalculateSize';

const {SEND_MESSAGE_FAIL} = TYPES;

const MessageDetails = props => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {id} = props.match.params;

  const prevPath = props.location.state;

  const {
    userLoading,
    currentUser,
    isAuthenticated,
    locale,

    //Current Message
    currentMessageLoading,
    currentMessage,

    //All Messages
    getRepliesLoading,
    replies,
    totalReplies,

    //Send Message
    sendMessageLoading,
    sendMessageSuccess,

    //Last Message
    lastMessage,

    // errors
    errorsId,
    errorsMsg,
  } = useSelector(state => ({
    userLoading: state.auth.isLoading,
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    locale: state.i18n.locale,

    //Current Message
    currentMessageLoading: state.messages.currentMessageLoading,
    currentMessage: state.messages.currentMessage,

    //All Messages
    getRepliesLoading: state.messages.getRepliesLoading,
    replies: state.messages.replies,
    totalReplies: state.messages.totalReplies,

    //Send Message
    sendMessageLoading: state.messages.sendMessageLoading,
    sendMessageSuccess: state.messages.sendMessageSuccess,

    //LastMessage
    lastMessage: state.messages.lastMessage,

    errorsId: state.error.id,
    errorsMsg: state.error.msg,
  }));

  const [activePage, setActivePage] = useState(1);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [isHuman, setIsHuman] = useState(false);
  const [message, setMessage] = useState('');
  const [localErrors, setLocalErrors] = useState({});
  const [returnedErrors, setReturnedErrors] = useState({});
  const [ERROR_ACTION_TYPE, setErrorActionType] = useState(SEND_MESSAGE_FAIL);
  const [conversationReplies, setConversationReplies] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [loadingReplies, setLoadingReplies] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filesUpload, setFilesUpload] = useState(false);
  const [filesChosen, setFilesChosen] = useState(false);
  const [filesUploadErrors, setFilesUploadErrors] = useState([]);
  const [filesSortLength, setFilesSortLength] = useState(false);
  const [filteredFilesErrorsLength, setFilteredFilesErrorsLength] =
    useState(false);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const filteredFilesErrors = filesUploadErrors
    ? filesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
      )
    : null;

  useEffect(() => {
    if (filteredFilesErrors.length > 0) {
      setFilteredFilesErrorsLength(true);
    }
  }, [filteredFilesErrors]);

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      setFilesSortLength(true);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    setLoadingMessage(currentMessageLoading);
  }, [currentMessageLoading]);

  useEffect(() => {
    setLoadingReplies(getRepliesLoading);
  }, [getRepliesLoading]);

  useEffect(() => {
    if (id) {
      dispatch(markMessageAsRead(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (location && prevPath && location.pathname !== prevPath.prevPath) {
      setConversationReplies([]);
      setActivePage(1);
    }
  }, [prevPath, location, dispatch]);

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    if (isAuthenticated && id) {
      const page = 1;
      const limit = 1;
      dispatch(getAllMessages(id, page, limit, locale));
    }
  }, [dispatch, isAuthenticated, locale, id]);

  useEffect(() => {
    if (isAuthenticated && id) {
      const conversation_id = id;
      const page = activePage;
      const limit = 15;
      dispatch(getReplies(conversation_id, page, limit, locale));
    }
  }, [dispatch, isAuthenticated, locale, activePage, id]);

  useEffect(() => {
    if (currentMessage.length > 0) {
      setCurrentConversation(currentMessage[0]);
    }
  }, [currentMessage]);

  useEffect(() => {
    if (errorsId && errorsMsg) {
      setErrorActionType(errorsId);
      setReturnedErrors(errorsMsg.errors);
    }
  }, [errorsId, errorsMsg]);

  useEffect(() => {
    if (conversationReplies.length < totalReplies) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [conversationReplies, totalReplies]);

  const createMarkup = text => {
    return {__html: text};
  };

  function handleMessageChange(value) {
    value = value || '';
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      setMessage('');
    } else {
      setMessage(value);
    }
  }

  function handleItemLocalError({propName, currentValue, message}) {
    if (currentValue) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onMessageBlur() {
    handleItemLocalError({
      propName: 'MessageError',
      currentValue: message,
      message: I18n.t('message_required'),
    });
  }

  function sendMessage(e) {
    e.preventDefault();
    handleItemLocalError({
      propName: 'MessageError',
      currentValue: message,
      message: I18n.t('message_required'),
    });
    if (typeof currentConversation.from === 'string') {
      const conversation_id = currentConversation.id;
      if (
        message !== '' &&
        filesUpload === false &&
        filesChosen === false &&
        isHuman
      ) {
        if (deletedFiles.length > 0) {
          deletedFiles.map(item => {
            const model = 'conversations';
            const id = currentMessage ? currentMessage.id : null;
            const fileURL = item;
            const fileData = {
              model,
              id,
              fileURL,
            };
            return dispatch(deleteFile(fileData));
          });
        }
        let files = [];
        if (uploadedFiles.length > 0) {
          files = uploadedFiles.map(item => item.source);
        }
        //attempt to send message
        dispatch(adminReply(message, conversation_id, files));
      } else if (filesUpload === true || filesChosen === true) {
        handleToast(
          true,
          I18n.t('upload_notification_title'),
          I18n.t('upload_notification'),
          'Toast__Container__Warning',
          6000,
        );
      } else {
        handleToast(
          true,
          I18n.t('complete_required_info_title'),
          I18n.t('complete_required_info'),
          'Toast__Container__Warning',
          10000,
        );
      }
    } else {
      const morphID = currentConversation.conversationable.id;
      const morphType = currentConversation.morph_type;
      const to =
        currentUser.id === currentConversation.from.id
          ? currentConversation.to.id
          : currentConversation.from.id;
      if (
        message !== '' &&
        filesUpload === false &&
        filesChosen === false &&
        isHuman
      ) {
        if (deletedFiles.length > 0) {
          deletedFiles.map(item => {
            const model = 'conversations';
            const id = currentMessage ? currentMessage.id : null;
            const fileURL = item;
            const fileData = {
              model,
              id,
              fileURL,
            };
            return dispatch(deleteFile(fileData));
          });
        }
        //attempt to send message
        let files = [];
        if (uploadedFiles.length > 0) {
          files = uploadedFiles.map(item => item.source);
        }
        dispatch(postMessage(message, to, morphType, morphID, files));
      } else if (filesUpload === true || filesChosen === true) {
        handleToast(
          true,
          I18n.t('upload_notification_title'),
          I18n.t('upload_notification'),
          'Toast__Container__Warning',
          6000,
        );
      } else {
        handleToast(
          true,
          I18n.t('complete_required_info_title'),
          I18n.t('complete_required_info'),
          'Toast__Container__Warning',
          10000,
        );
      }
    }
  }

  useEffect(() => {
    if (sendMessageSuccess) {
      setConversationReplies([]);
      setActivePage(1);
      dispatch({
        type: CLEAR_MESSAGE_REPLIES,
      });
      const conversation_id = id;
      const page = 1;
      const limit = 15;
      dispatch(getReplies(conversation_id, page, limit, locale));
      setMessage('');
      setLocalErrors({});
      setFilesChosen(false);
      setFilesUpload(false);
      setFilesUploadErrors([]);
      setUploadedFiles([]);
      setFilesSortLength(false);
      setFilteredFilesErrorsLength(false);
      setDeletedFiles([]);
      handleToast(
        true,
        I18n.t('sending_message'),
        I18n.t('message_sent__success'),
        'Toast__Container__Success',
        6000,
      );
      dispatch(clearErrors());
      dispatch({
        type: CLEAR_SEND_MESSAGE_SUCCESS,
      });
    }
  }, [sendMessageSuccess, dispatch, handleToast, id, locale]);

  useEffect(() => {
    if (replies.length > 0) {
      var ids = new Set(conversationReplies.map(item => item.id));
      var merged = [
        ...conversationReplies,
        ...replies.filter(item => !ids.has(item.id)),
      ];
      var sorted = merged.sort((a, b) => (a.id < b.id ? 1 : -1));
      setConversationReplies(sorted);
    }
  }, [replies, loadingReplies]);

  Array.prototype.inArray = function (comparer) {
    for (var i = 0; i < this.length; i++) {
      if (comparer(this[i])) return true;
    }
    return false;
  };

  // adds an element to the array if it does not already exist using a comparer
  // function
  Array.prototype.pushIfNotExist = function (element, comparer) {
    if (!this.inArray(comparer)) {
      this.push(element);
    }
  };

  useEffect(() => {
    if (
      lastMessage &&
      conversationReplies &&
      conversationReplies.length > 0 &&
      lastMessage.content.conversation_id &&
      lastMessage.content.conversation_id.toString() === id
    ) {
      conversationReplies.pushIfNotExist(lastMessage.content, function (e) {
        return e.id === lastMessage.content.id;
      });
      const merged = [...conversationReplies];
      var sorted = merged.sort((a, b) => (a.id < b.id ? 1 : -1));
      setConversationReplies(sorted);
      var objDiv = document.getElementById('scrollableDiv');
      objDiv.scrollTop = objDiv.scrollHeight;
      dispatch({
        type: CLEAR_LAST_MESSAGE,
      });
    } else if (
      lastMessage &&
      lastMessage.content.conversation_id &&
      lastMessage.content.conversation_id.toString() === id
    ) {
      const conversation_id = id;
      const page = activePage;
      const limit = 15;
      dispatch(getReplies(conversation_id, page, limit, locale));
    }
  }, [lastMessage, conversationReplies, id, dispatch, activePage, locale]);

  function fetchMoreData() {
    setTimeout(() => {
      setActivePage(activePage + 1);
    }, 1500);
  }

  function renderReply(item) {
    if (typeof item.from === 'string') {
      return (
        <li key={item.id}>
          <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion">
            <header>
              <div className="ProfileImage">
                <figure>
                  <img src={logoIcon} alt="Lancersin" className="img-fluid" />
                </figure>
              </div>
              <div className="ProfileContent">
                <div className="ProfileContent__Details">
                  <h2 className="ProfileName">{item.from}</h2>
                </div>
                <time className="Time">
                  <span className="icon icon-calender-time"></span>{' '}
                  {item.created_at.Date} - {item.created_at.Time}
                </time>
              </div>
            </header>
            <div className="ProjectDetails__Proposal__Content">
              <div dangerouslySetInnerHTML={createMarkup(item.message)}></div>
              {item.files ? (
                item.files.length > 0 ? (
                  <div className="ProjectDetails__Proposal__Content__attachments">
                    <h3>{I18n.t('attachments')}:</h3>
                    <ul>
                      {item.files.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="fas fa-paperclip"></span>
                            {truncate(file.filename, 20)}
                          </a>
                          <span className="file__size ms-1">
                            ({Size(file.size)})
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </li>
      );
    } else {
      return (
        <li key={item.id}>
          <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion">
            <header>
              <div className="ProfileImage">
                <figure>
                  <img
                    src={item.from.image}
                    alt={item.from.fname + ' ' + item.from.lname}
                    className="img-fluid"
                  />
                </figure>
              </div>
              <div className="ProfileContent">
                <div className="ProfileContent__Details">
                  <h2 className="ProfileName">
                    <Link to={{pathname: `/in/${item.from.username}`}}>
                      {item.from.fname + ' ' + item.from.lname}
                    </Link>
                  </h2>
                </div>
                <time className="Time">
                  <span className="icon icon-calender-time"></span>{' '}
                  {item.created_at.Date} - {item.created_at.Time}
                </time>
              </div>
            </header>
            <div className="ProjectDetails__Proposal__Content">
              <div dangerouslySetInnerHTML={createMarkup(item.message)}></div>
              {item.files ? (
                item.files.length > 0 ? (
                  <div className="ProjectDetails__Proposal__Content__attachments">
                    <h3>{I18n.t('attachments')}:</h3>
                    <ul>
                      {item.files.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="fas fa-paperclip"></span>
                            {truncate(file.filename, 20)}
                          </a>
                          <span className="file__size ms-1">
                            ({Size(file.size)})
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </li>
      );
    }
  }

  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <TitleComponent title={I18n.t('lancersin_messages')} />
      <MetaComponent />
      <div className="container">
        <div className="row">
          <div className="col-12 Dashboard__tasks__List">
            {loadingMessage || userLoading ? (
              <Spin />
            ) : currentConversation && !loadingMessage ? (
              <Fragment>
                {currentConversation.morph_type === 'project_bids' ? (
                  <AddingRow
                    title={
                      I18n.t('inquiry_about') +
                      ' ' +
                      currentConversation.conversationable.title
                    }
                    titleIconClassName={'icon-envelope'}
                  />
                ) : currentConversation.morph_type === 'services' ? (
                  <AddingRow
                    title={
                      I18n.t('inquiry_about') +
                      ' ' +
                      currentConversation.conversationable.title
                    }
                    titleIconClassName={'icon-envelope'}
                  />
                ) : currentConversation.morph_type === 'system_messages' ? (
                  <AddingRow
                    title={currentConversation.subject}
                    titleIconClassName={'icon-envelope'}
                  />
                ) : null}
                <div className="row">
                  <div className="col-md-8">
                    <div className="notificationList box-shadow mb-4 Inner">
                      {conversationReplies.length > 0 &&
                      (currentConversation.morph_type === 'services' ||
                        currentConversation.morph_type === 'project_bids') ? (
                        <Fragment>
                          <div
                            id="scrollableDiv"
                            style={{
                              height:
                                conversationReplies.length > 3 ? 500 : 250,
                              overflow: 'auto',
                              display: 'flex',
                              flexDirection: 'column-reverse',
                            }}>
                            <ul className="list">
                              {hasMore ? null : (
                                <li>
                                  <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion">
                                    <header>
                                      <div className="ProfileImage">
                                        <figure>
                                          <img
                                            src={currentConversation.from.image}
                                            alt={
                                              currentConversation.from.fname +
                                              ' ' +
                                              currentConversation.from.lname
                                            }
                                            className="img-fluid"
                                          />
                                        </figure>
                                      </div>
                                      <div className="ProfileContent">
                                        <div className="ProfileContent__Details">
                                          <h2 className="ProfileName">
                                            <Link
                                              to={{
                                                pathname: `/in/${currentConversation.from.username}`,
                                              }}>
                                              {currentConversation.from.fname +
                                                ' ' +
                                                currentConversation.from.lname}
                                            </Link>
                                          </h2>
                                        </div>
                                        <time className="Time">
                                          <span className="icon icon-calender-time"></span>{' '}
                                          {currentConversation.created_at.Date}{' '}
                                          -{' '}
                                          {currentConversation.created_at.Time}
                                        </time>
                                      </div>
                                    </header>
                                    <div className="ProjectDetails__Proposal__Content">
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(
                                          currentConversation.message,
                                        )}></div>
                                      {currentConversation.files ? (
                                        currentConversation.files.length > 0 ? (
                                          <div className="ProjectDetails__Proposal__Content__attachments">
                                            <h3>{I18n.t('attachments')}:</h3>
                                            <ul>
                                              {currentConversation.files.map(
                                                (file, index) => (
                                                  <li key={index}>
                                                    <a
                                                      href={file.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer">
                                                      <span className="fas fa-paperclip"></span>
                                                      {truncate(
                                                        file.filename,
                                                        20,
                                                      )}
                                                    </a>
                                                    <span className="file__size ms-1">
                                                      ({Size(file.size)})
                                                    </span>
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          </div>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              )}
                              <InfiniteScroll
                                dataLength={conversationReplies.length}
                                next={fetchMoreData}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                }}
                                inverse={true}
                                hasMore={hasMore}
                                loader={<Spin />}
                                scrollableTarget="scrollableDiv">
                                {conversationReplies.map(item => (
                                  <li key={item.id}>
                                    <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion">
                                      <header>
                                        <div className="ProfileImage">
                                          <figure>
                                            <img
                                              src={item.from.image}
                                              alt={
                                                item.from.fname +
                                                ' ' +
                                                item.from.lname
                                              }
                                              className="img-fluid"
                                            />
                                          </figure>
                                        </div>
                                        <div className="ProfileContent">
                                          <div className="ProfileContent__Details">
                                            <h2 className="ProfileName">
                                              <Link
                                                to={{
                                                  pathname: `/in/${item.from.username}`,
                                                }}>
                                                {item.from.fname +
                                                  ' ' +
                                                  item.from.lname}
                                              </Link>
                                            </h2>
                                          </div>
                                          <time className="Time">
                                            <span className="icon icon-calender-time"></span>{' '}
                                            {item.created_at.Date} -{' '}
                                            {item.created_at.Time}
                                          </time>
                                        </div>
                                      </header>
                                      <div className="ProjectDetails__Proposal__Content">
                                        <div
                                          dangerouslySetInnerHTML={createMarkup(
                                            item.message,
                                          )}></div>
                                        {item.files ? (
                                          item.files.length > 0 ? (
                                            <div className="ProjectDetails__Proposal__Content__attachments">
                                              <h3>{I18n.t('attachments')}:</h3>
                                              <ul>
                                                {item.files.map(
                                                  (file, index) => (
                                                    <li key={index}>
                                                      <a
                                                        href={file.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        <span className="fas fa-paperclip"></span>
                                                        {truncate(
                                                          file.filename,
                                                          20,
                                                        )}
                                                      </a>
                                                      <span className="file__size ms-1">
                                                        ({Size(file.size)})
                                                      </span>
                                                    </li>
                                                  ),
                                                )}
                                              </ul>
                                            </div>
                                          ) : null
                                        ) : null}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </InfiniteScroll>
                            </ul>
                          </div>
                        </Fragment>
                      ) : conversationReplies.length > 0 &&
                        currentConversation.morph_type === 'system_messages' ? (
                        <Fragment>
                          <div
                            id="scrollableDiv"
                            style={{
                              height:
                                conversationReplies.length > 3 ? 500 : 250,
                              overflow: 'auto',
                              display: 'flex',
                              flexDirection: 'column-reverse',
                            }}>
                            <ul className="list">
                              {hasMore ? null : (
                                <li>
                                  <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion">
                                    <header>
                                      <div className="ProfileImage">
                                        <figure>
                                          <img
                                            src={logoIcon}
                                            alt="Lancersin"
                                            className="img-fluid"
                                          />
                                        </figure>
                                      </div>
                                      <div className="ProfileContent">
                                        <div className="ProfileContent__Details">
                                          <h2 className="ProfileName">
                                            {currentConversation.from}
                                          </h2>
                                        </div>
                                        <time className="Time">
                                          <span className="icon icon-calender-time"></span>{' '}
                                          {currentConversation.created_at.Date}{' '}
                                          -{' '}
                                          {currentConversation.created_at.Time}
                                        </time>
                                      </div>
                                    </header>
                                    <div className="ProjectDetails__Proposal__Content">
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(
                                          currentConversation.message,
                                        )}></div>
                                      {currentConversation.files ? (
                                        currentConversation.files.length > 0 ? (
                                          <div className="ProjectDetails__Proposal__Content__attachments">
                                            <h3>{I18n.t('attachments')}:</h3>
                                            <ul>
                                              {currentConversation.files.map(
                                                (file, index) => (
                                                  <li key={index}>
                                                    <a
                                                      href={file.url}
                                                      target="_blank"
                                                      rel="noopener noreferrer">
                                                      <span className="fas fa-paperclip"></span>
                                                      {truncate(
                                                        file.filename,
                                                        20,
                                                      )}
                                                    </a>
                                                    <span className="file__size ms-1">
                                                      ({Size(file.size)})
                                                    </span>
                                                  </li>
                                                ),
                                              )}
                                            </ul>
                                          </div>
                                        ) : null
                                      ) : null}
                                    </div>
                                  </div>
                                </li>
                              )}
                              <InfiniteScroll
                                dataLength={conversationReplies.length}
                                next={fetchMoreData}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column-reverse',
                                }}
                                inverse={true}
                                hasMore={hasMore}
                                loader={<Spin />}
                                scrollableTarget="scrollableDiv">
                                {conversationReplies.map(item =>
                                  renderReply(item),
                                )}
                              </InfiniteScroll>
                            </ul>
                          </div>
                        </Fragment>
                      ) : currentConversation.morph_type ===
                        'system_messages' ? (
                        <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion mt-3">
                          <header>
                            <div className="ProfileImage">
                              <figure>
                                <img
                                  src={logoIcon}
                                  alt="Lancersin"
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                            <div className="ProfileContent">
                              <div className="ProfileContent__Details">
                                <h2 className="ProfileName">
                                  {currentConversation.from}
                                </h2>
                              </div>
                              <time className="Time">
                                <span className="icon icon-calender-time"></span>{' '}
                                {currentConversation.created_at.Date} -{' '}
                                {currentConversation.created_at.Time}
                              </time>
                            </div>
                          </header>
                          <div className="ProjectDetails__Proposal__Content">
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                currentConversation.message,
                              )}></div>
                            {currentConversation.files ? (
                              currentConversation.files.length > 0 ? (
                                <div className="ProjectDetails__Proposal__Content__attachments">
                                  <h3>{I18n.t('attachments')}:</h3>
                                  <ul>
                                    {currentConversation.files.map(
                                      (file, index) => (
                                        <li key={index}>
                                          <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <span className="fas fa-paperclip"></span>
                                            {truncate(file.filename, 20)}
                                          </a>
                                          <span className="file__size ms-1">
                                            ({Size(file.size)})
                                          </span>
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="ProjectDetails__Proposal__Card ProjectDetails__Discussion mt-3">
                          <header>
                            <div className="ProfileImage">
                              <figure>
                                <img
                                  src={currentConversation.from.image}
                                  alt={
                                    currentConversation.from.fname +
                                    ' ' +
                                    currentConversation.from.lname
                                  }
                                  className="img-fluid"
                                />
                              </figure>
                            </div>
                            <div className="ProfileContent">
                              <div className="ProfileContent__Details">
                                <h2 className="ProfileName">
                                  <Link
                                    to={{
                                      pathname: `/in/${currentConversation.from.username}`,
                                    }}>
                                    {currentConversation.from.fname +
                                      ' ' +
                                      currentConversation.from.lname}
                                  </Link>
                                </h2>
                              </div>
                              <time className="Time">
                                <span className="icon icon-calender-time"></span>{' '}
                                {currentConversation.created_at.Date} -{' '}
                                {currentConversation.created_at.Time}
                              </time>
                            </div>
                          </header>
                          <div className="ProjectDetails__Proposal__Content">
                            <div
                              dangerouslySetInnerHTML={createMarkup(
                                currentConversation.message,
                              )}></div>
                            {currentConversation.files ? (
                              currentConversation.files.length > 0 ? (
                                <div className="ProjectDetails__Proposal__Content__attachments">
                                  <h3>{I18n.t('attachments')}:</h3>
                                  <ul>
                                    {currentConversation.files.map(
                                      (file, index) => (
                                        <li key={index}>
                                          <a
                                            href={file.url}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <span className="fas fa-paperclip"></span>
                                            {truncate(file.filename, 20)}
                                          </a>
                                          <span className="file__size ms-1">
                                            ({Size(file.size)})
                                          </span>
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                </div>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      )}
                      {currentConversation.replyable === false ? null : (
                        <div className="row">
                          {currentConversation.morph_type !==
                          'system_messages' ? (
                            currentUser.id ===
                            currentConversation.conversationable.user.id ? (
                              <div className="col-12">
                                <div
                                  className="alert alert-danger"
                                  role="alert">
                                  {I18n.t('bids_warning')}
                                </div>
                              </div>
                            ) : null
                          ) : null}
                          <div className="col-12 mb-5 mt-3">
                            <Form>
                              <QuillBox
                                required
                                controlId="Message"
                                label="message"
                                value={message}
                                onChange={handleMessageChange}
                                onBlur={onMessageBlur}
                                feedbackType="invalid"
                                feedback={
                                  localErrors.MessageError
                                    ? localErrors.MessageError
                                    : returnedErrors
                                    ? returnedErrors.message
                                      ? returnedErrors.message[0]
                                      : null
                                    : null
                                }
                                isInvalid={
                                  (errorsId === ERROR_ACTION_TYPE &&
                                  returnedErrors
                                    ? returnedErrors.message
                                      ? true
                                      : false
                                    : false) || localErrors.MessageError
                                }
                              />
                              <Form.Group controlId="MessageFiles">
                                <Form.Label>
                                  {I18n.t('upload_files')}
                                </Form.Label>
                                <small>{I18n.t('project_files_hint')}</small>
                                <Uploader
                                  accept={[
                                    {
                                      mime: 'application/msword',
                                      ext: 'doc',
                                    },
                                    {
                                      mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                      ext: 'docx',
                                    },
                                    {
                                      mime: 'application/vnd.ms-powerpoint',
                                      ext: 'ppt',
                                    },
                                    {
                                      mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                      ext: 'pptx',
                                    },
                                    {
                                      mime: 'application/vnd.ms-excel',
                                      ext: 'xls',
                                    },
                                    {
                                      mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                      ext: 'xlsx',
                                    },
                                    {
                                      mime: 'application/pdf',
                                      ext: 'pdf',
                                    },
                                    {
                                      mime: 'image/jpeg',
                                      ext: 'jpeg',
                                    },
                                    {
                                      mime: 'image/gif',
                                      ext: 'gif',
                                    },
                                    {
                                      mime: 'image/png',
                                      ext: 'png',
                                    },
                                    {
                                      mime: 'application/rtf',
                                      ext: 'rtf',
                                    },
                                    {
                                      mime: 'text/plain',
                                      ext: 'plain-text',
                                    },
                                    {
                                      mime: 'application/x-rar',
                                      ext: 'rar',
                                    },
                                    {
                                      mime: 'application/zip',
                                      ext: 'zip',
                                    },
                                  ]}
                                  maxFiles={10}
                                  multiple={true}
                                  maxSize={5000000}
                                  sortable={false}
                                  setFilesChosen={setFilesChosen}
                                  setFilesUpload={setFilesUpload}
                                  setUploadErrors={setFilesUploadErrors}
                                  uploadErrors={filesUploadErrors}
                                  setFilesSort={setUploadedFiles}
                                  filesSort={uploadedFiles}
                                  filesSortLength={filesSortLength}
                                  filteredErrorsLength={
                                    filteredFilesErrorsLength
                                  }
                                  filteredErrors={filteredFilesErrors}
                                  uploadPath="conversations"
                                  projectId={
                                    currentConversation
                                      ? currentConversation.id
                                      : null
                                  }
                                  deletedFiles={deletedFiles}
                                  setDeletedFiles={setDeletedFiles}
                                  optimize={1}
                                />
                              </Form.Group>
                              <Form.Row className="mb-4">
                                <Col>
                                  <GRecaptch
                                    setIsHuman={setIsHuman}
                                    className="mt-3"
                                  />
                                </Col>
                              </Form.Row>
                              {errorsId === 'SEND_MESSAGE_FAIL' ? (
                                <div className="row">
                                  <div className="col-12">
                                    <ErrorBox
                                      message={I18n.t('something_went_wrong')}
                                    />
                                  </div>
                                </div>
                              ) : null}
                              <ButtonWithLoading
                                variant="primary"
                                type="submit"
                                onClick={sendMessage}
                                data-backdrop="static"
                                loading={
                                  sendMessageLoading ? sendMessageLoading : null
                                }>
                                {I18n.t('send')}
                              </ButtonWithLoading>
                            </Form>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="notificationList box-shadow pt-3 Inner">
                      <div className="message__Details">
                        <div className="title">
                          {currentConversation.morph_type === 'project_bids'
                            ? I18n.t('project') + ':'
                            : currentConversation.morph_type === 'services'
                            ? I18n.t('service') + ':'
                            : null}
                        </div>
                        <div className="content">
                          {currentConversation.morph_type === 'project_bids' ? (
                            <Link
                              exact="true"
                              to={{
                                pathname: `/projects/${
                                  currentConversation.conversationable
                                    .project_id
                                }/${currentConversation.conversationable.title
                                  .replace(/\s+/g, '-')
                                  .replace(/\//g, '-')
                                  .toLowerCase()}`,
                              }}>
                              {currentConversation.conversationable.title}
                            </Link>
                          ) : currentConversation.morph_type === 'services' ? (
                            <Link
                              exact="true"
                              to={{
                                pathname: `/services/${
                                  currentConversation.conversationable.id
                                }/${currentConversation.conversationable.title
                                  .replace(/\s+/g, '-')
                                  .replace(/\//g, '-')
                                  .toLowerCase()}`,
                              }}>
                              {currentConversation.conversationable.title}
                            </Link>
                          ) : null}
                        </div>
                      </div>
                      <div className="message__Details">
                        <div className="title">{I18n.t('created_at')}:</div>
                        <div className="content">
                          {currentConversation.created_at.Date +
                            ' - ' +
                            currentConversation.created_at.Time}
                        </div>
                      </div>
                      {currentConversation.morph_type === 'project_bids' ? (
                        <div className="message__Details">
                          <div className="title">{I18n.t('proposal')}:</div>
                          <div className="content">
                            {currentConversation.conversationable.bid.price +
                              I18n.t('dollar') +
                              ' ' +
                              I18n.t('in') +
                              ' ' +
                              currentConversation.conversationable.bid
                                .deadline +
                              ' ' +
                              I18n.t('day_s')}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <NoItems />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MessageDetails;
