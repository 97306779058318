import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPages } from '../../actions/getpagesActions';
import Spin from '../Spin';
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { Card } from "react-bootstrap";
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";

export default function Addons(){
    const dispatch = useDispatch();

    const {
        pages,
        getPagesLoading,
        locale,
    } = useSelector((state) => ({
        pages: state.websitePages.pages,
        getPagesLoading: state.websitePages.getPagesLoading,
        locale: state.i18n.locale,
    }));

    useEffect(() => {
        dispatch(getPages(locale));
    }, [dispatch, locale]);

    const createMarkup = (text) => {
        return { __html: text };
    };

    function stripHtml(html){
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html.replace(/&nbsp;/g, '');
        return tmp.textContent || tmp.innerText || "";
    }

    return(
        getPagesLoading ?
            <Spin />
        :
        <Fragment>
            <TitleComponent title={I18n.t('service_features')} />
            <MetaComponent description={
                pages.length > 0 ?
                pages.map( (page) => 
                    page.title === "addons" ?
                        stripHtml(page.details).substring(0, 150)
                    : null
                )
                : null
            } />
            <div className="breadcrumb__container">
                <div className="container">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">{I18n.t('service_features')}</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className="container webPages">
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <Card.Header>
                                <h1>{I18n.t('service_features')}</h1>
                            </Card.Header>
                            <Card.Body>
                                {
                                    pages.length > 0 ?
                                        pages.map( (page) => 
                                            page.title === "addons" ?
                                            <div 
                                                dangerouslySetInnerHTML={createMarkup(
                                                    page.details
                                            )}
                                            >
                                            </div>
                                            : null
                                        )
                                    : null
                                }
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}