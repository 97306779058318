// Action Types

/**
 * createTypes is a function to create CONSTANT action types instead of regular strings to prevent typo mistakes.
 * @param  {array} strings
 */
function createTypes(strings, ...types) {
  types = [...strings, types];
  types = types.join("\n");
  types = types.split("\n");
  const TYPES = {};
  for (let type of types) {
    if (type === "") {
      continue;
    }
    TYPES[type] = type;
  }
  return TYPES;
}

/**
 * createOurSteps is a function to create the four types for given action.
 * @method
 * @param {string} PREFEX - The PREFEX for each generated type.
 */
function createSteps(PREFEX) {
  const OUR_STEPS = ["loading", "success", "fail"];

  const OUR_STEP_CREATED = [PREFEX.toUpperCase()];

  OUR_STEPS.forEach((STEP) =>
    OUR_STEP_CREATED.push(`${PREFEX}_${STEP}`.toUpperCase())
  );

  return OUR_STEP_CREATED.join("\n");
}

const types = [
  "GET_ARTICLES",
  "ADD_ARTICLE",
  "DELETE_ARTICLE",
  "EDIT_ARTICLE",
  "GET_USER_ARTICLES",
  "GET_ARTICLE_BY_ID",
];

export default createTypes`
RESET_ARTICLES_ACTIONS

${types.map(createSteps).join("\n")}
`;
