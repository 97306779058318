import React from 'react';
import {Form} from 'react-bootstrap';
import Select, {components} from 'react-select';
import {Translate, I18n} from 'react-redux-i18n';

const {Group, Label, Control} = Form;
const {Feedback} = Control;

export function SelectBox({
  name,
  controlId = '',
  label = '',
  desc = '',
  onChange,
  onBlur,
  value = '',
  feedback = '',
  feedbackType = 'invalid',
  required = true,
  isInvalid = false,
  placeholder = '',
  options = [],
  loading = false,
  isMulti = false,
  noOptionsMessage = '',
}) {
  placeholder = placeholder ? I18n.t(placeholder) : '';
  feedback = feedback && feedback.toString();
  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span className="custom-css-class">
          {noOptionsMessage ? noOptionsMessage : I18n.t('select_no_options')}
        </span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <Group controlId={controlId}>
      <Label>
        <Translate value={label} />
        {required ? (
          <small className="required"> ({I18n.t('required')})</small>
        ) : (
          <small className="optional"> ({I18n.t('optional')})</small>
        )}
      </Label>
      {desc ? (
        <small>
          <Translate value={desc} />
        </small>
      ) : null}
      <Select
        closeMenuOnSelect={isMulti ? false : true}
        name={name}
        placeholder={placeholder}
        isMulti={isMulti}
        onChange={onChange}
        onBlur={onBlur}
        options={options}
        components={{NoOptionsMessage}}
        value={value}
        className={isInvalid ? 'invalid' : ''}
        isLoading={loading}
        menuPortalTarget={typeof window !== 'undefined' && document.html}
      />
      {isInvalid && feedback ? (
        <Feedback className="d-block" type={feedbackType}>
          {feedback}
        </Feedback>
      ) : null}
    </Group>
  );
}
