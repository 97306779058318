const USERDATA = "/api/user";
const UPDATE_USER_PLANS = "api/update_user_plans";
const SET_USER_VIEW = "api/set-default-plan";

const exportedObject = {
  USERDATA,
  UPDATE_USER_PLANS,
  SET_USER_VIEW
};

export default exportedObject;
