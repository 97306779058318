import TYPES  from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
    //Cancel Service Invoice
    CANCEL_SERVICE_INVOICE_LOADING,
    CANCEL_SERVICE_INVOICE_SUCCESS,
    CANCEL_SERVICE_INVOICE_FAIL,
    //Report Service Invoice
    REPORT_SERVICE_INVOICE_LOADING,
    REPORT_SERVICE_INVOICE_SUCCESS,
    REPORT_SERVICE_INVOICE_FAIL,
    //Handover Service Invoice
    HANDOVER_SERVICE_INVOICE_LOADING,
    HANDOVER_SERVICE_INVOICE_SUCCESS,
    HANDOVER_SERVICE_INVOICE_FAIL,
    //Indorse Service Invoice
    INDORSE_SERVICE_INVOICE_LOADING,
    INDORSE_SERVICE_INVOICE_SUCCESS,
    INDORSE_SERVICE_INVOICE_FAIL
} = TYPES;

// Cancel Invoice
export function cancelServiceInvoice(data) {
    return async function cancelServiceInvoiceWithDispatch(dispatch, getState) {
        dispatch(clearErrors());
        dispatch({
        type: CANCEL_SERVICE_INVOICE_LOADING,
        });
        try {
            let response = await axiosInstance.post(
            API.CANCEL_SERVICES_INVOICE, 
            data, 
            tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: CANCEL_SERVICE_INVOICE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: CANCEL_SERVICE_INVOICE_SUCCESS,
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'CANCEL_SERVICE_INVOICE_FAIL'));
                dispatch ({ 
                    type: CANCEL_SERVICE_INVOICE_FAIL 
                });
            }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'CANCEL_SERVICE_INVOICE_FAIL')),
                dispatch ({ 
                    type: CANCEL_SERVICE_INVOICE_FAIL 
                })
            )
            }
        }
    };
}

// Report Invoice
export function reportServiceInvoice(data) {
    return async function reportServiceInvoiceWithDispatch(dispatch, getState) {
        dispatch(clearErrors());
        dispatch({
        type: REPORT_SERVICE_INVOICE_LOADING,
        });
        try {
            let response = await axiosInstance.post(
            API.REPORT_SERVICES_INVOICE, 
            data, 
            tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: REPORT_SERVICE_INVOICE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: REPORT_SERVICE_INVOICE_SUCCESS,
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'REPORT_SERVICE_INVOICE_FAIL'));
                dispatch ({ 
                    type: REPORT_SERVICE_INVOICE_FAIL 
                });
            }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'REPORT_SERVICE_INVOICE_FAIL')),
                dispatch ({ 
                    type: REPORT_SERVICE_INVOICE_FAIL 
                })
            )
            }
        }
    };
}

// Handover Invoice
export function handoverServiceInvoice(data) {
    return async function handoverServiceInvoiceWithDispatch(dispatch, getState) {
        dispatch(clearErrors());
        dispatch({
        type: HANDOVER_SERVICE_INVOICE_LOADING,
        });
        try {
            let response = await axiosInstance.post(
            API.HANDOVER_SERVICES_INVOICE, 
            data, 
            tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: HANDOVER_SERVICE_INVOICE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: HANDOVER_SERVICE_INVOICE_SUCCESS,
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'HANDOVER_SERVICE_INVOICE_FAIL'));
                dispatch ({ 
                    type: HANDOVER_SERVICE_INVOICE_FAIL 
                });
            }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'HANDOVER_SERVICE_INVOICE_FAIL')),
                dispatch ({ 
                    type: HANDOVER_SERVICE_INVOICE_FAIL 
                })
            )
            }
        }
    };
}

// Indorse Invoice
export function indorseServiceInvoice(data) {
    return async function indorseServiceInvoiceWithDispatch(dispatch, getState) {
        dispatch(clearErrors());
        dispatch({
        type: INDORSE_SERVICE_INVOICE_LOADING,
        });
        try {
            let response = await axiosInstance.post(
            API.INDORSE_SERVICES_INVOICE, 
            data, 
            tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: INDORSE_SERVICE_INVOICE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: INDORSE_SERVICE_INVOICE_SUCCESS,
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'INDORSE_SERVICE_INVOICE_FAIL'));
                dispatch ({ 
                    type: INDORSE_SERVICE_INVOICE_FAIL 
                });
            }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'INDORSE_SERVICE_INVOICE_FAIL')),
                dispatch ({ 
                    type: INDORSE_SERVICE_INVOICE_FAIL 
                })
            )
            }
        }
    };
}