import React from 'react';

const SpinPulse = () => {
    return(
        <div className="Lancersin__Loading__Pulse">
            <div className="Lancersin__Loading__ldio">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default SpinPulse;