import React, { useState, useEffect, Fragment } from "react";
import { Link } from 'react-router-dom';
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import { I18n } from "react-redux-i18n";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { getFollowers } from "../../redux/followers/actions/getActions";
import MyFollowings from "./MyFollowings";

const MyFollowers = () => {
  const dispatch = useDispatch();
  const {
    userLoading,
    isAuthenticated,

    getFollowersLoading,
    followers,
    totalFollowers,
    followersPerPage,

    errorId,
    errorMessage,
    lastNotification,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,

    getFollowersLoading: state.followers.getFollowersLoading,
    followers: state.followers.followers,
    totalFollowers: state.followers.totalFollowers,
    followersPerPage: state.followers.followersPerPage,

    errorId: state.error.id,
    errorMessage: state.error.msg,
    lastNotification: state.notifications.lastNotification,
  }));

  const [activePage, setActivePage] = useState(1);
  const [loadingFollowers, setLoadingFollowers] = useState(true);

  useEffect(() => {
    setLoadingFollowers(getFollowersLoading);
  }, [getFollowersLoading]);

  const totalItemsCount = totalFollowers;
  const itemsCountPerPage = Number(followersPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if(isAuthenticated){
      const page = activePage;
      const limit = 20;
      dispatch(getFollowers(page, limit, null));
    }
  }, [dispatch, isAuthenticated, activePage]);

  useEffect(() => {
    if(lastNotification && lastNotification.notification_content.type === "following" && window.location.href.indexOf("/followers") > -1){
        if(activePage === 1){
            const page = 1;
            const limit = 20;
            dispatch(getFollowers(page, limit, null));
        }else{
            setActivePage(1);
        }
    }
  }, [lastNotification, dispatch, activePage]);

  return (
    <Fragment>
      <div className="container">
      <div className="row">
        <div className="col-12 Dashboard__tasks__List">
            <Tabs defaultActiveKey="followers" id="JoinedStats" unmountOnExit={true}>
                <Tab eventKey="followers" title={I18n.t('followers')}>
                    <TitleComponent title={I18n.t('lancersin_followers')} />
                    <MetaComponent />
                    <div className="row Freelancers__List">
                        {
                          loadingFollowers || userLoading ?
                            <Spin />
                        : errorId === "GET_FOLLOWERS_FAIL" && errorMessage === "Network Error" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                            />
                        </div>
                        : errorId === "GET_FOLLOWERS_FAIL" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                            />
                        </div>
                        : followers.length > 0 &&  !loadingFollowers ?
                        <Fragment>
                            {
                            followers.map((item) => (
                              <div className="col-md-6 col-lg-4 mb-4" key={item.id}>
                                  <div className="Profile-Card box-shadow">
                                      <div className="row">
                                          <div className="col-md-12">
                                              <div className="ProfileData">
                                                  <div className="ProfileImage">
                                                      <figure>
                                                          <img src={item.image.url ? item.image.url : item.image} alt={item.fname + " " + item.lname} className="img-fluid" />
                                                      </figure>
                                                  </div>
                                                  <div className="ProfileContent">
                                                      <h2 className="ProfileName">
                                                          <Link to={{pathname:`/in/${item.username}`}}>{item.fname + " " + item.lname}</Link>
                                                          {
                                                              item.price_per_hour ?
                                                              <Fragment>
                                                                  <span className="HourRate">{" " + item.price_per_hour}</span>
                                                                  <span>{I18n.t('dollar_per_hour')}</span>
                                                              </Fragment>
                                                              : null
                                                          }
                                                      </h2>
                                                      <h3 className="ProfileTitle">{item.position ? item.position : null}</h3>
                                                      {
                                                          item.country && item.city ?
                                                          <div className="LocationTime">
                                                              <address>
                                                                  <span className="LocationFlag">
                                                                      <img src={item.country.flag} alt={item.country.name} />
                                                                  </span>
                                                                  <span className="Location">{item.city.name}-{item.country.name}</span>
                                                              </address>
                                                          </div>
                                                          : null
                                                      }
                                                      {/*<div className="LastSeen">Last seen: 23 minutes ago</div>*/}
                                                  </div>
                                                  <Link to={{pathname:`/in/${item.username}`}} className="btn btn-primary">{I18n.t('view_profile')}</Link>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div> 
                            ))
                            }
                            <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            </div>
                        </Fragment>
                        :
                        <div className="col-12">
                            <NoItems
                            message="no_followers_message"
                            />
                        </div>
                        }
                    </div>
                </Tab>
                <Tab eventKey="followings" title={I18n.t('followings')}>
                    <MyFollowings />
                </Tab>
            </Tabs>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default MyFollowers;
