import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";
import translations from "./l10n/translations";

const initialState = {};

const middleware = [thunk];

const devTools = typeof window !== 'undefined' && 
  (window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 })) ||
  ((a) => a);

const store = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(...middleware), devTools)
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));

store.dispatch(setLocale("en"));

export default store;
