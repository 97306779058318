import React from 'react';
import {I18n} from 'react-redux-i18n';
import {Tabs, Tab} from 'react-bootstrap';
import {Link} from 'react-router-dom';

function HowItWorks() {
  return (
    <section className="HowWorks">
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>{I18n.t('how_it_works')}</h2>
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Tabs id="HowTabs" unmountOnExit={true}>
              <Tab eventKey="Clients" title={I18n.t('clients_tab')}>
                <section>
                  <h3>{I18n.t('projects')}</h3>
                  <div className="row flex-row-reverse-mobile">
                    <div className="col-md-7 mb-5">
                      <ul className="FeaturesList">
                        <li>
                          <h4>{I18n.t('how_client_project_head_one')}</h4>
                          <p>{I18n.t('how_client_project_desc_one')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_client_project_head_two')}</h4>
                          <p>{I18n.t('how_client_project_desc_two')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_client_project_head_three')}</h4>
                          <p>{I18n.t('how_client_project_desc_three')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_client_project_head_four')}</h4>
                          <p>{I18n.t('how_client_project_desc_four')}</p>
                        </li>
                      </ul>
                      <div className="action_btn">
                        <Link
                          to={'/projects/create'}
                          aria-label={I18n.t(
                            'lancersin_projects_adding_project',
                          )}
                          className="btn btn-primary">
                          <i className="icon icon-plus"></i>
                          {I18n.t('post_project')}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5 mb-5">
                      <img
                        src="images/how-client-project.png"
                        className="img-fluid FlipImgEnglish"
                        loading="lazy"
                        alt={I18n.t('clients_tab')}
                      />
                    </div>
                  </div>
                </section>
                <section>
                  <h3>{I18n.t('services')}</h3>
                  <div className="row">
                    <div className="col-md-5">
                      <img
                        src="images/services.png"
                        className="img-fluid FlipImgEnglish"
                        loading="lazy"
                        alt={I18n.t('clients_tab')}
                      />
                    </div>
                    <div className="col-md-7">
                      <ul className="FeaturesList">
                        <li>
                          <h4>{I18n.t('browse_services')}</h4>
                          <p>{I18n.t('how_client_service_desc_one')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_client_service_head_two')}</h4>
                          <p>{I18n.t('how_client_service_desc_two')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_client_project_head_four')}</h4>
                          <p>{I18n.t('how_client_service_desc_three')}</p>
                        </li>
                      </ul>
                      <div className="action_btn">
                        <Link
                          to={'/services'}
                          aria-label={I18n.t('browse_services')}
                          className="btn btn-primary">
                          {I18n.t('browse_services')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </Tab>
              <Tab eventKey="Freelancers" title={I18n.t('freelancers_tab')}>
                <section>
                  <h3>{I18n.t('services')}</h3>
                  <div className="row flex-row-reverse-mobile">
                    <div className="col-md-7 mb-5">
                      <ul className="FeaturesList">
                        <li>
                          <h4>{I18n.t('how_freelancer_service_head_one')}</h4>
                          <p>{I18n.t('how_freelancer_service_desc_one')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_freelancer_service_head_two')}</h4>
                          <p>{I18n.t('how_freelancer_service_desc_two')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_freelancer_service_head_three')}</h4>
                          <p>{I18n.t('how_freelancer_service_desc_three')}</p>
                        </li>
                      </ul>
                      <div className="action_btn">
                        <Link
                          to={'/signup'}
                          aria-label={I18n.t('signup')}
                          className="btn btn-primary">
                          {I18n.t('signup')}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5 mb-5">
                      <img
                        src="images/how-freelancer-services.png"
                        className="img-fluid"
                        loading="lazy"
                        alt={I18n.t('freelancers_tab')}
                      />
                    </div>
                  </div>
                </section>
                <section>
                  <h3>{I18n.t('portfolio')}</h3>
                  <div className="row">
                    <div className="col-md-5 mb-5">
                      <img
                        src="images/how-freelancer-portfolio.png"
                        className="img-fluid FlipImgArabic"
                        loading="lazy"
                        alt={I18n.t('freelancers_tab')}
                      />
                    </div>
                    <div className="col-md-7 mb-5">
                      <ul className="FeaturesList">
                        <li>
                          <h4>{I18n.t('how_freelancer_portfolio_head_one')}</h4>
                          <p>{I18n.t('how_freelancer_portfolio_desc_one')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_freelancer_service_head_two')}</h4>
                          <p>{I18n.t('how_freelancer_portfolio_desc_two')} </p>
                        </li>
                      </ul>
                      <div className="action_btn">
                        <Link
                          to={'/signup'}
                          aria-label={I18n.t('signup')}
                          className="btn btn-primary">
                          {I18n.t('signup')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h3>{I18n.t('projects')}</h3>
                  <div className="row flex-row-reverse-mobile">
                    <div className="col-md-7">
                      <ul className="FeaturesList">
                        <li>
                          <h4>{I18n.t('how_freelancer_project_head_one')}</h4>
                          <p>{I18n.t('how_freelancer_project_desc_one')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_freelancer_project_head_two')}</h4>
                          <p>{I18n.t('how_freelancer_project_desc_two')}</p>
                        </li>
                        <li>
                          <h4>{I18n.t('how_freelancer_service_head_three')}</h4>
                          <p>{I18n.t('how_freelancer_service_desc_three')}</p>
                        </li>
                      </ul>
                      <div className="action_btn">
                        <Link
                          to={'/projects'}
                          aria-label={I18n.t('browse_projects')}
                          className="btn btn-primary">
                          {I18n.t('browse_projects')}
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-5">
                      <img
                        src="images/projects-freelancer.png"
                        className="img-fluid"
                        loading="lazy"
                        alt={I18n.t('freelancers_tab')}
                      />
                    </div>
                  </div>
                </section>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
