import React, { useState, useEffect, Fragment, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import ServiceCard from "../ServiceCardUser";
import { I18n } from "react-redux-i18n";
import ConfToast from "../ConfToast";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { getFavouriteProjects } from "../../redux/favourite/actions/getActions";
import { CLEAR_DELETE_FAVOURITE_SUCCESS } from "../../redux/favourite/actions/types";

const FavServices = () => {
  const dispatch = useDispatch();
  const {
    userLoading,
    isAuthenticated,
    locale,

    getFavServicesLoading,
    favServices,
    totalFavServices,
    favServicesPerPage,

    deletingFavouriteSuccess,

    errorId,
    errorMessage,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    locale: state.i18n.locale,

    getFavServicesLoading: state.favourites.getFavServicesLoading,
    favServices: state.favourites.favServices,
    totalFavServices: state.favourites.totalFavServices,
    favServicesPerPage: state.favourites.favServicesPerPage,

    deletingFavouriteSuccess: state.favourites.deletingFavouriteSuccess,

    errorId: state.error.id,
    errorMessage: state.error.msg,
  }));

  const [activePage, setActivePage] = useState(1);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loadingFavServices, setLoadingFavServices] = useState(true);

  useState(() => {
    setLoadingFavServices(getFavServicesLoading);
  }, [getFavServicesLoading]);

  const handleToast = useCallback((showToast, title, message, status, delay) => {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  const totalItemsCount = totalFavServices;
  const itemsCountPerPage = Number(favServicesPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if(isAuthenticated){
      const page = activePage;
      const limit = 30;
      const morphType = "services";
      dispatch(getFavouriteProjects(morphType, locale, page, limit));
    }
  }, [dispatch, locale, isAuthenticated, activePage]);

  useEffect(() => {
    if (deletingFavouriteSuccess){
      const page = 1;
      const limit = 30;
      const morphType = "services";
      dispatch(getFavouriteProjects(morphType, locale, page, limit));
      setTimeout(() => {
        handleToast(true, I18n.t('deleting_service_from_fav'), I18n.t('deleting_service_from_fav_success'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_DELETE_FAVOURITE_SUCCESS
      });
    };
  },[deletingFavouriteSuccess, handleToast, dispatch, locale]);

  return (
    <Fragment>
        <ConfToast
            showConf={confShow}
            setShowConf={setConfShow}
            title={confTitle}
            message={confMessage}
            status={confStatus}
            delay={delay}
        />
        <TitleComponent title={I18n.t('lancersin_fav_services')} />
        <MetaComponent />
        <div className="container">
            <div className="Offers__List">
                <div className="row">
                    {
                        loadingFavServices || userLoading ?
                            <Spin />
                        : errorId === "GET_FAV_SERVICES_FAIL" && errorMessage === "Network Error" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                            />
                        </div>
                        : errorId === "GET_FAV_SERVICES_FAIL" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                            />
                        </div>
                        : favServices.length > 0 &&  !loadingFavServices ?
                        <Fragment>
                            {
                            favServices.map((item) => (
                                <ServiceCard key={item.id} item={item.service} deleteButton={true} />
                            ))
                            }
                            <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            </div>
                        </Fragment>
                        :
                        <div className="col-12">
                            <NoItems
                            title="no_services_found"
                            message="no_fav_services_message"
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    </Fragment>
  );
};

export default FavServices;
