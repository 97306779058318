import TYPES, { CLEAR_ADD_PAYPAL_SUCCESS, CLEAR_ADD_BANK_ACCOUNT_SUCCESS, CLEAR_PAYMENT_METHOD_SUCCESS, CLEAR_REQUEST_WITHDRAWAL_SUCCESS } from "../actions/types";

const INITIAL_STATE = {
  // ADD/EDIT PAYPAL ACCOUNT
  addingPaypalAccountLoading: false,
  addingPaypalAccountError: "",
  addingPaypalAccountSuccess: false,
  // ADD/EDIT BANK ACCOUNT
  addingBankAccountLoading: false,
  addingBankAccountError: "",
  addingBankAccountSuccess: false,
  // SELECT PAYMENT METHOD
  selectPaymentMethodLoading: false,
  selectPaymentMethodError: "",
  selectPaymentMethodSuccess: false,
  // REQUEST WITHDRAWAL
  requestWithdrawalLoading: false,
  requestWithdrawalError: "",
  requestWithdrawalSuccess: false,
  // WITHDRAWAL REQUESTS
  getWithdrawalRequestsLoading: false,
  getWithdrawalRequestsError: "",
  withdrawalRequests: [],
  totalWithdrawalRequests: 0,
  withdrawalRequestsPerPage: 0,
};

const {
  // ADD/EDIT PAYPAL ACCOUNT
  ADD_PAYPAL_LOADING,
  ADD_PAYPAL_SUCCESS,
  ADD_PAYPAL_FAIL,
  // ADD/EDIT BANK ACCOUNT
  ADD_BANK_ACCOUNT_LOADING,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAIL,
  // SELECT PAYMENT METHOD
  SELECT_PAYMENT_METHOD_LOADING,
  SELECT_PAYMENT_METHOD_SUCCESS,
  SELECT_PAYMENT_METHOD_FAIL,
  // REQUEST WITHDRAWAL
  REQUEST_WITHDRAWAL_LOADING,
  REQUEST_WITHDRAWAL_SUCCESS,
  REQUEST_WITHDRAWAL_FAIL,
  // GET WITHDRAWAL REQUESTS
  GET_WITHDRAWAL_REQUESTS_LOADING,
  GET_WITHDRAWAL_REQUESTS_SUCCESS,
  GET_WITHDRAWAL_REQUESTS_FAIL,
} = TYPES;

export default function paymentReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // ADD/EDIT PAYPAL ACCOUNT
    case ADD_PAYPAL_LOADING:
      return {
        ...state,
        addingPaypalAccountLoading: true,
        addingPaypalAccountError: "",
      };
    case ADD_PAYPAL_SUCCESS:
      return {
        ...state,
        addingPaypalAccountLoading: false,
        addingPaypalAccountError: "",
        addingPaypalAccountSuccess: true,
      };
    case ADD_PAYPAL_FAIL:
      return {
        ...state,
        addingPaypalAccountLoading: false,
        addingPaypalAccountError: action.error,
      };
    case CLEAR_ADD_PAYPAL_SUCCESS:
      return{
        ...state,
        addingPaypalAccountSuccess: false,
      };
    // ADD/EDIT BANK ACCOUNT
    case ADD_BANK_ACCOUNT_LOADING:
      return {
        ...state,
        addingBankAccountLoading: true,
        addingBankAccountError: "",
      };
    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        addingBankAccountLoading: false,
        addingBankAccountError: "",
        addingBankAccountSuccess: true,
      };
    case ADD_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        addingBankAccountLoading: false,
        addingBankAccountError: action.error,
      };
    case CLEAR_ADD_BANK_ACCOUNT_SUCCESS:
      return{
        ...state,
        addingBankAccountSuccess: false,
      };
    // SELECT PAYMENT METHOD
    case SELECT_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        selectPaymentMethodLoading: true,
        selectPaymentMethodError: "",
      };
    case SELECT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        selectPaymentMethodLoading: false,
        selectPaymentMethodError: "",
        selectPaymentMethodSuccess: true,
      };
    case SELECT_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        selectPaymentMethodLoading: false,
        selectPaymentMethodError: action.error,
      };
    case CLEAR_PAYMENT_METHOD_SUCCESS:
      return{
        ...state,
        selectPaymentMethodSuccess: false,
      };
    // REQUEST WITHDRAWAL
    case REQUEST_WITHDRAWAL_LOADING:
      return {
        ...state,
        requestWithdrawalLoading: true,
        requestWithdrawalError: "",
      };
    case REQUEST_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        requestWithdrawalLoading: false,
        requestWithdrawalError: "",
        requestWithdrawalSuccess: true,
      };
    case REQUEST_WITHDRAWAL_FAIL:
      return {
        ...state,
        requestWithdrawalLoading: false,
        requestWithdrawalError: action.error,
      };
    case CLEAR_REQUEST_WITHDRAWAL_SUCCESS:
      return{
        ...state,
        requestWithdrawalSuccess: false,
      };
    // WITHDRAWAL REQUESTS
    case GET_WITHDRAWAL_REQUESTS_LOADING:
      return {
        ...state,
        getWithdrawalRequestsLoading: true,
        getWithdrawalRequestsError: "",
      };
    case GET_WITHDRAWAL_REQUESTS_SUCCESS:
      return {
        ...state,
        getWithdrawalRequestsLoading: false,
        getWithdrawalRequestsError: "",
        withdrawalRequests: action.payload.data,
        totalWithdrawalRequests: action.payload.totalRecords,
        withdrawalRequestsPerPage: action.payload.itemsPerPage,
      };
    case GET_WITHDRAWAL_REQUESTS_FAIL:
      return {
        ...state,
        getWithdrawalRequestsLoading: false,
        getWithdrawalRequestsError: action.error,
      };
    default:
      return state;
  }
}
