import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Echo from "laravel-echo"
import Pusher from "pusher-js"
import TYPES from "../../redux/notification/actions/types";
import MESSAGESTYPES from "../../redux/messages/actions/types";
import { ServerURL, ServerIP } from "../Server";

const {
  GET_LAST_NOTIFICATION_SUCCESS,
} = TYPES;

const {
  GET_LAST_MESSAGE_SUCCESS,
} = MESSAGESTYPES;


export default function Channels(){

  const dispatch = useDispatch();

  var {
    //Logged User
    loggedUser,
    isAuthenticated,
    token,
  } = useSelector((state) => ({
    loggedUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
  }));

  // Pusher Config
  useEffect(() => {
    //Pusher.logToConsole = true;

    window.Pusher = Pusher;

    if (!window.Echo && isAuthenticated){
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'b98c7859a84a34daa9c9',
        wsHost: ServerIP,
        wssHost: ServerIP,
        wsPort: 2053,
        wssPort: 2053,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
        cluster: 'eu',
        forceTLS: true,
        authEndpoint: ServerURL + '/broadcasting/auth',
        auth: isAuthenticated ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }: undefined,
      });
      const channel = window.Echo.private('App.Models.users.' + loggedUser.id);
      channel.notification((notification) => {
        if(notification.NotificationsType === "Conversation"){
          dispatch ({
            type: GET_LAST_MESSAGE_SUCCESS,
            payload: notification
        })
        }else{
          dispatch ({
              type: GET_LAST_NOTIFICATION_SUCCESS,
              payload: notification
          })
        }
      });
    }
    
  }, [loggedUser, isAuthenticated, dispatch, token]);

  return <div />;

}