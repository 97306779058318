// Actions for Deleting Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  DELETE_FAVOURITE_LOADING,
  DELETE_FAVOURITE_SUCCESS,
  DELETE_FAVOURITE_FAIL,
} = TYPES;

// DELETE FAVOURITE
export function deleteFavourite( morphType = null, id = null, locale ) {
  return async function deleteFavouriteWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: DELETE_FAVOURITE_LOADING
    });
    try {
        const body = {morphType, id};
        let response = await axiosInstance
        .post(API.DELETE_FAVOURITE, body , tokenConfig(getState, locale));
        if(response.status !== 200){
            dispatch({
              type: DELETE_FAVOURITE_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: DELETE_FAVOURITE_SUCCESS,
            status: response.data.data,
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch(returnErrors(error.response.data, error.response.status, 'DELETE_FAVOURITE_FAIL'));
          dispatch ({ 
              type: DELETE_FAVOURITE_FAIL 
          });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch(returnErrors(errorParsed.message, "Network Error", 'DELETE_FAVOURITE_FAIL'));
          dispatch ({ 
              type: DELETE_FAVOURITE_FAIL 
          })
        }
    }
  }
}
