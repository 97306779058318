const NOTIFICATIONS = "/api/notifications";
const READ = NOTIFICATIONS + "/mark-as-read";
const INVITE_USER = NOTIFICATIONS + "/invite";

const exportedObject = {
  NOTIFICATIONS,
  READ,
  INVITE_USER
};

export default exportedObject;
