import React from 'react';
import {Tabs,Tab} from 'react-bootstrap';
import { I18n } from 'react-redux-i18n';
import {Num} from '../Numbers';
import RatingStarsPreview from "../RatingStarsPreview";

export default function ProfileStats(props) {
    const freelancer = props.freelancerdata;

    function userStatistics(plans){
        let statisticsContainer = [];
        for(var i = 0; i < plans.length; i++) {
            if (plans[i].id === 1 || plans[i].id === 2) {
                statisticsContainer.push(
                    <Tab eventKey="FreelancerStats" title={I18n.t('freelancer_stats')}>
                        <ul className="StatsList">
                            <li>
                                <span>{I18n.t('total_earnings')}</span>
                                <span>{Num(freelancer.totalEarnings ? freelancer.totalEarnings : 0)}$</span>
                            </li>
                            <li>
                                <span>{I18n.t('services_done')}</span>
                                <span>{freelancer.servicesCompleted ? freelancer.servicesCompleted : 0}</span>
                            </li>
                            {/*<li>
                                <span>Buyer worked with</span>
                                <span>{freelancer.buyersWorkedWith}</span>
                            </li>*/}
                            <li>
                                <span>{I18n.t('feedback_title')}</span>
                                <span><RatingStarsPreview rate={freelancer.rate_as_seller ? freelancer.rate_as_seller : 0} /></span>
                            </li>
                            <li>
                                <span>{I18n.t('followers')}</span>
                                <span>{freelancer.followersCount ? freelancer.followersCount : 0}</span>
                            </li>
                            <li>
                                <span>{I18n.t('followings')}</span>
                                <span>{freelancer.followingsCount ? freelancer.followingsCount : 0}</span>
                            </li>
                            {/*<li>
                                <span>Contest Completed</span>
                                <span>{freelancer.contestsAsFreelancer}</span>
                            </li>*/}
                        </ul>
                        <footer className="StatsList__footer">{I18n.t('member_since')}, {freelancer.created_at.Date}</footer>
                    </Tab>
                )
            }
            if (plans[i].id === 3 || plans[i].id === 4) {
                statisticsContainer.push(
                    <Tab eventKey="ClientStats" title={I18n.t('client_stats')}>
                        <ul className="StatsList">
                            <li>
                                <span>{I18n.t('total_spent')}</span>
                                <span>{Num(freelancer.totalSpent ? freelancer.totalSpent : 0)}$</span>
                            </li>
                            <li>
                                <span>{I18n.t('projects_posted')}</span>
                                <span>{freelancer.projectsPosted ? freelancer.projectsPosted : 0}</span>
                            </li>
                            <li>
                                <span>{I18n.t('projects_paid')}</span>
                                <span>{freelancer.projectsPaid ? freelancer.projectsPaid : 0}</span>
                            </li>
                            <li>
                                <span>{I18n.t('projects_done')}</span>
                                <span>{freelancer.projectsCompleted ? freelancer.projectsCompleted : 0}</span>
                            </li>
                            <li>
                                <span>{I18n.t('services_purchased')}</span>
                                <span>{freelancer.servicesPurchased ? freelancer.servicesPurchased : 0}</span>
                            </li>
                            {/*<li>
                                <span>Seller worked with</span>
                                <span>{freelancer.sellersWorkedWith}</span>
                            </li>*/}
                            <li>
                                <span>{I18n.t('feedback_title')}</span>
                                <span><RatingStarsPreview rate={freelancer.rate_as_buyer ? freelancer.rate_as_buyer : 0} /></span>
                            </li>
                            <li>
                                <span>{I18n.t('followers')}</span>
                                <span>{freelancer.followersCount ? freelancer.followersCount : 0}</span>
                            </li>
                            <li>
                                <span>{I18n.t('followings')}</span>
                                <span>{freelancer.followingsCount ? freelancer.followingsCount : 0}</span>
                            </li>
                            {/*<li>
                                <span>Contest Completed</span>
                                <span>{freelancer.contestsAsClient}</span>
                            </li>*/}
                        </ul>
                        <footer className="StatsList__footer">{I18n.t('member_since')}, {freelancer.created_at.Date}</footer>
                    </Tab>
                )
            }
        }
        return statisticsContainer;
    }

    return(
        freelancer.plans && freelancer.plans.length > 0 ?
            <Tabs id="ProfileStats">
                {
                    userStatistics(freelancer.plans).map(item => item)
                }
            </Tabs>
        : null
    )
}