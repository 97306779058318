const translations = {
  en: {
    browse_projects: 'Browse Projects',
    contests: 'Contests',
    services: 'Services',
    portfolio: 'Portfolio',
    how_it_works: 'How It Works?',
    find_freelancer: 'Find a Freelancer',
    welcome_back: 'Welcome Back',
    post_project: 'Post a Project',
    logout: 'Logout',
    dashboard: 'Dashboard',
    signup: 'Sign up',
    signup_now: 'Signup now',
    signin: 'Sign in',
    dont_have_account: "Don't have an account?",
    password: 'Password',
    repassword: 'Retype Password',
    email_username: 'Email or Username',
    username: 'Username',
    account_type: 'Account Type',
    add_service: 'Add Service',
    categories: 'Categories',
    freelancers: 'Freelancers',
    lancersin_pro: 'Lancersin Pro',
    about: 'About',
    about_us: 'About us',
    sitemap: 'Sitemap',
    articles: 'Articles',
    referral_program: 'Referral Program',
    terms: 'Terms',
    privacy_policy: 'Privacy Policy',
    copyright_policy: 'Copyright Policy',
    code_of_conduct: 'Code of Conduct',
    fees_charges: 'Fees & Charges',
    copyright: 'Copyright ©',
    lancersin_tech: 'Lancersin Technology',
    favourite: 'Favourite',
    title: 'Title',
    description: 'Description',
    requirements_title: 'requirements',
    requirements_description:
      'Ask the client the questions that you need answers for to start your job (If found), Please note that we will forward what you will write directly to the client after he buys your service, So write as if you are talking to the client himself. As much as your questions are clear, as mush you got useful answers to start your job.',
    thumbnail_image: 'Thumbnail image',
    service_images: 'service images',
    number_to_deliver: 'number of days to deliver',
    number_to_deliver_service: 'Number of service delivery days',
    number_to_deliver_project:
      'The number of days expected to deliver the project',
    day_s: 'day(s)',
    price: 'price',
    you_will_receive: 'you will receive',
    hide_from_services: "Pause (I don't want to receive more requests)",
    add_feature: 'Add a service improvement',
    add: 'add',
    submit: 'Submit',
    close: 'Close',
    hide: 'Hide',
    sort_message:
      'after uploading images you can use the list that will appear below to sort or delete images',
    uploader_txt: "Drag 'n' drop some files here, or click to select files",
    search_keywords: 'search keywords',
    add_keywords: 'Select or add keywords...',
    category: 'category',
    sub_category: 'sub category',
    video_samples: 'video samples',
    link: 'Link',
    video_placeholder: 'Add Youtube or Vimeo link',
    delete: 'Delete',
    add_portfolio_item: 'Add portfolio item',
    add_portfolio_item_title: 'Add portfolio item',
    portfolio_images: 'portfolio images',
    video_link: 'video link',
    project_link: 'project URL',
    skills: 'skills',
    add_skills: 'Select or add skills...',
    hide_from_portfolio: 'hide item from portfolio list',
    remove_items_margin: 'remove images margin',
    not_exceed_mb: "File shouldn't exceed 1MB",
    edit: 'Edit',
    by: 'By',
    followers: 'Followers',
    hire_me: 'Hire Me',
    invite_project: 'Invite to project',
    invite_contest: 'Invite to contest',
    follow: 'Follow',
    un_follow: 'Unfollow',
    live_preview: 'Live Preview',
    published_on: 'Published on',
    comments: 'comments',
    add_comment: 'add comment',
    edit_portfolio_title: 'Edit Portfolio Item',
    delete_confirmation: 'Delete Confirmation',
    delete_confirmation_message: 'Are you sure you need to delete this item?!',
    edit_comment: 'Edit Comment',
    save: 'Save',
    cancel: 'Cancel',
    report_content: 'Report Content',
    join_conversation: 'Sign up or Sign in to join the conversation',
    add_feedback:
      'Add your feedback for %{fname} %{lname}’s portfolio by signing in or signing up.',
    reason: 'Reason',
    sort_data_message:
      'after adding your videos you can use the list that will appear below to sort or delete URLs',
    valid_video: 'Please enter a valid youtube or vimeo URL',
    under_review: 'Waiting for approval',
    status: 'status',
    approved: 'Approved',
    features_to_service: 'Improvements available for this service',
    buy_service: 'Buy Service',
    total_price: 'Total Price',
    buy_now: 'Buy Now',
    message_seller: 'Message Seller',
    sign_to_buy: 'Sign up or Sign in to buy',
    sign_to_view: 'Sign up or Sign in to view content',
    sign_service_buy:
      'Buy %{fname} %{lname}’s services by signing in or signing up.',
    sign_services_preview: 'View our services by signing in or signing up.',
    sign_projects_preview: 'View our projects by signing in or signing up.',
    sign_dashboard_preview: 'View your dashboard by signing in or signing up.',
    sign_freelancers_preview: 'View our heroes by signing in or signing up.',
    sign_for_preview: 'View this page content by signing in or signing up.',
    upload_notification: 'Please submit form after your upload finished',
    upload_notification_title: 'Uploading files:',
    my_profile: 'My Profile',
    edit_profile: 'Edit Profile',
    first_name: 'First Name',
    last_name: 'Last Name',
    birth_date: 'Birthdate',
    position: 'Position',
    hourly_price: 'Hourly Price',
    email_address: 'Email Address',
    mobile: 'Mobile Number',
    profile_image: 'Profile Image',
    country: 'Country',
    city: 'City',
    about_user: 'About',
    local_time: 'Local Time',
    follower: 'Follower',
    email: 'Email',
    relatedـservices: 'Related Services',
    service_statistics: 'Service Statistics',
    bought_service: 'Bought this service',
    inprogress_requests: 'In progress requests',
    total_request: 'Total Requests',
    home: 'Home',
    my_services: 'My Services',
    closed_services: 'Closed Services',
    service_features: 'Service improvements',
    required_information: 'Required Information',
    seller_requirements: 'Seller Requirements',
    please_clarify:
      'Please clarify the below to the seller so he can proceed with your request',
    your_clarification: 'Your Clarification',
    upload_files: 'Upload Files',
    buyer_reply: 'Buyer Reply',
    attached_files: 'Attached Files',
    service_discussion: 'Service Discussion',
    uploading_files: 'Uploading Files',
    error_uploading_file: 'Error uploading file',
    feedback_title: 'Feedback',
    feedback: 'Feedback',
    entries: 'Entries',
    designers: 'Designers',
    contest_brief: 'Contest Brief',
    time_left: 'Time Left',
    prize: 'Prize',
    browse_contests: 'Browse Contests',
    contest_description: 'Contest Description',
    look_n_feel: 'Design Look & Feel',
    fav_colors: 'Favourite Colors',
    client_likes: 'Client Likes',
    add_project: 'Add Project',
    budget: 'Budget',
    proposalAmountDesc: 'Paid to you: $500.00 - $50.00 fee = $450.00',
    add_contest: 'Add Contest',
    edit_service: 'Edit Service',
    min_price: 'Min Price',
    max_price: 'Max Price',
    min_days: 'Minimum delivery days',
    max_days: 'Maximum delivery days',
    search: 'Search',
    reset: 'Reset',
    service_delivered: 'Mark as delivered',
    please_wait: 'Please wait',
    project_budget: 'Project budget',
    bids_average: 'Bids Avg.',
    bids_sent: 'Bids sent',
    created_at: 'Created at',
    updated_at: 'Updated at',
    no_bids: 'No bids yet',
    dollar: '$',
    receiving_bids: 'Receiving bids',
    in_progress: 'In progress',
    project_images: 'Project images',
    my_projects: 'My projects',
    running_projects: 'Running projects',
    closed_projects: 'Closed projects',
    field_required: 'This field is required',
    complete_required_info: 'Please complete the required information',
    complete_required_info_title: 'Required fields:',
    fix_info_title: 'Fix mistakes:',
    fix_info: 'Please fix fields mistakes before submission',
    project_description: 'Project Description',
    attachments: 'Attachments',
    required_skills: 'Required Skills',
    submit_proposal: 'Submit a Proposal',
    submit_the_proposal: 'Submit The Proposal',
    proposals: 'Proposals',
    project_discussions: 'Project Discussions',
    real_text: 'Please type meaningful text in this field',
    file_removed: 'The file has been removed',
    uploaded_files: 'Uploaded Files:',
    upload_canceled: 'Upload canceled',
    upload_success: 'File uploaded successfully',
    bid_amount: 'Bid Amount',
    bid_description: 'Bid Description',
    pick_proposal: 'Pick proposal',
    projects: 'Projects',
    lancersin_projects: 'Lancersin - Projects',
    advanced_search: 'Advanced Search',
    close_search: 'Close Search',
    add_to_favourite: 'Add to favorite',
    thank_you: 'Thank you...',
    comment_success: 'Your comment added successfully',
    hide_proposal: 'Hide Proposal',
    hide_proposal_desc:
      'Turning on this option enables you to hide your proposal from being seen by other freelancers',
    proposal_success: 'Your proposal added successfully',
    join_now: 'Join Now',
    freelancer: 'Freelancer',
    client: 'Client',
    by_registering: 'By continuing you agree to our',
    terms_conditions: 'Terms and Conditions',
    signup_consent:
      'and you consent to receive offers and opportunities from Lancersin by telephone, text message, and email',
    and: 'and',
    privacyـpolicy: 'Privacy Policy',
    have_account: 'Already have an account?',
    accepted_files: 'Accepted files',
    rejected_files: 'Rejected files',
    max_files: 'The allowed number of files is',
    max_size: 'The file size should not exceed',
    files_extensions: 'The allowed file extensions',
    invalid_file_type: 'Invalid file type, the allowed types are:',
    invalid_max_files: 'Too many files, the allowed number of files is:',
    end_date_time: 'End Date & Time',
    choose_style: 'Choose Style',
    complex: 'Complex',
    minimal: 'Minimal',
    modern: 'Modern',
    classic: 'Classic',
    playful: 'Playful',
    serious: 'Serious',
    subtle: 'Subtle',
    loud: 'Loud',
    luxury: 'Luxury',
    inspiration_url: 'Inspiration URLs',
    inspiration_images: 'Inspiration Images',
    sample_url: 'https://www.lancersin.com',
    post_article: 'Post an Article',
    article_image: 'Article Image',
    lancersin_articles: 'Lancersin - Articles',
    delete_article: 'Delete Article',
    delete_conf: 'Are you sure you need to delete',
    no: 'No',
    yes: 'Yes',
    delete_article_success: 'Article deleted successfully.',
    description_required: 'Description field is required',
    requirements_required: 'Requirements field is required',
    deadline_required: 'Number of days to deliver field is required',
    image_required: 'Image field is required',
    subcategory_required: 'Subcategory field is required',
    category_required: 'Category field is required',
    keywords_required: 'Keywords field is required',
    title_required: 'Title field is required',
    select_no_options: 'No options found',
    choose_cat_first: 'Please choose the category first',
    edit_article: 'Edit Article',
    edit_article_success: 'Article Edited successfully.',
    contest_prize: 'Contest prize',
    price_required: 'Price field is required',
    end_date_time_required: 'End Date & Time field is required',
    edit_contest: 'Edit contest',
    edit_contest_success: 'Contest Edited successfully.',
    d: 'd',
    h: 'h',
    m: 'm',
    lancersin_contests: 'Lancersin - Contests',
    insert_valid_link: 'please enter a valid URL',
    price_error: 'Price should be equal to or more than',
    closed_contests: 'Closed Contests',
    submit_design: 'Submit design',
    submit_design_success: 'Design submitted successfully.',
    design_image: 'Design image',
    closed: 'Completed',
    rate_bid: 'Rate',
    rating_bid: 'Rate the bid',
    rating: 'Rating',
    rating_success: 'Your rating added successfully.',
    adding_bid_comment_title: 'Adding Comment',
    editing_bid_comment_title: 'Editing Comment',
    adding_bid_comment_success: 'Your comment added successfully.',
    editing_bid_comment_success: 'Your comment edited successfully.',
    comment_required: 'Comment field is required',
    in_active: 'Under review',
    active: 'Published',
    delete_comment: 'Delete comment',
    delete_comment_success: 'Comment deleted successfully.',
    like_bid: 'Like the bid',
    like_bid_success: 'You liked this bid successfully.',
    back_to_home: 'Back to homepage',
    no_permissions_message:
      "We are so sorry! you don't have permissions to view this page at this time. Please try again later",
    report_title: 'Report Title',
    report_description: 'Report Description',
    reason_required: 'Reason field is required',
    report_content_success: 'Thank you, report sent successfully',
    report_content_title: 'Report Content:',
    service_delivered_title: 'Service Delivered:',
    service_delivered_message:
      'The service was delivered successfully. Thank you.',
    something_went_wrong:
      'Sorry! Something went wrong. Please try again later.',
    clarification_required: 'Requirements field is required.',
    following: 'Following',
    following_success: 'You followed',
    successfull: 'successfully',
    unfollowing: 'Unfollowing',
    unfollowing_success: 'You unfollowed',
    rate_service: 'Please rate the service',
    feedback_required: 'Feedback field is required',
    service_feedback: 'Rate the service',
    rate_customer: 'Rate the customer',
    valid_url: 'Please enter a valid URL',
    lancersin_services: 'Lancersin - Services',
    lancersin_portfolio: 'Lancersin - Portfolio',
    delete_portfolio: 'Delete Portfolio Item',
    delete_portfolio_success: 'Portfolio Item deleted successfully.',
    like_portfolio: 'Like portfolio item',
    like_portfolio_success: 'You liked this item successfully.',
    add_portfolio_success: 'Your portfolio item added successfully.',
    service_price_limit: 'The price should be less than or equal to 5$',
    video_add_message:
      'After pasting the video link, you must press the Add button below the link entry field to add this video to your service.',
    feature_add_message:
      'After filling up the required fields you should click on add button below to add this feature to your service.',
    any_one_service: 'Anyone can see and buy this service',
    no_one_service: 'No one can see this service except you',
    any_one_portfolio: 'Anyone can see this portfolio item',
    no_one_portfolio: 'No one can see this portfolio item',
    login_required_info: 'Please, log in to proceed with your request',
    login_required_title: 'Login:',
    overview: 'Overview',
    profile_feedback: 'Customers Feedback',
    last_seen: 'Last seen',
    fname_required: 'First name field is required',
    lname_required: 'Last name field is required',
    position_required: 'Position field is required',
    birthdate_required: 'Birthdate field is required',
    hourly_price_required: 'Hourly price field is required',
    email_required: 'Email field is required',
    mobile_required: 'Mobile field is required',
    country_required: 'Country field is required',
    city_required: 'City field is required',
    profile_image_required: 'Profile field is required',
    about_required: 'About field is required',
    skills_required: 'Skills field is required',
    username_required: 'Username field is required',
    password_required: 'Password field is required',
    repassword_required: 'Retype password field is required',
    repassword_password_required:
      'Password and retype password should be matched',
    account_type_required: 'Account type field is required',
    network_error_title: 'Connection Error',
    network_error_message:
      'Your internet connection seems to have a problem. Please, check it and try again later.',
    something_error_title: 'Something Went Wrong',
    something_error_message:
      'We are so sorry. Something went wrong please, reload the page and try again.',
    noitems_title: 'No items found',
    noitems_message: 'No items found, Please check back later.',
    dollar_per_hour: '$USD/hr',
    view_profile: 'View Profile',
    verfication_code: 'Verification Code',
    verification_required: 'Verification code field is required',
    verify: 'Verify',
    verify_email_address: 'Verify email address',
    complete_profile_info:
      'Please complete your profile by clicking on the edit profile below.',
    account_verification_title: 'Account verification',
    account_verification_success: 'Your account has been verified.',
    profile_update_title: 'Profile update',
    profile_update_success: 'Your profile has been updated successfully.',
    check_email_inbox: 'Please, check your email',
    verification_sent:
      'We sent you an email with a verification code so please, copy and paste it below',
    reset_password: 'Reset Password',
    forgot_password: 'Forgot password? click here',
    send_code: 'Send verification code',
    reset_password_success: 'Password has been reset successfully.',
    change_password: 'Change password',
    delete_file: 'Delete File',
    delete_file_error:
      'Deleting files faild, please try to submit the form again',
    browse_most: 'Browse Most of',
    popular_categories: 'popular categories',
    categories_description:
      'Are you one of the millions of freelancers or Job owners who need to work the globe? browse our categories now...',
    get_started: 'get started',
    browse_top: 'Browse top',
    ranked_freelancers: 'ranked freelancers',
    reach_summit: 'There is no way to reach the summit without hard work.',
    incredible_showcase: "Our freelancers' showcases",
    total_earnings: 'Total Earnings',
    withdrawn_request: 'Withdrawable Balance',
    pending_amount: 'Pending Balance',
    total_projects: 'Total Projects',
    projects_done: 'Projects Completed',
    total_contests: 'Total Contests',
    contests_done: 'Contests Completed',
    running_contests: 'Running Contests',
    total_services: 'Total Services',
    services_done: 'Services Delivered',
    running_services: 'Running services',
    free_membership: 'Free Membership',
    proposals_sent: 'Proposals Sent',
    proposals_limit: 'Proposals Limit',
    proposals_renewal: 'Proposals Renewal',
    monthly: 'Proposal Per Month',
    uploaded_successfully: 'Uploaded Successfully',
    image_max_width: 'Image max-width',
    image_max_height: 'Image max-height',
    pixel: 'px',
    no_articles_found: 'No articles found',
    no_articles_message_with_action:
      'We are sorry, there are no articles found but you can post an article using the action button below',
    no_articles_message:
      'We are sorry, there are no articles found, Please check back later',
    no_portfolio_found: 'No portfolio items found',
    no_portfolio_message_with_action:
      'We are sorry, there are no portfolio items found but you can add an item using the action button below',
    no_portfolio_message:
      'We are sorry, there are no portfolio items found, Please check back later',
    no_services_found: 'No services found',
    no_services_message_with_action:
      'We are sorry, there are no services found but you can add service using the action button below',
    site_description:
      'Find & hire top freelancers, developers & designers at the lowest price. The world’s marketplace, where you can get your job done. Post your project for free.',
    site_keywords:
      'Lancersin, freelancer, work, remotly, design, design logos, website design, logo design, application development, application, website development, wordpress, wordpress development, developer, designer, contest, services, projects, service, project',
    estimate_required: 'Number of days to deliver field is required',
    bid_amount_required: 'Bid amount field is required',
    amount_less: 'The amount should be less than or equal to',
    amount_greater: 'The amount should be greater than or equal to',
    no_proposals_title: 'No bids added yet',
    no_proposals_message: 'Add the first bid to this project.',
    no_proposals_owner_message:
      'We will notify our freelancers about your project and they will add bids soon.',
    no_comments_title: 'No comments added yet',
    no_comments_message: 'Add the first comment',
    project_delivered: 'Mark project as delivered',
    total_spent: 'Total spent',
    edit_project: 'Edit project',
    delete_project: 'Delete project',
    delete_project_success: 'Project deleted successfully.',
    project_budget_required: 'Project budget field is required',
    adding_project: 'Add Project',
    add_project_success: 'Your project added successfully.',
    edit_project_success: 'Your project edited successfully.',
    no_bids_sent: 'No bids sent yet.',
    adding_project_to_fav: 'Adding a project to favourite',
    adding_project_to_fav_success:
      'The project added to favourite successfully.',
    adding_service_to_fav: 'Adding a service to favourite',
    adding_service_to_fav_success: 'Service added to favourite successfully.',
    bids: 'Bids',
    bid_added: 'Bid Added',
    bid_added_message: 'You already added a bid to this project.',
    project_already_favourite:
      'This project is already on your favourite list.',
    select_bid: 'Select Bid',
    select_bid_conf: 'Are you sure of selecting the below bid:',
    select_project_bid_success: 'Bid selected successfully. Thank you',
    authentication_error:
      'Sorry! something went wrong, Please refresh the page and try again.',
    rate_freelancer: 'Please rate the freelancer',
    rate_project_owner: 'Please rate the project owner',
    project_feedback_freelancer: 'Rate the freelancer',
    project_feedback_owner: 'Rate the project owner',
    close_project: 'Close Project',
    close_project_success: 'Your project was delivered successfully.',
    request_project_closure: 'Request project closure',
    request_project_closure_success:
      'Your request to close the project was sent successfully.',
    request_project_closure_confirm:
      'Are you sure about sending the project closure request?',
    project_feedback_owner_success:
      'Your rating for the project owner has been added successfully.',
    project_close_request: 'Project closure request:',
    project_close_request_message:
      'The project has been requested to be closed from the freelancer side. So, please review all files carefully and if everything is ok, please click on the button below to close the project.',
    delete_from_fav: 'Delete from favourite',
    deleting_project_from_fav: 'Deleting project from favourite',
    deleting_project_from_fav_success:
      'The project was deleted from favourite successfully.',
    deleting_service_from_fav: 'Deleting service from favourite',
    deleting_service_from_fav_success:
      'Service was deleted from favourite successfully.',
    no_projects_found: 'No projects found',
    no_projects_message_with_action:
      'We are sorry, there are no projects found but you can add a project using the action button below',
    no_projects_message_with_no_action:
      'We are sorry, there are no projects found.',
    add_service_success:
      'Your service was added successfully, and currently, it is under review',
    edit_service_success: 'Your service was edited successfully.',
    edit_service_review_success:
      'Your service was edited successfully, and currently, it is under review',
    delete_service: 'Delete Service',
    delete_service_success: 'Your service was deleted successfully',
    service_purchase: 'Service purchase',
    service_purchase_success: 'The service has been bought successfully.',
    reply_to_seller: 'Reply to seller requirements',
    reply_to_seller_success:
      'Your reply was sent successfully and the seller will work on your request soon.',
    price_start: 'Price starts from',
    buyers_feedback: 'Buyers Feedback',
    project_files_hint:
      'Please note that all files attached to this project and their comments will be deleted 30 days after project has been delivered. So please, download all your files before the end of this period to avoid losing files.',
    service_files_hint:
      'Please note that all files attached to this service and their comments will be deleted 30 days after service has been delivered. So please, download all your files before the end of this period to avoid losing files.',
    fav_projects: 'Projects',
    fav_services: 'Services',
    fav_contests: 'Contests',
    lancersin_fav_projects: 'Lancersin - Favourite Projects',
    lancersin_fav_services: 'Lancersin - Favourite Services',
    lancersin_fav_contests: 'Lancersin - Favourite Contests',
    lancersin_followers: 'Lancersin - Followers',
    lancersin_followings: 'Lancersin - Followings',
    added_a_comment: 'added a comment to',
    added_a_bid: 'added a bid to',
    select_a_bid: 'selected your bid on',
    project_of: 'The project of',
    service_of: 'The service of',
    delivered_success: 'was delivered successfully',
    all_notifications: 'All Notifications',
    purchased_service: 'purchased your service',
    notifications: 'Notifications',
    messages: 'Messages',
    delivered_service: 'Service Delivered',
    message: 'Message',
    inquiry_about: 'Inquiry about',
    all_messages: 'All Messages',
    no_fav_projects_message:
      'We are sorry, there are no projects found added to your favourites',
    no_fav_services_message:
      'We are sorry, there are no services found added to your favourites',
    awaiting_payment: 'Awaiting Payment',
    awaiting_payment_message:
      'Please note that you need to pay the below invoices to notify the freelancer to work on it.',
    pay_invoice: 'Pay invoice',
    your_proposal: 'your proposal for',
    send_inquiry: 'Send Inquiry',
    send: 'Send',
    message_required: 'Message field is required',
    message_sent__success: 'Your message was sent successfully',
    sending_message: 'Sending a message',
    project: 'Project',
    service: 'Service',
    proposal: 'Proposal',
    in: 'in',
    contact: 'Contact us',
    message_title: 'Message title',
    full_name: 'Full name',
    fullname_required: 'Full name field is required',
    message_type: 'Message type',
    type_required: 'Message type field is required',
    subject_required: 'Message title field is required',
    message_type_issue: 'Issue',
    message_type_request: 'Request',
    message_type_suggestion: 'Suggestion',
    message_type_inquiry: 'Inquiry',
    message_type_other: 'Other',
    enter_valid_email: 'Please enter a valid email address',
    close_a_project: 'closed the project of',
    no_messages_found: 'No messages found!',
    no_messages_body: "You don't have any messages in your inbox.",
    no_notifications_found: 'No notifications found!',
    no_notifications_body: "You don't have any notifications yet.",
    mark_all_messages_read: 'Mark all messages as read',
    mark_all_messages_read_body:
      'Are you sure of marking all messages as read?',
    mark_all_messages_read_success:
      'All messages have been marked as read successfully.',
    skills_desc:
      "You can choose your skills from the dropdown below, and if you didn't find your skill, just type it and click the enter button on the keyboard",
    no_entries: 'No entries yet',
    service_title: 'Service Title',
    service_description: 'Service Description',
    buyer_can_cancel_service:
      'The clinet has the right to cancel the service directly in case of delay in the specified date.',
    service_desc_placeholder:
      'Please add full specifications about what the client will receive based on the service specified price and time. Please be on point because this will be as a contract between you and the client.',
    feature_title: 'Feature Title',
    feature_description: 'Feature Description',
    feature_desc_placeholder:
      'Please add full specifications about what the client will receive based on the feature specified price and time. Please be on point because this will be as a contract between you and the client.',
    service_title_placeholder:
      'Include a short title that accurately describes your service.',
    service_images_desc:
      'These images will be used as a reference for your previous work within the service detail page, so that the customer has an idea of your experiences.',
    feature_title_placeholder:
      'The description should be simple and give a hint about what the client will receive based on the specified price.',
    verification_desc:
      "If you don't find the email in your inbox you may find it in the Promotions tab if you use the default Gmail layout and in case this happens you need to drag and drop our email to the Primary tab so you can find any updates sent from us easily. In case you still didn't find it please check your junk or spam folder. And also, if you find it you will need also to move this message to your inbox. This happens always for the first email you receive only till you move it to your inbox.",
    watch_register_video:
      'Please watch the below video of the registration process before starting',
    watch_video: 'Watch video',
    still_have_problem:
      "If you still can't verify your account please first be sure that your email address working fine, and you can receive emails from others and",
    watch_register_video_issue:
      'Please watch the below video of the registration process if you face any issues with verification',
    watch_profile_video: 'For completing your profile, please watch this video',
    service_price: 'Service Price',
    increase_by: 'will increase by',
    please_write_requiremens:
      'Please write your requirements, so the seller can start his work:',
    please_write_clarification:
      "Please reply to the seller's requirements, and also, write your requirements, so the seller can start his work:",
    business_requirements: 'Business Requirements',
    order_details: 'Order Details',
    waiting_requirement: 'Incomplete',
    canceled: 'Canceled',
    order_from: 'Order From',
    buyer: 'Buyer',
    order_number: 'Order Number',
    submit_requirements: 'submit requirements to start your order',
    write_requirements: 'Please write your requirements',
    reminder: 'Reminder:',
    please_provide_instruction: 'Please provide instruction so that',
    can_start_order: 'can start your order',
    cancel_order: 'Cancel Order',
    report_problem: 'Report a Problem',
    explain_cancel_order: 'Please explain the reasons for canceling the order',
    cancel_order_succes: 'Service cancelation request sent successfully.',
    explanation_field_required:
      'The Reasons for canceling the order is required.',
    explain_the_problem:
      'Please write the problem description so that we can help you to solve it:',
    problem_description_required: 'The problem description field is required.',
    report_problem_succes: 'The report of the problem sent successfully.',
    service_cancel_request_declined:
      'We are so sorry, the request to cancel the service was rejected. If you have any inquiries, please contact us.',
    service_cancel_request_sent: 'The request to cancel the service was sent.',
    service_handover: 'Service Handover',
    service_handover_success:
      'Service handover request was sent successfully and the buyer will take action soon.',
    delivered_order: 'delivered your order.',
    note: 'Note',
    service_delivery_note:
      'Respond in the next 3 days or the order will be automatically approved and completed.',
    service_delivery_label: 'You received your delivery from',
    service_delivery_message:
      'Are you pleased with the delivery and ready to approve it?',
    service_delivery_approve: 'Yes, I approve delivery',
    service_delivery_decline: 'Request modifications',
    client_declined_delivery:
      'The client have some modification, Do it and click on service handover button again.',
    request_to_cancel_invoice: 'request to cancel the service',
    client_request_cancel_invoice:
      'If there is a service cancel request, take action on it first.',
    service_cancellation_message:
      'The client asks to cancel the service, and the cancellation reason is below, Are you agree, and are ready to approve it?',
    service_cancellation_label: 'You received a cancellation request from',
    service_cancellation_approve: 'Yes, I approve the cancellation',
    service_cancellation_decline: 'No, I decline the cancellation',
    cancellation_request_to_seller:
      'The cancellation request will be sent to the seller for approval, and then sent to the platform management for approval',
    cancellation_request_to_admin:
      'The cancellation request will be sent to the platform management for review and approval',
    delivery_success_title: 'Delivery approval',
    delivery_approval_success: 'You approved the delivery successfully',
    delivered: 'Delivered',
    client_declined_delivery_notification:
      'have some modification, Do it and click on service handover button again.',
    hello: 'Hello',
    please_dont_start_service:
      "Please, don't start your work before receiving the client's requirements. Because the client can cancel this order anytime at this step.",
    order_actions: 'Order actions',
    added_requirments: 'added the requirements to your service',
    service_cancel_request_approved:
      'The request to cancel the service was approved.',
    client_request_cancel_invoice_admin:
      'There is a service cancellation request, waiting for the platform management approval.',
    admin_approve_service:
      'Congratulations, The platform management approved your service.',
    please_wait_till_we_review:
      'Please wait till we review your cancellation request',
    watch_service_video: 'To sell a new service, please watch this video',
    followings: 'followings',
    service_features_hint:
      "The below service improvemennts are optional, so the client won't be forced to have them.",
    learn_how_use: 'Learn how to use it properly',
    no_followers_message: "You don't have any followers yet.",
    no_followings_message: "You don't follow anybody yet.",
    view_as_visitor: 'View my profile like a visitor',
    service_rate_present_customer:
      'Please note that this rating will be displayed on the service details page as a reference for other customers.',
    service_rate_present_freelancer:
      'Please note that this rating will be displayed on the service details page as a reply to the customer review below:',
    service_rating_title: 'Service Review:',
    service_rating_message: 'Service review posted successfully.',
    service_rating_customer_title: 'Customer Review:',
    service_rating_customer_message: 'Customer review posted successfully.',
    withdrawal_accounts: 'Profit Withdrawal Accounts',
    paypal: 'PayPal',
    bank_account: 'Bank Account',
    no_paypal_account: 'You do not have a PayPal account added.',
    paypal_accounnt: 'PayPal Account',
    add_accounnt: 'Add Account',
    edit_accounnt: 'Edit Account',
    paypal_account_success: 'A PayPal account has been added successfully.',
    paypal_account_edit_success:
      'A PayPal account has been edited successfully.',
    email_placeholder: 'email@domain.com',
    paypal_note:
      'The minimum withdrawal amount when using PayPal is 25$ per transaction',
    lancersin_withdrawal_accounts: 'Lancersin - Profit Withdrawal Accounts',
    no_bank_account: 'You do not have a bank account added.',
    bank_account_note:
      'The minimum withdrawal amount when using a bank account is 100$ per transaction',
    bank_account_success: 'The bank account has been added successfully.',
    bank_account_edit_success: 'The bank account has been edited successfully.',
    account_number: 'Account Number',
    iban: 'IBAN',
    swift_code: 'Swift Code',
    routing_number: 'Routing Number / ABA Code',
    bankName_required: 'Bank Name field is required',
    bank_name: 'Bank Name',
    account_currency: 'Account Currency',
    currency_required: 'Account Currency field is required',
    address: 'Address',
    address_required: 'Address field is required',
    postcode: 'Postcode',
    state_province: 'State or Province',
    bank_data_note:
      'Please fill in all data you have for your bank account in English. If you found some data that your bank did not provide no problem because some of the below data are related to US bank accounts.',
    make_default_payment_method: 'A default way to withdraw profits.',
    admin_approve_project:
      'Congratulations, The platform management approved your project.',
    withdrawal_requests: 'Profit withdrawal requests',
    add_request: 'Add Request',
    no_withdrawal_requests:
      'You do not have profit withdrawal requests added yet.',
    amount_required: 'Amount field is required',
    amount: 'Amount',
    withdrawal_request: 'Profit withdrawal request',
    add_withdrawal_account:
      'First, you should add a PayPal Account or Bank Account so you can withdraw your money.',
    withdrawal_bank_default_note:
      "Please note that you chose the bank account as a default profit withdrawal method if you don't like that, please go to the PayPal tab and click on edit or add to choose PayPal account as default instead.",
    withdrawal_paypal_default_note:
      "Please note that you chose the PayPal account as a default profit withdrawal method if you don't like that, please go to the bank account tab and click on edit or add to choose the bank account as default instead.",
    no_withdrawal_default_note:
      'You need to choose the default method for profit withdrawal, You can do that by clicking on the PayPal tab or Bank Account tab and clicking on edit or add and fill all required info then select A default way to withdraw profits.',
    withdrawal_bank_default_balance_note:
      "Please note that you chose the bank account as a default profit withdrawal method and the minimum amount for this method is 100$, and you don't have this amount yet, so please go to the PayPal tab and click on edit or add to choose PayPal account as default instead.",
    withdrawal_balance_note:
      "We are sorry that your balance isn't enough for the withdrawal request.",
    withdrawal_request_success:
      'The profit withdrawal request has been sent successfully.',
    request_number: 'The Request Number',
    request_amount: 'The Request Amount',
    withdrawal_method: 'Withdrawal Method',
    withdrawal_status: 'Withdrawal request status',
    withdrawal_pending: 'Pending',
    withdrawal_sent: 'Sent',
    Transfer_date: 'Transfer Date',
    project_handover: 'Project Handover',
    project_handover_success:
      'Project handover request was sent successfully and the project owner will take action soon.',
    client_request_cancel_project:
      'If there is a project cancellation request, take action on it first.',
    client_request_cancel_project_admin:
      'There is a project cancellation request, waiting for the platform management approval.',
    project_delivery_note:
      'Respond in the next 3 days or the request of the project delivery will be automatically approved and completed.',
    client_declined_project_delivery:
      'The client have some modification, Do it and click on project handover button again.',
    project_delivered_message:
      'The project was delivered successfully. Thank you.',
    project_feedback_freelancer_success:
      'Your rating for the freelancer has been added successfully.',
    project_cancel_request_sent: 'The request to cancel the project was sent.',
    project_cancel_order: 'Cancel Project',
    project_cancel_order_succes:
      'Project cancelation request sent successfully.',
    cancellation_request_to_freelancer:
      'The cancellation request will be sent to the freelancer for approval, and then sent to the platform management for approval',
    project_title: 'Project Title',
    project_title_placeholder:
      'Include a short title that accurately describes your project.',
    project_desc_placeholder:
      'Enter a detailed description of your project and attach examples of what you want if possible.',
    portfolio_title: 'Portfolio Title',
    portfolio_title_placeholder:
      'Include a short title that accurately describes your portfolio.',
    project_cancellation_message:
      'The client asks to cancel the project, and the cancellation reason is below, Are you agree, and are ready to approve it?',
    request_to_cancel_project: 'request to cancel the project',
    project_cancel_request_declined:
      'We are so sorry, the request to cancel the project was rejected. If you have any inquiries, please contact us.',
    project_cancel_request_approved:
      'The request to cancel the project was approved.',
    liked_portfolio: 'liked',
    added_portfolio_comment: 'added a comment to',
    followed_you: 'started following you.',
    lancersin_how: 'Lancersin - How It Works',
    lancersin_freelancers: 'Lancersin - Freelancers',
    lancersin_about_us: 'Lancersin - About us',
    lancersin_contact: 'Lancersin - Contact us',
    lancersin_privacy_policy: 'Lancersin - Privacy Policy',
    lancersin_terms_conditions: 'Lancersin - Terms and Conditions',
    lancersin_dashboard: 'Lancersin - Dashboard',
    lancersin_profile: 'Lancersin - My Profile',
    lancersin_notifications: 'Lancersin - Notifications',
    lancersin_messages: 'Lancersin - Messages',
    lancersin_myportfolio: 'Lancersin - My Portfolio',
    lancersin_myarticles: 'Lancersin - My Articles',
    send_invitation: 'Send Invitation',
    invite_project_success: 'Your invitation sent successfully.',
    invite_to_project: 'invited you to submit a bid on his project',
    cancelled_services: 'Cancelled Services',
    cancelled_projects: 'Cancelled Projects',
    client_declined_project_delivery_notification:
      'have some modification, Do it and click on project handover button again.',
    handover_request_action_needed_project:
      'There is a project handover request, please take an action on it before you can cancel this project',
    handover_request_action_needed_service:
      'There is a service handover request, please take an action on it before you can cancel this service',
    no_projects_Invite_with_action:
      "We are sorry, you don't have any projects, maybe you have a project that is still under review. you can also add a project using the action button below",
    client_feedback_done:
      'evaluated your work, You can also add your evaluation about his/her collaboration now.',
    freelancer_feedback_done: 'evaluated your collaboration.',
    customer_feedback: 'Customer Feedback',
    later: 'Later',
    email_not_verified: "Your email address isn't verified yet:",
    tree_steps: 'Three easy steps',
    create_project: 'Create a Project',
    create_project_desc:
      'It’s free and easy to post a project. Simply fill in a title, describe your project in detail and select a budget and competitive bids come within minutes.',
    select_freelancer: 'Select a Freelancer',
    select_freelancer_desc:
      'Select only the Freelancer, who suits your requirements the most.',
    pay_safely: 'Pay Safely',
    pay_safely_desc:
      "Only pay for work when it has been completed and you're 100% satisfied with the quality using our secure payment gateway.",
    register_to_add:
      'Log in now or create a new account to add a project for free',
    hire: 'Hire',
    professional_freelancers: 'Professional Freelancers',
    hire_desc:
      "Lancersin connect businesses with freelancers from all over the world, improve your company's efficiency with the support of highly skilled professionals.",
    admin_approve_withdrawal:
      'Congratulations, The platform management approved your profit withdrawal request.',
    bids_limit: 'Bids Limit Reached',
    bids_limit_message:
      'You already used the bids quota, you need to wait for any action taken on another project you bid on or the next month, which is closer.',
    client_description:
      '(Looking for freelancers to do my jobs or buy services)',
    freelancer_description: '(Looking for jobs or post my services for sale)',
    switch_to_freelancer: 'Switch view to freelancer',
    switch_to_client: 'Switch view to client',
    recently_added_project: 'recently added a project you maybe interested in.',
    recently_added_service: 'recently added a service you maybe interested in.',
    mark_all_notifications_read: 'Mark all notifications as read',
    mark_all_notifications_read_body:
      'Are you sure of marking all notifications as read?',
    mark_all_notifications_read_success:
      'All notifications have been marked as read successfully.',
    username_regex_error:
      "The Username field should only contain English letters, numbers, and underscore '_' or hyphen '-' between it",
    description_limit:
      'The minimum number of characters for the description field should be more than or equal to 250.',
    about_limit:
      'The minimum number of characters for the about field should be more than or equal to 250.',
    min_character: 'Min character limit:',
    description_limit_small:
      'The minimum number of characters for the description field should be more than or equal to 200.',
    lancersin_projects_receiving: 'Lancersin - Projects Receiving Bids',
    lancersin_projects_progress: 'Lancersin - Projects In Progress',
    lancersin_projects_closed: 'Lancersin - Closed Projects',
    lancersin_projects_cancelled: 'Lancersin - Cancelled Projects',
    lancersin_services_myservices: 'Lancersin - My Services',
    lancersin_services_progress: 'Lancersin - Services In Progress',
    lancersin_services_closed: 'Lancersin - Closed Services',
    lancersin_services_cancelled: 'Lancersin - Cancelled Services',
    lancersin_services_Awaiting: 'Lancersin - Services Waiting Payment',
    verify_robot: "Please verify you're not a robot",
    title_limit:
      'The minimum number of characters for the title field should be more than or equal to 25.',
    projects_posted: 'Projects Posted',
    projects_paid: 'Projects Paid',
    services_purchased: 'Services Purchased',
    member_since: 'Member since',
    total_balance: 'Total Balance',
    refund_balance: 'Refunded Balance',
    not_receive_verify_code: "Didn't receive the verification code?!",
    resend_verify_code: 'Resend verification code',
    client_stats: 'Client Stats',
    freelancer_stats: 'Freelancer Stats',
    join_bids: 'Sign up or Sign in to submit a bid on this project',
    add_bid_lock:
      'Add your bid for %{fname} %{lname}’s project by signing in or signing up.',
    add_similar_project: 'Add similar project',
    start_your_project: 'Start building your project on the Lancersin platform',
    start_your_project_desc:
      'Enter the project details and expected budget, and your project will be reviewed and published for free on the Lancersin platform. After that, you will receive bids from specialized freelancers. You can choose the most suitable bid for you from the submitted bids and start implementing your project.',
    project_guarantee: 'Guarantee',
    project_guarantee_desc:
      'When you deal with a freelancer through the Lancersin platform, Lancersin becomes an intermediary between you and the freelancer who implements your project, and after completing the project with the required quality, the project amount will be transferred to the account of the freelancer.',
    project_tips: 'Some tips for a successful project',
    project_tip_one: 'Enter a short title that describes your project',
    project_tip_two: 'Write the project description accurately and clearly',
    project_tip_three:
      'Choose the appropriate category and subcategory for your project',
    project_tip_four:
      'Choose a budget and number of days appropriate to the size of the project',
    project_tip_five:
      'Break the project into several small phases and provide examples of what you want whenever possible',
    lancersin_projects_adding_project: 'Lancersin - Projects - Add Project',
    client_coupon: 'For a limited time use the discount coupon',
    client_coupon_and: 'and get',
    client_coupon_amount: '10% off',
    client_coupon_desc:
      'on the first purchase of a service or adding a project.',
    client_coupon_limit_one:
      'Please note that the minimum service price or bid price on the project is',
    client_coupon_limit_two: '7$',
    discount_coupon: 'Discount Coupon',
    discount_desc:
      'If you have a discount coupon, you can add it in the following field to be applied when choosing the right bid for you from the bids offered by freelancers',
    bids_warning:
      'Please note that sharing your personal contact details with clients via your bids or in messages will leads to account suspension and you will lose any balance into your account.',
    adding_warning:
      'Please note that adding any pictures or files that contains your personal contact details will leads to account suspension and you will lose any balance into your account.',
    profile_link: 'Your profile link will be:',
    required: 'required',
    project_received: 'I received the project',
    project_delivery_body:
      'If you are sure about receiving the project below, please click yes. Please note that we will transfer the project budget to the freelancer after clicking yes.',
    service_received: 'I received the service',
    service_delivery_body:
      'If you are sure about receiving the service below, please click yes. Please note that we will transfer the service budget to the freelancer after clicking yes.',
    clients_tab: 'Clients',
    freelancers_tab: 'Freelancers',
    browse_services: 'Browse Services',
    why_lancersin: 'Why Lancersin?',
    general: 'General',
    partners: 'Success Partners',
    faq: 'Frequently Asked Questions (FAQ)',
    client_slide_project_head:
      'Now hire the best available freelancer to complete your project',
    client_slide_project_desc:
      'We connect you with top-notch professional freelancers in various fields, including design, programming, translation, content writing. You can easily and safely accomplish your tasks by hiring a freelancer who fits your requirements and budget. Simply add a new project to get started.',
    client_slide_service_head:
      'Buying a service from our top-notch freelancers is easy',
    client_slide_service_desc:
      'You can buy a wide range of professional microservices from top-notch freelancers in various fields, where you can accomplish your tasks easily and safely at prices starting from 3 USD.',
    freelancer_slide_project_head:
      'Start executing projects and get your profits with the lowest commission',
    freelancer_slide_project_desc:
      'Join Lancersin now, create a freelance acccount on Lancersin, and then add your portfolio. After that, you can start browsing projects. You can add your proposal to projects based on your area of expertise as a freelancer.',
    freelancer_slide_service_head:
      'Start offering your services for sale on Lancersin platform for free',
    freelancer_slide_service_desc:
      'Create a freelance account on Lancersin platform for freelancing. After that, add your services for sale on the platform for free, and earn your profits with the lowest commission.',
    how_client_project_head_one: 'Add your project for free',
    how_client_project_desc_one:
      'Create your project with us and tell us all your requirements. The more information you provide us with, the greater your chances of finding the right freelancer for your project.',
    how_client_project_head_two: 'Compare between the presented proposals',
    how_client_project_desc_two:
      'Compare between the presented proposals before choosing the most suitable freelancer to carry out your project.',
    how_client_project_head_three:
      'Communicate with whoever you see as suitable',
    how_client_project_desc_three:
      'Before choosing the proposal, you can communicate with the freelancer through the platform to make sure of everything before selecting their proposal.',
    how_client_project_head_four: 'Pay safely',
    how_client_project_desc_four:
      'Pay safely through the best and most secure payment gateways in the world, and the balance will remain on the platform until the project is completed with the required quality.',
    how_client_service_desc_one:
      'Browse the services provided by freelancers and choose the most suitable one for you.',
    how_client_service_head_two: 'Get in touch with the seller',
    how_client_service_desc_two:
      'Before purchasing the service, you can communicate with the freelance seller of the service through the platform to make sure that you will get what you want.',
    how_client_service_desc_three:
      'Pay securely through the best and most secure payment gateways in the world, and the balance will remain on the platform until the service is delivered with the required quality.',
    how_freelancer_service_head_one: 'Add your service for sale for free',
    how_freelancer_service_desc_one:
      'Add your service for sale with us for free. The clearer and more concise the service title is, the greater your chances of increasing sales. The service must be limited to one field only to be accepted on the platform, and the service description must be comprehensive of what will be delivered to the customer upon purchasing the service.',
    how_freelancer_service_head_two: 'Diverse job areas',
    how_freelancer_service_desc_two:
      'You can add many services in various fields such as design, programming, translation, content writing, and many others. All you have to do is choose the field in which you have sufficient expertise to provide excellent work, thus enabling you to win an excellent rating from potential clients.',
    how_freelancer_service_head_three: 'Receive your profits wherever you are',
    how_freelancer_service_desc_three:
      'You can receive your profits through various methods, including PayPal, bank transfer, and electronic wallets such as Vodafone Cash, Orange Cash, Etisalat Cash, and many others.',
    how_freelancer_portfolio_head_one:
      'Add your professional portfolio to our website',
    how_freelancer_portfolio_desc_one:
      "Your portfolio helps build customers' trust in you and gives them an idea of your expertise and uniqueness in your field. The better your portfolio is, the more opportunities you have to win projects or sell your services on the platform.",
    how_freelancer_portfolio_desc_two:
      'You can add many of your distinguished works in various fields such as design, programming, motion graphics, and many others, with a suitable description that illustrates your responsibilities and achievements in this work.',
    how_freelancer_project_head_one:
      'Add your proposal to the available projects on our platform',
    how_freelancer_project_desc_one:
      'Add professional and attractive proposals to increase your chances of getting the right project. The more professional and detailed your proposal description is, the higher your chances of winning the project. Choose projects that require the skills and experience you have to execute them accurately and professionally. Your accurate and professional execution of the project helps you receive an excellent rating from customers, which in turn helps you win other projects in the future.',
    how_freelancer_project_head_two: 'Communicating with the client',
    how_freelancer_project_desc_two:
      'If the project owner contacts you to inquire about your offer, make sure to respond in a professional and courteous manner to increase your chances of winning the project. To protect your rights, it is necessary to conduct all communication with clients through the platform.',
    why_client_head_one: 'Secure transactions and payment',
    why_client_desc_one:
      'We use the best and most secure global payment gateways, and the balance is kept within the platform until the work is fully delivered and approved by the clients.',
    why_client_head_two: 'Reducing operating costs',
    why_client_desc_two:
      'We help project owners and entrepreneurs reduce operating costs by collaborating with freelancers to complete tasks with the required quality and within the specified time.',
    why_client_head_three: 'Highly skilled freelancers',
    why_client_desc_three:
      'You can hire an expert freelancer in your field from among thousands of specialized freelancers.',
    why_client_head_four: 'Flexible employment in various fields',
    why_client_desc_four:
      'You can hire an expert freelancer in various fields such as graphic design, marketing, programming, translation, content writing, and many more.',
    why_client_head_five: '24/7 technical support',
    why_client_desc_five:
      'We provide exceptional 24/7 technical support to help you overcome any obstacles you may encounter on the platform.',
    why_freelancer_head_one:
      'Diverse and multiple withdrawal methods for earnings',
    why_freelancer_desc_one:
      'We offer diverse and multiple withdrawal methods for easy access to your earnings, such as PayPal, bank transfer, and e-wallets like Vodafone Cash, Orange Cash, Etisalat Cash, and many more.',
    why_freelancer_head_two: 'Platform commission rate',
    why_freelancer_desc_two:
      'The lowest commission rate in the Arab world, where the commission can reach up to 10%.',
    why_freelancer_head_three: 'Balance retention period',
    why_freelancer_desc_three:
      'We are distinguished by the shortest balance withholding period, which can be as low as only one day.',
    why_freelancer_head_four:
      'We prioritize protecting your rights and providing exceptional technical support',
    why_freelancer_desc_four:
      'We guarantee full protection of your rights when complying with all platform instructions, and provide exceptional 24/7 technical support to serve you.',
    faq_q1: 'What is Lancersin platform?',
    faq_a1:
      'Lancersin is a safe and reliable platform that acts as a mediator between project owners (entrepreneurs) and professional freelancers in various fields. The platform provides a professional working environment that ensures the execution of work according to the agreement between the parties.',
    faq_q2: 'How can I work as a freelancer on Lancersin platform?',
    faq_a2:
      'You can do this by creating a freelancer account on the platform, completing your profile and showcasing your skills and areas of expertise. You can also add some of your work to your portfolio. After that, you can earn by offering some of your services for sale on the platform, or by browsing the projects and submitting your proposal for the projects in which you have sufficient experience to execute.',
    faq_q3: 'How do I choose a professional freelancer to complete my project?',
    faq_a3:
      'You can do this by comparing the proposals submitted for your project, reviewing the profiles of each freelancer and viewing their portfolio and skills, in addition to the ratings they have received from completing previous projects or selling services. You can also contact the freelancer through the platform to inquire about their proposal before accepting to work on your project.',
    faq_q4: 'How does Lancersin ensure my rights as a client?',
    faq_a4:
      'In case the freelancer fails to deliver the work in full as agreed, Lancersin platform guarantees a refund to the buyer according to the terms and conditions of use. The platform can also execute the project for the client with another freelancer if desired.',
    faq_q5: 'How does Lancersin ensure my rights as a freelancer?',
    faq_a5:
      "When a client chooses your proposal for one of the available projects on the platform or purchases a service from your services, the service fee is deducted from the client's balance and remains pending on the platform until the project or service is delivered with the required quality.",
    faq_q6:
      'What are the available methods for withdrawing profits on the platform?',
    faq_a6:
      'We have several withdrawal methods available, including PayPal, bank transfer, and electronic wallets such as Vodafone Cash, Orange Cash, and Etisalat Cash.',
    faq_q7:
      'What are the reasons that can lead to the suspension of a user account?',
    faq_a7:
      'There are several reasons mentioned in the terms of use, including: sending external communication means within any section of the platform for the purpose of conducting transactions outside the platform, creating multiple accounts, any form of ethical violations, and attempting fraud or theft.',
    faq_q8:
      'How can I benefit from the projects section as a project owner or entrepreneur?',
    faq_a8:
      'The projects section on the Lancerzane platform is an effective way to accomplish various tasks remotely, in collaboration with professional freelancers in fields such as design, programming, translation, writing, and more. All you need to do is write a detailed description of the project you want to execute, and the clearer and more accurate the information, the better your chances of finding the perfect freelancer to execute your project. After that, you can set the timeframe and budget available for the project and wait for experienced freelancers who have the required skills to submit their proposals. You can review the different proposals you receive and choose the suitable freelancer to execute the project. It is worth mentioning that the project amount remains in your account on the platform, and it is not transferred to the freelancer until you receive the project and click the "I have received the project" button.',
    faq_q9:
      'Are there any additional fees for adding my project as a project owner or entrepreneur?',
    faq_a9:
      'Adding a project to the platform is free of charge. When you choose the suitable proposal to execute the project, no additional fees are charged except for the fees of the selected proposal. The payment is not transferred to the freelancer until you receive the project and click the "I have received the project" button.',
    faq_q10: 'How can freelancers benefit from the projects section?',
    faq_a10:
      "You can browse the available projects on the platform and submit proposals for projects that match your skills and experience. The more complete your profile and portfolio, the greater your chances of winning the project. It's important to submit proposals for projects that you have the necessary skills and experience to execute with precision and high quality. This will help you get an excellent rating from the client and increase your chances of getting other projects in the future.",
    faq_q11:
      'What percentage does Lancersin take as a platform fee for providing services to freelancers who complete projects on the platform?',
    faq_a11:
      "The basic percentage that Lancersin takes is 20% of the project value completed on the platform, but this percentage can sometimes reach 10% depending on several factors. These factors include your completion rate for projects and excellent ratings, as well as your adherence to the platform's laws and terms of work.",
    faq_q12:
      'How can project owners and entrepreneurs benefit from the services section available on the platform?',
    faq_a12:
      'The services section available on the platform can help you access a variety of ready-made services for sale by freelancers. You can browse the services section and choose the service you wish to purchase, then communicate with the seller to agree on all the details. After that, you can click on the "Buy Now" button and, once the payment is completed, you can continue working with the freelancer, knowing that the service fee remains with the platform and is not transferred to the freelancer until you receive the service and click on the "I have received the service" button.',
    faq_q13:
      'Are there any additional fees to purchase a service on Lancersin platform?',
    faq_a13:
      'There are no additional fees other than the service price, and the amount is not transferred to the freelancer until you receive the service and click on the "I have received the service" button.',
    faq_q14:
      'As a freelancer, how can I benefit from the services section on the platform?',
    faq_a14:
      'You can add many services and offer them for sale on the platform for free. The more consistent and clear the service description is, and the more it explains everything that will be delivered to the customer for the specified amount and time, the more your sales will increase. Focusing on meeting delivery deadlines and delivering high-quality work helps you get excellent ratings from customers, which helps you increase your sales in the future.',
    faq_q15:
      'What percentage does Lancersin platform receive if I sell a service as a freelancer?',
    faq_a15:
      "The basic percentage that Lancersin platform receives is 20%. However, this percentage may sometimes reach 10% depending on several factors, including your completion rate with excellent ratings and your compliance with the platform's rules and terms.",
    faq_q16: 'Is there a minimum and maximum limit for the service price?',
    faq_a16:
      'Yes, definitely. The minimum price for a service is $3 USD, and the maximum price is $1500 USD.',
    lancersin_home_title:
      'Hire the best freelancers and discover job opportunities online',
    optional: 'optional',
  },
  ar: {
    browse_projects: 'تصفح المشاريع',
    contests: 'المسابقات',
    services: 'الخدمات',
    portfolio: 'معرض الأعمال',
    how_it_works: 'كيف يعمل؟',
    find_freelancer: 'ابحث عن مستقل',
    welcome_back: 'مرحبا بكـ',
    post_project: 'أضف مشروع',
    logout: 'تسجيل الخروج',
    dashboard: 'لوحة التحكم',
    signup: 'حساب جديد',
    signin: 'تسجيل الدخول',
    signup_now: 'سجل الآن',
    dont_have_account: 'ليس لديك حساب؟',
    password: 'كلمة المرور',
    repassword: 'تأكيد كلمة المرور',
    email_username: 'البريد الإلكتروني أو اسم المستخدم',
    username: 'إسم المستخدم',
    account_type: 'نوع الحساب',
    add_service: 'أضف خدمة',
    categories: 'الأقسام',
    freelancers: 'المستقلين',
    lancersin_pro: 'العضوية المدفوعة',
    about: 'عن الشركة',
    about_us: 'من نحن',
    sitemap: 'خريطة الموقع',
    articles: 'المقالات',
    referral_program: 'برنامج الإحالة',
    terms: 'الشروط',
    privacy_policy: 'سياسة الإستخدام',
    copyright_policy: 'سياسة وحقوق النشر',
    code_of_conduct: 'قواعد الإستخدام السلوكية',
    fees_charges: 'الرسوم والتكاليف',
    copyright: 'جميع الحقوق محفوظة ©',
    lancersin_tech: 'لانسرزان تكنولوجي',
    favourite: 'المفضلة',
    title: 'العنوان',
    description: 'الوصف',
    requirements_title: 'المتطلبات',
    requirements_description:
      'اطرح على العميل الأسئلة التي تحتاج إلى إجابات لبدء عملك (إذا وجدت) ، يرجى ملاحظة أننا سنعيد توجيه ما ستكتبه مباشرة إلى العميل بعد أن يشتري خدمتك ، لذا اكتب كما لو كنت تتحدث مع العميل نفسه . بقدر ما تكون أسئلتك واضحة ، يمكنك الحصول على إجابات مفيدة لبدء عملك.',
    thumbnail_image: 'صورة العرض',
    service_images: 'صور الخدمة',
    number_to_deliver: 'عدد أيام التسليم',
    number_to_deliver_service: 'عدد أيام تسليم الخدمة',
    number_to_deliver_project: 'عدد الأيام المتوقعة لتسليم المشروع',
    day_s: 'يوم(أيام)',
    price: 'السعر',
    you_will_receive: 'السعر بعد خصم العمولة',
    hide_from_services: 'إيقاف مؤقت (لا اريد استقبال مزيد من الطلبات)',
    add_feature: 'أضف تحسيناً للخدمة',
    add: 'أضف',
    submit: 'إرسال',
    close: 'إغلاق',
    hide: 'إخفاء',
    sort_message:
      'بعد رفع الملفات يمكنك إستخدام القائمة التي ستظر بالأسفل لترتيب أو حذف الصور',
    uploader_txt: 'قم بسحب "وإسقاط" بعض الملفات هنا ، أو انقر لتحديد الملفات',
    search_keywords: 'كلمات البحث',
    add_keywords: 'اختر أو أضف كلمات بحثية...',
    category: 'التصنيف',
    sub_category: 'التصنيف الفرعي',
    video_samples: 'مقاطع فيديو',
    link: 'الرابط',
    video_placeholder: 'أضف رابط Youtube أو Vimeo',
    delete: 'حذف',
    add_portfolio_item: 'أضف عمل جديد',
    add_portfolio_item_title: 'إضافة عمل جديد',
    portfolio_images: 'صور العمل',
    video_link: 'رابط فيديو',
    project_link: 'رابط المشروع',
    skills: 'المهارات',
    add_skills: 'اختر أو أضف مهارات...',
    hide_from_portfolio: 'إخفاء العمل من قائمة الأعمال',
    remove_items_margin: 'حذف المسافات بين صور العمل',
    not_exceed_mb: 'حجم الملف لابد ألا يتجاوز 1 ميجا بايت',
    edit: 'تعديل',
    by: 'بواسطة',
    followers: 'المتابعون',
    hire_me: 'وظفني',
    invite_project: 'دعوة لمشروع',
    invite_contest: 'دعوة لمسابقة',
    follow: 'متابعة',
    un_follow: 'إلغاء المتابعة',
    live_preview: 'مشاهدة العمل',
    published_on: 'تاريخ الإضافة',
    comments: 'التعليقات',
    add_comment: 'أضف تعليق',
    edit_portfolio_title: 'تعديل العمل',
    delete_confirmation: 'تأكيد الحذف',
    delete_confirmation_message: 'هل أنت متأكد من حذف هذا العنصر؟!',
    edit_comment: 'تعديل التعليق',
    save: 'حفظ',
    cancel: 'إلغاء',
    report_content: 'تبليغ عن محتوى',
    join_conversation: 'قم بتسجيل الدخول أو التسجيل للمشاركة في المحادثة',
    add_feedback:
      'اضف رائيك علي أعمال %{fname} %{lname} عن طريق تسجيل دخولك أو التسجيل',
    reason: 'السبب',
    sort_data_message:
      'بعد إضافة مقاطع الفيديو الخاصة بك يمكنك إستخدام القائمة التي ستظهر بالأسفل لترتيب أو حذف الروابط',
    valid_video: 'يرجى إدخال رابط فيديو Youtube أو Vimeo صحيح',
    under_review: 'بإنتظار الموافقة',
    status: 'الحالة',
    approved: 'مصدق عليه',
    features_to_service: 'تحسينات متوفرة لهذه الخدمة',
    buy_service: 'اشتري الخدمة',
    total_price: 'إجمالي السعر',
    buy_now: 'اشتر الآن',
    message_seller: 'راسل البائع',
    sign_to_buy: 'قم بتسجيل الدخول أو التسجيل للشراء',
    sign_to_view: 'قم بتسجيل الدخول أو التسجيل لمشاهدة المحتوى',
    sign_service_buy:
      'اشتري خدمات %{fname} %{lname} عن طريق تسجيل دخولك أو التسجيل.',
    sign_services_preview: 'شاهد خدماتنا عن طريق تسجيل دخولك أو التسجيل.',
    sign_projects_preview:
      'شاهد المشاريع المضافة عن طريق تسجيل دخولك أو التسجيل.',
    sign_dashboard_preview:
      'شاهد لوحة التحكم الخاصة بكـ عن طريق تسجيل دخولك أو التسجيل.',
    sign_freelancers_preview: 'شاهد أبطالنا من خلال تسجيل دخولك أو التسجيل.',
    sign_for_preview: 'شاهد محتوى هذه الصفحة عن طريق تسجيل دخولك أو التسجيل.',
    upload_notification:
      'من فضلك انتظر تحميل الملفات الخاصة بك ومن ثم اضغط ارسال',
    upload_notification_title: 'تحميل الملفات:',
    my_profile: 'ملفي الشخصي',
    edit_profile: 'تعديل الملف الشخصي',
    first_name: 'الإسم الأول',
    last_name: 'إسم العائلة',
    birth_date: 'تاريخ الميلاد',
    position: 'الوظيفة',
    hourly_price: 'سعر الساعة',
    email_address: 'البريد الإلكتروني',
    mobile: 'رقم الجوال',
    profile_image: 'صورة الملف الشخصي',
    country: 'الدولة',
    city: 'المدينة',
    about_user: 'نبذة تعريفية',
    local_time: 'التوقيت المحلي',
    follower: 'متابع',
    email: 'البريد الإلكتروني',
    relatedـservices: 'خدمات ذات صلة',
    service_statistics: 'إحصائيات الخدمة',
    bought_service: 'اشتروا الخدمة',
    inprogress_requests: 'طلبات قيد التنفيذ',
    total_request: 'عدد مرات الطلب',
    home: 'الرئيسية',
    my_services: 'خدماتي',
    closed_services: 'الخدمات المنجزة',
    service_features: 'تحسينات الخدمة',
    required_information: 'المعلومات المطلوبة',
    seller_requirements: 'متطلبات البائع',
    please_clarify: 'يرجى توضيح ما يلي للبائع حتى يتمكن من متابعة طلبك',
    your_clarification: 'التوضيح الخاص بك',
    upload_files: 'رفع ملفات',
    buyer_reply: 'رد المشتري',
    attached_files: 'الملفات المرفقة',
    service_discussion: 'نقاش الخدمة',
    uploading_files: 'رفع الملفات',
    error_uploading_file: 'خطأ في رفع الملف',
    feedback_title: 'التقييم',
    feedback: 'رائيك',
    entries: 'المشاركات',
    designers: 'المصممين',
    contest_brief: 'نبذة عن المسابقة',
    time_left: 'الوقت المتبقي',
    prize: 'الجائزة',
    browse_contests: 'تصفح المسابقات',
    contest_description: 'وصف المسابقة',
    look_n_feel: 'تصميم الشكل والمظهر',
    fav_colors: 'الألوان المفضلة',
    client_likes: 'العميل معجب بـ',
    add_project: 'أضف مشروع',
    budget: 'ميزانية',
    proposalAmountDesc: 'يدفع لك: ٥٠٠ دولار - ٥٠ دولار ضريبه = ٤٥٠ دولار',
    add_contest: 'أضف مسابقة',
    edit_service: 'تعديل الخدمة',
    min_price: 'أقل سعر',
    max_price: 'أعلى سعر',
    min_days: 'أقل عدد أيام تسليم',
    max_days: 'أقصي عدد أيام تسليم',
    search: 'البحث',
    reset: 'إعادة التعيين',
    service_delivered: 'تم استلام الخدمة',
    please_wait: 'برجاء الإنتظار',
    project_budget: 'ميزانية المشروع',
    bids_average: 'متوسط العروض',
    bids_sent: 'عدد العروض',
    created_at: 'أنشئت في',
    updated_at: 'آخر تحديث في',
    no_bids: 'لا توجد عروض',
    dollar: 'دولار',
    receiving_bids: 'يستقبل العروض',
    in_progress: 'قيد التنفيذ',
    project_images: 'صور المشروع',
    my_projects: 'مشاريعي',
    closed_projects: 'المشاريع المنجزة',
    field_required: 'هذا الحقل مطلوب',
    complete_required_info: 'برجاء إكمال البيانات المطلوبة',
    complete_required_info_title: 'حقول مطلوبة:',
    fix_info_title: 'إصلاح الأخطاء:',
    fix_info: 'برجاء تصحيح أخطاء الحقول قبل الإرسال',
    project_description: 'وصف المشروع',
    attachments: 'المرفقات',
    required_skills: 'المهارات المطلوبة',
    submit_proposal: 'تقديم عرض',
    submit_the_proposal: 'تقديم العرض',
    proposals: 'العروض',
    project_discussions: 'مناقشات المشروع',
    real_text: 'برجاء كتابة نص ذو معنى في هذا الحقل',
    file_removed: 'تمت إزالة الملف',
    uploaded_files: 'الملفات المرفقة:',
    upload_canceled: 'تم إلغاء التحميل',
    upload_success: 'تم تحميل الملف بنجاح',
    bid_amount: 'قيمة العرض',
    bid_description: 'وصف العرض',
    pick_proposal: 'اختر العرض',
    projects: 'المشاريع',
    lancersin_projects: 'لانسرزان - المشاريع',
    advanced_search: 'بحث مفصل',
    close_search: 'إغلاق البحث',
    add_to_favourite: 'أضف إلى المفضلة',
    thank_you: 'شكرا لك...',
    comment_success: 'تمت إضافة تعليقك بنجاح',
    hide_proposal: 'إخفاء العرض',
    hide_proposal_desc:
      'يتيح لك تشغيل هذا الخيار إخفاء عرضك من أن يراه المستقلون الآخرون',
    proposal_success: 'تم تقديم عرضك بنجاح',
    join_now: 'انضم الآن',
    freelancer: 'مستقل',
    client: 'عميل',
    by_registering: 'من خلال المتابعة فأنت تؤكد أنك تقبل',
    terms_conditions: 'الشروط والأحكام',
    signup_consent:
      'وأنت توافق على تلقي العروض والفرص من لانسرزان عبر الهاتف والرسائل النصية والبريد الإلكتروني',
    and: 'و',
    privacyـpolicy: 'سياسة الخصوصية',
    have_account: 'هل لديك حساب؟',
    accepted_files: 'الملفات المقبولة',
    rejected_files: 'الملفات المرفوضة',
    max_files: 'عدد الملفات المسموح به هو',
    max_size: 'يجب ألا يتجاوز حجم الملف',
    files_extensions: 'امتدادات الملفات المسموح بها',
    invalid_file_type: 'امتداد الملف غير مسموح به ، الامتدادات المسموح بها هي:',
    invalid_max_files:
      'يوجد عدد كبير جدًا من الملفات ، العدد المسموح به من الملفات هو:',
    end_date_time: 'تاريخ ووقت الإنتهاء',
    choose_style: 'اختر النمط',
    complex: 'مركب',
    minimal: 'بسيط',
    modern: 'حديث',
    classic: 'كلاسيكي',
    playful: 'مرح',
    serious: 'جاد',
    subtle: 'هاديء',
    loud: 'صاخب',
    luxury: 'فاخر',
    inspiration_url: 'روابط للإلهام',
    inspiration_images: 'صور للإلهام',
    sample_url: 'https://www.lancersin.com',
    post_article: 'أضف مقالاً',
    article_image: 'صورة المقال',
    lancersin_articles: 'لانسرزان - المقالات',
    delete_article: 'حذف المقال',
    delete_conf: 'هل أنت متأكد من حذف',
    no: 'لا',
    yes: 'نعم',
    delete_article_success: 'تم حذف المقال بنجاح.',
    description_required: 'حقل الوصف مطلوب',
    requirements_required: 'حقل المتطلبات مطلوب',
    deadline_required: 'حقل عدد أيام التسليم مطلوب',
    image_required: 'حقل الصورة مطلوب',
    subcategory_required: 'حقل التصنيف الفرعي مطلوب',
    category_required: 'حقل التصنيف مطلوب',
    keywords_required: 'حقل كلمات البحث مطلوب',
    title_required: 'حقل العنوان مطلوب',
    select_no_options: 'لاتوجد خيارات متاحة',
    choose_cat_first: 'برجاء اختيار التصنيف أولاً',
    edit_article: 'تعديل المقال',
    edit_article_success: 'تم تعديل المقال بنجاح.',
    contest_prize: 'جائزة المسابقة',
    price_required: 'حقل السعر مطلوب',
    end_date_time_required: 'حقل التاريخ ووقت الإنتهاء مطلوب',
    edit_contest: 'تعديل المسابقة',
    edit_contest_success: 'تم تعديل المسابقة بنجاح',
    d: 'يوم',
    h: 'س',
    m: 'د',
    lancersin_contests: 'لانسرزان - المسابقات',
    insert_valid_link: 'من فضلك ادخل رابط صحيح',
    price_error: 'يجب أن يكون السعر مساويًا أو أكثر من',
    closed_contests: 'المسابقات المنجزة',
    submit_design: 'أضف تصميم',
    submit_design_success: 'تم إرسال التصميم بنجاح.',
    design_image: 'صورة التصميم',
    closed: 'تم الانتهاء',
    rate_bid: 'التقييم',
    rating_bid: 'تقييم المشاركة',
    rating: 'التقييم',
    rating_success: 'تم إضافة التقييم بنجاح.',
    adding_bid_comment_title: 'إضافة تعليق',
    editing_bid_comment_title: 'تعديل التعليق',
    adding_bid_comment_success: 'تم إضافة تعليقك بنجاح.',
    editing_bid_comment_success: 'تم تعديل تعليقك بنجاح.',
    comment_required: 'حقل التعليق مطلوب',
    in_active: 'قيد المراجعة',
    active: 'تم النشر',
    delete_comment: 'حذف التعليق',
    delete_comment_success: 'تم حذف التعليق بنجاح.',
    like_bid: 'تسجيل الإعجاب بالعرض',
    like_bid_success: 'تم تسجيل إعجابك بالعرض بنجاح.',
    back_to_home: 'العودة للصفحة الرئيسية',
    no_permissions_message:
      'نعتذر منك! ليست لديك صلاحية لمشاهدة هذه الصفحة في هذا الوقت. برجاء إعادة المحاولة لاحقاً',
    report_title: 'عنوان البلاغ',
    report_description: 'وصف البلاغ',
    reason_required: 'حقل السبب مطلوب',
    report_content_success: 'شكرا لك، تم إرسال بلاغك بنجاح',
    report_content_title: 'تبليغ عن محتوى:',
    service_delivered_title: 'تم تسليم الخدمة:',
    service_delivered_message: 'تم تسليم الخدمة بنجاح. شكرا لك.',
    something_went_wrong: 'نعتذر منك! هناك خطأ ما. برجاء المحاولة في وقت لاحق.',
    clarification_required: 'حقل المتطلبات مطلوب',
    following: 'متابعة',
    following_success: 'تمت متابعة',
    successfull: 'بنجاح',
    unfollowing: 'إلغاء المتابعة لـ',
    unfollowing_success: 'قد تم إلغاء متابعتك لـ',
    rate_service: 'برجاء تقييم الخدمة',
    feedback_required: 'حقل الرأي مطلوب',
    service_feedback: 'تقييم الخدمة',
    rate_customer: 'تقييم العميل',
    valid_url: 'يرجى إدخال رابط موقع إلكتروني صحيح',
    lancersin_services: 'لانسرزان - الخدمات',
    lancersin_portfolio: 'لانسرزان - معرض الأعمال',
    delete_portfolio: 'حذف عمل من معرض الأعمال',
    delete_portfolio_success: 'تم حذف العمل من معرض أعمالك بنجاح.',
    like_portfolio: 'تسجيل الإعجاب بالعمل',
    like_portfolio_success: 'تم تسجيل إعجابك بنجاح.',
    add_portfolio_success: 'تم إضافة العمل الخاص بك بنجاح.',
    service_price_limit: 'يجب أن يكون السعر أقل من أو مساوياً لـ 5 دولار',
    video_add_message:
      'بعد لصق رابط الفيديو يجب عليك الضغط على زر أضف أسفل حقل ادخال الرابط لإضافة هذا الفيديو لخدمتك.',
    feature_add_message:
      'بعد ملء جميع البيانات المطلوبة يجب عليك الضغط علي زر أضف بالأسفل لإضافة هذا الملحق لخدمتك.',
    any_one_service: 'يمكن لأي شخص مشاهدة وشراء هذه الخدمة',
    no_one_service: 'لا أحد يستطيع رؤية هذه الخدمة سواك',
    any_one_portfolio: 'يمكن لأي شخص مشاهدة هذا العمل',
    no_one_portfolio: 'لا أحد يستطيع مشاهدة هذا العمل سواك',
    login_required_info: 'برجاء تسجيل الدخول لمتابعة طلبك',
    login_required_title: 'تسجيل الدخول:',
    overview: 'نظرة عامة',
    profile_feedback: 'رأي العملاء',
    last_seen: 'آخر تواجد',
    fname_required: 'حقل الإسم الأول مطلوب',
    lname_required: 'حقل إسم العائلة مطلوب',
    position_required: 'حقل الوظيفة مطلوب',
    birthdate_required: 'حقل تاريخ الميلاد مطلوب',
    hourly_price_required: 'حقل سعر الساعة مطلوب',
    email_required: 'حقل البريد الإلكتروني مطلوب',
    mobile_required: 'حقل الجوال مطلوب',
    country_required: 'حقل الدولة مطلوب',
    city_required: 'حقل المدينة مطلوب',
    profile_image_required: 'حقل صورة الملف الشخصي مطلوب',
    about_required: 'حقل نبذة تعريفية مطلوب',
    skills_required: 'حقل المهارات مطلوب',
    username_required: 'حقل إسم المستخدم مطلوب',
    password_required: 'حقل كلمة المرور مطلوب',
    repassword_required: 'حقل تأكيد كلمة المرور مطلوب',
    repassword_password_required:
      'يجب أن تتطابق كلمة المرور وتأكيد كلمة المرور',
    account_type_required: 'حقل نوع الحساب مطلوب',
    network_error_title: 'خطأ في الإتصال',
    network_error_message:
      'يبدو أن اتصالك بالإنترنت به مشكلة. من فضلك ، تحقق من ذلك وحاول مرة أخرى في وقت لاحق.',
    something_error_title: 'حدث خطأ ما',
    something_error_message:
      'نحن آسفون جدا. حدث خطأ ما من فضلك ، أعد تحميل الصفحة وحاول مرة أخرى.',
    noitems_title: 'لاتوجد نتائج',
    noitems_message: 'لم يتم العثور علي نتائج، برجاء المحاولة مرة أخرى لاحقا.',
    dollar_per_hour: 'دولار/س',
    view_profile: 'الملف الشخصي',
    verfication_code: 'كود التفعيل',
    verification_required: 'حقل كود التفعيل مطلوب',
    verify: 'تفعيل',
    verify_email_address: 'تفعيل البريد الإلكتروني',
    complete_profile_info:
      'يرجى إكمال ملف التعريف الخاص بك من خلال النقر على تعديل الملف الشخصي أدناه.',
    account_verification_title: 'التحقق من الحساب',
    account_verification_success: 'تم التحقق من حسابك بنجاح.',
    profile_update_title: 'تحديث الملف الشخصي',
    profile_update_success: 'تم تحديث ملفك الشخصي بنجاح.',
    check_email_inbox: 'يرجى التحقق من البريد الإلكتروني الخاص بك',
    verification_sent:
      'لقد أرسلنا إليك بريد إلكتروني يحتوي على رمز التحقق ، لذا يرجى نسخه ولصقه أدناه',
    reset_password: 'إعادة تعيين كلمة المرور',
    forgot_password: 'نسيت كلمة المرور؟ اضغط هنا',
    send_code: 'ارسل كود التحقق',
    reset_password_success: 'تم إعادة تعيين كلمة المرور بنجاح.',
    change_password: 'تغيير كلمة المرور',
    delete_file: 'حذف الملف',
    delete_file_error: 'فشل في حذف الملف، برجاء إرسال النموذج مرة أخرى',
    browse_most: 'تصفح أكثر',
    popular_categories: 'التصنيفات انتشاراً',
    categories_description:
      'هل أنت واحد من ملايين المستقلين أو أصحاب الوظائف الذين يحتاجون للعمل في جميع أنحاء العالم؟ تصفح تصنيفاتنا الآن ...',
    get_started: 'ابدء الآن',
    browse_top: 'تصفح أعلى',
    ranked_freelancers: 'المستقلين تقييماً',
    reach_summit: 'لا سبيل للوصول الى القمة من دون العمل الجاد.',
    incredible_showcase: 'معرض أعمال المستقلين',
    total_earnings: 'إجمالي الأرباح',
    withdrawn_request: 'الرصيد القابل للسحب',
    pending_amount: 'الرصيد المعلق',
    total_projects: 'إجمالي المشاريع',
    projects_done: 'المشاريع المنجزة',
    running_projects: 'المشاريع الجارية',
    total_contests: 'إجمالي المسابقات',
    contests_done: 'المسابقات المنجزة',
    running_contests: 'المسابقات الجارية',
    total_services: 'إجمالي الخدمات',
    services_done: 'الخدمات المنجزة',
    running_services: 'الخدمات الجارية',
    free_membership: 'العضوية المجانية',
    proposals_sent: 'العروض المقدمة',
    proposals_limit: 'إجمالي العروض',
    proposals_renewal: 'تجديد العروض',
    monthly: 'عروض شهريا',
    uploaded_successfully: 'تم التحميل بنجاح',
    image_max_width: 'أقصى عرض للصورة',
    image_max_height: 'أقصى ارتفاع للصورة',
    pixel: 'بيكسل',
    no_articles_found: 'لم يتم العثور على مقالات',
    no_articles_message_with_action:
      'عذرا، لاتوجد أي مقالات مضافة ولكن يمكنك إضافة مقال بإستخدام زر الإضافة الموجود بالأسفل',
    no_articles_message:
      'عذرا، لاتوجد مقالات مضافة، برجاء إعادة المحاولة مرة أخرى لاحقا',
    no_portfolio_found: 'لم يتم العثور على معرض أعمال',
    no_portfolio_message_with_action:
      'عذرا، لايوجد أي مشاركات بمعرض الأعمال ولكن يمكنك إضافة عمل جديد بإستخدام زر الإضافة الموجود بالأسفل',
    no_portfolio_message:
      'عذرا، لاتوجد مشاركات بمعرض الأعمال، برجاء إعادة المحاولة مرة أخرى لاحقا',
    no_services_found: 'لم يتم العثور علي خدمات',
    no_services_message_with_action:
      'عذرا، لايوجد أي خدمات مضافة ولكن يمكنك إضافة خدمة بإستخدام زر الإضافة الموجود بالأسفل',
    site_description:
      'لانسرزان يجمع بين المستقلين و أصحاب الأعمال. أفضل المستقلين و المطورين والمصممين للتعاون معهم بأقل سعر. إنه سوق عالمي ، حيث يمكنك إنجاز عملك. انشر مشروعك مجانا',
    site_keywords:
      'لانسرزان، مستقل ، عمل ، عن بعد ، تصميم ، تصميم شعارات ، تصميم موقع ويب ، تصميم شعار ، تطوير تطبيقات ، تطبيق ، تطوير موقع ويب ، ووردبريس ، تطوير ووردبريس ، مطور ، مصمم ، مسابقة ، خدمات ، مشاريع ، خدمة ، مشروع',
    estimate_required: 'حقل عدد أيام التسليم مطلوب',
    bid_amount_required: 'حقل قيمة العرض مطلوب',
    amount_less: 'قيمة العرض يجب أن تكون أقل من أو مساوية لـ',
    amount_greater: 'قيمة العرض يجب أن تكون أكبر من أو مساوية لـ',
    no_proposals_title: 'لم يتم إضافة أي عروض حتى الآن',
    no_proposals_message: 'أضف العرض الأول لهذا المشروع.',
    no_proposals_owner_message:
      'سنقوم بإخطار المستقلين بمشروعك وسيضيفون عروضهم قريبًا.',
    no_comments_title: 'لم يتم إضافة أي تعليقات حتى الآن',
    no_comments_message: 'أضف التعليق الأول لهذا المشروع',
    project_delivered: 'تم إستلام المشروع',
    total_spent: 'إجمالي الإنفاق علي المنصة',
    edit_project: 'تعديل المشروع',
    delete_project: 'حذف المشروع',
    delete_project_success: 'تم حذف المشروع بنجاح.',
    project_budget_required: 'حقل ميزانية المشروع مطلوب',
    adding_project: 'إضافة مشروع',
    add_project_success: 'تم إضافة مشروعك بنجاح.',
    edit_project_success: 'تم تعديل مشروعك بنجاح.',
    no_bids_sent: 'لم ترسل عروض حتى الان.',
    adding_project_to_fav: 'إضافة المشروع للمفضلة',
    adding_project_to_fav_success: 'تم إضافة المشروع للمفضلة بنجاح.',
    adding_service_to_fav: 'إضافة الخدمة للمفضلة',
    adding_service_to_fav_success: 'تم إضافة الخدمة للمفضلة بنجاح.',
    bids: 'العروض',
    bid_added: 'تمت إضافة العرض',
    bid_added_message: 'لقد أضفت بالفعل عرض لهذا المشروع.',
    project_already_favourite: 'هذا المشروع موجود بالفعل في قائمتك المفضلة.',
    select_bid: 'اختيار عرض',
    select_bid_conf: 'هل أنت متأكد من اختيارك للعرض أدناه:',
    select_project_bid_success: 'تم اختيار العرض بنجاح. شكرا لك',
    authentication_error:
      'نعتذر منك! هناك خطأ ما. يرجى تحديث الصفحة والمحاولة مرة أخرى.',
    rate_freelancer: 'برجاء تقييم المستقل',
    rate_project_owner: 'برجاء تقييم صاحب المشروع',
    project_feedback_freelancer: 'تقييم المستقل',
    project_feedback_owner: 'تقييم صاحب المشروع',
    close_project: 'إنهاء المشروع',
    close_project_success: 'تم تسليم مشروعك بنجاح.',
    request_project_closure: 'طلب إنهاء المشروع',
    request_project_closure_success: 'تم إرسال طلبك لإنهاء المشروع بنجاح.',
    request_project_closure_confirm: 'هل أنت متأكد من إرسال طلب إنهاء المشروع؟',
    project_feedback_owner_success: 'تم إضافة تقييمك لصاحب المشروع بنجاح.',
    project_close_request: 'طلب إنهاء المشروع:',
    project_close_request_message:
      'تم طلب إغلاق المشروع من جانب المستقل. لذا ، يرجى مراجعة جميع الملفات بعناية وإذا كان كل شيء على ما يرام ، فالرجاء النقر فوق الزر أدناه لإغلاق المشروع.',
    delete_from_fav: 'حذف من المفضلة',
    deleting_project_from_fav: 'حذف المشروع من المفضلة',
    deleting_project_from_fav_success: 'تم حذف المشروع من المفضلة بنجاح.',
    deleting_service_from_fav: 'حذف الخدمة من المفضلة',
    deleting_service_from_fav_success: 'تم حذف الخدمة من المفضلة بنجاح.',
    no_projects_found: 'لم يتم العثور علي مشاريع',
    no_projects_message_with_action:
      'عذرا، لايوجد أي مشاريع مضافة ولكن يمكنك إضافة مشروع بإستخدام زر الإضافة الموجود بالأسفل',
    no_projects_message_with_no_action: 'عذرا، لايوجد أي مشاريع مضافة حالياً.',
    add_service_success: 'لقد تم إضافة خدمتك بنجاح ، وهي قيد المراجعة حاليًا',
    edit_service_success: 'تم تعديل خدمتك بنجاح.',
    edit_service_review_success:
      'لقد تم تعديل خدمتك بنجاح، وهي قيد المراجعة حاليًا',
    delete_service: 'حذف الخدمة',
    delete_service_success: 'تم حذف خدمتك بنجاح',
    service_purchase: 'شراء خدمة',
    service_purchase_success: 'تم شراء الخدمة بنجاح.',
    reply_to_seller: 'الرد على متطلبات البائع',
    reply_to_seller_success:
      'تم إرسال ردك بنجاح وسيعمل البائع على طلبك قريبًا.',
    price_start: 'السعر يبدأ من',
    buyers_feedback: 'آراء المشترين',
    project_files_hint:
      'يرجى ملاحظة أنه سيتم حذف جميع الملفات المرفقة بهذا المشروع وتعليقاته بعد 30 يومًا من تسليم المشروع. لذا من فضلك ، قم بتنزيل جميع ملفاتك قبل نهاية هذه الفترة لتجنب فقدان الملفات.',
    service_files_hint:
      'يرجى ملاحظة أنه سيتم حذف جميع الملفات المرفقة بهذه الخدمة وتعليقاتها بعد 30 يومًا من تسليم الخدمة. لذا من فضلك ، قم بتنزيل جميع ملفاتك قبل نهاية هذه الفترة لتجنب فقدان الملفات.',
    fav_projects: 'المشاريع',
    fav_services: 'الخدمات',
    fav_contests: 'المسابقات',
    lancersin_fav_projects: 'لانسرزان - المشاريع المفضلة',
    lancersin_fav_services: 'لانسرزان - الخدمات المفضلة',
    lancersin_fav_contests: 'لانسرزان - المسابقات المفضلة',
    lancersin_followers: 'لانسرزان - المتابعون',
    lancersin_followings: 'لانسرزان - المتابعات',
    added_a_comment: 'أضاف تعليقا على',
    added_a_bid: 'أضاف عرضا على',
    select_a_bid: 'اختار عرضك على',
    project_of: 'تم تسليم مشروع',
    service_of: 'تم تسليم خدمة',
    delivered_success: 'بنجاح',
    all_notifications: 'جميع الإشعارات',
    purchased_service: 'اشترى خدمتك',
    notifications: 'الإشعارات',
    messages: 'الرسائل',
    delivered_service: 'تم تسليم الخدمة',
    message: 'الرسالة',
    inquiry_about: 'إستفسار حول',
    all_messages: 'جميع الرسائل',
    no_fav_projects_message: 'نأسف، لم يتم العثور على مشاريع مضافة إلى مفضلتك',
    no_fav_services_message: 'نأسف، لم يتم العثور على خدمات مضافة إلى مفضلتك',
    awaiting_payment: 'بإنتظار الدفع',
    awaiting_payment_message:
      'يرجى العلم أنك بحاجة إلى دفع الفواتير أدناه لإخطار المستقل للعمل عليها.',
    pay_invoice: 'دفع الفاتورة',
    your_proposal: 'عرضك على',
    send_inquiry: 'إرسال استفسار',
    send: 'أرسل',
    message_required: 'حقل الرسالة مطلوب',
    message_sent__success: 'لقد تم ارسال رسالتك بنجاح',
    sending_message: 'إرسال رسالة',
    project: 'المشروع',
    service: 'الخدمة',
    proposal: 'العرض',
    in: 'خلال',
    contact: 'اتصل بنا',
    message_title: 'عنوان الرسالة',
    full_name: 'الإسم بالكامل',
    fullname_required: 'حقل الإسم بالكامل مطلوب',
    message_type: 'نوع الرسالة',
    type_required: 'حقل نوع الرسالة مطلوب',
    subject_required: 'حقل عنوان الرسالة مطلوب',
    message_type_issue: 'مشكلة',
    message_type_request: 'طلب',
    message_type_suggestion: 'اقتراح',
    message_type_inquiry: 'استفسار',
    message_type_other: 'آخرى',
    enter_valid_email: 'يرجى إدخال عنوان بريد إلكتروني صحيح',
    close_a_project: 'اغلق مشروع',
    no_messages_found: 'لم يتم العثور على رسائل!',
    no_messages_body: 'ليس لديك أي رسائل في صندوق الوارد الخاص بك.',
    no_notifications_found: 'لم يتم العثور على إشعارات!',
    no_notifications_body: 'ليس لديك أي إشعارات حتى الآن.',
    mark_all_messages_read: 'تحويل كافة الرسائل مقروءة',
    mark_all_messages_read_body: 'هل أنت متأكد من تحويل جميع الرسائل كمقروءة؟',
    mark_all_messages_read_success: 'تم تحويل كافة الرسائل كمقروءة بنجاح.',
    skills_desc:
      'يمكنك اختيار مهاراتك من القائمة المنسدلة أدناه وإذا لم تجد مهارتك ، فقط اكتبها وانقر فوق زر "Enter" الموجود على لوحة المفاتيح',
    no_entries: 'لاتوجد مشاركات',
    service_title: 'عنوان الخدمة',
    service_description: 'وصف الخدمة',
    buyer_can_cancel_service:
      'يحق للعميل إلغاء الخدمة مباشرة بحال التأخر عن الموعد المحدد.',
    service_desc_placeholder:
      'يرجى إضافة المواصفات الكاملة لما سيحصل عليه العميل بناءً على السعر والوقت المحددين للخدمة. يرجى الانتباه لأن هذا سيكون بمثابة عقد بينك وبين العميل.',
    feature_title: 'عنوان الملحق',
    feature_description: 'وصف الملحق',
    feature_desc_placeholder:
      'يرجى إضافة المواصفات الكاملة لما سيحصل عليه العميل بناءً على السعر والوقت المحددين للملحق. يرجى الانتباه لأن هذا سيكون بمثابة عقد بينك وبين العميل.',
    service_title_placeholder: 'أدرج عنوانا موجزا يصف خدمتك بشكل دقيق.',
    service_images_desc:
      'سيتم استخدام هذه الصور كمرجع لأعمالك السابقة داخل صفحة تفاصيل الخدمة ، بحيث يكون لدى العميل فكرة عن خبراتك.',
    feature_title_placeholder:
      'يجب أن يكون الوصف بسيطًا وأن يعطي تلميحًا حول ما سيحصل عليه العميل بناءً على السعر المحدد.',
    verification_desc:
      "إذا لم تعثر على البريد الإلكتروني في صندوق الوارد الخاص بك ، فقد تجده في علامة التبويب 'العروض الترويجية' إذا كنت تستخدم تخطيط Gmail الافتراضي وفي حالة حدوث ذلك ، فأنت بحاجة إلى سحب بريدنا الإلكتروني وإفلاته في علامة التبويب 'الأساسي' حتى تتمكن من العثور على أي تحديثات مرسلة منا بسهولة. في حالة استمرار عدم العثور عليه ، يرجى التحقق من مجلد البريد العشوائي. وأيضًا ، إذا وجدت ذلك ، فستحتاج أيضًا إلى نقل هذه الرسالة إلى صندوق الوارد الخاص بك. يحدث هذا دائمًا لأول بريد إلكتروني تتلقاه فقط حتى تقوم بنقله إلى صندوق الوارد الخاص بك.",
    watch_register_video: 'يرجى مشاهدة الفيديو أدناه لعملية التسجيل قبل البدء',
    watch_video: 'شاهد الفيديو',
    still_have_problem:
      'إذا كنت لا تزال غير قادر على التحقق من حسابك ، فيرجى التأكد أولاً من أن عنوان بريدك الإلكتروني يعمل بشكل جيد ، ويمكنك تلقي رسائل بريد إلكتروني من الآخرين و',
    watch_register_video_issue:
      'يرجى مشاهدة الفيديو أدناه لعملية التسجيل إذا واجهت أي مشاكل في التحقق',
    watch_profile_video: 'لاستكمال ملفك الشخصي، يرجي مشاهدة هذا الفيديو',
    service_price: 'سعر الخدمة',
    increase_by: 'سيزيد بمقدار',
    please_write_requiremens: 'برجاء كتابة متطلباتك ليتمكن البائع من بدء عمله:',
    please_write_clarification:
      'يرجى الرد على متطلبات البائع ، وكتابة متطلباتك أيضًا ، حتى يتمكن البائع من بدء عمله:',
    business_requirements: 'متطلبات العمل',
    order_details: 'تفاصيل الطلب',
    waiting_requirement: 'غير مكتمل',
    canceled: 'تم الإلغاء',
    order_from: 'طلبت من',
    buyer: 'المشتري',
    order_number: 'رقم الطلب',
    submit_requirements: 'إرسال المتطلبات لبدء طلبك',
    write_requirements: 'برجاء كتابة المتطلبات الخاصة بك',
    reminder: 'تذكير:',
    please_provide_instruction: 'يرجى تقديم التعليمات حتى يتمكن',
    can_start_order: 'من بدء طلبك',
    cancel_order: 'إلغاء الطلب',
    report_problem: 'الإبلاغ عن مشكلة',
    explain_cancel_order: 'يرجى توضيح أسباب إلغاء الطلب',
    cancel_order_succes: 'تم إرسال طلب إلغاء الخدمة بنجاح.',
    explanation_field_required: 'حقل أسباب إلغاء الطلب مطلوب.',
    explain_the_problem: 'يرجى كتابة وصف المشكلة حتى نتمكن من مساعدتك في حلها:',
    problem_description_required: 'حقل وصف المشكلة مطلوب.',
    report_problem_succes: 'تم إرسال تقرير المشكلة بنجاح.',
    service_cancel_request_declined:
      'نحن آسفون جدا ، تم رفض طلب إلغاء الخدمة. إذا كان لديك أي استفسارات ، يرجى الاتصال بنا.',
    service_cancel_request_sent: 'تم إرسال طلب إلغاء الخدمة.',
    service_handover: 'تسليم الخدمة',
    service_handover_success:
      'تم إرسال طلب تسليم الخدمة بنجاح وسيتخذ المشتري الإجراء قريبًا.',
    delivered_order: 'سلم/ت طلبك.',
    note: 'ملحوظة',
    service_delivery_note:
      'قم بالرد خلال الأيام الثلاثة القادمة وإلا فسيتم اعتماد الطلب وإكماله تلقائيًا.',
    service_delivery_label: 'لقد تلقيت التسليم الخاص بك من',
    service_delivery_message: 'هل أنت سعيد بالتسليم ومستعد للموافقة عليه؟',
    service_delivery_approve: 'نعم ، أوافق على التسليم',
    service_delivery_decline: 'طلب تعديلات',
    client_declined_delivery:
      'العميل لديه بعض التعديلات ، قم بذلك وانقر على زر تسليم الخدمة مرة أخرى.',
    request_to_cancel_invoice: 'طلب/ت إلغاء الخدمة',
    client_request_cancel_invoice:
      'إذا كان هناك طلب إلغاء خدمة ، فاتخذ إجراءً بشأنه أولاً.',
    service_cancellation_message:
      'العميل يطلب إلغاء الخدمة، وسبب الإلغاء أدناه، هل أنت موافق، ومستعد للموافقة عليه؟',
    service_cancellation_label: 'لقد تلقيت طلب إلغاء من',
    service_cancellation_approve: 'نعم ، أوافق على الإلغاء',
    service_cancellation_decline: 'لا ، أنا أرفض الإلغاء',
    cancellation_request_to_seller:
      'سيتم إرسال طلب الإلغاء إلى البائع للموافقة عليه ، ثم إرساله إلى إدارة المنصة للاعتماد.',
    cancellation_request_to_admin:
      'سيتم إرسال طلب الإلغاء لإدارة المنصة للمراجعة والاعتماد.',
    delivery_success_title: 'الموافقة على التسليم',
    delivery_approval_success: 'لقد وافقت على التسليم بنجاح',
    delivered: 'تم التسليم',
    client_declined_delivery_notification:
      'لديه بعض التعديلات ، قم بذلك وانقر على زر تسليم الخدمة مرة أخرى.',
    hello: 'مرحباً',
    please_dont_start_service:
      'من فضلك لا تبدأ عملك قبل استلام متطلبات العميل. لأن العميل يمكنه إلغاء هذا الطلب في أي وقت في هذه الخطوة.',
    order_actions: 'إجراءات الطلب',
    added_requirments: 'أضاف المتطلبات لخدمتك',
    service_cancel_request_approved: 'تمت الموافقة على طلب إلغاء الخدمة.',
    client_request_cancel_invoice_admin:
      'يوجد طلب إلغاء خدمة بانتظار موافقة إدارة المنصة.',
    admin_approve_service: 'تهانينا ، وافقت إدارة المنصة على خدمتك.',
    please_wait_till_we_review: 'يرجى الانتظار حتى نراجع طلب الإلغاء الخاص بك',
    watch_service_video: 'لبيع خدمة جديدة ، يرجى مشاهدة هذا الفيديو',
    followings: 'المتابعات',
    service_features_hint:
      'تعد تحسينات الخدمة أدناه اختيارية ، لذلك لن يضطر العميل إلى الحصول عليها.',
    learn_how_use: 'تعلم كيفية استخدامها بالشكل الصحيح',
    no_followers_message: 'ليس لديك أي متابعين حتى الآن.',
    no_followings_message: 'أنت لا تتابع أي شخص حتى الآن.',
    view_as_visitor: 'مشاهدة ملفي الشخصي كزائر',
    service_rate_present_customer:
      'يرجى ملاحظة أنه سيتم عرض هذا التقييم في صفحة تفاصيل الخدمة كمرجع للعملاء الآخرين.',
    service_rate_present_freelancer:
      'يرجى ملاحظة أنه سيتم عرض هذا التقييم في صفحة تفاصيل الخدمة كرد على تقييم العميل بالأسفل:',
    service_rating_title: 'تقييم الخدمة:',
    service_rating_message: 'تم نشر تقييم الخدمة بنجاح.',
    service_rating_customer_title: 'تقييم العميل:',
    service_rating_customer_message: 'تم نشر تقييم العميل بنجاح.',
    withdrawal_accounts: 'حسابات سحب الأرباح',
    paypal: 'PayPal',
    bank_account: 'حساب مصرفي',
    no_paypal_account: 'لايوجد لديك حساب PayPal مضاف.',
    paypal_accounnt: 'حساب PayPal',
    add_accounnt: 'أضف حساب',
    edit_accounnt: 'تعديل الحساب',
    paypal_account_success: 'تم إضافة حساب PayPal بنجاح.',
    paypal_account_edit_success: 'تم تعديل حساب PayPal بنجاح.',
    email_placeholder: 'email@domain.com',
    paypal_note:
      'الحد الأدنى لمبلغ السحب عند استخدام PayPal هو 25 دولارًا لكل معاملة',
    lancersin_withdrawal_accounts: 'لانسرزان - حسابات سحب الأرباح',
    no_bank_account: 'لايوجد لديك حساب مصرفي مضاف.',
    bank_account_note:
      'الحد الأدنى لمبلغ السحب عند استخدام حساب مصرفي هو 100 دولار لكل معاملة',
    bank_account_success: 'تم إضافة الحساب المصرفي بنجاح.',
    bank_account_edit_success: 'تم تعديل الحساب المصرفي بنجاح.',
    account_number: 'رقم الحساب',
    iban: 'رقم الحساب المصرفي الدولي (IBAN)',
    swift_code: 'كود التحويل الإلكتروني (Swift Code)',
    routing_number: 'رقم التحويل المصرفي (ABA Code)',
    bankName_required: 'حقل اسم البنك مطلوب',
    bank_name: 'اسم البنك',
    account_currency: 'عملة الحساب',
    currency_required: 'حقل عملة الحساب مطلوب',
    address: 'العنوان',
    address_required: 'حقل العنوان مطلوب',
    postcode: 'الرمز البريدي',
    state_province: 'الولاية أو المقاطعة',
    bank_data_note:
      'يرجى ملء جميع البيانات الموجودة لديك لحسابك المصرفي باللغة الإنكليزية. إذا وجدت بعض البيانات التي لم يقدمها البنك الذي تتعامل معه ، فلا مشكلة لأن بعض البيانات أدناه مرتبطة بحسابات بنكية أمريكية.',
    make_default_payment_method: 'طريقة افتراضية لسحب الارباح.',
    admin_approve_project: 'تهانينا ، وافقت إدارة المنصة على مشروعك.',
    withdrawal_requests: 'طلبات سحب الأرباح',
    add_request: 'أضف طلب',
    no_withdrawal_requests: 'لم تتم إضافة طلبات سحب أرباح حتى الآن.',
    amount_required: 'حقل المبلغ مطلوب',
    amount: 'المبلغ',
    withdrawal_request: 'طلب سحب أرباح',
    add_withdrawal_account:
      'أولاً ، يجب عليك إضافة حساب PayPal أو حساب مصرفي حتى تتمكن من سحب أموالك.',
    withdrawal_bank_default_note:
      'يرجى ملاحظة أنك اخترت الحساب المصرفي كطريقة افتراضية لسحب الأرباح إذا لم يعجبك ذلك ، يرجى الانتقال إلى علامة التبويب PayPal والنقر فوق تعديل أو إضافة لاختيار حساب PayPal كطريقة افتراضية بدلاً من ذلك.',
    withdrawal_paypal_default_note:
      'يرجى ملاحظة أنك اخترت حساب PayPal كطريقة افتراضية لسحب الأرباح إذا لم يعجبك ذلك ، يرجى الانتقال إلى علامة تبويب الحساب المصرفي والنقر فوق تعديل أو إضافة لاختيار الحساب المصرفي كطريقة افتراضية بدلاً من ذلك.',
    no_withdrawal_default_note:
      'تحتاج إلى اختيار الطريقة الافتراضية لسحب الأرباح ، ويمكنك القيام بذلك عن طريق النقر فوق علامة التبويب PayPal أو علامة التبويب الحساب المصرفي والنقر فوق تعديل أو إضافة وملء جميع المعلومات المطلوبة ثم تحديد طريقة افتراضية لسحب الأرباح.',
    withdrawal_bank_default_balance_note:
      'يرجى ملاحظة أنك اخترت الحساب المصرفي كطريقة افتراضية لسحب الأرباح وأن الحد الأدنى لمبلغ هذه الطريقة هو 100 دولار ، وليس لديك هذا المبلغ حتى الآن ، لذا يرجى الانتقال إلى علامة التبويب PayPal والنقر فوق تعديل أو إضافة لاختيار حساب PayPal كإعداد افتراضي بدلاً من ذلك.',
    withdrawal_balance_note: 'نأسف لأن رصيدك لا يكفي لطلب السحب.',
    withdrawal_request_success: 'تم إرسال طلب سحب الأرباح بنجاح.',
    request_number: 'رقم الطلب',
    request_amount: 'مبلغ الطلب',
    withdrawal_method: 'طريقة السحب',
    withdrawal_status: 'حالة طلب السحب',
    withdrawal_pending: 'قيد الانتظار',
    withdrawal_sent: 'تم التحويل',
    Transfer_date: 'تاريخ التحويل',
    project_handover: 'تسليم المشروع',
    project_handover_success:
      'تم إرسال طلب تسليم المشروع بنجاح وسيتخذ صاحب المشروع الإجراء قريبًا.',
    client_request_cancel_project:
      'إذا كان هناك طلب إلغاء المشروع ، فاتخذ إجراءً بشأنه أولاً.',
    client_request_cancel_project_admin:
      'يوجد طلب إلغاء المشروع بانتظار موافقة إدارة المنصة.',
    project_delivery_note:
      'قم بالرد خلال الأيام الثلاثة القادمة وإلا فسيتم الموافقة على طلب تسليم المشروع وإكماله تلقائيًا.',
    client_declined_project_delivery:
      'العميل لديه بعض التعديلات ، قم بذلك وانقر على زر تسليم المشروع مرة أخرى.',
    project_delivered_message: 'تم تسليم المشروع بنجاح. شكرا لك.',
    project_feedback_freelancer_success: 'تم إضافة تقييمك للمستقل بنجاح.',
    project_cancel_request_sent: 'تم إرسال طلب إلغاء المشروع.',
    project_cancel_order: 'إلغاء المشروع',
    project_cancel_order_succes: 'تم إرسال طلب إلغاء المشروع بنجاح.',
    cancellation_request_to_freelancer:
      'سيتم إرسال طلب الإلغاء إلى المستقل للموافقة عليه ، ثم إرساله إلى إدارة المنصة للاعتماد.',
    project_title: 'عنوان المشروع',
    project_title_placeholder: 'أدرج عنوانا موجزا يصف مشروعك بشكل دقيق.',
    project_desc_placeholder:
      'أدخل وصفاً مفصلاً لمشروعك وأرفق أمثلة لما تريد ان أمكن.',
    portfolio_title: 'عنوان العمل',
    portfolio_title_placeholder: 'أدرج عنوانا موجزا يصف عملك بشكل دقيق.',
    project_cancellation_message:
      'العميل يطلب إلغاء المشروع، وسبب الإلغاء أدناه، هل أنت موافق، ومستعد للموافقة عليه؟',
    request_to_cancel_project: 'طلب/ت إلغاء المشروع',
    project_cancel_request_declined:
      'نحن آسفون جدا ، تم رفض طلب إلغاء المشروع. إذا كان لديك أي استفسارات ، يرجى الاتصال بنا.',
    project_cancel_request_approved: 'تمت الموافقة على طلب إلغاء المشروع.',
    liked_portfolio: 'أعجب/ت بـ',
    added_portfolio_comment: 'أضاف/ت تعليقا على',
    followed_you: 'بدأ بمتابعتك.',
    lancersin_how: 'لانسرزان - كيف يعمل',
    lancersin_freelancers: 'لانسرزان - المستقلين',
    lancersin_about_us: 'لانسرزان - من نحن',
    lancersin_contact: 'لانسرزان - اتصل بنا',
    lancersin_privacy_policy: 'لانسرزان - سياسة الإستخدام',
    lancersin_terms_conditions: 'لانسرزان - الشروط والأحكام',
    lancersin_dashboard: 'لانسرزان - لوحة التحكم',
    lancersin_profile: 'لانسرزان - ملفي الشخصي',
    lancersin_notifications: 'لانسرزان - الإشعارات',
    lancersin_messages: 'لانسرزان - الرسائل',
    lancersin_myportfolio: 'لانسرزان - معرض أعمالي',
    lancersin_myarticles: 'لانسرزان - مقالاتي',
    send_invitation: 'ارسل الدعوة',
    invite_project_success: 'تم إرسال دعوتك بنجاح.',
    invite_to_project: 'دعاك لتقديم عرض على مشروعه',
    cancelled_services: 'الخدمات الملغاه',
    cancelled_projects: 'المشاريع الملغاه',
    client_declined_project_delivery_notification:
      'لديه بعض التعديلات ، قم بذلك وانقر على زر تسليم المشروع مرة أخرى.',
    handover_request_action_needed_project:
      'يوجد طلب تسليم للمشروع ، يرجى اتخاذ إجراء بشأنه قبل أن تتمكن من إلغاء هذا المشروع',
    handover_request_action_needed_service:
      'يوجد طلب تسليم للخدمة ، يرجى اتخاذ إجراء بشأنه قبل أن تتمكن من إلغاء هذه الخدمة',
    no_projects_Invite_with_action:
      'نحن آسفون ، ليس لديك أي مشاريع ، ربما لديك مشروع لا يزال قيد المراجعة. يمكنك أيضًا إضافة مشروع باستخدام زر الإجراء أدناه',
    client_feedback_done:
      'قام/ت بتقييم عملك ، يمكنك أيضًا إضافة تقييمك حول تعاونه / تعاونها الآن.',
    freelancer_feedback_done: 'قام/ت بتقييم تعاونك.',
    project_feedback: 'Project Feedback',
    customer_feedback: 'رأي العميل',
    later: 'لاحقا',
    email_not_verified: 'لم يتم التحقق من عنوان بريدك الإلكتروني حتى الآن:',
    tree_steps: 'ثلاث خطوات سهلة',
    create_project: 'أنشئ مشروعًا',
    create_project_desc:
      'نشر مشروع مجاني وسهل. ما عليك سوى ملء عنوان ووصف مشروعك بالتفصيل وتحديد الميزانية وستأتي العروض التنافسية في غضون دقائق.',
    select_freelancer: 'اختر مستقل',
    select_freelancer_desc: 'اختر فقط المستقل الذي يناسب متطلباتك أكثر.',
    pay_safely: 'ادفع بأمان',
    pay_safely_desc:
      'ادفع مقابل العمل فقط عند اكتماله وأنت راضٍ بنسبة 100٪ عن الجودة باستخدام بوابة الدفع الآمنة الخاصة بنا.',
    register_to_add:
      'سجّل الدخول الآن أو أنشئ حسابًا جديدًا لإضافة مشروع مجانًا',
    hire: 'وظف',
    professional_freelancers: 'مستقلين محترفين',
    hire_desc:
      'لانسرزان تجمع بين الشركات والمستقلين من جميع أنحاء العالم, قم بتحسين كفاءة شركتك بدعم من المهنيين ذوي المهارات العالية.',
    admin_approve_withdrawal:
      'تهانينا ، وافقت إدارة المنصة على طلب سحب الأرباح الخاص بك.',
    bids_limit: 'تم نفاذ عدد العروض المتاحة',
    bids_limit_message:
      'لقد استخدمت بالفعل العروض المتاحة لديك، أنت بحاجة لانتظار اتخاذ أي اجراء علي مشروع قدمت عرضا عليه أو الانتظار للشهر القادم، أيهما أقرب.',
    client_description: '(أبحث عن مستقلين لإنجاز أعمالي أو شراء الخدمات)',
    freelancer_description: '(أبحث عن وظائف أوعرض خدماتي للبيع)',
    switch_to_freelancer: 'تبديل العرض كمستقل',
    switch_to_client: 'تبديل العرض كعميل',
    recently_added_project: 'أضاف/ت مؤخرًا مشروعًا ربما تكون مهتمًا به.',
    recently_added_service: 'أضاف/ت مؤخرًا خدمة ربما تكون مهتمًا به.',
    mark_all_notifications_read: 'تحويل كافة الإشعارات مقروءة',
    mark_all_notifications_read_body:
      'هل أنت متأكد من تحويل جميع الإشعارات كمقروءة؟',
    mark_all_notifications_read_success:
      'تم تحويل كافة الإشعارات كمقروءة بنجاح.',
    username_regex_error:
      "يجب أن يحتوي حقل اسم المستخدم على أحرف إنكليزية وأرقام وعوارض سفلية '_' أو واصلة '-' بينهما فقط",
    description_limit:
      'يجب أن يكون الحد الأدنى لعدد الأحرف في حقل الوصف أكثر من أو يساوي 250.',
    about_limit:
      'يجب أن يكون الحد الأدنى لعدد الأحرف في حقل نبذة تعريفية أكثر من أو يساوي 250.',
    min_character: 'الحد الأدنى لعدد الأحرف:',
    description_limit_small:
      'يجب أن يكون الحد الأدنى لعدد الأحرف في حقل الوصف أكثر من أو يساوي 200.',
    lancersin_projects_receiving: 'لانسرزان - مشاريع تستقبل العروض',
    lancersin_projects_progress: 'لانسرزان - مشاريع قيد التنفيذ',
    lancersin_projects_closed: 'لانسرزان - المشاريع المنجزة',
    lancersin_projects_cancelled: 'لانسرزان - المشاريع الملغاه',
    lancersin_services_myservices: 'لانسرزان - خدماتي',
    lancersin_services_progress: 'لانسرزان - خدمات قيد التنفيذ',
    lancersin_services_closed: 'لانسرزان - الخدمات المنجزة',
    lancersin_services_cancelled: 'لانسرزان - الخدمات الملغاه',
    lancersin_services_Awaiting: 'لانسرزان خدمات بإنتظار الدفع',
    verify_robot: 'يرجى التحقق من أنك لست روبوتًا',
    title_limit:
      'يجب أن يكون الحد الأدنى لعدد الأحرف في حقل العنوان أكثر من أو يساوي 25.',
    projects_posted: 'المشاريع المضافة',
    projects_paid: 'المشاريع المدفوعة',
    services_purchased: 'الخدمات المشتراه',
    member_since: 'عضو منذ',
    total_balance: 'إجمالي الرصيد',
    refund_balance: 'الرصيد المسترد',
    not_receive_verify_code: 'لم يصلك رمز التحقق؟!',
    resend_verify_code: 'إعادة إرسال رمز التحقق',
    client_stats: 'احصائيات العميل',
    freelancer_stats: 'احصائيات المستقل',
    join_bids: 'قم بالاشتراك أو تسجيل الدخول لإضافة عرض على هذا المشروع',
    add_bid_lock:
      'أضف عرضك على مشروع %{fname} %{lname} عن طريق تسجيل الدخول أو الاشتراك',
    add_similar_project: 'أضف مشروعًا مشابهًا',
    start_your_project: 'ابدأ ببناء مشروعك على منصة لانسرزان',
    start_your_project_desc:
      'أدخل تفاصيل المشروع والميزانية المتوقعة، ليتم مراجعة مشروعك ونشره مجاناً على منصة لانسرزان .بعد ذلك، سيتقدم إليك عدد من العروض من المستقلين المتخصصين يمكنك إختيار العرض الأنسب لك من العروض المقدمة وتبدأ بتنفيذ مشروعك.',
    project_guarantee: 'ضمان الحقوق',
    project_guarantee_desc:
      'عندما تتعامل مع أحد المستقلين من خلال منصة لانسرزان، يصبح لانسرزان وسيطا بينك وبين المستقل الذي ينفّذ مشروعك وبعد انتهاء تنفيذ المشروع كاملاً بالجودة المطلوبة يتم تحويل المبلغ لحساب المستقل الذي نفّذ مشروعك.',
    project_tips: 'بعض النصائح لمشروع ناجح',
    project_tip_one: 'أدخل عنوانًا مختصرًا يصف مشروعك',
    project_tip_two: 'كتابة وصف المشروع بدقة ووضوح',
    project_tip_three: 'اختر التصنيف والتصنيف الفرعي المناسبين لمشروعك',
    project_tip_four: 'اختر ميزانية وعدد أيام مناسبة لحجم المشروع',
    project_tip_five:
      'جزء المشروع على عدّة مراحل صغيرة ووفر أمثلة لما تريد كلما أمكن',
    lancersin_projects_adding_project: 'لانسرزان - المشاريع - إضافة مشروع',
    client_coupon: 'لفترة محدودة استخدم كوبون الخصم',
    client_coupon_and: 'واحصل على',
    client_coupon_amount: 'خصم 10%',
    client_coupon_desc: 'على أول عملية شراء لخدمة أو إضافة مشروع.',
    client_coupon_limit_one:
      'علما بأن الحد الأدنى لسعر الخدمة أو سعر العرض على المشروع هو',
    client_coupon_limit_two: '7 دولار',
    discount_coupon: 'كوبون خصم',
    discount_desc:
      'اذا كان لديك كوبون خصم يمكنك اضافته في الحقل التالي ليتم تطبيقه عند اختيارك للعرض المناسب لك من العروض المقدمة من خلال المستقلين',
    bids_warning:
      'يرجى ملاحظة أن مشاركة تفاصيل الاتصال الشخصية الخاصة بك مع العملاء عبر عروضك على المشاريع أو في الرسائل سيؤدي إلى غلق الحساب وستفقد أي رصيد في حسابك.',
    adding_warning:
      'يرجى ملاحظة أن إضافة أي صور أو ملفات تحتوي على تفاصيل الاتصال الشخصية الخاصة بك سيؤدي إلى غلق الحساب وستفقد أي رصيد في حسابك.',
    profile_link: 'سيكون رابط ملفك التعريفي:',
    required: 'مطلوب',
    project_received: 'استملت المشروع',
    project_delivery_body:
      "إذا كنت متأكدًا من استلام المشروع أدناه ، فيرجى النقر فوق 'نعم'. يرجى ملاحظة أنه سيتم تحويل ميزانية المشروع إلى المستقل بعد النقر فوق نعم.",
    service_received: 'استملت الخدمة',
    service_delivery_body:
      "إذا كنت متأكدًا من استلام الخدمة أدناه ، فيرجى النقر فوق 'نعم'. يرجى ملاحظة أنه سيتم تحويل ميزانية الخدمة إلى المستقل بعد النقر فوق نعم.",
    clients_tab: 'أصحاب المشاريع (رواد الأعمال)',
    freelancers_tab: 'المستقلين (الفريلانسرز)',
    browse_services: 'تصفح الخدمات',
    why_lancersin: 'لماذا لانسرزان؟',
    general: 'عام',
    partners: 'شُركاء النجاح',
    faq: 'الأسئلة الشائعة',
    client_slide_project_head:
      'وظف الآن أفضل مستقل (فريلانسر) متاح لإنجاز مشروعك',
    client_slide_project_desc:
      'نوصلك بأفضل المستقلين (الفريلانسرز) المحترفين في مختلف المجالات، بما في ذلك التصميم والبرمجة والترجمة وكتابة المحتوى، حيث يمكنك إنجاز مهامك بسهولة وأمان من خلال توظيف مستقل (فريلانسر) حسب متطلباتك وميزانيتك. ببساطة، أضف مشروعاً جديداً للبدء.',
    client_slide_service_head:
      'شراء الخدمات من أفضل المستقلين (الفريلانسرز) لدينا سهل',
    client_slide_service_desc:
      'يمكنك شراء مجموعة واسعة من الخدمات المصغرة الاحترافية من أفضل المستقلين (الفريلانسرز) في مختلف المجالات حيث يمكنك إنجاز مهامك بسهولة وآمان بأسعار تبدأ من 3 دولار أمريكي.',
    freelancer_slide_project_head:
      'إبدأ بتنفيذ المشاريع واحصل على ارباحك بأقل عمولة',
    freelancer_slide_project_desc:
      'انضم إلى لانسرزان الآن، أنشىء حساب مستقل (فريلانسر) في لانسرزان للعمل الحر، ومن ثم أضف معرض أعمالك ثم ابدأ بتصفح المشاريع. يمكنك إضافة عرضك على المشاريع حسب مجال تخصصك للعمل كمستقل.',
    freelancer_slide_service_head:
      'إبدأ بعرض خدماتك للبيع على منصة لانسرزان مجاناً',
    freelancer_slide_service_desc:
      'أنشىء حساب مستقل (فريلانسر) في منصة لانسرزان للعمل الحر، ومن ثم أضف خدماتك مجاناً للبيع على المنصة واحصل على أرباحك بأقل عمولة.',
    how_client_project_head_one: 'أضف مشروعك مجاناً',
    how_client_project_desc_one:
      'أنشيء مشروعك معنا وأخبرنا بجميع متطلباتك، كلما قدمت لنا مزيداً من المعلومات، زادت فرصتك في العثور على المستقل (الفريلانسر) المناسب لمشروعك.',
    how_client_project_head_two: 'قارن بين العروض المقدمة',
    how_client_project_desc_two:
      'قارن بين العروض المقدمة قبل اختيار المستقل (الفريلانسر) الأنسب لتنفيذ مشروعك.',
    how_client_project_head_three: 'تواصل مع من تراه مناسبا',
    how_client_project_desc_three:
      'قبل اختيار العرض يمكنك التواصل مع المستقل (الفريلانسر) عن طريق المنصة للتأكد من كل شيء قبل اختيارك للعرض الخاص به.',
    how_client_project_head_four: 'ادفع بأمان',
    how_client_project_desc_four:
      'ادفع بأمان عبر افضل بوابات الدفع والأكثر آمانا في العالم وسيظل الرصيد بالمنصة لحين الانتهاء من تسليم المشروع بالجودة المطلوبة.',
    how_client_service_desc_one:
      'تصفح الخدمات المقدمة من المستقلين (الفريلانسرز) واختر الأنسب لك.',
    how_client_service_head_two: 'تواصل مع البائع',
    how_client_service_desc_two:
      'قبل شراء الخدمة يمكنك التواصل مع المستقل (الفريلانسر) البائع للخدمة عن طريق المنصة للتأكد أنك ستحصل على ما تريد.',
    how_client_service_desc_three:
      'ادفع بأمان عبر افضل بوابات الدفع والأكثر آمانا في العالم وسيظل الرصيد بالمنصة لحين الانتهاء من تسليم الخدمة بالجودة المطلوبة.',
    how_freelancer_service_head_one: 'أضف خدمتك للبيع مجاناً',
    how_freelancer_service_desc_one:
      'أضف خدمتك للبيع مجاناً معنا؛ فكلما كان عنوان الخدمة واضحاً ومختصراً، زادت فرص زيادة مبيعاتك. يجب أن تكون الخدمة محصورة في مجال واحد فقط لتتم قبولها على المنصة، ويجب أن يكون وصف الخدمة شاملاً لما سيتم تسليمه للعميل عند شراء هذه الخدمة.',
    how_freelancer_service_head_two: 'مجالات عمل متنوعة',
    how_freelancer_service_desc_two:
      'يمكنك إضافة العديد من الخدمات في مجالات مختلفة مثل التصميم والبرمجة والترجمة وكتابة المحتوى وغيرها الكثير. كل ما عليك فعله هو اختيار المجال الذي تمتلك فيه الخبرة الكافية لتقديم عمل رائع، وبذلك تتمكن من الفوز بتقييم ممتاز من العملاء المحتملين.',
    how_freelancer_service_head_three: 'استلم أرباحك أينما كنت',
    how_freelancer_service_desc_three:
      'يمكنك استلام أرباحك بوسائل مختلفة، بما في ذلك PayPal والتحويل البنكي والمحافظ الإلكترونية مثل فودافون كاش وأورانج كاش وإتصالات كاش وغيرها الكثير.',
    how_freelancer_portfolio_head_one: 'أضف معرض أعمالك المهنيّ إلى موقعنا',
    how_freelancer_portfolio_desc_one:
      'يساعد معرض أعمالك في بناء ثقة العملاء فيك ويعطيهم فكرة عن خبرتك وتميزك في مجالك. كلما كان معرض أعمالك جيداً، زادت فرصك في الحصول على مشاريع لتنفيذها أو بيع خدماتك على المنصة.',
    how_freelancer_portfolio_desc_two:
      'يمكنك إضافة العديد من الأعمال التي تميزت بها من قبل في مجالات مختلفة مثل التصميم والبرمجة والموشن جرافيك وغيرها الكثير، مع وصف العمل بشكل لائق يوضح مسؤولياتك وإنجازاتك في هذا العمل.',
    how_freelancer_project_head_one: 'أضف عَرْضُكَ على المشاريع المتاحة لدينا',
    how_freelancer_project_desc_one:
      'أضف عروضاً مهنية وجذابة لزيادة فرصك في الحصول على المشروع المناسب. كلما كان وصف عَرْضُكَ احترافياً ويحتوي على جميع التفاصيل التي ستقدمها للعميل، زادت فرصك في الفوز بالمشروع. اختر المشاريع التي تتمتع بالمهارات والخبرة الكافية لتنفيذها بدقة واحترافية عالية. يساعد تنفيذك للمشروع بدقة واحترافية على الحصول على تقييم ممتاز من العملاء، الذي يساعدك في الفوز بمشاريع أخرى في المستقبل.',
    how_freelancer_project_head_two: 'التواصل مع صاحب المشروع',
    how_freelancer_project_desc_two:
      'إذا تم التواصل معك عبر صاحب المشروع للاستفسار عن عَرْضُكَ، يجب عليك الرد بصورة احترافية ومهنية حتى تزيد فرصك في الفوز بالمشروع. ولحماية حقوقك، يجب أن تجعل كل تواصلك مع العملاء داخل المنصة.',
    why_client_head_one: 'تعامل ودفع آمن',
    why_client_desc_one:
      'نحن نستخدم أفضل بوابات الدفع الآمنة عالمياً، ويتم الاحتفاظ بالرصيد داخل المنصة حتى يتم تسليم العمل بشكل كامل وراضٍ عنه من قِبل العملاء.',
    why_client_head_two: 'تخفيض التكاليف التشغيلية',
    why_client_desc_two:
      'نقدّم المساعدة لأصحاب المشاريع ورواد الأعمال في تقليل التكاليف التشغيلية عن طريق التعاون مع المستقلين (الفريلانسرز) لإنجاز المهام بالجودة المطلوبة وفي الوقت المحدد.',
    why_client_head_three: 'مستقلون (فريلانسرز) ذوو كفاءات عالية.',
    why_client_desc_three:
      'يمكنك توظيف مستقل (فريلانسر) خبير في مجالك من بين الآلاف من المستقلين المتخصصين.',
    why_client_head_four: 'توظيف مرن في مختلف المجالات',
    why_client_desc_four:
      'يمكنك توظيف مستقل (فريلانسر) ذو خبرة في مجالات متعددة، مثل التصميم الجرافيكي، والتسويق، والبرمجة، والترجمة، والكتابة، وغيرها الكثير.',
    why_client_head_five: 'دعم فني متاح على مدار الساعة',
    why_client_desc_five:
      'يتوفر لدينا دعم فني متميز على مدار الساعة لمساعدتك في تجاوز أي عقبات قد تواجهك على المنصة.',
    why_freelancer_head_one: 'وسائل سحب الأرباح متعددة ومتنوعة',
    why_freelancer_desc_one:
      'يتوفر لدينا وسائل سحب الأرباح المتعددة والمتنوعة، مثل PayPal والتحويل البنكي والمحافظ الإلكترونية مثل فودافون كاش، وأورانج كاش، واتصالات كاش، وغيرها الكثير.',
    why_freelancer_head_two: 'نسبة عمولة المنصة',
    why_freelancer_desc_two:
      'أقل نسبة عمولة في الوطن العربي، حيث يمكن أن تصل العمولة إلى 10%.',
    why_freelancer_head_three: 'فترة تعليق الرصيد',
    why_freelancer_desc_three:
      'نتميز بأقل فترة تعليق للرصيد، حيث يمكن أن تصل إلى يوم واحد فقط.',
    why_freelancer_head_four: 'نحن نحرص على حماية حقوقك ونوفر دعم فني متميز',
    why_freelancer_desc_four:
      'نضمن حماية كاملة لحقوقك عند الالتزام بجميع تعليمات المنصة، ونوفر دعم فني متميز يخدمك على مدار الساعة.',
    faq_q1: 'ما هي منصة لانسرزان؟',
    faq_a1:
      'منصة لانسرزان هي منصة آمنة وموثوقة تعمل كوسيط بين أصحاب المشاريع (رواد الأعمال) والمستقلين (الفريلانسرز) المحترفين في مختلف المجالات. توفر المنصة بيئة عمل احترافية تضمن تنفيذ العمل وفق الاتفاق المبرم بين الطرفين.',
    faq_q2: 'كيف يمكن لي العمل كمستقل (فريلانسر) على منصة لانسرزان؟',
    faq_a2:
      'يمكنك القيام بذلك عن طريق إنشاء حساب فريلانسر على المنصة، ومن ثم استكمال ملفك الشخصي وعرض مهاراتك ومجالات عملك. يمكنك أيضًا إضافة بعض من أعمالك إلى معرض الأعمال الخاص بك. بعد ذلك، يمكنك الربح عن طريق عرض بعض خدماتك للبيع على المنصة أو تصفح المشاريع وتقديم العرض الخاص بك على المشاريع التي تمتلك فيها الخبرة الكافية لتنفيذها.',
    faq_q3: 'كيف يمكنني اختيار مستقل (فريلانسر) محترف لإنجاز مشروعي؟',
    faq_a3:
      'يمكنك القيام بذلك عن طريق مقارنة العروض المقدمة على مشروعك، واستعراض ملفات كل مستقل ومشاهدة معارض أعمالهم ومهاراتهم، بالإضافة إلى التقييمات التي حصلوا عليها من إكمال مشاريع سابقة أو بيع خدمات. كما يمكنك مراسلة المستقل عبر المنصة للاستفسار عن عرضه قبل قبوله للعمل على مشروعك.',
    faq_q4: 'كيف يمكنني ضمان حقوقي كعميل على منصة لانسرزان؟',
    faq_a4:
      'إذا لم يقم المستقل (الفريلانسر) بتسليم العمل بالكامل كما هو متفق عليه، فإن منصة لانسرزان تضمن استرداد الأموال للمشتري وفقًا لشروط وسياسة الاستخدام. كما يمكن للمنصة تنفيذ المشروع للعميل مع مستقل آخر إذا رغب بذلك.',
    faq_q5: 'كيف يمكن لمنصة لانسرزان ضمان حقوقي كمستقل؟',
    faq_a5:
      'عندما يقوم العميل باختيار عَرْضُكَ على أحد المشاريع المتاحة في المنصة أو يشتري خدمة من خدماتك، يتم اقتطاع مبلغ الخدمة من رصيد العميل ويبقى معلقًا في المنصة حتى يتم تسليم المشروع أو الخدمة بالجودة المطلوبة.',
    faq_q6: 'ما هي طرق سحب الأرباح المتاحة على المنصة؟',
    faq_a6:
      'يتوفر لدينا العديد من وسائل سحب الأرباح، بما في ذلك PayPal والتحويل البنكي والمحافظ الإلكترونية مثل فودافون كاش وأورانج كاش واتصالات كاش.',
    faq_q7: 'ما هي الأسباب التي يمكن أن تؤدي إلى حظر حساب المستخدم؟',
    faq_a7:
      'هناك عدة أسباب مذكورة في شروط الاستخدام، بما في ذلك: إرسال وسائل تواصل خارجية ضمن أي قسم من أقسام المنصة بهدف التعامل خارج المنصة، إنشاء أكثر من حساب، التجاوز الأخلاقي بأي شكل من الأشكال، ومحاولة الاحتيال أو السرقة.',
    faq_q8: 'كيف يمكن لي كصاحب مشروع أو رائد أعمال الاستفادة من قسم المشاريع؟',
    faq_a8:
      'يُعدُّ قسم المشاريع على منصة لانسرزان طريقة فعّالة لإنجاز المهام المختلفة التي تريد تنفيذها عن بُعد، بالتعاون مع مستقلين (فريلانسرز) متخصصين في مجالات مثل التصميم، البرمجة، الترجمة، الكتابة، وغيرها. كل ما تحتاج إليه هو كتابة وصف تفصيلي للمشروع الذي تريد تنفيذه، وكلما كانت المعلومات واضحة ودقيقة، زادت فرص العثور على المستقل المثالي لتنفيذ مشروعك. بعد ذلك، يمكنك تحديد المدة الزمنية والميزانية المتاحة للمشروع، والانتظار حتى يتقدَّم المستقلون الذين يتوفرون على مهارات تتناسب مع متطلبات المشروع بعروضهم. يمكنك مراجعة العروض المختلفة التي تتلقاها واختيار المستقل المناسب لتنفيذ المشروع. ومن الجدير بالذكر أن مبلغ المشروع يبقى في حسابك على المنصة، ولا يتم تحويله إلى المستقل إلا بعد استلامك للمشروع والضغط على زر "استلمت المشروع".',
    faq_q9: 'هل يتطلب إضافة مشروعي كصاحب مشروع أو رائد أعمال دفع رسوم إضافية؟',
    faq_a9:
      'يتم إضافة المشروع على المنصة مجاناً، وعندما تختار العرض المناسب لتنفيذ المشروع، لا يتم فرض أي رسوم إضافية بخلاف رسوم العرض المختار. ولا يتم تحويل المبلغ إلى المستقل (الفريلانسر) إلا بعد استلامك للمشروع والضغط على زر "استلمت المشروع".',
    faq_q10:
      'كيف يمكن للمستقلين (الفريلانسرز) استخدام قسم المشاريع على المنصة؟',
    faq_a10:
      'على المنصة، يمكنك تصفح المشاريع المتاحة وتقديم عروضك على المشاريع التي تتناسب مع مهاراتك وخبرتك. كلما كان ملفك الشخصي ومعرض أعمالك مكتمل، زادت فرصك في الفوز بتنفيذ المشروع. ومن الضروري تقديم عروضك على المشاريع التي تمتلك المهارات والخبرة الكافية لتنفيذها بدقة وجودة عالية. فهذا سيساعدك على الحصول على تقييم ممتاز من العميل، وسيزيد من فرصك في الحصول على مشاريع أخرى في المستقبل.',
    faq_q11:
      'ما هي النسبة التي تحصل عليها منصة لانسرزان من أجل توفير خدماتها للمستقلين (الفريلانسرز) الذين ينجزون مشاريع على المنصة؟',
    faq_a11:
      'تحصل منصة لانسرزان على نسبة أساسية تبلغ 20% من قيمة المشروع المنجز على المنصة، ولكن هذه النسبة يمكن أن تصل في بعض الأحيان إلى 10%، وذلك يعتمد على عدة عوامل. من بين هذه العوامل: معدل انجازك للمشاريع وتقييماتك الممتازة، ومدى التزامك بقوانين وشروط العمل على المنصة.',
    faq_q12:
      'كيف يمكن لصاحبي المشاريع ورجال الأعمال الاستفادة من قسم الخدمات المتاح على المنصة؟',
    faq_a12:
      'يمكن لقسم الخدمات المتاح على المنصة أن يساعدك على الحصول على العديد من الخدمات الجاهزة للبيع عن طريق المستقلين (الفريلانسرز). يمكنك تصفح قسم الخدمات واختيار الخدمة التي ترغب في شرائها، ثم التواصل مع البائع للاتفاق على جميع التفاصيل. بعد ذلك، يمكنك الضغط على زر "اشترِ الآن" وبعد إتمام عملية الدفع، يمكنك متابعة العمل مع المستقل (الفريلانسر)، مع العلم بأن مبلغ الخدمة يبقى في المنصة ولا يتم تحويله إلى المستقل إلا بعد استلامك للخدمة والضغط على زر "استلمت الخدمة".',
    faq_q13: 'هل يوجد رسوم اضافية لشراء خدمة من على منصة لانسرزان؟',
    faq_a13:
      'لا يوجد أي رسوم إضافية بخلاف سعر الخدمة، ولا يتم تحويل المبلغ إلى المستقل (الفريلانسر) إلا بعد استلامك للخدمة والضغط على زر "استلمت الخدمة".',
    faq_q14:
      'كمستقل (فريلانسر)، كيف يمكنني الاستفادة من قسم الخدمات على المنصة؟',
    faq_a14:
      'يمكنك إضافة العديد من الخدمات وعرضها للبيع على المنصة مجانًا. وكلما كان وصف الخدمة متناسقًا ويوضح كافة ما سيتم تسليمه للعميل مقابل المبلغ والوقت المحددين، كلما زادت مبيعاتك. الاهتمام بالالتزام بموعد التسليم والجودة في التنفيذ يساعدك على الحصول على تقييم ممتاز من العملاء، مما يساعدك على زيادة مبيعاتك في المستقبل.',
    faq_q15:
      'ما هي النسبة التي تحصل عليها منصة لانسرزان إذا قمت كمستقل ببيع خدمة؟',
    faq_a15:
      'تبلغ النسبة الأساسية التي تحصل عليها منصة لانسرزان 20%. ومع ذلك، يمكن أن تصل هذه النسبة في بعض الأحيان إلى 10% وذلك يعتمد على عدة عوامل، بما في ذلك معدل تنفيذك للخدمات مع تقييمات ممتازة والالتزام بقوانين وشروط العمل على المنصة.',
    faq_q16: 'هل يوجد حد أدني وأقصى لسعر الخدمة؟',
    faq_a16:
      'نعم، بالتأكيد. يتراوح الحد الأدنى لسعر الخدمة بين 3 دولارات أمريكية، والحد الأقصى هو 1500 دولار أمريكي.',
    lancersin_home_title:
      'وظف أفضل المستقلين (الفريلانسرز) واكتشف فرص العمل عبر الانترنت',
    optional: 'اختياري',
  },
};
export default translations;
