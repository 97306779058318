import React from "react";
import { I18n } from 'react-redux-i18n';
import Section from '../Section/Section';

export default function ClientSteps(){
    return (
        <Section 
            sectionClassName="lazy-background sectionTopBottomSpaces clientSteps main main-bg bg visible"
            h2Content={I18n.t('how_it_works')}
            h1Content={I18n.t('tree_steps')}
        >
            <div className="row clientSteps">
                <div className="col-md-4">
                    <div className="sliderItem box-shadow">
                        <i className="icon-proposals-limit" aria-hidden aria-label={I18n.t('create_project')} />
                        <h2>{I18n.t('create_project')}</h2>
                        <p>{I18n.t('create_project_desc')}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="sliderItem box-shadow">
                        <i className="icon-proposals-sent" aria-hidden aria-label={I18n.t('select_freelancer')} />
                        <h2>{I18n.t('select_freelancer')}</h2>
                        <p>{I18n.t('select_freelancer_desc')}</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="sliderItem box-shadow">
                        <i className="icon-protection" aria-hidden aria-label={I18n.t('pay_safely')} />
                        <h2>{I18n.t('pay_safely')}</h2>
                        <p>{I18n.t('pay_safely_desc')}</p>
                    </div>
                </div>
            </div>
        </Section>
    )
}