// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_USER_DATA_LOADING,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
} = TYPES;

export function getUserData(locale) {
  return async function getUserDataWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: GET_USER_DATA_LOADING
    });
    try {
        let response = await axiosInstance
        .get(API.USERDATA, {...tokenConfig(getState, locale)});
        if(response.status !== 200){
            dispatch({
              type: GET_USER_DATA_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_USER_DATA_SUCCESS,
            payload: response.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: GET_USER_DATA_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_USER_DATA_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: GET_USER_DATA_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_USER_DATA_FAIL'));
        }
    }
  }
}

const exportedObject = {
  getUserData
};

export default exportedObject;
