// Actions for Adding Behavior

import TYPES, {CLEAR_ADD_PROJECT_BID_SUCCESS, SAVE_PROJECT} from './types';
import {axiosInstance, tokenConfig} from '../../../axiosInstance';
import {returnErrors, clearErrors} from '../../../actions/errorActions';
import {resetActions} from './resetActions';
import {getProjectBids, getProjectComments} from './getActions';
import API from '../API';

const {
  ADD_PROJECT_LOADING,
  ADD_PROJECT_SUCCESS,
  ADD_PROJECT_FAIL,

  ADD_PROJECT_BID_LOADING,
  ADD_PROJECT_BID_SUCCESS,
  ADD_PROJECT_BID_FAIL,

  SELECT_PROJECT_BID_LOADING,
  SELECT_PROJECT_BID_SUCCESS,
  SELECT_PROJECT_BID_FAIL,

  ADD_PROJECT_COMMENT_LOADING,
  ADD_PROJECT_COMMENT_SUCCESS,
  ADD_PROJECT_COMMENT_FAIL,

  EDIT_PROJECT_LOADING,
  EDIT_PROJECT_SUCCESS,
  EDIT_PROJECT_FAIL,

  CLOSE_PROJECT_LOADING,
  CLOSE_PROJECT_SUCCESS,
  CLOSE_PROJECT_FAIL,

  REQUEST_CLOSE_PROJECT_LOADING,
  REQUEST_CLOSE_PROJECT_SUCCESS,
  REQUEST_CLOSE_PROJECT_FAIL,

  RATE_PROJECT_LOADING,
  RATE_PROJECT_SUCCESS,
  RATE_PROJECT_FAIL,

  //Handover Project Bid
  HANDOVER_PROJECT_BID_LOADING,
  HANDOVER_PROJECT_BID_SUCCESS,
  HANDOVER_PROJECT_BID_FAIL,

  //Indorse Project Bid
  INDORSE_PROJECT_BID_LOADING,
  INDORSE_PROJECT_BID_SUCCESS,
  INDORSE_PROJECT_BID_FAIL,

  //Report Project Bid
  REPORT_PROJECT_BID_LOADING,
  REPORT_PROJECT_BID_SUCCESS,
  REPORT_PROJECT_BID_FAIL,

  //Cancel Project Bid
  CANCEL_PROJECT_BID_LOADING,
  CANCEL_PROJECT_BID_SUCCESS,
  CANCEL_PROJECT_BID_FAIL,
} = TYPES;

// ADD/EDIT Project
export function addProject(
  title = null,
  cat_id = null,
  sub_cat_id = null,
  skills = null,
  keywords = null,
  description = null,
  price_id = null,
  deadline = null,
  image = null,
  images = null,
  images_sort = null,
  promo_code = null,
  id = null,
  locale,
) {
  return async function addProjectsWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    if (id) {
      dispatch({
        type: EDIT_PROJECT_LOADING,
      });
    } else {
      dispatch({
        type: ADD_PROJECT_LOADING,
      });
    }
    try {
      const body = {
        title,
        cat_id,
        sub_cat_id,
        skills,
        keywords,
        description,
        price_id,
        deadline,
        image,
        images,
        images_sort,
        promo_code,
        id,
      };
      let response = await axiosInstance.post(
        API.PROJECTS,
        body,
        tokenConfig(getState, locale),
      );
      if (response.status !== 200) {
        if (id) {
          dispatch({
            type: EDIT_PROJECT_FAIL,
          });
        } else {
          dispatch({
            type: ADD_PROJECT_FAIL,
          });
        }
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        if (id) {
          dispatch({
            type: EDIT_PROJECT_SUCCESS,
            payload: response.data.data,
            status: response.data.response,
          });
        } else {
          dispatch({
            type: ADD_PROJECT_SUCCESS,
            payload: response.data.data,
            status: response.data.response,
          });
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (id) {
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'EDIT_PROJECT_FAIL',
            ),
          );
          dispatch({
            type: EDIT_PROJECT_FAIL,
          });
        } else {
          dispatch({
            type: ADD_PROJECT_FAIL,
          });
          dispatch(
            returnErrors(
              error.response.data,
              error.response.status,
              'ADD_PROJECT_FAIL',
            ),
          );
        }
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        if (id) {
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'EDIT_PROJECT_FAIL',
            ),
          );
          dispatch({
            type: EDIT_PROJECT_FAIL,
          });
        } else {
          dispatch({
            type: ADD_PROJECT_FAIL,
          });
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'ADD_PROJECT_FAIL',
            ),
          );
        }
      }
    }
  };
}

export function saveProject(
  title = null,
  cat_id = null,
  sub_cat_id = null,
  skills = null,
  keywords = null,
  description = null,
  price_id = null,
  deadline = null,
  image = null,
  images = null,
  images_sort = null,
  promo_code = null,
  id = null,
  locale,
) {
  return function addProjectsWithDispatch(dispatch) {
    const project = {
      title,
      cat_id,
      sub_cat_id,
      skills,
      keywords,
      description,
      price_id,
      deadline,
      image,
      images,
      images_sort,
      promo_code,
      id,
    };
    dispatch({
      type: SAVE_PROJECT,
      payload: project,
    });
  };
}

export function addProjectBid(data) {
  return async function addProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: ADD_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.CREATE_PROJECT_BID,
        data,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: ADD_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        const page = 1;
        const limit = 10;
        dispatch({
          type: ADD_PROJECT_BID_SUCCESS,
        });
        dispatch({
          type: CLEAR_ADD_PROJECT_BID_SUCCESS,
        });
        dispatch(getProjectBids(data.project_id, page, limit));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'ADD_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: ADD_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'ADD_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: ADD_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

export function selectProjectBid(id) {
  return async function selectProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: SELECT_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.PROJECT_BID_SELECT,
        {id},
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: SELECT_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: SELECT_PROJECT_BID_SUCCESS,
        });
        dispatch(resetActions());
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'SELECT_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: SELECT_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'SELECT_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: SELECT_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

// ADD Project COMMENT
export function addProjectComment({comment, projectId, commentfiles}) {
  return async function addProjectCommentWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: ADD_PROJECT_COMMENT_LOADING,
    });
    try {
      const project_id = projectId;
      const files = commentfiles;
      //Request body
      const body = {comment, project_id, files};
      let response = await axiosInstance.post(
        API.PROJECT_COMMENTS,
        body,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: ADD_PROJECT_COMMENT_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        const page = 1;
        const limit = 10;
        const id = projectId;
        dispatch({
          type: ADD_PROJECT_COMMENT_SUCCESS,
          payload: response.data,
        });
        dispatch(getProjectComments(id, page, limit));
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'ADD_PROJECT_COMMENT_FAIL',
          ),
        );
        dispatch({
          type: ADD_PROJECT_COMMENT_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        dispatch(
          returnErrors(
            errorParsed.message,
            'Network Error',
            'ADD_PROJECT_COMMENT_FAIL',
          ),
        );
        dispatch({
          type: ADD_PROJECT_COMMENT_FAIL,
        });
      }
    }
  };
}

//Rate Freelancer
export function rateProject(projectInfo) {
  return async function rateProjectWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: RATE_PROJECT_LOADING,
    });
    const morphType = 'projects';
    const morphID = projectInfo.invoice_id;
    const comment = projectInfo.comment;
    const rate = projectInfo.rate;
    const {serviceUserId, loggedUserId} = projectInfo;

    const projectRatingInfo = {morphType, morphID, comment, rate};
    try {
      let response = await axiosInstance.post(
        API.FEEDBACK,
        projectRatingInfo,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: RATE_PROJECT_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: RATE_PROJECT_SUCCESS,
        });
        if (serviceUserId === loggedUserId) {
          dispatch(closeProject(morphID));
        }
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'RATE_PROJECT_FAIL',
          ),
        );
        dispatch({
          type: RATE_PROJECT_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'RATE_PROJECT_FAIL',
            ),
          ),
          dispatch({
            type: RATE_PROJECT_FAIL,
          })
        );
      }
    }
  };
}
//Close Project
export function closeProject(id) {
  return async function closeProjectWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: CLOSE_PROJECT_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.CLOSE_PROJECT,
        {id},
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: CLOSE_PROJECT_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: CLOSE_PROJECT_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'CLOSE_PROJECT_FAIL',
          ),
        );
        dispatch({
          type: CLOSE_PROJECT_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'CLOSE_PROJECT_FAIL',
            ),
          ),
          dispatch({
            type: CLOSE_PROJECT_FAIL,
          })
        );
      }
    }
  };
}
//Request Close Project
export function requestCloseProject(id) {
  return async function requestCloseProjectWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: REQUEST_CLOSE_PROJECT_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.REQUEST_CLOSE_PROJECT,
        {id},
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: REQUEST_CLOSE_PROJECT_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: REQUEST_CLOSE_PROJECT_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'REQUEST_CLOSE_PROJECT_FAIL',
          ),
        );
        dispatch({
          type: REQUEST_CLOSE_PROJECT_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'REQUEST_CLOSE_PROJECT_FAIL',
            ),
          ),
          dispatch({
            type: REQUEST_CLOSE_PROJECT_FAIL,
          })
        );
      }
    }
  };
}

// Handover Bid
export function handoverProjectBid(data) {
  return async function handoverProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: HANDOVER_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.HANDOVER_PROJECT_BID,
        data,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: HANDOVER_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: HANDOVER_PROJECT_BID_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'HANDOVER_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: HANDOVER_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'HANDOVER_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: HANDOVER_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

// Indorse Bid
export function indorseProjectBid(data) {
  return async function indorseProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: INDORSE_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.INDORSE_PROJECT_BID,
        data,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: INDORSE_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: INDORSE_PROJECT_BID_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'INDORSE_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: INDORSE_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'INDORSE_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: INDORSE_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

// Report Project Bid
export function reportProjectBid(data) {
  return async function reportProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: REPORT_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.REPORT_PROJECT_BID,
        data,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: REPORT_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: REPORT_PROJECT_BID_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'REPORT_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: REPORT_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'REPORT_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: REPORT_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

// Cancel Project Bid
export function cancelProjectBid(data) {
  return async function cancelProjectBidWithDispatch(dispatch, getState) {
    dispatch(clearErrors());
    dispatch({
      type: CANCEL_PROJECT_BID_LOADING,
    });
    try {
      let response = await axiosInstance.post(
        API.CANCEL_PROJECT_BID,
        data,
        tokenConfig(getState),
      );
      if (response.status !== 200) {
        dispatch({
          type: CANCEL_PROJECT_BID_FAIL,
        });
        throw Error({
          response: {
            data: 'Other Errors',
            status: 'Other Errors',
          },
        });
      } else {
        dispatch({
          type: CANCEL_PROJECT_BID_SUCCESS,
        });
      }
    } catch (error) {
      if (error.response && error.response.data) {
        dispatch(
          returnErrors(
            error.response.data,
            error.response.status,
            'CANCEL_PROJECT_BID_FAIL',
          ),
        );
        dispatch({
          type: CANCEL_PROJECT_BID_FAIL,
        });
      } else {
        let errorJson = JSON.stringify(error);
        let errorParsed = JSON.parse(errorJson);
        return (
          dispatch(
            returnErrors(
              errorParsed.message,
              'Network Error',
              'CANCEL_PROJECT_BID_FAIL',
            ),
          ),
          dispatch({
            type: CANCEL_PROJECT_BID_FAIL,
          })
        );
      }
    }
  };
}

// export const addProjectComment = ({comment, projectId, commentfiles}) => (dispatch, getState) => {
//   // Data Loading
//   dispatch({
//       type: ADD_PROJECT_COMMENT_LOADING
//   });
//   const project_id = projectId;
//   const files = commentfiles;
//   //Request body
//   const body = { comment, project_id, files };
//   axiosInstance.post('/api/projects/comments', body, tokenConfig(getState))
//   .then( res => {
//           dispatch ({
//               type: ADD_PROJECT_COMMENT_SUCCESS,
//               payload: res.data
//           });
//           dispatch(resetActions());
//           dispatch({
//               type: GET_PROJECT_COMMENTS_LOADING
//           });
//           //Request body
//           axiosInstance.get(`/api/projects/comments?project_id=${project_id}&page=1&limit=10`, tokenConfig(getState))
//           .then( res =>
//               dispatch ({
//                   type: GET_PROJECT_COMMENTS_SUCCESS,
//                   payload: res.data
//               })
//           )
//           .catch(err => {
//               dispatch(returnErrors(err.response.data, err.response.status, 'GET_PROJECT_COMMENTS_FAIL'))
//               dispatch ({
//                   type: GET_PROJECT_COMMENTS_FAIL
//               })
//           });
//       }
//   )
//   .catch(err => {
//       dispatch(returnErrors(err.response.data, err.response.status, 'ADD_PROJECT_COMMENT_FAIL'))
//       dispatch ({
//           type: ADD_PROJECT_COMMENT_FAIL
//       })
//   });
// };
