import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { I18n } from 'react-redux-i18n';
import Pagination from "react-js-pagination";
import AddingRow from "../AddingRow";
import AddingArticleModal from '../AddingArticleModal';
import { getArticles } from "../../redux/article/actions/getActions";
import Spin from "../Spin";
import ArticleCard from "../ArticleCard";
import ConfToast from "../ConfToast";
import ConnectionLost from "../ConnectionLost";
import NoItems from '../NoItems';
import { MetaComponent } from '../MetaComponent';
import { TitleComponent } from "../../components/TitleComponent";

const MyArticles = () => {
    const dispatch = useDispatch();
    var [toggleAddArticleModel, setToggleAddArticleModel] = useState(false);
    function handleHide() {
        setToggleAddArticleModel(!toggleAddArticleModel);
    }
    const {
        articles = [],
        getUserArticlesLoading,
        addingArticleLoading,
        deletingArticleLoading,
        deletingArticleSuccess,
        user,
        isLoading,
        totalRecords,
        itemsPerPage,
        errorId,
        errorMessage,
      } = useSelector((state) => ({
        articles: state.article.userArticles,
        getUserArticlesLoading: state.article.getUserArticlesLoading,
        addingArticleLoading: state.article.addingArticleLoading,
        deletingArticleLoading: state.article.deletingArticleLoading,
        deletingArticleSuccess: state.article.deletingArticleSuccess,
        user: state.auth.user,
        isLoading: state.auth.isLoading,
        totalRecords: state.article.totalRecords,
        itemsPerPage: state.article.itemsPerPage,
        errorId: state.error.id,
        errorMessage: state.error.msg,
    }));
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [loadingArticles, setLoadingArticles] = useState(true);
    const { id } = user;

    useEffect(() => {
        setLoadingArticles(getUserArticlesLoading);
    }, [getUserArticlesLoading]);

    useEffect(() => {
        if (id) {
            let user_id = id;
            const page = activePage;
            const limit = 12;
            dispatch(getArticles(user_id, null, page, limit, null, null, null, null));
        }
    }, [id, dispatch, activePage]);

    useEffect(() => {
        if (deletingArticleSuccess) {
            setConfShow(true);
            setConfTitle(I18n.t('delete_article'));
            setConfMessage(I18n.t('delete_article_success'));
            setConfStatus('Toast__Container__Success');
            setDelay(6000);
            if(id){
                let user_id = id;
                const page = 1;
                setActivePage(1);
                const limit = 12;
                dispatch(getArticles(user_id, null, page, limit, null, null, null, null));
            }
        }
    }, [deletingArticleSuccess, dispatch, id]);
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };
    return(
        <div className="container articles__list">
            <ConfToast
            showConf={confShow}
            setShowConf={setConfShow}
            title={confTitle}
            message={confMessage}
            status={confStatus}
            delay={delay}
            />
            <TitleComponent title={I18n.t('lancersin_myarticles')} />
            <MetaComponent />
            <AddingRow
                onAdding={() => {
                    setToggleAddArticleModel(!toggleAddArticleModel);
                }}
                title={"articles"}
                titleIconClassName={"icon-articles"}
                btnText={"post_article"}
                btnIconClassName={"icon-plus"}
                forceShowButton={true}
            />
            <AddingArticleModal
                loading={addingArticleLoading}
                modalTitle={"post_article"}
                className="CustomPopup"
                size="lg"
                show={toggleAddArticleModel}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            <div className="row articles__content">
                {
                    loadingArticles || isLoading || deletingArticleLoading ? 
                        <Spin />
                    : errorId === "GET_ARTICLES_FAIL" && errorMessage === "Network Error" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                            />
                        </div>
                    : errorId === "GET_ARTICLES_FAIL" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                            />
                        </div>
                    : articles.length > 0 && !loadingArticles ? (
                        <Fragment>
                            {
                                articles.map((item) => (
                                    <ArticleCard key={`article-#${item.id}`} item={item} />
                                ))
                            }
                            <div className="col-12 mb-5">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            </div>
                        </Fragment>
                    ) : 
                    <div className="col-12">
                        <NoItems
                            title="no_articles_found"
                            message="no_articles_message_with_action"
                            buttonText="post_article"
                            iconClassName="icon icon-plus"
                            onClick={() => {
                                setToggleAddArticleModel(!toggleAddArticleModel);
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default MyArticles;