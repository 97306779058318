import React, { Fragment } from "react";
import { I18n } from "react-redux-i18n";

export default function ConnectionLost(props) {
    const {
        errorType= "",
        title = "",
        message = ""
    } = props;
    return(
        <div className="Connection__Lost">
            <div className="Connection__Lost__Container">
                {
                    errorType === "connection" ?
                    <Fragment>
                        <figure>
                            <span className="ConnectionLostImg"></span>
                        </figure>
                        <h1>{title ? I18n.t(title) : I18n.t('network_error_title')}</h1>
                        <p>
                            {message ? I18n.t(message) : I18n.t('network_error_message')}
                        </p>
                    </Fragment>
                    : errorType === "somethingWrong" ?
                    <Fragment>
                        <figure>
                            <span className="SomethingWrongImg"></span>
                        </figure>
                        <h1>{title ? I18n.t(title) : I18n.t('something_error_title')}</h1>
                        <p>
                            {message ? I18n.t(message) : I18n.t('something_error_message')}
                        </p>
                    </Fragment>
                    :
                    <Fragment>
                        <figure>
                            <span className="SomethingWrongImg"></span>
                        </figure>
                        <h1>{title ? I18n.t(title) : I18n.t('something_error_title')}</h1>
                        <p>
                            {message ? I18n.t(message) : I18n.t('something_error_message')}
                        </p>
                    </Fragment>
                }
            </div>
        </div>
    )
}