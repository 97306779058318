import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_FAV_PROJECTS_LOADING,
  GET_FAV_PROJECTS_SUCCESS,
  GET_FAV_PROJECTS_FAIL,
  GET_FAV_SERVICES_LOADING,
  GET_FAV_SERVICES_SUCCESS,
  GET_FAV_SERVICES_FAIL,
  GET_FAV_CONTESTS_LOADING,
  GET_FAV_CONTESTS_SUCCESS,
  GET_FAV_CONTESTS_FAIL,
} = TYPES;

// Get Favourite Projects

export function getFavouriteProjects(morphType = null, locale, page = null, limit = null) {
  return async function getFavouriteProjectsWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    if(morphType === "projects"){
      dispatch({
          type: GET_FAV_PROJECTS_LOADING
      });
    }else if(morphType === "services"){
      dispatch({
        type: GET_FAV_SERVICES_LOADING
      });
    }else if(morphType === "contests"){
      dispatch({
        type: GET_FAV_CONTESTS_LOADING
      });
    }
    try {
        let response = await axiosInstance
        .get(API.FAVOURITE, {params: { morphType, page, limit } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            if(morphType === "projects"){
              dispatch({
                type: GET_FAV_PROJECTS_FAIL,
              });
            }else if(morphType === "services"){
              dispatch({
                type: GET_FAV_SERVICES_FAIL,
            });
            }else if(morphType === "contests"){
              dispatch({
                type: GET_FAV_CONTESTS_FAIL,
              });
            }
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          if (morphType === "projects") {
            dispatch({
                type: GET_FAV_PROJECTS_SUCCESS,
                payload: response.data
            })
          } else if (morphType === "services") {
            dispatch({
              type: GET_FAV_SERVICES_SUCCESS,
              payload: response.data
            })
          } else if (morphType === "contests"){
            dispatch({
              type: GET_FAV_CONTESTS_SUCCESS,
              payload: response.data
            })
          }
        }
    } catch (error) {
        if (error.response && error.response.data) {
          if(morphType === "projects"){
            dispatch({
              type: GET_FAV_PROJECTS_FAIL
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_FAV_PROJECTS_FAIL'));
          }else if(morphType === "services"){
            dispatch ({ 
              type: GET_FAV_SERVICES_FAIL 
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_FAV_SERVICES_FAIL'));
          }else if(morphType === "contests"){
            dispatch({
              type: GET_FAV_CONTESTS_FAIL
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_FAV_CONTESTS_FAIL'));
          }
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          if(morphType === "projects"){
            dispatch({
              type: GET_FAV_PROJECTS_FAIL
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FAV_PROJECTS_FAIL'));
          }else if(morphType === "services"){
            dispatch ({ 
              type: GET_FAV_SERVICES_FAIL 
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FAV_SERVICES_FAIL'));
          }else if(morphType === "contests"){
            dispatch({
              type: GET_FAV_CONTESTS_FAIL
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_FAV_CONTESTS_FAIL'));
          }
        }
    }
  }
}
