import {
    REPORTS_LOADING,
    REPORTS_SUCCESS, 
    REPORTS_FAIL,
    POST_REPORT_SUCCESS,
    POST_REPORT_FAIL,
    POST_REPORT_LOADING,
    POST_REPORT_RESET
} from '../actions/types';

const initialState = {
    isLoading: false,
    reportTypes: [],
    postReport: '',
}

export default function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case REPORTS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case REPORTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                reportTypes: action.payload.data
            };
        case REPORTS_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case POST_REPORT_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case POST_REPORT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                postReport: action.payload.data
            };
        case POST_REPORT_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case POST_REPORT_RESET:
            return {
                ...state,
                isLoading: false,
                postReport: '',
            };
        default:
            return state;
    }
}