import TYPES, {CLEAR_LAST_NOTIFICATION, CLEAR_MARK_NOTIFICATION_READ_SUCCESS, CLEAR_INVITE_USER_SUCCESS, CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS} from "../actions/types";

const INITIAL_STATE = {
  // Notifications
  getNotificationsLoading: false,
  getNotificationsError: "",
  notifications: [],
  unread: 0,
  totalNotifications: 0,
  notificationsPerPage: 0,
  lastNotification: null,
  getAllNotificationsLoading: false,
  getAllNotificationsError: "",
  allNotifications: [],
  totalAllNotifications: 0,
  allNotificationsPerPage: 0,
  //Mark Notification as Read
  markNotificationError: "",
  markNotificationSuccess: false,
  // Invite User
  inviteUserLoading: false,
  inviteUserError: "",
  inviteUserSuccess: false,
  //Mark All Messages as Read
  loadingMarknotificationsRead: false,
  markNotificationsError: "",
  markNotificationsSuccess: false,
};

const {
  GET_NOTIFICATIONS_LOADING,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAIL,
  GET_LAST_NOTIFICATION_SUCCESS,
  GET_ALL_NOTIFICATIONS_LOADING,
  GET_ALL_NOTIFICATIONS_SUCCESS,
  GET_ALL_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATION_READ_SUCCESS,
  MARK_NOTIFICATION_READ_FAIL,
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAIL,
  MARK_ALL_NOTIFICATIONS_READ_LOADING,
  MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
  MARK_ALL_NOTIFICATIONS_READ_FAIL,
} = TYPES;

export default function projectsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // Get User Notifications
    case GET_NOTIFICATIONS_LOADING:
      return {
        ...state,
        getNotificationsLoading: true,
        getNotificationsError: "",
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getNotificationsLoading: false,
        getNotificationsError: "",
        notifications: action.payload.data,
        totalNotifications: action.payload.totalRecords,
        notificationsPerPage: action.payload.itemsPerPage,
        unread: action.payload.unread,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        getNotificationsLoading: false,
        getNotificationsError: action.error,
      };

    case GET_LAST_NOTIFICATION_SUCCESS:
      return{
        ...state,
        lastNotification: action.payload,
      };
    case CLEAR_LAST_NOTIFICATION:
      return{
        ...state,
        lastNotification: null,
      }
    
    // Get All Notifications
    case GET_ALL_NOTIFICATIONS_LOADING:
      return {
        ...state,
        getAllNotificationsLoading: true,
        getAllNotificationsError: "",
      };
    case GET_ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        getAllNotificationsLoading: false,
        getAllNotificationsError: "",
        allNotifications: action.payload.data,
        totalAllNotifications: action.payload.totalRecords,
        allNotificationsPerPage: action.payload.itemsPerPage,
      };
    case GET_ALL_NOTIFICATIONS_FAIL:
      return {
        ...state,
        getAllNotificationsLoading: false,
        getAllNotificationsError: action.error,
      };
    // Mark as Read
    case MARK_NOTIFICATION_READ_SUCCESS :
      return{
        ...state,
        markMessageSuccess: action.payload === "success"
      };
    case MARK_NOTIFICATION_READ_FAIL:
      return{
        ...state,
        markMessageError: action.error,
      }
    case CLEAR_MARK_NOTIFICATION_READ_SUCCESS:
      return{
        ...state,
        markMessageSuccess: false,
      }
    // Invite User
    case INVITE_USER_LOADING:
      return {
        ...state,
        inviteUserLoading: true,
        inviteUserError: "",
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        inviteUserLoading: false,
        inviteUserError: "",
        inviteUserSuccess: action.payload === "success",
      };
    case INVITE_USER_FAIL:
      return {
        ...state,
        inviteUserLoading: false,
        inviteUserError: action.error,
      };
    case CLEAR_INVITE_USER_SUCCESS:
      return{
        ...state,
        inviteUserSuccess: false,
      }
    // Mark All as Read
    case MARK_ALL_NOTIFICATIONS_READ_LOADING:
      return{
        ...state,
        loadingMarknotificationsRead: true,
        markNotificationsError: "",
      };
    case MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
      return{
        ...state,
        loadingMarknotificationsRead: false,
        markNotificationsSuccess: action.payload === "success"
      };
    case MARK_ALL_NOTIFICATIONS_READ_FAIL:
      return{
        ...state,
        loadingMarknotificationsRead: false,
        markNotificationsError: action.error,
      }
    case CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS:
      return{
        ...state,
        markNotificationsSuccess: false,
      }
      
    default:
      return state;
  }
}
