import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { I18n } from "react-redux-i18n";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { useDispatch } from "react-redux";
import { clearErrors } from "../../actions/errorActions";

export default function DeleteModal(props) {
  const dispatch = useDispatch();
  const {
    modalTitle,
    loading,
    messageHeading,
    messageBody
  } = props;

  function handleHide() {
    props.onHide();
    dispatch(clearErrors());
  }
  function handleDelete(e) {
    e.preventDefault();
    props.onDelete();
  }

  const createCommentMarkup = (comment) => {
      return {__html: comment};
  }

  return (
    <Modal
      className={props.className}
      size={props.size}
      show={props.show}
      onHide={props.onHide}
      backdrop={props.backdrop}
      keyboard={props.keyboard}
      aria-labelledby={props["aria-labelledby"]}
    >
      <Form>
        <Modal.Header>
          <Modal.Title>
            {I18n.t(modalTitle)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className="text-center">{I18n.t(messageHeading)}</h4>
          <div dangerouslySetInnerHTML={createCommentMarkup(messageBody)} className="text-center"></div>
        </Modal.Body>

        <Modal.Footer>
          <ButtonWithLoading
          variant="danger"
          type="submit"
          onClick={handleDelete}
          data-backdrop="static"
          className="btn btn-danger"
          loading={loading ? loading : null}
          >
              {I18n.t('yes')}
          </ButtonWithLoading>
          <Button
            variant="secondary"
            onClick={handleHide}
            disabled={loading}
          >
            {I18n.t('no')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
