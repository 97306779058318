import { 
    ADD_CLIENT_REPLY_LOADING, 
    ADD_CLIENT_REPLY_SUCCESS, 
    ADD_CLIENT_REPLY_FAIL,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Add Portfolio Item

export const addclientreply = ({ reply, invoice_id, files }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: ADD_CLIENT_REPLY_LOADING
        });
        const body = { reply, invoice_id, files };
        try {
            let response = await axiosInstance.post(
                '/api/services/invoices/replies', 
                body, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: ADD_CLIENT_REPLY_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: ADD_CLIENT_REPLY_SUCCESS,
                    payload: response.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'ADD_CLIENT_REPLY_FAIL'));
                dispatch ({ 
                    type: ADD_CLIENT_REPLY_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_CLIENT_REPLY_FAIL')),
                    dispatch ({ 
                        type: ADD_CLIENT_REPLY_FAIL 
                    })
                )
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}