import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {NavLink} from 'react-router-dom';

const Navbar = (props) =>{
    const langSet = localStorage.getItem('lang');
    let tooltipDir = '';
    if (langSet){
        if (langSet === 'English'){
            tooltipDir = 'right';
        } else {
            tooltipDir = 'left';
        }
    }
    const {Links} = props;
    const {className} = props;
    const NavigationLinks = Links.map( SideLink =>{
        return(
            <li key={SideLink.id} >
                <OverlayTrigger
                    key={SideLink.title}
                    placement={tooltipDir}
                    overlay={
                    <Tooltip id={`tooltip-${SideLink.title}`}>
                        {SideLink.title}
                    </Tooltip>
                    }
                >
                    <NavLink exact to={SideLink.to}>
                    { SideLink.icon && <span className={SideLink.icon}></span> } 
                    {SideLink.txt}
                    </NavLink>
                </OverlayTrigger>
            </li> 
        )
    })
    return(
        <React.Fragment>
            <nav className={className}>
                {NavigationLinks} 
            </nav>
        </React.Fragment>
    )
}

export default Navbar;