import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
//import {Tabs,Tab} from 'react-bootstrap';
import { I18n } from "react-redux-i18n";
import { MetaComponent } from '../MetaComponent';
import { TitleComponent } from "../../components/TitleComponent";
import VerifyAccountModal from '../Popups/VerifyAccountModal';
import ConfToast from "../ConfToast";
import { RESET_VERIFICATION } from '../../actions/types';
import { loadUser } from '../../actions/authActions';
import { getUserData } from '../../redux/userData/actions/getActions';
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import { updateUserView } from '../../redux/userData/actions/addActions';
import { CLEAR_SET_USER_VIEW_SUCCESS } from '../../redux/userData/actions/types';


const Dashboard = () => {

    const dispatch = useDispatch();

    const {
        user,
        verifyLoading,
        verifySuccess,
        locale,
        setUserViewLoading,
        setUserViewSuccess
    } = useSelector((state) => ({
        user: state.userData.user,
        verifyLoading: state.verifyAccount.verifyLoading,
        verifySuccess: state.verifyAccount.verifySuccess,
        locale: state.i18n.locale,
        setUserViewLoading: state.userData.setUserViewLoading,
        setUserViewSuccess: state.userData.setUserViewSuccess,
    }));

    const [totalEarnings, setTotalEarnings] = useState(0);
    const [toggleVerificationModel, setToggleVerificationModel] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [haveMultipleAccount, setHaveMultipleAccount] = useState(false);
    const [id, setId] = useState(null);
    const [isClient, setIsClient] = useState(false);


    useEffect(() => {
        if(user && user.withdrawable_balances && user.pending_balances){
            setTotalEarnings(user.withdrawable_balances.balance + user.withdrawable_balances.withdrawals + user.pending_balances.PendingBalance);
        }
    },[user]);

    useEffect(() => {
        if(verifySuccess){
            setConfShow(true);
            setConfTitle(I18n.t('account_verification_title'));
            setConfMessage(I18n.t('account_verification_success'));
            setConfStatus('Toast__Container__Success');
            setDelay(6000);
            dispatch({
                type: RESET_VERIFICATION
            });
            dispatch(loadUser());
            dispatch(getUserData(locale));
        }
    }, [verifySuccess, dispatch, locale]);

    useEffect(() => {
        if(user && user.memberships.length > 1){
            setHaveMultipleAccount(true);
            user.memberships.map( item => {
                if(!item.default){
                    setId(item.pivot_id)
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if(user && user.memberships && user.memberships.length >= 1){
            user.memberships.map(item => {
                if((item.id === 3 || item.id === 4) && item.default){
                    return setIsClient(true);
                }else{
                    return setIsClient(false);
                }
            })
        }
    }, [user]);

    useEffect(() => {
        if(setUserViewSuccess){
            dispatch({
                type: CLEAR_SET_USER_VIEW_SUCCESS
            });
            dispatch(getUserData(locale));
        }
    }, [setUserViewSuccess, dispatch, locale]);

    function handleVerificationShow(){
        if(user && user.email){
            setToggleVerificationModel(true);
        }
    };

    function handleVerificationHide() {
        setToggleVerificationModel(false);
    }

    function onSubmit(e) {
        e.preventDefault();
        if(id){
            const itemData = {
                id
            };
            dispatch(updateUserView(itemData));
        }
    }

    function checkUserProfile(user){
        if(!user?.country || !user?.city || user?.categories?.lenght <= 0 || user?.sub_categories?.length <= 0 || user?.skills?.lenght <= 0){
            return true;
        }else{
            return false;
        }
    }

    return(
        <div className="container">
            <TitleComponent title={I18n.t('lancersin_dashboard')} />
            <MetaComponent />
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <VerifyAccountModal
                loading={verifyLoading}
                modalTitle={"verify_email_address"}
                className="CustomPopup"
                size="lg"
                show={toggleVerificationModel}
                onHide={handleVerificationHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            <div className="row">
                <div className="col-12">
                    <div className="section__title">
                        <div className="section__title__text">
                            <span className="icon icon-dashboard"></span>
                            {I18n.t('dashboard')}
                        </div>
                        {
                            haveMultipleAccount ?
                            <ButtonWithLoading
                                variant="success"
                                type="submit"
                                className="custom-btn"
                                onClick={onSubmit}
                                data-backdrop="static"
                                loading={setUserViewLoading ? setUserViewLoading : null}
                            >
                                {
                                    user && user.memberships.length > 0 ?
                                    user.memberships.map(item => {
                                        if(item.default){
                                            if(item.id === 3 || item.id === 4){
                                                return I18n.t("switch_to_freelancer")
                                            }else{
                                                return I18n.t("switch_to_client")
                                            }
                                        }
                                    })
                                    : null
                                }
                            </ButtonWithLoading>
                            : null
                        }
                    </div>
                </div>
                {
                    user && user.status ?
                        user.status.id === 3 ?
                            <div className="col-12">
                                <div className="alert alert-warning mb-2 d-flex flex-wrap justify-content-between align-items-center pb-0" role="alert">
                                    <span className='mb-2'>{I18n.t('email_not_verified') + " "}</span>
                                    <button className="btn btn-primary btn-sm mb-2" onClick={() => {handleVerificationShow();}}><span className="far fa-envelope"></span>{I18n.t('verify_email_address')}</button>
                                </div>
                            </div>
                        : null
                    :null
                }
                {
                    checkUserProfile(user) ?
                        <div className="col-12">
                            <div className="alert alert-warning mb-4 d-flex flex-wrap justify-content-between align-items-center pb-0" role="alert">
                                <span className='mb-2'>{I18n.t('watch_profile_video') + " "}</span>
                                <a href='https://youtu.be/ZnRIFPnNGQU' target="_blank" rel="noopener noreferrer" className="videoBTn btn-success btn-sm mb-2"><i className="fas fa-play"></i> {I18n.t('watch_video')}</a>
                            </div>
                        </div>
                    : null
                }
            </div>
            <div className="row">
                {
                    isClient ?
                    <div className="col-md-12">
                        <div className="total__earnings">
                            <div className="total__earnings__total">
                                <span className="icon icon-budget"></span>
                                <h2>{I18n.t('total_balance')}</h2>
                                
                                <span className="total"> { user && user.withdrawable_balances.refunded_balances ? user.withdrawable_balances.refunded_balances : 0 } {I18n.t('dollar')}</span>
                            </div>
                            <div className="total__earnings__desc">
                                <h2>{I18n.t('refund_balance')}</h2>
                                <span className="total">{ user && user.withdrawable_balances.refunded_balances ? user.withdrawable_balances.refunded_balances : 0} {I18n.t('dollar')}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-md-12">
                        <div className="total__earnings">
                            <div className="total__earnings__total">
                                <span className="icon icon-budget"></span>
                                <h2>{I18n.t('total_earnings')}</h2>
                                
                                <span className="total"> { totalEarnings ? totalEarnings : 0} {I18n.t('dollar')}</span>
                            </div>
                            <div className="total__earnings__desc">
                                <h2>{I18n.t('withdrawn_request')}</h2>
                                <span className="total">{ user && user.withdrawable_balances.balance ? user.withdrawable_balances.balance : 0} {I18n.t('dollar')}</span>
                                <h2>{I18n.t('pending_amount')}</h2>
                                <span className="total">{ user && user.pending_balances.PendingBalance ? user.pending_balances.PendingBalance : 0} {I18n.t('dollar')}</span>
                            </div>
                        </div>
                    </div>
                }
                {
                    isClient ?
                    <div className="col-md-6">
                        <div className="total__projects">
                            <div className="total__projects__total">
                                <span className="icon icon-projects"></span>
                                <h2>{I18n.t('total_projects')}</h2>
                                <span className="total">{ user && user.projectsPosted ?  user.projectsPosted : 0}</span>
                            </div>
                            <div className="total__projects__desc">
                                <h2>{I18n.t('projects_done')}</h2>
                                <span className="total">{ user && user.projectsHasBidCompleted ? user.projectsHasBidCompleted : 0}</span>
                                <h2>{I18n.t('running_projects')}</h2>
                                <span className="total">{ user && user.projectsHasBidRunning >= 0 && user.projectsPaid && user.projectsPosted  ? ((user.projectsPosted - user.projectsPaid) + user.projectsHasBidRunning) : 0}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-md-6">
                        <div className="total__projects">
                            <div className="total__projects__total">
                                <span className="icon icon-projects"></span>
                                <h2>{I18n.t('total_projects')}</h2>
                                <span className="total">{ user && user.bids ? (user.bids.running + user.bids.completed) : 0}</span>
                            </div>
                            <div className="total__projects__desc">
                                <h2>{I18n.t('projects_done')}</h2>
                                <span className="total">{ user && user.bids ? user.bids.completed : 0}</span>
                                <h2>{I18n.t('running_projects')}</h2>
                                <span className="total">{ user && user.bids ? user.bids.running : 0}</span>
                            </div>
                        </div>
                    </div>
                }
                {/*<div className="col-md-6">
                    <div className="total__projects">
                        <div className="total__projects__total">
                            <span className="icon icon-contests"></span>
                            <h2>{I18n.t('total_contests')}</h2>
                            <span className="total">0</span>
                        </div>
                        <div className="total__projects__desc">
                            <h2>{I18n.t('contests_done')}</h2>
                            <span className="total">0</span>
                            <h2>{I18n.t('running_contests')}</h2>
                            <span className="total">0</span>
                        </div>
                    </div>
                </div>*/}
                <div className="col-md-6">
                    <div className="total__earnings">
                        <div className="total__earnings__total">
                            <span className="icon icon-services"></span>
                            <h2>{I18n.t('total_services')}</h2>
                            <span className="total">{ user && user.servicesCompleted >=0 && user.servicesRunning >= 0 ? user.servicesCompleted + user.servicesRunning : 0}</span>
                        </div>
                        <div className="total__earnings__desc">
                            <h2>{I18n.t('services_done')}</h2>
                            <span className="total">{ user && user.servicesCompleted ? user.servicesCompleted : 0}</span>
                            <h2>{I18n.t('running_services')}</h2>
                            <span className="total">{ user && user.servicesRunning ? user.servicesRunning : 0}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="section__title">
                        <div className="section__title__text">
                            <span className="icon icon-membership"></span>
                            {I18n.t('free_membership')}
                        </div>
                        {/*<a href="/test"><span className="icon icon-upgrade"></span>Upgrade</a>*/}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="proposals__stats">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="proposals__stats__container">
                                    <span className="icon icon-proposals-sent"></span>
                                    <h2>{I18n.t('proposals_sent')}</h2>
                                    <div className="proposals__stats__desc"><span>{user && user.bids && Object.keys(user.bids).length > 0 ? user.bids.consumed : 0}</span></div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="proposals__stats__container">
                                    <span className="icon icon-proposals-limit"></span>
                                    <h2>{I18n.t('proposals_limit')}</h2>
                                    <div className="proposals__stats__desc"><span>{user && user.bids && Object.keys(user.bids).length > 0 ? user.bids.consumed + user.bids.remaining : 0}</span>{" " + I18n.t('monthly')}</div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="proposals__stats__container">
                                    <span className="icon icon-proposals-renewal"></span>
                                    <h2>{I18n.t('proposals_renewal')}</h2>
                                    <div className="proposals__stats__desc">{user && user.bids && Object.keys(user.bids).length > 0 ? user.bids.renew_date : '--'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*
            <div className="row">
                <div className="col-12 running__tasks">
                    <Tabs defaultActiveKey="RunningProjects" id="JoinedStats">
                        <Tab eventKey="RunningProjects" title="Running Projects">
                            <div className="row">
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="RunningContests" title="Running Contests">
                            <div className="row">
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="RunningServices" title="Running Services">
                            <div className="row">
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="Dashboard__Project">
                                        <h2><a href="/test">Branding Expert to create a brand from scratch</a></h2>
                                        <p>
                                        I'm looking for a branding expert to help me create the branding from scratch for a new company. This includes: Name of the company Logo Corporate Image kit Website design (but not necessarily the programming)
                                        </p>
                                        <div className="Dashboard__Project__Owner">
                                            <div className="Owner__Name"><span className="icon icon-user"></span>Nada Ali</div>
                                            <div className="Owner__Name">Rating Replaced Here</div>
                                            <div className="Owner__Name"><span className="icon icon-location"></span>Egypt</div>
                                            <div className="Owner__Name"><span className="icon icon-budget"></span>300$ Spent</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            */}
        </div>
    )
}

export default Dashboard;