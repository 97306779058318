import React, { useEffect, Fragment, useState } from "react";
import {Card} from "react-bootstrap";
import CustomButton from "./../Buttons/Button";
import { getArticleById } from "../../redux/article/actions/getActions";
import { deleteArticle } from "../../redux/article/actions/deleteActions";
import { useDispatch, useSelector } from "react-redux";
import Spin from "../Spin";
import AddingArticleModal from "../AddingArticleModal";
import DeleteModal from "../DeleteModal";
import { Redirect } from "react-router";
import ErrorBox from "../ErrorBox";
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import RatingStarsPreview from "../RatingStarsPreview";
import ConfToast from "../ConfToast";
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";
import StatusLabel from "../../StatusLabel";
import NoPermisions from "../NoPermissions";
import ReportContentModal from "../ReportContentModal";
import ConnectionLost from "../ConnectionLost";

export default function ArticleDetails(props) {
  const dispatch = useDispatch();
  var {
    // article
    currentArticle: article,
    getArticleByIdLoading,
    getArticleByIdError,

    // Delete article
    deleteArticleLoading,
    deleteArticleError,

    // Edit article
    editingArticleLoading,
    editingArticleSuccess,
    editingArticleError,

    loggedUser,
    userLoading,

    postReportLoading,
    errorId,
    errorMessage,
    locale,
  } = useSelector((state) => ({
    currentArticle: state.article.currentArticle,
    getArticleByIdLoading: state.article.getArticleByIdLoading,
    getArticleByIdError: state.article.getArticleByIdError,

    deleteArticleLoading: state.article.deleteArticleLoading,
    deleteArticleError: state.article.deleteArticleError,

    editingArticleLoading: state.article.editingArticleLoading,
    editingArticleSuccess: state.article.editingArticleSuccess,
    editingArticleError: state.article.editingArticleError,

    loggedUser: state.auth.user,
    userLoading: state.auth.isLoading,

    postReportLoading: state.reportsTypes.isLoading,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    locale: state.i18n.locale,
  }));

  const [currentArticle, setCurrentArticle] = useState(article);
  const [redirect, setRedirect] = useState(false);
  const [articleId, setArticleId] = useState();
  const [isOwnerOfThisArticle, setIsOwnerOfThisArticle] = useState(false);
  const [articleDetails, setArticleDetails] = useState(null);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [articleLoading, setArticleLoading] = useState(true);
  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = () => setShowDeleteConfirm(true);

  useEffect(() => {
    setArticleLoading(getArticleByIdLoading);
  }, [getArticleByIdLoading]);

  useEffect(() => {
    if (article) {
      setCurrentArticle(article);
    }
  }, [article]);

  const { id } = props.match.params;

  useEffect(() => {
    if(id){
      const ItemData = {
        id,
        locale
      };
      dispatch(getArticleById(ItemData));
      setArticleId(id);
    }
  }, [id, dispatch, locale] );

  useEffect(() => {
    if(editingArticleSuccess && articleLoading){
      setToggleEditModal(!toggleEditModal);
      setTimeout(() => {
        setConfShow(true);
        setConfTitle(I18n.t('edit_article'));
        setConfMessage(I18n.t('edit_article_success'));
        setConfStatus('Toast__Container__Success');
        setDelay(6000);
      }, 3000);
    }
  }, [editingArticleSuccess, articleLoading, toggleEditModal]);

  useEffect(() => {
    if (currentArticle) {
      setArticleDetails(currentArticle);
    }
  }, [currentArticle]);

  useEffect(() => {
    if (loggedUser?.id && article?.user?.id) {
      setIsOwnerOfThisArticle(loggedUser.id === article.user.id);
    }
  }, [loggedUser, article]);

  function handleEditOnClick() {
    setToggleEditModal(!toggleEditModal);
  }

  function handleDeleteOnClick() {
    dispatch(deleteArticle({ id: articleId }));
    setRedirect(!redirect);
  }

  function handleShowReport() {
    setShowReport(true);
  };
  function handleHideReport() {
      setShowReport(false);
  };

  if (redirect) {
    return <Redirect to="/dashboard/articles" />;
  }

  if (getArticleByIdError && !articleLoading) {
    return (
      <div>
        <ErrorBox message={getArticleByIdError} />
        <Link to="/articles">Go To Articles</Link>
      </div>
    );
  }

  if (deleteArticleError && !deleteArticleLoading) {
    return (
      <div>
        <ErrorBox message={deleteArticleError} />
      </div>
    );
  }

  if (editingArticleError && !editingArticleLoading) {
    return (
      <div>
        <ErrorBox message={editingArticleError} />
      </div>
    );
  }

  if (articleLoading || !articleDetails || userLoading) return <Spin />;

  if (articleDetails) {
    var {
      title,
      description,
      category,
      sub_category,
      status,
      user,
      created_at,
      updated_at,
      keywords,
      image
    } = articleDetails;
  }

  const createMarkup = (text) => {
    return { __html: text };
  };

  function stripHtml(html){
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html.replace(/&nbsp;/g, '');
    return tmp.textContent || tmp.innerText || "";
  }

  return (
    <Fragment>
      <TitleComponent title={I18n.t('lancersin_articles') + " - " + title.substring(0, 35)} />
      <MetaComponent keywords={keywords && keywords.length >= 3 ? keywords : title} description={stripHtml(description).substring(0, 150)} />
      <ConfToast
      showConf={confShow}
      setShowConf={setConfShow}
      title={confTitle}
      message={confMessage}
      status={confStatus}
      delay={delay}
      />
      <DeleteModal
        onHide={handleDeleteConfirmClose}
        onDelete={handleDeleteOnClick}
        show={showDeleteConfirm}
        modalTitle={"delete_article"}
        className="CustomPopup"
        size="md"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={deleteArticleLoading}
        messageHeading="delete_conf"
        messageBody={title}
      />
      <AddingArticleModal
        articleDetails={articleDetails}
        onSubmit={setCurrentArticle}
        onHide={handleEditOnClick}
        show={toggleEditModal}
        modalTitle={"edit_article"}
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={editingArticleLoading}
        params={props.match.params}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <ReportContentModal
        onHide={handleHideReport}
        show={showReport}
        className="CustomPopup"
        size="lg"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={postReportLoading}
        model="articles"
        model_id={articleId}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      {
        errorId === "GET_ARTICLE_BY_ID_FAIL" && errorMessage === "Network Error" ?
        <div className="col-12">
            <ConnectionLost
              errorType= "connection"
              title = "network_error_title"
              message = "network_error_message"
            />
        </div>
        : errorId === "GET_ARTICLE_BY_ID_FAIL" ?
        <div className="col-12">
            <ConnectionLost
              errorType= "somethingWrong"
              title = "something_error_title"
              message = "something_error_message"
            />
        </div>
        : status.id === 2 && !isOwnerOfThisArticle ?
        <NoPermisions />
        :
        <Fragment>
          <div className="breadcrumb__container">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                    <li className="breadcrumb-item"><Link to="/articles">{I18n.t('articles')}</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container ProjectDetails">
            <div className="Profile-Card box-shadow">
              <div className="row">
                <div className="col-md-9">
                  <div className="ProfileData">
                    <div className="ProfileContent">
                      <div className="Title__Status">
                        <h1 className="ProfileName">
                          {title}
                          <StatusLabel status_id={status ? status.id : null} type="project" />
                        </h1>
                      </div>
                      <div className="Projects__List__LeftSide ProjectDetails__ProjectData">
                        {
                          category ?
                          <div className="Projects__List__Budget">
                            <div className="Projects__List__Budget__Title">
                            <h2>{I18n.t('category')}</h2>
                            </div>
                            <div className="Projects__List__Budget__Desc">
                              {category.title}
                            </div>
                          </div>
                          : null
                        }
                        {
                          sub_category ?
                          <div className="Projects__List__Budget">
                            <div className="Projects__List__Budget__Title">
                              <h4>{I18n.t('sub_category')}</h4>
                            </div>
                            <div className="Projects__List__Budget__Desc">
                              {sub_category.title}
                            </div>
                          </div>
                          : null
                        }
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            {I18n.t('created_at')}
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {`${created_at.Date} - ${created_at.Time}`}
                          </div>
                        </div>
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            {I18n.t('updated_at')}
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {`${updated_at.Date} - ${updated_at.Time}`}
                          </div>
                        </div>
                      </div>
                      <div className="Dashboard__Project__Owner">
                        <div className="ProfileImage">
                          <figure>
                            <img src={user.image} alt="" className="img-fluid" />
                          </figure>
                        </div>
                        <div className="d-inline-flex flex-column">
                          <h3 className="ProfileTitle">
                            <Link to={{ pathname: `/in/${user.username}` }}>
                              {user.fname} {user.lname}
                            </Link>
                          </h3>
                          <div className="Owner__Name mb-0">
                            <RatingStarsPreview rate={user.rate} />
                          </div>
                        </div>
                        {
                          user.country && user.city ?
                          <div className="LocationTime">
                            <address>
                              <span className="LocationFlag">
                                <img
                                  src={user.country.flag}
                                  alt={user.country.name}
                                />
                              </span>
                              <span className="Location">
                                {user.city.name}, {user.country.name}
                              </span>
                            </address>
                          </div>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center justify-content-center flex-column h-100">
                    {(isOwnerOfThisArticle === true) ? (
                      <Fragment>
                        <CustomButton
                          parentClassName="FollowUser"
                          iconClassName="icon icon-edit"
                          text="edit_article"
                          onClick={handleEditOnClick}
                        />
                        <CustomButton
                          parentClassName="DeleteItem"
                          iconClassName="far fa-trash-alt"
                          text="delete_article"
                          onClick={handleDeleteConfirmShow}
                        />
                      </Fragment>
                    ) : null}
                    {!isOwnerOfThisArticle ?
                      <Fragment>
                        <CustomButton
                          parentClassName="FollowUser"
                          iconClassName="icon icon-heart"
                          text={I18n.t('add_to_favourite')}
                        />
                        <CustomButton
                          parentClassName="InviteContest"
                          iconClassName="far fa-flag"
                          text={I18n.t('report_content')}
                          onClick={() => handleShowReport()}
                        />
                      </Fragment>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <Card>
              <Card.Body>
              <div className="d-flex justify-content-center">
                <img 
                src={image.url ? image.url : image} 
                alt={title}
                className="img-fluid mb-4" 
                />
              </div>
              <div
                dangerouslySetInnerHTML={createMarkup(
                  description
                )}
                className="listing__description"
              ></div>
              </Card.Body>
            </Card> 
          </div>
        </Fragment>
      }
    </Fragment>
  );
}
