import React from 'react';
import Helmet from 'react-helmet';
import { I18n } from 'react-redux-i18n';

const TitleComponent = ({ title }) => {
    return (
        <Helmet>
            <title>{title ? title : I18n.t('lancersin_tech')}</title>
        </Helmet>
    );
};

export { TitleComponent };