import React, { Fragment } from "react";
import { useLayer, Arrow } from "react-laag";
import { motion, AnimatePresence } from "framer-motion";
import {Link} from 'react-router-dom';
import { I18n } from "react-redux-i18n";

export default function DropdownMenu(props) {
  //const [isOpen, setOpen] = React.useState(false);

  const {
    children,
    buttonIcon,
    buttonText,
    newMessage,
    buttonClassName,
    dropDownClassName,
    hasViewAll,
    viewAllClassName,
    viewAllPath,
    viewAllText,
    viewAllIcon,
    viewAllIconClass,
    onClickFun,
    unread,
    isOpen,
    setOpen
  } = props;

  // helper function to close the menu
  function close() {
    setOpen(false);
  }

  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    //onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: "top-end", // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16 // let the arrow have some room to breath also
  });

  // Again, we're using framer-motion for the transition effect
  return (
    <Fragment>
      <button {...triggerProps} onClick={onClickFun ? () => {setOpen(!isOpen); onClickFun()} : () => setOpen(!isOpen)} className={buttonClassName ? buttonClassName : null}>
        {buttonIcon ? <i className={buttonIcon}></i> : null}
        {buttonText ? buttonText : null}
        {
            newMessage ?
            <small>{unread ? (unread + 1) > 99 ? "99+" : unread + 1 : 1}</small>
            : unread && unread > 0 ? 
            <small>{unread > 99 ? "99+" : unread}</small>
            : null
        }
      </button>
      {renderLayer(
        <AnimatePresence>
          {isOpen && (
            <motion.ul {...layerProps} className={`${dropDownClassName ? dropDownClassName : null} ${hasViewAll ? viewAllClassName : null}`} onClick={() => setOpen(!isOpen)}>
              {
                  children ?
                  children
                  : null
              }
              {
                hasViewAll ?
                <Link exact="true" to={{ pathname: `${viewAllPath}` }} className="dropdownViewAllLink">{viewAllIcon ? <span className={viewAllIconClass}></span> : null}{I18n.t(viewAllText)}</Link>
                : null
              }
              <Arrow {...arrowProps} />
            </motion.ul>
          )}
        </AnimatePresence>
      )}
    </Fragment>
  );
}