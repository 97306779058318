import {
    ADD_SERVICE_LOADING,
    ADD_SERVICE_SUCCESS,
    ADD_SERVICE_FAIL,
    CLEAR_ADD_SERVICE_SUCCESS,
    EDIT_SERVICE_LOADING,
    EDIT_SERVICE_SUCCESS,
    EDIT_SERVICE_FAIL,
    CLEAR_EDIT_SERVICE_SUCCESS,
} from '../actions/types';

const initialState = {
    isLoading: false,
    serviceItem: {},
    addServiceSuccess: false,
    editServiceSuccess: false,
}

export default function addserviceitemReducer(state = initialState, action) {
    switch (action.type) {
        case ADD_SERVICE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case ADD_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceItem: action.payload,
                addServiceSuccess: action.status === "success"
            };
        case ADD_SERVICE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case EDIT_SERVICE_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case EDIT_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                serviceItem: action.payload,
                editServiceSuccess: action.status === "success"
            };
        case EDIT_SERVICE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case CLEAR_ADD_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                addServiceSuccess: false,
            };
        case CLEAR_EDIT_SERVICE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                editServiceSuccess: false,
            };
        default:
            return state;
    }
}