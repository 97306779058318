// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_MESSAGES_LOADING,
  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,
  GET_ALL_MESSAGES_LOADING,
  GET_ALL_MESSAGES_SUCCESS,
  GET_ALL_MESSAGES_FAIL,
  GET_REPLIES_LOADING,
  GET_REPLIES_SUCCESS,
  GET_REPLIES_FAIL,
  GET_MESSAGE_DETAILS_LOADING,
  GET_MESSAGE_DETAILS_SUCCESS,
  GET_MESSAGE_DETAILS_FAIL,
  MARK_MESSAGE_READ_LOADING,
  MARK_MESSAGE_READ_SUCCESS,
  MARK_MESSAGE_READ_FAIL,
  MARK_ALL_MESSAGES_READ_LOADING,
  MARK_ALL_MESSAGES_READ_SUCCESS,
  MARK_ALL_MESSAGES_READ_FAIL,
} = TYPES;

// Get User Messages

export function getMessages(id = null, page = null, limit = null, locale) {
  return async function getMessagesWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: GET_MESSAGES_LOADING
    });
    try {
        let response = await axiosInstance
        .get(API.MESSAGES, {params: { id, page, limit } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            dispatch({
              type: GET_MESSAGES_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_MESSAGES_SUCCESS,
            payload: response.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: GET_MESSAGES_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_MESSAGES_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: GET_MESSAGES_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_MESSAGES_FAIL'));
        }
    }
  }
}

// Get All Messages

export function getAllMessages(id = null, page = null, limit = null, locale) {
  return async function getAllMessagesWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    if(id){
      dispatch({
        type: GET_MESSAGE_DETAILS_LOADING
      });
    }else{
      dispatch({
        type: GET_ALL_MESSAGES_LOADING
      });
    }
    try {
        let response = await axiosInstance
        .get(API.MESSAGES, {params: { id, page, limit } , ...tokenConfig(getState, locale)});
        if(id){
          if(response.status !== 200){
            dispatch({
              type: GET_MESSAGE_DETAILS_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
          }else{
            dispatch({
              type: GET_MESSAGE_DETAILS_SUCCESS,
              payload: response.data
            })
          }
        }else{
          if(response.status !== 200){
            dispatch({
              type: GET_ALL_MESSAGES_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
          }else{
            dispatch({
              type: GET_ALL_MESSAGES_SUCCESS,
              payload: response.data
            })
          }
        }
    } catch (error) {
        if(id){
          if (error.response && error.response.data) {
            dispatch({
              type: GET_MESSAGE_DETAILS_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_MESSAGE_DETAILS_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            dispatch({
              type: GET_MESSAGE_DETAILS_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_MESSAGE_DETAILS_FAIL'));
          }
        }else{
          if (error.response && error.response.data) {
            dispatch({
              type: GET_ALL_MESSAGES_FAIL,
            });
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_ALL_MESSAGES_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            dispatch({
              type: GET_ALL_MESSAGES_FAIL,
            });
            dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ALL_MESSAGES_FAIL'));
          }
        }
    }
  }
}

// Get Message Replies
export function getReplies(conversation_id = null, page = null, limit = null, locale) {
  return async function getRepliesWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: GET_REPLIES_LOADING
    });
    try {
        let response = await axiosInstance
        .get(API.REPLIES, {params: { conversation_id, page, limit } , ...tokenConfig(getState, locale)});
        if(response.status !== 200){
            dispatch({
              type: GET_REPLIES_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: GET_REPLIES_SUCCESS,
            payload: response.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: GET_REPLIES_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'GET_REPLIES_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: GET_REPLIES_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_REPLIES_FAIL'));
        }
    }
  }
}

// Mark Message As Read

export function markMessageAsRead(id = null) {
  return async function markAsReadWithDispatch(dispatch, getState){
    dispatch({
      type: MARK_MESSAGE_READ_LOADING
    });
    try {
        let response = await axiosInstance
        .get(`${API.READ + "/" + id}` ,{...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
              type: MARK_MESSAGE_READ_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: MARK_MESSAGE_READ_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: MARK_MESSAGE_READ_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'MARK_MESSAGE_READ_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: MARK_MESSAGE_READ_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'MARK_MESSAGE_READ_FAIL'));
        }
    }
  }
}

// Mark All Messages As Read

export function markAllMessageAsRead() {
  return async function markAllMessageAsReadWithDispatch(dispatch, getState){
    dispatch({
      type: MARK_ALL_MESSAGES_READ_LOADING
    });
    try {
        let response = await axiosInstance
        .get(`${API.READ}` ,{...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
              type: MARK_ALL_MESSAGES_READ_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: MARK_ALL_MESSAGES_READ_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: MARK_ALL_MESSAGES_READ_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'MARK_ALL_MESSAGES_READ_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: MARK_ALL_MESSAGES_READ_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'MARK_ALL_MESSAGES_READ_FAIL'));
        }
    }
  }
}