import React from 'react';
import Helmet from 'react-helmet';
import { I18n } from 'react-redux-i18n';
import { WebsiteLink } from './Server';


const MetaComponent = ({ keywords, description }) => {
    var defaultKeywords = I18n.t('site_keywords');
    return (
        <Helmet>
            <meta name="keywords" content={ keywords ? keywords : defaultKeywords}></meta>
            <meta name="description" content={description ? description : I18n.t('site_description')}></meta>
            <link rel="alternate" type="application/rss+xml" title="Lancersin Projects | Services | Freelancers | مشاريع لانسرزان | خدمات لانسرزان | المستقلين" href={`${WebsiteLink}/rss`}></link>
        </Helmet>
    );
};

export { MetaComponent };