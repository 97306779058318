import {
    DELETE_FILE_LOADING,
    DELETE_FILE_SUCCESS, 
    DELETE_FILE_FAIL
} from '../actions/types';

const initialState = {
    isLoading: false,
    deleteFile: '',
}

export default function deletefileReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_FILE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                portfolioItem: action.payload.data
            };
        case DELETE_FILE_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}