import { 
    RESET_PASSWORD_LOADING,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    RESET_VERIFICATION
} from "../actions/types";

const initialState = {
    resetPasswordSuccess: "",
    resetPasswordLoading: false,
}

export default function resetpasswordReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_LOADING: 
            return{
                ...state,
                resetPasswordLoading: true
            }
        ;
        case RESET_PASSWORD_SUCCESS:
            return{
                ...state,
                resetPasswordSuccess: action.payload.data,
                resetPasswordLoading: false
            }    
        ;
        case RESET_PASSWORD_FAIL:
            return{
                ...state,
                resetPasswordLoading: false
            }
        ;
        case RESET_VERIFICATION:
            return{
                ...state,
                resetPasswordSuccess: "",
            }
        ;
        default:
            return state;
    }
}