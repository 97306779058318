import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_LOADING,
    REGISTER_SUCCESS,
    CLEAR_REGISTER,
    REGISTER_FAIL,
    VERIFY_ACCOUNT_LOADING,
} from '../actions/types';

const initialState = {
    token: typeof window !== 'undefined' && localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: false,
    loginLoading: false,
    registerLoading: false,
    user: {},
    userFname: typeof window !== 'undefined' && localStorage.getItem('loggeduser'),
    userImage: typeof window !== 'undefined' && localStorage.getItem('loggeduserImage'),
    registerSuccess: false
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload.data
            };
        case LOGIN_LOADING:
            return{
                ...state,
                loginLoading: true,
            };
        case REGISTER_LOADING:
            return{
                ...state,
                registerLoading: true,
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('loggeduser', action.payload.data.fname);
            localStorage.setItem('loggeduserImage', action.payload.data.image.url ? action.payload.data.image.url : action.payload.data.image);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                isLoading: false,
                loginLoading: false,
                registerLoading: false,
                user: action.payload.data,
                userFname: action.payload.data.fname,
                userImage: action.payload.data.image.url ? action.payload.data.image.url : action.payload.data.image,
            };
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('loggeduser', action.payload.data.fname);
            localStorage.setItem('loggeduserImage', action.payload.data.image.url ? action.payload.data.image.url : action.payload.data.image);
            return {
                ...state,
                token: action.payload.token,
                isAuthenticated: true,
                isLoading: false,
                loginLoading: false,
                registerLoading: false,
                user: action.payload.data,
                userFname: action.payload.data.fname,
                userImage: action.payload.data.image.url ? action.payload.data.image.url : action.payload.data.image,
                registerSuccess: true
            };
        case CLEAR_REGISTER:
            return{
                ...state,
                registerSuccess: false
            };
        case VERIFY_ACCOUNT_LOADING:
            return{
                ...state,
                registerSuccess: false
            };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            localStorage.removeItem('loggeduser');
            localStorage.removeItem('loggeduserImage');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
                loginLoading: false,
                registerLoading: false,
                user: {},
                userFname: null,
                userImage: null,
            };
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');
            localStorage.removeItem('loggeduser');
            localStorage.removeItem('loggeduserImage');
            localStorage.removeItem('referrer');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                isLoading: false,
                loginLoading: false,
                registerLoading: false,
                user: {},
                userFname: null,
                userImage: null,
                registerSuccess: false,
            };
        default:
            return state;
    }
}