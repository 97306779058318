import React from "react";

export default function ConfToast({
    title = "",
    time = null,
    message = "",
    status = null,
    delay = null,
    showConf,
    setShowConf
    }) {
    function autohide(delay){
        if(delay){
            setTimeout(() => {
                setShowConf(false);
            }, delay);
        }
    }
    return(
        <div className={ status ? "Toast__Container " + status : "Toast__Container"}>
            {autohide(delay)}
            <div bg="light" className={ showConf ? "fade toast m-1 Toast__Show" : "fade toast m-1 Toast__Hide"} role="alert" aria-live="assertive" aria-atomic="true">
                {
                    status ?    
                    <div className="Toast__Icon">
                        {
                            status === "Toast__Container__Success" ? 
                            <i className="fas fa-check-circle"></i> : 
                            status === "Toast__Container__Warning" ? 
                            <i className="fas fa-exclamation-circle"></i> : 
                            status === "Toast__Container__Error" ? 
                            <i className="fas fa-times-circle"></i> : 
                            null
                        } 
                    </div>
                    : null
                }
                <div className="Toast__Content">
                    <div className="toast-header">
                        <strong className="me-auto">{title}</strong>
                        <div>
                        {
                            time ? <small>{time}</small> : null
                        }
                        <button type="button" className="close ml-2 mb-1" onClick={() => setShowConf(false)}>
                            <span aria-hidden="true">×</span><span className="sr-only">Close</span>
                        </button>
                        </div>
                    </div>
                    <div className="toast-body">{message}</div>
                </div>
            </div>
        </div>
    );
}