import { 
    ALL_SERVICES_ITEMS_LOADING,
    GET_ALL_SERVICES_SUCCESS, 
    GET_ALL_SERVICES_FAIL,
    GET_RELATED_SERVICES_LOADING,
    GET_RELATED_SERVICES_SUCCESS, 
    GET_RELATED_SERVICES_FAIL, 
    GET_USER_SERVICES_LOADING,
    GET_USER_SERVICES_SUCCESS,
    GET_USER_SERVICES_FAIL,
    CLEAR_ALL_SERVICES
} from '../actions/types';

const initialState = {
    allservices: [],
    userservices: {
        services: [],
        totalRecords: 0,
        itemsPerPage: 0,
        loadingUserServices: false
    },
    relatedservices: {
        relatedServices: [],
        totalRecords: 0,
        itemsPerPage: 0,
        loadingRelatedServices: false
    },
    totalRecords: 0,
    itemsPerPage: 0,
    loadingallservices: false
};

const { userservices, relatedservices } = initialState;

export default function servicesReducer(state = initialState, action) {
    switch (action.type) {
        case ALL_SERVICES_ITEMS_LOADING: 
            return{
                ...state,
                loadingallservices: true
            }
        ;
        case GET_ALL_SERVICES_SUCCESS:
            return{
                ...state,
                allservices: action.payload.data,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                loadingallservices: false
            }    
        ;
        case GET_ALL_SERVICES_FAIL:
            return{
                ...state,
                loadingallservices: false
            }
        ;
        case GET_USER_SERVICES_LOADING:
            return{
                ...state,
                userservices: {
                    ...userservices,
                    loadingUserServices: true
                }
            }
        ;
        case GET_USER_SERVICES_SUCCESS:
            return{
                ...state,
                userservices: {
                    services: action.payload.data,
                    totalRecords: action.payload.totalRecords,
                    itemsPerPage: action.payload.itemsPerPage,
                    loadingUserServices: false
                }
            }    
        ;
        case GET_USER_SERVICES_FAIL:
            return{
                ...state,
                userservices: {
                    ...userservices,
                    loadingUserServices: false
                }
            }
        ;
        case GET_RELATED_SERVICES_LOADING:
            return{
                ...state,
                relatedservices: {
                    ...relatedservices,
                    loadingRelatedServices: true
                },
            }
        ;
        case GET_RELATED_SERVICES_SUCCESS:
            return{
                ...state,
                relatedservices: {
                    relatedServices: action.payload.data,
                    totalRecords: action.payload.totalRecords,
                    itemsPerPage: action.payload.itemsPerPage,
                    loadingRelatedServices: false
                },
            }    
        ;
        case GET_RELATED_SERVICES_FAIL:
            return{
                ...state,
                relatedservices: {
                    ...relatedservices,
                    loadingRelatedServices: false
                },
            }
        ;
        case CLEAR_ALL_SERVICES:
            return{
                ...state,
                allservices: [],
                totalRecords: 0,
                itemsPerPage: 0,
            }
        default:
            return state;
    }
}