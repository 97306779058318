import { axiosInstance, tokenConfig } from '../axiosInstance';
import { GET_PORTFOLIO_SUCCESS, GET_PORTFOLIO_FAIL, PORTFOLIO_ITEMS_LOADING } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getfreelancerPortfolio = (user_id = null, publish = null, id = null, page = null, limit = null) => {
    if(user_id){
        return async function(dispatch){
            dispatch(clearErrors());
            dispatch({
                type: PORTFOLIO_ITEMS_LOADING
            });
            try {
                let response = await axiosInstance.get('/api/portfolios', {params: { user_id, publish, id, page, limit }});
                if(response.status !== 200){
                    dispatch({
                        type: GET_PORTFOLIO_FAIL,
                    });
                    throw Error({
                        response: {
                            data: "Other Errors",
                            status: "Other Errors"
                        },
                    })
                }else{
                    dispatch({
                        type: GET_PORTFOLIO_SUCCESS,
                        payload: response.data
                    })
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    dispatch(returnErrors(error.response.data, error.response.status, 'GET_PORTFOLIO_FAIL'));
                    dispatch ({ 
                        type: GET_PORTFOLIO_FAIL 
                    });
                }else{
                    let errorJson = JSON.stringify(error);
                    let errorParsed = JSON.parse(errorJson);
                    return (
                        dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PORTFOLIO_FAIL')),
                        dispatch ({ 
                            type: GET_PORTFOLIO_FAIL 
                        })
                    )
                }
            }
        }
    }else{
        return async function(dispatch, getState){
            dispatch(clearErrors());
            dispatch({
                type: PORTFOLIO_ITEMS_LOADING
            });
            try {
                let response = await axiosInstance.get('/api/portfolios', {params: { user_id, publish, id, page, limit } , ...tokenConfig(getState)});
                if(response.status !== 200){
                    dispatch({
                        type: GET_PORTFOLIO_FAIL,
                    });
                    throw Error({
                        response: {
                            data: "Other Errors",
                            status: "Other Errors"
                        },
                    })
                }else{
                    dispatch({
                        type: GET_PORTFOLIO_SUCCESS,
                        payload: response.data
                    })
                }
            } catch (error) {
                if (error.response && error.response.data) {
                    dispatch(returnErrors(error.response.data, error.response.status, 'GET_PORTFOLIO_FAIL'));
                    dispatch ({ 
                        type: GET_PORTFOLIO_FAIL 
                    });
                }else{
                    let errorJson = JSON.stringify(error);
                    let errorParsed = JSON.parse(errorJson);
                    return (
                        dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PORTFOLIO_FAIL')),
                        dispatch ({ 
                            type: GET_PORTFOLIO_FAIL 
                        })
                    )
                }
            }
        }
    }
};