import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { getProjects } from '../redux/project/actions/getActions';
import { getallServices } from '../actions/getservicesActions';
import { getFreelancers } from '../actions/freelancersActions';
import { WebsiteLink } from './Server';

export default function RssFeed(){
    const dispatch = useDispatch();
    var { 
        getProjectsLoading,
        projects, 
        loadingallservices,
        allservices,
        loading,
        freelancers,
        isAuthenticated,
        locale,
      } = useSelector((state) => ({
        getProjectsLoading: state.project.getProjectsLoading,
        projects: state.project.projects,
        loadingallservices: state.services.loadingallservices,
        allservices: state.services.allservices,
        loading: state.freelancer.loadingFreelancers,
        freelancers: state.freelancer.freelancers,
        isAuthenticated: state.auth.isAuthenticated,
        locale: state.i18n.locale,
      }));

    useEffect(() => {
        if(isAuthenticated){
            dispatch(getProjects(null, null, 1, 10, null, null, null, null, null, null, null, 8, null, locale));
            dispatch(getallServices(null, null, null, 1, 1, null, 1, 10, null, null, null, null, null));
        }else{
            dispatch(getFreelancers(null, null, null, null, null, [1, 3], 1, null, null, null, locale));
        }
    }, [isAuthenticated, dispatch, locale]);

    var RSS = require('rss-generator');
    var feed = new RSS({
        title: 'لانسرزان | Lancersin',
        description: "The world’s marketplace. where you can get your job done. Post your project for free. | سوق عالمي. حيث يمكنك إنجاز عملك. انشر مشروعك مجانا",
        feed_url: `${WebsiteLink}/rss`,
        site_url: WebsiteLink,
        language: 'ar',
        generator: 'لانسرزان | Lancersin',
    });

    const createMarkup = (text) => {
        return { __html: text };
    };

    if(projects && projects.length > 0 && !getProjectsLoading){
        projects.map( project => (
            feed.item({
                title:  project.title,
                url: `${WebsiteLink}/projects/${project.id}/${project.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`,
                description: project.description.replace(/(<([^>]+)>)/ig, ''),
                guid: project.id,
                pubDate: project.created_at.Date + ", " + project.created_at.Time, // any format that js Date can parse.
            })
        ));
    }

    if(allservices && allservices.length > 0 && !loadingallservices){
        allservices.map( service => (
            feed.item({
                title:  service.title,
                description: service.title,
                url: `${WebsiteLink}/services/${service.id}/${service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`, // link to the item
                guid: service.id,
                pubDate: new Date().getFullYear(), // any format that js Date can parse.
            })
        ));
    }

    if(freelancers && freelancers.length > 0 && !loading){
        freelancers.map( freelancer => (
            feed.item({
                title:  freelancer.fname,
                description: freelancer.position ? freelancer.position : freelancer.lname,
                url: `${WebsiteLink}/in/${freelancer.username}`, // link to the item
                guid: freelancer.username,
                pubDate: new Date().getFullYear(), // any format that js Date can parse.
            })
        ));
    }

    var xml = feed.xml();

    return <div dangerouslySetInnerHTML={createMarkup(xml)}></div>;
}