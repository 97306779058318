import React from "react";
import { Button, Spinner } from "react-bootstrap";
import { I18n } from "react-redux-i18n";

export default function ButtonWithLoading({ loading = true, ...rest }) {

  return (
    <Button {...rest}>
      { loading ? 
        <span className="btnWloading">
        <Spinner as="span" animation="border" role="status" aria-hidden="true" size="sm" /> <span className="btnWloading__text">{I18n.t('please_wait')}</span>
        </span>
        : rest.children}
    </Button>
  );
}
