import React from 'react';
import Lottie from 'react-lottie';
import animationData from './loader.json';

const Spin = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
    };
    return(
        // <div className="LoadingContainer">
        //     <div className="LancersLoading">
        //         <div></div>
        //         <div></div>
        //     </div>
        // </div>
        <div className="LoadingContainer">
            <Lottie 
                options={defaultOptions}
                height={200}
                width={200}
            />
        </div>
    )
}

export default Spin;