import {
    GET_SERVICE_INVOICE_COMMENTS_SUCCESS, 
    GET_SERVICE_INVOICE_COMMENTS_FAIL, 
    GET_SERVICE_INVOICE_COMMENTS_LOADING,
    EDIT_SERVICE_INVOICE_COMMENT_LOADING,
    EDIT_SERVICE_INVOICE_COMMENT_SUCCESS,
    ADD_SERVICE_INVOICE_COMMENT_LOADING,
    ADD_SERVICE_INVOICE_COMMENT_SUCCESS,
    DELETE_SERVICE_INVOICE_COMMENT_SUCCESS,
    DELETE_SERVICE_INVOICE_COMMENT_FAIL,
    DELETE_SERVICE_INVOICE_COMMENT_LOADING,
    GET_SERVICE_INVOICE_LAST_COMMENT,
    RESET_SERVICE_INVOICE_COMMENTS
} from '../actions/types';

const initialState = {
    isLoading: false,
    addCommentLoading: false,
    editCommentLoading: false,
    deleteCommentLoading: false,
    totalRecords: 0,
    itemsPerPage: '0',
    serviceinvoiceComments: [],
    serviceinvoiceCommentEdited: "",
    serviceinvoiceCommentAdded: "",
    serviceinvoiceCommentDeleted: "",
    serviceinvoiceLastComment: null,
}


export default function serviceinvoicecommentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SERVICE_INVOICE_COMMENTS_LOADING:
            return {
                ...state,
                serviceinvoiceCommentEdited: {},
                serviceinvoiceCommentAdded: {},
                serviceinvoiceCommentDeleted: {},
                isLoading: true
            };
        case GET_SERVICE_INVOICE_COMMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                serviceinvoiceComments: action.payload.data
            };
        case GET_SERVICE_INVOICE_LAST_COMMENT:
            return {
                ...state,
                serviceinvoiceLastComment: action.payload
            };
        case GET_SERVICE_INVOICE_COMMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
        };
        case DELETE_SERVICE_INVOICE_COMMENT_LOADING:
            return {
                ...state,
                deleteCommentLoading: true
            };
        case DELETE_SERVICE_INVOICE_COMMENT_SUCCESS:
            return {
                ...state,
                deleteCommentLoading: false,
                serviceinvoiceCommentDeleted: action.payload.data
            };
        case DELETE_SERVICE_INVOICE_COMMENT_FAIL:
            return {
                ...state,
                deleteCommentLoading: false,
        };
        case EDIT_SERVICE_INVOICE_COMMENT_LOADING:
            return{
                ...state,
                editCommentLoading: true,
        };
        case EDIT_SERVICE_INVOICE_COMMENT_SUCCESS:
            return {
                ...state,
                editCommentLoading: false,
                serviceinvoiceCommentEdited: action.payload.data
        };
        case ADD_SERVICE_INVOICE_COMMENT_LOADING:
            return {
                ...state,
                addCommentLoading: true,
        };
        case ADD_SERVICE_INVOICE_COMMENT_SUCCESS:
            return {
                ...state,
                addCommentLoading: false,
                serviceinvoiceCommentAdded: action.payload.data
        };
        case RESET_SERVICE_INVOICE_COMMENTS:
            return {
                ...state,
                serviceinvoiceCommentEdited: "",
                serviceinvoiceCommentAdded: "",
                serviceinvoiceCommentDeleted: "",
        };
        default:
            return state;
    }
}