import React, {Fragment} from 'react';
import {Translate, I18n} from 'react-redux-i18n';
import {Card} from 'react-bootstrap';
import {useHistory, useLocation} from 'react-router-dom';

const {Body} = Card;

export default function LockCard({
  user,
  title,
  signFor,
  fullPage = false,
  className = '',
}) {
  const location = useLocation();
  const history = useHistory();

  function onSignInClick() {
    history.push(`/signin?path=${location.pathname}`);
  }

  function onSignUpClick() {
    history.push(`/signup?path=${location.pathname}`);
  }

  return (
    <Fragment>
      <Card className={fullPage ? 'Lock__Card__FullPage' : className}>
        <Body>
          <div className="SignTo">
            <span className="LockImg"></span>
            {title ? (
              <h4>
                <Translate value={title} />
              </h4>
            ) : null}
            {signFor ? (
              user ? (
                <p>
                  {I18n.t(signFor, {
                    fname: user.fname,
                    lname: user.lname,
                  })}
                </p>
              ) : (
                <p>{I18n.t(signFor)}</p>
              )
            ) : null}

            <div className="SignTo__Actions">
              <button className="btn btn-primary" onClick={onSignInClick}>
                <span className="icon icon-user"></span>{' '}
                <Translate value="signin" />
              </button>

              <button className="btn btn-primary" onClick={onSignUpClick}>
                <span className="icon icon-add-user"></span>{' '}
                <Translate value="signup" />
              </button>
            </div>
          </div>
        </Body>
      </Card>
    </Fragment>
  );
}
