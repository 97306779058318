import { 
    REPORTS_LOADING, 
    REPORTS_SUCCESS, 
    REPORTS_FAIL,
    POST_REPORT_SUCCESS,
    POST_REPORT_FAIL,
    POST_REPORT_LOADING
} from '../actions/types';
import { axiosInstance, tokenConfig } from '../axiosInstance';
import { returnErrors } from '../actions/errorActions';

// Add Portfolio Item

export const getreportsTypes = (locale) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: REPORTS_LOADING
    });
    //Request body
    axiosInstance.get('/api/report/types', tokenConfig(getState, locale))
    .then( res => 
        dispatch ({
            type: REPORTS_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'REPORTS_FAIL'))
            dispatch ({ 
                type: REPORTS_FAIL 
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'REPORTS_FAIL')),
                dispatch ({ 
                    type: REPORTS_FAIL 
                })
            )
        }
    });
};


export const postReport = ({ type_id, report, title, model, model_id }) => (dispatch, getState) => {
    // Data Loading
    dispatch({
        type: POST_REPORT_LOADING
    });
    //Request body
    const body = { type_id, report, title, model, model_id };
    axiosInstance.post('/api/report/create', body, tokenConfig(getState))
    .then( res => 
        dispatch ({
            type: POST_REPORT_SUCCESS,
            payload: res.data
        })
    )
    .catch(error => {
        if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'POST_REPORT_FAIL'))
            dispatch ({ 
                type: POST_REPORT_FAIL 
            })
        }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'POST_REPORT_FAIL')),
                dispatch ({ 
                    type: POST_REPORT_FAIL 
                })
            )
        }
    });
};