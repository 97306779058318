import { axiosInstance, tokenConfig } from '../axiosInstance';
import { GET_PORTFOLIO_ITEM_SUCCESS, GET_PORTFOLIO_ITEM_FAIL, PORTFOLIO_ITEM_LOADING } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getfreelancerPortfolioItem = ({ id, locale }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: PORTFOLIO_ITEM_LOADING
        });
        try {
            let response = await axiosInstance.get(`/api/portfolios?id=${id}`, {...tokenConfig(getState, locale)});
            if(response.status !== 200){
                dispatch({
                    type: GET_PORTFOLIO_ITEM_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: GET_PORTFOLIO_ITEM_SUCCESS,
                    payload: response.data.data
                })
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'GET_PORTFOLIO_ITEM_FAIL'));
                dispatch ({ 
                    type: GET_PORTFOLIO_ITEM_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_PORTFOLIO_ITEM_FAIL')),
                    dispatch ({ 
                        type: GET_PORTFOLIO_ITEM_FAIL 
                    })
                )
            }
        }
    }
};