import React, {useEffect, Fragment, useState, useCallback} from 'react';
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import CustomButton from './../Buttons/Button';
import {
  getProjects,
  getBidReports,
} from '../../redux/project/actions/getActions';
import {deleteProject} from '../../redux/project/actions/deleteActions';
import {
  closeProject,
  handoverProjectBid,
  indorseProjectBid,
} from '../../redux/project/actions/addActions';
import {CLEAR_PROJECT_BID_REPORTS} from '../../redux/project/actions/types';
import {useDispatch, useSelector} from 'react-redux';
import Spin from '../Spin';
import AddingProjectModal from '../AddingProjectModal';
import {Link, Redirect} from 'react-router-dom';
import {truncate} from '../ReduceFileName';
import {Size} from '../CalculateSize';
import {I18n} from 'react-redux-i18n';
import RatingStarsPreview from '../RatingStarsPreview';
import ConfToast from '../ConfToast';
import {TitleComponent} from '../TitleComponent';
import {MetaComponent} from '../MetaComponent';
import ProjectProposals from './ProjectProposals';
import StatusLabel from '../../StatusLabel';
import ProjectComments from './ProjectComments';
import LockCard from '../LockCard';
import ConnectionLost from '../ConnectionLost';
import NoItems from '../NoItems';
import DeleteModal from '../DeleteModal';
import ReportContentModal from '../ReportContentModal';
import {addFavourite} from '../../redux/favourite/actions/addActions';
import {deleteFavourite} from '../../redux/favourite/actions/deleteActions';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {
  CLEAR_ADD_FAVOURITE_SUCCESS,
  CLEAR_DELETE_FAVOURITE_SUCCESS,
} from '../../redux/favourite/actions/types';
import {
  CLEAR_SELECT_PROJECT_BID_SUCCESS,
  CLEAR_CURRENT_PROJECT,
  CLEAR_RATE_PROJECT_SUCCESS,
  CLEAR_INDORSE_PROJECT_BID_SUCCESS,
  CLEAR_HANDOVER_PROJECT_BID_SUCCESS,
} from '../../redux/project/actions/types';
import FeedbackModal from '../FeedbackModal';
import logoIcon from '../../imgs/logo-icon.png';
import ReportProjectBidModal from '../ReportProjectBidModal';
import CancelBidModal from '../CancelBidModal';
import {getFeedbacks} from '../../redux/feedbacks/actions/getActions';
import CloseProjectModal from '../CloseProjectModal';

export default function ProjectDetails(props) {
  const dispatch = useDispatch();
  var {
    // Project
    currentProject: project,
    getProjectByIdLoading,
    addedProject,
    addingProjectSuccess,

    // Delete Project
    deleteProjectLoading,
    deletingProjectSuccess,

    // Close Project
    closeProjectLoading,
    closeProjectSuccess,

    // Edit Project
    editingProjectLoading,
    editingProjectSuccess,

    // Select Project Bid
    selectProjectBidSuccess,

    //Logged User
    loggedUser,
    isAuthenticated,
    userLoading,

    userData,

    // Reports
    postReportLoading,

    // errors
    errorsId,
    errorStatus,
    errorsMsg,

    //Add To favourite
    addingFavouriteLoading,
    addingFavouriteSuccess,

    //Delete from favourite
    deletingFavouriteLoading,
    deletingFavouriteSuccess,

    //Rate Project
    rateProjectLoading,
    rateProjectSuccess,

    //Project Bid Reports
    projectBidReportsLoading,
    projectBidReports,

    //Project Handover
    handoverProjectBidLoading,
    handoverProjectBidSuccess,
    indorseProjectBidSuccess,

    //Cancel Project Bid
    cancelProjectBidLoading,
    cancelProjectBidSuccess,

    reportProjectBidLoading,

    lastNotification,

    //Feedbacks
    feedbacks,

    locale,
  } = useSelector(state => ({
    currentProject: state.project.currentProject,
    getProjectByIdLoading: state.project.getProjectByIdLoading,
    addedProject: state.project.addedProject,

    addingProjectSuccess: state.project.addingProjectSuccess,

    deleteProjectLoading: state.project.deletingProjectLoading,
    deletingProjectSuccess: state.project.deletingProjectSuccess,

    closeProjectLoading: state.project.closeProjectLoading,
    closeProjectSuccess: state.project.closeProjectSuccess,

    editingProjectLoading: state.project.editingProjectLoading,
    editingProjectSuccess: state.project.editingProjectSuccess,

    selectProjectBidSuccess: state.project.selectProjectBidSuccess,

    loggedUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    userLoading: state.auth.isLoading,

    userData: state.userData.user,

    postReportLoading: state.reportsTypes.isLoading,

    errorsId: state.error.id,
    errorStatus: state.error.status,
    errorsMsg: state.error.msg,

    addingFavouriteLoading: state.favourites.addingFavouriteLoading,
    addingFavouriteSuccess: state.favourites.addingFavouriteSuccess,

    deletingFavouriteLoading: state.favourites.deletingFavouriteLoading,
    deletingFavouriteSuccess: state.favourites.deletingFavouriteSuccess,

    rateProjectLoading: state.project.rateProjectLoading,
    rateProjectSuccess: state.project.rateProjectSuccess,

    projectBidReportsLoading: state.project.projectBidReportsLoading,
    projectBidReports: state.project.projectBidReports,

    handoverProjectBidLoading: state.project.handoverProjectBidLoading,
    handoverProjectBidSuccess: state.project.handoverProjectBidSuccess,
    indorseProjectBidSuccess: state.project.indorseProjectBidSuccess,

    cancelProjectBidLoading: state.project.cancelProjectBidLoading,
    cancelProjectBidSuccess: state.project.cancelProjectBidSuccess,

    reportProjectBidLoading: state.project.reportProjectBidLoading,

    lastNotification: state.notifications.lastNotification,

    feedbacks: state.feedbacks.feedbacks,

    locale: state.i18n.locale,
  }));

  const [currentProject, setCurrentProject] = useState(project);
  const [redirect, setRedirect] = useState(false);
  const [projectLoading, setProjectLoading] = useState(true);
  const [isOwnerOfThisProject, setIsOwnerOfThisProject] = useState(false);
  const [isOwnerOfSelectedBid, setIsOwnerOfSelectedBid] = useState(false);
  const [isProjectReceivingBids, setIsProjectReceivingBids] = useState(false);
  const [isProjectUnderReview, setIsProjectUnderReview] = useState(false);
  const [isProjectRunning, setIsProjectRunning] = useState(false);
  const [isProjectClosed, setIsProjectClosed] = useState(false);
  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showBidReport, setShowBidReport] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [feedbackModel, setFeedbackModel] = useState(false);
  const [feedbackModalTitle, setFeedbackModalTitle] = useState('');
  const [projectToDeleteId, setProjectToDeleteId] = useState('');
  const [projectToDeleteTitle, setProjectToDeleteTitle] = useState('');
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [addSimilarProject, setAddSimilarProject] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);

  useEffect(() => {
    if (userData && userData.memberships && userData.memberships.length >= 1) {
      userData.memberships.map(item => {
        if ((item.id === 3 || item.id === 4) && item.default) {
          return setIsClient(true);
        } else {
          return setIsClient(false);
        }
      });
    }
  }, [userData]);

  useEffect(() => {
    setProjectLoading(getProjectByIdLoading);
  }, [getProjectByIdLoading]);

  const {id} = props.match.params;

  useEffect(() => {
    if (id) {
      dispatch({
        type: CLEAR_PROJECT_BID_REPORTS,
      });
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
  }, [id, dispatch, locale, userLoading, isAuthenticated]);

  useEffect(() => {
    const morphType = 'project';
    const morphID = id;
    dispatch(getFeedbacks(null, morphType, morphID, null, null));
  }, [id, dispatch]);

  useEffect(() => {
    if (
      project &&
      project.selected_bid &&
      Object.keys(project.selected_bid).length > 0
    ) {
      const project_bid_id = project.selected_bid.id;
      dispatch(getBidReports(project_bid_id));
    }
  }, [project, dispatch]);

  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = (id, projectTitle) => {
    setProjectToDeleteId(id);
    setProjectToDeleteTitle(projectTitle);
    setShowDeleteConfirm(true);
  };

  const handleToast = useCallback(
    (showToast, title, message, status, delay) => {
      setConfShow(showToast);
      setConfTitle(title);
      setConfMessage(message);
      setConfStatus(status);
      setDelay(delay);
    },
    [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay],
  );

  useEffect(() => {
    if (selectProjectBidSuccess) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('select_bid'),
          I18n.t('select_project_bid_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 1500);
      dispatch({
        type: CLEAR_SELECT_PROJECT_BID_SUCCESS,
      });
    }
  }, [selectProjectBidSuccess, id, dispatch, locale, handleToast]);

  useEffect(() => {
    if (errorsId === null && cancelProjectBidSuccess && id) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
  }, [errorsId, cancelProjectBidSuccess, dispatch, id, locale]);

  useEffect(() => {
    if (indorseProjectBidSuccess) {
      setDeclineLoading(false);
      setApproveLoading(false);
      dispatch({
        type: CLEAR_INDORSE_PROJECT_BID_SUCCESS,
      });
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
  }, [indorseProjectBidSuccess, dispatch, id, locale]);

  useEffect(() => {
    if (
      errorsId === null &&
      !handoverProjectBidLoading &&
      handoverProjectBidSuccess &&
      id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
      handleToast(
        true,
        I18n.t('project_handover'),
        I18n.t('project_handover_success'),
        'Toast__Container__Success',
        8000,
      );
      dispatch({
        type: CLEAR_HANDOVER_PROJECT_BID_SUCCESS,
      });
    }
  }, [
    errorsId,
    handoverProjectBidLoading,
    handoverProjectBidSuccess,
    dispatch,
    handleToast,
    id,
    locale,
  ]);

  useEffect(() => {
    if (window.location.href.indexOf('/payment&payment=success') > -1) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('select_bid'),
          I18n.t('select_project_bid_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 2500);
      const pathName = window.location.pathname;
      const redirectTo = pathName.replace('payment&payment=success', '');
      window.history.replaceState(null, '', redirectTo);
    }
  }, [handleToast]);

  useEffect(() => {
    if (isOwnerOfThisProject) {
      if (rateProjectSuccess) {
        dispatch(
          getProjects(
            null,
            id,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            locale,
          ),
        );
        setTimeout(() => {
          handleToast(
            true,
            I18n.t('project_feedback_freelancer'),
            I18n.t('project_feedback_freelancer_success'),
            'Toast__Container__Success',
            6000,
          );
        }, 1500);
        setFeedbackModalTitle('');
        setFeedbackModel(false);
        dispatch({
          type: CLEAR_RATE_PROJECT_SUCCESS,
        });
      }
    }

    if (isOwnerOfSelectedBid) {
      if (rateProjectSuccess) {
        dispatch(
          getProjects(
            null,
            id,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            locale,
          ),
        );
        setTimeout(() => {
          handleToast(
            true,
            I18n.t('project_feedback_owner'),
            I18n.t('project_feedback_owner_success'),
            'Toast__Container__Success',
            6000,
          );
        }, 1500);
        setFeedbackModalTitle('');
        setFeedbackModel(false);
        dispatch({
          type: CLEAR_RATE_PROJECT_SUCCESS,
        });
      }
    }
  }, [
    rateProjectSuccess,
    isOwnerOfThisProject,
    isOwnerOfSelectedBid,
    id,
    dispatch,
    locale,
    handleToast,
  ]);

  useEffect(() => {
    if (project && !getProjectByIdLoading) {
      setIsProjectReceivingBids(project.status.id === 8);
      setIsProjectUnderReview(project.status.id === 2);
      setIsProjectRunning(project.status.id === 5);
      setIsProjectClosed(project.status.id === 6);
      setIsOwnerOfSelectedBid(
        loggedUser.id ===
          (project.selected_bid ? project.selected_bid.user.id : null),
      );
      setIsOwnerOfThisProject(loggedUser.id === project.user.id);
      setCurrentProject(project);
      dispatch({
        type: CLEAR_CURRENT_PROJECT,
      });
    }
  }, [project, getProjectByIdLoading, dispatch, loggedUser]);

  const showFeedbackModal = useCallback(() => {
    if (currentProject) {
      if (loggedUser.id === currentProject.user.id) {
        setFeedbackModalTitle('project_feedback_freelancer');
      } else {
        setFeedbackModalTitle('project_feedback_owner');
      }
    }
    setFeedbackModel(true);
  }, [currentProject, loggedUser]);

  useEffect(() => {
    if (currentProject) {
      if (
        isProjectClosed &&
        isOwnerOfSelectedBid &&
        !currentProject.rated_as_client &&
        currentProject.rated_as_freelancer === true
      ) {
        showFeedbackModal();
      }
    }
  }, [
    isProjectClosed,
    isOwnerOfSelectedBid,
    currentProject,
    showFeedbackModal,
  ]);

  useEffect(() => {
    if (currentProject) {
      if (
        isProjectClosed &&
        isOwnerOfThisProject &&
        !currentProject.rated_as_freelancer
      ) {
        showFeedbackModal();
      }
    }
  }, [
    isProjectClosed,
    isOwnerOfThisProject,
    currentProject,
    showFeedbackModal,
  ]);

  function handleEditOnClick() {
    setToggleEditModal(true);
  }

  function handleHide() {
    setToggleEditModal(false);
    setAddSimilarProject(false);
  }

  function handleAddSimilarOnClick() {
    setAddSimilarProject(true);
    setToggleEditModal(true);
  }

  function handleDeleteOnClick() {
    dispatch(deleteProject({id: projectToDeleteId}));
  }

  function addToFavourite() {
    const morphType = 'projects';
    const morphID = id;
    dispatch(addFavourite(morphType, morphID, locale));
  }

  function deleteFromFavourite() {
    const morphType = 'projects';
    dispatch(deleteFavourite(morphType, id, locale));
  }

  useEffect(() => {
    if (addingFavouriteSuccess) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('adding_project_to_fav'),
          I18n.t('adding_project_to_fav_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 1500);
      dispatch({
        type: CLEAR_ADD_FAVOURITE_SUCCESS,
      });
    }
  }, [addingFavouriteSuccess, handleToast, dispatch, id, locale]);

  useEffect(() => {
    if (deletingFavouriteSuccess) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('deleting_project_from_fav'),
          I18n.t('deleting_project_from_fav_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 1500);
      dispatch({
        type: CLEAR_DELETE_FAVOURITE_SUCCESS,
      });
    }
  }, [deletingFavouriteSuccess, handleToast, dispatch, id, locale]);

  useEffect(() => {
    if (deletingProjectSuccess) {
      setShowDeleteConfirm(false);
      setRedirect(!redirect);
    }
  }, [deletingProjectSuccess, handleToast, redirect]);

  useEffect(() => {
    if (addingProjectSuccess) {
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('adding_project'),
          I18n.t('add_project_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 2000);
    }
  }, [addingProjectSuccess, handleToast]);

  useEffect(() => {
    if (editingProjectSuccess && addedProject.length > 0) {
      let {id} = addedProject[0];
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
      setTimeout(() => {
        handleToast(
          true,
          I18n.t('edit_project'),
          I18n.t('edit_project_success'),
          'Toast__Container__Success',
          6000,
        );
      }, 2000);
    }
  }, [editingProjectSuccess, addedProject, dispatch, handleToast, locale]);

  useEffect(() => {
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidCancelationRequest' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'BidReportAdminDeclined' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidReportAdminApproved' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'BidReportAdminApproved' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'ProjectBidHandover' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectStatusChangedByAdmin' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'SelectBid' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'CloseProject' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type === 'ProjectFeedback' &&
      window.location.href.indexOf('/projects') > -1 &&
      lastNotification.notification_content.content.id.toString() === id
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidRequestDeclined' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'handover'
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidRequestApproved' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'handover'
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidRequestDeclined' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'cancel'
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
    if (
      lastNotification &&
      lastNotification.notification_content.type ===
        'ProjectBidRequestApproved' &&
      lastNotification.notification_content.content.type &&
      lastNotification.notification_content.content.type === 'cancel'
    ) {
      dispatch(
        getProjects(
          null,
          id,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          locale,
        ),
      );
    }
  }, [lastNotification, dispatch, id, locale]);

  function handleShowReport() {
    setShowReport(true);
  }
  function handleHideReport() {
    setShowReport(false);
  }

  function handleShowBidReport() {
    setShowBidReport(true);
  }
  function handleHideBidReport() {
    setShowBidReport(false);
  }

  function handleShowCancel() {
    setShowCancel(true);
  }

  function handleHideCancel() {
    setShowCancel(false);
  }

  const hideFeedbackModal = useCallback(() => {
    setFeedbackModalTitle('');
    setFeedbackModel(false);
  }, []);

  function onHandover(e) {
    e.preventDefault();
    if (
      currentProject &&
      currentProject.selected_bid &&
      Object.keys(currentProject.selected_bid).length > 0
    ) {
      const data = {
        project_bid_id: currentProject.selected_bid.id,
      };
      dispatch(handoverProjectBid(data));
    }
  }

  const handleCloseProjectConfirmClose = () => setShowCloseConfirm(false);
  const handleCloseProjectConfirmShow = (id, projectTitle) => {
    setProjectToDeleteId(id);
    setProjectToDeleteTitle(projectTitle);
    setShowCloseConfirm(true);
  };
  function handleCloseOnClick() {
    dispatch(closeProject(projectToDeleteId));
  }

  useEffect(() => {
    if (closeProjectSuccess) {
      window.location.reload();
    }
  }, [closeProjectSuccess]);

  function checkReports(reports) {
    const cancelOrders = reports
      .filter(function (item) {
        if (item.type === 'cancel') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (cancelOrders.length > 0) {
      const maxId = Math.max(...cancelOrders.map(item => item.id));
      const currentCancelOrder = cancelOrders
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentCancelOrder[0].status === 'approved') {
        return 'approved';
      } else if (currentCancelOrder[0].status === 'declined') {
        return 'declined';
      } else if (currentCancelOrder[0].status === 'pending') {
        return 'pending';
      }
      return 'noRequest';
    }
  }

  function currentCancel(reports) {
    const cancelOrders = reports
      .filter(function (item) {
        if (item.type === 'cancel') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (cancelOrders.length > 0) {
      const maxId = Math.max(...cancelOrders.map(item => item.id));
      const currentCancelOrder = cancelOrders
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentCancelOrder[0].status === 'pending') {
        return currentCancelOrder[0];
      }
      return 'noRequest';
    }
  }

  function handover(reports) {
    const handoverRequests = reports
      .filter(function (item) {
        if (item.type === 'handover') {
          return true;
        }
        return false;
      })
      .map(function (item) {
        return item;
      });

    if (handoverRequests.length > 0) {
      const maxId = Math.max(...handoverRequests.map(item => item.id));
      const currentHandover = handoverRequests
        .filter(function (item) {
          if (item.id === maxId) {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });
      if (currentHandover[0].status === 'pending') {
        return 'pending';
      } else if (currentHandover[0].status === 'declined') {
        return 'declined';
      }
      return 'noHandover';
    }
  }

  function indorseBid(status) {
    if (status === 'declined' && projectBidReports.length > 0) {
      setDeclineLoading(true);
      const reports = projectBidReports;
      const cancelOrders = reports
        .filter(function (item) {
          if (item.type === 'cancel') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (cancelOrders.length > 0) {
        const maxId = Math.max(...cancelOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseProjectBid(data));
      }
    } else if (status === 'approved' && projectBidReports.length > 0) {
      setApproveLoading(true);
      const reports = projectBidReports;
      const cancelOrders = reports
        .filter(function (item) {
          if (item.type === 'cancel') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (cancelOrders.length > 0) {
        const maxId = Math.max(...cancelOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseProjectBid(data));
      }
    }
  }

  function indorseBidHandover(status) {
    if (status === 'declined' && projectBidReports.length > 0) {
      setDeclineLoading(true);
      const reports = projectBidReports;
      const handoverOrders = reports
        .filter(function (item) {
          if (item.type === 'handover') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (handoverOrders.length > 0) {
        const maxId = Math.max(...handoverOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseProjectBid(data));
      }
    } else if (status === 'approved' && projectBidReports.length > 0) {
      setApproveLoading(true);
      const reports = projectBidReports;
      const handoverOrders = reports
        .filter(function (item) {
          if (item.type === 'handover') {
            return true;
          }
          return false;
        })
        .map(function (item) {
          return item;
        });

      if (handoverOrders.length > 0) {
        const maxId = Math.max(...handoverOrders.map(item => item.id));
        const report_id = maxId;
        const data = {
          report_id,
          status,
        };
        dispatch(indorseProjectBid(data));
      }
    }
  }

  function stripHtml(html) {
    let tmp = document.createElement('DIV');
    tmp.innerHTML = html.replace(/&nbsp;/g, '');
    return tmp.textContent || tmp.innerText || '';
  }

  if (redirect) {
    return <Redirect to="/dashboard/projects" />;
  }

  if (projectLoading || projectBidReportsLoading) {
    return <Spin />;
  } else {
    if (currentProject) {
      var {
        title,
        price,
        description,
        deadline,
        keywords,
        skills,
        bids_count,
        bids_average,
        category,
        sub_category,
        status,
        user,
        created_at,
        files: projectFiles,
        is_favourite,
        //image
      } = currentProject;

      const createMarkup = text => {
        return {__html: text};
      };
      return (
        <Fragment>
          <TitleComponent
            title={
              I18n.t('lancersin_projects') + ' - ' + title.substring(0, 35)
            }
          />
          <MetaComponent
            keywords={keywords && keywords.length >= 3 ? keywords : title}
            description={stripHtml(description).substring(0, 150)}
          />
          <ConfToast
            showConf={confShow}
            setShowConf={setConfShow}
            title={confTitle}
            message={confMessage}
            status={confStatus}
            delay={delay}
          />
          <DeleteModal
            onHide={handleDeleteConfirmClose}
            onDelete={handleDeleteOnClick}
            show={showDeleteConfirm}
            modalTitle={'delete_project'}
            className="CustomPopup"
            size="md"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={deleteProjectLoading}
            messageHeading="delete_conf"
            messageBody={projectToDeleteTitle}
          />
          <CloseProjectModal
            onHide={handleCloseProjectConfirmClose}
            onClose={handleCloseOnClick}
            show={showCloseConfirm}
            modalTitle={'project_received'}
            className="CustomPopup"
            size="md"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={closeProjectLoading}
            messageHeading="project_delivery_body"
            messageBody={projectToDeleteTitle}
          />
          <AddingProjectModal
            projectDetails={currentProject}
            onSubmit={setCurrentProject}
            onHide={handleHide}
            show={toggleEditModal}
            modalTitle={'edit_project'}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={editingProjectLoading}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
            addSimilarProject={addSimilarProject}
          />
          <FeedbackModal
            className="CustomPopup"
            size="lg"
            show={feedbackModel}
            onHide={hideFeedbackModal}
            modalTitle={feedbackModalTitle}
            backdrop="static"
            keyboard={false}
            invoice_id={id}
            loggedUserId={loggedUser.id}
            serviceUserId={user.id}
            aria-labelledby="example-modal-sizes-title-lg"
            loadingInvoiceStatus={rateProjectLoading}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
            isProject={true}
          />
          <ReportContentModal
            onHide={handleHideReport}
            show={showReport}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={postReportLoading}
            model="projects"
            model_id={id}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <ReportProjectBidModal
            onHide={handleHideBidReport}
            show={showBidReport}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={reportProjectBidLoading}
            project_bid_id={
              currentProject.selected_bid
                ? currentProject.selected_bid.id
                : null
            }
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <CancelBidModal
            onHide={handleHideCancel}
            show={showCancel}
            className="CustomPopup"
            size="lg"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={cancelProjectBidLoading}
            projectStatusId={currentProject.status.id}
            project_bid_id={
              currentProject.selected_bid
                ? currentProject.selected_bid.id
                : null
            }
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <div className="breadcrumb__container">
            <div className="container">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">{I18n.t('home')}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/projects">{I18n.t('browse_projects')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="container ProjectDetails">
            {checkReports(projectBidReports) === 'pending' &&
            currentProject.status.id === 5 &&
            isOwnerOfSelectedBid ? (
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Header>{I18n.t('order_actions')}</Card.Header>
                    <Card.Body>
                      <div className="serviceDelivery">
                        <div className="serviceDelivery__Img">
                          <img src={logoIcon} alt="Lancersin" />
                        </div>
                        <div className="serviceDelivery__Content">
                          <div className="serviceDelivery__Content__Message">
                            {I18n.t('service_cancellation_label') + ' '}
                            {currentProject.user
                              ? currentProject.user.fname +
                                ' ' +
                                currentProject.user.lname
                              : null}
                            .<br />
                            {' ' + I18n.t('project_cancellation_message')}
                            {currentCancel(projectBidReports) !==
                            'noRequest' ? (
                              <div
                                dangerouslySetInnerHTML={createMarkup(
                                  currentCancel(projectBidReports).report,
                                )}></div>
                            ) : null}
                          </div>
                          <div className="serviceDelivery__Content__Actions">
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={() => indorseBid('approved')}
                              data-backdrop="static"
                              loading={approveLoading ? approveLoading : null}>
                              {I18n.t('service_cancellation_approve')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                              variant="secondary"
                              type="submit"
                              onClick={() => indorseBid('declined')}
                              data-backdrop="static"
                              loading={declineLoading ? declineLoading : null}>
                              {I18n.t('service_cancellation_decline')}
                            </ButtonWithLoading>
                            {errorsId &&
                            errorsId === 'INDORSE_SERVICE_INVOICE_FAIL' ? (
                              <div className="invalid-feedback d-block">
                                {I18n.t('something_went_wrong')}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : null}
            {handover(projectBidReports) === 'pending' &&
            isOwnerOfThisProject &&
            currentProject.status.id !== 6 ? (
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Header>{I18n.t('order_actions')}</Card.Header>
                    <Card.Body>
                      <div className="alert alert-warning" role="alert">
                        <i className="fas fa-exclamation-circle"></i>{' '}
                        <b>{I18n.t('note') + ': '}</b>
                        {I18n.t('project_delivery_note')}
                      </div>
                      <div className="serviceDelivery">
                        <div className="serviceDelivery__Img">
                          <img src={logoIcon} alt="Lancersin" />
                        </div>
                        <div className="serviceDelivery__Content">
                          <div className="serviceDelivery__Content__Message">
                            {I18n.t('service_delivery_label') + ' '}
                            {currentProject.selected_bid.user
                              ? currentProject.selected_bid.user.fname +
                                ' ' +
                                currentProject.selected_bid.user.lname
                              : null}
                            .<br />
                            {' ' + I18n.t('service_delivery_message')}
                          </div>
                          <div className="serviceDelivery__Content__Actions">
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={() => indorseBidHandover('approved')}
                              data-backdrop="static"
                              loading={approveLoading ? approveLoading : null}>
                              {I18n.t('service_delivery_approve')}
                            </ButtonWithLoading>
                            <ButtonWithLoading
                              variant="secondary"
                              type="submit"
                              onClick={() => indorseBidHandover('declined')}
                              data-backdrop="static"
                              loading={declineLoading ? declineLoading : null}>
                              {I18n.t('service_delivery_decline')}
                            </ButtonWithLoading>
                            {errorsId &&
                            errorsId === 'INDORSE_PROJECT_BID_FAIL' ? (
                              <div className="invalid-feedback d-block">
                                {I18n.t('something_went_wrong')}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            ) : null}
            <div className="Profile-Card box-shadow">
              <div className="row">
                <div className="col-md-9">
                  <div className="ProfileData">
                    <div className="ProfileContent">
                      <div className="Title__Status">
                        <h1 className="ProfileName">{title}</h1>
                        {handover(projectBidReports) === 'pending' &&
                        currentProject.status.id !== 6 ? (
                          <StatusLabel status_id={9} type="project" />
                        ) : (
                          <StatusLabel status_id={status.id} type="project" />
                        )}
                      </div>
                      <div className="Projects__List__LeftSide ProjectDetails__ProjectData">
                        {category ? (
                          <div className="Projects__List__Budget">
                            <div className="Projects__List__Budget__Title">
                              {I18n.t('category')}
                            </div>
                            <div className="Projects__List__Budget__Desc">
                              {category.title}
                            </div>
                          </div>
                        ) : null}
                        {sub_category ? (
                          <div className="Projects__List__Budget">
                            <div className="Projects__List__Budget__Title">
                              {I18n.t('sub_category')}
                            </div>
                            <div className="Projects__List__Budget__Desc">
                              {sub_category.title}
                            </div>
                          </div>
                        ) : null}
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            <h2>{I18n.t('project_budget')}</h2>
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {price.title} {I18n.t('dollar')}
                          </div>
                        </div>
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            <h3>{I18n.t('number_to_deliver')}</h3>
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {deadline} {I18n.t('day_s')}
                          </div>
                        </div>
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            {I18n.t('created_at')}
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {`${created_at.Date} - ${created_at.Time}`}
                          </div>
                        </div>
                        <div className="Projects__List__Budget">
                          <div className="Projects__List__Budget__Title">
                            <h4>{I18n.t('bids')}</h4>
                          </div>
                          <div className="Projects__List__Budget__Desc">
                            {bids_count > 0
                              ? bids_count
                              : I18n.t('no_bids_sent')}
                          </div>
                        </div>
                        {bids_average > 0 ? (
                          <div className="Projects__List__Budget">
                            <div className="Projects__List__Budget__Title">
                              {I18n.t('bids_average')}
                            </div>
                            <div className="Projects__List__Budget__Desc">
                              {Math.round(bids_average)}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className="Dashboard__Project__Owner">
                        <div className="ProfileImage">
                          <figure>
                            <img
                              src={user.image}
                              alt=""
                              className="img-fluid"
                            />
                          </figure>
                        </div>
                        <div className="d-inline-flex flex-column">
                          <h3 className="ProfileTitle">
                            <Link to={{pathname: `/in/${user.username}`}}>
                              {user.fname} {user.lname}
                            </Link>
                          </h3>
                          <div className="Owner__Name mb-0">
                            <RatingStarsPreview rate={user.rate} />
                          </div>
                        </div>
                        <div className="LocationTime">
                          {user.country && user.city ? (
                            <address>
                              <span className="LocationFlag">
                                <img
                                  src={user.country.flag}
                                  alt={user.country.name}
                                />
                              </span>
                              <span className="Location">
                                {user.city.name}, {user.country.name}
                              </span>
                            </address>
                          ) : null}
                        </div>
                        <div className="Owner__Name">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">
                                {I18n.t('total_spent')}
                              </Tooltip>
                            }>
                            <span>
                              <span className="icon icon-budget"></span>
                              {user.totalSpent} {I18n.t('dollar')}
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="d-flex align-items-center justify-content-center flex-column h-100">
                    {isOwnerOfThisProject && isProjectRunning ? (
                      <ButtonWithLoading
                        variant="success"
                        type="submit"
                        data-backdrop="static"
                        loading={closeProjectLoading}
                        onClick={() =>
                          handleCloseProjectConfirmShow(
                            currentProject.id,
                            title,
                          )
                        }
                        className="w-100 btn-sm mb-2">
                        {I18n.t('project_received')}
                      </ButtonWithLoading>
                    ) : null}
                    {isOwnerOfThisProject && isProjectUnderReview ? (
                      <button
                        className="btn btn-primary w-100 btn-sm mb-2"
                        onClick={handleEditOnClick}>
                        <span className="icon icon-edit"></span>
                        {I18n.t('edit_project')}
                      </button>
                    ) : null}
                    {isOwnerOfThisProject && isProjectReceivingBids ? (
                      <button
                        className="btn btn-danger w-100 btn-sm mb-2"
                        onClick={() =>
                          handleDeleteConfirmShow(currentProject.id, title)
                        }>
                        <span className="far fa-trash-alt"></span>
                        {I18n.t('delete_project')}
                      </button>
                    ) : null}
                    {
                      <Fragment>
                        {isAuthenticated ? (
                          is_favourite ? (
                            <ButtonWithLoading
                              variant="danger"
                              type="submit"
                              data-backdrop="static"
                              loading={deletingFavouriteLoading}
                              onClick={() => deleteFromFavourite()}
                              className="w-100 btn-sm mb-2">
                              <span className="fas fa-heart"></span>
                              {I18n.t('delete_from_fav')}
                            </ButtonWithLoading>
                          ) : (
                            <ButtonWithLoading
                              variant="primary"
                              type="submit"
                              onClick={() => addToFavourite()}
                              data-backdrop="static"
                              loading={addingFavouriteLoading}
                              className="w-100 btn-sm mb-2">
                              <span className="icon icon-heart"></span>
                              {I18n.t('add_to_favourite')}
                            </ButtonWithLoading>
                          )
                        ) : null}
                        {isOwnerOfSelectedBid ||
                        isOwnerOfThisProject ? null : isProjectReceivingBids &&
                          isAuthenticated ? (
                          <CustomButton
                            parentClassName="InviteContest"
                            iconClassName="far fa-flag"
                            text={I18n.t('report_content')}
                            onClick={() => handleShowReport()}
                          />
                        ) : null}
                        {isOwnerOfSelectedBid || isOwnerOfThisProject ? (
                          isProjectRunning ? (
                            <button
                              className="btn btn-danger btn-sm w-100 mb-2"
                              onClick={() => {
                                handleShowBidReport();
                              }}>
                              <i className="icon icon-report"></i>{' '}
                              {I18n.t('report_problem')}
                            </button>
                          ) : null
                        ) : null}
                        {isOwnerOfThisProject && !isProjectClosed ? (
                          checkReports(projectBidReports) === 'pending' &&
                          currentProject.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block text-center">
                                {I18n.t('project_cancel_request_sent')}
                              </div>
                            </div>
                          ) : checkReports(projectBidReports) === 'approved' &&
                            currentProject.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block text-center">
                                {I18n.t('project_cancel_request_sent')}
                              </div>
                            </div>
                          ) : handover(projectBidReports) === 'pending' &&
                            currentProject.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_cancel_order')}
                              </button>
                              <div className="invalid-feedback d-block text-center">
                                {I18n.t(
                                  'handover_request_action_needed_project',
                                )}
                              </div>
                            </div>
                          ) : currentProject.status.id === 5 ? (
                            <button
                              className="btn btn-danger btn-sm w-100"
                              onClick={() => {
                                handleShowCancel();
                              }}>
                              {I18n.t('project_cancel_order')}
                            </button>
                          ) : null
                        ) : isOwnerOfSelectedBid && !isProjectClosed ? (
                          handover(projectBidReports) === 'pending' ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_handover')}
                              </button>
                              <div className="valid-feedback d-block">
                                {I18n.t('project_handover_success')}
                              </div>
                            </div>
                          ) : checkReports(projectBidReports) === 'pending' &&
                            currentProject.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_handover')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_request_cancel_project')}
                              </div>
                            </div>
                          ) : checkReports(projectBidReports) === 'approved' &&
                            currentProject.status.id !== 7 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <button className="btn btn-disabled btn-sm w-100">
                                {I18n.t('project_handover')}
                              </button>
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_request_cancel_project_admin')}
                              </div>
                            </div>
                          ) : handover(projectBidReports) === 'declined' ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <ButtonWithLoading
                                variant="success"
                                type="submit"
                                className="btn-sm w-100"
                                onClick={onHandover}
                                data-backdrop="static"
                                loading={
                                  handoverProjectBidLoading
                                    ? handoverProjectBidLoading
                                    : null
                                }>
                                {I18n.t('project_handover')}
                              </ButtonWithLoading>
                              {errorsId &&
                              errorsId === 'HANDOVER_PROJECT_BID_FAIL' ? (
                                <div className="invalid-feedback d-block">
                                  {I18n.t('something_went_wrong')}
                                </div>
                              ) : null}
                              <div className="invalid-feedback d-block">
                                {I18n.t('client_declined_project_delivery')}
                              </div>
                            </div>
                          ) : currentProject.status.id === 5 ? (
                            <div className="d-flex flex-column align-items-start w-100">
                              <ButtonWithLoading
                                variant="success"
                                type="submit"
                                className="btn-sm w-100"
                                onClick={onHandover}
                                data-backdrop="static"
                                loading={
                                  handoverProjectBidLoading
                                    ? handoverProjectBidLoading
                                    : null
                                }>
                                {I18n.t('project_handover')}
                              </ButtonWithLoading>
                              {errorsId &&
                              errorsId === 'HANDOVER_PROJECT_BID_FAIL' ? (
                                <div className="invalid-feedback d-block">
                                  {I18n.t('something_went_wrong')}
                                </div>
                              ) : null}
                            </div>
                          ) : null
                        ) : null}
                      </Fragment>
                    }
                    {isClient && !isOwnerOfSelectedBid ? (
                      (isOwnerOfThisProject && isProjectClosed) ||
                      !isOwnerOfThisProject ? (
                        <button
                          className="btn btn-primary w-100 btn-sm mb-2"
                          onClick={handleAddSimilarOnClick}>
                          <span className="icon icon-plus"></span>
                          {I18n.t('add_similar_project')}
                        </button>
                      ) : null
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <Card>
              <Card.Header>
                <div>
                  <span className="icon icon-business-case"></span>{' '}
                  {I18n.t('project_description')}
                </div>
              </Card.Header>
              <Card.Body>
                <div
                  dangerouslySetInnerHTML={createMarkup(description)}
                  className="listing__description"></div>
                {skills.length > 0 ? (
                  <Fragment>
                    <h2 className="inline_title mt-3 mb-2">
                      <span className="icon icon-skills"></span>{' '}
                      {I18n.t('required_skills')}:
                    </h2>
                    <div className="row">
                      <div className="col-12">
                        <ul className="ProfileSkills">
                          {skills.map(skill => (
                            <li key={skill}>{skill}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </Fragment>
                ) : null}
                {projectFiles.length > 0 ? (
                  <div className="ProjectDetails__Proposal__Content__attachments">
                    <h3>{I18n.t('attachments')}:</h3>
                    <ul>
                      {projectFiles.map((file, index) => (
                        <li key={index}>
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            <span className="fas fa-paperclip"></span>
                            {truncate(file.filename, 20)}
                          </a>
                          <span className="file__size ms-1">
                            ({Size(file.size)})
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}
              </Card.Body>
            </Card>
            {feedbacks && feedbacks.length > 0 ? (
              <Card>
                <Card.Header>
                  <div>{I18n.t('customer_feedback')}</div>
                </Card.Header>
                <Card.Body>
                  {
                    <Fragment>
                      <ul className="serviceFeedback__List">
                        {feedbacks.map(item => (
                          <li key={item.id}>
                            {item.user.type === 'Seller' &&
                            item.feedbacks.length > 0 ? (
                              <Fragment>
                                <div className="feedback__Buyer">
                                  <div className="Comment__userInfo">
                                    <div className="UserPic">
                                      <img
                                        src={item.feedbacks[0].user.image}
                                        alt={
                                          item.feedbacks[0].user.fname +
                                          ' ' +
                                          item.feedbacks[0].user.lname
                                        }
                                      />
                                    </div>
                                    <div className="CommentContent__Details">
                                      <h3>
                                        <Link
                                          to={{
                                            pathname: `/in/${item.feedbacks[0].user.username}`,
                                          }}>
                                          {item.feedbacks[0].user.fname +
                                            ' ' +
                                            item.feedbacks[0].user.lname}
                                        </Link>
                                        <span className="userType">
                                          {I18n.t('client')}
                                        </span>
                                      </h3>
                                      <div className="CommentContent__Details__PostTime">
                                        {item.feedbacks[0].created_at.Date +
                                          ' - ' +
                                          item.feedbacks[0].created_at.Time}
                                      </div>
                                    </div>
                                  </div>
                                  <RatingStarsPreview
                                    rate={item.feedbacks[0].rate}
                                  />
                                  <div className="CommentContent">
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.feedbacks[0].comment,
                                      )}></div>
                                  </div>
                                </div>
                                <div className="feedback__Seller">
                                  <div className="Comment__userInfo">
                                    <div className="UserPic">
                                      <img
                                        src={item.user.image}
                                        alt={
                                          item.user.fname +
                                          ' ' +
                                          item.user.lname
                                        }
                                      />
                                    </div>
                                    <div className="CommentContent__Details">
                                      <h3>
                                        <Link
                                          to={{
                                            pathname: `/in/${item.user.username}`,
                                          }}>
                                          {item.user.fname +
                                            ' ' +
                                            item.user.lname}
                                        </Link>
                                        <span className="userType">
                                          {I18n.t('freelancer')}
                                        </span>
                                      </h3>
                                      <div className="CommentContent__Details__PostTime">
                                        {item.created_at.Date +
                                          ' - ' +
                                          item.created_at.Time}
                                      </div>
                                    </div>
                                  </div>
                                  <RatingStarsPreview rate={item.rate} />
                                  <div className="CommentContent">
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.comment,
                                      )}></div>
                                  </div>
                                </div>
                              </Fragment>
                            ) : (
                              <Fragment>
                                <div className="feedback__Buyer">
                                  <div className="Comment__userInfo">
                                    <div className="UserPic">
                                      <img
                                        src={item.user.image}
                                        alt={
                                          item.user.fname +
                                          ' ' +
                                          item.user.lname
                                        }
                                      />
                                    </div>
                                    <div className="CommentContent__Details">
                                      <h3>
                                        <Link
                                          to={{
                                            pathname: `/in/${item.user.username}`,
                                          }}>
                                          {item.user.fname +
                                            ' ' +
                                            item.user.lname}
                                        </Link>
                                        <span className="userType">
                                          {I18n.t('client')}
                                        </span>
                                      </h3>
                                      <div className="CommentContent__Details__PostTime">
                                        {item.created_at.Date +
                                          ' - ' +
                                          item.created_at.Time}
                                      </div>
                                    </div>
                                  </div>
                                  <RatingStarsPreview rate={item.rate} />
                                  <div className="CommentContent">
                                    <div
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.comment,
                                      )}></div>
                                  </div>
                                </div>
                                {item.feedbacks.length > 0 ? (
                                  <div className="feedback__Seller">
                                    <div className="Comment__userInfo">
                                      <div className="UserPic">
                                        <img
                                          src={item.feedbacks[0].user.image}
                                          alt={
                                            item.feedbacks[0].user.fname +
                                            ' ' +
                                            item.feedbacks[0].user.lname
                                          }
                                        />
                                      </div>
                                      <div className="CommentContent__Details">
                                        <h3>
                                          <Link
                                            to={{
                                              pathname: `/in/${item.feedbacks[0].user.username}`,
                                            }}>
                                            {item.feedbacks[0].user.fname +
                                              ' ' +
                                              item.feedbacks[0].user.lname}
                                          </Link>
                                          <span className="userType">
                                            {I18n.t('freelancer')}
                                          </span>
                                        </h3>
                                        <div className="CommentContent__Details__PostTime">
                                          {item.feedbacks[0].created_at.Date +
                                            ' - ' +
                                            item.feedbacks[0].created_at.Time}
                                        </div>
                                      </div>
                                    </div>
                                    <RatingStarsPreview
                                      rate={item.feedbacks[0].rate}
                                    />
                                    <div className="CommentContent">
                                      <div
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.feedbacks[0].comment,
                                        )}></div>
                                    </div>
                                  </div>
                                ) : null}
                              </Fragment>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  }
                </Card.Body>
              </Card>
            ) : null}
            {!isProjectReceivingBids &&
            !isProjectUnderReview &&
            (isOwnerOfThisProject || isOwnerOfSelectedBid) ? (
              <ProjectComments
                projectDetails={currentProject}
                isOwnerOfThisProject={isOwnerOfThisProject}
                isOwnerOfSelectedBid={isOwnerOfSelectedBid}
                isProjectReceivingBids={isProjectReceivingBids}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
              />
            ) : (
              <ProjectProposals
                projectDetails={currentProject}
                isOwnerOfThisProject={isOwnerOfThisProject}
                isOwnerOfSelectedBid={isOwnerOfSelectedBid}
                isProjectReceivingBids={isProjectReceivingBids}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
              />
            )}
          </div>
        </Fragment>
      );
    } else if (
      errorsId === 'GET_PROJECT_BY_ID_FAIL' &&
      errorsMsg === 'Network Error'
    ) {
      return (
        <ConnectionLost
          errorType="connection"
          title="network_error_title"
          message="network_error_message"
        />
      );
    } else if (
      errorsId === 'GET_PROJECT_BY_ID_FAIL' &&
      errorStatus &&
      errorStatus === 401
    ) {
      return (
        <LockCard
          title="sign_to_view"
          signFor="sign_for_preview"
          fullPage={true}
        />
      );
    } else if (errorsId === 'GET_PROJECT_BY_ID_FAIL') {
      return (
        <ConnectionLost
          errorType="somethingWrong"
          title="something_error_title"
          message="something_error_message"
        />
      );
    } else {
      return <NoItems />;
    }
  }
}
