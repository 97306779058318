import {
    GET_PORTFOLIO_COMMENTS_SUCCESS, 
    GET_PORTFOLIO_COMMENTS_FAIL, 
    GET_PORTFOLIO_COMMENTS_LOADING,
    EDIT_PORTFOLIO_COMMENT_SUCCESS,
    ADD_PORTFOLIO_COMMENT_SUCCESS,
    DELETE_PORTFOLIO_COMMENT_SUCCESS,
    DELETE_PORTFOLIO_COMMENT_FAIL,
    DELETE_PORTFOLIO_COMMENT_LOADING,
    ADD_PORTFOLIO_COMMENT_LOADING,
    ADD_PORTFOLIO_COMMENT_FAIL,
    EDIT_PORTFOLIO_COMMENT_FAIL,
    EDIT_PORTFOLIO_COMMENT_LOADING,
    RESET_PORTFOLIO_COMMENTS
} from '../actions/types';

const initialState = {
    isLoading: false,
    deletePortfolioCommentLoading: false,
    addPortfolioCommentLoading: false,
    editPortfolioCommentLoading: false,
    totalRecords: 0,
    itemsPerPage: '0',
    portfolioitemComments: [],
    portfolioitemCommentEdited: "",
    portfolioitemCommentAdded: "",
    portfolioitemCommentDeleted: "",
}

export default function portfolioitemcommentsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PORTFOLIO_COMMENTS_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case GET_PORTFOLIO_COMMENTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                portfolioitemComments: action.payload.data
            };
        case GET_PORTFOLIO_COMMENTS_FAIL:
            return {
                ...state,
                isLoading: false,
        };
        case DELETE_PORTFOLIO_COMMENT_LOADING:
            return {
                ...state,
                deletePortfolioCommentLoading: true
            };
        case DELETE_PORTFOLIO_COMMENT_SUCCESS:
            return {
                ...state,
                deletePortfolioCommentLoading: false,
                portfolioitemCommentDeleted: action.payload.data
            };
        case DELETE_PORTFOLIO_COMMENT_FAIL:
            return {
                ...state,
                deletePortfolioCommentLoading: false,
        };
        case EDIT_PORTFOLIO_COMMENT_LOADING:
            return {
                ...state,
                editPortfolioCommentLoading: true,
        };
        case EDIT_PORTFOLIO_COMMENT_SUCCESS:
            return {
                ...state,
                editPortfolioCommentLoading: false,
                portfolioitemCommentEdited: action.payload.data
        };
        case EDIT_PORTFOLIO_COMMENT_FAIL:
            return {
                ...state,
                editPortfolioCommentLoading: false,
        };
        case ADD_PORTFOLIO_COMMENT_LOADING:
            return {
                ...state,
                addPortfolioCommentLoading: true,
        };
        case ADD_PORTFOLIO_COMMENT_SUCCESS:
            return {
                ...state,
                addPortfolioCommentLoading: false,
                portfolioitemCommentAdded: action.payload.data
        };
        case ADD_PORTFOLIO_COMMENT_FAIL:
            return {
                ...state,
                addPortfolioCommentLoading: false,
        };
        case RESET_PORTFOLIO_COMMENTS:
            return {
                ...state,
                portfolioitemCommentEdited: "",
                portfolioitemCommentAdded: "",
                portfolioitemCommentDeleted: "",
        };
        default:
            return state;
    }
}