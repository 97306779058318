export const GET_ITEMS = "GET_ITEMS";
export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const ITEMS_LOADING = "ITEMS_LOADING";

// AUTH + LOGIN & REGISTER & RESET PASSWORD
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_LOADING = "REGISTER_LOADING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const CLEAR_REGISTER = "CLEAR_REGISTER";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

// VERIFY ACCOUNT
export const VERIFY_ACCOUNT_LOADING = "VERIFY_ACCOUNT_LOADING";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAIL = "VERIFY_ACCOUNT_FAIL";
// SEND VERIFICATION CODE
export const SEND_VERIFICATION_LOADING = "SEND_VERIFICATION_LOADING";
export const SEND_VERIFICATION_SUCCESS = "SEND_VERIFICATION_SUCCESS";
export const SEND_VERIFICATION_FAIL = "SEND_VERIFICATION_FAIL";
export const RESET_VERIFICATION = "RESET_VERIFICATION";

//Categories Types
export const CATS_LOADING = "CATS_LOADING";
export const GET_CATS = "GET_CATS";
export const CATS_FAIL = "CATS_FAIL";

//Statuses Types
export const GET_STATUS = "GET_STATUS";
export const STATUS_LOADING = "STATUS_LOADING";
export const STATUS_FAIL = "STATUS_FAIL";

// Freelancer Types
export const FREELANCER_PROFILE_SUCCESS = "FREELANCER_PROFILE_SUCCESS";
export const FREELANCER_PROFILE_FAIL = "FREELANCER_PROFILE_FAIL";
export const FREELANCER_PROFILE_LOADING = "FREELANCER_PROFILE_LOADING";

//Keywords Types
export const GET_KEYWORDS_FAIL = "GET_KEYWORDS_FAIL";

//Countries Types
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL";

// Language Selector
export const SET_LANGUAGE_ARABIC = "SET_LANGUAGE_ARABIC";
export const SET_LANGUAGE_ENGLISH = "SET_LANGUAGE_ENGLISH";

//Update Profile Types
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const PROFILE_DATA_LOADING = "PROFILE_DATA_LOADING";
export const RESET_UPDATE_PROFILE = "RESET_UPDATE_PROFILE";

//Portfolio Types
//Add
export const ADD_PORTFOLIO_SUCCESS = "ADD_PORTFOLIO_SUCCESS";
export const ADD_PORTFOLIO_FAIL = "ADD_PORTFOLIO_FAIL";
export const PORTFOLIO_DATA_LOADING = "PORTFOLIO_DATA_LOADING";
//Edit
export const EDIT_PORTFOLIO_SUCCESS = "EDIT_PORTFOLIO_SUCCESS";
export const EDIT_PORTFOLIO_FAIL = "EDIT_PORTFOLIO_FAIL";
export const EDIT_PORTFOLIO_DATA_LOADING = "EDIT_PORTFOLIO_DATA_LOADING";
//DELETE
export const DELETE_PORTFOLIO_SUCCESS = "DELETE_PORTFOLIO_SUCCESS";
export const DELETE_PORTFOLIO_FAIL = "DELETE_PORTFOLIO_FAIL";
export const DELETE_PORTFOLIO_DATA_LOADING = "DELETE_PORTFOLIO_DATA_LOADING";

//Freelancers Types
export const GET_FREELANCERS_LOADING = "GET_FREELANCERS_LOADING";
export const GET_FREELANCERS_FAIL = "GET_FREELANCERS_FAIL";
export const GET_FREELANCERS_SUCCESS = "GET_FREELANCERS_SUCCESS";

//Get freelancer Portfolio Types
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_FAIL = "GET_PORTFOLIO_FAIL";
export const PORTFOLIO_ITEMS_LOADING = "PORTFOLIO_ITEMS_LOADING";
export const GET_PORTFOLIO_ITEM_SUCCESS = "GET_PORTFOLIO_ITEM_SUCCESS";
export const GET_PORTFOLIO_ITEM_FAIL = "GET_PORTFOLIO_ITEM_FAIL";
export const PORTFOLIO_ITEM_LOADING = "PORTFOLIO_ITEM_LOADING";
export const CLEAR_PORTFOLIO_ITEMS = "CLEAR_PORTFOLIO_ITEMS";

//Portfolio Comments
//GET
export const GET_PORTFOLIO_COMMENTS_SUCCESS = "GET_PORTFOLIO_COMMENTS_SUCCESS";
export const GET_PORTFOLIO_COMMENTS_FAIL = "GET_PORTFOLIO_COMMENTS_FAIL";
export const GET_PORTFOLIO_COMMENTS_LOADING = "GET_PORTFOLIO_COMMENTS_LOADING";
//ADD
export const ADD_PORTFOLIO_COMMENT_SUCCESS = "ADD_PORTFOLIO_COMMENT_SUCCESS";
export const ADD_PORTFOLIO_COMMENT_FAIL = "ADD_PORTFOLIO_COMMENT_FAIL";
export const ADD_PORTFOLIO_COMMENT_LOADING = "ADD_PORTFOLIO_COMMENT_LOADING";
//DELETE
export const DELETE_PORTFOLIO_COMMENT_SUCCESS =
  "DELETE_PORTFOLIO_COMMENT_SUCCESS";
export const DELETE_PORTFOLIO_COMMENT_FAIL = "DELETE_PORTFOLIO_COMMENT_FAIL";
export const DELETE_PORTFOLIO_COMMENT_LOADING =
  "DELETE_PORTFOLIO_COMMENT_LOADING";
//EDIT
export const EDIT_PORTFOLIO_COMMENT_SUCCESS = "EDIT_PORTFOLIO_COMMENT_SUCCESS";
export const EDIT_PORTFOLIO_COMMENT_FAIL = "EDIT_PORTFOLIO_COMMENT_FAIL";
export const EDIT_PORTFOLIO_COMMENT_LOADING = "EDIT_PORTFOLIO_COMMENT_LOADING";
//RESET
export const RESET_PORTFOLIO_COMMENTS = "RESET_PORTFOLIO_COMMENTS";
export const RESET_PORTFOLIO_ITEM = "RESET_PORTFOLIO_ITEM";

//Portfolio Likes
//POST
export const ADD_PORTFOLIO_LIKE_SUCCESS = "ADD_PORTFOLIO_LIKE_SUCCESS";
export const ADD_PORTFOLIO_LIKE_FAIL = "ADD_PORTFOLIO_LIKE_FAIL";
export const ADD_PORTFOLIO_LIKE_LOADING = "ADD_PORTFOLIO_LIKE_LOADING";
//GET
export const GET_PORTFOLIO_LIKES_SUCCESS = "GET_PORTFOLIO_LIKES_SUCCESS";
export const GET_PORTFOLIO_LIKES_FAIL = "GET_PORTFOLIO_LIKES_FAIL";
export const GET_PORTFOLIO_LIKES_LOADING = "GET_PORTFOLIO_LIKES_LOADING";

//User Follow
//POST
export const FOLLOW_USER_SUCCESS = "FOLLOW_USER_SUCCESS";
export const FOLLOW_USER_FAIL = "FOLLOW_USER_FAIL";
export const FOLLOW_USER_LOADING = "FOLLOW_USER_LOADING";
export const CLEAR_FOLLOW_USER_SUCCESS = "CLEAR_FOLLOW_USER_SUCCESS";

//User Unfollow
//POST
export const UNFOLLOW_USER_SUCCESS = "UNFOLLOW_USER_SUCCESS";
export const UNFOLLOW_USER_FAIL = "UNFOLLOW_USER_FAIL";
export const UNFOLLOW_USER_LOADING = "UNFOLLOW_USER_LOADING";
export const CLEAR_UNFOLLOW_USER_SUCCESS = "CLEAR_UNFOLLOW_USER_SUCCESS";

//GET Followers
export const FOLLOWERS_SUCCESS = "FOLLOWERS_SUCCESS";
export const FOLLOWERS_FAIL = "FOLLOWERS_FAIL";
export const FOLLOWERS_LOADING = "FOLLOWERS_LOADING";

//GET Followings
export const FOLLOWINGS_SUCCESS = "FOLLOWINGS_SUCCESS";
export const FOLLOWINGS_FAIL = "FOLLOWINGS_FAIL";
export const FOLLOWINGS_LOADING = "FOLLOWINGS_LOADING";

//DELETE File
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";
export const DELETE_FILE_LOADING = "DELETE_FILE_LOADING";

//GET Reports
export const REPORTS_SUCCESS = "REPORTS_SUCCESS";
export const REPORTS_FAIL = "REPORTS_FAIL";
export const REPORTS_LOADING = "REPORTS_LOADING";

//POST Report
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_FAIL = "POST_REPORT_FAIL";
export const POST_REPORT_LOADING = "POST_REPORT_LOADING";
export const POST_REPORT_RESET = "POST_REPORT_RESET";

//Services Types
//GET
export const GET_SERVICE_ITEM_SUCCESS = "GET_SERVICE_ITEM_SUCCESS";
export const GET_SERVICE_ITEM_FAIL = "GET_SERVICE_ITEM_FAIL";
export const SERVICE_ITEM_LOADING = "SERVICE_ITEM_LOADING";
//GET Service
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAIL = "GET_SERVICES_FAIL";
export const SERVICES_ITEMS_LOADING = "SERVICES_ITEMS_LOADING";
//Add
export const ADD_SERVICE_LOADING = "ADD_SERVICE_LOADING";
export const ADD_SERVICE_SUCCESS = "ADD_SERVICE_SUCCESS";
export const ADD_SERVICE_FAIL = "ADD_SERVICE_FAIL";
export const CLEAR_ADD_SERVICE_SUCCESS = "CLEAR_ADD_SERVICE_SUCCESS";
//Edit
export const EDIT_SERVICE_LOADING = "EDIT_SERVICE_LOADING";
export const EDIT_SERVICE_SUCCESS = "EDIT_SERVICE_SUCCESS";
export const EDIT_SERVICE_FAIL = "EDIT_SERVICE_FAIL";
export const CLEAR_EDIT_SERVICE_SUCCESS = "CLEAR_EDIT_SERVICE_SUCCESS";
//DELET
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS";
export const DELETE_SERVICE_FAIL = "DELETE_SERVICE_FAIL";
export const DELETE_SERVICE_DATA_LOADING = "DELETE_SERVICE_DATA_LOADING";
export const RESET_DELETE_SERVICE = "RESET_DELETE_SERVICE";
export const DELETE_SERVICE_FEATURE_LOADING = "DELETE_SERVICE_FEATURE_LOADING";
export const DELETE_SERVICE_FEATURE_SUCCESS = "DELETE_SERVICE_FEATURE_SUCCESS";
export const DELETE_SERVICE_FEATURE_FAIL = "DELETE_SERVICE_FEATURE_FAIL";

//GET USER SERVICES
export const GET_USER_SERVICES_LOADING = "GET_USER_SERVICES_LOADING";
export const GET_USER_SERVICES_SUCCESS = "GET_USER_SERVICES_SUCCESS";
export const GET_USER_SERVICES_FAIL = "GET_USER_SERVICES_FAIL";

//GET RELATED SERVICES
export const GET_RELATED_SERVICES_LOADING = "GET_RELATED_SERVICES_LOADING";
export const GET_RELATED_SERVICES_SUCCESS = "GET_RELATED_SERVICES_SUCCESS";
export const GET_RELATED_SERVICES_FAIL = "GET_RELATED_SERVICES_FAIL";

//GET SERVICES
export const GET_ALL_SERVICES_SUCCESS = "GET_ALL_SERVICES_SUCCESS";
export const GET_ALL_SERVICES_FAIL = "GET_ALL_SERVICES_FAIL";
export const ALL_SERVICES_ITEMS_LOADING = "ALL_SERVICES_ITEMS_LOADING";
export const CLEAR_ALL_SERVICES = "CLEAR_ALL_SERVICES";

//Purches SERVICE
export const PURCHES_SERVICE_SUCCESS = "PURCHES_SERVICE_SUCCESS";
export const PURCHES_SERVICE_FAIL = "PURCHES_SERVICE_FAIL";
export const PURCHES_SERVICE_LOADING = "PURCHES_SERVICE_LOADING";
export const CLEAR_PURCHES_SERVICE_SUCCESS = "CLEAR_PURCHES_SERVICE_SUCCESS";

//GET SERVICE INVOICES
export const GET_RUNNING_INVOICES_SUCCESS = "GET_RUNNING_INVOICES_SUCCESS";
export const GET_RUNNING_INVOICES_FAIL = "GET_RUNNING_INVOICES_FAIL";
export const GET_RUNNING_INVOICES_LOADING = "GET_RUNNING_INVOICES_LOADING";
export const GET_CLOSED_INVOICES_SUCCESS = "GET_CLOSED_INVOICES_SUCCESS";
export const GET_CLOSED_INVOICES_FAIL = "GET_CLOSED_INVOICES_FAIL";
export const GET_CLOSED_INVOICES_LOADING = "GET_CLOSED_INVOICES_LOADING";
export const GET_CANCELLED_INVOICES_LOADING = "GET_CANCELLED_INVOICES_LOADING";
export const GET_CANCELLED_INVOICES_SUCCESS = "GET_CANCELLED_INVOICES_SUCCESS";
export const GET_CANCELLED_INVOICES_FAIL = "GET_CANCELLED_INVOICES_FAIL";

//GET SERVICE INVOICE
export const GET_SERVICE_INVOICE_SUCCESS = "GET_SERVICE_INVOICE_SUCCESS";
export const GET_SERVICE_INVOICE_FAIL = "GET_SERVICE_INVOICE_FAIL";
export const GET_SERVICE_INVOICE_LOADING = "GET_SERVICE_INVOICE_LOADING";

//GET PORTFOLIOS
export const GET_ALL_PORTFOLIOS_SUCCESS = "GET_ALL_PORTFOLIOS_SUCCESS";
export const GET_ALL_PORTFOLIOS_FAIL = "GET_ALL_PORTFOLIOS_FAIL";
export const ALL_PORTFOLIOS_ITEMS_LOADING = "ALL_PORTFOLIOS_ITEMS_LOADING";

//ADD CLIENT REPLY
export const ADD_CLIENT_REPLY_SUCCESS = "ADD_CLIENT_REPLY_SUCCESS";
export const ADD_CLIENT_REPLY_FAIL = "ADD_CLIENT_REPLY_FAIL";
export const ADD_CLIENT_REPLY_LOADING = "ADD_CLIENT_REPLY_LOADING";
export const CLEAR_CLIENT_REPLY_SUCCESS = "CLEAR_CLIENT_REPLY_SUCCESS";

//Service Invoice Comments
//GET
export const GET_SERVICE_INVOICE_COMMENTS_SUCCESS =
  "GET_SERVICE_INVOICE_COMMENTS_SUCCESS";
export const GET_SERVICE_INVOICE_COMMENTS_FAIL =
  "GET_SERVICE_INVOICE_COMMENTS_FAIL";
export const GET_SERVICE_INVOICE_COMMENTS_LOADING =
  "GET_SERVICE_INVOICE_COMMENTS_LOADING";
  export const GET_SERVICE_INVOICE_LAST_COMMENT = "GET_SERVICE_INVOICE_LAST_COMMENT";
//ADD
export const ADD_SERVICE_INVOICE_COMMENT_SUCCESS =
  "ADD_SERVICE_INVOICE_COMMENT_SUCCESS";
export const ADD_SERVICE_INVOICE_COMMENT_FAIL =
  "ADD_SERVICE_INVOICE_COMMENT_FAIL";
export const ADD_SERVICE_INVOICE_COMMENT_LOADING =
  "ADD_SERVICE_INVOICE_COMMENT_LOADING";
//DELETE
export const DELETE_SERVICE_INVOICE_COMMENT_SUCCESS =
  "DELETE_SERVICE_INVOICE_COMMENT_SUCCESS";
export const DELETE_SERVICE_INVOICE_COMMENT_FAIL =
  "DELETE_SERVICE_INVOICE_COMMENT_FAIL";
export const DELETE_SERVICE_INVOICE_COMMENT_LOADING =
  "DELETE_SERVICE_INVOICE_COMMENT_LOADING";
//EDIT
export const EDIT_SERVICE_INVOICE_COMMENT_SUCCESS =
  "EDIT_SERVICE_INVOICE_COMMENT_SUCCESS";
export const EDIT_SERVICE_INVOICE_COMMENT_FAIL =
  "EDIT_SERVICE_INVOICE_COMMENT_FAIL";
export const EDIT_SERVICE_INVOICE_COMMENT_LOADING =
  "EDIT_SERVICE_INVOICE_COMMENT_LOADING";
  //RESET
  export const RESET_SERVICE_INVOICE_COMMENTS =
  "RESET_SERVICE_INVOICE_COMMENTS";

// Post Invoice Status
export const POST_INVOICE_STATUS_LOADING = "POST_INVOICE_STATUS_LOADING";
export const POST_INVOICE_STATUS_SUCCESS = "POST_INVOICE_STATUS_SUCCESS";
export const POST_INVOICE_STATUS_FAIL = "POST_INVOICE_STATUS_FAIL";

// Post Invoice Rate
export const POST_INVOICE_RATING_LOADING = "POST_INVOICE_RATING_LOADING";
export const POST_INVOICE_RATING_SUCCESS = "POST_INVOICE_RATING_SUCCESS";
export const POST_INVOICE_RATING_FAIL = "POST_INVOICE_RATING_FAIL";

// Projects

// GET Projects
export const GET_PROJECTS_LOADING = "GET_PROJECTS_LOADING";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAIL = "GET_PROJECTS_FAIL";

// GET Project by Id
export const GET_PROJECT_BY_ID_LOADING = "GET_PROJECT_BY_ID_LOADING";
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const GET_PROJECT_BY_ID_FAIL = "GET_PROJECT_BY_ID_FAIL";

// ADD Project
export const ADD_PROJECT_LOADING = "ADD_PROJECT_LOADING";
export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS";
export const ADD_PROJECT_FAIL = "ADD_PROJECT_FAIL";

// Project Prices
export const GET_PROJECTS_PRICESS_LOADING = "GET_PROJECTS_PRICES";
export const GET_PROJECTS_PRICES_SUCCESS = "GET_PROJECTS_PRICES_SUCCESS";
export const GET_PROJECTS_PRICES_FAIL = "GET_PROJECTS_PRICES_FAIL";

// SKILLS
export const SKILLS_DATA_LOADING = "SKILL_DATA_LOADING";
export const GET_SKILLS_SUCCESS = "GET_SKILLS_SUCCESS";
export const GET_SKILLS_FAIL = "GET_SKILLS_FAIL";

// PAGES
export const GET_PAGES_LOADING = "GET_PAGES_LOADING";
export const GET_PAGES_SUCCESS = "GET_PAGES_SUCCESS";
export const GET_PAGES_FAIL = "GET_PAGES_FAIL";

// SOCIAL
export const GET_SOCIALS_LOADING = "GET_SOCIALS_LOADING";
export const GET_SOCIALS_SUCCESS = "GET_SOCIALS_SUCCESS";
export const GET_SOCIALS_FAIL = "GET_SOCIALS_FAIL";
