import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Pagination from "react-js-pagination";
import AddingRow from "../AddingRow";
import { getAllNotifications, markAsRead, markAllNotificationAsRead } from "../../redux/notification/actions/getActions";
import Spin from "../Spin";
import NoItems from "../NoItems";
import { I18n } from 'react-redux-i18n';
import logoIcon from '../../imgs/logo-icon.png';
import { MetaComponent } from '../MetaComponent';
import { TitleComponent } from "../../components/TitleComponent";
import DeleteModal from "../DeleteModal";
import { CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS } from "../../redux/notification/actions/types";
import ConfToast from "../ConfToast";


const MyNotifications = () => {
  const dispatch = useDispatch();

  const{
    auth,
    userLoading,
    isAuthenticated,
    locale,

    //All Notifications
    getAllNotificationsLoading,
    allNotifications,
    totalAllNotifications,
    allNotificationsPerPage,

    //Mark All Messages Read
    loadingMarknotificationsRead,
    markNotificationsSuccess,
  } = useSelector((state) => ({
    auth: state.auth,
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    locale: state.i18n.locale,

    //All Notifications
    getAllNotificationsLoading: state.notifications.getAllNotificationsLoading,
    allNotifications: state.notifications.allNotifications,
    totalAllNotifications: state.notifications.totalAllNotifications,
    allNotificationsPerPage: state.notifications.allNotificationsPerPage,

    //Mark All Messages Read
    loadingMarknotificationsRead: state.notifications.loadingMarknotificationsRead,
    markNotificationsSuccess: state.notifications.markNotificationsSuccess,
  }));

  const [activePage, setActivePage] = useState(1);
  const [loadingNotifications, setLoadingNotifications] = useState(true);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = () => setShowDeleteConfirm(true);

  useEffect(() => {
    setLoadingNotifications(getAllNotificationsLoading);
  }, [getAllNotificationsLoading]);

  useEffect(() => {
    if (isAuthenticated){
        const page = activePage;
        const limit = 20;
        dispatch(getAllNotifications(page, limit, locale));
    }
  },[dispatch, isAuthenticated, locale, activePage]);

  useEffect(() => {
    if(markNotificationsSuccess){
      handleDeleteConfirmClose();
      dispatch({
        type: CLEAR_MARK_ALL_NOTIFICATIONS_READ_SUCCESS
      });
      if(activePage === 1){
        const page = 1;
        const limit = 20;
        dispatch(getAllNotifications(page, limit, locale));
      }else{
        setActivePage(1);
      }
      setTimeout(() => {
        setConfShow(true);
        setConfTitle(I18n.t('mark_all_notifications_read'));
        setConfMessage(I18n.t('mark_all_notifications_read_success'));
        setConfStatus('Toast__Container__Success');
        setDelay(6000);
      }, 3000);
    }
  }, [markNotificationsSuccess, locale, dispatch, activePage]);

  function handlePageChange(pageNumber){
    setActivePage(pageNumber);
  };

  const totalItemsCount = totalAllNotifications;
  const itemsCountPerPage = Number(allNotificationsPerPage);

  function markNotificationRead(id){
      dispatch(markAsRead(id));
  }

  function handleMarkNNotificationsRead() {
    dispatch(markAllNotificationAsRead());
  }
  
  return (
    <Fragment>
        <ConfToast
            showConf={confShow}
            setShowConf={setConfShow}
            title={confTitle}
            message={confMessage}
            status={confStatus}
            delay={delay}
        />
        <TitleComponent title={I18n.t('lancersin_notifications')} />
        <MetaComponent />
        <DeleteModal
            onHide={handleDeleteConfirmClose}
            onDelete={handleMarkNNotificationsRead}
            show={showDeleteConfirm}
            modalTitle={"mark_all_notifications_read"}
            className="CustomPopup"
            size="md"
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            loading={loadingMarknotificationsRead}
            messageHeading="mark_all_notifications_read_body"
        />
        <div className="container">
            <div className="row">
            <div className="col-12 Dashboard__tasks__List">
                <AddingRow
                onAdding={() => {
                    handleDeleteConfirmShow();
                }}
                title={"notifications"}
                titleIconClassName={"icon-notification"}
                btnText={"mark_all_notifications_read"}
                btnIconClassName={"fas fa-check"}
                forceShowButton={true}
                />
                {
                    loadingNotifications || userLoading ?
                    <Spin />
                    : allNotifications.length > 0 && !loadingNotifications ?
                    <div className="notificationList box-shadow">
                        <ul className="list">
                        {
                            allNotifications.map(item => (
                            item.model === "projects" ?
                                item.type === "ProjectStatusChangedByAdmin" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('admin_approve_project')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "CreateBid" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('added_a_bid') + " " + item.content.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "SelectBid" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('select_a_bid') + " " + item.content.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "CloseProject" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{I18n.t('project_of') + " " + item.content.title + " " + I18n.t('delivered_success')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectsInvitation" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('invite_to_project') + " " + item.content.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                //The Below For Project Handover
                                : item.type === "BidReportAdminDeclined" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('project_cancel_request_declined')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectBidReportAdminApproved" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('project_cancel_request_approved')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectBidHandover" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname + " " + item.user.lname : "") + " " + I18n.t('delivered_order') }</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectBidCancelationRequest" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname + " " + item.user.lname : "") + " " + I18n.t('request_to_cancel_project') + " " + item.content.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "BidReportAdminApproved" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('project_cancel_request_approved')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectFeedback" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span>  
                                            <div className='messageContent'>
                                                <p>{(item.user ? item.user.fname : "") + " " + (auth.user.id ===  item.content.user.id ?  I18n.t('freelancer_feedback_done') : I18n.t('client_feedback_done'))}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectWasRecentlyCreated" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            <span className="userImg"> <img src={item.content.user.image.url ? item.content.user.image.url : item.content.user.image} alt={item.content.user.fname + ' ' + item.content.user.lname} /></span>  
                                            <div className='messageContent'>
                                                <p>{(item.content.user ? item.content.user.fname : "") + " " + I18n.t('recently_added_project')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{item.content.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : null
                            : item.model === "project_comments" ?
                                item.type === "CreateProjectComment" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/projects/${item.content.project.id}/${item.content.project.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('added_a_comment') + " " + item.content.project.title}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "project_bid_reports" ?
                                item.type === "ProjectBidRequestDeclined" && item.content && item.content.type && item.content.type === "handover"?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.project.id}/${item.content.project.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('client_declined_project_delivery_notification') }</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectBidRequestApproved" && item.content && item.content.type && item.content.type === "handover" ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.project.id}/${item.content.project.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }
                                            <div className='messageContent'>
                                                <p>{I18n.t('project_delivered_message')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "ProjectBidRequestDeclined" && item.content && item.content.type && item.content.type === "cancel" ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/projects/${item.content.project.id}/${item.content.project.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ I18n.t('project_cancel_request_declined')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : null
                            : item.model === "services" ?
                                item.type === "ServiceStatusChangedByAdmin" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                        { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                        <div className='messageContent'>
                                            <p>{I18n.t('admin_approve_service')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.type === "ServiceWasRecentlyCreated" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/${item.content.id}/${item.content.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                        <span className="userImg"> <img src={item.content.user.image.url ? item.content.user.image.url : item.content.user.image} alt={item.content.user.fname + ' ' + item.content.user.lname} /></span>  
                                        <div className='messageContent'>
                                            <p>{(item.content.user ? item.content.user.fname : "") + " " + I18n.t('recently_added_service')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "service_invoice_comments" ?
                                item.type === "CreateServiceComment" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('added_a_comment') + " " + item.content.invoice.service.title}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{item.content.invoice.service.title}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "service_invoice_reports" ?
                                item.type === "InvoiceReportAdminDeclined" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('service_cancel_request_declined')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceReportAdminApproved" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                            <div className='messageContent'>
                                                <p>{I18n.t('service_cancel_request_approved')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceHandover" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname + " " + item.user.lname : "") + " " + I18n.t('delivered_order') }</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceRequestDeclined" && item.content && item.content.type && item.content.type === "handover"?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname + " " + item.user.lname : "") + " " + I18n.t('client_declined_delivery_notification') }</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceRequestApproved" && item.content && item.content.type && item.content.type === "handover" ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }
                                            <div className='messageContent'>
                                                <p>{I18n.t('service_delivered_message')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceRequestDeclined" && item.content && item.content.type && item.content.type === "cancel" ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ I18n.t('service_cancel_request_declined')}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : item.type === "InvoiceCancelationRequest" && item.content ?
                                    <li key={item.id}>
                                        <Link exact="true" to={{ pathname: `/services/invoices/${item.content.invoice.id}/${item.content.invoice.service.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                            { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                            <div className='messageContent'>
                                                <p>{ (item.user ? item.user.fname + " " + item.user.lname : "") + " " + I18n.t('request_to_cancel_invoice') + " " + item.content.invoice.service.title}</p>
                                                <div className="notification__Date">
                                                    <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                                </div>
                                            </div>
                                            {item.read_at ? null : <span className='unread'></span>}
                                        </Link>
                                    </li>
                                : null
                            : item.model === "service_invoices" ?
                                item.type === "PurchaseService" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.id}/${item.content.service.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('purchased_service') + " " + item.content.service}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.type === "CreateInvoiceReply" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.id}/${item.content.service.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }    
                                        <div className='messageContent'>
                                            <p>{item.user.fname + " " + I18n.t('added_requirments') + " " + item.content.service}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.type === "PurchaseServiceClient" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.id}/${item.content.service.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p><span className="specialText">{I18n.t('reminder')}</span> {I18n.t('please_provide_instruction')} <span className="specialText">{item.user ? item.user.fname + " " + item.user.lname : ""}</span> {I18n.t('can_start_order')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.type === "CloseInvoice" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.id}/${item.content.service.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{I18n.t('service_of') + " " + item.content.service + " " + I18n.t('delivered_success')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : item.type === "ServiceInvoiceFeedback" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/services/invoices/${item.content.id}/${item.content.service.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                        <p>{(item.user ? item.user.fname : "") + " " + (auth.user.id ===  item.content.user_id ?  I18n.t('client_feedback_done') : I18n.t('freelancer_feedback_done'))}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "portfolio_likes" ?
                                item.type === "LikePortfolio" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/portfolio/${item.content.portfolio.id}/${item.content.portfolio.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('liked_portfolio') + " " + item.content.portfolio.title}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "portfolio_comments" ?
                                item.type === "CommentPortfolio" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/portfolio/${item.content.portfolio.id}/${item.content.portfolio.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + I18n.t('added_portfolio_comment') + " " + item.content.portfolio.title}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "users" ?
                                item.type === "following" && item.content ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/dashboard/followers` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item">
                                        { <span className="userImg"> <img src={item.user.image} alt={item.user.fname + ' ' + item.user.lname} /></span> }
                                        <div className='messageContent'>
                                            <p>{ (item.user ? item.user.fname : "") + " " + (item.user ? item.user.lname : "") + " " + I18n.t('followed_you')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : item.model === "withdrawals" ?
                                item.type === "WithdrawalApproved" ?
                                <li key={item.id}>
                                    <Link exact="true" to={{ pathname: `/` }} onClick={() => markNotificationRead(item.id)} className="dropdown-item dropdownn-reminder">
                                        { <span className="userImg"> <img src={logoIcon} alt={I18n.t('lancersin_tech')} /></span> }    
                                        <div className='messageContent'>
                                            <p>{I18n.t('admin_approve_withdrawal')}</p>
                                            <div className="notification__Date">
                                                <span className="icon icon-calender-time"></span>{item.created_at.Date} - {item.created_at.Time}
                                            </div>
                                        </div>
                                        {item.read_at ? null : <span className='unread'></span>}
                                    </Link>
                                </li>
                                : null
                            : null
                            ))
                        }
                        </ul>
                        <Pagination
                            activePage={activePage}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={10}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>
                    : 
                    <NoItems
                    title="no_notifications_found"
                    message="no_notifications_body"
                    />
                }
            </div>
            </div>
        </div>
    </Fragment>
  );
};

export default MyNotifications;
