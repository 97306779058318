import React, { Fragment, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Spin from "../Spin";
import { I18n } from "react-redux-i18n";
import NoItems from '../NoItems';
import { clearErrors } from "../../actions/errorActions";
import AddingRow from "../../components/AddingRow";
import AddingPaymentModal from "../AddingPaymentModal";
import ConfToast from "../ConfToast";
import { getWithdrawalRequests } from "../../redux/payments/actions/getActions";
import Pagination from "react-js-pagination";
import StatusLabel from "../../StatusLabel";

const MyWithdrawalRequests = () => {

    const dispatch = useDispatch();

    const {
        isAuthenticated,
        locale,

        requestWithdrawalLoading,
        getWithdrawalRequestsLoading,
        withdrawalRequests,
        totalWithdrawalRequests,
        withdrawalRequestsPerPage,
    } = useSelector((state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        locale: state.i18n.locale,

        requestWithdrawalLoading: state.payments.requestWithdrawalLoading,
        getWithdrawalRequestsLoading: state.payments.getWithdrawalRequestsLoading,
        withdrawalRequests: state.payments.withdrawalRequests,
        totalWithdrawalRequests: state.payments.totalWithdrawalRequests,
        withdrawalRequestsPerPage: state.payments.withdrawalRequestsPerPage,
    }));

    const [lgShow, setLgShow] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [loadingWithdrawalRequests, setLoadingWithdrawalRequests] = useState(true);

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };

    useEffect(() => {
        setLoadingWithdrawalRequests(getWithdrawalRequestsLoading);
    }, [getWithdrawalRequestsLoading]);

    useEffect(() => {
      if(isAuthenticated){
        const page = activePage;
        const limit = 20;
        dispatch(getWithdrawalRequests(page, limit, null, locale));
      }
    }, [dispatch, isAuthenticated, activePage, locale]);

    function handlePageChange(pageNumber){
        setActivePage(pageNumber);
    };

    const totalItemsCount = totalWithdrawalRequests;
    const itemsCountPerPage = Number(withdrawalRequestsPerPage);
    

    return (
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <AddingPaymentModal
                loading={requestWithdrawalLoading}
                modalTitle={"withdrawal_request"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
                paymentType="Withdrawal"
            />
            <TitleComponent title={I18n.t('lancersin_withdrawal_accounts')} />
            <MetaComponent />
            <AddingRow
                onAdding={() => {
                    handleShow();
                }}
                forceShowButton={true}
                title={"withdrawal_requests"}
                //titleIconClassName={"icon-services"}
                btnText={"add_request"}
                btnIconClassName={"icon-plus"}
            />
            {
            loadingWithdrawalRequests ?
                <Spin />
            : 
            withdrawalRequests && withdrawalRequests.length > 0 ?
                <Fragment>
                    {
                        withdrawalRequests.map( item => (
                            <div className="Profile-Card box-shadow Withdrawal__List" key={item.id}>
                                <div className="userProfile__Contacts">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div><b>{I18n.t('request_number')}</b> {item.id}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div><b>{I18n.t('request_amount')}</b> {item.amount + " " + I18n.t('dollar')}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div><b>{I18n.t('withdrawal_method')}</b> {item.payment_method === "Paypal" ? "PayPal" : I18n.t('bank_account')}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div><b>{I18n.t('created_at')}</b> {item.created_at.Date + " - " + item.created_at.Time}</div>
                                        </div>
                                        <div className="col-md-6">
                                            <div><b>{I18n.t('withdrawal_status')}</b> { item.approved_at ? <div className="d-inline-block"><StatusLabel status_id="1" type="withdrawal" /></div> : <div className="d-inline-block"><StatusLabel status_id="0" type="withdrawal" /></div>}</div>
                                        </div>
                                        { 
                                            item.approved_at ?
                                            <div className="col-md-6">
                                                <div><b>{I18n.t('Transfer_date')}</b> {item.approved_at.Date + " - " + item.approved_at.Time}</div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Fragment>
            :
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <NoItems
                            title="withdrawal_requests"
                            message="no_withdrawal_requests"
                        />
                    </div>
                </div>
            </Fragment>
            }
        </Fragment>
    );
};

export default MyWithdrawalRequests;
