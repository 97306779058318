import React, {Fragment} from 'react';
import Pagination from "react-js-pagination";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {Num} from '../Numbers';
import NoItems from '../NoItems';
import Spin from '../Spin';

const Portfolio = (props) => {
    const {
        totalRecords,
        itemsPerPage,
        loadingportfolio,
        freelancerportfolio,
    } = useSelector((state) => ({
        totalRecords: state.freelancerportfolio.totalRecords,
        itemsPerPage: state.freelancerportfolio.itemsPerPage,
        loadingportfolio: state.freelancerportfolio.loadingportfolio,
        freelancerportfolio: state.freelancerportfolio.portfolios,
    }));

    const {
        activePage,
        setActivePage
    } = props;

    const handlePageChange = (pageNumber) =>{
        setActivePage(pageNumber);
    }

    //Get Portfolios
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);
    return(
        <Fragment>
            <div className="ShowCase__List">
                {
                    loadingportfolio ?
                        <Spin />   
                    :
                    freelancerportfolio.length > 0 ?
                    <div className="row">
                        {
                            freelancerportfolio.map( item => (
                            <div className="col-md-6 col-lg-6 col-12" key={item.id}>
                                <div className="freelancers__listItem box-shadow">
                                    <figure>
                                        <Link to={{pathname:`/portfolio/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}>
                                            <p className="portfolio__title">{item.title}</p>
                                            <img src={item.image} alt={item.title} className="img-fluid" />                                        
                                        </Link>
                                    </figure>
                                    <figure>
                                        <figcaption className="justify-content-center">
                                            <p className="icon-like">
                                                {Num(item.likes_count)}
                                            </p>
                                        </figcaption>
                                    </figure>
                                    <Link to={{pathname:`/portfolio/${item.id}/${item.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`}}><i className="icon-arrow-right goToTarget" aria-hidden /><span className="More__Link">More</span></Link>
                                </div>
                            </div>
                            ))
                        }
                        <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div>
                    :
                    <NoItems />
                }
            </div>
        </Fragment>
    )
}

export default Portfolio;