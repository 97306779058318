import { 
    ADD_PORTFOLIO_LIKE_SUCCESS,
    ADD_PORTFOLIO_LIKE_FAIL,
    ADD_PORTFOLIO_LIKE_LOADING,
    GET_PORTFOLIO_LIKES_SUCCESS,
    GET_PORTFOLIO_LIKES_FAIL,
    GET_PORTFOLIO_LIKES_LOADING,
    RESET_PORTFOLIO_ITEM
} from '../actions/types';

const initialState = {
    portfolioLikesLoading: false,
    addPortfolioLikeLoading: false,
    portfolioitemLikeAdded: '',
    portfolioitemLikes: [],
}

export default function portfoliolikesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PORTFOLIO_LIKES_LOADING:
            return {
                ...state,
                portfolioLikesLoading: true
            };
        case GET_PORTFOLIO_LIKES_SUCCESS:
            return {
                ...state,
                portfolioLikesLoading: false,
                portfolioitemLikes: action.payload.data
            };
        case GET_PORTFOLIO_LIKES_FAIL:
            return {
                ...state,
                portfolioLikesLoading: false,
        };
        case ADD_PORTFOLIO_LIKE_LOADING:
            return {
                ...state,
                addPortfolioLikeLoading: true
            };
        case ADD_PORTFOLIO_LIKE_SUCCESS:
            return {
                ...state,
                addPortfolioLikeLoading: false,
                portfolioitemLikeAdded: action.payload.data
            };
        case ADD_PORTFOLIO_LIKE_FAIL:
            return {
                ...state,
                addPortfolioLikeLoading: false,
        };
        case RESET_PORTFOLIO_ITEM:
            return{
                ...state,
                portfolioitemLikeAdded: '',
            };
        default:
            return state;
    }
}