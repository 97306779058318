import React from 'react';
import { Link } from "react-router-dom";
import { I18n } from "react-redux-i18n";

export default function NoPermisions(){
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center">
                        <div className="NoPermissions__Card">
                            <i className="fas fa-exclamation-triangle"></i>
                            <h2>{I18n.t('no_permissions_message')}</h2>
                            <Link to="/" className="btn btn-primary"><i className="fas fa-home"></i> {I18n.t('back_to_home')}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}