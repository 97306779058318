import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import ProjectCard from "../ProjectCard";
import { getProjects } from "../../redux/project/actions/getActions";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import { I18n } from "react-redux-i18n";

const CancelledProjects = (props) => {
  const dispatch = useDispatch();
  const {
    projects = [],
    getUserProjectsLoading,
    user,
    totalRecords,
    itemsPerPage,
    isLoading,
    errorId,
    errorMessage,
    locale,
  } = useSelector((state) => ({
    projects: state.project.userProjectsCancelled,
    getUserProjectsLoading: state.project.getUserProjectsCancelledLoading,
    user: state.auth.user,
    isLoading: state.auth.isLoading,
    totalRecords: state.project.cancelledTotalRecords,
    itemsPerPage: state.project.cancelledItemsPerPage,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    locale: state.i18n.locale,
  }));

  const [activePage, setActivePage] = useState(1);
  const [loadingProjects, setLoadingProjects] = useState(true);

  useEffect(() => {
    setLoadingProjects(getUserProjectsLoading);
  }, [getUserProjectsLoading]);

  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const { id } = user;

  useEffect(() => {
    if (id) {
      let user_id = id;
      const page = activePage;
      const limit = 10;
      const status_id = 7; //Cancelled Status Id
      dispatch(getProjects(user_id, null, page, limit, null, null, null, null, null, null, null, status_id, null, locale));
    }
  }, [id, dispatch, locale, activePage]);

  return (
    <Fragment>
        <TitleComponent title={I18n.t('lancersin_projects_cancelled')} />
        <MetaComponent />
        <div className="row">
        {
          loadingProjects || isLoading ?
            <Spin />
          : errorId === "GET_USER_PROJECTS_CANCELLED_FAIL" && errorMessage === "Network Error" ?
          <div className="col-12">
              <ConnectionLost
                errorType= "connection"
                title = "network_error_title"
                message = "network_error_message"
              />
          </div>
          : errorId === "GET_USER_PROJECTS_CANCELLED_FAIL" ?
          <div className="col-12">
              <ConnectionLost
                errorType= "somethingWrong"
                title = "something_error_title"
                message = "something_error_message"
              />
          </div>
          : projects.length > 0 && !loadingProjects ?
          <Fragment>
            {
              projects.map((item) => (
              <ProjectCard key={`project-#${item.id}`} item={item} />
              ))
            }
            <div className="col-12 my-4">
              <Pagination
                  activePage={activePage}
                  itemsCountPerPage={itemsCountPerPage}
                  totalItemsCount={totalItemsCount}
                  pageRangeDisplayed={10}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
              />
            </div>
          </Fragment>
          :
          <div className="col-12">
            <NoItems />
          </div>
        }
        </div>
    </Fragment>
  );
};

export default CancelledProjects;
