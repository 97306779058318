import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Helmet from 'react-helmet';
import {Link, NavLink, useHistory, useLocation} from 'react-router-dom';
import {
  setLangEnglish,
  setLangArabic,
  setLocaleWithFallback,
} from '../../actions/langActions';
import {logout} from '../../actions/authActions';
import {AUTH_ERROR} from '../../actions/types';
import {I18n, Translate} from 'react-redux-i18n';
import {useLayer, Arrow} from 'react-laag';
import {motion, AnimatePresence} from 'framer-motion';
import {MetaComponent} from '../MetaComponent';
import DropdownMenu from '../DropdownMenu';
import {
  getNotifications,
  markAsRead,
} from '../../redux/notification/actions/getActions';
import {
  getMessages,
  markMessageAsRead,
} from '../../redux/messages/actions/getActions';
import {CLEAR_LAST_NOTIFICATION} from '../../redux/notification/actions/types';
import {
  CLEAR_LAST_MESSAGE,
  CLEAR_MARK_MESSAGE_READ_SUCCESS,
} from '../../redux/messages/actions/types';
import {CLEAR_MARK_NOTIFICATION_READ_SUCCESS} from '../../redux/notification/actions/types';
import audioSound from '../../imgs/notification.mp3';
import NoItems from '../NoItems';
import {CLEAR_MESSAGE_REPLIES} from '../../redux/messages/actions/types';
import Spin from '../Spin';
import logoIcon from '../../imgs/logo-icon.png';
import {getCats} from '../../actions/catsActions';
import {getProjectsPrices} from '../../redux/project/actions/getActions';
import {getStatus} from '../../actions/statusActions';
import ConfToast from '../ConfToast';
import AddingProjectModal from '../AddingProjectModal';
import {getUserData} from '../../redux/userData/actions/getActions';

export default function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    lang,
    currentUser,
    isAuthenticated,
    auth,
    locale,
    errorStatus,
    errorId,
    errors,
    cats,
    projectsPrices,
    statuses,

    //Notifications
    getNotificationsLoading,
    lastNotification,
    notifications,
    unreadNotifications,
    markNotificationSuccess,
    markNotificationsSuccess,

    //Messages
    getMessagesLoading,
    lastMessage,
    messages,
    unreadMessages,
    markMessageSuccess,
    markMessagesSuccess,

    addingProjectLoading,

    user,
  } = useSelector(state => ({
    lang: state.lang,
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    auth: state.auth,
    locale: state.i18n.locale,
    errorStatus: state.error.status,
    errorId: state.error.id,
    errors: state.error.msg,
    cats: state.category.cats,
    projectsPrices: state.project.projectsPrices,
    statuses: state.status.statuses,

    //Notifications
    getNotificationsLoading: state.notifications.getNotificationsLoading,
    lastNotification: state.notifications.lastNotification,
    notifications: state.notifications.notifications,
    unreadNotifications: state.notifications.unread,
    markNotificationSuccess: state.notifications.markNotificationSuccess,
    markNotificationsSuccess: state.notifications.markNotificationsSuccess,

    //Messages
    getMessagesLoading: state.messages.getMessagesLoading,
    lastMessage: state.messages.lastMessage,
    messages: state.messages.messages,
    unreadMessages: state.messages.unread,
    markMessageSuccess: state.messages.markMessageSuccess,
    markMessagesSuccess: state.messages.markMessagesSuccess,

    addingProjectLoading: state.project.addingProjectLoading,
    user: state.userData.user,
  }));

  const [navMenuClicked, setNavMenuClicked] = useState(false);
  const [theLastNotification, setTheLastNotification] = useState(null);
  const [theLastMessage, setTheLastMessage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [messagesOpen, setMessagesOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [toggleAddProjectModelHeader, setToggleAddProjectModelHeader] =
    useState(false);
  const [isClient, setIsClient] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [audio] = useState(
    typeof window !== 'undefined' && new Audio(audioSound),
  );
  const [show, setShow] = useState(false);
  const [prevPath, setPrevPath] = useState(null);

  // const onRouteChanged = useCallback(() => {
  //     if(typeof window !== 'undefined'){
  //             function lazyLoading() {
  //                 var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-background"));
  //                 if ("IntersectionObserver" in window) {
  //                     let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
  //                     entries.forEach(function(entry) {
  //                         if (entry.isIntersecting) {
  //                         entry.target.classList.add("visible");
  //                         lazyBackgroundObserver.unobserve(entry.target);
  //                         }
  //                     });
  //                     });

  //                     lazyBackgrounds.forEach(function(lazyBackground) {
  //                     lazyBackgroundObserver.observe(lazyBackground);
  //                     });
  //                 }
  //             }
  //             document.addEventListener("DOMContentLoaded", lazyLoading);
  //             console.log("route changed")
  //             return () => {
  //                 document.removeEventListener('DOMContentLoaded', lazyLoading);
  //             };
  //     }
  //   }, []);

  // useEffect(() => {
  //     onRouteChanged();
  // }, [location, onRouteChanged]);

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.href.indexOf('path=') > -1) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        if (url.searchParams) {
          var path = url.searchParams.get('path');
          setPrevPath(path);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.href.indexOf('referrer=') > -1) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        if (url.searchParams) {
          var referrer = url.searchParams.get('referrer');
          localStorage.setItem('referrer', referrer);
        }
      }
      if (window.location.href.indexOf('fbclid=') > -1) {
        localStorage.setItem('referrer', 'FacebookAds');
      }
      if (
        window.location.href.indexOf('referrer=GoogleAdsClient') > -1 ||
        window.location.href.indexOf('referrer=GoogleAdsClient2') > -1 ||
        window.location.href.indexOf('referrer=GoogleAdsClientWriting') > -1
      ) {
        setShow(true);
      }
    }
  }, []);

  useEffect(() => {
    if (cats && cats.length <= 0) {
      dispatch(getCats(locale));
    }
  }, [dispatch, locale, cats]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(getUserData(locale));
    }
  }, [dispatch, locale, isAuthenticated]);

  useEffect(() => {
    if (user && user.memberships && user.memberships.length >= 1) {
      user.memberships.map(item => {
        if ((item.id === 3 || item.id === 4) && item.default) {
          return setIsClient(true);
        } else {
          return setIsClient(false);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      isAuthenticated &&
      theLastNotification &&
      theLastNotification.notification_content.type === 'WithdrawalApproved'
    ) {
      dispatch(getUserData(locale));
    }
  }, [dispatch, locale, isAuthenticated, theLastNotification]);

  useEffect(() => {
    if (projectsPrices && projectsPrices.length <= 0) {
      dispatch(getProjectsPrices());
    }
  }, [dispatch, projectsPrices]);

  useEffect(() => {
    if (statuses && statuses.length <= 0) {
      dispatch(getStatus());
    }
  }, [dispatch, statuses]);

  function markMessageRead(id) {
    dispatch(markMessageAsRead(id));
    setTheLastMessage(null);
  }

  function markNotificationRead(id) {
    dispatch(markAsRead(id));
    setTheLastNotification(null);
  }

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    if (errorStatus) {
      if (
        (errorStatus === 401 || errorStatus === 403) &&
        errorId === 'AUTH_ERROR'
      ) {
        dispatch({
          type: AUTH_ERROR,
        });
      }
      if (
        (errorStatus === 401 || errorStatus === 403) &&
        errors.errors &&
        errors.errors.length > 0 &&
        errors.errors[0] === 'Authorization Failed'
      ) {
        dispatch({
          type: AUTH_ERROR,
        });
      }
    }
  }, [dispatch, errorStatus, errorId, errors]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  useEffect(() => {
    if (lastNotification) {
      setPlaying(true);
      setTheLastNotification(lastNotification);
    }
  }, [lastNotification]);

  useEffect(() => {
    if (lastMessage) {
      setPlaying(true);
      setTheLastMessage(lastMessage);
    }
  }, [lastMessage]);

  function onSignInClick() {
    if (prevPath !== null) {
      history.push(`/signin?path=${prevPath}`);
    } else {
      history.push(`/signin?path=${location.pathname}`);
    }
  }

  function onSignUpClick() {
    if (prevPath !== null) {
      history.push(`/signup?path=${prevPath}`);
    } else {
      history.push(`/signin?path=${location.pathname}`);
    }
  }

  function showMenuOnClick() {
    setNavMenuClicked(!navMenuClicked);
  }

  useEffect(() => {
    const langSet = localStorage.getItem('lang');
    if (langSet) {
      if (langSet === 'English') {
        const code = 'en';
        dispatch(setLangEnglish());
        dispatch(setLocaleWithFallback(code));
      } else {
        const code = 'ar';
        dispatch(setLangArabic());
        dispatch(setLocaleWithFallback(code));
      }
    } else {
      const code = 'ar';
      dispatch(setLangArabic());
      dispatch(setLocaleWithFallback(code));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      const page = 1;
      const limit = 5;
      dispatch(getMessages(null, page, limit, locale));
    }
    dispatch({
      type: CLEAR_MESSAGE_REPLIES,
    });
  }, [dispatch, isAuthenticated, locale, location]);

  useEffect(() => {
    if ((markMessageSuccess || markMessagesSuccess) && isAuthenticated) {
      if (markMessageSuccess) {
        dispatch({type: CLEAR_MARK_MESSAGE_READ_SUCCESS});
      }
      const page = 1;
      const limit = 5;
      dispatch(getMessages(null, page, limit, locale));
    }
  }, [
    markMessageSuccess,
    markMessagesSuccess,
    isAuthenticated,
    locale,
    dispatch,
  ]);

  useEffect(() => {
    if (isAuthenticated) {
      const page = 1;
      const limit = 5;
      dispatch(getNotifications(page, limit, locale));
    }
  }, [locale, isAuthenticated, dispatch, location]);

  useEffect(() => {
    if (
      (markNotificationSuccess || markNotificationsSuccess) &&
      isAuthenticated
    ) {
      if (markNotificationSuccess) {
        dispatch({type: CLEAR_MARK_NOTIFICATION_READ_SUCCESS});
      }
      const page = 1;
      const limit = 5;
      dispatch(getNotifications(page, limit, locale));
    }
  }, [
    markNotificationSuccess,
    markNotificationsSuccess,
    isAuthenticated,
    locale,
    dispatch,
  ]);

  function clearLastNotification() {
    return dispatch({type: CLEAR_LAST_NOTIFICATION});
  }

  function clearLastMessage() {
    return dispatch({type: CLEAR_LAST_MESSAGE});
  }

  function handlelangArabic(code) {
    const langSet = localStorage.getItem('lang');
    if (langSet) {
      if (langSet === 'English') {
        dispatch(setLangArabic());
        dispatch(getCats(code));
        dispatch(setLocaleWithFallback(code));
      } else {
        return null;
      }
    }
  }

  function handlelangEnglish(code) {
    const langSet = localStorage.getItem('lang');
    if (langSet) {
      if (langSet === 'English') {
        return null;
      } else {
        dispatch(setLangEnglish());
        dispatch(getCats(code));
        dispatch(setLocaleWithFallback(code));
      }
    }
  }

  function handleLogout() {
    dispatch(logout());
  }

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  function close() {
    setIsOpen(false);
  }

  const {userFname} = auth;
  const userImage =
    typeof window !== 'undefined' && localStorage.getItem('loggeduserImage');
  const {triggerProps, layerProps, arrowProps, renderLayer} = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    //onDisappear: close, // close the menu when the menu gets scrolled out of sight
    overflowContainer: false, // keep the menu positioned inside the container
    auto: true, // automatically find the best placement
    placement: 'top-end', // we prefer to place the menu "top-end"
    triggerOffset: 12, // keep some distance to the trigger
    containerOffset: 16, // give the menu some room to breath relative to the container
    arrowOffset: 16, // let the arrow have some room to breath also
  });

  function messagesConditions(item) {
    return item.morph_type === 'project_bids' && item.conversationable ? (
      <li key={item.id}>
        <Link
          exact="true"
          to={{
            pathname: `/dashboard/messages/${
              item.id
            }/${item.conversationable.title
              .replace(/\s+/g, '-')
              .replace(/\//g, '-')
              .toLowerCase()}`,
            state: {prevPath: location.pathname},
          }}
          onClick={() => markMessageRead(item.id)}
          className="dropdown-item">
          {item.from.id === currentUser.id ? (
            <span className="userImg">
              {' '}
              <img
                src={item.to.image}
                alt={item.to.fname + ' ' + item.to.lname}
              />
            </span>
          ) : (
            <span className="userImg">
              {' '}
              <img
                src={item.from.image}
                alt={item.from.fname + ' ' + item.from.lname}
              />
            </span>
          )}
          <div className="messageContent">
            <p>{I18n.t('inquiry_about') + ' ' + item.conversationable.title}</p>
            <div className="notification__Date">
              <span className="icon icon-calender-time"></span>
              {item.last_reply
                ? item.last_reply.created_at.Date
                : item.created_at.Date}{' '}
              -{' '}
              {item.last_reply
                ? item.last_reply.created_at.Time
                : item.created_at.Time}
            </div>
          </div>
          {item.read_at ? null : <span className="unread"></span>}
        </Link>
      </li>
    ) : item.morph_type === 'services' && item.conversationable ? (
      <li key={item.id}>
        <Link
          exact="true"
          to={{
            pathname: `/dashboard/messages/${
              item.id
            }/${item.conversationable.title
              .replace(/\s+/g, '-')
              .replace(/\//g, '-')
              .toLowerCase()}`,
            state: {prevPath: location.pathname},
          }}
          onClick={() => markMessageRead(item.id)}
          className="dropdown-item">
          {item.from.id === currentUser.id ? (
            <span className="userImg">
              {' '}
              <img
                src={item.to.image}
                alt={item.to.fname + ' ' + item.to.lname}
              />
            </span>
          ) : (
            <span className="userImg">
              {' '}
              <img
                src={item.from.image}
                alt={item.from.fname + ' ' + item.from.lname}
              />
            </span>
          )}
          <div className="messageContent">
            <p>{I18n.t('inquiry_about') + ' ' + item.conversationable.title}</p>
            <div className="notification__Date">
              <span className="icon icon-calender-time"></span>
              {item.last_reply
                ? item.last_reply.created_at.Date
                : item.created_at.Date}{' '}
              -{' '}
              {item.last_reply
                ? item.last_reply.created_at.Time
                : item.created_at.Time}
            </div>
          </div>
          {item.read_at ? null : <span className="unread"></span>}
        </Link>
      </li>
    ) : item.morph_type === 'system_messages' ? (
      <li key={item.id}>
        <Link
          exact="true"
          to={{
            pathname: `/dashboard/messages/${item.id}/${item.subject
              .replace(/\s+/g, '-')
              .replace(/\//g, '-')
              .toLowerCase()}`,
            state: {prevPath: location.pathname},
          }}
          onClick={() => markMessageRead(item.id)}
          className="dropdown-item">
          {
            <span className="userImg">
              {' '}
              <img src={logoIcon} alt="Lancersin" />
            </span>
          }
          <div className="messageContent">
            <p>{item.subject}</p>
            <div className="notification__Date">
              <span className="icon icon-calender-time"></span>
              {item.last_reply
                ? item.last_reply.created_at.Date
                : item.created_at.Date}{' '}
              -{' '}
              {item.last_reply
                ? item.last_reply.created_at.Time
                : item.created_at.Time}
            </div>
          </div>
          {item.read_at ? null : <span className="unread"></span>}
        </Link>
      </li>
    ) : null;
  }

  function notificationsConditions(item) {
    return item.model === 'projects' ? (
      item.type === 'ProjectStatusChangedByAdmin' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('admin_approve_project')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'CreateBid' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('added_a_bid') +
                  ' ' +
                  item.content.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'SelectBid' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('select_a_bid') +
                  ' ' +
                  item.content.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'CloseProject' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {I18n.t('project_of') +
                  ' ' +
                  item.content.title +
                  ' ' +
                  I18n.t('delivered_success')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectsInvitation' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            <span className="userImg">
              {' '}
              <img
                src={item.user.image}
                alt={item.user.fname + ' ' + item.user.lname}
              />
            </span>
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('invite_to_project') +
                  ' ' +
                  item.content.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : //The Below For Project Handover
      item.type === 'BidReportAdminDeclined' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('project_cancel_request_declined')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectBidReportAdminApproved' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('project_cancel_request_approved')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectBidHandover' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname + ' ' + item.user.lname : '') +
                  ' ' +
                  I18n.t('delivered_order')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectBidCancelationRequest' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname + ' ' + item.user.lname : '') +
                  ' ' +
                  I18n.t('request_to_cancel_project') +
                  ' ' +
                  item.content.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'BidReportAdminApproved' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('project_cancel_request_approved')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectFeedback' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            <span className="userImg">
              {' '}
              <img
                src={item.user.image}
                alt={item.user.fname + ' ' + item.user.lname}
              />
            </span>
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  (auth.user.id === item.content.user.id
                    ? I18n.t('freelancer_feedback_done')
                    : I18n.t('client_feedback_done'))}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectWasRecentlyCreated' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            <span className="userImg">
              {' '}
              <img
                src={
                  item.content.user.image.url
                    ? item.content.user.image.url
                    : item.content.user.image
                }
                alt={item.content.user.fname + ' ' + item.content.user.lname}
              />
            </span>
            <div className="messageContent">
              <p>
                {(item.content.user ? item.content.user.fname : '') +
                  ' ' +
                  I18n.t('recently_added_project')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>{item.content.title}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'project_bid_reports' ? (
      item.type === 'ProjectBidRequestDeclined' &&
      item.content &&
      item.content.type &&
      item.content.type === 'handover' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${
                item.content.project.id
              }/${item.content.project.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('client_declined_project_delivery_notification')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectBidRequestApproved' &&
        item.content &&
        item.content.type &&
        item.content.type === 'handover' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${
                item.content.project.id
              }/${item.content.project.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('project_delivered_message')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ProjectBidRequestDeclined' &&
        item.content &&
        item.content.type &&
        item.content.type === 'cancel' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${
                item.content.project.id
              }/${item.content.project.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('project_cancel_request_declined')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'project_comments' ? (
      item.type === 'CreateProjectComment' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/projects/${
                item.content.project.id
              }/${item.content.project.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('added_a_comment') +
                  ' ' +
                  item.content.project.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'services' ? (
      item.type === 'ServiceStatusChangedByAdmin' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('admin_approve_service')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ServiceWasRecentlyCreated' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/${item.content.id}/${item.content.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            <span className="userImg">
              {' '}
              <img
                src={
                  item.content.user.image.url
                    ? item.content.user.image.url
                    : item.content.user.image
                }
                alt={item.content.user.fname + ' ' + item.content.user.lname}
              />
            </span>
            <div className="messageContent">
              <p>
                {(item.content.user ? item.content.user.fname : '') +
                  ' ' +
                  I18n.t('recently_added_service')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'service_invoice_comments' ? (
      item.type === 'CreateServiceComment' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('added_a_comment') +
                  ' ' +
                  item.content.invoice.service.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>{item.content.invoice.service.title}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'service_invoice_reports' ? (
      item.type === 'InvoiceReportAdminDeclined' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('service_cancel_request_declined')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceReportAdminApproved' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('service_cancel_request_approved')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceHandover' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname + ' ' + item.user.lname : '') +
                  ' ' +
                  I18n.t('delivered_order')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceRequestDeclined' &&
        item.content &&
        item.content.type &&
        item.content.type === 'handover' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname + ' ' + item.user.lname : '') +
                  ' ' +
                  I18n.t('client_declined_delivery_notification')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceRequestApproved' &&
        item.content &&
        item.content.type &&
        item.content.type === 'handover' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('service_delivered_message')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceRequestDeclined' &&
        item.content &&
        item.content.type &&
        item.content.type === 'cancel' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('service_cancel_request_declined')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'InvoiceCancelationRequest' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.invoice.id
              }/${item.content.invoice.service.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname + ' ' + item.user.lname : '') +
                  ' ' +
                  I18n.t('request_to_cancel_invoice') +
                  ' ' +
                  item.content.invoice.service.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'service_invoices' ? (
      item.type === 'PurchaseService' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.id
              }/${item.content.service
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('purchased_service') +
                  ' ' +
                  item.content.service}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'CreateInvoiceReply' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.id
              }/${item.content.service
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {item.user.fname +
                  ' ' +
                  I18n.t('added_requirments') +
                  ' ' +
                  item.content.service}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'PurchaseServiceClient' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.id
              }/${item.content.service
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                <span className="specialText">{I18n.t('reminder')}</span>{' '}
                {I18n.t('please_provide_instruction')}{' '}
                <span className="specialText">
                  {item.user ? item.user.fname + ' ' + item.user.lname : ''}
                </span>{' '}
                {I18n.t('can_start_order')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'CloseInvoice' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.id
              }/${item.content.service
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {I18n.t('service_of') +
                  ' ' +
                  item.content.service +
                  ' ' +
                  I18n.t('delivered_success')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : item.type === 'ServiceInvoiceFeedback' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/services/invoices/${
                item.content.id
              }/${item.content.service
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  (auth.user.id === item.content.user_id
                    ? I18n.t('client_feedback_done')
                    : I18n.t('freelancer_feedback_done'))}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'portfolio_likes' ? (
      item.type === 'LikePortfolio' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/portfolio/${
                item.content.portfolio.id
              }/${item.content.portfolio.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('liked_portfolio') +
                  ' ' +
                  item.content.portfolio.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'portfolio_comments' ? (
      item.type === 'CommentPortfolio' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{
              pathname: `/portfolio/${
                item.content.portfolio.id
              }/${item.content.portfolio.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
            }}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  I18n.t('added_portfolio_comment') +
                  ' ' +
                  item.content.portfolio.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'users' ? (
      item.type === 'following' && item.content ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{pathname: `/dashboard/followers`}}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item">
            {
              <span className="userImg">
                {' '}
                <img
                  src={item.user.image}
                  alt={item.user.fname + ' ' + item.user.lname}
                />
              </span>
            }
            <div className="messageContent">
              <p>
                {(item.user ? item.user.fname : '') +
                  ' ' +
                  (item.user ? item.user.lname : '') +
                  ' ' +
                  I18n.t('followed_you')}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : item.model === 'withdrawals' ? (
      item.type === 'WithdrawalApproved' ? (
        <li key={item.id}>
          <Link
            exact="true"
            to={{pathname: `/`}}
            onClick={() => markNotificationRead(item.id)}
            className="dropdown-item dropdownn-reminder">
            {
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
            }
            <div className="messageContent">
              <p>{I18n.t('admin_approve_withdrawal')}</p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {item.created_at.Date} - {item.created_at.Time}
              </div>
            </div>
            {item.read_at ? null : <span className="unread"></span>}
          </Link>
        </li>
      ) : null
    ) : null;
  }

  const notificationLinks = getNotificationsLoading ? (
    <Spin />
  ) : theLastNotification ? (
    <Fragment>
      {theLastNotification.notification_content.model === 'projects' ? (
        theLastNotification.notification_content.type ===
          'ProjectStatusChangedByAdmin' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('admin_approve_project')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type === 'CreateBid' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('added_a_bid') +
                    ' ' +
                    theLastNotification.notification_content.content.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type === 'SelectBid' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('select_a_bid') +
                    ' ' +
                    theLastNotification.notification_content.content.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type === 'CloseProject' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('close_a_project') +
                    ' ' +
                    theLastNotification.notification_content.content.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectsInvitation' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('invite_to_project') +
                    ' ' +
                    theLastNotification.notification_content.content.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : //The Below For Project Handover
        theLastNotification.notification_content.type ===
            'BidReportAdminDeclined' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('project_cancel_request_declined')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectBidReportAdminApproved' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('project_cancel_request_approved')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectBidHandover' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    : '') +
                    ' ' +
                    I18n.t('delivered_order')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectBidCancelationRequest' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    : '') +
                    ' ' +
                    I18n.t('request_to_cancel_project') +
                    ' ' +
                    theLastNotification.notification_content.content.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'BidReportAdminApproved' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('project_cancel_request_approved')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectFeedback' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              <span className="userImg">
                {' '}
                <img
                  src={theLastNotification.notification_content.user.image}
                  alt={
                    theLastNotification.notification_content.user.fname +
                    ' ' +
                    theLastNotification.notification_content.user.lname
                  }
                />
              </span>
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    (auth.user.id ===
                    theLastNotification.notification_content.content.user.id
                      ? I18n.t('freelancer_feedback_done')
                      : I18n.t('client_feedback_done'))}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectWasRecentlyCreated' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              <span className="userImg">
                {' '}
                <img
                  src={
                    theLastNotification.notification_content.content.user.image
                      .url
                      ? theLastNotification.notification_content.content.user
                          .image.url
                      : theLastNotification.notification_content.content.user
                          .image
                  }
                  alt={
                    theLastNotification.notification_content.content.user
                      .fname +
                    ' ' +
                    theLastNotification.notification_content.content.user.lname
                  }
                />
              </span>
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.content.user.fname +
                    ' ' +
                    I18n.t('recently_added_project')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>{theLastNotification.notification_content.content.title}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'project_comments' ? (
        theLastNotification.notification_content.type ===
          'CreateProjectComment' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.project.id
                }/${theLastNotification.notification_content.content.project.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('added_a_comment') +
                    ' ' +
                    theLastNotification.notification_content.content.project
                      .title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'project_bid_reports' ? (
        theLastNotification.notification_content.type ===
          'ProjectBidRequestDeclined' &&
        theLastNotification.notification_content.content &&
        theLastNotification.notification_content.content.type &&
        theLastNotification.notification_content.content.type === 'handover' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.project.id
                }/${theLastNotification.notification_content.content.project.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname
                    : '') +
                    ' ' +
                    I18n.t('client_declined_project_delivery_notification')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectBidRequestApproved' &&
          theLastNotification.notification_content.content &&
          theLastNotification.notification_content.content.type &&
          theLastNotification.notification_content.content.type ===
            'handover' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.project.id
                }/${theLastNotification.notification_content.content.project.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('project_delivered_message')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ProjectBidRequestDeclined' &&
          theLastNotification.notification_content.content &&
          theLastNotification.notification_content.content.type &&
          theLastNotification.notification_content.content.type === 'cancel' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/projects/${
                  theLastNotification.notification_content.content.project.id
                }/${theLastNotification.notification_content.content.project.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('project_cancel_request_declined')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model === 'services' ? (
        theLastNotification.notification_content.type ===
          'ServiceStatusChangedByAdmin' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              <span className="userImg">
                {' '}
                <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
              </span>
              <div className="messageContent">
                <p>{I18n.t('admin_approve_service')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ServiceWasRecentlyCreated' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              <span className="userImg">
                {' '}
                <img
                  src={
                    theLastNotification.notification_content.content.user.image
                      .url
                      ? theLastNotification.notification_content.content.user
                          .image.url
                      : theLastNotification.notification_content.content.user
                          .image
                  }
                  alt={
                    theLastNotification.notification_content.content.user
                      .fname +
                    ' ' +
                    theLastNotification.notification_content.content.user.lname
                  }
                />
              </span>
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.content.user.fname +
                    ' ' +
                    I18n.t('recently_added_service')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'service_invoice_comments' ? (
        theLastNotification.notification_content.type ===
          'CreateServiceComment' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              <span className="userImg">
                {' '}
                <img
                  src={theLastNotification.notification_content.user.image}
                  alt={
                    theLastNotification.notification_content.user.fname +
                    ' ' +
                    theLastNotification.notification_content.user.lname
                  }
                />
              </span>
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('added_a_comment') +
                    ' ' +
                    theLastNotification.notification_content.content.invoice
                      .service.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {
                    theLastNotification.notification_content.content.invoice
                      .service.title
                  }
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'service_invoice_reports' ? (
        theLastNotification.notification_content.type ===
          'InvoiceReportAdminDeclined' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('service_cancel_request_declined')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceReportAdminApproved' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('service_cancel_request_approved')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceHandover' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    : '') +
                    ' ' +
                    I18n.t('delivered_order')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceRequestDeclined' &&
          theLastNotification.notification_content.content &&
          theLastNotification.notification_content.content.type &&
          theLastNotification.notification_content.content.type ===
            'handover' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    : '') +
                    ' ' +
                    I18n.t('client_declined_delivery_notification')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceRequestApproved' &&
          theLastNotification.notification_content.content &&
          theLastNotification.notification_content.content.type &&
          theLastNotification.notification_content.content.type ===
            'handover' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('service_delivered_message')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceRequestDeclined' &&
          theLastNotification.notification_content.content &&
          theLastNotification.notification_content.content.type &&
          theLastNotification.notification_content.content.type === 'cancel' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('service_cancel_request_declined')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'InvoiceCancelationRequest' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.invoice.id
                }/${theLastNotification.notification_content.content.invoice.service.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {(theLastNotification.notification_content.user
                    ? theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    : '') +
                    ' ' +
                    I18n.t('request_to_cancel_invoice') +
                    ' ' +
                    theLastNotification.notification_content.content.invoice
                      .service.title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'service_invoices' ? (
        theLastNotification.notification_content.type === 'PurchaseService' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.service
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('purchased_service') +
                    ' ' +
                    theLastNotification.notification_content.content.service}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'PurchaseServiceClient' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.service
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  <span className="specialText">{I18n.t('reminder')}</span>{' '}
                  {I18n.t('please_provide_instruction')}{' '}
                  <span className="specialText">
                    {theLastNotification.notification_content.user
                      ? theLastNotification.notification_content.user.fname +
                        ' ' +
                        theLastNotification.notification_content.user.lname
                      : ''}
                  </span>{' '}
                  {I18n.t('can_start_order')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'CreateInvoiceReply' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.service
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('added_requirments') +
                    ' ' +
                    theLastNotification.notification_content.content.service}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type === 'CloseInvoice' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.service
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {I18n.t('service_of') +
                    ' ' +
                    theLastNotification.notification_content.content.service +
                    ' ' +
                    I18n.t('delivered_success')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : theLastNotification.notification_content.type ===
            'ServiceInvoiceFeedback' &&
          theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/services/invoices/${
                  theLastNotification.notification_content.content.id
                }/${theLastNotification.notification_content.content.service
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    (auth.user.id ===
                    theLastNotification.notification_content.content.user_id
                      ? I18n.t('client_feedback_done')
                      : I18n.t('freelancer_feedback_done'))}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'portfolio_likes' ? (
        theLastNotification.notification_content.type === 'LikePortfolio' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/portfolio/${
                  theLastNotification.notification_content.content.portfolio.id
                }/${theLastNotification.notification_content.content.portfolio.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('liked_portfolio') +
                    ' ' +
                    theLastNotification.notification_content.content.portfolio
                      .title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model ===
        'portfolio_comments' ? (
        theLastNotification.notification_content.type === 'CommentPortfolio' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{
                pathname: `/portfolio/${
                  theLastNotification.notification_content.content.portfolio.id
                }/${theLastNotification.notification_content.content.portfolio.title
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
              }}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    I18n.t('added_portfolio_comment') +
                    ' ' +
                    theLastNotification.notification_content.content.portfolio
                      .title}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model === 'users' ? (
        theLastNotification.notification_content.type === 'following' &&
        theLastNotification.notification_content.content ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{pathname: `/dashboard/followers`}}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img
                    src={theLastNotification.notification_content.user.image}
                    alt={
                      theLastNotification.notification_content.user.fname +
                      ' ' +
                      theLastNotification.notification_content.user.lname
                    }
                  />
                </span>
              }
              <div className="messageContent">
                <p>
                  {theLastNotification.notification_content.user.fname +
                    ' ' +
                    theLastNotification.notification_content.user.lname +
                    ' ' +
                    I18n.t('followed_you')}
                </p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : theLastNotification.notification_content.model === 'withdrawals' ? (
        theLastNotification.notification_content.type ===
        'WithdrawalApproved' ? (
          <li key={theLastNotification.notification_content.id}>
            <Link
              exact="true"
              to={{pathname: `/`}}
              onClick={() =>
                markNotificationRead(
                  theLastNotification.notification_content.id,
                )
              }
              className="dropdown-item dropdownn-reminder">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt={I18n.t('lancersin_tech')} />
                </span>
              }
              <div className="messageContent">
                <p>{I18n.t('admin_approve_withdrawal')}</p>
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {
                    theLastNotification.notification_content.created_at.Date
                  } - {theLastNotification.notification_content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : null
      ) : null}
      {notifications.map(item =>
        item.id === theLastNotification.notification_content.id
          ? false
          : notificationsConditions(item),
      )}
    </Fragment>
  ) : notifications.length > 0 ? (
    notifications.map(item => notificationsConditions(item))
  ) : (
    <NoItems title="no_notifications_found" message="no_notifications_body" />
  );

  const messagesLinks = getMessagesLoading ? (
    <Spin />
  ) : theLastMessage ? (
    <Fragment>
      {theLastMessage.content.morph_type === 'project_bids' ? (
        <li key={theLastMessage.id}>
          <Link
            exact="true"
            to={{
              pathname: `/dashboard/messages/${
                theLastMessage.content.conversation_id
                  ? theLastMessage.content.conversation_id
                  : theLastMessage.content.id
              }/${theLastMessage.content.conversationable.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
              state: {prevPath: location.pathname},
            }}
            onClick={() =>
              markMessageRead(theLastMessage.content.conversation_id)
            }
            className="dropdown-item">
            {theLastMessage.content.from.id === currentUser.id ? (
              <span className="userImg">
                {' '}
                <img
                  src={theLastMessage.content.to.image}
                  alt={
                    theLastMessage.content.to.fname +
                    ' ' +
                    theLastMessage.content.to.lname
                  }
                />
              </span>
            ) : (
              <span className="userImg">
                {' '}
                <img
                  src={theLastMessage.content.from.image}
                  alt={
                    theLastMessage.content.from.fname +
                    ' ' +
                    theLastMessage.content.from.lname
                  }
                />
              </span>
            )}
            <div className="messageContent">
              <p>
                {I18n.t('inquiry_about') +
                  ' ' +
                  theLastMessage.content.conversationable.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {theLastMessage.content.created_at.Date} -{' '}
                {theLastMessage.content.created_at.Time}
              </div>
            </div>
            <span className="unread"></span>
          </Link>
        </li>
      ) : theLastMessage.content.morph_type === 'services' ? (
        <li key={theLastMessage.id}>
          <Link
            exact="true"
            to={{
              pathname: `/dashboard/messages/${
                theLastMessage.content.conversation_id
                  ? theLastMessage.content.conversation_id
                  : theLastMessage.content.id
              }/${theLastMessage.content.conversationable.title
                .replace(/\s+/g, '-')
                .replace(/\//g, '-')
                .toLowerCase()}`,
              state: {prevPath: location.pathname},
            }}
            onClick={() =>
              markMessageRead(theLastMessage.content.conversation_id)
            }
            className="dropdown-item">
            {theLastMessage.content.from.id === currentUser.id ? (
              <span className="userImg">
                {' '}
                <img
                  src={theLastMessage.content.to.image}
                  alt={
                    theLastMessage.content.to.fname +
                    ' ' +
                    theLastMessage.content.to.lname
                  }
                />
              </span>
            ) : (
              <span className="userImg">
                {' '}
                <img
                  src={theLastMessage.content.from.image}
                  alt={
                    theLastMessage.content.from.fname +
                    ' ' +
                    theLastMessage.content.from.lname
                  }
                />
              </span>
            )}
            <div className="messageContent">
              <p>
                {I18n.t('inquiry_about') +
                  ' ' +
                  theLastMessage.content.conversationable.title}
              </p>
              <div className="notification__Date">
                <span className="icon icon-calender-time"></span>
                {theLastMessage.content.created_at.Date} -{' '}
                {theLastMessage.content.created_at.Time}
              </div>
            </div>
            <span className="unread"></span>
          </Link>
        </li>
      ) : theLastMessage.content.morph_type === 'system_messages' ? (
        theLastMessage.content.conversation_id ? (
          <li key={theLastMessage.id}>
            <Link
              exact="true"
              to={{
                pathname: `/dashboard/messages/${
                  theLastMessage.content.conversation_id
                }/${theLastMessage.content.subject
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
                state: {prevPath: location.pathname},
              }}
              onClick={() => markMessageRead(theLastMessage.id)}
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt="Lancersin" />
                </span>
              }
              <div className="notification__Content">
                {theLastMessage.content.subject}
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {theLastMessage.content.last_reply
                    ? theLastMessage.content.last_reply.created_at.Date
                    : theLastMessage.content.created_at.Date}{' '}
                  -{' '}
                  {theLastMessage.content.last_reply
                    ? theLastMessage.content.last_reply.created_at.Time
                    : theLastMessage.content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        ) : (
          <li key={theLastMessage.id}>
            <Link
              exact="true"
              to={{
                pathname: `/dashboard/messages/${
                  theLastMessage.content.id
                }/${theLastMessage.content.subject
                  .replace(/\s+/g, '-')
                  .replace(/\//g, '-')
                  .toLowerCase()}`,
                state: {prevPath: location.pathname},
              }}
              onClick={() => markMessageRead(theLastMessage.id)}
              className="dropdown-item">
              {
                <span className="userImg">
                  {' '}
                  <img src={logoIcon} alt="Lancersin" />
                </span>
              }
              <div className="notification__Content">
                {theLastMessage.content.subject}
                <div className="notification__Date">
                  <span className="icon icon-calender-time"></span>
                  {theLastMessage.content.last_reply
                    ? theLastMessage.content.last_reply.created_at.Date
                    : theLastMessage.content.created_at.Date}{' '}
                  -{' '}
                  {theLastMessage.content.last_reply
                    ? theLastMessage.content.last_reply.created_at.Time
                    : theLastMessage.content.created_at.Time}
                </div>
              </div>
              <span className="unread"></span>
            </Link>
          </li>
        )
      ) : null}
      {messages.map(item =>
        item.id === theLastMessage.content.conversation_id ||
        item.id === theLastMessage.content.id
          ? false
          : messagesConditions(item),
      )}
    </Fragment>
  ) : messages.length > 0 ? (
    messages.map(item => messagesConditions(item))
  ) : (
    <NoItems title="no_messages_found" message="no_messages_body" />
  );
  const authLinks = (
    <Fragment>
      <ul className="userDropdown">
        <li>
          <button
            {...triggerProps}
            onClick={toggleOpen}
            type="button"
            className={`btn btn-primary userDropdown__button ${
              isOpen ? 'show' : ''
            }`}>
            <span className="userDropdown__button__Img">
              <img
                src={
                  userImage ? (userImage.url ? userImage.url : userImage) : null
                }
                alt={userFname}
              />
            </span>
            <span className="welcomeBack">
              <Translate value="welcome_back" />, {userFname ? userFname : null}
            </span>
          </button>
          {renderLayer(
            <AnimatePresence>
              {isOpen && (
                <motion.ul {...layerProps} className="userDropdown__Menu">
                  <li>
                    <Link
                      exact="true"
                      to="/"
                      className="dropdown-item"
                      onClick={toggleOpen}>
                      <i className="icon-dashboard"></i>
                      <Translate value="dashboard" />
                    </Link>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/"
                      onClick={handleLogout}>
                      <i className="icon-logout"></i>
                      <Translate value="logout" />
                    </a>
                  </li>
                  <Arrow {...arrowProps} />
                </motion.ul>
              )}
            </AnimatePresence>,
          )}
        </li>
        <li>
          <DropdownMenu
            buttonIcon="icon-envelope"
            buttonClassName="btn btn-primary btnIcon"
            dropDownClassName="userDropdown__Menu"
            children={messagesLinks}
            newMessage={lastMessage ? true : false}
            hasViewAll={true}
            viewAllPath="/dashboard/messages"
            viewAllClassName="dropdownViewAll"
            viewAllText="all_messages"
            viewAllIcon={true}
            viewAllIconClass="icon icon-envelope"
            onClickFun={() => clearLastMessage()}
            unread={unreadMessages}
            isOpen={messagesOpen}
            setOpen={setMessagesOpen}
          />
        </li>
        <li>
          <DropdownMenu
            buttonIcon="icon-notification"
            buttonClassName="btn btn-primary btnIcon"
            dropDownClassName="userDropdown__Menu"
            children={notificationLinks}
            newMessage={lastNotification ? true : false}
            hasViewAll={true}
            viewAllPath="/dashboard/notifications"
            viewAllClassName="dropdownViewAll"
            viewAllText="all_notifications"
            viewAllIcon={true}
            viewAllIconClass="icon icon-notification"
            onClickFun={() => clearLastNotification()}
            unread={unreadNotifications}
            isOpen={notificationsOpen}
            setOpen={setNotificationsOpen}
          />
        </li>
      </ul>
    </Fragment>
  );
  const guestLinks = (
    <Fragment>
      <ul>
        <li onClick={onSignInClick}>
          <i className="icon-user" aria-hidden />
          <Translate value="signin" />
        </li>
        <li className="dividerBTW" />
        <li onClick={onSignUpClick}>
          <i className="icon-add-user" aria-hidden />
          <Translate value="signup" />
        </li>
      </ul>
    </Fragment>
  );

  function handleHide() {
    setToggleAddProjectModelHeader(false);
  }

  return (
    <header className="header inner-bg bg">
      <MetaComponent />
      <Helmet htmlAttributes={{lang: locale}} />
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <AddingProjectModal
        modalTitle={'add_project'}
        className="CustomPopup"
        size="lg"
        show={toggleAddProjectModelHeader}
        onHide={handleHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={addingProjectLoading}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <div
        role="alert"
        class={
          show
            ? 'fade alert alert-success alert-dismissible show'
            : 'fade alert alert-success alert-dismissible d-none'
        }>
        <button type="button" class="close" onClick={() => setShow(false)}>
          <span aria-hidden="true">×</span>
          <span class="sr-only">Close alert</span>
        </button>
        <p>
          {I18n.t('client_coupon')} <b>1st-order</b>{' '}
          {I18n.t('client_coupon_and')} <b>{I18n.t('client_coupon_amount')}</b>{' '}
          {I18n.t('client_coupon_desc')} {I18n.t('client_coupon_limit_one')}{' '}
          <b>{I18n.t('client_coupon_limit_two')}</b>
        </p>
      </div>
      <div className="header__topSide">
        <div className="header__topSide--content container">
          <div className="header__topSide--content--leftSide">
            {isAuthenticated ? authLinks : guestLinks}
          </div>

          <div className="header__topSide--content--rightSide">
            {isAuthenticated && location.pathname !== '/projects/create' ? (
              isClient ? (
                <button
                  className="btn btn-primary post-project"
                  onClick={() => {
                    setToggleAddProjectModelHeader(true);
                  }}>
                  <i className="icon icon-plus"></i>
                  {I18n.t('post_project')}
                </button>
              ) : null
            ) : (
              <Link
                to="/projects/create"
                aria-label={I18n.t('lancersin_projects_adding_project')}
                className="btn btn-primary post-project">
                <i className="icon icon-plus"></i>
                {I18n.t('post_project')}
              </Link>
            )}

            {lang.lang === 'English' ? (
              <button
                className="btn btn-primary lang-btn"
                onClick={() => handlelangArabic('ar')}>
                العربية
                <i aria-hidden className="icon-globe" />
              </button>
            ) : (
              <button
                className="btn btn-primary lang-btn"
                onClick={() => handlelangEnglish('en')}>
                English
                <i aria-hidden className="icon-globe" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <nav className="header__nav ">
          <div className="header__nav--mshape">
            <figure className="header__nav--leftSide">
              <Link to="/" aria-label={I18n.t('lancersin_tech')}>
                {lang.lang === 'English' ? (
                  <span className="logoEn"></span>
                ) : (
                  <span className="logoAr"></span>
                )}
              </Link>
            </figure>
            <div className="Lang__button__mobile">
              {/* {lang.lang === 'English' ? (
                <button
                  className="btn btn-primary lang-btn"
                  onClick={() => handlelangArabic('ar')}>
                  العربية
                  <i aria-hidden className="icon-globe" />
                </button>
              ) : (
                <button
                  className="btn btn-primary lang-btn"
                  onClick={() => handlelangEnglish('en')}>
                  English
                  <i aria-hidden className="icon-globe" />
                </button>
              )} */}
              <i className="nav-menu" aria-hidden onClick={showMenuOnClick} />
            </div>
          </div>
          <ul
            className={`header__nav--rightSide ${
              navMenuClicked ? 'd-flex' : 'd-none'
            }`}>
            {/**/}
            <li>
              <NavLink exact to="/projects" onClick={showMenuOnClick}>
                <Translate value="browse_projects" />
              </NavLink>
            </li>

            {/*<li><NavLink exact to="/contests" onClick={showMenuOnClick}><Translate value="contests" /></NavLink></li>*/}

            <li>
              <NavLink exact to="/services" onClick={showMenuOnClick}>
                <Translate value="services" />
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/portfolio" onClick={showMenuOnClick}>
                <Translate value="portfolio" />
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/how-it-works" onClick={showMenuOnClick}>
                <Translate value="how_it_works" />
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/freelancers" onClick={showMenuOnClick}>
                <Translate value="find_freelancer" />
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}
