import React, { useState } from "react";

export default function RatingStars({ rate = 0, onRate }) {
  var count = 5;
  var stars = [];

  var [rating, setRating] = useState(rate);

  for (let i = 1; i <= count; ++i) {
    let active = i <= rating;
    let starType = active ? "fas" : "far";

    function onMouseEnter(e) {
      var rating = e.target.getAttribute("datatype");
      setRating(rating);
      if (onRate) {
        onRate(rating);
      }
    }

    stars.push(
      <i
        key={`star-${i}`}
        className={`${starType} fa-star`}
        datatype={i}
        onMouseEnter={onMouseEnter}
      />
    );
  }
  return stars;
}
