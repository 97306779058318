import { GET_CATS, CATS_LOADING } from '../actions/types';

const initialState = {
    cats: [],
    loading: false
}

export default function catsReducer(state = initialState, action) {
    switch (action.type) {
        case GET_CATS:
            return{
                ...state,
                cats: action.payload,
                loading: false
            }    
        ;
        case CATS_LOADING: 
            return{
                ...state,
                loading: true
            }
        ;
        default:
            return state;
    }
}