import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import RatingStars from "../RatingStars";
import { rateInvoiceSeller } from "../../actions/getserviceinvoiceActions";
import { I18n, Translate} from "react-redux-i18n";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import QuillBox from "../QuillBox";
import ErrorBox from "../ErrorBox";
import { rateProject } from "../../redux/project/actions/addActions";
import GRecaptch from "../GRecaptch";
import { getFeedbacks } from "../../redux/feedbacks/actions/getActions";
import Spin from '../Spin';
import RatingStarsPreview from "../RatingStarsPreview";

export default function FeedbackModal(props){
    const dispatch = useDispatch();
    const { 
        errorsId, 
        errorsMsg: errors, 
        feedbacks,
        getFeedbacksLoading,
    } = useSelector((state) => ({
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
        feedbacks: state.feedbacks.feedbacks,
        getFeedbacksLoading: state.feedbacks.getFeedbacksLoading,
    }));

    const {
        modalTitle,
        serviceUserId, 
        loggedUserId, 
        invoice_id, 
        status_id,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay,
        isProject = false,
        note = null
    } = props;

    var [rate, setRate] = useState(0);
    var [feedback, setFeedback] = useState("");
    const [localErrors, setLocalErrors] = useState({});
    const [isHuman, setIsHuman] = useState(false);

    const createMarkup = (text) => {
        return { __html: text };
    };

    useEffect(() => {
        const morphType = isProject ? "project" : "service_invoice";
        dispatch (getFeedbacks(null, morphType, invoice_id, null, null));
    }, [isProject, dispatch, invoice_id]);


    function onRate(rate){
        setRate(rate);
    };

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: undefined,
            }));
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: message,
            }));
        }
    }


    function handleToast(showToast, title, message, status, delay){
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }

    function handleFeedbackChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setFeedback("");
        } else {
            setFeedback(value);
        }
    }

    function onFeedbackBlur(){
        handleItemLocalError({
            propName: "feedback",
            currentValue: feedback,
            message: I18n.t('feedback_required'),
        });
    }
    function onSubmitFeedback(e) {
        e.preventDefault();

        handleItemLocalError({
            propName: "feedback",
            currentValue: feedback,
            message: I18n.t('feedback_required'),
        });

        if (rate <= 0) {
            if(isProject && serviceUserId === loggedUserId){
                setLocalErrors((errors) => ({
                    ...errors,
                    "rateError" : I18n.t('rate_freelancer'),
                }));
            } else if(isProject && serviceUserId !== loggedUserId){
                setLocalErrors((errors) => ({
                    ...errors,
                    "rateError" : I18n.t('rate_project_owner'),
                }));
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    "rateError" : I18n.t('rate_service'),
                }));
            }
        }
    
        if (
            feedback &&
            rate > 0 &&
            serviceUserId &&
            loggedUserId &&
            !isProject &&
            isHuman
        ) {
            dispatch(
                rateInvoiceSeller({
                    serviceUserId, 
                    loggedUserId, 
                    invoice_id,
                    status_id,
                    rate,
                    comment: feedback,
                })
            );
        } else if(
            feedback &&
            rate > 0 &&
            serviceUserId &&
            loggedUserId &&
            isProject &&
            isHuman
        ){
            dispatch(
                rateProject({
                    serviceUserId, 
                    loggedUserId, 
                    invoice_id,
                    status_id,
                    rate,
                    comment: feedback,
                })
            );
        }else if(!serviceUserId || !loggedUserId){
            handleToast(true, I18n.t('login_required_title'), I18n.t('login_required_info'), 'Toast__Container__Error', 6000)
        }else {
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
      };
    return(
        <Modal
            className="CustomPopup"
            size="lg"
            show={props.show}
            onHide={props.onHide}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            aria-labelledby={props["aria-labelledby"]}
        >
            <Form onSubmit={onSubmitFeedback}>
                <Modal.Header closeButton>
                    <Modal.Title>
                    {
                        modalTitle ? I18n.t(modalTitle) : I18n.t('feedback_title')
                    }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        note ?
                        <div className="alert alert-warning" role="alert">
                          {I18n.t(note)}
                        </div>
                        : null
                    }
                    {
                        getFeedbacksLoading ?
                        <Spin />
                        : feedbacks && feedbacks.length > 0 ?
                            <ul className="serviceFeedback__List">
                                {
                                    feedbacks.map( item => 
                                        <li key={item.id}>
                                            <div className="feedback__Buyer">
                                                <div className="Comment__userInfo">
                                                    <div className="UserPic">
                                                    <img
                                                        src={ item.user.image }
                                                        alt={ item.user.fname + " " + item.user.lname }
                                                    />
                                                    </div>
                                                    <div className="CommentContent__Details">
                                                    <h3>
                                                        <Link to={{ pathname: `/in/${item.user.username}`}} >
                                                        {item.user.fname + " " + item.user.lname}
                                                        </Link>
                                                    </h3>
                                                    <div className="CommentContent__Details__PostTime">
                                                        { item.created_at.Date + " - " + item.created_at.Time }
                                                    </div>
                                                    </div>
                                                </div>
                                                <RatingStarsPreview rate={item.rate} />
                                                <div className="CommentContent">
                                                <div dangerouslySetInnerHTML={createMarkup( item.comment )}></div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        : null
                    }
                    <Form.Group>
                    <Form.Label>
                        <Translate value="rating" />
                        <span className="required">*</span>
                    </Form.Label>
                    <RatingStars onRate={onRate} />
                    {((errorsId === "POST_INVOICE_STATUS_FAIL" || errorsId === "RATE_PROJECT_FAIL") && errors.rate) || localErrors.rateError ? (
                        <Form.Control.Feedback
                        type="invalid"
                        className="d-block"
                        >
                        {localErrors.rateError}
                        </Form.Control.Feedback>
                    ) : null}
                    </Form.Group>

                    <QuillBox
                        required
                        controlId="Feedback"
                        label={I18n.t('feedback')}
                        value={feedback}
                        onChange={handleFeedbackChange}
                        onBlur={onFeedbackBlur}
                        feedbackType="invalid"
                        feedback={localErrors.feedback ? localErrors.feedback : null}
                        isInvalid={((errorsId === "POST_INVOICE_STATUS_FAIL" || errorsId === "RATE_PROJECT_FAIL") && errors.comment) || localErrors.feedback}
                    />
                    <GRecaptch
                        setIsHuman={setIsHuman}
                        className="mt-3"
                    />
                    {
                        (errorsId === "POST_INVOICE_RATING_FAIL" || errorsId === "RATE_PROJECT_FAIL") && errors === "Network Error" ?
                          <ErrorBox message={I18n.t('network_error_message')} className="mt-3" />
                        : (errorsId === "POST_INVOICE_RATING_FAIL" || errorsId === "RATE_PROJECT_FAIL") && errors === "Other Errors" ?
                          <ErrorBox message={I18n.t('something_error_message')} className="mt-3" />
                        : null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <ButtonWithLoading
                    variant="primary"
                    type="submit"
                    onClick={onSubmitFeedback}
                    data-backdrop="static"
                    loading={props.loadingInvoiceStatus}
                    >
                        <Translate value="submit" />
                    </ButtonWithLoading>
                    <Button
                    variant="secondary"
                    onClick={props.onHide}
                    >
                        <Translate value="close" />
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}