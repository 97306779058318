const PAYPAL = "/api/update-paypal-email";
const BANK_ACCOUNT = "/api/save-bank-account";
const PAYMENT_METHOD = "/api/update-payment-method";
const REQUEST_WITHDRAWAL = "/api/request-withdrawal";
const WITHDRAWAL_REQUESTS = "/api/withdrawals";

const exportedObject = {
  PAYPAL,
  BANK_ACCOUNT,
  PAYMENT_METHOD,
  REQUEST_WITHDRAWAL,
  WITHDRAWAL_REQUESTS,
};

export default exportedObject;
