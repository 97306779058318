import { 
    GET_SOCIALS_LOADING,
    GET_SOCIALS_SUCCESS,
    GET_SOCIALS_FAIL 
} from "../actions/types";

const initialState = {
    socialLinks: [],
    getSocialLoading: false,
}

export default function getsocialReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SOCIALS_LOADING: 
            return{
                ...state,
                getSocialLoading: true
            }
        ;
        case GET_SOCIALS_SUCCESS:
            return{
                ...state,
                socialLinks: action.payload.data,
                getSocialLoading: false
            }    
        ;
        case GET_SOCIALS_FAIL:
            return{
                ...state,
                getSocialLoading: false
            }
        ;
        default:
            return state;
    }
}