import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Spin from '../Spin';
import {Accordion, Card, Button, Form} from 'react-bootstrap';
import {I18n} from 'react-redux-i18n';
import EditingProfileModal from '../EditingProfileModal';
import ConfToast from '../ConfToast';
import VerifyAccountModal from '../Popups/VerifyAccountModal';
import {sendVerification} from '../../actions/verifyaccountActions';
import {RESET_VERIFICATION} from '../../actions/types';
import {loadUser} from '../../actions/authActions';
import ForgotPassword from '../Popups/ForgotPassword';
import ConnectionLost from '../ConnectionLost';
import {Link, useHistory} from 'react-router-dom';
import {MetaComponent} from '../MetaComponent';
import {TitleComponent} from '../../components/TitleComponent';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {updateUserPlans} from '../../redux/userData/actions/addActions';
import {CLEAR_UPDATE_USER_PLANS_SUCCESS} from '../../redux/userData/actions/types';
import {clearErrors} from '../../actions/errorActions';
import GRecaptch from '../GRecaptch';
const {Control} = Form;
const {Feedback} = Control;

const MyProfile = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    userLoading,
    user,
    isLoading,
    verifyLoading,
    verifySuccess,
    errorId,
    errorMessage,
    updateUserPlansLoading,
    updateUserPlansSuccess,
    userInfo,
    getUserDataLoading,
  } = useSelector(state => ({
    userLoading: state.auth.isLoading,
    user: state.auth.user,
    isLoading: state.updateprofile.isLoading,
    verifyLoading: state.verifyAccount.verifyLoading,
    verifySuccess: state.verifyAccount.verifySuccess,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    updateUserPlansLoading: state.userData.updateUserPlansLoading,
    updateUserPlansSuccess: state.userData.updateUserPlansSuccess,
    userInfo: state.userData.user,
    getUserDataLoading: state.userData.getUserDataLoading,
  }));

  const [toggleEditProfileModel, setToggleEditProfileModel] = useState(false);
  const [toggleVerificationModel, setToggleVerificationModel] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [forgotlgShow, setForgotlgShow] = useState(false);
  const [activeId, setActiveId] = useState('2');
  const [plans, setPlans] = useState([]);
  const [localErrors, setLocalErrors] = useState({});
  const [isHuman, setIsHuman] = useState(false);
  const [freelancerPlan, setFreelancerPlan] = useState(null);
  const [clientPlan, setClientPlan] = useState(null);
  const [freelancerChecked, setFreelancerChecked] = useState(false);
  const [clientChecked, setClientChecked] = useState(false);
  const [freelancerDisabled, setFreelancerDisabled] = useState(false);
  const [clientDisabled, setClientDisabled] = useState(false);

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.memberships && !getUserDataLoading) {
      userInfo.memberships.map(item => {
        if (item.id === 1 || item.id === 2) {
          setFreelancerChecked(true);
          setFreelancerPlan(item.id);
          if (!plans.includes(item.id)) {
            plans.push(item.id);
          }
        }
        if (item.id === 3 || item.id === 4) {
          setClientChecked(true);
          setClientPlan(item.id);
          if (!plans.includes(item.id)) {
            plans.push(item.id);
          }
        }

        if (item.id === 2) {
          setFreelancerDisabled(true);
        }

        if (item.id === 4) {
          setClientDisabled(true);
        }
      });
    }
  }, [userInfo, getUserDataLoading, plans]);

  function onChangePlans(e) {
    const accountType = Number(e.target.value);
    if (plans.includes(accountType)) {
      const index = plans.indexOf(accountType);
      if (index > -1) {
        plans.splice(index, 1);
      }
    } else {
      setPlans(plans.concat(accountType));
    }

    if ((accountType === 1 || accountType === 2) && !freelancerChecked) {
      setFreelancerPlan(accountType);
      setFreelancerChecked(true);
    } else if ((accountType === 1 || accountType === 2) && freelancerChecked) {
      setFreelancerChecked(false);
    }

    if ((accountType === 3 || accountType === 4) && !clientChecked) {
      setClientPlan(accountType);
      setClientChecked(true);
    } else if ((accountType === 3 || accountType === 4) && clientChecked) {
      setClientChecked(false);
    }
  }

  function handleShow() {
    setToggleEditProfileModel(true);
  }
  function handleHide() {
    setToggleEditProfileModel(false);
  }

  function handleVerificationShow() {
    if (user.email) {
      const type = 'verification-change-password';
      let ItemData = {
        email: user.email,
        type,
      };
      dispatch(sendVerification(ItemData));
      setToggleVerificationModel(true);
    }
  }
  function handleVerificationHide() {
    setToggleVerificationModel(false);
  }

  const createMarkup = () => {
    return {__html: user.about};
  };

  useEffect(() => {
    if (updateUserPlansSuccess) {
      dispatch(loadUser());
      dispatch({
        type: CLEAR_UPDATE_USER_PLANS_SUCCESS,
      });
      setTimeout(() => {
        setConfShow(true);
        setConfTitle(I18n.t('profile_update_title'));
        setConfMessage(I18n.t('profile_update_success'));
        setConfStatus('Toast__Container__Success');
        setDelay(6000);
      }, 3000);
    }
  }, [updateUserPlansSuccess, dispatch]);

  useEffect(() => {
    if (verifySuccess) {
      setConfShow(true);
      setConfTitle(I18n.t('account_verification_title'));
      setConfMessage(I18n.t('account_verification_success'));
      setConfStatus('Toast__Container__Success');
      setDelay(6000);
      dispatch({
        type: RESET_VERIFICATION,
      });
      dispatch(loadUser());
    }
  }, [verifySuccess, dispatch]);

  function onForgotShow() {
    setForgotlgShow(true);
  }

  function onForgotHide() {
    setForgotlgShow(false);
  }

  function handleItemLocalError({
    propName,
    currentValue,
    message,
    array = false,
  }) {
    if (currentValue && !array) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else if (currentValue && array) {
      if (currentValue.length > 0) {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: undefined,
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          [propName]: message,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function handleToast(showToast, title, message, status, delay) {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function changeAccountType(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'plans',
      currentValue: plans,
      message: I18n.t('account_type_required'),
      array: true,
    });

    if (plans.length > 0 && isHuman) {
      const data = {
        plans,
      };
      //attempt to register
      dispatch(clearErrors());
      dispatch(updateUserPlans(data));
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function handleResetPassword() {
    history.push('/reset-password');
  }

  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <TitleComponent title={I18n.t('lancersin_profile')} />
      <MetaComponent />
      {userLoading ? (
        <Spin />
      ) : errorId === 'AUTH_ERROR' && errorMessage === 'Network Error' ? (
        <div className="col-12">
          <ConnectionLost
            errorType="connection"
            title="network_error_title"
            message="network_error_message"
          />
        </div>
      ) : errorId === 'AUTH_ERROR' ? (
        <div className="col-12">
          <ConnectionLost
            errorType="somethingWrong"
            title="something_error_title"
            message="something_error_message"
          />
        </div>
      ) : (
        <div className="container">
          <ForgotPassword
            forgotPasswordShow={forgotlgShow}
            onForgotPasswordHide={onForgotHide}
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <EditingProfileModal
            userData={user}
            loading={isLoading}
            modalTitle={'edit_profile'}
            className="CustomPopup"
            size="lg"
            show={toggleEditProfileModel}
            onHide={handleHide}
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <VerifyAccountModal
            loading={verifyLoading}
            modalTitle={'verify_email_address'}
            className="CustomPopup"
            size="lg"
            show={toggleVerificationModel}
            onHide={handleVerificationHide}
            backdrop="static"
            keyboard={false}
            aria-labelledby="example-modal-sizes-title-lg"
            setConfShow={setConfShow}
            setConfTitle={setConfTitle}
            setConfMessage={setConfMessage}
            setConfStatus={setConfStatus}
            setDelay={setDelay}
          />
          <div className="row">
            <div className="col-12">
              <div className="section__title">
                <div className="section__title__text">
                  <span className="icon icon-user"></span>
                  {I18n.t('my_profile')}
                </div>
                <div>
                  <Link className="btn" to={'/in/' + user.username}>
                    <span className="icon icon-view"></span>
                    {I18n.t('view_as_visitor')}
                  </Link>
                  <button
                    className="btn"
                    onClick={() => {
                      handleShow();
                    }}>
                    <span className="icon icon-edit"></span>
                    {I18n.t('edit_profile')}
                  </button>
                  <button
                    className="btn Verify__Email"
                    onClick={() => {
                      handleResetPassword();
                    }}>
                    <span className="icon icon-lock"></span>
                    {I18n.t('change_password')}
                  </button>
                  {user.status ? (
                    user.status.id === 3 ? (
                      <button
                        className="btn Verify__Email"
                        onClick={() => {
                          handleVerificationShow();
                        }}>
                        <span className="far fa-envelope"></span>
                        {I18n.t('verify_email_address')}
                      </button>
                    ) : null
                  ) : null}
                </div>
              </div>
            </div>
            {user.country === null ||
            user.city === null ||
            user.categories.lenght <= 0 ||
            user.sub_categories.length <= 0 ||
            user.skills.lenght <= 0 ? (
              <div className="col-12">
                <div
                  className="alert alert-warning mb-4 d-flex flex-wrap justify-content-between align-items-center pb-0"
                  role="alert">
                  <span className="mb-2">
                    {I18n.t('watch_profile_video') + ' '}
                  </span>
                  <a
                    href="https://youtu.be/ZnRIFPnNGQU"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="videoBTn btn-success btn-sm mb-2">
                    <i className="fas fa-play"></i> {I18n.t('watch_video')}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
          <div className="Profile-Card box-shadow">
            <div className="row">
              <div className="col-md-12 col-lg-8">
                <div className="ProfileData">
                  <div className="ProfileImage">
                    <figure>
                      {
                        <img
                          src={user.image.url ? user.image.url : user.image}
                          alt={user.fname + ' ' + user.lname}
                          className="img-fluid"
                        />
                      }
                    </figure>
                  </div>
                  <div className="ProfileContent">
                    <h2 className="ProfileName">
                      {user.fname ? user.fname : null}{' '}
                      {user.lname ? user.lname : null}
                      {user.price_per_hour ? (
                        <Fragment>
                          <span className="HourRate">
                            {user.price_per_hour}
                          </span>
                          <span>{I18n.t('dollar_per_hour')}</span>
                        </Fragment>
                      ) : null}
                    </h2>
                    <h3 className="ProfileTitle">
                      {user.position ? user.position : null}
                    </h3>
                    <div className="LocationTime">
                      {user.country ? (
                        <address>
                          <span className="LocationFlag">
                            <img
                              src={user.country ? user.country.flag : null}
                              alt={user.country ? user.country.name : null}
                            />
                          </span>
                          <span className="Location">
                            {user.city ? user.city.name : null},{' '}
                            {user.country ? user.country.name : null}
                          </span>
                        </address>
                      ) : null}
                      {user.localTime ? (
                        <time className="Time">
                          <label>{I18n.t('local_time')}:</label>{' '}
                          <div>
                            <span
                              style={{
                                display: 'inline-block',
                                direction: 'ltr',
                              }}>
                              {user.localTime.Date}
                            </span>{' '}
                            -{' '}
                            <span
                              style={{
                                display: 'inline-block',
                                direction: 'ltr',
                              }}>
                              {user.localTime.Time}
                            </span>
                          </div>
                        </time>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="userProfile__Contacts">
                  <div>
                    <b>{I18n.t('email')}:</b> {user.email ? user.email : null}
                  </div>
                  <div>
                    <b>{I18n.t('mobile')}:</b>{' '}
                    {user.phone
                      ? user.country
                        ? user.country.calling_code + user.phone
                        : user.phone
                      : null}
                  </div>
                  <div>
                    <b>{I18n.t('birth_date')}:</b>{' '}
                    {user.birthday ? user.birthday : null}
                  </div>
                  <div>
                    <b>{I18n.t('followers')}:</b>{' '}
                    <Link to="/dashboard/followers">
                      {user.followersCount ? user.followersCount : 0}{' '}
                      {I18n.t('follower')}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Accordion defaultActiveKey="2">
            <Card className={activeId === '2' ? 'active-panel' : ''}>
              <Card.Header>
                <Accordion.Toggle
                  onClick={() => toggleActive('2')}
                  as={Button}
                  variant="link"
                  eventKey="2">
                  <span className="icon icon-user"></span>
                  {I18n.t('account_type')}
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="account__type__types">
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id="client"
                                name="client"
                                value={clientPlan ? clientPlan : 3}
                                className="form-check-input"
                                onChange={onChangePlans}
                                checked={clientChecked}
                                disabled={clientDisabled}
                              />
                              <label
                                title=""
                                htmlFor="client"
                                className="form-check-label">
                                {I18n.t('client')}{' '}
                                <small>{I18n.t('client_description')}</small>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="account__type__types">
                          <div className="form-group">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id="freelancer"
                                name="freelancer"
                                value={freelancerPlan ? freelancerPlan : 1}
                                className="form-check-input"
                                onChange={onChangePlans}
                                checked={freelancerChecked}
                                disabled={freelancerDisabled}
                              />
                              <label
                                title=""
                                htmlFor="freelancer"
                                className="form-check-label">
                                {I18n.t('freelancer')}{' '}
                                <small>
                                  {I18n.t('freelancer_description')}
                                </small>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {localErrors.plans ? (
                        <div className="col-12">
                          <Feedback
                            type="invalid"
                            className="d-block mt-0 mb-2">
                            {localErrors.plans}
                          </Feedback>
                        </div>
                      ) : null}
                      <GRecaptch
                        setIsHuman={setIsHuman}
                        className="col-12 mb-2"
                      />
                      <div className="col-12">
                        <ButtonWithLoading
                          variant="primary"
                          type="submit"
                          onClick={changeAccountType}
                          data-backdrop="static"
                          loading={
                            updateUserPlansLoading
                              ? updateUserPlansLoading
                              : null
                          }>
                          {I18n.t('save')}
                        </ButtonWithLoading>
                      </div>
                    </div>
                  </form>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            {user.about ? (
              <Card className={activeId === '0' ? 'active-panel' : ''}>
                <Card.Header>
                  <Accordion.Toggle
                    onClick={() => toggleActive('0')}
                    as={Button}
                    variant="link"
                    eventKey="0">
                    <span className="icon icon-user"></span>
                    {I18n.t('about_user')}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ) : null}
            {user.skills ? (
              user.skills.length > 0 ? (
                <Card className={activeId === '1' ? 'active-panel' : ''}>
                  <Card.Header>
                    <Accordion.Toggle
                      onClick={() => toggleActive('1')}
                      as={Button}
                      variant="link"
                      eventKey="1">
                      <span className="icon icon-skills"></span>{' '}
                      {I18n.t('skills')}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <ul className="ProfileSkills">
                        {user.skills.map((skill, index) => (
                          <li key={index}>{skill}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ) : null
            ) : null}
          </Accordion>
        </div>
      )}
    </Fragment>
  );
};

export default MyProfile;
