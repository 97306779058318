import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Pagination from "react-js-pagination";
import AddingRow from "../AddingRow";
import { getAllMessages, markAllMessageAsRead, markMessageAsRead } from "../../redux/messages/actions/getActions";
import { CLEAR_MARK_ALL_MESSAGE_READ_SUCCESS } from "../../redux/messages/actions/types";
import Spin from "../Spin";
import NoItems from "../NoItems";
import { I18n } from 'react-redux-i18n';
import ConfToast from "../ConfToast";
import DeleteModal from "../DeleteModal";
import logoIcon from '../../imgs/logo-icon.png';
import { MetaComponent } from '../MetaComponent';
import { TitleComponent } from "../../components/TitleComponent";


const MyMessages = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const{
    userLoading,
    currentUser,
    isAuthenticated,
    locale,

    //All Messages
    getAllMessagesLoading,
    allMessages,
    totalAllMessages,
    allMessagesPerPage,

    //Mark All Messages Read
    loadingMarkMessagesRead,
    markMessagesSuccess,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    currentUser: state.auth.user,
    isAuthenticated: state.auth.isAuthenticated,
    locale: state.i18n.locale,

    //All Messages
    getAllMessagesLoading: state.messages.getAllMessagesLoading,
    allMessages: state.messages.allMessages,
    totalAllMessages: state.messages.totalAllMessages,
    allMessagesPerPage: state.messages.allMessagesPerPage,

    //Mark All Messages Read
    loadingMarkMessagesRead: state.messages.loadingMarkMessagesRead,
    markMessagesSuccess: state.messages.markMessagesSuccess,
  }));

  const [activePage, setActivePage] = useState(1);
  const [loadingMessages, setLoadingMessages] = useState(true);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
  const handleDeleteConfirmShow = () => setShowDeleteConfirm(true);

  useEffect(() => {
    setLoadingMessages(getAllMessagesLoading);
  }, [getAllMessagesLoading]);

  useEffect(() => {
    if (isAuthenticated){
        const page = activePage;
        const limit = 20;
        dispatch(getAllMessages(null, page, limit, locale));
    }
  },[dispatch, isAuthenticated, locale, activePage]);

  function handlePageChange(pageNumber){
    setActivePage(pageNumber);
  };

  const totalItemsCount = totalAllMessages;
  const itemsCountPerPage = Number(allMessagesPerPage);

  function handleMarkMessagesRead() {
    dispatch(markAllMessageAsRead());
  }

  useEffect(() => {
    if(markMessagesSuccess){
      handleDeleteConfirmClose();
      dispatch({
        type: CLEAR_MARK_ALL_MESSAGE_READ_SUCCESS
      });
      if(activePage === 1){
        const page = 1;
        const limit = 20;
        dispatch(getAllMessages(null, page, limit, locale));
      }else{
        setActivePage(1);
      }
      setTimeout(() => {
        setConfShow(true);
        setConfTitle(I18n.t('mark_all_messages_read'));
        setConfMessage(I18n.t('mark_all_messages_read_success'));
        setConfStatus('Toast__Container__Success');
        setDelay(6000);
      }, 3000);
    }
  }, [markMessagesSuccess, locale, dispatch, activePage]);

  function markMessageRead(id){
    dispatch(markMessageAsRead(id));
  }
  
  return (
    <Fragment>
      <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
      />
      <TitleComponent title={I18n.t('lancersin_messages')} />
      <MetaComponent />
      <DeleteModal
        onHide={handleDeleteConfirmClose}
        onDelete={handleMarkMessagesRead}
        show={showDeleteConfirm}
        modalTitle={"mark_all_messages_read"}
        className="CustomPopup"
        size="md"
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={loadingMarkMessagesRead}
        messageHeading="mark_all_messages_read_body"
      />
      <div className="container">
        <div className="row">
          <div className="col-12 Dashboard__tasks__List">
              <AddingRow
              onAdding={() => {
                handleDeleteConfirmShow();
              }}
              title={"messages"}
              titleIconClassName={"icon-envelope"}
              btnText={"mark_all_messages_read"}
              btnIconClassName={"fas fa-check"}
              forceShowButton={true}
              />
              {
                loadingMessages || userLoading ?
                <Spin />
                : allMessages.length > 0 && !loadingMessages ?
                <div className="notificationList box-shadow">
                    <ul className="list">
                      {
                        allMessages.map(item => (
                          item.morph_type === "project_bids" && item.conversationable ?
                          <li key={item.id}>
                              <Link exact="true" to={{ pathname: `/dashboard/messages/${item.id}/${item.conversationable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`, state: { prevPath: location.pathname } }} onClick={() => markMessageRead(item.id)} className="dropdown-item">
                                  { item.from.id ===  currentUser.id ? <span className="userImg"> <img src={item.to.image} alt={item.to.fname + ' ' + item.to.lname} /></span> : <span className="userImg"> <img src={item.from.image} alt={item.from.fname + ' ' + item.from.lname} /></span> }
                                  <div className='messageContent'>
                                      <p>{I18n.t('inquiry_about') + " " + item.conversationable.title}</p>
                                      <div className="notification__Date">
                                          <span className="icon icon-calender-time"></span>{item.last_reply ? item.last_reply.created_at.Date : item.created_at.Date} - {item.last_reply ? item.last_reply.created_at.Time : item.created_at.Time}
                                      </div>
                                  </div>
                                  {item.read_at ? null : <span className='unread'></span>}
                              </Link>
                          </li>
                          : item.morph_type === "services" && item.conversationable ?
                          <li key={item.id}>
                              <Link exact="true" to={{ pathname: `/dashboard/messages/${item.id}/${item.conversationable.title.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`, state: { prevPath: location.pathname } }} onClick={() => markMessageRead(item.id)} className="dropdown-item">
                                  { item.from.id ===  currentUser.id ? <span className="userImg"> <img src={item.to.image} alt={item.to.fname + ' ' + item.to.lname} /></span> : <span className="userImg"> <img src={item.from.image} alt={item.from.fname + ' ' + item.from.lname} /></span> }    
                                  <div className='messageContent'>
                                      <p>{I18n.t('inquiry_about') + " " + item.conversationable.title}</p>
                                      <div className="notification__Date">
                                          <span className="icon icon-calender-time"></span>{item.last_reply ? item.last_reply.created_at.Date : item.created_at.Date} - {item.last_reply ? item.last_reply.created_at.Time : item.created_at.Time}
                                      </div>
                                  </div>
                                  {item.read_at ? null : <span className='unread'></span>}
                              </Link>
                          </li>
                          : item.morph_type === "system_messages" ?
                          <li key={item.id}>
                              <Link exact="true" to={{ pathname: `/dashboard/messages/${item.id}/${item.subject.replace(/\s+/g, '-').replace(/\//g, '-').toLowerCase()}`, state: { prevPath: location.pathname } }} onClick={() => markMessageRead(item.id)} className="dropdown-item">
                                  { <span className="userImg"> <img src={logoIcon} alt="Lancersin" /></span> }    
                                  <div className='messageContent'>
                                      <p>{ item.subject }</p>
                                      <div className="notification__Date">
                                          <span className="icon icon-calender-time"></span>{item.last_reply ? item.last_reply.created_at.Date : item.created_at.Date} - {item.last_reply ? item.last_reply.created_at.Time : item.created_at.Time}
                                      </div>
                                  </div>
                                  {item.read_at ? null : <span className='unread'></span>}
                              </Link>
                          </li>
                          : null
                        ))
                      }
                    </ul>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
                : 
                <NoItems
                  title="no_messages_found"
                  message="no_messages_body"
                />
              }
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyMessages;
