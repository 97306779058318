import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import { Card, Accordion, Button, Form, Col } from "react-bootstrap";
import { Translate, I18n } from "react-redux-i18n";
import { truncate } from "../ReduceFileName";
import { Size } from "../CalculateSize";
import SpinPulse from "../SpinPulse";
import { addserviceinvoiceComment, getserviceinvoiceComments, deleteserviceinvoiceComment, editserviceinvoiceComment } from "../../actions/serviceinvoicecommentsActions";
import { ADD_SERVICE_INVOICE_COMMENT_FAIL, RESET_SERVICE_INVOICE_COMMENTS } from "../../actions/types";
import QuillBox from "../QuillBox";
import { Uploader } from "../Upload";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import ReportContentModal from "../ReportContentModal";
import DeleteModal from "../DeleteModal";
import GRecaptch from "../GRecaptch";
import { 
    GET_SERVICE_INVOICE_LAST_COMMENT
} from '../../actions/types';

export default function ServiceInvoiceComments(props){
    const dispatch = useDispatch();
    const {
        params,
        serviceinvoice,
        invoiceId,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay
    } = props;
    const {
        id
    } = params;

    const {
        isAuthenticated,
        user,
        errorsId,
        invoiceComments,
        totalRecords,
        itemsPerPage,
        invoiceCommentsLoading,
        addCommentLoading,
        editCommentLoading,
        deleteCommentLoading,
        invoiceLastComment,
        invoiceCommentAdded,
        invoiceCommentEdited,
        invoiceCommentDeleted,
        postReportLoading,
        indorseServiceInvoiceSuccess,
        lastNotification,
      } = useSelector((state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        user: state.auth.user,
        errorsId: state.error.id,
        invoiceComments: state.serviceinvoicecomments.serviceinvoiceComments,
        totalRecords: state.serviceinvoicecomments.totalRecords,
        itemsPerPage: state.serviceinvoicecomments.itemsPerPage,
        invoiceCommentsLoading: state.serviceinvoicecomments.isLoading,
        addCommentLoading: state.serviceinvoicecomments.addCommentLoading,
        editCommentLoading: state.serviceinvoicecomments.editCommentLoading,
        deleteCommentLoading: state.serviceinvoicecomments.deleteCommentLoading,
        invoiceLastComment: state.serviceinvoicecomments.serviceinvoiceLastComment,
        invoiceCommentAdded: state.serviceinvoicecomments.serviceinvoiceCommentAdded,
        invoiceCommentEdited: state.serviceinvoicecomments.serviceinvoiceCommentEdited,
        invoiceCommentDeleted: state.serviceinvoicecomments.serviceinvoiceCommentDeleted,
        postReportLoading: state.reportsTypes.isLoading,
        indorseServiceInvoiceSuccess: state.service.indorseServiceInvoiceSuccess,
        lastNotification: state.notifications.lastNotification,
    }));

    const loggedUserId = user.id;
    const serviceBuyerId = serviceinvoice.user.id;

    const [activePage, setActivePage] = useState(1);
    const [comment, setComment] = useState("");
    const [editComment, setEditComment] = useState(false);
    const [commentId, setCommentId] = useState("");
    const [commentEdited, setCommentEdited] = useState("");
    const [localErrors, setLocalErrors] = useState({});
    const [uploadErrors, setUploadErrors] = useState([]);
    const [showReport, setShowReport] = useState(false);
    const [lastCommentTD, setLastCommentTD] = useState(0);
    const [ERROR_ACTION_TYPE, setErrorActionType] = useState(ADD_SERVICE_INVOICE_COMMENT_FAIL);
    const [activeId, setActiveId] = useState('0');
    const [deleteCommentId, setDeleteCommentId] = useState("");
    const [deleteComment, setDeleteComment] = useState("");
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [isHuman, setIsHuman] = useState(false);
    const handleDeleteConfirmClose = () => setShowDeleteConfirm(false);
    const handleDeleteConfirmShow = (id, comment) => {
        setDeleteCommentId(id);
        setDeleteComment(comment);
        setShowDeleteConfirm(true);
    }


    const [files, setFiles] = useState([]);
    const [filesUpload, setFilesUpload] = useState(false);
    const [filesChosen, setFilesChosen] = useState(false);
    const [filesUploadErrors, setFilesUploadErrors] = useState([]);
    const [filesSortLength, setFilesSortLength] = useState(false);
    const [filteredFilesErrorsLength, setFilteredFilesErrorsLength] = useState(false);

    const filteredFilesErrors = filesUploadErrors ? filesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
    ) : null;

    const [editFiles, setEditFiles] = useState([]);
    const [editFilesUpload, setEditFilesUpload] = useState(false);
    const [editFilesChosen, setEditFilesChosen] = useState(false);
    const [editFilesUploadErrors, setEditFilesUploadErrors] = useState([]);
    const [editFilesSortLength, setEditFilesSortLength] = useState(false);
    const [editFilteredFilesErrorsLength, setEditFilteredFilesErrorsLength] = useState(false);

    const editFilteredFilesErrors = editFilesUploadErrors ? editFilesUploadErrors.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        [],
    ) : null;

    useEffect(() => {
        if(files.length > 0){
            setFilesSortLength(true) 
        }
    }, [files]);

    useEffect(() => {
        if(lastNotification && lastNotification.notification_content && lastNotification.notification_content.type === "CreateServiceComment"){
            if(invoiceId === lastNotification.notification_content.content.invoice.id.toString()){
                let comment = {};
                comment.id = lastNotification.notification_content.content.id;
                comment.comment = lastNotification.notification_content.content.comment;
                comment.created_at = lastNotification.notification_content.content.created_at;
                comment.updated_at = lastNotification.notification_content.content.updated_at;
                comment.files = lastNotification.notification_content.content.files;
                comment.time_difference = lastNotification.notification_content.content.time_difference;
                comment.user = lastNotification.notification_content.user;
                dispatch ({
                    type: GET_SERVICE_INVOICE_LAST_COMMENT,
                    payload: comment
                });
            }
        }
    }, [lastNotification, dispatch, invoiceId]);

    useEffect(()=> {
        if(filteredFilesErrorsLength.length > 0){
            setFilteredFilesErrorsLength(true) 
        }
    }, [filteredFilesErrorsLength]);

    useEffect(()=> {
        if(editFilteredFilesErrorsLength.length > 0){
            setEditFilteredFilesErrorsLength(true) 
        }
    }, [editFilteredFilesErrorsLength]);

    useEffect(() => {
        if(editFiles.length > 0){
            setEditFilesSortLength(true) 
        }
    }, [editFiles]);

    const createMarkup = (text) => {
        return { __html: text };
    };

    function handlePageChange(pageNumber){
        const page = pageNumber;
        const limit = 30;
        dispatch(getserviceinvoiceComments(id, page, limit));
        setActivePage(pageNumber);
    };

    // useEffect(() => {
    //     const currentDate = new Date();
    //     if(currentDate && invoiceLastComment){
    //         const lastCommentDate = new Date(invoiceLastComment.updated_at);
    //         const timeDifference = Math.abs(currentDate - lastCommentDate);
    //         const minDifference = Math.floor(timeDifference / 60000);
    //         setLastCommentTD(minDifference);
    //     }
    // }, [invoiceLastComment]);

    useEffect(()=> {
        if(invoiceLastComment && !addCommentLoading){
            setTimeout(() => {
                setLastCommentTD(2);
            }, 90000);
        }
    },[invoiceLastComment, addCommentLoading]);

    useEffect(() => {
        if(invoiceLastComment){
            const page = 1;
            const limit = 30;
            dispatch(getserviceinvoiceComments(id, page, limit));
        }
    }, [invoiceLastComment, dispatch, id]);

    useEffect(() => {
        if(errorsId){
            setErrorActionType(errorsId);
        }
    }, [errorsId]);

    function handover(reports){
        const handoverRequests = reports.filter( function(item){
          if(item.type === "handover"){
            return true;
          }
          return false;
        }).map(function(item) { return item });
    
        if(handoverRequests.length > 0){
          const maxId = Math.max(...handoverRequests.map(item => item.id));
          const currentHandover = handoverRequests.filter( function(item){
            if(item.id === maxId){
              return true;
            }
            return false;
          }).map(function(item) { return item });
          if(currentHandover[0].status === "pending"){
            return "pending"
          }
          else if(currentHandover[0].status === "declined"){
            return "declined"
          }
          return "noHandover";
        }
    }

    //Get Invoice Comments
    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);

    function isObject(val) {
        return (typeof val === 'object');
    }

    function handleItemLocalError({ propName, currentValue, message, uploadArray }) {
        if(currentValue){
            if(isObject(currentValue) && !Array.isArray(currentValue)){
                if (Object.keys(currentValue).length > 0) {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                    }));
                } else {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: message,
                    }));
                }
            }else if(Array.isArray(currentValue) && uploadArray === true){
                if (currentValue.length <= 0) {
                    const noImageError = {
                        code: 'NoImage',
                        message: message,
                    };
                    const filtered = uploadErrors.filter(function(value, index, arr){ 
                        return value.code !== 'NoImage';
                    });
                    filtered.push(noImageError);
                    setUploadErrors(filtered);
                } else {
                    const filtered = uploadErrors.filter(function(value, index, arr){ 
                        return value.code !== 'NoImage';
                    });
                    setUploadErrors(filtered);
                }
            }else if(Array.isArray(currentValue) && uploadArray === false){
                if (currentValue.length <= 0) {
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: message,
                    }));
                }else{
                    setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                    }));
                }
            }else{
                setLocalErrors((errors) => ({
                    ...errors,
                    [propName]: undefined,
                }));
            }
        }else{
            setLocalErrors((errors) => ({
            ...errors,
            [propName]: message,
            }));
        }
    }

    function handleCommentChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setComment("");
        } else {
            setComment(value);
        }
    }

    function onCommentBlur(){
        handleItemLocalError({
            propName: "comment",
            currentValue: comment,
            message: I18n.t('comment_required'),
        });
    }

    function handleCommentEdit(value){
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setCommentEdited("");
        } else {
            setCommentEdited(value);
        }
    };

    function onCommentEditBlur(){
        handleItemLocalError({
            propName: "commentEdited",
            currentValue: commentEdited,
            message: I18n.t('comment_required'),
        });
    }

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

    function addComment(e){
        e.preventDefault();
        handleItemLocalError({
            propName: "comment",
            currentValue: comment,
            message: I18n.t('comment_required'),
        });

        if (
            filesUpload === false &&
            filesChosen === false &&
            comment &&
            isHuman
        ) {
            const commentData = {
                comment,
                invoice_id: invoiceId,
                files: files.map(item => item.source),
            };
            dispatch(addserviceinvoiceComment(commentData));
        } else if (
            filesUpload === true ||
            filesChosen === true
        ) {
            handleToast(true, I18n.t('upload_notification_title'), I18n.t('upload_notification'), 'Toast__Container__Warning', 6000)
        } else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
    };

    function onSubmitEditComment(e){
        e.preventDefault();

        handleItemLocalError({
            propName: "commentEdited",
            currentValue: commentEdited,
            message: I18n.t('comment_required'),
        });
        if (
            editFilesUpload === false &&
            editFilesChosen === false &&
            commentEdited &&
            isHuman
        ) {
            const commentData = {
                comment: commentEdited,
                invoice_id: invoiceId,
                id: commentId,
                files: editFiles.map(item => item.source),
            };
            dispatch(editserviceinvoiceComment(commentData));
        } else if (
            filesUpload === true ||
            filesChosen === true
        ) {
            handleToast(true, I18n.t('upload_notification_title'), I18n.t('upload_notification'), 'Toast__Container__Warning', 6000)
        } else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
    };

    useEffect(() => {
        if(handover(serviceinvoice.reports) === "approved" && loggedUserId === serviceBuyerId){
            handleToast(true, I18n.t('delivery_success_title'), I18n.t('delivery_approval_success'), 'Toast__Container__Success', 6000);
        }
    }, [indorseServiceInvoiceSuccess,dispatch, handleToast, loggedUserId, serviceBuyerId, serviceinvoice]);

    function editCommentFun(comment){
        setEditComment(true);
        setCommentId(comment.id);
        setCommentEdited(comment.comment);
        if(comment.files){
            setEditFiles(comment.files.map((item)=>({
                loaded: item.size,
                name: item.filename,
                origin: item.filename,
                path: item.filename,
                percent: 100,
                preview: item.url,
                size: item.size,
                source: item.url,
                total: item.size,
                type: `image/${item.extension}`
            })));
        }
    };

    function cancelEditComment(){
        setEditComment(false);
        setCommentId("");
        setCommentEdited("");
        setEditFiles([]);
    };

    useEffect(()=>{
        if(invoiceCommentAdded === "success"){
            setFiles([]);
            setComment("");
            const page = 1;
            const limit = 30;
            dispatch(getserviceinvoiceComments(id, page, limit));
            handleToast(true, I18n.t('adding_bid_comment_title'), I18n.t('adding_bid_comment_success'), 'Toast__Container__Success', 6000);
            dispatch({
                type: RESET_SERVICE_INVOICE_COMMENTS
            })
        }
    }, [invoiceCommentAdded, dispatch, handleToast, id]);

    useEffect(()=>{
        if(invoiceCommentDeleted === "success"){
            const page = 1;
            const limit = 30;
            dispatch(getserviceinvoiceComments(id, page, limit));
            setShowDeleteConfirm(false);
            handleToast(true, I18n.t('delete_comment'), I18n.t('delete_comment_success'), 'Toast__Container__Success', 6000);
        }
    }, [invoiceCommentDeleted, dispatch, handleToast, id]);

    useEffect(()=>{
        if(invoiceCommentEdited === "success"){
            setEditFiles([]);
            setCommentEdited("");
            setCommentId("");
            setEditComment(false);
            const page = 1;
            const limit = 30;
            dispatch(getserviceinvoiceComments(id, page, limit));
            handleToast(true, I18n.t('adding_bid_comment_title'), I18n.t('adding_bid_comment_success'), 'Toast__Container__Success', 6000);
        }
    }, [invoiceCommentEdited, dispatch, handleToast, id]);

    function handleShowReport() {
        setShowReport(true);
    };
    function handleHideReport() {
        setShowReport(false);
    };

    function handleDeleteOnClick() {
        dispatch(deleteserviceinvoiceComment({ id: deleteCommentId }));
    }

    function toggleActive(id) {
        if (activeId === id) {
        setActiveId(null);
        } else {
        setActiveId(id);
        }
    }

    return(
        <Fragment>
            <ReportContentModal
                onHide={handleHideReport}
                show={showReport}
                className="CustomPopup"
                size="lg"
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                loading={postReportLoading}
                model="service_invoice_comments"
                model_id={commentId}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            <DeleteModal
                onHide={handleDeleteConfirmClose}
                onDelete={handleDeleteOnClick}
                show={showDeleteConfirm}
                modalTitle={"delete_comment"}
                className="CustomPopup"
                size="md"
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                loading={deleteCommentLoading}
                messageHeading="delete_conf"
                messageBody={deleteComment}
            />
            <Accordion defaultActiveKey="0">
                <Card className={activeId === '0' ? 'Comments__Container active-panel' : 'Comments__Container'}>
                    <Card.Header>
                        <Accordion.Toggle
                        as={Button}
                        onClick={() => toggleActive('0')}
                        variant="link"
                        eventKey="0"
                        >
                        {I18n.t("service_discussion")}
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                        {
                        handover(serviceinvoice.reports) === "pending" && loggedUserId === serviceBuyerId ?
                        null
                        :
                        <Form onSubmit={addComment}>
                            <QuillBox
                                required
                                controlId="Comment"
                                label={I18n.t('add_comment')}
                                value={comment}
                                onChange={handleCommentChange}
                                onBlur={onCommentBlur}
                                feedbackType="invalid"
                                feedback={localErrors.comment ? localErrors.comment : null}
                                isInvalid={(errorsId === ERROR_ACTION_TYPE) || localErrors.comment}
                            />
                            <Form.Group controlId="CommentFiles">
                                <Form.Label>{I18n.t("upload_files")}</Form.Label>
                                <small>{I18n.t('service_files_hint')}</small>
                                <Uploader 
                                    accept={[
                                        {
                                        mime: "application/msword",
                                        ext: "doc"
                                        }, 
                                        {
                                        mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                        ext: "docx"
                                        }, 
                                        {
                                        mime: "application/vnd.ms-powerpoint",
                                        ext: "ppt"
                                        },
                                        {
                                        mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                        ext: "pptx"
                                        },
                                        {
                                        mime: "application/vnd.ms-excel",
                                        ext: "xls"
                                        },
                                        {
                                        mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                        ext: "xlsx"
                                        },
                                        {
                                        mime: "application/pdf",
                                        ext: "pdf"
                                        },
                                        {
                                        mime: "image/jpeg",
                                        ext: "jpeg"
                                        },
                                        {
                                        mime: "image/gif",
                                        ext: "gif"
                                        },
                                        {
                                        mime: "image/png",
                                        ext: "png"
                                        },
                                        {
                                        mime: "application/rtf",
                                        ext: "rtf"
                                        },
                                        {
                                        mime: "text/plain",
                                        ext: "plain-text"
                                        },
                                        {
                                        mime: "application/x-rar",
                                        ext: "rar"
                                        },
                                        {
                                        mime: "application/zip",
                                        ext: "zip"
                                        },
                                    ]}
                                    maxFiles={10} 
                                    multiple={true}
                                    maxSize={10000000}
                                    sortable={false}
                                    setFilesChosen={setFilesChosen}
                                    setFilesUpload={setFilesUpload}
                                    setUploadErrors={setFilesUploadErrors}
                                    uploadErrors={filesUploadErrors}
                                    setFilesSort={setFiles}
                                    filesSort={files}
                                    filesSortLength={filesSortLength}
                                    filteredErrorsLength={filteredFilesErrorsLength}
                                    filteredErrors={filteredFilesErrors}
                                    uploadPath="service_invoice_comments"
                                    projectId= {serviceinvoice ? serviceinvoice.id : null}
                                />
                            </Form.Group>
                            <Form.Row className="mb-4">
                                <Col>
                                    <GRecaptch
                                        setIsHuman={setIsHuman}
                                        className="mt-3"
                                    />
                                </Col>
                            </Form.Row>
                            <ButtonWithLoading
                                variant="primary"
                                type="submit"
                                onClick={addComment}
                                data-backdrop="static"
                                loading={addCommentLoading ? addCommentLoading : null}
                            >
                                <Translate value="add" />
                            </ButtonWithLoading>
                        </Form>
                        }
                        <ul className="CommentsList mt-4">
                            {
                                invoiceCommentsLoading ? (
                                    <div className="fullPageLoading">
                                        <SpinPulse />
                                    </div>
                                ) : null}
                            {
                                invoiceComments && invoiceLastComment ?
                                invoiceComments.some(e => e.id === invoiceLastComment.id) ? null :
                                (
                                    <li key={invoiceLastComment.id}>
                                        <div className="Comment__userInfo">
                                            <div className="UserPic">
                                            <img
                                                src={invoiceLastComment.user.image.url ? invoiceLastComment.user.image.url : invoiceLastComment.user.image}
                                                alt={
                                                invoiceLastComment.user.fname +
                                                " " +
                                                invoiceLastComment.user.lname
                                                }
                                            />
                                            </div>
                                            <div className="CommentContent__Details">
                                            <h3>
                                                <Link
                                                to={{
                                                    pathname: `/in/${invoiceLastComment.user.username}`,
                                                }}
                                                >
                                                {invoiceLastComment.user.fname +
                                                    " " +
                                                    invoiceLastComment.user.lname}
                                                </Link>
                                            </h3>
                                            <div className="CommentContent__Details__PostTime">
                                                {invoiceLastComment.created_at.Date +
                                                " - " +
                                                invoiceLastComment.created_at.Time}
                                            </div>
                                            </div>
                                        </div>
                                        <div className="CommentContent">
                                            {editComment === true &&
                                            commentId === invoiceLastComment.id ? (
                                                <Form
                                                onSubmit={onSubmitEditComment}
                                                >
                                                    <QuillBox
                                                        required
                                                        controlId="Comment"
                                                        label={I18n.t('edit_comment')}
                                                        value={commentEdited}
                                                        onChange={handleCommentEdit}
                                                        onBlur={onCommentEditBlur}
                                                        feedbackType="invalid"
                                                        feedback={localErrors.commentEdited ? localErrors.commentEdited : null}
                                                        isInvalid={(errorsId === ERROR_ACTION_TYPE) || localErrors.commentEdited}
                                                    />
                                                    <Form.Group controlId="CommentFiles">
                                                        <Form.Label>
                                                            {I18n.t("upload_files")}
                                                        </Form.Label>
                                                        <small>{I18n.t('service_files_hint')}</small>
                                                        <Uploader 
                                                            accept={[
                                                                {
                                                                mime: "application/msword",
                                                                ext: "doc"
                                                                }, 
                                                                {
                                                                mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                                ext: "docx"
                                                                }, 
                                                                {
                                                                mime: "application/vnd.ms-powerpoint",
                                                                ext: "ppt"
                                                                },
                                                                {
                                                                mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                                ext: "pptx"
                                                                },
                                                                {
                                                                mime: "application/vnd.ms-excel",
                                                                ext: "xls"
                                                                },
                                                                {
                                                                mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                                ext: "xlsx"
                                                                },
                                                                {
                                                                mime: "application/pdf",
                                                                ext: "pdf"
                                                                },
                                                                {
                                                                mime: "image/jpeg",
                                                                ext: "jpeg"
                                                                },
                                                                {
                                                                mime: "image/gif",
                                                                ext: "gif"
                                                                },
                                                                {
                                                                mime: "image/png",
                                                                ext: "png"
                                                                },
                                                                {
                                                                mime: "application/rtf",
                                                                ext: "rtf"
                                                                },
                                                                {
                                                                mime: "text/plain",
                                                                ext: "plain-text"
                                                                },
                                                                {
                                                                mime: "application/x-rar",
                                                                ext: "rar"
                                                                },
                                                                {
                                                                mime: "application/zip",
                                                                ext: "zip"
                                                                },
                                                            ]}
                                                            maxFiles={10} 
                                                            multiple={true}
                                                            maxSize={10000000}
                                                            sortable={false}
                                                            setFilesChosen={setEditFilesChosen}
                                                            setFilesUpload={setEditFilesUpload}
                                                            setUploadErrors={setEditFilesUploadErrors}
                                                            uploadErrors={editFilesUploadErrors}
                                                            setFilesSort={setEditFiles}
                                                            filesSort={editFiles}
                                                            filesSortLength={editFilesSortLength}
                                                            filteredErrorsLength={editFilteredFilesErrorsLength}
                                                            filteredErrors={editFilteredFilesErrors}
                                                            uploadPath="service_invoice_comments"
                                                            projectId= {invoiceLastComment ? invoiceLastComment.id : null}
                                                        />
                                                    </Form.Group>
                                                    <Form.Row className="mb-4">
                                                        <Col>
                                                            <GRecaptch
                                                                setIsHuman={setIsHuman}
                                                                className="mt-3"
                                                            />
                                                        </Col>
                                                    </Form.Row>
                                                    <ButtonWithLoading
                                                        variant="primary"
                                                        type="submit"
                                                        onClick={onSubmitEditComment}
                                                        data-backdrop="static"
                                                        loading={editCommentLoading ? editCommentLoading : null}
                                                    >
                                                        <Translate value="save" />
                                                    </ButtonWithLoading>
                                                    <Button
                                                        variant="secondary"
                                                        type="button"
                                                        onClick={cancelEditComment}
                                                        data-backdrop="static"
                                                    >
                                                    <Translate value="cancel" />
                                                    </Button>
                                                    {invoiceCommentsLoading
                                                    ? cancelEditComment()
                                                    : null}
                                                </Form>
                                            ) : (
                                                <Fragment>
                                                    <div
                                                        dangerouslySetInnerHTML={createMarkup(
                                                        invoiceLastComment.comment
                                                        )}
                                                    ></div>
                                                    {
                                                        invoiceLastComment.files ? 
                                                            invoiceLastComment.files.length > 0 ? (
                                                                <div>
                                                                    <h3 className="attachments__title">
                                                                    <span>
                                                                        {I18n.t("attached_files")}
                                                                    </span>
                                                                    </h3>
                                                                    <ul className="ProfileSkills">
                                                                    {invoiceLastComment.files.map(
                                                                        (file, index) => (
                                                                        <li key={index}>
                                                                            <span className="file__type">
                                                                            {file.extension}
                                                                            </span>
                                                                            <a
                                                                            href={file.url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            >
                                                                            {truncate(
                                                                                file.filename,
                                                                                20
                                                                            )}
                                                                            </a>{" "}
                                                                            -{" "}
                                                                            <span className="file__size">
                                                                            ({Size(file.size)})
                                                                            </span>
                                                                        </li>
                                                                        )
                                                                    )}
                                                                    </ul>
                                                                </div>
                                                            ) : null 
                                                        : null
                                                    }
                                                </Fragment>
                                            )}
                                        </div>
                                        {
                                            isAuthenticated ? (
                                                user.id === invoiceLastComment.user.id ? (
                                                    editComment === false &&
                                                    lastCommentTD < 2 ? (
                                                        <div className="Comments__actions">
                                                        <button
                                                            className="btn btn-link"
                                                            onClick={() => editCommentFun(invoiceLastComment)}
                                                        >
                                                            <span className="icon icon-edit"></span>{" "}
                                                            <Translate value="edit" />
                                                        </button>
                                                        <button
                                                            className="btn btn-link-delete"
                                                            onClick={() => handleDeleteConfirmShow(invoiceLastComment.id, invoiceLastComment.comment)}
                                                        >
                                                            <span className="icon icon-delete"></span>{" "}
                                                            <Translate value="delete" />
                                                        </button>
                                                        </div>
                                                    ) : null
                                                ) : (
                                                <div className="Comments__actions">
                                                    <button
                                                    className="btn btn-link"
                                                    onClick={() => {
                                                        handleShowReport();
                                                        setCommentId(invoiceLastComment.id);
                                                    }}
                                                    >
                                                    <span className="icon icon-report"></span>{" "}
                                                    <Translate value="report_content" />
                                                    </button>
                                                </div>
                                                )
                                            ) : null
                                        }
                                    </li>
                                )
                                : null
                            }
                            {invoiceComments
                            ? invoiceComments.map((comment) => {
                                return (
                                    <li key={comment.id}>
                                    <div className="Comment__userInfo">
                                        <div className="UserPic">
                                        <img
                                            src={comment.user.image.url ? comment.user.image.url : comment.user.image}
                                            alt={
                                            comment.user.fname +
                                            " " +
                                            comment.user.lname
                                            }
                                        />
                                        </div>
                                        <div className="CommentContent__Details">
                                        <h3>
                                            <Link
                                            to={{
                                                pathname: `/in/${comment.user.username}`,
                                            }}
                                            >
                                            {comment.user.fname +
                                                " " +
                                                comment.user.lname}
                                            </Link>
                                        </h3>
                                        <div className="CommentContent__Details__PostTime">
                                            {comment.created_at.Date +
                                            " - " +
                                            comment.created_at.Time}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="CommentContent">
                                        {editComment === true &&
                                        commentId === comment.id ? (
                                            <Form
                                                onSubmit={onSubmitEditComment}
                                            >
                                                <QuillBox
                                                    required
                                                    controlId="Comment"
                                                    label={I18n.t('edit_comment')}
                                                    value={commentEdited}
                                                    onChange={handleCommentEdit}
                                                    onBlur={onCommentEditBlur}
                                                    feedbackType="invalid"
                                                    feedback={localErrors.commentEdited ? localErrors.commentEdited : null}
                                                    isInvalid={(errorsId === ERROR_ACTION_TYPE) || localErrors.commentEdited}
                                                />
                                                <Form.Group controlId="CommentFiles">
                                                    <Form.Label>
                                                        {I18n.t("upload_files")}
                                                    </Form.Label>
                                                    <small>{I18n.t('service_files_hint')}</small>
                                                    <Uploader 
                                                        accept={[
                                                            {
                                                            mime: "application/msword",
                                                            ext: "doc"
                                                            }, 
                                                            {
                                                            mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                            ext: "docx"
                                                            }, 
                                                            {
                                                            mime: "application/vnd.ms-powerpoint",
                                                            ext: "ppt"
                                                            },
                                                            {
                                                            mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                                                            ext: "pptx"
                                                            },
                                                            {
                                                            mime: "application/vnd.ms-excel",
                                                            ext: "xls"
                                                            },
                                                            {
                                                            mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                            ext: "xlsx"
                                                            },
                                                            {
                                                            mime: "application/pdf",
                                                            ext: "pdf"
                                                            },
                                                            {
                                                            mime: "application/vnd.rar",
                                                            ext: "rar"
                                                            },
                                                            {
                                                            mime: "application/x-tar",
                                                            ext: "tar"
                                                            },
                                                            {
                                                            mime: "application/zip",
                                                            ext: "zip"
                                                            },
                                                            {
                                                            mime: "application/x-7z-compressed",
                                                            ext: "7z"
                                                            },
                                                            {
                                                            mime: "image/jpeg",
                                                            ext: "jpeg"
                                                            },
                                                            {
                                                            mime: "image/gif",
                                                            ext: "gif"
                                                            },
                                                            {
                                                            mime: "image/png",
                                                            ext: "png"
                                                            },

                                                        ]}
                                                        maxFiles={10} 
                                                        multiple={true}
                                                        maxSize={1000000}
                                                        sortable={false}
                                                        setFilesChosen={setEditFilesChosen}
                                                        setFilesUpload={setEditFilesUpload}
                                                        setUploadErrors={setEditFilesUploadErrors}
                                                        uploadErrors={editFilesUploadErrors}
                                                        setFilesSort={setEditFiles}
                                                        filesSort={editFiles}
                                                        filesSortLength={editFilesSortLength}
                                                        filteredErrorsLength={editFilteredFilesErrorsLength}
                                                        filteredErrors={editFilteredFilesErrors}
                                                        uploadPath="service_invoice_comments"
                                                        projectId= {comment ? comment.id : null}
                                                    />
                                                </Form.Group>
                                                <Form.Row className="mb-4">
                                                    <Col>
                                                        <GRecaptch
                                                            setIsHuman={setIsHuman}
                                                            className="mt-3"
                                                        />
                                                    </Col>
                                                </Form.Row>
                                                <ButtonWithLoading
                                                    variant="primary"
                                                    type="submit"
                                                    onClick={onSubmitEditComment}
                                                    data-backdrop="static"
                                                    loading={editCommentLoading ? editCommentLoading : null}
                                                >
                                                    <Translate value="save" />
                                                </ButtonWithLoading>
                                                <Button
                                                variant="secondary"
                                                type="button"
                                                onClick={cancelEditComment}
                                                data-backdrop="static"
                                                >
                                                <Translate value="cancel" />
                                                </Button>
                                                {invoiceCommentsLoading
                                                ? cancelEditComment()
                                                : null}
                                            </Form>
                                        ) : (
                                            <Fragment>
                                                <div
                                                    dangerouslySetInnerHTML={createMarkup(
                                                    comment.comment
                                                    )}
                                                ></div>
                                                {
                                                    comment.files ? 
                                                        comment.files.length > 0 ? (
                                                            <div>
                                                                <h3 className="attachments__title">
                                                                <span>
                                                                    {I18n.t("attached_files")}
                                                                </span>
                                                                </h3>
                                                                <ul className="ProfileSkills">
                                                                {comment.files.map(
                                                                    (file, index) => (
                                                                    <li key={index}>
                                                                        <span className="file__type">
                                                                        {file.extension}
                                                                        </span>
                                                                        <a
                                                                        href={file.url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        >
                                                                        {truncate(
                                                                            file.filename,
                                                                            20
                                                                        )}
                                                                        </a>{" "}
                                                                        -{" "}
                                                                        <span className="file__size">
                                                                        ({Size(file.size)})
                                                                        </span>
                                                                    </li>
                                                                    )
                                                                )}
                                                                </ul>
                                                            </div>
                                                        ) : null 
                                                    : null
                                                }
                                            </Fragment>
                                        )}
                                    </div>
                                    {isAuthenticated ? (
                                        user.id === comment.user.id ? 
                                        // (
                                        // editComment === false &&
                                        // comment.time_difference < 1.5 ? (
                                        //     <div className="Comments__actions">
                                        //     <button
                                        //         className="btn btn-link"
                                        //         onClick={() => editCommentFun(comment)}
                                        //     >
                                        //         <span className="icon icon-edit"></span>{" "}
                                        //         <Translate value="edit" />
                                        //     </button>
                                        //     <button
                                        //         className="btn btn-link-delete"
                                        //         onClick={() => handleDeleteConfirmShow(comment.id, comment.comment)}
                                        //     >
                                        //         <span className="icon icon-delete"></span>{" "}
                                        //         <Translate value="delete" />
                                        //     </button>
                                        //     </div>
                                        // ) : null
                                        // ) 
                                        null
                                        : (
                                        <div className="Comments__actions">
                                            <button
                                            className="btn btn-link"
                                            onClick={() => {
                                                handleShowReport();
                                                setCommentId(comment.id);
                                            }}
                                            >
                                            <span className="icon icon-report"></span>{" "}
                                            <Translate value="report_content" />
                                            </button>
                                        </div>
                                        )
                                    ) : null}
                                    </li>
                                );
                                })
                            : null}
                        </ul>
                        {invoiceComments.length > 0 ? (
                            <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            </div>
                        ) : null}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </Fragment>
    )
}