import React from "react";

export default function RatingStarsPreview({ rate = 0 }) {
  var count = 5;
  var stars = [];

  const ratingStars = Math.round(rate);

  for (let i = 1; i <= count; ++i) {
    let active = i <= ratingStars;
    let starType = active ? "fas" : "far";

    stars.push(
      <i
        key={`star-${i}`}
        className={`${starType} fa-star`}
        datatype={i}
      />
    );
  }
  return (
      <div className="userRating">
        {
            stars.map( i => i)
        }
      </div>
    );
}
