import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import ConnectionLost from "../ConnectionLost";
import { MetaComponent } from '../MetaComponent';
import ClientSteps from '../ClientSteps';
import LockCard from "../LockCard";
import { I18n } from "react-redux-i18n";
import Section from '../Section/Section';
import { setLangArabic, setLocaleWithFallback } from '../../actions/langActions';

export default function LandingPage(){

    const dispatch = useDispatch();

    const {
        isAuthenticated,
        errorMessage,
      } = useSelector((state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        errorMessage: state.error.msg,
    }));

    useEffect(() => {
        const langSet = localStorage.getItem('lang');
        if (langSet){
            if (langSet === 'English'){
                const code = 'ar';
                dispatch(setLangArabic(code));
                dispatch(setLocaleWithFallback(code));
            } else {
                const code = 'ar';
                dispatch(setLangArabic(code));
                dispatch(setLocaleWithFallback(code));
            }
        } else{
            const code = 'ar';
            dispatch(setLangArabic(code));
            dispatch(setLocaleWithFallback(code));
        }
    },[dispatch]);

    if(isAuthenticated){
        return <Redirect to="/" />;
    } else{
        return(
                errorMessage && errorMessage === "Network Error" ?
                <div className="container">
                    <div className="row">
                        <div className="col-12 Dashboard__tasks__List">
                            <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                            />
                        </div>
                    </div>
                </div>
                :
                <Fragment>
                    <MetaComponent />
                    <header className='inner-bg'>
                        <div className="container">
                            <nav className="header__nav">
                                <div className="header__nav--mshape d-flex justify-content-center">
                                    <figure className="header__nav--leftSide">
                                        <Link to="/" aria-label="لانسرزان">
                                            <span className='logoAr'></span>
                                        </Link>
                                    </figure>
                                </div>
                            </nav>
                        </div>
                    </header>
                    <Section 
                        sectionClassName="sectionTopBottomSpaces siteDesc bg"
                        h2Content={I18n.t('hire')}
                        h1Content={I18n.t('professional_freelancers')}
                    >
                        <div className='row'>
                            <div className='col-12'>
                                <p className='text-center'>{I18n.t('hire_desc')}</p>
                            </div>
                        </div>
                    </Section>
                    <ClientSteps />
                    <section className='loginToAdd'>
                        <LockCard
                            title="get_started"
                            signFor="register_to_add"
                            fullPage={true}
                        />
                    </section>
                    <footer className="footer sectionTopBottomSpaces pt-0">
                        <div className="copyright__bg">
                            <div className="container">
                                <div className="copyright__container">
                                    <p className="copyright">{I18n.t('copyright')} {(new Date().getFullYear())} {I18n.t('lancersin_tech')}</p>
                                    <ul>
                                        <li className='lazy-background visa'><span></span></li>
                                        <li className='lazy-background master'><span></span></li>
                                        <li className='lazy-background paybal'><span></span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </footer>
                </Fragment>
        )
    }
}