import { GET_SERVICE_ITEM_SUCCESS, SERVICE_ITEM_LOADING, GET_SERVICE_ITEM_FAIL, SERVICES_ITEMS_LOADING, GET_SERVICES_SUCCESS, GET_SERVICES_FAIL } from '../actions/types';

const initialState = {
    serviceitem: [],
    services: [],
    loadingserviceitem: false,
    loadingservices: false
}

export default function freelancerserviceitemReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SERVICE_ITEM_SUCCESS:
            return{
                ...state,
                serviceitem: action.payload.data,
                loadingserviceitem: false
            }    
        ;
        case SERVICE_ITEM_LOADING: 
            return{
                ...state,
                loadingserviceitem: true
            }
        ;
        case GET_SERVICE_ITEM_FAIL:
            return{
                ...state,
                loadingserviceitem: false
            }
        ;
        case GET_SERVICES_SUCCESS:
            return{
                ...state,
                loadingservices: false
            }    
        ;
        case SERVICES_ITEMS_LOADING: 
            return{
                ...state,
                loadingservices: true
            }
        ;
        case GET_SERVICES_FAIL:
            return{
                ...state,
                loadingservices: false
            }
        ;
        default:
            return state;
    }
}