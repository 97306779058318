import { GET_PORTFOLIO_ITEM_SUCCESS, PORTFOLIO_ITEM_LOADING, GET_PORTFOLIO_ITEM_FAIL, PORTFOLIO_ITEMS_LOADING, GET_PORTFOLIO_SUCCESS } from '../actions/types';

const initialState = {
    portfolioitem: [],
    portfolio: [],
    loadingportfolioitem: false,
    loadingportfolioitems: false
}

export default function freelancerportfolioitemReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PORTFOLIO_ITEM_SUCCESS:
            return{
                ...state,
                portfolioitem: action.payload,
                loadingportfolioitem: false
            }    
        ;
        case PORTFOLIO_ITEM_LOADING: 
            return{
                ...state,
                loadingportfolioitem: true
            }
        ;
        case GET_PORTFOLIO_ITEM_FAIL:
            return {
                ...state,
                loadingportfolioitem: false
            }
        ;
        case GET_PORTFOLIO_SUCCESS:
            return{
                ...state,
                loadingportfolioitems: false
            }    
        ;
        case PORTFOLIO_ITEMS_LOADING: 
            return{
                ...state,
                loadingportfolioitems: true
            }
        ;
        default:
            return state;
    }
}