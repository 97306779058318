import { GET_STATUS, STATUS_LOADING } from '../actions/types';

const initialState = {
    statuses: [],
    getStatusesLoading: false
}

export default function statusReducer(state = initialState, action) {
    switch (action.type) {
        case GET_STATUS:
            return{
                ...state,
                statuses: action.payload,
                getStatusesLoading: false
            }    
        ;
        case STATUS_LOADING: 
            return{
                ...state,
                getStatusesLoading: true
            }
        ;
        default:
            return state;
    }
}