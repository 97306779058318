// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  INVITE_USER_LOADING,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAIL,
} = TYPES;

// Send Message

export function inviteUser(morphType = null, morphIDs = null, user_id = null) {
  return async function inviteUserWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: INVITE_USER_LOADING
    });
    try {
        const body = {morphType, morphIDs, user_id};
        let response = await axiosInstance
        .post(API.INVITE_USER, body , tokenConfig(getState));
        if(response.status !== 200){
            dispatch({
              type: INVITE_USER_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: INVITE_USER_SUCCESS,
            payload: response.data.data
          })
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch({
            type: INVITE_USER_FAIL,
          });
          dispatch(returnErrors(error.response.data, error.response.status, 'INVITE_USER_FAIL'));
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch({
            type: INVITE_USER_FAIL,
          });
          dispatch(returnErrors(errorParsed.message, "Network Error", 'INVITE_USER_FAIL'));
        }
    }
  }
}
