import {
    DELETE_PORTFOLIO_SUCCESS,
    DELETE_PORTFOLIO_FAIL,
    DELETE_PORTFOLIO_DATA_LOADING,
    RESET_PORTFOLIO_ITEM
} from '../actions/types';

const initialState = {
    isLoading: false,
    portfolioItemDeleted: ""
}

export default function deleteportfolioReducer(state = initialState, action) {
    switch (action.type) {
        case DELETE_PORTFOLIO_DATA_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case DELETE_PORTFOLIO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                portfolioItemDeleted: action.payload.data
            };
        case DELETE_PORTFOLIO_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        case RESET_PORTFOLIO_ITEM:
            return {
                ...state,
                portfolioItemDeleted: ""
            };
        default:
            return state;
    }
}