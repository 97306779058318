import React from 'react';
import {Form} from 'react-bootstrap';
import {Translate, I18n} from 'react-redux-i18n';
import Creatable from 'react-select/lib/Creatable';

const {Group, Label} = Form;

export default function CreatableInput({
  name,
  onChange,
  onBlur,
  loadOptions,
  controlId = '',
  label = '',
  desc = '',
  defaultValue = [],
  value = '',
  feedback = '',
  feedbackType = 'invalid',
  placeholder = '',
  required = true,
  isInvalid = false,
  isMulti = true,
  options = [],
  hint = null,
}) {
  placeholder = placeholder ? I18n.t(placeholder) : '';
  return (
    <Group controlId={controlId}>
      <Label>
        <Translate value={label} />
        {required ? (
          <small className="required"> ({I18n.t('required')})</small>
        ) : (
          <small className="optional"> ({I18n.t('optional')})</small>
        )}
      </Label>

      {desc ? (
        <small>
          <Translate value={desc} />
        </small>
      ) : null}

      {hint ? (
        <div className="alert alert-primary" role="alert">
          {I18n.t(hint)}
        </div>
      ) : null}

      <Creatable
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        loadOptions={loadOptions}
        options={options}
        className={`basic-multi-select ${isInvalid ? 'invalid' : ''}`}
        classNamePrefix="select"
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        isMulti={isMulti}
      />

      {isInvalid && feedback ? (
        <div className="d-block invalid-feedback">{feedback}</div>
      ) : null}
    </Group>
  );
}
