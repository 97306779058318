// Actions for Deleting Behavior

import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAIL,
} = TYPES;

// ADD/EDIT Project
export function deleteProject( { id } ) {
  return async function deleteProjectsWithDispatch(dispatch, getState){
    dispatch(clearErrors());
    dispatch({
      type: DELETE_PROJECT_LOADING
    });
    try {
        let response = await axiosInstance
        .delete(API.PROJECTS, {params: { id } , ...tokenConfig(getState)});
        if(response.status !== 200){
            dispatch({
              type: DELETE_PROJECT_FAIL,
            });
            throw Error({
                response: {
                    data: "Other Errors",
                    status: "Other Errors"
                },
            })
        }else{
          dispatch({
            type: DELETE_PROJECT_SUCCESS,
            status: response.data.data,
            id
          });
        }
    } catch (error) {
        if (error.response && error.response.data) {
          dispatch(returnErrors(error.response.data, error.response.status, 'DELETE_PROJECT_FAIL'));
          dispatch ({ 
              type: DELETE_PROJECT_FAIL 
          });
        }else{
          let errorJson = JSON.stringify(error);
          let errorParsed = JSON.parse(errorJson);
          dispatch(returnErrors(errorParsed.message, "Network Error", 'DELETE_PROJECT_FAIL'));
          dispatch ({ 
              type: DELETE_PROJECT_FAIL 
          })
        }
    }
  }
}

const exportedObject = {
  deleteProject,
};

export default exportedObject;
