import React, { useState, useEffect, Fragment } from "react";
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import { I18n } from "react-redux-i18n";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { getFollowings } from "../../redux/followers/actions/getActions";

const MyFollowings = () => {
  const dispatch = useDispatch();
  const {
    userLoading,
    isAuthenticated,

    getFollowingsLoading,
    followings,
    totalFollowings,
    followingsPerPage,

    errorId,
    errorMessage,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,

    getFollowingsLoading: state.followers.getFollowingsLoading,
    followings: state.followers.followings,
    totalFollowings: state.followers.totalFollowings,
    followingsPerPage: state.followers.followingsPerPage,

    errorId: state.error.id,
    errorMessage: state.error.msg,
  }));

  const [activePage, setActivePage] = useState(1);
  const [loadingFollowings, setLoadingFollowings] = useState(true);

  useState(() => {
    setLoadingFollowings(getFollowingsLoading);
  }, [getFollowingsLoading]);

  const totalItemsCount = totalFollowings;
  const itemsCountPerPage = Number(followingsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if(isAuthenticated){
      const page = activePage;
      const limit = 20;
      dispatch(getFollowings(page, limit, null));
    }
  }, [dispatch, isAuthenticated, activePage]);

  return (
    <Fragment>
        <TitleComponent title={I18n.t('lancersin_followings')} />
        <MetaComponent />
        <div className="row Freelancers__List">
            {
              loadingFollowings || userLoading ?
                    <Spin />
                : errorId === "GET_FOLLOWINGS_FAIL" && errorMessage === "Network Error" ?
                <div className="col-12">
                    <ConnectionLost
                        errorType= "connection"
                        title = "network_error_title"
                        message = "network_error_message"
                    />
                </div>
                : errorId === "GET_FOLLOWINGS_FAIL" ?
                <div className="col-12">
                    <ConnectionLost
                        errorType= "somethingWrong"
                        title = "something_error_title"
                        message = "something_error_message"
                    />
                </div>
                : followings.length > 0 &&  !loadingFollowings ?
                <Fragment>
                    {
                    followings.map((item) => (
                      <div className="col-md-6 col-lg-4 mb-4" key={item.id}>
                          <div className="Profile-Card box-shadow">
                              <div className="row">
                                  <div className="col-md-12">
                                      <div className="ProfileData">
                                          <div className="ProfileImage">
                                              <figure>
                                                  <img src={item.image.url ? item.image.url : item.image} alt={item.fname + " " + item.lname} className="img-fluid" />
                                              </figure>
                                          </div>
                                          <div className="ProfileContent">
                                              <h2 className="ProfileName">
                                                  <Link to={{pathname:`/in/${item.username}`}}>{item.fname + " " + item.lname}</Link>
                                                  {
                                                      item.price_per_hour ?
                                                      <Fragment>
                                                          <span className="HourRate">{" " + item.price_per_hour}</span>
                                                          <span>{I18n.t('dollar_per_hour')}</span>
                                                      </Fragment>
                                                      : null
                                                  }
                                              </h2>
                                              <h3 className="ProfileTitle">{item.position ? item.position : null}</h3>
                                              {
                                                  item.country && item.city ?
                                                  <div className="LocationTime">
                                                      <address>
                                                          <span className="LocationFlag">
                                                              <img src={item.country.flag} alt={item.country.name} />
                                                          </span>
                                                          <span className="Location">{item.city.name}-{item.country.name}</span>
                                                      </address>
                                                  </div>
                                                  : null
                                              }
                                              {/*<div className="LastSeen">Last seen: 23 minutes ago</div>*/}
                                          </div>
                                          <Link to={{pathname:`/in/${item.username}`}} className="btn btn-primary">{I18n.t('view_profile')}</Link>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> 
                    ))
                    }
                    <div className="col-12 my-4">
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                    </div>
                </Fragment>
                :
                <div className="col-12">
                    <NoItems
                    message="no_followings_message"
                    />
                </div>
            }
        </div>
    </Fragment>
  );
};

export default MyFollowings;
