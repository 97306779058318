import {
  GET_SERVICE_INVOICE_SUCCESS,
  GET_SERVICE_INVOICE_LOADING,
  GET_SERVICE_INVOICE_FAIL,
  POST_INVOICE_STATUS_LOADING,
  POST_INVOICE_STATUS_SUCCESS,
  POST_INVOICE_STATUS_FAIL,
  POST_INVOICE_RATING_LOADING,
  POST_INVOICE_RATING_SUCCESS,
  POST_INVOICE_RATING_FAIL
} from "../actions/types";

const initialState = {
  invoice: [],
  totalRecords: 0,
  itemsPerPage: 0,
  loadinginvoice: false,
  loadingInvoiceStatus: false,
  invoiceStatusSuccess: "",
  loadingInvoiceRating: false,
  invoiceRatingSuccess: "",
};

export default function serviceinvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.payload.data,
        totalRecords: action.payload.totalRecords,
        itemsPerPage: action.payload.itemsPerPage,
        loadinginvoice: false,
      };
    case GET_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        invoiceStatusSuccess: "",
        invoiceRatingSuccess: "",
        loadinginvoice: true,
      };
    case GET_SERVICE_INVOICE_FAIL:
      return{
        ...state,
        loadinginvoice: false
      };

    case POST_INVOICE_STATUS_LOADING:
      return {
        ...state,
        loadingInvoiceStatus: true,
      };
    case POST_INVOICE_STATUS_SUCCESS:
      return {
        ...state,
        invoiceStatusSuccess: action.invoiceStatusSuccess,
        loadingInvoiceStatus: false,
      };
    case POST_INVOICE_STATUS_FAIL:
      return {
        ...state,
        loadingInvoiceStatus: false,
      };

    case POST_INVOICE_RATING_LOADING:
      return {
        ...state,
        loadingInvoiceRating: true,
      };
    case POST_INVOICE_RATING_SUCCESS:
      return {
        ...state,
        invoiceRatingSuccess: action.invoiceRatingSuccess,
        loadingInvoiceRating: false,
      };
    case POST_INVOICE_RATING_FAIL:
      return {
        ...state,
        loadingInvoiceRating: false,
      };

    default:
      return state;
  }
}
