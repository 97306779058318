import React from 'react';
import {Form} from 'react-bootstrap';
// import Quill from "react-quill";
import {Translate, I18n} from 'react-redux-i18n';

const {Group, Label, Control} = Form;
const {Feedback} = Control;

const MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{list: 'ordered'}, {list: 'bullet'}],
    [{script: 'sub'}, {script: 'super'}],
    [{indent: '-1'}, {indent: '+1'}],
    [{align: []}],
    ['clean'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const FORMATS = [
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'code-block',
  'list',
  'ordered',
  'bullet',
  'indent',
  'script',
  'sub',
  'super',
  'align',
];

var Quill = null;
if (typeof document !== 'undefined') {
  Quill = require('react-quill');
}

export default function QuillBox({
  controlId = '',
  label = '',
  desc = '',
  onChange,
  onBlur,
  value = '',
  feedback = '',
  feedbackType = 'invalid',
  required = true,
  isInvalid = false,
  placeholder = '',
  minlength = '',
  characterCount = '',
}) {
  return (
    <Group controlId={controlId}>
      <Label>
        <Translate value={label} />
        {required ? (
          <small className="required"> ({I18n.t('required')})</small>
        ) : (
          <small className="optional"> ({I18n.t('optional')})</small>
        )}
      </Label>
      {desc ? (
        <div className="alert alert-primary" role="alert">
          <Translate value={desc} />
        </div>
      ) : null}
      <Quill
        modules={MODULES}
        formats={FORMATS}
        onChange={onChange}
        onBlur={onBlur}
        className={isInvalid ? 'invalid' : ''}
        value={value}
        placeholder={I18n.t(placeholder)}
      />

      {minlength ? (
        <small>
          {I18n.t('min_character')} {characterCount} / {minlength}
        </small>
      ) : null}

      {isInvalid && feedback ? (
        <Feedback className="d-block" type={feedbackType}>
          {feedback}
        </Feedback>
      ) : null}
    </Group>
  );
}
