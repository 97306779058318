import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Form } from "react-bootstrap";
import { I18n } from 'react-redux-i18n';
import { reportProjectBid } from "../../redux/project/actions/addActions";
import QuillBox from "../QuillBox";
import { CLEAR_REPORT_PROJECT_BID_SUCCESS } from "../../redux/project/actions/types";
import { clearErrors } from "../../actions/errorActions";
import ButtonWithLoading from "../Buttons/ButtonWithLoading";
import GRecaptch from "../GRecaptch";

export default function ReportProjectBidModal(props){
    const dispatch = useDispatch();

    const {
        errorsId,
        errorsMsg,
        reportProjectBidSuccess,
      } = useSelector((state) => ({
        errorsId: state.error.id,
        errorsMsg: state.error.msg,
        reportProjectBidSuccess: state.project.reportProjectBidSuccess,
    }));

    const [report, setReport] = useState("");
    const [errors, setErrors] = useState([]);
    const [localErrors, setLocalErrors] = useState({});
    const [isHuman, setIsHuman] = useState(false);

    const {
        project_bid_id,
        loading,
        setConfShow,
        setConfTitle,
        setConfMessage,
        setConfStatus,
        setDelay,
        onHide
    } = props;

    const handleHide = useCallback(() => {
        setLocalErrors({});
        onHide();
        dispatch(clearErrors());
    }, [dispatch, onHide]);

    const handleToast = useCallback((showToast, title, message, status, delay) => {
        setConfShow(showToast);
        setConfTitle(title);
        setConfMessage(message);
        setConfStatus(status);
        setDelay(delay);
    }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

    useEffect(() => {
        if (Object.keys(errorsMsg).length > 0){
          setErrors(errorsMsg.errors);
        };
    }, [errorsMsg]);

    useEffect(() => {
        if (reportProjectBidSuccess){
            handleHide();
            handleToast(true, I18n.t('report_problem'), I18n.t('report_problem_succes'), 'Toast__Container__Success', 6000);
            dispatch({
                type: CLEAR_REPORT_PROJECT_BID_SUCCESS
            });
            setReport("");
            setErrors([]);
            setLocalErrors({});
            setIsHuman(false);
        };
    }, [reportProjectBidSuccess, dispatch, handleHide, handleToast]);

    function handleItemLocalError({ propName, currentValue, message }) {
        if(currentValue){
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: undefined,
            }));
        }else{
            setLocalErrors((errors) => ({
                ...errors,
                [propName]: message,
            }));
        }
    }

    function handleReportChange(value) {
        value = value || "";
        if (value.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
            setReport("");
        } else {
            setReport(value);
        }
    }

    function onReportBlur(){
        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('problem_description_required'),
        });
    }

    function onSubmit(e) {
        e.preventDefault();

        handleItemLocalError({
            propName: "report",
            currentValue: report,
            message: I18n.t('problem_description_required'),
        });
        if(
            report !== "" &&
            isHuman
            ){
            const reportData = {
            report,
            project_bid_id,
            };
            dispatch(reportProjectBid(reportData));
        } else{
            handleToast(true, I18n.t('complete_required_info_title'), I18n.t('complete_required_info'), 'Toast__Container__Warning', 6000)
        }
    };

    return(
        <Modal
            className={props.className}
            size={props.size}
            show={props.show}
            onHide={props.onHide}
            backdrop={props.backdrop}
            keyboard={props.keyboard}
            aria-labelledby={props["aria-labelledby"]}
            >
            <Form>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {I18n.t('report_problem')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <QuillBox
                        required
                        controlId="Description"
                        label="explain_the_problem"
                        value={report}
                        onChange={handleReportChange}
                        onBlur={onReportBlur}
                        feedbackType="invalid"
                        feedback={localErrors.report ? localErrors.report : null}
                        isInvalid={(errorsId === "POST_REPORT_FAIL" && errors.report) || localErrors.report}
                    />

                    <GRecaptch
                        setIsHuman={setIsHuman}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <ButtonWithLoading
                        variant="primary"
                        type="submit"
                        onClick={onSubmit}
                        data-backdrop="static"
                        loading={loading ? loading : null}
                    >
                        {I18n.t('submit')}
                    </ButtonWithLoading>
                    <Button
                        variant="secondary"
                        className="btn btn-secondary"
                        onClick={handleHide}
                    >
                        {I18n.t('close')}
                    </Button>
                </Modal.Footer>
            </Form>
            </Modal>
    )
}