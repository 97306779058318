import { 
    VERIFY_ACCOUNT_LOADING, 
    VERIFY_ACCOUNT_SUCCESS, 
    VERIFY_ACCOUNT_FAIL, 
    SEND_VERIFICATION_LOADING, 
    SEND_VERIFICATION_SUCCESS, 
    SEND_VERIFICATION_FAIL,
    RESET_VERIFICATION
} from "../actions/types";

const initialState = {
    verifySuccess: "",
    verifyLoading: false,
    sendVerificationSuccess: "",
    sendVerificationLoading: false,
}

export default function verifyaccountReducer(state = initialState, action) {
    switch (action.type) {
        case VERIFY_ACCOUNT_LOADING: 
            return{
                ...state,
                verifyLoading: true
            }
        ;
        case VERIFY_ACCOUNT_SUCCESS:
            return{
                ...state,
                verifySuccess: action.payload.data,
                verifyLoading: false
            }    
        ;
        case VERIFY_ACCOUNT_FAIL:
            return{
                ...state,
                verifyLoading: false
            }
        ;
        case SEND_VERIFICATION_LOADING: 
            return{
                ...state,
                sendVerificationLoading: true
            }
        ;
        case SEND_VERIFICATION_SUCCESS:
            return{
                ...state,
                sendVerificationSuccess: action.payload.data,
                sendVerificationLoading: false
            }    
        ;
        case SEND_VERIFICATION_FAIL:
            return{
                ...state,
                sendVerificationLoading: false
            }
        ;
        case RESET_VERIFICATION:
            return{
                ...state,
                verifySuccess: "",
                sendVerificationSuccess: "",
            }
        ;
        default:
            return state;
    }
}