import TYPES, { 
  CLEAR_CANCEL_SERVICE_INVOICE_SUCCESS, 
  CLEAR_REPORT_SERVICE_INVOICE_SUCCESS, 
  CLEAR_HANDOVER_SERVICE_INVOICE_SUCCESS,
  CLEAR_INDORSE_SERVICE_INVOICE_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
    //get service feedback
    getServiceFeedbackLoading: false,
    getServiceFeedbackError: "",
    serviceFeedbacks: null,
    feedbackPerPage: 0,
    totalFeedbacks: 0,
    // Cancel Service Invoice
    cancelServiceInvoiceLoading: false,
    cancelServiceInvoiceError: "",
    cancelServiceInvoiceSuccess: false,
    // Report Service Invoice
    reportServiceInvoiceLoading: false,
    reportServiceInvoiceError: "",
    reportServiceInvoiceSuccess: false,
    //Handover Service Invoice
    handoverServiceInvoiceLoading: false,
    handoverServiceInvoiceError: "",
    handoverServiceInvoiceSuccess: false,
    //Indorse Service Invoice
    indorseServiceInvoiceLoading: false,
    indorseServiceInvoiceError: "",
    indorseServiceInvoiceSuccess: false,
};

const {
    // GET Service Feedbacks
    GET_SERVICE_FEEDBACK_LOADING,
    GET_SERVICE_FEEDBACK_SUCCESS,
    GET_SERVICE_FEEDBACK_FAIL,
    //Cancel Service Invoice
    CANCEL_SERVICE_INVOICE_LOADING,
    CANCEL_SERVICE_INVOICE_SUCCESS,
    CANCEL_SERVICE_INVOICE_FAIL,
    //Report Service Invoice
    REPORT_SERVICE_INVOICE_LOADING,
    REPORT_SERVICE_INVOICE_SUCCESS,
    REPORT_SERVICE_INVOICE_FAIL,
    //Handover Service Invoice
    HANDOVER_SERVICE_INVOICE_LOADING,
    HANDOVER_SERVICE_INVOICE_SUCCESS,
    HANDOVER_SERVICE_INVOICE_FAIL,
    //Indorse Service Invoice
    INDORSE_SERVICE_INVOICE_LOADING,
    INDORSE_SERVICE_INVOICE_SUCCESS,
    INDORSE_SERVICE_INVOICE_FAIL
} = TYPES;

export default function servicesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // GET Service Feedbacks
    case GET_SERVICE_FEEDBACK_LOADING:
      return{
        ...state,
        getServiceFeedbackLoading: true,
      };
    case GET_SERVICE_FEEDBACK_SUCCESS:
      return{
        ...state,
        getServiceFeedbackLoading: false,
        serviceFeedbacks: action.payload.data,
        feedbackPerPage: action.payload.itemsPerPage,
        totalFeedbacks: action.payload.totalRecords,
      };
    case GET_SERVICE_FEEDBACK_FAIL:
      return{
        ...state,
        getServiceFeedbackLoading: false,
        getServiceFeedbackError: action.error,
      };
    //Cancel Service Invoice
    case CANCEL_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        cancelServiceInvoiceLoading: true,
        cancelServiceInvoiceError: "",
      };
    case CANCEL_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        cancelServiceInvoiceLoading: false,
        cancelServiceInvoiceError: "",
        cancelServiceInvoiceSuccess: true,
      };
    case CANCEL_SERVICE_INVOICE_FAIL:
      return {
        ...state,
        cancelServiceInvoiceLoading: false,
        cancelServiceInvoiceError: action.error,
      };
    case CLEAR_CANCEL_SERVICE_INVOICE_SUCCESS:
      return{
        ...state,
        cancelServiceInvoiceSuccess: false,
      };
    //Report Service Invoice
    case REPORT_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        reportServiceInvoiceLoading: true,
        reportServiceInvoiceError: "",
      };
    case REPORT_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        reportServiceInvoiceLoading: false,
        reportServiceInvoiceError: "",
        reportServiceInvoiceSuccess: true,
      };
    case REPORT_SERVICE_INVOICE_FAIL:
      return {
        ...state,
        reportServiceInvoiceLoading: false,
        reportServiceInvoiceError: action.error,
      };
    case CLEAR_REPORT_SERVICE_INVOICE_SUCCESS:
      return{
        ...state,
        reportServiceInvoiceSuccess: false,
      };
    //Handover Service Invoice
    case HANDOVER_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        handoverServiceInvoiceLoading: true,
        handoverServiceInvoiceError: "",
      };
    case HANDOVER_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        handoverServiceInvoiceLoading: false,
        handoverServiceInvoiceError: "",
        handoverServiceInvoiceSuccess: true,
      };
    case HANDOVER_SERVICE_INVOICE_FAIL:
      return {
        ...state,
        handoverServiceInvoiceLoading: false,
        handoverServiceInvoiceError: action.error,
      };
    case CLEAR_HANDOVER_SERVICE_INVOICE_SUCCESS:
      return{
        ...state,
        handoverServiceInvoiceSuccess: false,
      };
    //Indorse Service Invoice
    case INDORSE_SERVICE_INVOICE_LOADING:
      return {
        ...state,
        indorseServiceInvoiceLoading: true,
        indorseServiceInvoiceError: "",
      };
    case INDORSE_SERVICE_INVOICE_SUCCESS:
      return {
        ...state,
        indorseServiceInvoiceLoading: false,
        indorseServiceInvoiceError: "",
        indorseServiceInvoiceSuccess: true,
      };
    case INDORSE_SERVICE_INVOICE_FAIL:
      return {
        ...state,
        indorseServiceInvoiceLoading: false,
        indorseServiceInvoiceError: action.error,
      };
    case CLEAR_INDORSE_SERVICE_INVOICE_SUCCESS:
      return{
        ...state,
        indorseServiceInvoiceSuccess: false,
      };
    default:
      return state;
  }
}
