import {axiosInstance} from '../axiosInstance';
import { FREELANCER_PROFILE_SUCCESS, FREELANCER_PROFILE_FAIL, FREELANCER_PROFILE_LOADING } from './types.js';
import { returnErrors, clearErrors } from '../actions/errorActions';

export const getFreelancer = ({ username, locale }) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: FREELANCER_PROFILE_LOADING
        });
        const body = {username};
        const config = {
            headers: {
                "Content-type": "application/json",
                "Accept-Language": locale ? locale : "en"
            }
        }
        try {
            let response = await axiosInstance.post(
                '/api/users', 
                body,
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: FREELANCER_PROFILE_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: FREELANCER_PROFILE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'FREELANCER_PROFILE_FAIL'));
                dispatch ({ 
                    type: FREELANCER_PROFILE_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'FREELANCER_PROFILE_FAIL')),
                    dispatch ({ 
                        type: FREELANCER_PROFILE_FAIL 
                    })
                )
            }
        }
    }
};