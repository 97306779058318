import React from 'react';

export default class DatePickerInput extends React.PureComponent {
  state = {readOnly: false};

  render() {
    return (
      <input
        {...this.props}
        readOnly={true}
      />
    );
  };
}