import { 
    ADD_SERVICE_LOADING, 
    ADD_SERVICE_SUCCESS, 
    ADD_SERVICE_FAIL,
    EDIT_SERVICE_LOADING,
    EDIT_SERVICE_SUCCESS,
    EDIT_SERVICE_FAIL,
} from '../actions/types';
import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';

// Add Service Item

export const addserviceitem = ({ title, price, description, requirments, deadline, publish, cat_id, sub_cat_id, images_sort, keywords, image, images, videos, features, id }) => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        if(id){
            dispatch({
                type: EDIT_SERVICE_LOADING
            });
        }else{
            dispatch({
                type: ADD_SERVICE_LOADING
            });
        }
        const body = { title, price, description, requirments, deadline, publish, cat_id, sub_cat_id, images_sort, keywords, image, images, videos, features, id };
        try {
            let response = await axiosInstance.post(
                '/api/services', 
                body, 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                if(id){
                    dispatch({
                        type: EDIT_SERVICE_FAIL,
                    });
                }else{
                    dispatch({
                        type: ADD_SERVICE_FAIL,
                    });
                }
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                if(id){
                    dispatch({
                        type: EDIT_SERVICE_SUCCESS,
                        payload: response.data.data,
                        status: response.data.response,
                    });
                }else{
                    dispatch({
                        type: ADD_SERVICE_SUCCESS,
                        payload: response.data.data,
                        status: response.data.response,
                    });
                }
            }
        } catch (error) {
            if (error.response && error.response.data) {
                if(id){
                    dispatch ({ 
                        type: EDIT_SERVICE_FAIL 
                    });
                    dispatch(returnErrors(error.response.data, error.response.status, 'EDIT_SERVICE_FAIL'));
                }else{
                    dispatch ({ 
                        type: EDIT_SERVICE_FAIL 
                    });
                    dispatch(returnErrors(error.response.data, error.response.status, 'ADD_SERVICE_FAIL'));
                }
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                if(id){
                    return (
                        dispatch(returnErrors(errorParsed.message, "Network Error", 'EDIT_SERVICE_FAIL')),
                        dispatch ({ 
                            type: EDIT_SERVICE_FAIL 
                        })
                    )
                }else{
                    return (
                        dispatch(returnErrors(errorParsed.message, "Network Error", 'ADD_SERVICE_FAIL')),
                        dispatch ({ 
                            type: ADD_SERVICE_FAIL 
                        })
                    )
                }
            }
        }
    }
};


// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}