import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import Filteration from '../Filteration';
import Spin from '../Spin';
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { addserviceitem } from "../../actions/addserviceActions";
import { clearErrors } from "../../actions/errorActions";
import ConfToast from "../ConfToast";
import AddingServiceModal from "../AddingServiceModal";
import ServiceCard from "../ServiceCardUser";
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";

export default function ServicesList() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const path = typeof window !== 'undefined' ? window.location.pathname : '/services';

    const { 
        loadingallservices, 
        allservices, 
        itemsPerPage, 
        totalRecords,
        errorId,
        errorMessage,
        isLoading,
        user,
        isAuthenticated,
    } = useSelector((state) => ({
        loadingallservices: state.services.loadingallservices,
        allservices: state.services.allservices,
        itemsPerPage: state.services.itemsPerPage,
        totalRecords: state.services.totalRecords,
        errorId: state.error.id,
        errorMessage: state.error.msg,
        isLoading: state.addserviceitem.isLoading,
        user: state.userData.user,
        isAuthenticated: state.auth.isAuthenticated,
    }));

    const [activePage, setActivePage] = useState(1);
    const [open, setOpen] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [confShow, setConfShow] = useState(false);
    const [confTitle, setConfTitle] = useState("");
    const [confMessage, setConfMessage] = useState("");
    const [confStatus, setConfStatus] = useState(null);
    const [delay, setDelay] = useState(null);
    const [loadingServices, setLoadingServices] = useState(true);
    const [isClient, setIsClient] = useState(false);
    const [loadPageNumber, setLoadPageNumber] = useState(true);

    useEffect(() => {
      setLoadingServices(loadingallservices);
    }, [loadingallservices]);

    useEffect(() => {
        if(user && user.memberships && user.memberships.length >= 1){
            user.memberships.map(item => {
                if((item.id === 3 || item.id === 4) && item.default){
                    return setIsClient(true);
                }else{
                    return setIsClient(false);
                }
            })
        }
    }, [user]);
    

    function handleShow(){
        setLgShow(true);
    };

    function handleHide(){
        setLgShow(false);
        dispatch(clearErrors());
    };

    useEffect(() => {
        let url_string = "";
        let url = "";
        let page = "";
        if(loadPageNumber){
            if(typeof window != 'undefined'){
                if(window.location.href.indexOf("page=") > -1){
                    url_string = window.location.href; 
                    url = new URL(url_string);
                    if(url.searchParams){
                        page = url.searchParams.get("page");
                        setActivePage(parseInt(page));
                        setLoadPageNumber(false);
                    }
                }else{
                    setActivePage(1);
                    history.push(`${path}?page=${1}`);
                    setLoadPageNumber(false);
                }
            }
        }else{
            if(typeof window != 'undefined'){
                if(window.location.href.indexOf("page=") > -1){
                    url_string = window.location.href; 
                    url = new URL(url_string);
                    if(url.searchParams){
                        page = url.searchParams.get("page");
                        setActivePage(parseInt(page));
                        setLoadPageNumber(false);
                    }
                }
            }
        }
    }, [loadPageNumber, location, history, path]);

    function handlePageChange (pageNumber) {
        setActivePage(pageNumber);
        if(typeof window != 'undefined'){
            var url_string = window.location.href; 
            var url = new URL(url_string);
            if(url_string.indexOf("page=") > -1){
                var search_params = url.searchParams;
                if(search_params){
                  search_params.set('page', pageNumber);
                  url.search = search_params.toString();
                  var new_url = url.pathname + url.search;
                  history.push(new_url);
                }
            }
        }
    }

    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);

    return(
        <Fragment>
            <ConfToast
                showConf={confShow}
                setShowConf={setConfShow}
                title={confTitle}
                message={confMessage}
                status={confStatus}
                delay={delay}
            />
            <TitleComponent title={I18n.t('lancersin_services')} />
            <MetaComponent />
            <AddingServiceModal
                modalTitle={"add_service"}
                className="CustomPopup"
                size="lg"
                show={lgShow}
                onHide={handleHide}
                backdrop="static"
                keyboard={false}
                aria-labelledby="example-modal-sizes-title-lg"
                addserviceitem={addserviceitem}
                isLoading={isLoading}
                setConfShow={setConfShow}
                setConfTitle={setConfTitle}
                setConfMessage={setConfMessage}
                setConfStatus={setConfStatus}
                setDelay={setDelay}
            />
            <Fragment>
                <div className="breadcrumb__container">
                    <div className="container">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{I18n.t('services')}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="container Projects__List__Container">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="section__title my-0">
                                <div className="section__title__text">
                                    <span className="icon icon-services"></span>
                                    <h1>{I18n.t('services')}</h1>
                                </div>
                                <div>
                                    {
                                        isAuthenticated ?
                                            isClient ?
                                                null
                                            :
                                            <button className="btn mx-3" onClick={() => {handleShow()}}>
                                                <span className="icon icon-plus"></span>
                                                <h2>{I18n.t('add_service')}</h2>
                                            </button>
                                        : null
                                    }
                                    {
                                        open === false ?
                                        <button className="btn" onClick={() => setOpen(!open)}>
                                            <span className="icon icon-search"></span>
                                            <h3>{I18n.t('advanced_search')}</h3>
                                        </button>
                                        :
                                        <button className="btn" onClick={() => setOpen(!open)}>
                                            <span className="fas fa-times"></span>
                                            <h3>{I18n.t('close_search')}</h3>
                                        </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> 
                    {
                        loadPageNumber ?
                        <Spin />
                        :
                        <div className="row">
                            <div className="col-12">
                                <Filteration 
                                    page={activePage} 
                                    limit={30}
                                    type="services" 
                                    open={open} 
                                    setOpen={setOpen} 
                                />
                            </div>
                            <div className="col-12">
                                <div className="Offers__List">
                                    <div className="row">
                                        {
                                            loadingServices ?
                                            <Spin />
                                            : errorId === "GET_ALL_SERVICES_FAIL" && errorMessage === "Network Error" ?
                                            <div className="col-12">
                                                <ConnectionLost
                                                errorType= "connection"
                                                title = "network_error_title"
                                                message = "network_error_message"
                                                />
                                            </div>
                                            : errorId === "GET_ALL_SERVICES_FAIL" ?
                                            <div className="col-12">
                                                <ConnectionLost
                                                errorType= "somethingWrong"
                                                title = "something_error_title"
                                                message = "something_error_message"
                                                />
                                            </div>
                                            : allservices.length > 0 && !loadingServices ?
                                            <Fragment>
                                                { 
                                                allservices.map( item => (
                                                    <ServiceCard key={item.id} item={item} />
                                                ))
                                                }
                                                <div className="col-12 mt-3">
                                                    <Pagination
                                                        activePage={activePage}
                                                        itemsCountPerPage={itemsCountPerPage}
                                                        totalItemsCount={totalItemsCount}
                                                        pageRangeDisplayed={10}
                                                        onChange={handlePageChange}
                                                        itemClass="page-item"
                                                        linkClass="page-link"
                                                    />
                                                </div>
                                            </Fragment>
                                            :
                                            <div className="col-12">
                                                <NoItems
                                                    title="no_services_found"
                                                    message="no_services_message_with_action"
                                                    buttonText="add_service"
                                                    iconClassName="icon icon-plus"
                                                    onClick={() => {
                                                        handleShow();
                                                    }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        </Fragment>
    )
}

