import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { I18n } from "react-redux-i18n";
import Spin from "../Spin";
import { addserviceitem } from "../../actions/addserviceActions";
import { getuserServices } from '../../actions/getservicesActions';
import { clearErrors } from "../../actions/errorActions";
import { TitleComponent } from "../../components/TitleComponent";
import { MetaComponent } from "../../components/MetaComponent";
import RunningServices from "./RunningServices";
import ClosedServices from "./ClosedServices";
import AwaitingServices from "./AwaitingServices";
import CancelledServices from "./CancelledServices";
import AddingRow from "../../components/AddingRow";
import AddingServiceModal from "../AddingServiceModal";
import ServiceCard from "../ServiceCard";
import NoItems from '../NoItems';
import ConfToast from "../ConfToast";
import ConnectionLost from "../ConnectionLost";
import { RESET_DELETE_SERVICE } from "../../actions/types";

export default function MyServices() {
  const dispatch = useDispatch();

  const {
    userLoading,
    isAuthenticated,
    user,
    serviceItem,
    userservices,
    totalRecords,
    itemsPerPage,
    deleteServiceLoading,
    serviceItemDeleted,
    loadingUserServices,
    isLoading,
    errorId,
    errorMessage,
    userInfo,
    getUserDataLoading,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    serviceItem: state.addserviceitem.serviceItem,
    userservices: state.services.userservices.services,
    totalRecords: state.services.userservices.totalRecords,
    itemsPerPage: state.services.userservices.itemsPerPage,
    deleteServiceLoading: state.deleteserviceItem.isLoading,
    serviceItemDeleted: state.deleteserviceItem.serviceItemDeleted,
    loadingUserServices: state.services.userservices.loadingUserServices,
    isLoading: state.addserviceitem.isLoading,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    userInfo: state.userData.user,
    getUserDataLoading: state.userData.getUserDataLoading,
  }));

  const [lgShow, setLgShow] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loadingServices, setLoadingServices] = useState(true);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setLoadingServices(loadingUserServices);
  }, [loadingUserServices]);

  useEffect(() => {
    if(userInfo && userInfo.memberships && userInfo.memberships.length > 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    } else if(userInfo && userInfo.memberships && userInfo.memberships.length <= 1){
        userInfo.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    }
  }, [userInfo]);

  const handleToast = useCallback((showToast, title, message, status, delay) =>{
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  useEffect(() => {
    if (serviceItemDeleted && !deleteServiceLoading) {
      dispatch({
          type: RESET_DELETE_SERVICE
      });
      setTimeout(() => {
        handleToast(true, I18n.t('delete_service'), I18n.t('delete_service_success'), 'Toast__Container__Success', 7000);
      }, 1500);
    }
  }, [serviceItemDeleted, deleteServiceLoading, dispatch, handleToast]);

  function handleShow(){
    setLgShow(true);
  };

  function handleHide(){
    setLgShow(false);
    dispatch(clearErrors());
  };

  const getUserServices = useCallback(() => {
    let user_id = user.id;
    if (user_id) {
      const page = activePage;
      const limit = 12;
      const publish = null;
      dispatch(getuserServices(user_id, null, null, null, publish, null, page, limit, null, null, null, null, null));
    }
  }, [dispatch, user.id, activePage]);

  useEffect(() => {
    if(user || isAuthenticated){
      getUserServices();
    }
  }, [user, isAuthenticated, getUserServices]);

  function handlePageChange(pageNumber){
      setActivePage(pageNumber);
  }

    const totalItemsCount = totalRecords;
    const itemsCountPerPage = Number(itemsPerPage);
    if (serviceItem === "success") {
      window.location.reload(setTimeout(3000));
    }
    return (
      <Fragment>
        <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
        />
        {
          userLoading || getUserDataLoading ?
          <Spin />
          :
          <div className="container">
            <div className="row">
              <div className="col-12">
                  <div className="alert alert-warning mb-0 mt-4 d-flex justify-content-between align-items-center flex-wrap" role="alert">
                      <p className="my-1">{I18n.t('watch_service_video') + " "}</p>
                      <a href='https://youtu.be/YEFeOtnQdZ0' target="_blank" rel="noopener noreferrer" className="videoBTn btn-success btn-sm my-1"><i className="fas fa-play"></i> {I18n.t('watch_video')}</a>
                  </div>
              </div>
              <div className="col-12 Dashboard__tasks__List">
                <AddingRow
                  onAdding={() => {
                    handleShow();
                  }}
                  isClient={isClient}
                  type="services"
                  title={"services"}
                  titleIconClassName={"icon-services"}
                  btnText={"add_service"}
                  btnIconClassName={"icon-plus"}
                />
                <AddingServiceModal
                  modalTitle={"add_service"}
                  className="CustomPopup"
                  size="lg"
                  show={lgShow}
                  onHide={handleHide}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="example-modal-sizes-title-lg"
                  addserviceitem={addserviceitem}
                  isLoading={isLoading}
                  setConfShow={setConfShow}
                  setConfTitle={setConfTitle}
                  setConfMessage={setConfMessage}
                  setConfStatus={setConfStatus}
                  setDelay={setDelay}
                />
                <Tabs id="JoinedStats" unmountOnExit={true}>
                  {
                    isClient ?
                    null
                    :
                    <Tab eventKey="MyServices" title={I18n.t("my_services")}>
                      <TitleComponent title={I18n.t("lancersin_services_myservices")} />
                      <MetaComponent />
                      <div className="row">
                        {
                          loadingServices || userLoading ? 
                            <Spin />
                          : errorId === "GET_USER_SERVICES_FAIL" && errorMessage === "Network Error" ?
                          <div className="col-12">
                              <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                              />
                          </div>
                          : errorId === "GET_USER_SERVICES_FAIL" ?
                          <div className="col-12">
                              <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                              />
                          </div>
                          : userservices.length > 0 && !loadingServices ? 
                          <Fragment>
                            {
                              userservices.map((item) =>
                                item.publish === 1 && item.status.id === 1 ? (
                                  <ServiceCard key={item.id} item={item} />
                                ) : (
                                  <ServiceCard hide key={item.id} item={item} />
                                )
                              )
                            }
                            <div className="col-12 my-4">
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    pageRangeDisplayed={10}
                                    onChange={handlePageChange}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                          </Fragment>
                        :
                          <div className="col-12">
                            <NoItems
                              title="no_services_found"
                              message="no_services_message_with_action"
                              buttonText="add_service"
                              iconClassName="icon icon-plus"
                              onClick={() => {
                                  handleShow();
                              }}
                            />
                          </div>
                        }
                      </div>
                    </Tab>
                  }
                  <Tab
                    eventKey="RunningServices"
                    title={I18n.t("in_progress")}
                  >
                    <RunningServices />
                  </Tab>
                  <Tab eventKey="ClosedServices" title={I18n.t("closed_services")}>
                    <ClosedServices />
                  </Tab>
                  <Tab eventKey="CancelledServices" title={I18n.t("cancelled_services")}>
                    <CancelledServices />
                  </Tab>
                  {
                    isClient ?
                    <Tab eventKey="AwaitingServices" title={I18n.t("awaiting_payment")}>
                      <AwaitingServices />
                    </Tab>
                    :
                    null
                  }
                </Tabs>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
}
