import TYPES from "../actions/types";

const INITIAL_STATE = {
  // Articles
  getArticlesLoading: false,
  getArticlesError: "",
  getArticlesSuccess: false,
  articles: [],
  totalArticles: 0,
  articlesPerPage: 0,

  // User Articles
  getUserArticlesLoading: false,
  getUserArticlesError: "",
  userArticles: [],
  addedArticle: [],
  totalRecords: 0,
  itemsPerPage: 0,

  // Add Article
  addingArticleLoading: false,
  addingArticleError: "",
  addingArticleSuccess: false,

  //   Delete Article
  deletingArticleLoading: false,
  deletingArticleError: "",
  deletingArticleSuccess: false,

  //   Edit Article
  editingArticleLoading: false,
  editingArticleError: "",
  editingArticleSuccess: false,

  // Get Article
  getArticleByIdLoading: false,
  getArticleByIdError: "",
  currentArticle: null,
};

const {
  GET_ARTICLES_LOADING,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,

  GET_ARTICLE_BY_ID_LOADING,
  GET_ARTICLE_BY_ID_SUCCESS,
  GET_ARTICLE_BY_ID_FAIL,

  GET_USER_ARTICLES_LOADING,
  GET_USER_ARTICLES_SUCCESS,
  GET_USER_ARTICLES_FAIL,

  DELETE_ARTICLE_LOADING,
  DELETE_ARTICLE_SUCCESS,
  DELETE_ARTICLE_FAIL,

  EDIT_ARTICLE_LOADING,
  EDIT_ARTICLE_SUCCESS,
  EDIT_ARTICLE_FAIL,

  ADD_ARTICLE_LOADING,
  ADD_ARTICLE_SUCCESS,
  ADD_ARTICLE_FAIL,

  RESET_ARTICLES_ACTIONS,
} = TYPES;

export default function articlesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    // TODO:Get Articles
    case GET_ARTICLES_LOADING:
      return {
        ...state,
        getArticlesLoading: true,
        getArticlesError: "",
        deletingArticleSuccess: false,
      };
    case GET_ARTICLES_SUCCESS:
      return {
        ...state,
        getArticlesLoading: false,
        getArticlesError: "",
        articles: action.payload.data,
        totalArticles: action.payload.totalRecords,
        articlesPerPage: action.payload.itemsPerPage,
      };
    case GET_ARTICLES_FAIL:
      return {
        ...state,
        getArticlesLoading: false,
        getUserArticlesLoading: false,
        getArticleError: action.error,
      };

    // Get User Articles
    case GET_USER_ARTICLES_LOADING:
      return {
        ...state,
        getUserArticlesLoading: true,
        getUserArticlesError: "",
        deletingArticleSuccess: false,
      };
    case GET_USER_ARTICLES_SUCCESS:
      return {
        ...state,
        getUserArticlesLoading: false,
        getUserArticlesError: "",
        userArticles: action.payload.data,
        totalRecords: action.payload.totalRecords,
        itemsPerPage: action.payload.itemsPerPage,
      };
    case GET_USER_ARTICLES_FAIL:
      return {
        ...state,
        getUserArticlesLoading: false,
        getUserArticlesError: action.error,
      };

    // Get User Article By ID
    case GET_ARTICLE_BY_ID_LOADING:
      return {
        ...state,
        getArticleByIdLoading: true,
        getArticleByIdError: "",
      };
    case GET_ARTICLE_BY_ID_SUCCESS:
      return {
        ...state,
        getArticleByIdLoading: false,
        editingArticleSuccess: false,
        getArticleByIdError: "",
        currentArticle: action.payload[0],
        addingArticleSuccess: false
      };
    case GET_ARTICLE_BY_ID_FAIL:
      return {
        ...state,
        getArticleByIdLoading: false,
        getArticleByIdError: action.error,
      };

    // Add Article
    case ADD_ARTICLE_LOADING:
      return {
        ...state,
        addingArticleLoading: true,
        addingArticleError: "",
      };
    case ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        addingArticleLoading: false,
        addingArticleError: "",
        addedArticle: [action.payload.data],
        addingArticleSuccess: true,
      };
    case ADD_ARTICLE_FAIL:
      return {
        ...state,
        addingArticleLoading: false,
        addingArticleError: action.error,
      };

    // Edit Article
    case EDIT_ARTICLE_LOADING:
      return {
        ...state,
        editingArticleLoading: true,
        editingArticleError: "",
      };
    case EDIT_ARTICLE_SUCCESS:
      return {
        ...state,
        editingArticleLoading: false,
        editingArticleError: "",
        addedArticle: [action.payload.data],
        editingArticleSuccess: true,
      };
    case EDIT_ARTICLE_FAIL:
      return {
        ...state,
        editingArticleLoading: false,
        editingArticleError: action.error,
      };

    // Delete Article
    case DELETE_ARTICLE_LOADING:
      return {
        ...state,
        deletingArticleLoading: true,
        deletingArticleError: "",
      };
    case DELETE_ARTICLE_SUCCESS:
      let newArticles = state.articles.filter(
        (article) => article.id !== action.id
      );
      return {
        ...state,
        deletingArticleLoading: false,
        deletingArticleSuccess: true,
        deletingArticleError: "",
        articles: newArticles,
      };
    case DELETE_ARTICLE_FAIL:
      return {
        ...state,
        deletingArticleLoading: false,
        deletingArticleError: action.error,
      }
    case RESET_ARTICLES_ACTIONS:
      return {
        ...state,
        ...INITIAL_STATE,
        articles: state.articles,
      };

    default:
      return state;
  }
}
