import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import Filteration from '../Filteration';
import ProjectCard from "../ProjectCard";
import { I18n } from "react-redux-i18n";
import { TitleComponent } from "../TitleComponent";
import { MetaComponent } from "../MetaComponent";
import AddingProjectModal from "../AddingProjectModal";
import Spin from "../Spin";
import ConfToast from "../ConfToast";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";

export default function ProjectList() {
  const history = useHistory();
  const location = useLocation();
  const path = typeof window !== 'undefined' ? window.location.pathname : '/projects';
  var { 
    getProjectsLoading, 
    projects, 
    itemsPerPage, 
    totalRecords, 
    addingProjectLoading, 
    errorId,
    errorMessage,
    user,
  } = useSelector((state) => ({
    getProjectsLoading: state.project.getProjectsLoading,
    projects: state.project.projects,
    itemsPerPage: state.project.projectsPerPage,
    totalRecords: state.project.totalProjects,
    addingProjectLoading: state.project.addingProjectLoading,
    errorId: state.error.id,
    errorMessage: state.error.msg,
    user: state.userData.user,
  }));
  const [activePage, setActivePage] = useState(1);
  const [open, setOpen] = useState(false);
  const [toggleAddProjectModel, setToggleAddProjectModel] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [isClient, setIsClient] = useState(false);
  const [loadPageNumber, setLoadPageNumber] = useState(true);

  useEffect(() => {
    if(user && user.memberships && user.memberships.length >= 1){
        user.memberships.map(item => {
            if((item.id === 3 || item.id === 4) && item.default){
                return setIsClient(true);
            }else{
                return setIsClient(false);
            }
        })
    }
  }, [user]);

  useEffect(() => {
    let url_string = "";
    let url = "";
    let page = "";
    if(loadPageNumber){
        if(typeof window != 'undefined'){
            if(window.location.href.indexOf("page=") > -1){
                url_string = window.location.href; 
                url = new URL(url_string);
                if(url.searchParams){
                    page = url.searchParams.get("page");
                    setActivePage(parseInt(page));
                    setLoadPageNumber(false);
                }
            }else{
                setActivePage(1);
                history.push(`${path}?page=${1}`);
                setLoadPageNumber(false);
            }
        }
    }else{
        if(typeof window != 'undefined'){
            if(window.location.href.indexOf("page=") > -1){
                url_string = window.location.href; 
                url = new URL(url_string);
                if(url.searchParams){
                    page = url.searchParams.get("page");
                    setActivePage(parseInt(page));
                    setLoadPageNumber(false);
                }
            }
        }
    }
  }, [loadPageNumber, location, history, path]);

  useEffect(() => {
    setProjectsLoading(getProjectsLoading);
  }, [getProjectsLoading]);

  function handleHide() {
    setToggleAddProjectModel(!toggleAddProjectModel);
  }
  function handlePageChange (pageNumber) {
      setActivePage(pageNumber);
      if(typeof window != 'undefined'){
        var url_string = window.location.href; 
        var url = new URL(url_string);
        if(url_string.indexOf("page=") > -1){
            var search_params = url.searchParams;
            if(search_params){
              search_params.set('page', pageNumber);
              url.search = search_params.toString();
              var new_url = url.pathname + url.search;
              history.push(new_url);
            }
        }
      }
  }
  const totalItemsCount = totalRecords;
  const itemsCountPerPage = Number(itemsPerPage);

  return (
    <Fragment>
      <AddingProjectModal
        modalTitle={"add_project"}
        className="CustomPopup"
        size="lg"
        show={toggleAddProjectModel}
        onHide={handleHide}
        backdrop="static"
        keyboard={false}
        aria-labelledby="example-modal-sizes-title-lg"
        loading={addingProjectLoading}
        setConfShow={setConfShow}
        setConfTitle={setConfTitle}
        setConfMessage={setConfMessage}
        setConfStatus={setConfStatus}
        setDelay={setDelay}
      />
      <ConfToast
          showConf={confShow}
          setShowConf={setConfShow}
          title={confTitle}
          message={confMessage}
          status={confStatus}
          delay={delay}
      />
      <TitleComponent title={I18n.t('lancersin_projects')} />
      <MetaComponent />
      <div className="breadcrumb__container">
        <div className="container">
          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><Link to="/">{I18n.t('home')}</Link></li>
                  <li className="breadcrumb-item active" aria-current="page">{I18n.t('browse_projects')}</li>
              </ol>
          </nav>
        </div>
      </div>
      <div className="container Projects__List__Container">
        <div className="row">
            <div className="col-12 mb-4">
                <div className="section__title my-0">
                    <div className="section__title__text">
                        <span className="icon icon-projects"></span>
                        <h1>{I18n.t('projects')}</h1>
                    </div>
                    <div>
                      {
                        isClient ?
                        <button className="btn mx-3" onClick={() => {
                          setToggleAddProjectModel(!toggleAddProjectModel);
                        }}>
                            <span className="icon icon-plus"></span>
                            <h2>{I18n.t('add_project')}</h2>
                        </button>
                        : null
                      }
                      {
                        open === false ?
                        <button className="btn" onClick={() => setOpen(!open)}>
                            <span className="icon icon-search"></span>
                            <h3>{I18n.t('advanced_search')}</h3>
                        </button>
                        :
                        <button className="btn" onClick={() => setOpen(!open)}>
                            <span className="fas fa-times"></span>
                            <h3>{I18n.t('close_search')}</h3>
                        </button>
                      }
                    </div>
                </div>
            </div>
        </div>  
        {
          loadPageNumber ?
          <Spin />
          :
          <div className="row">
              <div className="col-12">
                  <Filteration 
                    page={activePage} 
                    limit={20}
                    type="projects" 
                    open={open} 
                    setOpen={setOpen} 
                  />
              </div>
              {
                projectsLoading ?
                  <Spin />
                : errorId === "GET_PROJECTS_FAIL" && errorMessage === "Network Error" ?
                <div className="col-12">
                    <ConnectionLost
                      errorType= "connection"
                      title = "network_error_title"
                      message = "network_error_message"
                    />
                </div>
                : errorId === "GET_PROJECTS_FAIL" ?
                <div className="col-12">
                    <ConnectionLost
                      errorType= "somethingWrong"
                      title = "something_error_title"
                      message = "something_error_message"
                    />
                </div>
                : projects.length > 0 && !projectsLoading ?
                <Fragment>
                    {
                      projects.map((project) => (
                          <ProjectCard key={`project-#${project.id}`} item={project} />
                        ))
                    }
                    <div className="col-12 mt-3">
                      <Pagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                </Fragment>
                : 
                <div className="col-12">
                  <NoItems
                    title="no_projects_found"
                    message="no_projects_message_with_action"
                    buttonText="add_project"
                    iconClassName="icon icon-plus"
                    onClick={() => {
                      setToggleAddProjectModel(true);
                    }}
                  />
                </div>
              }
          </div>
        }
      </div>
    </Fragment>
  );
}