import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav, Tab } from 'react-bootstrap';
import Overview from './Overview';
import Portfolio from './Portfolio';
import Projects from './Projects';
import Services from './Services';
import Feedback from './Feedback';
import ProfileStats from './ProfileStats';
import { I18n } from 'react-redux-i18n';
import { getfreelancerPortfolio } from '../../actions/freelancerportfolioActions';
import { getFeedbacks } from "../../redux/feedbacks/actions/getActions";
import { getallServices } from '../../actions/getservicesActions';
import { getProjects } from '../../redux/project/actions/getActions';
import { CLEAR_PROJECTS } from '../../redux/project/actions/types';
import { CLEAR_ALL_SERVICES, CLEAR_PORTFOLIO_ITEMS } from '../../actions/types';
import { CLEAR_FEEDBACKS } from '../../redux/feedbacks/actions/types';

const ProfileContent = (props) => {
    const dispatch = useDispatch();
    const freelancer = props.freelancerdata;

    const {
        isAuthenticated,
        locale,

        freelancerportfolio,

        allservices,

        feedbacks,

        projects = [],
    } = useSelector((state) => ({
        isAuthenticated: state.auth.isAuthenticated,
        locale: state.i18n.locale,

        loadingportfolio: state.freelancerportfolio.loadingportfolio,
        freelancerportfolio: state.freelancerportfolio.portfolios,

        allservices: state.services.allservices,

        feedbacks: state.feedbacks.feedbacks,

        projects: state.project.projects,
    }));

    const [userId, setUserId] = useState(null);
    const [portfolioActivePage, setPortfolioActivePage] = useState(1);
    const [feeddbacksActivePage, setFeedbacksActivePage] = useState(1);
    const [servicesActivePage, setServicesActivePage] = useState(1);
    const [projectsActivePage, setProjectsActivePage] = useState(1);

    useEffect(() => {
        dispatch({
            type: CLEAR_PROJECTS
        });
        dispatch({
            type: CLEAR_ALL_SERVICES
        });
        dispatch({
            type: CLEAR_PORTFOLIO_ITEMS
        });
        dispatch({
            type: CLEAR_FEEDBACKS
        });
    }, [dispatch]);


    const getUserPortfolio = useCallback(() => {
        const publish = 1;
        const limit = 18;
        const user_id = userId;
        const page = portfolioActivePage;
        if(user_id && publish && page && limit){
            dispatch(getfreelancerPortfolio(user_id, publish, null, page, limit));
        }
    }, [dispatch, userId, portfolioActivePage]);    

    const getUserServices = useCallback(() => {
        const user_id = userId;
        const publish = 1;
        const status_id = 1;
        const limit = 18;
        const page = servicesActivePage;
        if(user_id && publish && page && limit){
            dispatch(getallServices(user_id, null, null, status_id, publish, null, page, limit, null, null, null, null, null));
        }
    }, [dispatch, servicesActivePage, userId]);

    const getUserProjects = useCallback(() => {
        const user_id = userId;
        const page = projectsActivePage;
        const limit = 15;
        dispatch(getProjects(user_id, null, page, limit, null, null, null, null, null, null, null, null, null, locale));
    }, [userId, dispatch, projectsActivePage, locale]);

    useEffect(() => {
        if(Object.keys(freelancer).length > 0){
            setUserId(freelancer.id);
        }
        if(userId){
            getUserPortfolio();
            getUserServices();
        }
        if(userId && isAuthenticated){
            getUserProjects();
        }
    }, [userId, freelancer, getUserPortfolio, getUserServices, getUserProjects, isAuthenticated]);

    useEffect(() => {
        if(userId){
            const limit = 20;
            dispatch (getFeedbacks(userId, null, null, feeddbacksActivePage, limit));
        }
    }, [userId, feeddbacksActivePage, dispatch, isAuthenticated]);

    return(
        <div className="container InnerPageContainer">
            <div className="row">
                <Tab.Container id="left-tabs-example" 
                    defaultActiveKey={
                        freelancer.about || (freelancer.skills && freelancer.skills.length > 0) ?
                        "Overview"
                        : freelancerportfolio && freelancerportfolio.length > 0 ?
                        "Portfolio"
                        : projects && projects.length > 0 ?
                        "Projects"
                        : allservices && allservices.length > 0 ?
                        "Services"
                        : feedbacks && feedbacks.length > 0 ?
                        "Feedback"
                        : ""
                    }
                >
                    <div className="col-md-5 col-lg-4 mb-4">
                        <Nav variant="pills" className="flex-column ProfileNav">
                            {
                                freelancer.about || (freelancer.skills && freelancer.skills.length > 0) ?
                                <Nav.Item>
                                    <Nav.Link eventKey="Overview">
                                        <span className="icon icon-dashboard"></span> 
                                        <h2>{I18n.t('overview')}</h2>
                                    </Nav.Link>
                                </Nav.Item>
                                : null
                            }
                            {
                                freelancerportfolio && freelancerportfolio.length > 0 ?
                                <Nav.Item>
                                    <Nav.Link eventKey="Portfolio">
                                        <span className="icon icon-business-case"></span> 
                                        {I18n.t('portfolio')}
                                    </Nav.Link>
                                </Nav.Item>
                                : null
                            }
                            {
                                projects && projects.length > 0 ?
                                <Nav.Item>
                                    <Nav.Link eventKey="Projects">
                                        <span className="icon icon-projects"></span> 
                                        {I18n.t('projects')}
                                    </Nav.Link>
                                </Nav.Item>
                                : null
                            }
                            {
                                allservices && allservices.length > 0 ?
                                <Nav.Item>
                                    <Nav.Link eventKey="Services">
                                        <span className="icon icon-services"></span> 
                                        {I18n.t('services')}
                                    </Nav.Link>
                                </Nav.Item>
                                : null
                            }
                            {
                                feedbacks && feedbacks.length > 0 ?
                                <Nav.Item>
                                    <Nav.Link eventKey="Feedback">
                                        <span className="icon icon-feedback"></span> 
                                        <h4>{I18n.t('profile_feedback')}</h4>
                                    </Nav.Link>
                                </Nav.Item>
                                : null
                            }
                        </Nav>
                        <ProfileStats freelancerdata= {freelancer} />
                    </div>
                    <div className="col-md-7 col-lg-8">
                        <Tab.Content>
                            {
                                freelancer.about || (freelancer.skills && freelancer.skills.length > 0) ?
                                <Tab.Pane eventKey="Overview">
                                    <Overview freelancerdata= {freelancer} />
                                </Tab.Pane>
                                : null
                            }
                            {
                                freelancerportfolio && freelancerportfolio.length > 0 ?
                                <Tab.Pane eventKey="Portfolio">
                                    <Portfolio activePage={portfolioActivePage} setActivePage={setPortfolioActivePage} />
                                </Tab.Pane>
                                : null
                            }
                            {
                                projects && projects.length > 0 ?
                                <Tab.Pane eventKey="Projects">
                                    <Projects activePage={projectsActivePage} setActivePage={setProjectsActivePage} />
                                </Tab.Pane>
                                : null
                            }
                            {
                                allservices && allservices.length > 0 ?
                                <Tab.Pane eventKey="Services">
                                    <Services activePage={servicesActivePage} setActivePage={setServicesActivePage} />
                                </Tab.Pane>
                                : null
                            }
                            {
                                feedbacks && feedbacks.length > 0 ?
                                <Tab.Pane eventKey="Feedback">
                                    <Feedback activePage={feeddbacksActivePage} setActivePage={setFeedbacksActivePage} />
                                </Tab.Pane>
                                : null
                            }
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    )
}

export default ProfileContent;