import ReCAPTCHA from 'react-google-recaptcha';
import {useSelector} from 'react-redux';

export default function GRecaptch(props) {
  const {locale} = useSelector(state => ({
    locale: state.i18n.locale,
  }));

  function onRecaptchChange() {
    props.setIsHuman(true);
  }

  const {className} = props;
  // Test Sitekey for Development sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
  // Live Sitekey for Production sitekey="6LcBfRUeAAAAAItj1rQKpKo87-ZzXZXgfLGrcnBN"
  return (
    <ReCAPTCHA
      sitekey="6LcBfRUeAAAAAItj1rQKpKo87-ZzXZXgfLGrcnBN"
      onChange={onRecaptchChange}
      className={className ? className : null}
      hl={locale}
    />
  );
}
