import React, { useMemo, Fragment }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import { useDropzone } from 'react-dropzone';
import { I18n } from 'react-redux-i18n';
import { ProgressBar } from 'react-bootstrap';
import axios from "axios";
import { Size } from "../CalculateSize";
import { ServerURL } from '../Server';
import { returnErrors } from '../../actions/errorActions';

var browserImageSize = require('browser-image-size');
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#00e676'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };


export function Uploader({
        accept,
        maxFiles,
        multiple,
        maxSize,
        sortable,
        setFilesChosen,
        setFilesUpload,
        setUploadErrors,
        uploadErrors,
        setFilesSort,
        filesSort,
        filesSortLength,
        filteredErrorsLength,
        filteredErrors,
        uploadPath,
        projectId,
        setDeletedFiles,
        deletedFiles,
        maxWidth,
        maxHeight,
        optimize = ''
    }) {
    let acceptedMime = [];
    let acceptedExtensions = [];

    const dispatch = useDispatch();

    if(accept){
        acceptedMime = Array.from(accept, ({mime}) => mime);
        acceptedExtensions = Array.from(accept, ({ext}) => ext);
    }

    

    function isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }

    const {
        isDragActive,
        isDragAccept,
        isDragReject,
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop: handleOnDrop,
        maxFiles,
        accept: acceptedMime.map(item => item),
        maxSize,
        multiple
    });

    function deleteItem(item) {
        if( item && item.includes(`uploads/`)){
            const filestoDelete = [...deletedFiles];
            filestoDelete.push(item);
            setDeletedFiles(filestoDelete);
            const images_sort = filesSort.filter(i => i.source !== item);
            setFilesSort(images_sort);
        }else{
            const images_sort = filesSort.filter(i => i.source !== item);
            setFilesSort(images_sort);
        }
    }

    const SortableItem = SortableElement(({ value }) => (
        <li className="ListItemDragabol">
            {
                value.type.includes("image/") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <img
                                src={value.preview}
                                alt="Lancersin - لانسرزان"
                            />
                        </div>
                    </div>
                : value.type.includes("application/pdf") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-pdf"></i>
                        </div>
                    </div>
                : value.type.includes("application/msword") || value.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-word"></i>
                        </div>
                    </div>
                : value.type.includes("application/vnd.ms-powerpoint") || value.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-powerpoint"></i>
                        </div>
                    </div>
                : value.type.includes("application/vnd.ms-excel") || value.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-excel"></i>
                        </div>
                    </div>
                : value.type.includes("application/zip") || value.type.includes("application/vnd.rar") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-archive"></i>
                        </div>
                    </div>
                : value.type.includes("text/plain") ?
                    <div className="File__Thumb">
                        <div className="Image__Container">
                            <i className="far fa-file-alt"></i>
                        </div>
                    </div>
                :
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file"></i>
                    </div>
                </div>
            }
            <div className="File__Content">
                <h2>{value.path}</h2>
                <small>{Size(value.size)}</small>
                <ProgressBar striped animated variant="success" now={value.percent} label={`${value.percent}%`} />
                {
                    value.percent < 100 ?
                    <small>{Size(value.loaded)} of {Size(value.total)}</small>
                    : <div className="File__Uploaded">{I18n.t('uploaded_successfully')}</div>
                }
                <button
                    className="deleteItem"
                    type="button"
                    onClick={() => deleteItem(value.source)}
                    >
                    <span className="icon icon-delete"></span>
                    {I18n.t('delete')}
                </button>
            </div>
        </li>
    ));

    const SortableList = SortableContainer(({ images_sort }) => {
        return (
            <ul className="ListDragabol">
                {images_sort.map((value, index) => (
                    <SortableItem key={index} index={index} value={value} />
                ))}
            </ul>
        );
    });


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);


    const { token } = useSelector((state) => ({
        token: state.auth.token,
    }));

    function containsObject(code) {
        return uploadErrors.some(function(el) {
          return el.code === code;
        }); 
    }

    function onSortEnd({ oldIndex, newIndex }) {
        setFilesSort((filesSort) => arrayMove(filesSort, oldIndex, newIndex));
    }


    async function handleOnDrop(acceptedFiles, rejectedFiles) {
        var filesToUpload = [];
        var filesError = [];
         for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (isFileImage(file) && (maxWidth || maxHeight)) {
                let size = await browserImageSize(file)
                if(size.height > maxHeight || size.width > maxWidth){
                    const maxDimError = {
                        code: 'MaxDim',
                        message: `${maxWidth &&  size.width > maxWidth ? `The Image width shouldn't exceed ${maxWidth}px` : ''} ${maxHeight && size.height > maxHeight ? `, and the height shouldn't exceed ${maxHeight}px` : ''}`,
                    };
                    filesError.push(maxDimError);
                }else{
                    filesToUpload.push(file);
                }
            } else{
                filesToUpload.push(file);
            }
        }
        setTimeout(() => {
            if(filesError.length > 0){
                setUploadErrors((prevErrors) => [
                    ...prevErrors,
                    filesError[0]
                ])
            }
        }, 500);
        const newFiles = filesToUpload.map(file =>  Object.assign(file, {
                preview: URL.createObjectURL(file),
                percent: 0,
                loaded: 0,
                total: 0
            })
        );
        setUploadErrors([]);
        if((filesSort.length >= maxFiles) || (newFiles >= maxFiles)){
            const maxFilesError = {
                code: 'MaxFiles',
                message: `You Exceed The Limit of Max Files which is ${maxFiles}`,
            };
            if(containsObject(maxFilesError.code)){
                return null;
            }else{ 
                setUploadErrors((prevErrors) => [
                    ...prevErrors,
                    maxFilesError
                ])
            }
        } else{
            const updatedFiles = [...newFiles];
            updatedFiles.map( file => {
                const formData = new FormData();
                formData.append("file", file, file.name);
                formData.append("optimize", optimize);

                // aborting the request
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                const config = token || null;
                const langSet = localStorage.getItem("lang");
                return(
                    axios({
                        method: "POST",
                        url:
                        `${ServerURL}/api/upload`,
                        data: formData,
                        headers: {
                            "Accept-Language": langSet === "English" ? "en" : "ar",
                            Authorization: "bearer" + config,
                            //config
                        },
                        cancelToken: source.token,
                        onUploadProgress: (progressEvent) => {
                            const {loaded, total} = progressEvent;
                            let percent = Math.floor( (loaded * 100) / total );
                            const updatedFile = Object.assign(file, {
                                percent,
                                loaded,
                                total
                            })
                            const updatedFilesObject = updatedFiles.map((item) =>
                                item.name === file.name ? updatedFile : item
                            );
                            setFilesSort([...filesSort, ...updatedFilesObject]);
                            setFilesUpload(true);
                            setFilesChosen(false);
                        },
                    })
                    .then((response) => {
                        const updatedFile = Object.assign(file, {
                            source: `${response.data.filename}`,
                            origin: `${file.path}`,
                        })
                        const updatedFilesObject = updatedFiles.map((item) =>
                            item.name === file.name ? updatedFile : item
                        );
                        setFilesSort([...filesSort, ...updatedFilesObject]);
                        setFilesUpload(false);
                        setFilesChosen(false);
                    })
                    .catch((thrown) => {
                        if (axios.isCancel(thrown)) {
                        dispatch(returnErrors(thrown.message, "Request canceled", 'UPLOAD_FAIL'));
                        setFilesUpload(false);
                        setFilesChosen(false);
                        } else {
                        dispatch(returnErrors(thrown.message, "Request canceled", 'UPLOAD_FAIL'));
                        setFilesUpload(false);
                        setFilesChosen(false);
                        }
                    })
                )
            });

        }

        if(rejectedFiles){
            rejectedFiles.map(file => {
                return (containsObject(file.errors[0].code)) ? null :
                setUploadErrors((prevErrors) => [
                    ...prevErrors,
                    file.errors[0]
                ])
            })
        }
    }
    const selectedFiles = filesSort ? filesSort.map((file, index) => (
        <li key={index}>
            {
            file.type.includes("image/") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <img
                            src={file.preview}
                            alt="Lancersin - لانسرزان"
                        />
                    </div>
                </div>
            : file.type.includes("application/pdf") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-pdf"></i>
                    </div>
                </div>
            : file.type.includes("application/msword") || file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-word"></i>
                    </div>
                </div>
            : file.type.includes("application/vnd.ms-powerpoint") || file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-powerpoint"></i>
                    </div>
                </div>
            : file.type.includes("application/vnd.ms-excel") || file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-excel"></i>
                    </div>
                </div>
            : file.type.includes("application/zip") || file.type.includes("application/vnd.rar") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-archive"></i>
                    </div>
                </div>
            : file.type.includes("text/plain") ?
                <div className="File__Thumb">
                    <div className="Image__Container">
                        <i className="far fa-file-alt"></i>
                    </div>
                </div>
            :
            <div className="File__Thumb">
                <div className="Image__Container">
                    <i className="far fa-file"></i>
                </div>
            </div>
            }
            <div className="File__Content">
                <h2>{file.path}</h2>
                <small>{Size(file.size)}</small>
                <ProgressBar striped animated variant="success" now={file.percent} label={`${file.percent}%`} />
                {
                    file.percent < 100 ?
                    <small>{Size(file.loaded)} of {Size(file.total)}</small>
                    : <div className="File__Uploaded">{I18n.t('uploaded_successfully')}</div>
                }
                <button
                    className="deleteItem"
                    type="button"
                    onClick={() => deleteItem(file.source)}
                    >
                    <span className="icon icon-delete"></span>
                    {I18n.t('delete')}
                </button>
            </div>
        </li>

    )) : null;

    return(
        <section>
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <p className="mb-0">{I18n.t('uploader_txt')}</p>
                {
                    maxSize ? 
                    <p className="mt-2 mb-0">
                        {
                            accept ?
                            <Fragment>
                                {I18n.t('files_extensions') + ": \""} {<span className="Uploader__limit">{acceptedExtensions.map((item, index) => <span className="Uploader__limit__item" key={index}>{item}</span>)}</span>} {" \" - "}
                            </Fragment>
                            : null
                        }
                        {I18n.t('max_size') + ": "}{<span className="Uploader__limit"> {Size(maxSize)} </span>}
                    </p>
                    : null
                }
                {
                    maxWidth || maxHeight ? 
                    <p className="mt-2 mb-0">
                        {
                            maxWidth ?
                            <Fragment>
                                {I18n.t('image_max_width')}: <span className="Uploader__limit"> {maxWidth}{I18n.t('pixel')} </span>
                            </Fragment>
                            : null
                        }
                        {
                            maxHeight ?
                            <Fragment>
                                { maxWidth && maxHeight ? I18n.t('and') : null} {I18n.t('image_max_height')}: <span className="Uploader__limit"> {maxHeight}{I18n.t('pixel')} </span>
                            </Fragment>
                            : null
                        }
                    </p>
                    : null
                }
                {
                    maxFiles ? 
                    <p className="mt-2 mb-0">{I18n.t('max_files') + ": "}{<span className="Uploader__limit"> {maxFiles} </span>}</p>
                    : null
                }
            </div>
            {
                filteredErrorsLength ?
                    filteredErrors.map( (error, index) => (
                        error.code === "file-invalid-type" ?
                        <div className="d-block invalid-feedback mt-2" key={index}>
                            {
                                I18n.t('invalid_file_type') + " \" " + 
                                acceptedExtensions.map
                                (
                                    item => " " + item
                                ) + " \""
                            }
                        </div>
                        : error.code === "MaxFiles" ?
                        <div className="d-block invalid-feedback mt-2" key={index}>
                            {I18n.t('invalid_max_files') + " " + maxFiles}
                        </div>
                        : error.code === "file-too-large" ?
                        <div className="d-block invalid-feedback mt-2" key={index}>
                            {I18n.t('max_size') + " " + Size(maxSize)}
                        </div>
                        :
                        <div className="d-block invalid-feedback mt-2" key={index}>
                            {error.message}
                        </div>
                    ))
                : null
            }
            {
                filesSortLength ?
                <aside className="Files__Accepted mt-3">
                    {
                        sortable ? 
                        <Fragment>
                            <SortableList
                            images_sort={filesSort}
                            onSortEnd={onSortEnd}
                            distance={1}
                            />
                        </Fragment>
                        :
                        <Fragment>
                            <ul>{selectedFiles}</ul>
                        </Fragment>
                    }
                    
                </aside> 
                : null
            }
        </section>
    )
}