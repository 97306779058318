// Actions for Editing Behavior
import TYPES from "./types";
import { axiosInstance, tokenConfig } from "../../../axiosInstance";
import { returnErrors, clearErrors } from "../../../actions/errorActions";
import API from "../API";

const {
  GET_ARTICLES_LOADING,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,

  GET_ARTICLE_BY_ID_LOADING,
  GET_ARTICLE_BY_ID_SUCCESS,
  GET_ARTICLE_BY_ID_FAIL,

  GET_USER_ARTICLES_LOADING,
  GET_USER_ARTICLES_SUCCESS,
  GET_USER_ARTICLES_FAIL,
} = TYPES;

// Get Articles

export function getArticlesLoading() {
  return { type: GET_ARTICLES_LOADING };
}

export function getArticlesSuccess(res) {
  return {
    type: GET_ARTICLES_SUCCESS,
    payload: res,
  };
}

export function getArticlesFail(error) {
  return {
    type: GET_ARTICLES_FAIL,
    error
  };
}

// Get User Articles

export function getUserArticlesLoading() {
  return {
    type: GET_USER_ARTICLES_LOADING,
  };
}

export function getUserArticlesSuccess(res) {
  return {
    type: GET_USER_ARTICLES_SUCCESS,
    payload: res,
  };
}

export function getUserArticlesFail(error) {
  return {
    type: GET_USER_ARTICLES_FAIL,
    error,
  };
}

// export function getArticles(user_id = null, id = null, page = null, limit = null, cat_id = null, sub_cat_id = null, keywords = null, status_id = null) {
//   return function getArticlesWithDispatch(dispatch, getState) {
//     if(user_id){
//       dispatch(getUserArticlesLoading());
//     }else{
//       dispatch(getArticlesLoading());
//     }
//     axiosInstance
//       .get(API.ARTICLES, {params: { user_id, id, page, limit, cat_id, sub_cat_id, keywords, status_id } }, tokenConfig(getState))
//       .then((res) => {
//         if(user_id){
//           dispatch(getUserArticlesSuccess(res.data));
//         }else{
//           dispatch(getArticlesSuccess(res.data));
//         }
//       })
//       .catch((error) => {
//         if(user_id){
//           dispatch(getUserArticlesFail(error));
//         }else{
//           dispatch(getArticlesFail(error));
//         }
//         dispatch(
//           returnErrors(
//             error.response.data,
//             error.response.status,
//             GET_USER_ARTICLES_FAIL
//           )
//         );
//       });
//   };
// }

export const getArticles = (user_id = null, id = null, page = null, limit = null, cat_id = null, sub_cat_id = null, keywords = null, status_id = null) => {
  return async function(dispatch, getState){
      dispatch(clearErrors());
      if(user_id){
        dispatch(getUserArticlesLoading());
      }else{
        dispatch(getArticlesLoading());
      }
      try {
          let response = await axiosInstance.get(
            API.ARTICLES, 
            {params: { user_id, id, page, limit, cat_id, sub_cat_id, keywords, status_id } }, 
            tokenConfig(getState)
          );
          if(response.status !== 200){
              dispatch({
                  type: GET_ARTICLES_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              if(user_id){
                dispatch(getUserArticlesSuccess(response.data));
              }else{
                dispatch(getArticlesSuccess(response.data));
              }
          }
      } catch (error) {
          if (error.response && error.response.data) {
            if(user_id){
              dispatch(getUserArticlesFail(error));
            }else{
              dispatch(getArticlesFail(error));
            }
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_ARTICLES_FAIL'));
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ARTICLES_FAIL')),
                dispatch ({ 
                    type: GET_ARTICLES_FAIL 
                })
            )
          }
      }
  }
};

// Get Article

export const getArticleById = ({id, locale}) => {
  return async function(dispatch){
      dispatch(clearErrors());
      dispatch({
          type: GET_ARTICLE_BY_ID_LOADING
      });
      const config = {
          headers: {
              "Content-type": "application/json",
              "Accept-Language": locale ? locale : "en"
          }
      }
      try {
          let response = await axiosInstance.get(`${API.ARTICLES}?id=${id}`, config);
          if(response.status !== 200){
              dispatch({
                  type: GET_ARTICLE_BY_ID_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: GET_ARTICLE_BY_ID_SUCCESS,
                  payload: response.data.data
              })
          }
      } catch (error) {
          if (error.response && error.response.data) {
              dispatch(returnErrors(error.response.data, error.response.status, 'GET_ARTICLE_BY_ID_FAIL'));
              dispatch ({ 
                  type: GET_ARTICLE_BY_ID_FAIL 
              });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_ARTICLE_BY_ID_FAIL')),
                dispatch ({ 
                    type: GET_ARTICLE_BY_ID_FAIL 
                })
            )
          }
      }
  }
};

const exportedObject = {
  getArticlesLoading,
  getArticlesSuccess,
  getArticlesFail,
  getUserArticlesLoading,
  getUserArticlesSuccess,
  getUserArticlesFail,
  getArticles,
  getArticleById
};

export default exportedObject;
