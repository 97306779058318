export const Size = (x) => {
	if(isNaN(x)) return x;

	if(x < 999) {
		return x + "B";
	}

	if(x >= 1000000) {
		return Math.round(x/1000000) + "MB";
	}

	if(x >= 1000) {
		return Math.round(x/1000) + "KB";
    }
}