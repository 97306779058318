import {
  GET_SERVICE_INVOICE_LOADING,
  GET_SERVICE_INVOICE_SUCCESS,
  GET_SERVICE_INVOICE_FAIL,
  POST_INVOICE_STATUS_LOADING,
  POST_INVOICE_STATUS_SUCCESS,
  POST_INVOICE_STATUS_FAIL,
  POST_INVOICE_RATING_LOADING,
  POST_INVOICE_RATING_SUCCESS,
  POST_INVOICE_RATING_FAIL
} from "../actions/types";
import { axiosInstance } from "../axiosInstance";
import { returnErrors, clearErrors } from "../actions/errorActions";
import { CLEAR_FEEDBACKS } from "../redux/feedbacks/actions/types";

// Setup config/headers and token

export const tokenConfig = (getState) => {
  // Get token from localStorage
  const token = getState().auth.token;
  //Headers
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  // If token, add to headers

  if (token) {
    config.headers["Authorization"] = "bearer" + token;
  }
  return config;
};

export const getserviceinvoice = (id) => {
  return async function(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
          type: GET_SERVICE_INVOICE_LOADING
      });
      dispatch({
        type: CLEAR_FEEDBACKS
      });
      try {
          let response = await axiosInstance.get(
            `/api/services/invoices?id=${id}`, 
            tokenConfig(getState)
          );
          if(response.status !== 200){
              dispatch({
                  type: GET_SERVICE_INVOICE_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: GET_SERVICE_INVOICE_SUCCESS,
                  payload: response.data
              })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'GET_SERVICE_INVOICE_FAIL'));
            dispatch ({ 
                type: GET_SERVICE_INVOICE_FAIL 
            });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'GET_SERVICE_INVOICE_FAIL')),
                dispatch ({ 
                    type: GET_SERVICE_INVOICE_FAIL 
                })
            )              
          }
      }
  }
};

export const rateInvoiceSeller = (invoiceStatusInfo) => {
  return async function(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
          type: POST_INVOICE_RATING_LOADING
      });
      const morphType = 'service_invoices';
      const morphID = invoiceStatusInfo.invoice_id;
      const comment = invoiceStatusInfo.comment;
      const rate = invoiceStatusInfo.rate;
      const {
        serviceUserId, 
        loggedUserId
      } = invoiceStatusInfo;

      const invocieRatingInfo = {morphType, morphID, comment, rate};
      try {
          let response = await axiosInstance.post(
            `/api/feedbacks`,
            invocieRatingInfo,
            tokenConfig(getState)
          );
          if(response.status !== 200){
              dispatch({
                  type: POST_INVOICE_RATING_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: POST_INVOICE_RATING_SUCCESS,
                  invoiceRatingSuccess: response.data.response
              });
              if(serviceUserId !== loggedUserId){
                dispatch(changeInvoiceStatus(invoiceStatusInfo));
              }
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'POST_INVOICE_RATING_FAIL'));
            dispatch ({ 
                type: POST_INVOICE_RATING_FAIL 
            });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'POST_INVOICE_RATING_FAIL')),
                dispatch ({ 
                    type: POST_INVOICE_RATING_FAIL 
                })
            )
          }
      }
  }
};

export const changeInvoiceStatus = (invoiceStatusInfo) => {
  return async function(dispatch, getState){
      dispatch(clearErrors());
      dispatch({
          type: POST_INVOICE_STATUS_LOADING
      });
      const invoice_id = invoiceStatusInfo.invoice_id;
      const status_id = invoiceStatusInfo.status_id;
      const changeStatusInfo = {invoice_id, status_id};
      try {
          let response = await axiosInstance.post(
            `/api/services/invoices/status`,
            changeStatusInfo,
            tokenConfig(getState)
          );
          if(response.status !== 200){
              dispatch({
                  type: POST_INVOICE_STATUS_FAIL,
              });
              throw Error({
                  response: {
                      data: "Other Errors",
                      status: "Other Errors"
                  },
              })
          }else{
              dispatch({
                  type: POST_INVOICE_STATUS_SUCCESS,
                  invoiceStatusSuccess: response.data.data,
              })
          }
      } catch (error) {
          if (error.response && error.response.data) {
            dispatch(returnErrors(error.response.data, error.response.status, 'POST_INVOICE_STATUS_FAIL'));
            dispatch ({ 
                type: POST_INVOICE_STATUS_FAIL 
            });
          }else{
            let errorJson = JSON.stringify(error);
            let errorParsed = JSON.parse(errorJson);
            return (
                dispatch(returnErrors(errorParsed.message, "Network Error", 'POST_INVOICE_STATUS_FAIL')),
                dispatch ({ 
                    type: POST_INVOICE_STATUS_FAIL 
                })
            )
          }
      }
  }
};
