import React, { useState, useEffect, Fragment, useCallback } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { MetaComponent } from "../MetaComponent";
import { TitleComponent } from "../TitleComponent";
import AddingRow from "../AddingRow";
import Pagination from "react-js-pagination";
import Spin from "../Spin";
import ProjectCard from "../ProjectCard";
import { I18n } from "react-redux-i18n";
import ConfToast from "../ConfToast";
//import ClosedProjects from "./ClosedProjects";
import NoItems from '../NoItems';
import ConnectionLost from "../ConnectionLost";
import { getFavouriteProjects } from "../../redux/favourite/actions/getActions";
import FavServices from "./FavServices";
import { CLEAR_DELETE_FAVOURITE_SUCCESS } from "../../redux/favourite/actions/types";

const MyFavourites = () => {
  const dispatch = useDispatch();
  const {
    userLoading,
    isAuthenticated,
    locale,

    getFavProjectsLoading,
    favProjects,
    totalFavProjects,
    favProjectsPerPage,

    //Delete from favourite
    deletingFavouriteSuccess,

    errorId,
    errorMessage,
  } = useSelector((state) => ({
    userLoading: state.auth.isLoading,
    isAuthenticated: state.auth.isAuthenticated,
    locale: state.i18n.locale,

    getFavProjectsLoading: state.favourites.getFavProjectsLoading,
    favProjects: state.favourites.favProjects,
    totalFavProjects: state.favourites.totalFavProjects,
    favProjectsPerPage: state.favourites.favProjectsPerPage,

    deletingFavouriteSuccess: state.favourites.deletingFavouriteSuccess,

    errorId: state.error.id,
    errorMessage: state.error.msg,
  }));

  const [activePage, setActivePage] = useState(1);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState("");
  const [confMessage, setConfMessage] = useState("");
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [loadingFavProjects, setLoadingFavProjects] = useState(true);

  useEffect(() => {
    setLoadingFavProjects(getFavProjectsLoading);
  }, [getFavProjectsLoading]);

  const handleToast = useCallback((showToast, title, message, status, delay) => {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }, [setConfShow, setConfTitle, setConfMessage, setConfStatus, setDelay]);

  const totalItemsCount = totalFavProjects;
  const itemsCountPerPage = Number(favProjectsPerPage);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    if(isAuthenticated){
      const page = activePage;
      const limit = 20;
      const morphType = "projects";
      dispatch(getFavouriteProjects(morphType, locale, page, limit));
    }
  }, [dispatch, locale, isAuthenticated, activePage]);

  useEffect(() => {
    if (deletingFavouriteSuccess){
      const page = 1;
      const limit = 20;
      const morphType = "projects";
      dispatch(getFavouriteProjects(morphType, locale, page, limit));
      setTimeout(() => {
        handleToast(true, I18n.t('deleting_project_from_fav'), I18n.t('deleting_project_from_fav_success'), 'Toast__Container__Success', 6000);
      }, 1500);
      dispatch({
        type: CLEAR_DELETE_FAVOURITE_SUCCESS
      });
    };
  },[deletingFavouriteSuccess, handleToast, dispatch, locale]);

  return (
    <Fragment>
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="container">
      <div className="row">
        <div className="col-12 Dashboard__tasks__List">
            <AddingRow
                title={"favourite"}
                titleIconClassName={"icon-favourite"}
            />
            <Tabs defaultActiveKey="favouriteProjects" id="JoinedStats" unmountOnExit={true}>
                <Tab eventKey="favouriteProjects" title={I18n.t('fav_projects')}>
                    <TitleComponent title={I18n.t('lancersin_fav_projects')} />
                    <MetaComponent />
                    <div className="row">
                        {
                        loadingFavProjects || userLoading ?
                            <Spin />
                        : errorId === "GET_FAV_PROJECTS_FAIL" && errorMessage === "Network Error" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "connection"
                                title = "network_error_title"
                                message = "network_error_message"
                            />
                        </div>
                        : errorId === "GET_FAV_PROJECTS_FAIL" ?
                        <div className="col-12">
                            <ConnectionLost
                                errorType= "somethingWrong"
                                title = "something_error_title"
                                message = "something_error_message"
                            />
                        </div>
                        : favProjects.length > 0 &&  !loadingFavProjects ?
                        <Fragment>
                            {
                            favProjects.map((item) => (
                                <ProjectCard key={`project-#${item.id}`} item={item.project} deleteButton={true} />
                            ))
                            }
                            <div className="col-12 my-4">
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalItemsCount}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                            </div>
                        </Fragment>
                        :
                        <div className="col-12">
                            <NoItems
                            title="no_projects_found"
                            message="no_fav_projects_message"
                            />
                        </div>
                        }
                    </div>
                </Tab>
                <Tab eventKey="favouriteServices" title={I18n.t('fav_services')}>
                    <FavServices />
                </Tab>
                {/*<Tab eventKey="favouriteContests" title={I18n.t('fav_contests')}>
                    <ClosedProjects />
                </Tab>*/}
            </Tabs>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default MyFavourites;
