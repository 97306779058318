import React, {useCallback, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {clearErrors} from '../../actions/errorActions';
import {Translate, I18n} from 'react-redux-i18n';
import ButtonWithLoading from '../Buttons/ButtonWithLoading';
import {sendVerification} from '../../actions/verifyaccountActions';
import {resetPassword} from '../../actions/resetpasswordActions';
import {logout} from '../../actions/authActions';
import GRecaptch from '../GRecaptch';
import {useHistory} from 'react-router-dom';
import ConfToast from '../ConfToast';
const {Control} = Form;
const {Feedback} = Control;

function ForgotPassword() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [localErrors, setLocalErrors] = useState({});
  const [isHuman, setIsHuman] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [confShow, setConfShow] = useState(false);
  const [confTitle, setConfTitle] = useState('');
  const [confMessage, setConfMessage] = useState('');
  const [confStatus, setConfStatus] = useState(null);
  const [delay, setDelay] = useState(null);
  const [prevPath, setPrevPath] = useState(null);

  const {
    isAuthenticated,
    user,
    errorMsg: {errors = {}},
    errorId,
    sendVerificationLoading,
    sendVerificationSuccess,
    resetPasswordLoading,
    resetPasswordSuccess,
  } = useSelector(state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    errorMsg: state.error.msg,
    errorId: state.error.id,
    sendVerificationLoading: state.verifyAccount.sendVerificationLoading,
    sendVerificationSuccess: state.verifyAccount.sendVerificationSuccess,
    resetPasswordLoading: state.resetPassword.resetPasswordLoading,
    resetPasswordSuccess: state.resetPassword.resetPasswordSuccess,
  }));

  useEffect(() => {
    if (typeof window != 'undefined') {
      if (window.location.href.indexOf('path=') > -1) {
        var url_string = window.location.href;
        var url = new URL(url_string);
        if (url.searchParams) {
          var path = url.searchParams.get('path');
          setPrevPath(path);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      setEmail(user.email);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (sendVerificationSuccess === 'success') {
      setCodeSent(true);
    }
  }, [sendVerificationSuccess]);

  useEffect(() => {
    if (resetPasswordSuccess === 'success') {
      //   dispatch({
      //     type: RESET_VERIFICATION,
      //   });
      setCodeSent(false);
      if (isAuthenticated) {
        dispatch(logout());
        if (prevPath !== null) {
          history.push(`/signin?path=${prevPath}`);
        } else {
          history.push('/signin');
        }
      } else {
        if (prevPath !== null) {
          history.push(`/signin?path=${prevPath}`);
        } else {
          history.push('/signin');
        }
      }
    }
  }, [resetPasswordSuccess, history, isAuthenticated, dispatch]);

  function handleItemLocalError({propName, currentValue, message}) {
    if (currentValue) {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: undefined,
      }));
    } else {
      setLocalErrors(errors => ({
        ...errors,
        [propName]: message,
      }));
    }
  }

  function onEmailChange(e) {
    setEmail(e.target.value);
  }

  function onEmailBlur() {
    handleItemLocalError({
      propName: 'email',
      currentValue: email,
      message: I18n.t('email_required'),
    });
  }

  function onCodeChange(e) {
    setCode(e.target.value);
  }

  function onCodeBlur() {
    handleItemLocalError({
      propName: 'code',
      currentValue: code,
      message: I18n.t('verification_required'),
    });
  }

  function onPasswordChange(e) {
    setPassword(e.target.value);
  }

  function onPasswordBlur() {
    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
  }

  function onRePasswordChange(e) {
    setRepassword(e.target.value);
  }

  function onRePasswordBlur() {
    if (repassword) {
      if (repassword !== password) {
        setLocalErrors(errors => ({
          ...errors,
          repassword: I18n.t('repassword_password_required'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          repassword: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        repassword: I18n.t('repassword_required'),
      }));
    }
  }

  function handleToast(showToast, title, message, status, delay) {
    setConfShow(showToast);
    setConfTitle(title);
    setConfMessage(message);
    setConfStatus(status);
    setDelay(delay);
  }

  function onSendCode(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'email',
      currentValue: email,
      message: I18n.t('email_required'),
    });

    if (email && isHuman) {
      const type = 'verification-change-password';
      const itemData = {
        email,
        type,
      };
      dispatch(clearErrors());
      dispatch(sendVerification(itemData));
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  function onResetPassword(e) {
    e.preventDefault();

    handleItemLocalError({
      propName: 'code',
      currentValue: code,
      message: I18n.t('verification_required'),
    });

    handleItemLocalError({
      propName: 'password',
      currentValue: password,
      message: I18n.t('password_required'),
    });
    if (repassword) {
      if (repassword !== password) {
        setLocalErrors(errors => ({
          ...errors,
          repassword: I18n.t('repassword_password_required'),
        }));
      } else {
        setLocalErrors(errors => ({
          ...errors,
          repassword: undefined,
        }));
      }
    } else {
      setLocalErrors(errors => ({
        ...errors,
        repassword: I18n.t('repassword_required'),
      }));
    }

    if (code && password && repassword && isHuman) {
      const itemData = {
        verification_code: code,
        password,
        password_confirmation: repassword,
      };
      dispatch(clearErrors());
      dispatch(resetPassword(itemData));
    } else if (!isHuman) {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('verify_robot'),
        'Toast__Container__Error',
        10000,
      );
    } else {
      handleToast(
        true,
        I18n.t('complete_required_info_title'),
        I18n.t('complete_required_info'),
        'Toast__Container__Warning',
        6000,
      );
    }
  }

  return (
    <div className="container">
      <ConfToast
        showConf={confShow}
        setShowConf={setConfShow}
        title={confTitle}
        message={confMessage}
        status={confStatus}
        delay={delay}
      />
      <div className="SignContainer__fixed">
        <div className="SignContainer">
          <header>
            {isAuthenticated ? (
              <h1>{I18n.t('change_password')}</h1>
            ) : (
              <h1>{I18n.t('reset_password')}</h1>
            )}
          </header>
          {codeSent ? (
            <form>
              <p className="text-center">{I18n.t('verification_sent')}</p>
              <div className="row">
                <div className="col-12">
                  <div class="form-group">
                    <label>
                      {I18n.t('verfication_code')}{' '}
                      <small className="required">({I18n.t('required')})</small>
                    </label>
                    <input
                      type="number"
                      name="code"
                      id="code"
                      className="form-control"
                      placeholder={I18n.t('verfication_code')}
                      onChange={onCodeChange}
                      onBlur={onCodeBlur}
                    />
                    {errorId === 'RESET_PASSWORD_FAIL' && errors.length > 0 ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {errors[0]}
                      </Feedback>
                    ) : localErrors.code ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {localErrors.code}
                      </Feedback>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div class="form-group">
                    <label>
                      {I18n.t('password')}{' '}
                      <small className="required">({I18n.t('required')})</small>
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      className="form-control"
                      placeholder={I18n.t('password')}
                      onChange={onPasswordChange}
                      onBlur={onPasswordBlur}
                    />
                    {errors.password ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {errors.password[0]}
                      </Feedback>
                    ) : localErrors.password ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {localErrors.password}
                      </Feedback>
                    ) : null}
                  </div>
                </div>
                <div className="col-12">
                  <div class="form-group">
                    <label>
                      {I18n.t('repassword')}{' '}
                      <small className="required">({I18n.t('required')})</small>
                    </label>
                    <input
                      type="password"
                      name="repassword"
                      id="repassword"
                      className="form-control"
                      placeholder={I18n.t('repassword')}
                      onChange={onRePasswordChange}
                      onBlur={onRePasswordBlur}
                    />
                    {errors.password_confirmation ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {errors.password_confirmation[0]}
                      </Feedback>
                    ) : localErrors.repassword ? (
                      <Feedback type="invalid" className="d-block mt-0 mb-2">
                        {localErrors.repassword}
                      </Feedback>
                    ) : null}
                  </div>
                </div>
              </div>

              <GRecaptch setIsHuman={setIsHuman} className="mb-2" />

              <ButtonWithLoading
                variant="primary"
                type="submit"
                onClick={onResetPassword}
                data-backdrop="static"
                loading={resetPasswordLoading}>
                <Translate value="change_password" />
              </ButtonWithLoading>
            </form>
          ) : (
            <form>
              {isAuthenticated ? null : (
                <div className="row">
                  <div className="col-12">
                    <div class="form-group">
                      <label>
                        {I18n.t('email_address')}{' '}
                        <small className="required">
                          ({I18n.t('required')})
                        </small>
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        placeholder={I18n.t('email_address')}
                        onChange={onEmailChange}
                        onBlur={onEmailBlur}
                      />
                      {errors && errorId === 'SEND_VERIFICATION_FAIL' ? (
                        <Feedback type="invalid" className="d-block mt-0 mb-2">
                          {errors.email ? errors.email[0] : errors[0]}
                        </Feedback>
                      ) : localErrors.email ? (
                        <Feedback type="invalid" className="d-block mt-0 mb-2">
                          {localErrors.email}
                        </Feedback>
                      ) : null}
                    </div>
                  </div>
                </div>
              )}

              <GRecaptch setIsHuman={setIsHuman} className="mb-2" />

              <ButtonWithLoading
                variant="primary"
                type="submit"
                onClick={onSendCode}
                data-backdrop="static"
                loading={sendVerificationLoading}>
                <Translate value="send_code" />
              </ButtonWithLoading>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
