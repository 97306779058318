import { axiosInstance } from '../axiosInstance';
import { returnErrors, clearErrors } from '../actions/errorActions';
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_LOADING,
    REGISTER_SUCCESS,
    REGISTER_FAIL
} from '../actions/types';


//Check token & load user

export const loadUser = () => {
    return async function(dispatch, getState){
        dispatch(clearErrors());
        dispatch({
            type: USER_LOADING
        });
        try {
            let response = await axiosInstance.get(
                '/api/user', 
                tokenConfig(getState)
            );
            if(response.status !== 200){
                dispatch({
                    type: AUTH_ERROR,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: USER_LOADED,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'AUTH_ERROR'));
                dispatch ({ 
                    type: AUTH_ERROR 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'AUTH_ERROR')),
                    dispatch ({ 
                        type: AUTH_ERROR 
                    })
                )
            }
        }
    }
};

// Register User

export const register = ({ fname, lname, password, email, username, plans, referrer }) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: REGISTER_LOADING
        });
        //Headers

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        //Request body
        const body = JSON.stringify({ fname, lname, password, email, username, plans, referrer });
        try {
            let response = await axiosInstance.post(
                '/api/signup', 
                body, 
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: REGISTER_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: REGISTER_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'REGISTER_FAIL'));
                dispatch ({ 
                    type: REGISTER_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'REGISTER_FAIL')),
                    dispatch ({ 
                        type: REGISTER_FAIL 
                    })
                )
            }
        }
    }
};

// Login

export const login = ({ loginID, password, locale }) => {
    return async function(dispatch){
        dispatch(clearErrors());
        dispatch({
            type: LOGIN_LOADING
        });
        //Headers

        //Headers

        const config = {
            headers: {
                'Content-Type': 'application/json',
                "Accept-Language": locale ? locale : "en"
            }
        }

        //Request body
        const body = JSON.stringify({ loginID, password });
        try {
            let response = await axiosInstance.post(
                '/api/login', 
                body, 
                config
            );
            if(response.status !== 200){
                dispatch({
                    type: LOGIN_FAIL,
                });
                throw Error({
                    response: {
                        data: "Other Errors",
                        status: "Other Errors"
                    },
                })
            }else{
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error) {
            if (error.response && error.response.data) {
                dispatch(returnErrors(error.response.data, error.response.status, 'LOGIN_FAIL'));
                dispatch ({ 
                    type: LOGIN_FAIL 
                });
            }else{
                let errorJson = JSON.stringify(error);
                let errorParsed = JSON.parse(errorJson);
                return (
                    dispatch(returnErrors(errorParsed.message, "Network Error", 'LOGIN_FAIL')),
                    dispatch ({ 
                        type: LOGIN_FAIL 
                    })
                )
            }
        }
    }
};

// Logout

export const logout = () =>{
    return ({
        type: LOGOUT_SUCCESS
    });
}

// Setup config/headers and token

export const tokenConfig = getState => {
    // Get token from localStorage
    const token = getState().auth.token;
    //Headers
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers

    if (token) {
        config.headers['Authorization'] = 'bearer' + token;
    }

    return config;

}