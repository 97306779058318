import { GET_PORTFOLIO_SUCCESS, PORTFOLIO_ITEMS_LOADING, GET_PORTFOLIO_FAIL, CLEAR_PORTFOLIO_ITEMS } from '../actions/types';

const initialState = {
    portfolios: [],
    totalRecords: 0,
    itemsPerPage: 0,
    loadingportfolio: false
}

export default function freelancerportfolioReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PORTFOLIO_SUCCESS:
            return{
                ...state,
                portfolios: action.payload.data,
                totalRecords: action.payload.totalRecords,
                itemsPerPage: action.payload.itemsPerPage,
                loadingportfolio: false
            }    
        ;
        case PORTFOLIO_ITEMS_LOADING: 
            return{
                ...state,
                loadingportfolio: true
            }
        ;
        case GET_PORTFOLIO_FAIL:
            return{
                ...state,
                loadingportfolio: false
            }
        ;
        case CLEAR_PORTFOLIO_ITEMS:
            return{
                ...state,
                portfolios: [],
                totalRecords: 0,
                itemsPerPage: 0,
            }
        default:
            return state;
    }
}