const SERVICES = "/api/services";
const SERVICE_FEEDBACK = SERVICES + "/feedbacks";
const SERVICES_INVOICES = SERVICES + "/invoices";
const CANCEL_SERVICES_INVOICE = SERVICES_INVOICES + "/cancel";
const REPORT_SERVICES_INVOICE = SERVICES_INVOICES + "/abuse";
const HANDOVER_SERVICES_INVOICE = SERVICES_INVOICES + "/handover";
const INDORSE_SERVICES_INVOICE = SERVICES_INVOICES + "/indorse";

const exportedObject = {
    SERVICES,
    SERVICE_FEEDBACK,
    SERVICES_INVOICES,
    CANCEL_SERVICES_INVOICE,
    REPORT_SERVICES_INVOICE,
    HANDOVER_SERVICES_INVOICE,
    INDORSE_SERVICES_INVOICE,
};

export default exportedObject;
